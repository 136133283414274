import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import i18next from 'i18next'
import { List } from 'immutable'
import React from 'react'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { IOptimusService } from '../../../domain/optimusService'
import { TertiaryButton } from '../../../primitives/Button'
import { SmallValidationError } from '../../../primitives/ErrorMessages'
import { InputForLabel } from '../../../primitives/Forms'
import { LabeledInput } from '../../../primitives/LabeledInput'
import { isNotEmpty } from '../../../utils/collectionUtils'
import { validQuantityOrEmpty } from '../../../utils/inputValidation'
import { emptyVAS, ensureListWithOneItem } from '../bookingOrderFunctions'
import { IOrderForm } from '../bookingOrderTypes'
import { VasCode } from './VasCode'

interface BookingFormValueAddedServicesProps {
  formValues: IOrderForm
  valueAddedServices: List<IOptimusService>
  onVASAdded: () => void
  onVASRemoved: (index: number) => void
  disableSelection?: boolean
  isH2Booking?: boolean
  prefix?: string
}

const getSelectedServices = (formValues: IOrderForm, prefix: string) => {
  let selectedServices
  if (prefix !== '') {
    const prefixWithoutDot = prefix.substring(1)
    selectedServices = formValues.getIn([prefixWithoutDot, 'additionalServices'])
  } else {
    selectedServices = formValues.get('additionalServices')
  }
  return ensureListWithOneItem(selectedServices, emptyVAS)
}

const BookingFormValueAddedServices = ({
  formValues,
  valueAddedServices,
  onVASAdded,
  onVASRemoved,
  disableSelection,
  isH2Booking = false,
  prefix = ''
}: BookingFormValueAddedServicesProps) => {
  const selectedServices = getSelectedServices(formValues, prefix)
  const canRemoveVAS = selectedServices.count() > 1
  const showVasComponent =
    (isH2Booking || isNotEmpty(valueAddedServices)) && isNotEmpty(formValues.get('additionalServices'))

  const handleAddVAS = (e: React.MouseEvent) => {
    e.preventDefault()
    onVASAdded()
  }

  const handleRemoveVas = (i: number) => (e: React.MouseEvent) => {
    e.preventDefault()
    if (canRemoveVAS) {
      onVASRemoved(i)
    }
  }

  if (!showVasComponent) {
    return null
  }

  return (
    <>
      <h4>{i18next.t('instant.booking.additionalServices')}</h4>
      {isH2Booking && <div style={{ marginBottom: '1em' }}>{i18next.t('planner.h2.vasHelperText')}</div>}

      {selectedServices.map((_, i) => (
        <div
          key={'selectedVas-' + i}
          style={{
            display: 'grid',
            gridTemplateColumns: '4fr 1.1fr auto auto',
            gridColumnGap: '1em',
            maxWidth: '45em',
            height: '2.7em',
            marginBottom: '0.5em'
          }}
        >
          <VasCode
            isH2Booking={isH2Booking}
            index={i}
            disableSelection={disableSelection}
            valueAddedServices={valueAddedServices}
            prefix={prefix}
          />

          <div>
            <LabeledInput
              label={i18next.t('instant.booking.quantity')}
              htmlFor={prefix + `.additionalServices[${i}].quantity`}
            >
              <Control.text
                autoComplete="nope"
                model={prefix + `.additionalServices[${i}].quantity`}
                id={prefix + `.additionalServices[${i}].quantity`}
                component={InputForLabel}
                controlProps={{
                  onKeyDown: (e: KeyboardEvent) => e.key === 'Enter' && e.preventDefault()
                }}
                validators={{ validQuantityOrEmpty }}
                disaled={disableSelection}
              />
            </LabeledInput>
            <Errors
              className="errors"
              model={prefix + `.additionalServices[${i}].quantity`}
              show="touched"
              wrapper={SmallValidationError}
              messages={{
                validQuantityOrEmpty: `${i18next.t('error.notAValidNumber')}`
              }}
            />
          </div>

          <div>
            <TertiaryButton onClick={handleAddVAS} disabled={disableSelection}>
              <FontAwesomeIcon icon="plus" />
              <span style={{ paddingLeft: '0.5em' }}>{i18next.t('instant.booking.addItem')}</span>
            </TertiaryButton>
          </div>

          <div>
            <TertiaryButton onClick={handleRemoveVas(i)} disabled={disableSelection || !canRemoveVAS}>
              <FontAwesomeIcon icon="times" />
              <span style={{ paddingLeft: '0.5em' }}>{i18next.t('instant.booking.removeItem')}</span>
            </TertiaryButton>
          </div>
        </div>
      ))}
    </>
  )
}

export default BookingFormValueAddedServices
