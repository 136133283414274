import React from 'react'
import bringLogo from '../assets/brandLogos/bring-logo.svg'
import glowLogo from '../assets/brandLogos/glow-logo.svg'
import { isBring } from '@glow/common'

export const BrandingLogo: React.FC<{ style?: React.CSSProperties; className?: string }> = ({ style, className }) =>
  isBring() ? (
    <img className={className} style={style} src={bringLogo} alt="Bring" />
  ) : (
    <img className={className} style={style} src={glowLogo} alt="Glow" />
  )
