import React, { useState } from 'react'
import { TertiaryButton } from '../primitives/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import i18next from 'i18next'
import { H3 } from '../primitives/Headings'
import { createLabel } from '../actions/creators/helpers'
import { OrderIdType } from '../types/coreEntitiesTypes'
import { isNotNullOrUndefined } from '../types/immutableTypes'
import { createAWBLabel } from '../actions/creators/airexpressHelpers'
import { ConsumedResponse } from '../http/httpHelper'
import { useAppDispatch } from '../reducers/redux-hooks'

interface Props {
  label?: string
  orderId?: OrderIdType
  awbNo?: string
  showHeading?: boolean
}

const ShipmentLabel = ({ label, orderId, awbNo, showHeading }: Props) => {
  const [submitting, setSubmitting] = useState(false)
  const dispatch = useAppDispatch()

  const handleCreateAWBLabelResponse = (res: ConsumedResponse) => {
    setSubmitting(false)
    const url = (Object.values(res.readBody?.airwaybillLabels)?.[0] as any)?.url
    if (res.ok && isNotNullOrUndefined(url)) {
      const win = window.open(url, '_blank')
      if (win) win.focus()
    }
  }

  const labelCreationFailed = () => {
    setSubmitting(false)
  }

  const onCreateAWBLabel = (awbNo: number) => {
    createAWBLabel(awbNo, handleCreateAWBLabelResponse, labelCreationFailed)(dispatch)
  }

  let button
  if (submitting) {
    button = (
      <TertiaryButton>
        <FontAwesomeIcon icon="spinner" pulse />
      </TertiaryButton>
    )
  } else {
    button = (
      <TertiaryButton
        onClick={() => {
          setSubmitting(true)
          if (orderId) {
            createLabel(orderId)(dispatch).finally(() => setSubmitting(false))
          } else if (awbNo) {
            onCreateAWBLabel(Number(awbNo))
          }
        }}
      >
        {i18next.t('consignment.createLabel')}
      </TertiaryButton>
    )
  }

  return (
    <div>
      {showHeading && <H3>{i18next.t('consignment.label')}</H3>}
      <div>
        {button}
        {label ? (
          <a className="ml-4" href={label} target="_blank" rel="noopener noreferrer">
            <span className="underline">{i18next.t('consignment.viewLabel')}</span>
          </a>
        ) : null}
      </div>
    </div>
  )
}

export default ShipmentLabel
