import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import i18next from 'i18next'
import { List, Map, OrderedSet, Seq, Set } from 'immutable'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  COLLECT_CONSIGNMENTS_MANUAL_OVERRIDE,
  DELIVER_CONSIGNMENT_MANUAL_OVERRIDE,
  DEVIATE_CONSIGNMENT_MANUAL_OVERRIDE,
  RETURN_CONSIGNMENTS_MANUAL_OVERRIDE
} from '../../../actions/actionTypes'
import { closeModalAndNotify } from '../../../actions/creators/helpers'
import { ConsumedResponse } from '../../../http/httpHelper'
import DatePicker from '../../../pages/instant/DatePicker'
import TimePicker from '../../../pages/instant/TimePicker'
import { PrimaryButton } from '../../../primitives/Button'
import { ErrorMessages, ValidationError } from '../../../primitives/ErrorMessages'
import { Input, SimpleLabel } from '../../../primitives/Forms'
import { H2 } from '../../../primitives/Headings'
import { allOption, MultiSelectKeyed } from '../../../primitives/MultiSelectKeyed'
import Select from '../../../primitives/Select'
import { VerticalSpace } from '../../../primitives/Space'
import { useAppDispatch } from '../../../reducers/redux-hooks'
import { consignmentsByOrderIdsSelector } from '../../../selectors/consignmentSelectors'
import { errorMessagesFor } from '../../../selectors/httpStatusSelectors'
import variables from '../../../styles/variables'
import {
  Consignment,
  ConsignmentIdType,
  Department,
  DepartmentIdType,
  OrderIdType,
  PackageIdType
} from '../../../types/coreEntitiesTypes'
import { isNotNullOrUndefined } from '../../../types/immutableTypes'
import { AppStateType } from '../../../utils/appStateReduxStore'
import { concat, isNotEmpty } from '../../../utils/collectionUtils'
import { EventType } from '../../../utils/consignmentEvent'
import { consignmentCanBeCollected, ConsignmentState } from '../../../utils/consignmentState'
import {
  deliveryParcelDeviationCodes,
  deliveryWithoutParcelDeviationCodes,
  DeviationCodeLabel,
  pickupDeviationCodes,
  shouldDisplayParcelDeviations
} from '../../../utils/deviation'
import {
  requiredFormik,
  validDateFormik,
  validMultiSelectFormik,
  validTimeFormik
} from '../../../utils/formikInputValidation'
import { TIMESTAMP_DATE_FORMAT, TIMESTAMP_TIME_FORMAT } from '../../../utils/inputValidation'
import { isAdminUser } from '../../../utils/roles'
import { GroupedDropdown } from '../../Select'
import { FadingNotification } from '../shared/Styles'
import { dispatchManualOverrideEvent } from './ManualOverrideUtils'
import { SelectManualEventUnit } from './SelectManualEventUnit'

interface ConsignmentsProps {
  shipmentState?: ConsignmentState
  selectedShipmentsReturnable?: boolean
  selectedShipmentsCanBeCollected?: boolean
  viaBulkAction?: boolean
  consignmentIdToPackageId: Seq.Keyed<ConsignmentIdType, PackageIdType>
  orderIds: OrderedSet<OrderIdType>
  departmentIds: List<DepartmentIdType>
  departments: List<Department>
}

const manuallyOverriddenEvents = (isAdmin: boolean) =>
  (isAdmin &&
    List([
      EventType.COLLECTED,
      EventType.DEVIATED,
      EventType.DELIVERED,
      EventType.RETURNED,
      EventType.SCANNED,
      EventType.PRE_ADVISED
    ])) ||
  List([EventType.COLLECTED, EventType.DEVIATED, EventType.DELIVERED, EventType.RETURNED, EventType.PRE_ADVISED])

export interface ManualOverrideFormProps {
  eventType: string
  eventDate: string
  eventTime: string
  name: string
  deviationCode: string
  consignmentIds: Set<ConsignmentIdType>
  unitId: string
  driverUserId: string
  departmentId: string
}

const initialValues = (
  consignmentIds: Set<ConsignmentIdType>,
  consignments: List<Consignment>,
  currentDepartmentId: DepartmentIdType
): ManualOverrideFormProps => {
  const selectedConsignments = consignments.filter((c) => consignmentIds.contains(c.get('id')))

  const firstUnitId = selectedConsignments.first(undefined)?.get('courierId')
  const allConsignmentsHaveSameUnit = selectedConsignments.every((c) => c.get('courierId') === firstUnitId)
  const selectedUnitId = allConsignmentsHaveSameUnit && firstUnitId ? firstUnitId.toString() : ''

  const firstDriverUserId = selectedConsignments.first(undefined)?.get('driverUserId')
  const allConsignmentsHaveSameDriver = selectedConsignments.every((c) => c.get('driverUserId') === firstUnitId)
  const selectedDriverUserId = allConsignmentsHaveSameDriver && firstDriverUserId ? firstDriverUserId.toString() : ''

  return {
    eventType: '',
    eventDate: DateTime.now().toFormat(TIMESTAMP_DATE_FORMAT),
    eventTime: DateTime.now().toFormat(TIMESTAMP_TIME_FORMAT),
    name: '',
    deviationCode: pickupDeviationCodes.first<DeviationCodeLabel>(Map()).get('code'),
    consignmentIds: consignmentIds,
    unitId: selectedUnitId,
    driverUserId: selectedDriverUserId,
    departmentId: currentDepartmentId?.toString()
  }
}

export const ManualOverride = ({
  consignmentIdToPackageId,
  shipmentState,
  selectedShipmentsReturnable,
  selectedShipmentsCanBeCollected,
  viaBulkAction,
  orderIds,
  departmentIds,
  departments
}: ConsignmentsProps) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [showNotification, setShowNotification] = useState(false)

  const errorMessages = useSelector((state: AppStateType) =>
    concat(
      errorMessagesFor(state, DELIVER_CONSIGNMENT_MANUAL_OVERRIDE),
      errorMessagesFor(state, DEVIATE_CONSIGNMENT_MANUAL_OVERRIDE),
      errorMessagesFor(state, RETURN_CONSIGNMENTS_MANUAL_OVERRIDE),
      errorMessagesFor(state, COLLECT_CONSIGNMENTS_MANUAL_OVERRIDE)
    )
  )

  const eventTypes = useSelector((state: AppStateType) => manuallyOverriddenEvents(isAdminUser(state)))

  const handleSuccess = (res: ConsumedResponse) => {
    if (res.ok) {
      viaBulkAction ? dispatch(closeModalAndNotify(i18next.t('consignment.updated'))) : setShowNotification(true)
    }
  }

  const onSubmit = (formValues: ManualOverrideFormProps) => {
    const eventType = formValues.eventType as EventType
    const timestamp = DateTime.fromFormat(
      `${formValues.eventDate} ${formValues.eventTime}`,
      `${TIMESTAMP_DATE_FORMAT} ${TIMESTAMP_TIME_FORMAT}`
    ).toISO()
    const req = dispatchManualOverrideEvent(eventType, orderIds, timestamp, formValues, dispatch)

    if (req) {
      setLoading(true)
      setShowNotification(false)
      req
        .then(handleSuccess)
        .catch(console.warn)
        .finally(() => setLoading(false))
    }
  }

  const consignments = useSelector((state: AppStateType) => {
    return consignmentsByOrderIdsSelector(state, orderIds.toList())
  })

  const canReturnShipments = selectedShipmentsReturnable || shipmentState === ConsignmentState.DEVIATED
  const canCollectShipment =
    selectedShipmentsCanBeCollected || (shipmentState && consignmentCanBeCollected.contains(shipmentState)) || false

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize={true}
      initialValues={initialValues(
        consignmentIdToPackageId.keySeq().toSet(),
        consignments.toList(),
        departmentIds.first()
      )}
    >
      {({ setFieldValue, values, errors, touched, resetForm, setErrors }) => {
        const showEventCreationComponent = values.consignmentIds.size > 0
        const showEventInformation =
          showEventCreationComponent &&
          eventTypes.filter((e) => e !== EventType.PRE_ADVISED).contains(values.eventType as EventType)

        const showCourierInput = showEventInformation && values.eventType !== EventType.SCANNED
        const showDepartmentInput = values.eventType == EventType.SCANNED

        const onSelectEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
          const consignmentIds = values.consignmentIds

          resetForm()
          setErrors({})

          setFieldValue('eventType', e.target.value)
          setFieldValue('consignmentIds', consignmentIds)
        }

        const onSelectPackages = (ids: Seq.Indexed<ConsignmentIdType>) => {
          setFieldValue('consignmentIds', ids.filter(isNotNullOrUndefined).toSet())
        }

        return (
          <Form>
            {isNotEmpty(errorMessages) && <ErrorMessages errorMessages={errorMessages} />}

            {showNotification && <EventNotification showNotification={showNotification} />}
            {!viaBulkAction && (
              <SimpleLabel style={{ margin: '30px 0 0 1px' }}>
                <span style={{ fontSize: '12px' }}>{i18next.t('manualOverride.selectPackages')}</span>
                <Field
                  as={SelectPackages}
                  name="consignmentIds"
                  consignmentIdToPackageId={consignmentIdToPackageId}
                  onSelectPackages={onSelectPackages}
                  validate={validMultiSelectFormik}
                />
                {errors.consignmentIds && touched.consignmentIds && (
                  <ValidationError>
                    <>{errors.consignmentIds}</>
                  </ValidationError>
                )}
              </SimpleLabel>
            )}
            {showEventCreationComponent ? (
              <SelectEvent
                errors={errors}
                touched={touched}
                onSelect={onSelectEvent}
                canReturnShipments={canReturnShipments}
                canCollectShipment={canCollectShipment}
                eventTypes={eventTypes}
              />
            ) : (
              <VerticalSpace />
            )}
            {showEventInformation && (
              <EventInformation
                setFieldValue={setFieldValue}
                consignments={consignments}
                eventType={values.eventType}
                errors={errors}
                touched={touched}
              />
            )}

            {showCourierInput && <SelectManualEventUnit orderIds={orderIds} departmentIds={departmentIds} />}
            {showDepartmentInput && (
              <SelectDepartment
                departments={departments}
                currentDepartmentId={values.departmentId}
                setFieldValue={setFieldValue}
              />
            )}
            <PrimaryButton
              style={{ marginBottom: '14px', background: variables.newColors.primaryGreen, float: 'right' }}
              disabled={loading}
              type="submit"
            >
              {i18next.t('application.save')}
            </PrimaryButton>
          </Form>
        )
      }}
    </Formik>
  )
}

const SelectDepartment: React.FC<{
  departments: List<Department>
  currentDepartmentId: string
  setFieldValue: (key: string, value: any) => void
}> = ({ departments, currentDepartmentId, setFieldValue }) => (
  <div style={{ marginBottom: '1em' }}>
    <H2 size="s">{i18next.t('manualOverride.selectDepartment')}</H2>
    <div style={{ marginLeft: '1px', width: '280px' }}>
      <GroupedDropdown
        expand
        itemGroups={Map({ Departments: departments })}
        selected={currentDepartmentId}
        function={(departmentId) => setFieldValue('departmentId', departmentId)}
      />
    </div>
  </div>
)

const SelectPackages: React.FC<{
  consignmentIdToPackageId: Seq.Keyed<ConsignmentIdType, PackageIdType>
  onSelectPackages: (packages: Seq.Indexed<ConsignmentIdType>) => void
}> = ({ consignmentIdToPackageId, onSelectPackages }) => (
  <MultiSelectKeyed
    options={consignmentIdToPackageId}
    onSelection={onSelectPackages}
    shouldClearSelection={false}
    allowSelectAll={true}
    allSelectedByDefault={true}
    useStandardClearButtonStyles={true}
    getPlaceholderText={getPlaceholderText}
    useStandardGreenForCheckBoxes={true}
  />
)

const getPlaceholderText = (selectedLabels: Seq.Indexed<string>): string => {
  if (selectedLabels.contains(allOption.label)) {
    return i18next.t('manualOverride.allPackagesSelected')
  } else {
    const labelsExcludingSelectAllOption = selectedLabels.filterNot((it) => it === allOption.label)
    if (labelsExcludingSelectAllOption.count() === 1 || labelsExcludingSelectAllOption.count() === 2) {
      return labelsExcludingSelectAllOption.take(labelsExcludingSelectAllOption.count()).join(', ')
    } else if (labelsExcludingSelectAllOption.count() > 2) {
      return labelsExcludingSelectAllOption.take(2).join(', ') + '...'
    } else {
      return ' '
    }
  }
}

const SelectEvent: React.FC<{
  errors: FormikErrors<ManualOverrideFormProps>
  touched: FormikTouched<ManualOverrideFormProps>
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void
  canReturnShipments: boolean
  canCollectShipment: boolean
  eventTypes: List<EventType>
}> = ({ errors, touched, onSelect, canReturnShipments, canCollectShipment, eventTypes }) => (
  <div style={{ margin: '30px 0px' }}>
    <H2 size="s">{i18next.t('manualOverride.selectEventType')}</H2>
    <Select medium style={{ marginLeft: '1px', width: '280px' }}>
      <Field as="select" name="eventType" style={{ width: '100%' }} validate={requiredFormik} onChange={onSelect}>
        <option key={''} value={''}>
          {i18next.t('manualOverride.noEventSelected')}
        </option>
        <optgroup label="Status">
          {eventTypes.map((event, index) => {
            const disabled =
              (event === EventType.RETURNED && !canReturnShipments) ||
              (event === EventType.COLLECTED && !canCollectShipment)
            return (
              <option key={index} value={event} disabled={disabled}>
                {i18next.t(`manualOverride.eventType.${event}`)}
                {disabled &&
                  i18next.t(
                    `manualOverride.disabledEventReason.${event}`,
                    i18next.t('manualOverride.disabledEventReason.default')
                  )}
              </option>
            )
          })}
        </optgroup>
      </Field>
    </Select>
    {errors.eventType && touched.eventType && <ValidationError>{errors.eventType}</ValidationError>}
  </div>
)

const EventInformation: React.FC<{
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  eventType: string
  errors: FormikErrors<ManualOverrideFormProps>
  consignments: Seq.Indexed<Consignment>
  touched: FormikTouched<ManualOverrideFormProps>
}> = ({ setFieldValue, eventType, errors, touched, consignments }) => (
  <>
    <H2 size="s">{getEventInformationLabel(eventType)}</H2>
    {eventType === EventType.DEVIATED && <SelectDeviation consignments={consignments} />}
    <div style={{ display: 'flex', gap: '10px', marginLeft: '1px', marginBottom: '30px' }}>
      {(eventType === EventType.DELIVERED || eventType === EventType.RETURNED) && (
        <div style={{ width: '100%', flexDirection: 'column' }}>
          <Field as={Input} style={{ height: '40px' }} name="name" placeholder="Name" validate={requiredFormik} />
          {errors.name && touched.name && <ValidationError>{errors.name}</ValidationError>}
        </div>
      )}
      <div style={{ width: 'min-content', flexDirection: 'column' }}>
        <Field
          as={DatePicker}
          placeholder="yyyy-mm-dd"
          style={{ width: '100px' }}
          name="eventDate"
          validate={validDateFormik}
          onChange={(date: string) => setFieldValue('eventDate', date)}
        />
        {errors.eventDate && <ValidationError>{errors.eventDate}</ValidationError>}
      </div>
      <div style={{ width: 'min-content', flexDirection: 'column', marginRight: '1px' }}>
        <Field
          as={TimePicker}
          style={{ width: '60px', height: '40px' }}
          name="eventTime"
          validate={validTimeFormik}
          onChange={(time: string) => setFieldValue('eventTime', time)}
        />
        {errors.eventTime && <ValidationError>{errors.eventTime}</ValidationError>}
      </div>
    </div>
  </>
)

const SelectDeviation = ({ consignments }: { consignments: Seq.Indexed<Consignment> }) => {
  const displayParcelDeviations = shouldDisplayParcelDeviations(consignments.toList())
  return (
    <Select style={{ width: '99%', marginLeft: '1px', marginBottom: '30px' }}>
      <Field as="select" name="deviationCode" style={{ width: '100%' }}>
        <optgroup label={i18next.t('consignment.pickupDeviation')}>
          {pickupDeviationCodes.map((deviationCode: DeviationCodeLabel) => (
            <option key={deviationCode.get('code')} value={deviationCode.get('code')}>
              {i18next.t(deviationCode.get('label', ''))}
            </option>
          ))}
        </optgroup>
        <optgroup label={i18next.t('consignment.deliveryDeviation')}>
          {deliveryWithoutParcelDeviationCodes.concat(displayParcelDeviations ? deliveryParcelDeviationCodes : []).map(
            (deviationCode: DeviationCodeLabel) =>
              !deviationCode.get('duplicateDescription') && (
                <option key={deviationCode.get('code')} value={deviationCode.get('code')}>
                  {i18next.t(deviationCode.get('label', ''))}
                </option>
              )
          )}
        </optgroup>
      </Field>
    </Select>
  )
}

const getEventInformationLabel = (eventType: string): string => {
  let eventInfoKey
  if (eventType === EventType.DELIVERED || eventType === EventType.RETURNED) {
    eventInfoKey = 'fillDeliverOrReturnEventInfo'
  } else if (eventType === EventType.DEVIATED) {
    eventInfoKey = 'fillDeviationEventInfo'
  } else {
    eventInfoKey = 'fillGeneralEventInfo'
  }
  return i18next.t(`manualOverride.${eventInfoKey}`)
}

const EventNotification: React.FC<{ showNotification: boolean }> = ({ showNotification }) => (
  <FadingNotification key={showNotification.toString()}>{i18next.t('manualOverride.eventMessage')}</FadingNotification>
)
