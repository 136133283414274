import { Customer } from '../domain/customer'
import { Department, Shipment } from '../types/coreEntitiesTypes'
import { Set } from 'immutable'
import { CountryCode, basePostenTrackUrl, baseTrackUrl } from '@glow/common'
import { notAssignedStates } from './consignmentState'

export const paramForInternalTrackingURL = 'referrer=internal'

export function getDeliveryPageBaseUrl(
  customer?: Customer,
  department?: Department,
  orderType?: string | null,
  deliveryCountry?: string
) {
  return (
    (customer?.get('trackingType') === 'posten' ||
    (orderType && orderType.toLowerCase() === 'h2') ||
    (!orderType && Set(['posten', 'h2']).contains(department?.get('departmentType', 'other') || 'other')) ||
    (orderType?.toLowerCase() === 'hd' && deliveryCountry?.toUpperCase() === CountryCode.NO)
      ? basePostenTrackUrl()
      : baseTrackUrl()) || ''
  )
}

export const getTrackingUrl = (trackingBaseUrl: string, shipment: Shipment) =>
  (shipment.get('type') == 'HD' || shipment.get('type') == 'Baggage') &&
  notAssignedStates.contains(shipment.get('state'))
    ? `${trackingBaseUrl}/track/delivery/${shipment.get('shipmentId')}?${paramForInternalTrackingURL}`
    : `${trackingBaseUrl}/track/${shipment.get('externalId')}?${paramForInternalTrackingURL}`
