import React from 'react'
import i18next from 'i18next'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { EventMap } from './event-map'
import { EventType } from '../../../../utils/consignmentEvent'
import { ImmutableMap } from '../../../../types/immutableTypes'
import { IConsignmentEvent } from '../../../../types/coreEntitiesTypes'
import { EventRecipientUnitAndDriver } from './event-deviated'

export const EventManuallyOverridden = (props: {
  data: AnyData
  consignmentEvent: ImmutableMap<IConsignmentEvent>
}): React.ReactElement => {
  const label =
    props.consignmentEvent.get('type') === EventType.DELIVERED_MANUALLY_OVERRIDDEN
      ? i18next.t('consignmentEvent.message.receivedBy')
      : undefined

  return (
    <>
      <EventRecipientUnitAndDriver data={props.data} labelForRecipient={label} />
      <EventMap data={props.data} />
    </>
  )
}
