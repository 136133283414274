import React from 'react'
import { Accordion, ExpansionPanelProps } from '../../../primitives/Accordion'
import { List } from 'immutable'

export interface ShipmentModalAccordionProps {
  expansionPanels: List<ExpansionPanelProps>
}

export const ShipmentModalAccordion = (props: ShipmentModalAccordionProps) => (
  <Accordion style={{ marginLeft: '20px', width: '590px' }} {...props} />
)
