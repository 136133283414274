import styled, { keyframes } from 'styled-components'
import variables from '../../../styles/variables'

export const HorizontalSpace = styled.div`
  display: inline-block;
  height: 1.25rem;
`

export const ContactInfoText = styled.span`
  font-size: 1.125rem;
  color: ${variables.newColors.black};
  justify-self: start;
  min-height: 25px;
`

export const ContactInfoGrid = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  column-gap: 1rem;
  justify-items: center;
  align-items: center;
`

export const EditableContactInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 5fr;
  gap: 10px;
`

const openStayClose = keyframes`
  0% {
    opacity: 0;
    height: 0px;
  }
  16.67% {
    opacity: 1;
    height: 40px;
  }
  83.33% {
    opacity: 1;
    height: 40px;
  }
  100% {
    opacity: 0;
    height: 0px;
  }
`

export const FadingNotification = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: ${variables.newColors.lightGreen};
  height: 40px;
  font-size: 1rem;
  animation: ${openStayClose} 1800ms ease-out forwards;
`
