import { Map } from 'immutable'
import { Customer, CustomerIdType, CustomerReturnAddress } from '../domain/customer'
import { AppStateType } from '../utils/appStateReduxStore'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'

export const customersSelector: (state: AppStateType) => Map<number, Customer> = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'customers']),
  (customers) => customers || Map()
)

export const customerByIdSelector: (state: AppStateType, id: number | undefined) => Customer | undefined =
  createImmutableEqualSelector(
    customersSelector,
    (state: AppStateType, id: number | undefined) => id,
    (customers, id) => {
      if (id) {
        return customers.find((customer) => customer.get('id') == id)
      } else {
        return undefined
      }
    }
  )

export const customersByIdsSelector: (state: AppStateType, ids: number[]) => Map<CustomerIdType, Customer> =
  createImmutableEqualSelector(
    customersSelector,
    (state: AppStateType, ids: number[]) => ids,
    (customers, ids) => customers.filter((customer) => ids.includes(customer.get('id')))
  )

export const customersByDepartmentIdSelector: (state: AppStateType, departmentId: number) => Map<number, Customer> =
  createImmutableEqualSelector(
    customersSelector,
    (state: AppStateType, departmentId: number) => departmentId,
    (customers: Map<number, Map<string, any>>, departmentId) =>
      customers.filter((c) => (c.get('departments') ? c.get('departments').contains(departmentId) : false))
  )

export const customerReturnAddressSelector: (state: AppStateType) => Map<number, CustomerReturnAddress> =
  createImmutableEqualSelector(
    (state: AppStateType) => state.getIn(['entities', 'customerReturnAddresses']),
    (customerReturnAddresses) => customerReturnAddresses || Map()
  )

export const customerReturnAddressByIdSelector: (state: AppStateType, id: number) => CustomerReturnAddress | undefined =
  createImmutableEqualSelector(
    customerReturnAddressSelector,
    (state: AppStateType, id: number) => id,
    (customerReturnAddresses, id) => customerReturnAddresses.find((address) => address.get('customerId') == id)
  )
