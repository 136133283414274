import React from 'react'
import InputGroup from '../InputGroup'
import i18next from 'i18next'
import { H3 } from '../../../primitives/Headings'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { InputForLabel } from '../../../primitives/Forms'
import { LabeledInput } from '../../../primitives/LabeledInput'
import DatePicker from '../DatePicker'
import { required } from '../../../utils/inputValidation'
import { SmallValidationError } from '../../../primitives/ErrorMessages'
import { List, Map } from 'immutable'
import { ClearInstantBookingFormButton } from '../../../components/ClearInstantBookingFormButton'
import { h2BookingForm, serviceComponentNames } from './H2BookingSupport'
import BookingFormValueAddedServices from '../bookingFormComponents/BookingFormValueAddedServices'
import { emptyVAS } from '../bookingOrderFunctions'
import { H2OrderForm } from './H2BookingTypes'
import { IOrderForm } from '../bookingOrderTypes'

interface Props {
  clearComponents: (components: List<string>) => void
  formValues: H2OrderForm
  addToFormList: (formName: string, values: Map<string, string>) => void
  removeFromFormList: (model: string, index: number) => void
}

export const H2BookingService = ({ clearComponents, formValues, addToFormList, removeFromFormList }: Props) => {
  const addVAS = () => {
    addToFormList(`${h2BookingForm}.additionalServices`, emptyVAS())
  }

  const removeVAS = (i: number) => {
    removeFromFormList(`${h2BookingForm}.additionalServices`, i)
  }

  return (
    <InputGroup>
      <H3>{i18next.t('instant.booking.service')}</H3>
      <div>
        <LabeledInput label={i18next.t('instant.booking.service')} htmlFor="serviceCode">
          <Control.text
            autoComplete="nope"
            model=".serviceCode"
            id="serviceCode"
            component={InputForLabel}
            validators={{ required }}
            defaultValue={''}
          />
        </LabeledInput>
        <Errors
          className="errors"
          model=".serviceCode"
          show="touched"
          wrapper={SmallValidationError}
          messages={{ required: `${i18next.t('application.required')}` }}
        />
      </div>
      <BookingFormValueAddedServices
        isH2Booking={true}
        formValues={formValues as unknown as IOrderForm}
        valueAddedServices={List()}
        onVASAdded={addVAS}
        onVASRemoved={removeVAS}
      />
      <div style={{ paddingTop: '1em' }}>
        <span style={{ textTransform: 'uppercase', paddingRight: '1em' }}>{i18next.t('instant.booking.date')}</span>
        <Control.input
          model=".serviceDate"
          id="serviceDate"
          component={DatePicker}
          defaultValue={''}
          placeholder={'YYYY-MM-DD'}
        />
      </div>
      <ClearInstantBookingFormButton components={serviceComponentNames} clearComponents={clearComponents} />
    </InputGroup>
  )
}
