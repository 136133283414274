import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { eventDataValue } from '@glow/entity-types'
import { Text } from '@glow/ui-components'
import i18next from 'i18next'

export function EventManualPriceEdit(props: { data: AnyData }) {
  const customerPriceFrom = eventDataValue(props.data, ['customerPrice', 'from'])
  const customerPriceTo = eventDataValue(props.data, ['customerPrice', 'to'])
  const unitPriceFrom = eventDataValue(props.data, ['unitPrice', 'from'])
  const unitPriceTo = eventDataValue(props.data, ['unitPrice', 'to'])

  console.log(props.data)
  console.log(customerPriceFrom)
  return (
    <div className="flex gap-8">
      {(customerPriceFrom || customerPriceTo) && (
        <div>
          <Text as="h4" textType="base">
            {i18next.t('instant.booking.customerPrice')}
          </Text>
          <Text as="span" textType="base">
            {`${customerPriceFrom} → ${customerPriceTo}`}
          </Text>
        </div>
      )}
      {(unitPriceFrom || unitPriceTo) && (
        <div>
          <Text as="h4" textType="base">
            {i18next.t('instant.booking.resourcePrice')}
          </Text>
          <Text as="span" textType="base">
            {`${unitPriceFrom} → ${unitPriceTo}`}
          </Text>
        </div>
      )}
    </div>
  )
}
