import { ConsignmentState } from '@glow/entity-types'
import i18next from 'i18next'
import { List, Map, Set } from 'immutable'
import React from 'react'
import { DeliveryTimeOrderedData, fromOldToNewValue } from '../components/Message'
import { SmallText } from '../primitives/Typography'
import { ConsignmentEvent, UnitIdType } from '../types/coreEntitiesTypes'
import { ImmutableMap } from '../types/immutableTypes'
import { isDeliveryDeviation, isPickupDeviation } from './deviation'

export enum EventType {
  CREATED = 'created',
  ASSIGNED = 'assigned',
  UNASSIGNED = 'unassigned',
  COLLECTED = 'collected',
  COLLECTED_MANUALLY_OVERRIDDEN = 'collected-manually-overridden',
  DELIVERED = 'delivered',
  DELIVERED_MANUALLY_OVERRIDDEN = 'delivered-manually-overridden',
  HANDED_OVER_TO_OTHER_UNIT = 'handed-over-to-other-unit',
  DEVIATED = 'deviated',
  DAMAGED = 'damaged',
  DEVIATED_MANUALLY_OVERRIDDEN = 'deviated-manually-overridden',
  REJECTED = 'rejected',
  OFFERED = 'offered',
  ACCEPTED = 'accepted',
  COMMENT = 'comment',
  IMAGE = 'image',
  ERROR = 'error',
  DELIVERY_TIME_ESTIMATED = 'delivery-time-estimated',
  PICKUP_CONSIGNMENT_DATA_CHANGED = 'pickup-consignment-data-changed',
  DELIVERY_CONSIGNMENT_DATA_CHANGED = 'delivery-consignment-data-changed',
  NOTIFICATION = 'notification',
  DELIVERY_ID_CHECK = 'delivery-id-check',
  SPECIFICATION_SIZE_CHANGED = 'specification-size-data-changed',
  DIGITAL_IDENTITY_VERIFIED = 'digital-identity-verified',
  FLEX_DELIVERY_ORDERED = 'flex-delivery-ordered',
  FLEX_DELIVERY_ORDERED_BY_SENDER = 'flex-delivery-ordered-by-sender',
  FLEX_DELIVERY_CHANGED = 'flex-delivery-changed',
  FLEX_DELIVERY_CONFIRMATION_SMS_SENT = 'flex-delivery-confirmation-sms-sent',
  ACCEPT_ORDER_CORRECTION = 'accept-order-correction',
  REJECT_ORDER_CORRECTION = 'reject-order-correction',
  RETURNED = 'returned',
  RETURNED_MANUALLY_OVERRIDDEN = 'returned-manually-overridden',
  ETA_SMS_SENT = 'eta-sms-sent',
  SIGNATURE_SMS_SENT = 'signature-sms-sent',
  DELIVERY_TIME_ORDERED = 'delivery-time-ordered',
  DELIVERY_TIME_ORDERED_INTERNAL = 'delivery-time-ordered-internal',
  DELIVERY_TIME_UPDATED_FROM_HF_SYNC = 'delivery-time-updated-from-hf-sync',
  EXTERNAL_ADDRESS_WASH = 'external-address-wash',
  ORDER_NOTE_CHANGED = 'order-note-changed',
  SPECIFICATION_DESCRIPTION_CHANGED = 'specification-description-data-changed',
  ORDER_DATA_CHANGED = 'order-data-changed',
  ORDER_MOVED_TO_DEPARTMENT = 'order-moved-to-department',
  PACKAGE_MEASUREMENTS_CHANGED = 'package-measurements-changed',
  PACKAGE_DATE_CHANGED = 'package-data-changed',
  DELIVERED_OUTSIDE_SERVICE_TIME_WINDOW = 'delivered-outside-service-time-window',
  DELIVERED_OUTSIDE_ESTIMATED_TIME_WINDOW = 'delivered-outside-estimated-time-window',
  DRIVER_DEVIATED_FROM_ROUTE = 'driver-deviated-from-route',
  FIRST_ESTIMATED_TIMES_SEEN = 'first-estimated-times-seen',
  PRE_ADVICE_STATUS_CHANGED = 'pre-advice-status-changed',
  SCANNED = 'scanned',
  DELIVERY_LOCATION_DEVIATION = 'delivery-location-deviation',
  PRE_ADVISED = 'preadvised',
  RESOLVED = 'resolved',
  ARRIVED_AT_TERMINAL = 'arrived-at-terminal',
  REPORTED_DAMAGED = 'reported-damaged',
  REPORTED_MISSING_ARTICLE = 'reported-missing-article',
  SERVICE_UPGRADE_PURCHASED = 'service-upgrade-purchased',
  CLAIM_REGISTERED_EMAIL_SENT = 'claim-registered-email-sent',
  ORDER_SORTED = 'order-sorted',
  PLANNED_DELIVERY_DATE_ORDERED = 'planned-delivery-date-ordered',
  INVOICED_OK = 'invoiced-ok',
  INVOICING_FAILED = 'invoicing-failed',
  PARCEL_LOCKER_DELIVERY_SELECTED = 'parcel-locker-delivery-selected',
  CANCELLED_PARCEL_LOCKER_DELIVERY = 'cancelled-parcel-locker-delivery',
  PRE_ADVICE_RESULT = 'pre-advice-result',
  UNIT_MANUALLY_ADDED_FOR_INVOICING = 'unit-manually-added-for-invoicing',
  ORDER_PAUSED_FROM_INVOICING = 'order-paused-from-invoicing',
  ORDER_RELEASED_FOR_INVOICING = 'order-released-for-invoicing',
  MANUALLY_SET_INVOICING_OK_FOR_ORDER_INVOICING = 'manually-set-invoicing-ok-for-order-invoicing',
  MANUALLY_SET_NOT_INVOICED_FOR_ORDER_INVOICING = 'manually-set-not-invoiced-for-order-invoicing',
  MANUALLY_SET_EXCLUDED_FROM_INVOICING = 'manually-set-excluded-from-invoicing',
  INVOICE_ACKNOWLEDGED_BY_EXTERNAL_SYSTEM = 'invoice-acknowledged-by-external-system',
  INVOICE_CREATED_IN_EXTERNAL_SYSTEM = 'invoice-created-in-external-system',
  INVOICE_SENT_TO_CUSTOMER = 'invoice-sent-to-customer',
  INVOICE_PAID_BY_CUSTOMER = 'invoice-paid-by-customer',
  ORDER_CREDITED = 'order-credited',
  TIME_WINDOW_CANCELLED_BY_RECIPIENT = 'time-window-cancelled-by-recipient',
  RESTORED = 'restored',
  NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL = 'not-arrived-at-distributing-terminal',
  MANUAL_PRICE_OVERRIDE = 'manual-price-override'
}

export function consignmentEventToState(type: EventType): ConsignmentState {
  switch (type) {
    case EventType.DELIVERED_MANUALLY_OVERRIDDEN:
    case EventType.DELIVERED_OUTSIDE_SERVICE_TIME_WINDOW:
    case EventType.DELIVERED_OUTSIDE_ESTIMATED_TIME_WINDOW:
      return 'DELIVERED'
    case EventType.RETURNED_MANUALLY_OVERRIDDEN:
      return 'RETURNED'
    case EventType.DEVIATED_MANUALLY_OVERRIDDEN:
      return 'DEVIATED'
    case EventType.COLLECTED_MANUALLY_OVERRIDDEN:
      return 'COLLECTED'
    default:
      return (type?.toUpperCase() as ConsignmentState) || undefined
  }
}

export function getActualPickupTimeFromSortedEvents(events = List<ConsignmentEvent>()) {
  const collectedEvent = events.findLast((ce) => collectedConsignmentEvents.contains(ce.get('type')))
  const pickupDeviation = events.findLast((ce) => isPickupDeviation(ce))
  const pickupEvent = collectedEvent ? collectedEvent : pickupDeviation
  return pickupEvent && pickupEvent.get('eventTime')
}

export function getLastEventCorrespondingToStateChange(events = List<ConsignmentEvent>(), state: ConsignmentState) {
  return events
    .filter((e) => consignmentEventToState(e.get('type')) === state)
    .sort((a, b) => (a.get('eventTime') < b.get('eventTime') ? 1 : -1))
    .first()
}

export function getActualArrivalTimeFromSortedEvents(events = List<ConsignmentEvent>()) {
  const endEvent =
    events.reverse().find((ce) => {
      const et = ce.get('type')
      return et === EventType.DELIVERED_MANUALLY_OVERRIDDEN || et === EventType.DEVIATED_MANUALLY_OVERRIDDEN
    }) ||
    events
      .sortBy((e) => e.get('eventTime'))
      .skipWhile((ce) => {
        //Deviations occurring before Collected events are pickup deviations
        const et = ce.get('type')
        return et != EventType.COLLECTED
      })
      .find((ce) => {
        const et = ce.get('type')
        return et === EventType.DELIVERED || et === EventType.HANDED_OVER_TO_OTHER_UNIT || isDeliveryDeviation(ce)
      })
  return endEvent && endEvent.get('eventTime')
}

const eventsThatContainUnitId = [
  EventType.COLLECTED_MANUALLY_OVERRIDDEN,
  EventType.DELIVERED_MANUALLY_OVERRIDDEN,
  EventType.DEVIATED_MANUALLY_OVERRIDDEN,
  EventType.DELIVERED_MANUALLY_OVERRIDDEN,
  EventType.UNIT_MANUALLY_ADDED_FOR_INVOICING
]
export const getUnitIdFromEvents = (events = List<ConsignmentEvent>()): UnitIdType | undefined => {
  return events
    .filter((event) => eventsThatContainUnitId.includes(event.get('type')))
    .sort((a, b) => (a.get('eventTime') < b.get('eventTime') ? 1 : -1))
    .find((event) => event.get('data')?.get('unitId') !== undefined)
    ?.get('data')
    ?.get('unitId')
}

const eventsThatCanContainSelfBillingId = [EventType.DELIVERED, EventType.RETURNED, EventType.DEVIATED]

export const getSelfBillingUnitIdFromEvents = (events = List<ConsignmentEvent>()): string | undefined => {
  return events
    .filter((event) => eventsThatCanContainSelfBillingId.includes(event.get('type')))
    .sort((a, b) => (a.get('eventTime') < b.get('eventTime') ? 1 : -1))
    .find((event) => event.get('data')?.get('selfBillingUnitId') !== undefined)
    ?.get('data')
    ?.get('selfBillingUnitId')
}

export const isConsignmentReturned = (events: List<ConsignmentEvent>): boolean =>
  events && events.some((event) => returnedCustomerConsignmentEvents.contains(event.get('type')))

export const isConsignmentCredited = (events: List<ConsignmentEvent>): boolean =>
  events && events.some((event) => creditedConsignmentEvents.contains(event.get('type')))

//recipientName is data field present in delivered-manually-overridden event data
// and recipientNameText is data field present in delivered event data
export function getRecipientName(
  data: ImmutableMap<{ recipientNameText?: string; recipientName?: string }> | null = Map()
) {
  const recipientName = data?.get('recipientNameText') || data?.get('recipientName')
  if (recipientName) {
    return (
      <SmallText>
        {' '}
        {i18next.t('consignmentEvent.message.recipientName')}
        {': '}
        {recipientName}
      </SmallText>
    )
  } else {
    return null
  }
}

export function getToAndFromDepartment(
  data: ImmutableMap<{ departmentName?: ImmutableMap<{ to: string; from: string }> }> | null = Map()
) {
  const moveOrderToAnotherDepartmentData = data ? data.get('departmentName') : null
  if (moveOrderToAnotherDepartmentData) {
    return (
      <SmallText>
        {' '}
        {`${i18next.t('consignmentEvent.message.order-moved-to-department-with-data')} ${fromOldToNewValue(
          moveOrderToAnotherDepartmentData
        )}`}
      </SmallText>
    )
  } else {
    return null
  }
}

export const shouldShowMapIcon = (type: EventType): boolean => consignmentEventsEligibleForMapIcon.contains(type)

export function getDeliverWithoutSignatureReason(
  data: ImmutableMap<{ deliverWithoutSignatureReason?: string }> | null
) {
  if (data && data.has('deliverWithoutSignatureReason')) {
    return (
      <SmallText>
        {i18next.t('consignmentEvent.message.deliveredWithoutSignature')}
        {': '}
        {i18next.t(
          `consignmentEvent.message.deliveredWithoutSignatureReason.${data.get('deliverWithoutSignatureReason')}`
        )}
      </SmallText>
    )
  }

  return null
}

export const returnedCustomerConsignmentEvents = Set([EventType.RETURNED, EventType.RETURNED_MANUALLY_OVERRIDDEN])

export const damagedConsignmentEvents = Set([EventType.DAMAGED])

export const deviatedConsignmentEvents = Set([EventType.DEVIATED, EventType.DEVIATED_MANUALLY_OVERRIDDEN])

export const deliveredConsignmentEvents = Set([EventType.DELIVERED, EventType.DELIVERED_MANUALLY_OVERRIDDEN])

export const collectedConsignmentEvents = Set([EventType.COLLECTED, EventType.COLLECTED_MANUALLY_OVERRIDDEN])

export const creditedConsignmentEvents = Set([EventType.ORDER_CREDITED])

export const liveDeviationEvents = List([
  EventType.DAMAGED,
  EventType.DEVIATED,
  EventType.DEVIATED_MANUALLY_OVERRIDDEN,
  EventType.DELIVERED_OUTSIDE_SERVICE_TIME_WINDOW,
  EventType.DELIVERED_OUTSIDE_ESTIMATED_TIME_WINDOW,
  EventType.DRIVER_DEVIATED_FROM_ROUTE,
  EventType.DELIVERY_LOCATION_DEVIATION
])

export const allLiveEvents = List([
  EventType.DELIVERED,
  EventType.COLLECTED,
  EventType.DELIVERED_MANUALLY_OVERRIDDEN,
  EventType.DEVIATED,
  EventType.DEVIATED_MANUALLY_OVERRIDDEN,
  EventType.HANDED_OVER_TO_OTHER_UNIT,
  EventType.COLLECTED_MANUALLY_OVERRIDDEN,
  EventType.DAMAGED,
  EventType.DELIVERED_OUTSIDE_SERVICE_TIME_WINDOW,
  EventType.DELIVERED_OUTSIDE_ESTIMATED_TIME_WINDOW,
  EventType.DRIVER_DEVIATED_FROM_ROUTE,
  EventType.DELIVERY_LOCATION_DEVIATION
])

export const liveDeviationDeliveryEvents = List([
  EventType.DELIVERED_OUTSIDE_SERVICE_TIME_WINDOW,
  EventType.DRIVER_DEVIATED_FROM_ROUTE,
  EventType.DELIVERED_OUTSIDE_ESTIMATED_TIME_WINDOW,
  EventType.DELIVERY_LOCATION_DEVIATION
])

export const delayedEvents = List([
  EventType.DELIVERED_OUTSIDE_SERVICE_TIME_WINDOW,
  EventType.DELIVERED_OUTSIDE_ESTIMATED_TIME_WINDOW
])

export const consignmentEventsEligibleForMapIcon: Set<string> = Set([
  EventType.COLLECTED,
  EventType.DELIVERED,
  EventType.DEVIATED,
  EventType.HANDED_OVER_TO_OTHER_UNIT,
  EventType.SIGNATURE_SMS_SENT,
  EventType.RETURNED
])

export const invoiceCallbackEvents: Set<string> = Set([
  EventType.INVOICE_ACKNOWLEDGED_BY_EXTERNAL_SYSTEM,
  EventType.INVOICE_CREATED_IN_EXTERNAL_SYSTEM,
  EventType.INVOICE_SENT_TO_CUSTOMER,
  EventType.INVOICE_PAID_BY_CUSTOMER
])

export const getOrderedDeliveryTime = (data?: ImmutableMap<DeliveryTimeOrderedData> | null) =>
  data?.get('deliveryDate') || JSON.parse(data?.get('extraData') || '{}')?.deliveryDate
