import { List } from 'immutable'
import { IPriceExplanation } from '../pages/instant/bookingOrderTypes'
import { ModalBase } from '@glow/ui-components'
import i18next from 'i18next'
import { formatPriceExplanations } from '../pages/instant/bookingOrderFunctions'
import * as React from 'react'

interface PriceExplanationsProps {
  priceExplanations: List<IPriceExplanation>
}

export function PriceExplanationModal(
  priceExplanationsProps: PriceExplanationsProps & { showModal: boolean; toggleModal: () => void }
) {
  return (
    <ModalBase
      className="max-h-[85vh] overflow-scroll"
      size="large"
      onClose={priceExplanationsProps.toggleModal}
      open={priceExplanationsProps.showModal}
      closeText={i18next.t('button.close')}
      title={i18next.t('instant.booking.priceExplanations.header')}
    >
      {priceExplanationsProps?.priceExplanations &&
        renderArticlePriceExplanations(priceExplanationsProps.priceExplanations)}
    </ModalBase>
  )
}

function renderArticlePriceExplanations(articles: List<IPriceExplanation>) {
  return <pre className="font-sans">{formatPriceExplanations(articles)}</pre>
}
