import { UserAccessKey } from '@glow/entity-types'
import { List, Map } from 'immutable'
import { SessionUser, CourierUser, User } from '../types/coreEntitiesTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'
import { courierUsersSelector } from '../components/unit/UnitHelpers'

export const usersSelector: (state: AppStateType) => Map<number, User> = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'users']),
  (users: Map<number, User>): Map<number, User> => (users ? users : Map())
)

export const adminSelector: (state: AppStateType) => Map<number, User> = createImmutableEqualSelector(
  usersSelector,
  (users: Map<number, User>) => users.filter((u) => u.get('role').toUpperCase() === 'ADMIN')
)
export const plannerSelector: (state: AppStateType) => Map<number, User> = createImmutableEqualSelector(
  usersSelector,
  (users: Map<number, User>) => users.filter((u) => u.get('role').toUpperCase() === 'PLANNER')
)

export const readOnlyPlannerSelector: (state: AppStateType) => Map<number, User> = createImmutableEqualSelector(
  usersSelector,
  (users: Map<number, User>) => users.filter((u) => u.get('role') === 'read_only_planner')
)

export const allCourierUsersInDepartment: (
  state: AppStateType,
  departmentIds: List<number>
) => Map<number, CourierUser> = createImmutableEqualSelector(
  courierUsersSelector,
  (_state: AppStateType, departmentIds: List<number>) => departmentIds,
  (users: Map<number, CourierUser>, departmentIds: List<number>) =>
    users.filter((u) => u.get('departments')?.some((x) => departmentIds.includes(x)))
)

export const plusPlannerSelector: (state: AppStateType) => Map<number, User> = createImmutableEqualSelector(
  usersSelector,
  (users: Map<number, User>) => users.filter((u) => u.get('role') === 'plus_planner')
)

export const partnerSelector: (state: AppStateType) => Map<number, User> = createImmutableEqualSelector(
  usersSelector,
  (users: Map<number, User>) => users.filter((u) => u.get('role') === 'partner')
)

export const userByIdSelector: (state: AppStateType, id: number) => User | undefined = createImmutableEqualSelector(
  usersSelector,
  (state: AppStateType, id: number) => id,
  (users: Map<number, User>, id: number) => users.get(id)
)

export const usersByIdsSelector: (state: AppStateType, ids: number[]) => Map<number, User> =
  createImmutableEqualSelector(
    usersSelector,
    (state: AppStateType, ids: number[]) => ids,
    (users: Map<number, User>, ids: number[]) => users.filter((u) => ids.includes(u.get('id')))
  )

export const loggedInUserSelector: (state: AppStateType) => User | undefined = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['user']),
  (user?: User) => user
)

export const userHasAccessSelector: (state: AppStateType, accesses: UserAccessKey[]) => boolean =
  createImmutableEqualSelector(
    (state: AppStateType, access: UserAccessKey[]) => access,
    (state: AppStateType) => state.getIn(['user']),
    (accesses: UserAccessKey[], user?: SessionUser) => accesses.some((access) => user?.get('access')?.includes(access))
  )

export const customerUserSelector: (state: AppStateType) => Map<number, User> = createImmutableEqualSelector(
  usersSelector,
  (users: Map<number, User>) => users.filter((u) => u.get('role') === 'customer')
)

export const handlerUserSelector: (state: AppStateType) => Map<number, User> = createImmutableEqualSelector(
  usersSelector,
  (users: Map<number, User>) => users.filter((u) => u.get('role') === 'handler')
)

export const customsAgentSelector: (state: AppStateType) => Map<number, User> = createImmutableEqualSelector(
  usersSelector,
  (users: Map<number, User>) => users.filter((u) => u.get('role') === 'customs_agent')
)
