import i18next from 'i18next'
import { Collection } from 'immutable'
import React from 'react'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { groupRecipientColumns } from '../../components/grid/GridColumns'
import { SmallValidationError } from '../../primitives/ErrorMessages'
import { InputForLabel, Label } from '../../primitives/Forms'
import { LabeledInput } from '../../primitives/LabeledInput'
import { ImmutableMap } from '../../types/immutableTypes'
import { required } from '../../utils/inputValidation'
import { IOrderForm } from './bookingOrderTypes'
import { createInstantGrid, noop } from './InstantGrid'

export interface GroupRecipientProps {
  id?: number
  name: string
  address: string
  zipCode: string
  city: string
  countryCode: string
  phone: string
  secondPhone?: string | null
  contactPerson: string
  instructions: string
  reference: string
  lat?: number
  lng?: number
  email?: string | null
}

const RecipientsGrid = createInstantGrid<GroupRecipientProps>()

interface MultistopGroupFormProps {
  recipients: Collection<number, ImmutableMap<GroupRecipientProps>>
  onDelete: (id: number) => void
  formValues: IOrderForm
}

export const MultistopGroupForm = ({ recipients, formValues, onDelete }: MultistopGroupFormProps) =>
  recipients.isEmpty() ? null : (
    <div>
      <RecipientsGrid
        data={recipients.map((v, k) => v.set('id', k))}
        onEditRow={noop}
        stateKey={'groupRecipientsGrid'}
        columns={groupRecipientColumns}
        onClickRow={noop}
        overflow={true}
        isEditable={false}
        isDragNDropEnabled={false}
        isDropEnabled={true}
        onDragEnd={noop}
        isMultiSelectEnabled={false}
        isResizeable={false}
        isDeletable={true}
        onDeleteRow={(row) => onDelete(row.get('id'))}
        isFullscreen={false}
        sorting={false}
      />
      <div style={{ marginTop: '0.75em', display: 'flex', justifyContent: 'flex-start' }}>
        <Label style={{ maxWidth: '15em' }}>
          <Control.checkbox model={'.groupOrders'} id={'groupOrders'} defaultValue={false} />
          <span style={{ padding: '0.4em' }}>{i18next.t('instant.groupOrders')}</span>
        </Label>
        <Label style={{ maxWidth: '15em' }}>
          <Control.checkbox
            model={'.fixedOrder'}
            id={'fixedOrder'}
            defaultValue={false}
            disabled={!formValues.get('groupOrders')}
          />
          <span style={{ padding: '0.4em' }}>{i18next.t('instant.fixedOrder')}</span>
        </Label>
        <Label style={{ maxWidth: '15em' }}>
          <Control.checkbox
            model={'.paidTogether'}
            id={'paidTogether'}
            defaultValue={false}
            disabled={!formValues.get('groupOrders')}
          />
          <span style={{ padding: '0.4em' }}>{i18next.t('instant.paidTogether')}</span>
        </Label>
      </div>
      {formValues.get('groupOrders', false) ? (
        <div style={{ width: '100%' }}>
          <LabeledInput label={i18next.t('instant.groupName')}>
            <Control.text
              autoComplete="nope"
              model=".groupName"
              id="groupName"
              component={InputForLabel}
              validators={{ required }}
              defaultValue={''}
            />
          </LabeledInput>
          <Errors
            className="errors"
            model=".groupName"
            show="touched"
            wrapper={SmallValidationError}
            messages={{ required: `${i18next.t('application.required')}` }}
          />
        </div>
      ) : null}
    </div>
  )
