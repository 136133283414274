import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import variables from '../../styles/variables'
import { ShipmentInfo } from './shipmentInfo/ShipmentInfo'
import { InternalNote } from './internalNote/InternalNote'
import { ShipmentForShipmentModal } from './shared/Shipment'
import { Form, Formik } from 'formik'
import { quickEditShipment, setPageStateValue } from '../../actions/creators/helpers'
import { Department, RoutePoint } from '../../types/coreEntitiesTypes'
import { PickupAndDelivery } from './PickupAndDelivery'
import { initialValuesForEditShipment, getPayloadForEditShipment, QuickEditFormProps } from './shipmentModalUtils'
import { List } from 'immutable'
import { FlightInfoEntity } from '../../domain/bringOrder'
import { useAppDispatch } from '../../reducers/redux-hooks'

export const NewShipmentModal: React.FC<{
  shipment: ShipmentForShipmentModal
  enableQuickEdit?: boolean
  department?: Department
  routePoints?: List<RoutePoint>
  flightInfo?: FlightInfoEntity
}> = ({ shipment, enableQuickEdit, department, routePoints }) => {
  const dispatch = useAppDispatch()
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    setPageStateValue('editingShipmentModal', editing)(dispatch)
  }, [editing])

  const initialValues = initialValuesForEditShipment(shipment)

  const onSubmit = (formValues: QuickEditFormProps) => {
    const payload = department ? getPayloadForEditShipment(formValues, shipment, department?.get('id')) : {}

    if (Object.keys(payload).some((key) => Object.keys(initialValues).includes(key))) {
      quickEditShipment(payload)(dispatch).then(() => setEditing(false))
    } else {
      setEditing(false)
    }
  }

  return (
    <Formik onSubmit={onSubmit} enableReinitialize={true} initialValues={initialValues}>
      {({ errors, touched, resetForm }) => (
        <Form>
          <ShipmentInfoContainer>
            <ShipmentInfo
              shipmentNumber={shipment.shipmentNumber}
              state={shipment.state}
              isMultileg={shipment.isMultileg}
              customer={shipment.customer}
              customerContact={shipment.customerContact}
              services={shipment.services}
              route={shipment.route}
              goods={shipment.goods}
              orderId={shipment.orderId}
              orderType={shipment.type}
              departmentId={shipment.departmentId}
              isEditable={shipment.isEditable}
              isDeleted={shipment.isDeleted}
              editing={editing}
              setEditing={(status: boolean) => setEditing(status)}
              enableQuickEdit={enableQuickEdit}
              resetForm={resetForm}
            />
          </ShipmentInfoContainer>
          <PickupAndDeliveryContainer>
            <PickupAndDelivery
              errors={errors}
              touched={touched}
              shipment={shipment}
              editing={editing}
              department={department}
              routePoints={routePoints}
            />
          </PickupAndDeliveryContainer>
          <InternalNoteContainer>
            <InternalNote note={shipment.internalNote} editing={editing} />
          </InternalNoteContainer>
        </Form>
      )}
    </Formik>
  )
}

export const ShipmentInfoContainer = styled.section`
  border-bottom: 1px solid ${variables.newColors.orbitGrey};
  padding-bottom: 2rem;
`

export const PickupAndDeliveryContainer = styled.div`
  padding: 2rem 0;
  display: flex;
  section {
    flex: 1;
    padding-right: 2rem;
  }
  section + section {
    border-left: 1px solid ${variables.newColors.orbitGrey};
    padding-left: 2rem;
  }
`

export const InternalNoteContainer = styled.section`
  margin-bottom: 2rem;
`
