import { DateTime } from 'luxon'
import { formatAmountWithUnit, MeasurementUnit } from '../../../domain/measurementUnits'
import { ConsignmentState } from '../../../utils/consignmentState'
import { DepartmentIdType, OrderIdType } from '../../../types/coreEntitiesTypes'
import { CustomerIdType, ServiceLevelBackend } from '../../../domain/customer'
import { OrderType } from '@glow/entity-types'

export interface Address {
  streetName: string
  postalCode: string
  area: string
  country: string
  lat: number
  lng: number
}

export interface ShipmentForShipmentModal {
  shipmentNumber: string
  orderId: OrderIdType
  departmentId: DepartmentIdType
  isEditable: boolean
  isDeleted: boolean
  state: ConsignmentState
  isMultileg: boolean
  pickup: Pickup
  delivery: Delivery
  customer: Customer
  customerContact?: string
  services: Services
  route: Route
  goods: Goods
  internalNote?: string
  serviceLevel: ServiceLevelBackend
  mawbNo?: string
  awbNo?: string
  isLastLeg: boolean
  type: OrderType | null
}

export interface Pickup {
  sender: Sender
  pickupInstructions: string
  pickupTime?: PickupTime
  eta?: DateTime
}

export interface Sender {
  name: string
  address: Address
  phone: string
  secondPhone?: string
  reference: string
  email?: string
}

export interface PickupTime {
  earliest: DateTime
  latest: DateTime
}

export interface Delivery {
  receiver: Receiver
  deliveryInstructions: string
  deliveryTime?: DeliveryTime
  eta?: DateTime
  showTrackingUrl: boolean
  trackingURL?: string
}

export interface Receiver {
  name: string
  address: Address
  phone?: string
  secondPhone?: string
  email?: string
  reference?: string
  deliverySms: boolean
}

export interface DeliveryTime {
  earliest: DateTime
  latest: DateTime
}

export interface Customer {
  id: CustomerIdType
  name: string
  number: string
}

export interface Services {
  service: Service
  valueAddedServices: VAS[]
}

export interface Service {
  code: string
  description: string
}

export interface VAS {
  text: string
  quantity: string
}

export interface Route {
  name?: string
  courierName?: string
}

export interface Goods {
  totalWeight: Quantity
  totalVolume: Quantity
  totalLoadingMeters?: Quantity
  totalPalletFootPrints?: Quantity
  goodsDescription: string
  consignments: Consignment[]
}

export interface Quantity {
  value: number
  unit: MeasurementUnit
}

export interface Consignment {
  packageId: string
  weight: number
  length: number
  width: number
  height: number
}

export const formatQuantity = (q: Quantity) => formatAmountWithUnit(q.value, q.unit)
