import React from 'react'
import i18next from 'i18next'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { eventDataValue } from '@glow/entity-types'

export const ArrivedAtTerminalMessage = (props: { data: AnyData }): React.ReactElement => {
  const terminalId = eventDataValue(props.data, ['depUnitNumber'])
  const postalCode = eventDataValue(props.data, ['postalCode'])
  const location = eventDataValue(props.data, ['location'])
  const city = eventDataValue(props.data, ['city'])

  return (
    <>
      {terminalId && postalCode && (
        <>
          {i18next.t('terminal')}
          {': '}
          {[terminalId, postalCode, city].filter((d) => d).join(', ')}
          {location && <>{', '}</>}
        </>
      )}
      {location && (
        <>
          {i18next.t('location')}
          {': '}
          {location}
        </>
      )}
    </>
  )
}
