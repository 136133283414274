import i18next from 'i18next'
import { List, Map } from 'immutable'
import React from 'react'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { ICustomerEventEmail } from '../pages/instant/bookingOrderTypes'
import { SmallValidationError } from '../primitives/ErrorMessages'
import { InputForLabel, Label } from '../primitives/Forms'
import ImmutableComponent from '../primitives/ImmutableComponent'
import { LabeledInput } from '../primitives/LabeledInput'
import variables from '../styles/variables'
import { email } from '../utils/inputValidation'

interface Props {
  customerEventEmailData: List<ICustomerEventEmail>
  selectedCustomerId: number
}

export default class EmailNotificationDetails extends ImmutableComponent<Props> {
  render() {
    const customerEventEmailDataBySelectedCustomerId = this.props.customerEventEmailData
      .filter((row) => row.get('customerId') === this.props.selectedCustomerId)
      .valueSeq()
    return (
      <div>
        <div className="text-xs font-bold my-5">{i18next.t('instant.booking.emailNotifications')}</div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto auto auto',
            marginBottom: '0.5em'
          }}
        >
          <div style={{ gridColumn: 1, gridRow: 1, marginRight: '1em', padding: '0.5em 0' }}>
            <LabeledInput label={i18next.t('instant.booking.emailAddress')} htmlFor="emailId">
              <Control.text
                autoComplete="nope"
                model=".emailId"
                id="emailId"
                component={InputForLabel}
                validators={{ email }}
              />
            </LabeledInput>
            <Errors
              className="errors"
              model=".emailId"
              show="touched"
              wrapper={SmallValidationError}
              messages={{ email: `${i18next.t('instant.booking.invalidEmail')}` }}
            />
          </div>
          <div style={{ gridColumn: 2, gridRow: 1, marginRight: '1em', padding: '0.5em', paddingTop: '1em' }}>
            <Label style={{ maxWidth: '15em' }}>
              <Control.checkbox model=".createdEvent" id="createdEvent" />
              <span style={{ padding: '0.4em' }}>{i18next.t('instant.booking.eventRegistered')}</span>
            </Label>
          </div>
          <div style={{ gridColumn: 3, gridRow: 1, marginRight: '1em', padding: '0.5em', paddingTop: '1em' }}>
            <Label style={{ maxWidth: '15em' }}>
              <Control.checkbox model=".collectedEvent" id="collectedEvent" />
              <span style={{ padding: '0.4em' }}>{i18next.t('instant.booking.eventPickup')}</span>
            </Label>
          </div>
          <div style={{ gridColumn: 4, gridRow: 1, marginRight: '1em', padding: '0.5em', paddingTop: '1em' }}>
            <Label style={{ maxWidth: '15em' }}>
              <Control.checkbox model=".deliveredEvent" id="deliveredEvent" />
              <span style={{ padding: '0.4em' }}>{i18next.t('instant.booking.eventDelivery')}</span>
            </Label>
          </div>
        </div>
        {!customerEventEmailDataBySelectedCustomerId.isEmpty() && (
          <div style={{ display: 'grid' }}>
            <table>
              <thead style={{ textAlign: 'left' }}>
                <tr>
                  <th style={{ width: '40%' }}>
                    <h5>{i18next.t('instant.booking.emailRecipientText')}</h5>
                  </th>
                  <th style={{ width: '20%' }}>
                    <h5>{i18next.t('instant.booking.eventRegistered')}</h5>
                  </th>
                  <th style={{ width: '20%' }}>
                    <h5>{i18next.t('instant.booking.eventPickup')}</h5>
                  </th>
                  <th style={{ width: '20%' }}>
                    <h5>{i18next.t('instant.booking.eventDelivery')}</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                {customerEventEmailDataBySelectedCustomerId
                  .valueSeq()
                  .map((row: Map<string, any>) => {
                    return (
                      <tr
                        style={{
                          textAlign: 'left'
                        }}
                        key={`emails_${row.get('id')}`}
                      >
                        <td>
                          <div style={{ color: variables.colors.black }}>{row.get('emailId')}</div>
                        </td>
                        <td>
                          <div style={{ color: variables.colors.black }}>{row.get('createdEvent') ? 'Yes' : 'No'}</div>
                        </td>
                        <td>
                          <div style={{ color: variables.colors.black }}>
                            {row.get('collectedEvent') ? 'Yes' : 'No'}
                          </div>
                        </td>
                        <td>
                          <div style={{ color: variables.colors.black }}>
                            {row.get('deliveredEvent') ? 'Yes' : 'No'}
                          </div>
                        </td>
                      </tr>
                    )
                  })
                  .valueSeq()}
              </tbody>
            </table>
          </div>
        )}
      </div>
    )
  }
}
