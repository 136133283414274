import { List, Map } from 'immutable'
import * as React from 'react'
import Select from '../../../primitives/Select'
// @ts-expect-error
import { Control } from 'react-redux-form/lib/immutable'
import i18next from 'i18next'

interface DepartmentSelectProps {
  model: string
  id: string
  departments: List<any>
  onSelectDepartment: (department: any) => void
  style?: React.CSSProperties
  defaultValue?: React.ReactText
  groupedDepartments?: boolean
}

function deptElement(dept: Map<string, any>) {
  const departmentId = dept.get('id')
  return (
    <option key={`department-${departmentId}`} value={departmentId}>
      {dept.get('name')}
    </option>
  )
}

export const DepartmentSelect = ({
  model,
  id,
  departments,
  onSelectDepartment,
  style,
  defaultValue,
  groupedDepartments
}: DepartmentSelectProps) => (
  <Select expand>
    <Control.select
      model={model}
      id={id}
      style={style}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        onSelectDepartment(e.target.value)
      }}
      defaultValue={defaultValue}
    >
      <option value={''}>{i18next.t('instant.booking.automaticDepartment')}</option>
      {groupedDepartments ? (
        <>
          {departments.map((deptGroup, i) => (
            <optgroup key={i} label="–––––––––––––––––">
              {deptGroup.map(deptElement)}
            </optgroup>
          ))}
        </>
      ) : (
        departments.map(deptElement)
      )}
    </Control.select>
  </Select>
)
