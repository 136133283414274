import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { eventDataValue } from '@glow/entity-types'

export function EventComment(props: { data: AnyData }) {
  const text = eventDataValue(props.data as Record<string, unknown> | null, ['text'])

  if (!text) {
    return null
  }

  return <p>{text}</p>
}
