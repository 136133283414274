import {
  addPreAdviceEventToOrders,
  addScannedEventsToConsignments,
  addSortedEventsToConsignments,
  manuallyCollectConsignments,
  manuallyDeliverConsignments,
  manuallyDeviateConsignments,
  manuallyReturnConsignments,
  markNotArrivedAtDistributingTerminal
} from '../../../actions/creators/helpers'
import { EventType } from '../../../utils/consignmentEvent'
import { OrderedSet, Set } from 'immutable'
import { ConsignmentIdType, OrderIdType } from '../../../types/coreEntitiesTypes'
import { Dispatch } from 'redux'

const LM_RETURN_CODE = 'I1'
export const SYSTEMBOLAGET_ALYSTRA_ID = '10013405104'

export const dispatchManualOverrideEvent = (
  eventType: EventType,
  orderIds: OrderedSet<OrderIdType>,
  timestamp: string,
  formValues: {
    name: string
    deviationCode: string
    consignmentIds: Set<ConsignmentIdType>
    unitId: string
    driverUserId: string
    departmentId: string
  },
  dispatch: Dispatch<any>
) => {
  let req
  if (eventType === EventType.DELIVERED) {
    req = manuallyDeliverConsignments(
      formValues.consignmentIds,
      timestamp,
      formValues.unitId,
      formValues.driverUserId,
      formValues.name
    )(dispatch)
  } else if (eventType === EventType.DEVIATED) {
    req = manuallyDeviateConsignments(
      formValues.consignmentIds,
      timestamp,
      formValues.unitId,
      formValues.driverUserId,
      formValues.deviationCode
    )(dispatch)
  } else if (eventType === EventType.RETURNED) {
    req = manuallyReturnConsignments(
      formValues.consignmentIds,
      timestamp,
      formValues.unitId,
      formValues.driverUserId,
      LM_RETURN_CODE,
      formValues.name
    )(dispatch)
  } else if (eventType === EventType.COLLECTED) {
    req = manuallyCollectConsignments(
      formValues.consignmentIds,
      timestamp,
      formValues.unitId,
      formValues.driverUserId
    )(dispatch)
  } else if (eventType === EventType.SCANNED) {
    req = addScannedEventsToConsignments(
      formValues.consignmentIds,
      timestamp,
      Number(formValues.departmentId)
    )(dispatch)
  } else if (eventType === EventType.PRE_ADVISED) {
    req = addPreAdviceEventToOrders(orderIds, timestamp)(dispatch)
  } else if (eventType === EventType.ORDER_SORTED) {
    req = addSortedEventsToConsignments(formValues.consignmentIds, timestamp)(dispatch)
  } else if (eventType === EventType.NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL) {
    req = markNotArrivedAtDistributingTerminal(formValues.consignmentIds, timestamp)(dispatch)
  }
  return req
}
