import { AppStateType } from '../utils/appStateReduxStore'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'
import { Map, Set } from 'immutable'
import { DeliveryTimeWindow, DeliveryTimeWindowIdType } from '../domain/deliveryTimeWindow'
import { zipmapBy } from '../utils/collectionUtils'
import { CustomerIdType } from '../domain/customer'
import { stripWhiteSpace } from '../utils/stringUtils'

const deliveryTimeWindows: (state: AppStateType) => Map<DeliveryTimeWindowIdType, DeliveryTimeWindow> =
  createImmutableEqualSelector(
    (state: AppStateType) => state.getIn(['entities', 'deliveryWindows']),
    (
      deliveryTimeWindows: Map<DeliveryTimeWindowIdType, DeliveryTimeWindow>
    ): Map<DeliveryTimeWindowIdType, DeliveryTimeWindow> =>
      deliveryTimeWindows ? zipmapBy('id', deliveryTimeWindows.valueSeq()) : Map()
  )

export const deliveryTimeWindowsForCustomerAndPostalCode: (
  state: AppStateType,
  customerId: CustomerIdType,
  postalCode: string,
  serviceCode: string
) => Map<DeliveryTimeWindowIdType, DeliveryTimeWindow> = createImmutableEqualSelector(
  deliveryTimeWindows,
  (state: AppStateType, customerId: CustomerIdType) => customerId,
  (state: AppStateType, customerId: CustomerIdType, postalCode: string) => postalCode,
  (state: AppStateType, customerId: CustomerIdType, postalCode: string, serviceCode: string) => serviceCode,
  (
    deliveryTimeWindows: Map<DeliveryTimeWindowIdType, DeliveryTimeWindow>,
    customerId: CustomerIdType,
    postalCode: string,
    serviceCode: string
  ) => {
    return deliveryTimeWindows
      ? deliveryTimeWindows
          .filter(
            (it) =>
              it.get('customerId') === customerId &&
              stripWhiteSpace(it.get('postalCode')) === stripWhiteSpace(postalCode) &&
              (!it.get('serviceCode') || stripWhiteSpace(it.get('serviceCode')) === stripWhiteSpace(serviceCode))
          )
          .sortBy((it) => it.get('timeFrom'))
      : Map<DeliveryTimeWindowIdType, DeliveryTimeWindow>()
  }
)

/**
 * Returns a set of zero indexed weekdays starting on monday
 */
export const deliveryDaysForCustomerAndPostalCode: (
  state: AppStateType,
  customerId: CustomerIdType,
  postalCode: string,
  serviceCode: string
) => Set<number> = createImmutableEqualSelector(
  deliveryTimeWindowsForCustomerAndPostalCode,
  (state: AppStateType, customerId: CustomerIdType, postalCode: string) => postalCode,
  (state: AppStateType, customerId: CustomerIdType, postalCode: string, serviceCode: string) => serviceCode,
  (deliveryTimeWindows: Map<DeliveryTimeWindowIdType, DeliveryTimeWindow>) =>
    deliveryTimeWindows
      ? deliveryTimeWindows
          .map((it) => it.get('dayOfWeek'))
          .toSet()
          .sort()
      : Set<number>()
)
