import styled from 'styled-components'
import vars from '../styles/variables'

export const SuggestionsContainer = styled.div<{ show?: boolean }>`
  background: white;
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  border: 1px solid ${vars.colors.gray5};
  width: auto;
  z-index: 999;
  border-radius: 0 0 5px 5px;
  border-top: 0;
  max-height: calc(100vh - 52px);
  overflow-y: auto;
`
