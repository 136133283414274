import { eventDataValue } from '@glow/entity-types'
import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'

export function EventNotification(props: { data: AnyData }) {
  const reason = eventDataValue(props.data as Record<string, unknown> | null, ['reason'])

  if (!reason) {
    return null
  }

  return <p>{reason}</p>
}
