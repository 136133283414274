import { Collection, List, Map, OrderedMap, Seq, Set } from 'immutable'
import { RouteReceipt, RouteReceiptProps } from '../types/hdRouteTypes'
import { Consignment, ConsignmentIdType, OrderIdType, RoutePoint, Shipment, Slot } from '../types/coreEntitiesTypes'
import { convert, MeasurementUnit } from '../domain/measurementUnits'
import { partitionBy } from './collectionUtils'
import { hourMinute, toLocaleDate } from './dateTime'
import { DateTime } from 'luxon'
import { sortByName } from '../pages/instant/bookingOrderFunctions'
import { isReturnService } from './hdUtils'
import { ImmutableMapFactory } from '../types/immutableTypes'
import { getServiceText, getVasText, GroupedServicesAndVasesTexts } from './serviceUtils'
import { getTotalVolumeValue, getTotalWeightValue, getUniqueConsignmentsPerOrderId } from './shipmentsUtils'
import { LoadList, LoadListProps } from '../pages/planner/planning2/Planning2LoadList'

type DeliveryByConsignmentIdType = Seq.Keyed<ConsignmentIdType, Collection<ConsignmentIdType, Map<string, any>>>
export type RoutePointsByConsignmentIdType = Map<ConsignmentIdType, Collection<ConsignmentIdType, RoutePoint>>

export const getHomeDeliveryRoutes = (
  consignments: Map<ConsignmentIdType, Consignment>,
  routePointsByConsignmentId: RoutePointsByConsignmentIdType,
  servicesAndVasesTexts: GroupedServicesAndVasesTexts
): List<RouteReceipt> =>
  getWaypointsToPrintHDRoutes(consignments, routePointsByConsignmentId, servicesAndVasesTexts).flatMap((waypoint) =>
    waypoint
      .groupBy((it: Map<string, any>) => it.get('orderId'))
      .map((it: Collection<OrderIdType, Map<string, any>>) => mapToReceipt(it.first<Map<string, any>>()))
      .toList()
  )

const mapToReceipt = (route: Map<string, any>): RouteReceipt =>
  ImmutableMapFactory<RouteReceiptProps>({
    pickupName: route.get('pickupName'),
    deliveryName: route.get('deliveryName'),
    noOfPackages: route.get('noOfPackages'),
    weight: route.get('weight'),
    volume: route.get('volume'),
    shipmentId: route.get('shipmentId'),
    customerRef: route.get('customerRef'),
    serviceCode: route.get('serviceCode'),
    vasCodes: route.get('vasCodes'),
    deliveryPhone: route.get('deliveryPhone'),
    pickupPhone: route.get('pickupPhone'),
    instructions: route.get('instructions'),
    pickupAddress: route.get('pickupAddress'),
    pickupZipArea: route.get('pickupZipArea'),
    pickupZipCode: route.get('pickupZipCode'),
    pickupTimeTo: route.get('pickupTimeTo'),
    pickupTimeFrom: route.get('pickupTimeFrom'),
    deliveryAddress: route.get('deliveryAddress'),
    deliveryZipArea: route.get('deliveryZipArea'),
    deliveryZipCode: route.get('deliveryZipCode'),
    timeFrom: route.get('timeFrom'),
    timeTo: route.get('timeTo'),
    dateEarliest: route.get('dateEarliest'),
    slotName: route.get('slotName'),
    isReturnService: route.get('isReturnService'),
    slotId: route.get('slotId'),
    courierId: route.get('courierId')
  })

export const sortPickupsByArrivalTime = (
  pickupRoutes: List<Map<string, any>>,
  deliveryByConsignmentId: DeliveryByConsignmentIdType
): List<Map<string, any>> =>
  !pickupRoutes.isEmpty()
    ? pickupRoutes
        .sortBy(
          (it: Map<string, any>) =>
            deliveryByConsignmentId.get(it.get('consignmentId'))?.first<Map<string, any>>()?.get('arrivalTime') ||
            it.get('arrivalTime')
        )
        .reverse()
    : pickupRoutes

export const getWaypointsToPrintHDRoutes = (
  consignments: Map<ConsignmentIdType, Consignment>,
  routePointsByConsignmentId: RoutePointsByConsignmentIdType,
  servicesAndVasesTexts: GroupedServicesAndVasesTexts
): List<List<Map<string, any>>> => {
  const routePoints = Set.fromKeys<ConsignmentIdType>(consignments)
    .map((it: number) => routePointsByConsignmentId.get(it))
    .flatten(true)
    .toList() as List<RoutePoint>

  return partitionBy(
    routePoints
      .filter((routePoint: RoutePoint) => routePoint?.get('type') === 'delivery')
      .sortBy((it) => toLocaleDate(it.get('arrival')))
      .map((routePoint: RoutePoint) => {
        const consignment: Consignment = consignments.get(routePoint.get('consignmentId')) || Map()
        const consignmentsForOrderId = consignments.filter((cons) => cons.get('orderId') === consignment.get('orderId'))
        return getConsignmentRouteData(consignmentsForOrderId, consignment, servicesAndVasesTexts)
      }),
    (it: Map<string, any>) => it.get('equalityKey')
  )
}

const getConsignmentRouteData = (
  consignmentsForOrderId: Collection<ConsignmentIdType, Consignment>,
  consignment: Consignment,
  servicesAndVasesTexts: GroupedServicesAndVasesTexts
): Map<string, any> => {
  const deliveryAddress = consignment.get('deliveryAddress')
  const deliveryZipCode = consignment.get('deliveryZipCode')
  const deliveryZipArea = consignment.get('deliveryZipArea')
  const deliveryCountry = consignment.get('deliveryCountry')
  const dateTimeEarliest = DateTime.fromISO(consignment.get('deliveryTimeEarliest', ''))
  const serviceCode = consignment.get('serviceCode')
  const consignmentsPerOrderId = getUniqueConsignmentsPerOrderId(consignmentsForOrderId)

  return Map({
    orderId: consignment.get('orderId'),
    pickupName: consignment.get('pickupName'),
    deliveryName: consignment.get('deliveryName'),
    weight: getTotalWeightValue(consignment, consignmentsPerOrderId, MeasurementUnit.KGM),
    volume: getTotalVolumeValue(consignment, consignmentsPerOrderId, MeasurementUnit.MTQ),
    shipmentId: consignment.get('shipmentId'),
    customerRef: consignment.get('customerRef', ''),
    serviceCode: getServiceText(servicesAndVasesTexts, serviceCode, consignment.get('custAlystraId')),
    vasCodes:
      consignment
        .get('vasCodes')
        ?.map((vasCode: string) => getVasText(servicesAndVasesTexts, vasCode, consignment.get('custAlystraId'))) ||
      List(),
    pickupAddress: consignment.get('pickupAddress'),
    pickupZipCode: consignment.get('pickupZipCode'),
    pickupZipArea: consignment.get('pickupZipArea'),
    pickupTimeFrom: hourMinute(consignment.get('pickupTimeEarliest')),
    pickupTimeTo: hourMinute(consignment.get('pickupTimeLatest')),
    deliveryAddress,
    deliveryZipCode,
    deliveryZipArea,
    deliveryPhone: consignment.get('deliveryPhone', ''),
    pickupPhone: consignment.get('pickupPhone', ''),
    instructions: consignment.get('deliveryInstructions'),
    timeFrom: hourMinute(consignment.get('deliveryTimeEarliest')),
    timeTo: hourMinute(consignment.get('deliveryTimeLatest')),
    dateEarliest: dateTimeEarliest.isValid ? dateTimeEarliest.toISODate() : '',
    slotName: consignment.get('slotName'),
    isReturnService: isReturnService(serviceCode),
    noOfPackages: consignmentsPerOrderId.count(),
    slotId: consignment.get('slotId'),
    courierId: consignment.get('courierId'),
    equalityKey: [deliveryAddress, deliveryZipCode, deliveryZipArea, deliveryCountry]
      .map((it) => it && it.toLowerCase())
      .join('-')
  })
}

export const sortedSlotsGroupedByCreatorName = (slots: List<Slot>): OrderedMap<string, Collection<number, Slot>> => {
  let slotsWithCreatedByName = OrderedMap<string, Collection<number, Slot>>()
  slots
    .groupBy((s) => s.get('createdByName'))
    .map((groupedSlots) => groupedSlots.sort(sortByName))
    .forEach((slot, createdByName) => (slotsWithCreatedByName = slotsWithCreatedByName.set(createdByName || '', slot)))
  return slotsWithCreatedByName.sortBy((_, createdByName) => createdByName)
}

export const getRouteReceipts = (
  consignments: Seq.Indexed<Consignment>,
  servicesAndVasesTexts: GroupedServicesAndVasesTexts
): List<RouteReceipt> =>
  consignments
    .groupBy((it) => it.get('orderId'))
    .map((consignmentsPerOrderId) => {
      const routeData = getConsignmentRouteData(
        consignmentsPerOrderId,
        consignmentsPerOrderId.first(),
        servicesAndVasesTexts
      )
      return mapToReceipt(routeData)
    })
    .toList()

export const getHomeDeliveryLoadList = (
  shipments: List<Shipment>,
  routePoints: RoutePointsByConsignmentIdType
): List<LoadList> => {
  return shipments
    .map((shipment) => {
      const serviceCode = shipment.get('serviceCode')
      const isReturn = isReturnService(serviceCode)
      const routePointsForShipment =
        routePoints.get(shipment.get('consignmentIds').first())?.valueSeq().toList() || List<RoutePoint>()
      const pickupRoutePoint = routePointsForShipment
        .filter((rp) => rp.get('type') === (isReturn ? 'delivery' : 'pickup'))
        .first(Map() as RoutePoint)
      const deliveryRoutePoint = routePointsForShipment
        .filter((rp) => rp.get('type') === (isReturn ? 'pickup' : 'delivery'))
        ?.first(Map() as RoutePoint)

      return ImmutableMapFactory<LoadListProps>({
        address: isReturn ? shipment.get('pickupAddress') : shipment.get('deliveryAddress'),
        customerRef: shipment.get('customerRef'),
        estimatedArrivalTime: hourMinute(deliveryRoutePoint.get('arrival')),
        estimatedLoadTime: isReturn ? '' : hourMinute(pickupRoutePoint.get('arrival')) || '',
        id: shipment.get('id'),
        instructions: shipment.get('deliveryInstructions'),
        name: isReturn ? shipment.get('pickupName') : shipment.get('deliveryName'),
        numberOfPackages: shipment.get('noOfPackages'),
        phoneNumber: isReturn ? shipment.get('pickupPhone') : shipment.get('deliveryPhone'),
        recipientRef: shipment.get('recipientRef'),
        serviceCode: shipment.get('serviceCode'),
        sortByTime: deliveryRoutePoint.get('arrival'),
        start: hourMinute(isReturn ? shipment.get('pickupTimeEarliest') : shipment.get('deliveryTimeEarliest')),
        stop: hourMinute(isReturn ? shipment.get('deliveryTimeLatest') : shipment.get('deliveryTimeLatest')),
        totalVolume:
          convert(shipment.get('totalVolumeUnit'), MeasurementUnit.MTQ, shipment.get('totalVolumeValue')) || 0,
        totalVolumeUnit: MeasurementUnit.MTQ.valueOf(),
        totalWeight: shipment.get('totalWeightValue'),
        totalWeightUnit: shipment.get('totalWeightUnit'),
        tripNumber: pickupRoutePoint.get('tripNumber'),
        vases: shipment.get('vasCodes')?.join(', ') || '',
        zipArea: isReturn ? shipment.get('pickupZipArea') : shipment.get('deliveryZipArea'),
        zipCode: isReturn ? shipment.get('pickupZipCode') : shipment.get('deliveryZipCode'),
        endTime: isReturn ? pickupRoutePoint.get('endTime') : deliveryRoutePoint?.get('endTime')
      })
    })
    .sortBy((it) => it.get('sortByTime'))
    .map((value, index) => value.setIn(['stopOrder'], index + 1))
}
