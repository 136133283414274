import React from 'react'
import { format24HourTime, toLocaleDate } from '../../../../utils/dateTime'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { eventDataValue } from '@glow/entity-types'

export const DeliveryTimeEstimate = (props: { data: AnyData }): React.ReactElement => {
  const adjustedTimeFrom = eventDataValue(props.data, ['adjustedTimeFrom'])
  const adjustedTimeTo = eventDataValue(props.data, ['adjustedTimeTo'])

  return (
    <>
      {adjustedTimeFrom &&
        adjustedTimeTo &&
        `${format24HourTime(toLocaleDate(adjustedTimeFrom as string))} - ${format24HourTime(
          toLocaleDate(adjustedTimeTo as string)
        )}`}
    </>
  )
}
