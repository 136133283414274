import React from 'react'
import { InfoWindow, Marker } from '@react-google-maps/api'

const couldChange = ['lat', 'lng', 'icon', 'display', 'colors']

interface Props {
  id: string
  ids: string[]
  key: string
  lat: number
  lng: number
  icon: any
  colors: string[]
  display: string
  heading: string
  zIndex: number
  interactive: boolean
  information: any[]
  title: string
  clusterer: any
}
interface State {
  isOpen: boolean
}
export class MarkerWithInfoWindow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { isOpen: false }
  }

  onToggleOpen() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  shouldComponentUpdate(newProps: Props, newState: State) {
    if (this.state.isOpen !== newState.isOpen) {
      return true
    }

    if (newProps.ids.length !== this.props.ids.length) {
      return true
    } else if (newProps.ids.length > 0) {
      for (let i = 0; i < newProps.ids.length; i++) {
        if (newProps.ids[i] !== this.props.ids[i]) {
          return true
        }
      }
    }

    for (let key of couldChange) {
      // @ts-ignore
      if (this.props[key] !== newProps[key]) {
        return true
      }
    }

    return false
  }

  render() {
    const { id, lat, lng, colors, display, heading, zIndex, information, interactive, icon, title, clusterer } =
      this.props

    let cumulativePercent = 0
    const slicePercent = 1 / colors.length

    const slices = colors.map((color) => {
      const [startX, startY] = getCoordinatesForPercent(cumulativePercent)
      cumulativePercent += slicePercent
      const [endX, endY] = getCoordinatesForPercent(cumulativePercent)
      const largeArcFlag = slicePercent > 0.5 ? 1 : 0
      const pathData = [
        `M ${startX} ${startY}`, // Move
        `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
        'L 0 0' // Line
      ].join(' ')
      return markerSvgPieSlice.replace('{{color}}', color).replace('{{pathData}}', pathData)
    })

    const d = colors.length > 1 ? colors.length : display

    const defaultIcon = {
      url:
        'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(markerSvg.replace('{{slices}}', slices as any).replace('{{display}}', d as any)),
      anchor: { x: 16, y: 16 }
    }

    const pos = {
      lat: lat,
      lng: lng
    }

    return (
      <Marker
        title={title}
        key={id}
        onClick={() => this.onToggleOpen()}
        position={pos}
        zIndex={zIndex}
        icon={icon || defaultIcon}
        clusterer={clusterer}
      >
        {interactive && (heading || information.length > 0) && this.state.isOpen && (
          <InfoWindow
            position={pos}
            options={
              {
                visible: true,
                enableEventPropagation: false,
                closeBoxMargin: '2px',
                pane: 'overlayLayer'
              } as any
            }
            onCloseClick={() => this.onToggleOpen()}
          >
            <div>
              {heading && <h3 style={{ marginTop: 0 }}>{heading}</h3>}
              <div
                style={{
                  maxHeight: '300px',
                  overflowY: 'auto'
                }}
              >
                {information.map((i) => i())}
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
    )
  }
}

function getCoordinatesForPercent(percent: number) {
  const x = Math.cos(2 * Math.PI * percent)
  const y = Math.sin(2 * Math.PI * percent)
  return [x, y]
}

const markerSvg = `
  <svg width="32" height="32" viewBox="-1.2 -1.2 2.4 2.4" xmlns="http://www.w3.org/2000/svg">
  <circle r="1" cx="0" cy="0" fill="none" stroke="white" stroke-width="0.4"/>
  {{slices}}
  <text fill="white" font-family="Verdana" font-size="1.4" textLength="1" lengthAdjust="spacingAndGlyphs" x="0" y="0.1" alignment-baseline="middle" text-anchor="middle">{{display}}</text>
  </svg>
`

const markerSvgPieSlice = '<path d="{{pathData}}" fill="{{color}}" />'
