import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { ConsignmentEventConfirmed } from '../../../../components/ConsignmentEventConfirmed'
import { IConsignmentEvent } from '../../../../types/coreEntitiesTypes'
import { ImmutableMap } from '../../../../types/immutableTypes'
import { EventType } from '../../../../utils/consignmentEvent'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

interface Props {
  consignmentEvent: ImmutableMap<IConsignmentEvent>
  data: AnyData
}

export const DeliveryLocationDeviationMessage = ({ consignmentEvent }: Props): React.ReactElement => {
  return (
    <p>
      <DeliveryLocationDeviationHelpMessage
        data={consignmentEvent.get('data')}
        shipmentId={null}
        courierName={consignmentEvent.get('data')?.get('driverName')}
      />
      <ConsignmentEventConfirmed
        consignmentEventId={consignmentEvent.get('id')}
        consignmentEventType={EventType.DELIVERY_LOCATION_DEVIATION}
      />
    </p>
  )
}

export const DeliveryLocationDeviationHelpMessage = ({ data, courierName, shipmentId }: AnyData) => {
  const actualLat = data.getIn(['actualLocation', 'latitude'])
  const actualLon = data.getIn(['actualLocation', 'longitude'])
  const actualCoordinates = `${actualLat},${actualLon}`
  const expectedLat = data.getIn(['expectedLocation', 'latitude'])
  const expectedLon = data.getIn(['expectedLocation', 'longitude'])
  const expectedCoordinates = `${expectedLat},${expectedLon}`
  const distance = Math.round(data.getIn(['distance']))
  const name = formatName(courierName)

  let translationKey = shipmentId
    ? 'live.eventHelpDeliveryLocationDeviation.withShipmentId'
    : 'live.eventHelpDeliveryLocationDeviation.withoutShipmentId'

  return (
    <div>
      <Trans i18nKey={translationKey}>
        {{ name }} has delivered shipment number {{ shipmentId }}{' '}
        <a href={'https://www.google.com/maps/place/' + actualCoordinates} target="_blank" rel="noreferrer">
          here
        </a>
        , {{ distance }} meters away from the{' '}
        <a href={'https://www.google.com/maps/place/' + expectedCoordinates} target="_blank" rel="noreferrer">
          expected location
        </a>
        .
      </Trans>
    </div>
  )
}

function formatName(name?: string) {
  return name ?? i18next.t('live.theCourier')
}
