import i18next from 'i18next'
import { formatQuantity, Quantity } from '../../shared/Shipment'
import React from 'react'
import { Consignment } from '../../../../types/coreEntitiesTypes'
import { DescriptionList, TermDescriptionGroup } from '../../../DescriptionList'
import styled from 'styled-components'
import vars from '../../../../styles/variables'
import { FlexDiv } from '../../../../primitives/Layout'

export const ConsignmentBox: React.FC<{
  consignment: Consignment
  consignmentNumber: number
  consignmentsCount: number
}> = ({ consignment, consignmentNumber, consignmentsCount }) => (
  <Box>
    <IdAndSequenceNumber
      consignment={consignment}
      consignmentNumber={consignmentNumber}
      consignmentsCount={consignmentsCount}
    />
    <DescriptionList flex>
      <TermDescriptionGroup
        noMargin
        rightMargin
        oneLiner
        term={i18next.t('homeDeliveryRouteList.weight')}
        description={formatQuantity({
          value: consignment.get('weightValue'),
          unit: consignment.get('weightUnit')
        } as Quantity)}
      />
      <TermDescriptionGroup
        noMargin
        rightMargin
        oneLiner
        term={i18next.t('homeDeliveryRouteList.volume')}
        description={formatQuantity({
          value: consignment.get('volumeValue'),
          unit: consignment.get('volumeUnit')
        } as Quantity)}
      />
      {consignment.get('loadingMetersValue') && (
        <TermDescriptionGroup
          noMargin
          rightMargin
          oneLiner
          term={i18next.t('homeDeliveryRouteList.loadingMeters')}
          description={formatQuantity({
            value: consignment.get('loadingMetersValue'),
            unit: consignment.get('loadingMetersUnit')
          } as Quantity)}
        />
      )}
      {consignment.get('palletFootPrintsValue') && (
        <TermDescriptionGroup
          noMargin
          rightMargin
          oneLiner
          term={i18next.t('homeDeliveryRouteList.palletFootPrints')}
          description={formatQuantity({
            value: consignment.get('palletFootPrintsValue'),
            unit: consignment.get('palletFootPrintsUnit')
          } as Quantity)}
        />
      )}
      <TermDescriptionGroup
        noMargin
        ellipsis
        term={i18next.t('consignment.pickupLocation')}
        description={consignment.get('location')}
      />
    </DescriptionList>
    <DescriptionList>
      <TermDescriptionGroup
        noMargin
        term={i18next.t('shipment.goodsInfo')}
        description={consignment.get('specificationDescription')}
      />
    </DescriptionList>
  </Box>
)

const Box = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1.25rem;
  border: 0.5px solid ${vars.newColors.lightGrey};
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0.625rem 0;
  padding: 1.25rem;
`

const IdAndSequenceNumber: React.FC<{
  consignment: Consignment
  consignmentNumber: number
  consignmentsCount: number
}> = ({ consignment, consignmentNumber, consignmentsCount }) => (
  <FlexDiv spaceBetween noMargin>
    <BigText fontWeight={600}>
      {i18next.t('consignment.packageId')}&#58;&nbsp;{consignment.get('packageId')}
    </BigText>
    <BigText fontWeight={400}>{`${consignmentNumber}/${consignmentsCount}`}</BigText>
  </FlexDiv>
)

const BigText = styled.p<{ fontWeight: number }>`
  font-size: 1rem;
  font-weight: ${(props) => props.fontWeight};
  line-height: 1.375rem;
  color: ${vars.newColors.black};
  margin-bottom: 0;
`
