import React from 'react'
import ImmutableComponent from '../primitives/ImmutableComponent'
import { DateTime } from 'luxon'
import { closeDrawer, openDrawer, openModal, setPageStateValue } from '../actions/creators/helpers'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { ShipmentInfo } from './ShipmentInfo'
import { GridBox } from '../primitives/customer/GridContent'
import { AppStateType } from '../utils/appStateReduxStore'
import { Consignment } from '../types/coreEntitiesTypes'
import { AnyData } from '../actions/creators/baseHelpers'

type Props = {
  consignment: Consignment
  shipmentId: number
  consignmentId: number
  role: string
  openModal: (modalClass: any, props: AnyData) => void
  openDrawer: (drawerClass: string | React.ElementType, props: AnyData) => void
  closeDrawer: () => void
  setPageStateValue: (key: string, value: any) => void
}
class CustomerDeliveryInformation extends ImmutableComponent<Props> {
  render() {
    const { consignment } = this.props
    const pickupTimeEarliest = consignment.get('pickupTimeEarliestCached')
    const pickupTileLatest = consignment.get('pickupTimeLatestCached')
    const dateTimeEarliest = consignment.get('deliveryTimeEarliestCached')
    const dateTimeLatest = consignment.get('deliveryTimeLatestCached')

    const onClick = () => {
      const prevId = this.props.shipmentId
      const id = consignment.get('orderId')
      if (id !== null && id !== prevId) {
        this.props.openDrawer(ShipmentInfo, { shipmentId: id })
        this.props.setPageStateValue('shipmentId', id)
      } else {
        this.props.closeDrawer()
        this.props.setPageStateValue('shipmentId', null)
      }
    }

    return (
      <GridBox style={{ cursor: 'pointer', border: '1px solid', margin: '5px', padding: '5px' }} onClick={onClick}>
        <strong>{i18next.t('planner.delivery')}</strong>
        <br />
        {dateTimeEarliest.toLocaleString(DateTime.TIME_24_SIMPLE)} -{' '}
        {dateTimeLatest.toLocaleString(DateTime.TIME_24_SIMPLE)}
        <br />({i18next.t('planner.pickup')}: {consignment.get('pickupAddress')},{' '}
        {pickupTimeEarliest.toLocaleString(DateTime.TIME_24_SIMPLE)} -{' '}
        {pickupTileLatest.toLocaleString(DateTime.TIME_24_SIMPLE)})
      </GridBox>
    )
  }
}

export default connect(
  (state: AppStateType) => {
    return {
      role: state.getIn(['user', 'role']),
      consignmentId: state.getIn(['pageState', 'consignmentId']),
      shipmentId: state.getIn(['pageState', 'shipmentId'])
    }
  },
  { openModal, openDrawer, closeDrawer, setPageStateValue }
)(CustomerDeliveryInformation)
