import ImmutableComponent from '../../primitives/ImmutableComponent'
import { DeliveryTimeWindow, DeliveryTimeWindowIdType } from '../../domain/deliveryTimeWindow'
import { Map } from 'immutable'
import { ToggleButton } from '../../primitives/Button'
import * as React from 'react'
import { isNotEmpty } from '../../utils/collectionUtils'

export class SelectDeliveryTimeWindow extends ImmutableComponent<Props> {
  render() {
    return (
      <>
        {isNotEmpty(this.props.timeWindows) && (
          <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '0.25rem' }}>
            {this.props.timeWindows.valueSeq().map((timeWindow: DeliveryTimeWindow) => {
              return (
                <ToggleButton
                  key={timeWindow.get('id')}
                  marginLeft
                  onClick={() => this.props.onTimeWindowChange(timeWindow.get('id'))}
                  selected={this.props.timeWindowId === timeWindow.get('id')}
                  type="button"
                >
                  <span>
                    {timeWindow.get('timeFrom')} - {timeWindow.get('timeTo')}
                  </span>
                </ToggleButton>
              )
            })}
          </div>
        )}
      </>
    )
  }
}

interface Props {
  timeWindowId: DeliveryTimeWindowIdType
  timeWindows: Map<DeliveryTimeWindowIdType, DeliveryTimeWindow>
  onTimeWindowChange: (timeWindowId: DeliveryTimeWindowIdType) => void
}
