import i18next from 'i18next'
import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'

export const DeliveryIdCheck = ({ data }: { data: AnyData }): React.ReactElement => {
  const idCheckFailedResult = data && data.idCheckFailedResult
  const idCheckType = data && data.idCheckType
  const isIdCheckTypeOther = idCheckType === 'other'
  const birthDate = data && data.birthDate

  return (
    <>
      {idCheckFailedResult ? (
        i18next.t('consignmentEvent.idCheck.idCheckFailed', {
          idCheckFailedResult
        })
      ) : (
        <span>
          {isIdCheckTypeOther
            ? i18next.t('consignmentEvent.idCheck.idCheckSummaryOther', {
                otherType: data && data.idCheckOtherType,
                birthDate
              })
            : i18next.t('consignmentEvent.idCheck.idCheckSummary', {
                idCheckType,
                birthDate
              })}
        </span>
      )}
    </>
  )
}
