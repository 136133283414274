import { Option } from '@glow/ui-components'
import i18next from 'i18next'
import { Collection, List, Map } from 'immutable'
import { AnyData } from '../actions/creators/baseHelpers'
import { PartialConsignmentMap } from '../pages/shipments/details-page/ManualOverrideView'
import { Consignment, ConsignmentEvent, ConsignmentEventId, ConsignmentIdType } from '../types/coreEntitiesTypes'
import { ImmutableMap } from '../types/immutableTypes'
import { deviatedConsignmentEvents } from './consignmentEvent'
import { ConsignmentState } from './consignmentState'

export interface DeviationCodeLabel
  extends ImmutableMap<{
    code: string
    label: string
    duplicateDescription: string
  }> {}

export const deliveryDeviationCodes: List<DeviationCodeLabel> = List([
  Map({ code: 'H-12', label: 'courier.deviateNotAtHomeButtonLabel' }),
  Map({ code: 'H-11', label: 'courier.deviateNotAtHomeButtonLabel', duplicateDescription: 'true' }),
  Map({ code: 'H-10', label: 'courier.deviateLocNotFoundButtonLabel' }),
  Map({ code: 'H-13', label: 'courier.deviateRecipientDeclinedButtonLabel' }),
  Map({ code: 'H-07-R', label: 'courier.failedSocialControl' }),
  Map({ code: 'H-07-Z', label: 'courier.failedSocialControl', duplicateDescription: 'true' }),
  Map({ code: 'H-09-Z', label: 'courier.failedIdControl' }),
  Map({ code: 'H-08-Z', label: 'courier.failedAgeControl' }),
  Map({ code: 'VI-16-X', label: 'courier.deviateTimeWindow' }),
  Map({ code: 'H-11-Z', label: 'courier.addressNotFound' }),
  Map({ code: 'H-BL-A', label: 'courier.deliverySpotUnavailable' }),
  Map({ code: 'V-100-CI', label: 'courier.noAvailableHatchInParcelBoxMachine' }),
  Map({ code: 'V-80-CI', label: 'courier.packageDoesNotFitInParcelBox' }),
  Map({ code: 'V-99-CI', label: 'courier.unknownErrorForParcelBoxDelivery' }),
  Map({ code: 'V-AO-CI', label: 'courier.errorInParcelBoxMachine' })
])
export const deliveryWithoutParcelDeviationCodes: List<DeviationCodeLabel> = List([
  Map({ code: 'H-12', label: 'courier.deviateNotAtHomeButtonLabel' }),
  Map({ code: 'H-11', label: 'courier.deviateNotAtHomeButtonLabel', duplicateDescription: 'true' }),
  Map({ code: 'H-10', label: 'courier.deviateLocNotFoundButtonLabel' }),
  Map({ code: 'H-13', label: 'courier.deviateRecipientDeclinedButtonLabel' }),
  Map({ code: 'H-07-R', label: 'courier.failedSocialControl' }),
  Map({ code: 'H-07-Z', label: 'courier.failedSocialControl', duplicateDescription: 'true' }),
  Map({ code: 'H-09-Z', label: 'courier.failedIdControl' }),
  Map({ code: 'H-08-Z', label: 'courier.failedAgeControl' }),
  Map({ code: 'VI-16-X', label: 'courier.deviateTimeWindow' }),
  Map({ code: 'H-11-Z', label: 'courier.addressNotFound' }),
  Map({ code: 'H-BL-A', label: 'courier.deliverySpotUnavailable' })
])
export const deliveryParcelDeviationCodes: List<DeviationCodeLabel> = List([
  Map({ code: 'V-100-CI', label: 'courier.noAvailableHatchInParcelBoxMachine' }),
  Map({ code: 'V-80-CI', label: 'courier.packageDoesNotFitInParcelBox' }),
  Map({ code: 'V-99-CI', label: 'courier.unknownErrorForParcelBoxDelivery' }),
  Map({ code: 'V-AO-CI', label: 'courier.errorInParcelBoxMachine' })
])

export const pickupDeviationCodes: List<DeviationCodeLabel> = List([
  Map({ code: '7-10', label: 'courier.incompletePickupAddress' }),
  Map({ code: '7-12', label: 'courier.custNotAvailableAtPickupAddress' }),
  Map({ code: '7-82', label: 'courier.goodsNotReadyAtPickupForCust' }),
  Map({ code: 'V-41-K', label: 'courier.shipmentNotAvailableAtTerminal' })
])

export const myBookingPickupDeviationCodes: List<DeviationCodeLabel> = List([
  Map({ code: 'A-10', label: 'courier.incompletePickupAddress' }),
  Map({ code: 'A-12', label: 'courier.custNotAvailableAtPickupAddress' }),
  Map({ code: 'A-82', label: 'courier.goodsNotReadyAtPickupForCust' })
])

export const isDeliveryDeviated = (
  consignmentId: ConsignmentIdType,
  consignmentEvents: Map<ConsignmentIdType, Collection<ConsignmentEventId, ConsignmentEvent>>,
  state: string
) => {
  if (state !== ConsignmentState.DEVIATED) return false
  const deviatedEventForConsignmentId = consignmentEvents
    .get(consignmentId)
    ?.sortBy((consEvent) => consEvent.get('eventTime'))
    ?.findLast(isDeviationEvent)
  return deviatedEventForConsignmentId ? hasDeliveryDeviationCode(deviatedEventForConsignmentId) : false
}

export const isDeliveryDeviation = (event: ConsignmentEvent): boolean => {
  return isDeviationEvent(event) && hasDeliveryDeviationCode(event)
}

export const isPickupDeviation = (event: ConsignmentEvent): boolean => {
  const deviationCode = event.getIn(['data', 'deviation'], '')
  return isDeviationEvent(event) && pickupDeviationCodes.map((devCode) => devCode.get('code')).contains(deviationCode)
}

export const hasDeliveryDeviationCode = (event: ConsignmentEvent): boolean => {
  const deviationCode = event.getIn(['data', 'deviation'], '')
  return deviationCode
    ? deliveryDeviationCodes.map((devCode) => devCode.get('code')).contains(deviationCode) ||
        deviationCode.startsWith('H')
    : false
}

export const isDeviationEvent = (event: ConsignmentEvent): boolean =>
  deviatedConsignmentEvents.contains(event.get('type'))

export const getDeviationText = (
  customerUniqueDeviation: AnyData | null,
  deviationCode: string | null | undefined
): string => {
  const currentLng = i18next.language.charAt(0).toUpperCase() + i18next.language.charAt(1)
  const courierDeviationKey = 'courier.deviation.' + deviationCode
  const description = ': ' + i18next.t(courierDeviationKey)
  return customerUniqueDeviation
    ? `${customerUniqueDeviation.get('deviationCode')}: ` +
        (customerUniqueDeviation.get('description' + currentLng) || '')
    : deviationCode && i18next.exists(courierDeviationKey)
      ? `${deviationCode}` + description
      : deviationCode
        ? deviationCode
        : ''
}

const isPickupOrder = (consignments: List<PartialConsignmentMap>) =>
  consignments.some((consignment) => consignment.get('type') === 'Pickup')

const generateOptions = (deviationCodes: List<DeviationCodeLabel>, displayParcelDeviations: boolean): Option[] => [
  { label: 'Pickup', type: 'header', code: '' },
  ...deviationCodes.map((pickupDeviation) => ({
    code: pickupDeviation.get('code'),
    label: i18next.t(pickupDeviation.get('label'))
  })),
  { label: 'Delivery', type: 'header', code: '' },
  ...deliveryWithoutParcelDeviationCodes
    .concat(displayParcelDeviations ? deliveryParcelDeviationCodes : [])
    .filter((code) => !code.get('duplicateDescription'))
    .map((deliveryDeviation) => ({
      code: deliveryDeviation.get('code'),
      label: i18next.t(deliveryDeviation.get('label'))
    }))
]

const PARCEL_DEVIATION_VAS_CODE = '1358'

export const shouldDisplayParcelDeviations = (consignments: List<PartialConsignmentMap> | List<Consignment>) =>
  consignments.some(
    (consignment) =>
      (consignment.get('vasCodes')?.includes?.(PARCEL_DEVIATION_VAS_CODE) ?? false) && consignment.get('type') === 'H2'
  )

export const selectDeviationOptions = (consignments: List<PartialConsignmentMap>): Option[] => {
  const displayParcelDeviations = shouldDisplayParcelDeviations(consignments)
  if (isPickupOrder(consignments)) {
    return generateOptions(myBookingPickupDeviationCodes, displayParcelDeviations)
  }
  return generateOptions(pickupDeviationCodes, displayParcelDeviations)
}
