import { ImmutableMap, isNullOrUndefined } from '../types/immutableTypes'
import { Collection, Map } from 'immutable'
import { ConsignmentsByOrderIdType } from '../selectors/shipmentSelectors'
import { CustomerIdType } from '../domain/customer'
import { Consignment } from '../types/coreEntitiesTypes'

export type GroupedServicesAndVasesTexts = Map<string, Collection<number, ServicesAndVasesTexts>>

export const serviceCodesWithoutPickupSms: string[] = ['2778', '3421', '3577', '3578', '4205', '3623']

export enum ServiceVasText {
  InternalNameText = 'INTERNAL_NAME_TEXT',
  InternalDescriptionText = 'INTERNAL_DESCRIPTION_TEXT',
  ExternalNameText = 'EXTERNAL_NAME_TEXT',
  ExternalDescriptionText = 'EXTERNAL_DESCRIPTION_TEXT'
}

export enum ServiceMappingType {
  Service = 'Service',
  VAS = 'VAS'
}

interface ServicesAndVasesTranslationsProps {
  serviceMappingType: string
  code: string
  translation: string
  customerAlystraId?: string
}

export interface ServicesAndVasesTexts extends ImmutableMap<ServicesAndVasesTranslationsProps> {}

export const getServiceText = (
  servicesAndVasesTexts: GroupedServicesAndVasesTexts,
  serviceCode: string,
  customerAlystraId: string | null,
  fallbackToEmptyString: boolean = false
): string =>
  getServiceOrVasText(
    servicesAndVasesTexts,
    serviceCode,
    ServiceMappingType.Service,
    customerAlystraId,
    fallbackToEmptyString
  )

export const getVasText = (
  servicesAndVasesTexts: GroupedServicesAndVasesTexts,
  vasCode: string,
  customerAlystraId: string | null,
  fallbackToEmptyString: boolean = false
): string =>
  getServiceOrVasText(servicesAndVasesTexts, vasCode, ServiceMappingType.VAS, customerAlystraId, fallbackToEmptyString)

const getServiceOrVasText = (
  servicesAndVasesTexts: GroupedServicesAndVasesTexts,
  serviceOrVasCode: string,
  serviceMappingType: ServiceMappingType,
  customerAlystraId: string | null,
  fallbackToEmptyString: boolean
): string => {
  const textsForCode = servicesAndVasesTexts.get(serviceOrVasCode)

  const customerSpecificText = textsForCode?.find(
    (mapping) =>
      mapping.get('serviceMappingType') === serviceMappingType && mapping.get('customerAlystraId') === customerAlystraId
  )

  const serviceVasText = (
    customerSpecificText ||
    textsForCode?.find(
      (mapping) =>
        mapping.get('serviceMappingType') === serviceMappingType && isNullOrUndefined(mapping.get('customerAlystraId'))
    )
  )?.get('translation')

  return serviceVasText || (fallbackToEmptyString ? '' : serviceOrVasCode)
}

export const serviceCodesForShipments = (
  serviceTextMatrix: GroupedServicesAndVasesTexts,
  shipments: ConsignmentsByOrderIdType,
  customerId: CustomerIdType
) => {
  const customerAlystraId =
    shipments
      .find((c) => c.first<Consignment>().get('customerId') === customerId)
      ?.first<Consignment>()
      .get('custAlystraId') || null
  return shipments
    .map((it) => it.first<Consignment>().get('serviceCode'))
    .toSet()
    .map((it) => {
      const name = getServiceText(serviceTextMatrix, it, customerAlystraId, true)
      return Map({ name: `${name} (${it})`, value: it })
    })
}
