import { Link } from 'react-router-dom'
import styled from 'styled-components'
import vars from '../styles/variables'

export const BringLogo = styled.div`
  width: 100%;
  vertical-align: middle;
  text-align: center;
  margin: 20px 0;
`

export const GlowLogo = styled(Link)`
  text-decoration: none;
  display: inline-block;
  color: ${vars.colors.darkGray};
  font-size: 1.25rem;
  font-weight: 600;
  -webkit-background-clip: text;
`

export const Logo = styled.img`
  height: 28px;
`

export const GlowLogoLarge = styled.div`
  text-decoration: none;
  color: ${vars.colors.black};
  font-size: 2.5rem;
  width: 150px;
  margin: 0 auto 1rem;
  display: block;
  font-weight: 600;
  background: ${vars.colors.logo};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
