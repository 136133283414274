import { EventDataValue, eventDataValue } from '@glow/entity-types'
import i18next from 'i18next'
import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'

export function EventSelfBilling(props: { data: AnyData; label?: string }) {
  const selfBillingUnitId = eventDataValue(props.data as Record<string, unknown> | null, ['selfBillingUnitId'])
  const selfBillingUser = eventDataValue(props.data as Record<string, unknown> | null, ['selfBillingUser'])

  if (!selfBillingUnitId && !selfBillingUser) {
    return null
  }

  return (
    <div>
      <p className="text-sm">
        <InformationField
          label={i18next.t('consignmentEvent.message.selfBillingUnitId')}
          value={selfBillingUnitId ?? '-'}
          padding={false}
        />
        <InformationField
          label={i18next.t('consignmentEvent.message.selfBillingUser')}
          value={selfBillingUser ?? '-'}
          padding={true}
        />
      </p>
    </div>
  )
}

const InformationField = (props: { label: string; value: EventDataValue; padding: boolean }) => {
  return (
    <>
      <span className={`text-emphasis-medium mb-1 ${props.padding ? 'pl-2' : ''}`}>{props.label}:</span>
      <span className={`text-emphasis-high pl-1 ${props.padding ? 'pl-1' : ''}`}>{props.value}</span>
    </>
  )
}
