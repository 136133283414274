import { Collection, List, Map } from 'immutable'
import { MeasurementUnit } from '../domain/measurementUnits'
import { Consignment, ConsignmentEvent, ConsignmentIdType, Shipment } from '../types/coreEntitiesTypes'
import { ConsignmentState, getHighestStateFromShipment } from '../utils/consignmentState'
import {
  ConsignmentEventsMapType,
  getShipmentSyncedStatusProps,
  getTotalVolumeValue,
  getTotalWeightValue,
  getUniqueConsignmentsPerOrderId,
  isShipmentDeleted
} from '../utils/shipmentsUtils'
import { areAllPackagesInOrderReturned } from '../utils/customerUtils'
import { deliveryDeviationCodes, pickupDeviationCodes } from '../utils/deviation'
import { getLatestDeviation } from '../components/grid/GridRenderers'

export function getShipments(
  consignments: Collection.Indexed<Consignment>,
  events?: Map<ConsignmentIdType, List<ConsignmentEvent>> | ConsignmentEventsMapType,
  isDeleted: boolean = false
): Map<number, Shipment> {
  const consignmentsById = consignments.groupBy((c) => c.get('orderId'))
  return consignmentsById
    .map((consignments) => consignmentsToOrder(consignments, events as ConsignmentEventsMapType, isDeleted))
    .toMap()
}

export function getShipmentDeviationType(events: Collection<number, ConsignmentEvent> | undefined): {
  deviationType: 'PICKUP' | 'DELIVERY' | undefined
  deviationCode: string | undefined
} {
  if (!events || events.isEmpty()) return { deviationType: undefined, deviationCode: undefined }
  const shipmentEvents = events.toList() || List<ConsignmentEvent>()
  const deviationCode = getLatestDeviation(shipmentEvents)
  const isPickupDeviation = pickupDeviationCodes.map((devCode) => devCode.get('code')).contains(deviationCode)
  const isDeliveryDeviation = isPickupDeviation
    ? false
    : deliveryDeviationCodes.map((devCode) => devCode.get('code')).contains(deviationCode)
  return {
    deviationType: isPickupDeviation ? 'PICKUP' : isDeliveryDeviation ? 'DELIVERY' : undefined,
    deviationCode: deviationCode
  }
}

/**
 * TODO: Implement explicit setting all fields in Shipment to enforce type safety.
 * TODO: Need to make sure it contains all fields as it is now used by non-ts code that might use fields not in the definition
 */
function consignmentsToOrder(
  consignments: Collection<number, Consignment>,
  consignmentsEventsByConsignmentId?: ConsignmentEventsMapType,
  isDeleted: boolean = false
): Shipment {
  const shipment = consignments.first() as Shipment
  const stateFromShipment = getHighestStateFromShipment(consignments.toSet())
  const isReturned = consignmentsEventsByConsignmentId
    ? areAllPackagesInOrderReturned(consignments, consignmentsEventsByConsignmentId) ||
      stateFromShipment === ConsignmentState.RETURNED
    : stateFromShipment === ConsignmentState.RETURNED
  const { deviationType, deviationCode } = consignmentsEventsByConsignmentId
    ? getShipmentDeviationType(consignmentsEventsByConsignmentId.get(shipment.get('id')))
    : { deviationType: undefined, deviationCode: undefined }
  const consignmentsPerOrderId = getUniqueConsignmentsPerOrderId(consignments)
  const totalPackagesInOrder = consignmentsPerOrderId.count()
  const consignmentsPerOrderIdList = consignmentsPerOrderId.toList()

  const { packagesArrivedAtDip, packagesCollected, packagesDelivered } =
    getShipmentSyncedStatusProps(consignmentsPerOrderIdList)

  return shipment.withMutations((c) => {
    c.set('id', c.get('orderId'))
      .set('noOfPackages', totalPackagesInOrder)
      .set('consignments', consignmentsPerOrderIdList)
      .set('consignmentIds', consignmentsPerOrderIdList.map((cv) => cv.get('id')).toSet())
      .set('state', isReturned ? ConsignmentState.RETURNED : stateFromShipment)
      .set('isReturned', isReturned)
      .set('packagesArrivedAtDip', packagesArrivedAtDip)
      .set('packagesCollected', packagesCollected)
      .set('packagesDelivered', packagesDelivered)
    c.set('totalVolumeValue', getTotalVolumeValue(c, consignmentsPerOrderId, MeasurementUnit.DMQ)).set(
      'totalVolumeUnit',
      MeasurementUnit.DMQ
    )
    c.set('totalWeightValue', getTotalWeightValue(c, consignmentsPerOrderId, MeasurementUnit.KGM))
      .set('totalWeightUnit', MeasurementUnit.KGM)
      .set('deviationType', deviationType)
      .set('deviationCode', deviationCode)
      .set('isDeletedShipment', isDeleted || isShipmentDeleted(shipment))
  })
}
