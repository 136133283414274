import i18next from 'i18next'
import { List } from 'immutable'
import { DateTime } from 'luxon'
import { CustomerSettingsEmailFormValues } from '../pages/admin/customerEmailTypes'
import { DeviationOrderFormValues } from '../pages/admin/orderDeviationTypes'
import { customerNumberAndSubcustomer } from '../pages/instant/bookingOrderFunctions'
import { Shipment } from '../types/coreEntitiesTypes'
import { ImmutableMap } from '../types/immutableTypes'
import { IdType } from './airexpress/common'
import { IOptimusService, isStoreHomeDeliveryService } from './optimusService'

export type CustomerIdType = number

export type ServiceLevel = 'none' | 'standard' | 'premium'
export type ServiceLevelBackend = ServiceLevel | null
export const serviceLevelTypes: {
  code: ServiceLevel | ''
  label: string
}[] = [
  { code: '', label: i18next.t('serviceLevel.notSet') },
  { code: 'none', label: i18next.t('serviceLevel.none') },
  { code: 'standard', label: i18next.t('serviceLevel.standard') },
  { code: 'premium', label: i18next.t('serviceLevel.premium') }
]

export interface CustomerProps {
  id: number
  name?: string
  alystraId: string
  alystraSubcustomer: string
  departments: List<number>
  information: string | undefined
  defaultSenderName?: string
  defaultSenderAddressId: number | null
  defaultSenderAddress?: string
  defaultSenderZipCode?: string
  defaultSenderZipArea?: string
  defaultSenderPhone?: string
  defaultSenderCountry?: string
  defaultSenderCustomerRef?: string
  defaultSenderContactPerson?: string
  defaultSenderInstructions?: string
  type?: 'customized' | 'instant'
  displayEndCustomerPriceShd?: boolean
  serviceLevel: ServiceLevelBackend
  trackingType?: string
  senderName?: string
  enableInvoiceHdOrdersFromDate?: DateTime
  npsDataFrom?: DateTime
  billingCustomerNumber?: string
  orgNumber?: string
  address?: string
  zipArea?: string
  zipCode?: string
  countryCode?: string
  invoicingAddress?: string
  invoicingZipArea?: string
  invoicingZipCode?: string
  invoicingCountryCode?: string
  creditBlockedFrom?: DateTime
  scanToCreatePackages: boolean
  hideBookingPage: boolean | null
  activateBrandedTracking: boolean | null
  returnLastLeg: boolean | null
  mandatorySubcustomer?: boolean
  customerCode?: string
}

export interface Customer extends ImmutableMap<CustomerProps> {}

export interface CustomerSettingsFormValues {
  // Settings in Glow
  serviceLevel: string
  // smsTexts: CustomerSmsTextPropsType
  senderName: string
  billingCustomerNumber: string
  scanToCreatePackages: boolean
  hideBookingPage: boolean
  activateBrandedTracking: boolean
  customerEventEmails?: Array<CustomerSettingsEmailFormValues>
  customerOrderDeviations?: Array<DeviationOrderFormValues>
  enableInvoiceHdOrdersFromDate?: DateTime
  npsDataFrom?: DateTime
}

export interface CustomerFormValues extends CustomerSettingsFormValues {
  // Customer information
  name: string
  customerNumber: string
  organisationNumber: string
  visitingAddress: string
  visitingZipCode: string
  visitingZipArea: string
  visitingCountryCode: string
  invoicingAddress: string
  invoicingZipCode: string
  invoicingZipArea: string
  invoicingCountryCode: string
  creditBlockedFrom?: DateTime | string
}

export interface CustomerReturnAddressProps {
  customerId: IdType
  name: string
  address: string
  zipCode: string
  zipArea: string
  countryCode: string
}

export interface CustomerReturnAddress extends ImmutableMap<CustomerReturnAddressProps> {}

export function isStoreHomeDeliveryCustomer(availableServicesForCustomer: List<IOptimusService>) {
  return availableServicesForCustomer.some((it) => isStoreHomeDeliveryService(it))
}

export function customerDisplayTextLong(shipment: Shipment) {
  const customerName = shipment.get('custName')
  const numberAndSubcustomer =
    customerNumberAndSubcustomer(shipment.get('custAlystraId'), shipment.get('custAlystraSubcustomer')) || ''

  if (customerName && numberAndSubcustomer) {
    return `${customerName} - ${numberAndSubcustomer}`.trim()
  }

  return customerName ? customerName.trim() : numberAndSubcustomer.trim()
}
