import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { PreAdviceResultData } from '../../../../components/Message'
import i18next from 'i18next'

export const EventPreAdviceResult = (props: { data: AnyData }): React.ReactElement => {
  const eventData = props.data as PreAdviceResultData
  return (
    <div>
      {eventData.result && <p className="text-emphasis-high">{i18next.t(`preadvice.result.${eventData.result}`)}</p>}
      {eventData.policyName && (
        <p className="text-sm text-emphasis-medium mb-1 mt-2">
          {i18next.t('preadvice.rule')}: {eventData.policyName}
        </p>
      )}
      {eventData.ruleTrigger && (
        <p className="text-sm text-emphasis-medium mb-1">
          {i18next.t('preadvice.ruleTrigger')}: {eventData.ruleTrigger}
        </p>
      )}
      {eventData.type && (
        <p className="text-sm text-emphasis-medium mb-1">
          {i18next.t('preadvice.type')}: {eventData.type}
        </p>
      )}
      {eventData.trigger && (
        <p className="text-sm text-emphasis-medium mb-1">
          {i18next.t('preadvice.trigger')}: {eventData.trigger}
        </p>
      )}
      {eventData.action && (
        <p className="text-sm text-emphasis-medium mb-1">
          {i18next.t('preadvice.action')}: {eventData.action}
        </p>
      )}
    </div>
  )
}
