import React from 'react'
import i18next from 'i18next'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { IconFa } from '@glow/ui-components'

interface DamagedOrMissingData {
  description: string
  filenames?: string[]
}

export function EventReportedDamaged(props: { data: AnyData }) {
  const eventData = props.data?.damage as DamagedOrMissingData | undefined | null

  if (!eventData) {
    return null
  }

  const description = eventData.description || ''
  const filenames = eventData.filenames || []

  return (
    <>
      <div>
        <p className="text-sm text-emphasis-medium mb-1">{i18next.t('consignmentEvent.message.reported-damaged')}</p>
        <p className="text-emphasis-high">{description}</p>
      </div>
      {filenames.length > 0 && (
        <ul>
          {filenames.map((f, i) => (
            <li key={f}>
              <a
                href={`/api/upload-multiple/redirect/${f}`}
                target="_blank"
                rel="noreferrer"
                className="text-emphasis-extra flex gap-2 items-center underline"
              >
                <IconFa icon={['far', 'external-link']} />
                {i18next.t('consignmentEvent.damaged.showPictureLink', { idx: i + 1 })}
              </a>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

export function EventReportedMissing(props: { data: AnyData }) {
  const eventData = (props.data?.damage || props.data?.missingArticle) as DamagedOrMissingData | undefined | null

  if (!eventData) {
    return null
  }

  const description = eventData.description || ''

  return (
    <div>
      <p className="text-sm text-emphasis-medium mb-1">
        {i18next.t('consignmentEvent.message.reported-missing-article')}
      </p>
      <p className="text-emphasis-high">{description}</p>
    </div>
  )
}
