import { Collection, List, Map, Seq } from 'immutable'
import {
  ConsignmentIdType,
  UnitIdType,
  Department,
  DepartmentIdType,
  OrderIdType,
  SlotIdType
} from '../types/coreEntitiesTypes'
import { ImmutableMap } from '../types/immutableTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'
import { departmentFromIdSelector } from './departmentsSelector'

export interface IErrorParamProps {
  consignmentIds?: string
  consignmentId?: string
  shipmentIds?: string
  details?: string
  importId?: string
}

export interface IErrorProps {
  description: string
  errorType?: string
  params?: IErrorParams
}

export interface IOptimizeJobResultProps {
  id: number
  departmentId: DepartmentIdType
  courierId: UnitIdType
  finished: boolean
  failed: boolean
  errors: List<IError>
  createdAt: string
  updatedAt: string
  consignmentIds: List<ConsignmentIdType>
  orderIds: List<OrderIdType>
  slotIds: List<SlotIdType>
}

export interface IErrorParams extends ImmutableMap<IErrorParamProps> {}

export interface IError extends ImmutableMap<IErrorProps> {}

export interface IOptimizeJobResult extends ImmutableMap<IOptimizeJobResultProps> {}

const optimizeJobResultsSelector: (state: AppStateType) => Map<number, IOptimizeJobResult> =
  createImmutableEqualSelector(
    (state: AppStateType) => state.getIn(['entities', 'optimizeJobResults']),
    (jobs) => (jobs ? jobs : Map())
  )

export const optimizeJobsResultsByDepartmentIdSelector: (
  state: AppStateType,
  departmentId: DepartmentIdType
) => Seq.Indexed<IOptimizeJobResult> = createImmutableEqualSelector(
  optimizeJobResultsSelector,
  departmentFromIdSelector,
  (jobs: Map<number, IOptimizeJobResult>, department: Department) =>
    jobs
      .valueSeq()
      .filter((it) => it.get('departmentId') === department.get('id'))
      .sortBy((job) => job.get('id'))
)

export const optimizeJobsResultsByDepartmentIdsSelector: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>
) => Collection<number, IOptimizeJobResult> = createImmutableEqualSelector(
  optimizeJobResultsSelector,
  (state: AppStateType, departmentIds: List<DepartmentIdType>) => departmentIds,
  (jobs: Map<number, IOptimizeJobResult>, departmentIds: List<DepartmentIdType>) =>
    jobs
      .valueSeq()
      .filter((it) => departmentIds.includes(it.get('departmentId')))
      .sortBy((job) => job.get('id'))
)

export const optimizeJobsResultsForUnitInDepartmentsSelector: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>,
  unitId: UnitIdType
) => Collection<number, IOptimizeJobResult> = createImmutableEqualSelector(
  optimizeJobsResultsByDepartmentIdsSelector,
  (state: AppStateType, departmentIds: List<DepartmentIdType>, unitId: UnitIdType) => unitId,
  (jobs: Collection<number, IOptimizeJobResult>, unitId: UnitIdType) =>
    jobs.filter((it) => unitId === it.get('courierId')).sortBy((job) => job.get('id'))
)
export const optimizeJobsResultsForUnitOrSlotInDepartmentsSelector: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>,
  slotId: SlotIdType,
  unitId?: UnitIdType
) => Collection<number, IOptimizeJobResult> = createImmutableEqualSelector(
  optimizeJobsResultsByDepartmentIdsSelector,
  (state: AppStateType, departmentIds: List<DepartmentIdType>, slotId: SlotIdType) => slotId,
  (state: AppStateType, departmentIds: List<DepartmentIdType>, slotId: SlotIdType, unitId?: UnitIdType) => unitId,
  (jobs: Collection<number, IOptimizeJobResult>, slotId: SlotIdType, unitId?: UnitIdType) =>
    jobs
      .filter((it) => (unitId && unitId === it.get('courierId')) || it.get('slotIds').includes(slotId))
      .sortBy((job) => job.get('id'))
)
export const optimizeJobsResultsForSlotIdInDepartmentsSelector: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>,
  slotId: SlotIdType
) => Collection<number, IOptimizeJobResult> = createImmutableEqualSelector(
  optimizeJobsResultsByDepartmentIdsSelector,
  (state: AppStateType, departmentIds: List<DepartmentIdType>, slotId: SlotIdType) => slotId,
  (jobs: Collection<number, IOptimizeJobResult>, slotId: SlotIdType) =>
    jobs.filter((it) => it.get('slotIds').includes(slotId)).sortBy((job) => job.get('id'))
)
