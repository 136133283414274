import React from 'react'
import { getMessageContainer } from './Message'
import { List, Map } from 'immutable'
import i18next from 'i18next'
import { GridConsignmentEvents } from '../primitives/ConsignmentDrawer'
import { EventType } from '../utils/consignmentEvent'
import { isNotEmpty } from '../utils/collectionUtils'
import { BaseConsignmentEvent } from '../types/coreEntitiesTypes'
import { DeviationOrderPropsType } from '../pages/admin/orderDeviationTypes'
import { getEventWithCustomerUniqueDeviationSelector } from '../selectors/customerOrderDeviationsSelector'
import { filterConsignmentEvents } from '../utils/shipmentsUtils'
import { OrderType } from '@glow/entity-types'

interface ConsignmentEventsGridProps {
  consignmentEvents: List<BaseConsignmentEvent>
  role?: string
  containsDeviation?: boolean
  customerDeviations?: Map<number, DeviationOrderPropsType>
  orderType: OrderType | null
}

export const ConsignmentEventsGrid = ({
  consignmentEvents,
  customerDeviations,
  orderType
}: ConsignmentEventsGridProps) => (
  <GridConsignmentEvents>
    <strong>
      {i18next.t('consignment.packageId')}: {consignmentEvents.first<BaseConsignmentEvent>().get('packageId')}
    </strong>
    {filterConsignmentEvents(consignmentEvents, orderType)
      .sort((a, b) => b.get('eventTime').localeCompare(a.get('eventTime')))
      .map((event, index) => getConsignmentEventContainer(event, event.get('type'), index, customerDeviations))
      .valueSeq()}
  </GridConsignmentEvents>
)

export const getConsignmentEventContainer = (
  event: BaseConsignmentEvent,
  eventType: string,
  index: number,
  customerDeviations: Map<number, DeviationOrderPropsType> | undefined
) => {
  if (customerDeviations) event = getEventWithCustomerUniqueDeviationSelector(event, customerDeviations)
  const MessageContainer = getMessageContainer(eventType)
  // @ts-ignore
  return <MessageContainer key={index} {...event.toObject()} />
}

interface EventsProps {
  shipmentConsignmentEvents: List<List<BaseConsignmentEvent>>
  customerDeviations?: Map<number, DeviationOrderPropsType>
  orderType: OrderType | null
}

export const Events = ({ shipmentConsignmentEvents, customerDeviations, orderType }: EventsProps) => (
  <>
    {shipmentConsignmentEvents.map((consignmentEvents, index) => {
      if (Map.isMap(consignmentEvents)) {
        const eventType = consignmentEvents.get('type') as EventType
        return getConsignmentEventContainer(consignmentEvents, eventType, index, customerDeviations)
      } else if (isNotEmpty(consignmentEvents)) {
        return (
          <ConsignmentEventsGrid
            key={index}
            consignmentEvents={consignmentEvents}
            customerDeviations={customerDeviations}
            orderType={orderType}
          />
        )
      }
    })}
  </>
)
