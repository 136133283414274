import React, { useMemo } from 'react'
import styles from './MarkdownRenderer.module.css'

const regexMarkdownLinks = /\[([^\[]+)\](\(.*\))/gm

const replaceLinksWithATag = (text: string) => {
  const matches = text.match(regexMarkdownLinks)
  matches?.forEach((match) => {
    const matchSplit = match.slice(1, -1).split('](')
    if (matchSplit.length === 2) {
      const [linkName, linkHref] = matchSplit
      text = text.replace(match, `<a target="_blank" href=${linkHref}>${linkName}</a>`)
    }
  })
  return text
}

const checkmarkSvgAsString =
  () => `<svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.184 0.507539L4.33389 7.35764L1.81691 4.84066C1.69488 4.71863 1.49702 4.71863 1.37496 4.84066L0.638398 5.57723C0.516367 5.69926 0.516367 5.89712 0.638398 6.01918L4.1129 9.49369C4.23494 9.61572 4.4328 9.61572 4.55486 9.49369L12.3625 1.68605C12.4845 1.56402 12.4845 1.36616 12.3625 1.2441L11.6259 0.507539C11.5039 0.385508 11.306 0.385508 11.184 0.507539Z" fill="currentColor"/>
</svg>
`

const replaceTextWithBold = (text: string): string => {
  if (text.split('**').length < 3) {
    return text
  }
  text = text.replace('**', '<strong>')
  text = text.replace('**', '</strong>')
  return replaceTextWithBold(text)
}
const replaceTextWithItalic = (text: string): string => {
  if (text.split('*').length < 3) {
    return text
  }
  text = text.replace('*', '<em>')
  text = text.replace('*', '</em>')
  return replaceTextWithBold(text)
}

const parseOrderedList = (text: string) => {
  const textSplitPerLine = text.split('\n')
  const textCopySplitPerLine: string[] = []
  let startIndexOfList: number | undefined = undefined
  for (let i = 0; i < textSplitPerLine.length; i++) {
    const text = textSplitPerLine[i]
    if (text.match(/^(\s*)(\d+\.\s+)(.*)/)) {
      // list match
      if (startIndexOfList) {
        // in current list iteration
        textCopySplitPerLine.push(`<li>${text.replace(/^(\s*)(\d+\.\s+)/, '')}</li>`)
      } else {
        // starting new list
        startIndexOfList = i
        textCopySplitPerLine.push('<ol>')
        textCopySplitPerLine.push(`<li>${text.replace(/^(\s*)(\d+\.\s+)/, '')}</li>`)
      }
    } else {
      if (startIndexOfList) {
        // list has ended
        textCopySplitPerLine.push('</ol>')
        startIndexOfList = undefined
      }
      textCopySplitPerLine.push(text)
    }
  }

  return textCopySplitPerLine.join('\n')
}

const parseUnOrderedList = (text: string) => {
  const textSplitPerLine = text.split('\n')
  const textCopySplitPerLine: string[] = []
  let startIndexOfList: number | undefined = undefined
  for (let i = 0; i < textSplitPerLine.length; i++) {
    const text = textSplitPerLine[i]
    if (text.match(/^(\s*)(\W{1}\s+)(.*)/)) {
      // list match
      if (startIndexOfList) {
        // in current list iteration
        textCopySplitPerLine.push(`<li>${checkmarkSvgAsString()}${text.replace(/^(\s*)(\W{1}\s+)/, '')}</li>`)
      } else {
        // starting new list
        startIndexOfList = i
        textCopySplitPerLine.push('<ul>')
        textCopySplitPerLine.push(`<li>${checkmarkSvgAsString()}${text.replace(/^(\s*)(\W{1}\s+)/, '')}</li>`)
      }
    } else {
      if (startIndexOfList) {
        // list has ended
        textCopySplitPerLine.push('</ul>')
        startIndexOfList = undefined
      }
      textCopySplitPerLine.push(text)
    }
  }

  return textCopySplitPerLine.join('\n')
}

export const MarkdownRenderer = ({ text }: { text: string }) => {
  const parsedText = useMemo(() => {
    const textWithParsedOrderedList = parseOrderedList(text)
    const textWithParsedUnOrderedList = parseUnOrderedList(textWithParsedOrderedList)
    const textWithReplaceLinks = replaceLinksWithATag(textWithParsedUnOrderedList)
    return textWithReplaceLinks
      .replaceAll('\n\n', '\n<br>\n')
      .replaceAll('\n---\n', '\n<hr>\n')
      .split('\n')
      .map((text) => {
        let parsedText = text
        if (text.startsWith('## ')) {
          const h1Row = text.replace('## ', '')
          parsedText = `<h2>${h1Row}</h2>`
        } else if (text.startsWith('### ')) {
          const h1Row = text.replace('### ', '')
          parsedText = `<h3>${h1Row}</h3>`
        } else if (text.startsWith('<') || text.endsWith('>')) {
        } else {
          if (text.includes('**')) {
            parsedText = replaceTextWithBold(parsedText)
          }
          if (text.includes('*')) {
            parsedText = replaceTextWithItalic(parsedText)
          }
          parsedText = '<p>' + parsedText + '</p>'
        }
        return parsedText
      })
      .join('\n')
  }, [text])

  return <div className={styles.md} dangerouslySetInnerHTML={{ __html: parsedText }} />
}
