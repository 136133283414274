import { PublicationStatus, WhatsThisModalIdType, WhatsThisModalProps, i18nTextIdType } from '@glow/entity-types'
import { List, Map } from 'immutable'
import { WhatsThisModal } from '../pages/cms/modalUtils'
import { I18nText } from '../types/coreEntitiesTypes'
import { ImmutableMap } from '../types/immutableTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'

export const allWhatsThisModalsSelector: (state: AppStateType) => Map<number, WhatsThisModal> =
  createImmutableEqualSelector(
    (state: AppStateType) => state.getIn(['entities', 'whatsThis']) as Map<number, WhatsThisModal>,
    (whatsThisModals) => whatsThisModals ?? Map()
  )

export interface WhatsThisModalWithTextsProps extends WhatsThisModalProps {
  texts: List<I18nText>
}
export interface WhatsThisModalWithTexts extends ImmutableMap<WhatsThisModalWithTextsProps> {}

export const whatsThisModalWithTextByIdSelector: (
  state: AppStateType,
  modalId: WhatsThisModalIdType
) => WhatsThisModalWithTexts | undefined = createImmutableEqualSelector(
  (state: AppStateType, modalId: WhatsThisModalIdType) =>
    state.getIn(['entities', 'whatsThis', modalId]) as WhatsThisModal | undefined,
  (state: AppStateType) => state.getIn(['entities', 'i18nTexts']) as Map<i18nTextIdType, I18nText> | undefined,
  (whatsThisModal, i18nextTexts) => {
    if (!whatsThisModal) {
      return undefined
    }
    const texts = i18nextTexts?.filter((text) => text.get('key') === whatsThisModal.get('i18nKey')) ?? Map()
    return (whatsThisModal as WhatsThisModalWithTexts).withMutations((b) => {
      b.set('texts', texts.valueSeq().toList())
    })
  }
)

export const publishedWhatsThisModalsSelector: (state: AppStateType) => List<WhatsThisModalWithTexts> =
  createImmutableEqualSelector(
    (state: AppStateType) => state.getIn(['entities', 'whatsThis']) as List<WhatsThisModal> | undefined,
    (state: AppStateType) => state.getIn(['entities', 'i18nTexts']) as Map<i18nTextIdType, I18nText> | undefined,
    (whatsThisModals, i18nextTexts) => {
      if (!whatsThisModals) {
        return List()
      }
      return whatsThisModals
        .filter((whatsThisModal) => whatsThisModal.get('state') === PublicationStatus.Published)
        .map((whatsThisModal) => {
          const texts = i18nextTexts?.filter((text) => text.get('key') === whatsThisModal.get('i18nKey')) ?? Map()
          return (whatsThisModal as WhatsThisModalWithTexts).withMutations((b) => {
            b.set('texts', texts.valueSeq().toList())
          })
        })
    }
  )
