import trim from 'lodash/trim'
import React, { useState } from 'react'
import InputGroup from '../InputGroup'
import i18next from 'i18next'
import { H3 } from '../../../primitives/Headings'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { InputForLabel } from '../../../primitives/Forms'
import { LabeledInput } from '../../../primitives/LabeledInput'
import { validAlphanumeric, validTrimmedAlphanumericOrEmpty } from '../../../utils/inputValidation'
import { SmallValidationError } from '../../../primitives/ErrorMessages'
import { ClearInstantBookingFormButton } from '../../../components/ClearInstantBookingFormButton'
import { fromJS, List, Map } from 'immutable'
import { getH2FormData, h2BookingForm, searchOrderComponentNames } from './H2BookingSupport'
import { SearchSecondaryButton } from '../../../primitives/Buttons'
import { getShipmentDetailsFromTracking } from '../../../actions/creators/helpers'
import { H2OrderForm } from './H2BookingTypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppDispatch } from '../../../reducers/redux-hooks'

interface Props {
  clearComponents: (components: List<string>) => void
  formValues: H2OrderForm
  setTouched: (models: string) => void
  mergeFormValues: (model: string, values: Map<string, any>) => void
}

export function H2BookingSearchOrder({ clearComponents, formValues, setTouched, mergeFormValues }: Props) {
  const dispatch = useAppDispatch()
  const [submitting, setSubmitting] = useState(false)

  let buttonLabel
  if (submitting) {
    buttonLabel = <FontAwesomeIcon icon="spinner" pulse />
  } else {
    buttonLabel = i18next.t('planner.search')
  }

  const searchOrder = (formValues: H2OrderForm) => {
    setTouched(`${h2BookingForm}.shipmentOrPackageId`)

    const shipmentOrPackageId = trim(formValues.get('shipmentOrPackageId'))

    if (validAlphanumeric(shipmentOrPackageId)) {
      setSubmitting(true)
      getShipmentDetailsFromTracking(shipmentOrPackageId)(dispatch)
        .then((response) => {
          const orderData = fromJS(response.readBody) as Map<string, any>
          mergeFormValues(h2BookingForm, getH2FormData(orderData, formValues.get('deliveryPhone')))
        })
        .finally(() => {
          setSubmitting(false)
          mergeFormValues(h2BookingForm, Map({ shipmentOrPackageId: shipmentOrPackageId }))
        })
    }
  }

  return (
    <InputGroup>
      <H3>Search Orders:</H3>
      <div style={{ display: 'flex' }}>
        <div style={{ maxWidth: 200 }}>
          <LabeledInput label={i18next.t('instant.booking.shipmentOrPackageId')} htmlFor="shipmentOrPackageId">
            <Control.text
              autoComplete="nope"
              model=".shipmentOrPackageId"
              id="shipmentOrPackageId"
              component={InputForLabel}
              validators={{ validTrimmedAlphanumericOrEmpty }}
              defaultValue={''}
            />
          </LabeledInput>
          <Errors
            className="errors"
            model=".shipmentOrPackageId"
            show="touched"
            wrapper={SmallValidationError}
            messages={{ validTrimmedAlphanumericOrEmpty: `${i18next.t('error.notAValidAlphaNumeric')}` }}
          />
        </div>
        <SearchSecondaryButton
          type="button"
          style={{ marginLeft: 10, marginTop: -1 }}
          onClick={() => searchOrder(formValues)}
        >
          {buttonLabel}
        </SearchSecondaryButton>
      </div>
      <ClearInstantBookingFormButton clearComponents={clearComponents} components={searchOrderComponentNames} />
    </InputGroup>
  )
}
