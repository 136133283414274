import styled from 'styled-components'
import { getWidth, IContainer } from './Container'

export const ResponsiveContainer = styled.div<IContainer>`
  margin: ${(props) => !props.right && '0 auto'};
  float: ${(props) => (props.right ? 'right' : props.left ? 'left' : 'center')};
  max-width: ${(props) => getWidth(props)};
  filter: ${(props) => props.blur && 'blur(4px)'};
  transition: filter 100ms;
  text-align: ${(props) => props.center && 'center'};
  background: ${(props) => (props.gray ? '#f2f2f2' : null)};
  width: ${(props) => (props.fullscreen ? '100%' : '')};
  position: relative;

  @media screen and (orientation: portrait) {
    padding: ${(props) => (props.noPadding ? '0' : '5rem 1rem 2rem 1rem')};
  }
  @media screen and (orientation: landscape) and (max-width: 849px) {
    padding: ${(props) => (props.noPadding ? '0' : '2rem 1rem 1rem 1rem')};
  }
  @media screen and (orientation: landscape) and (min-width: 850px) {
    padding: ${(props) => (props.noPadding ? '0' : '2rem 1rem 1rem 1rem')};
  }

  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`
