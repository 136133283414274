import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import variables from '../../../styles/variables'
import { ContactInfoGrid, ContactInfoText } from '../shared/Styles'
import { Receiver } from '../shared/Shipment'
import i18next from 'i18next'
import { FlexRow } from '../../../primitives/Flex'
import { ServiceLevelBackend } from '../../../domain/customer'
import styled from 'styled-components'

const DeliveryPhones = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const ReceiverComponent: React.FC<{ receiver: Receiver; serviceLevel: ServiceLevelBackend }> = ({
  receiver,
  serviceLevel
}) =>
  !receiver ? null : (
    <ContactInfoGrid>
      <FontAwesomeIcon icon="arrow-to-bottom" color={variables.newColors.stellarGrey} />
      <ContactInfoText>{receiver.name}</ContactInfoText>
      <FontAwesomeIcon icon="map-marker-alt" color={variables.newColors.stellarGrey} />
      <ContactInfoText>
        {receiver.address.streetName}, {receiver.address.postalCode} {receiver.address.area}
      </ContactInfoText>
      <FontAwesomeIcon icon="phone" color={variables.newColors.stellarGrey} />
      <ContactInfoText>
        <FlexRow gap={10}>
          <DeliveryPhones>
            <div key={1}>{receiver.phone}</div>
            <div key={2}>
              (
              {i18next.t('application.sms') +
                ': ' +
                (receiver.deliverySms ? i18next.t('application.yes') : i18next.t('application.no')) +
                (', ' + i18next.t(`serviceLevel.${serviceLevel}`))}
              )
            </div>
          </DeliveryPhones>
        </FlexRow>
      </ContactInfoText>
      {receiver.secondPhone && (
        <>
          <FontAwesomeIcon icon="phone" color={variables.newColors.stellarGrey} />
          <ContactInfoText>
            <FlexRow gap={10}>{receiver.secondPhone && <div>{receiver.secondPhone}</div>}</FlexRow>
          </ContactInfoText>
        </>
      )}
      {receiver.email && (
        <>
          <FontAwesomeIcon icon="envelope" color={variables.newColors.stellarGrey} />
          <ContactInfoText>{receiver.email}</ContactInfoText>
        </>
      )}
    </ContactInfoGrid>
  )
