import { List, Map } from 'immutable'
import React from 'react'
import Select from '../primitives/Select'
import i18next from 'i18next'
import isFunction from 'lodash/isFunction'
import { ImmutableMap } from '../types/immutableTypes'
import toLower from 'lodash/toLower'

interface ItemType {
  id: string | number
  name: string
}

interface Props {
  items: List<ImmutableMap<ItemType>>
  function: (id: string) => void
  defaultValue?: string
  selected: string
  render?: (item: ImmutableMap<any>) => string
  expand?: boolean
  marginLeft?: boolean
  marginBottom?: boolean
}

const Dropdown = (props: Props) => {
  function select(event: React.FormEvent<HTMLSelectElement>) {
    const value = event.currentTarget.value
    if (value != 'DEFAULT_VALUE') {
      props.function(value)
    }
  }

  const render = isFunction(props.render) ? props.render : (item: ImmutableMap<ItemType>) => item.get('name')
  return (
    <Select expand={props.expand} marginLeft={props.marginLeft} marginBottom={props.marginBottom}>
      <select value={props.selected} onChange={select}>
        {props.defaultValue !== undefined && <option value="DEFAULT_VALUE">{i18next.t(props.defaultValue)}</option>}
        {props.items
          .map((item, index) => (
            <option key={index} value={item.get('id')}>
              {render(item)}
            </option>
          ))
          .valueSeq()}
      </select>
    </Select>
  )
}

export default Dropdown

interface GroupedDropdownProps {
  itemGroups: Map<string, List<ImmutableMap<ItemType>>>
  function: (id: string) => void
  selected: string
  render?: (item: ImmutableMap<any>) => string
  expand?: boolean
  marginLeft?: boolean
  marginBottom?: boolean
  placeholder?: string
}

export const GroupedDropdown = (props: GroupedDropdownProps) => {
  function onSelect(event: React.FormEvent<HTMLSelectElement>) {
    props.function(event.currentTarget.value)
  }

  const render = isFunction(props.render) ? props.render : (item: ImmutableMap<ItemType>) => item.get('name')
  return (
    <Select expand={props.expand} marginLeft={props.marginLeft} marginBottom={props.marginBottom}>
      <select value={props.selected} onChange={onSelect}>
        {props.placeholder && <option>{props.placeholder}</option>}
        {props.itemGroups
          .map((items, label) => (
            <optgroup key={label} label={label}>
              {items
                .sortBy((i) => toLower(i.get('name', '')))
                .map((item) => (
                  <option key={item.get('id')} value={item.get('id')}>
                    {render(item)}
                  </option>
                ))
                .valueSeq()}
            </optgroup>
          ))
          .valueSeq()}
      </select>
    </Select>
  )
}
