import React, { Fragment } from 'react'
import notFound from '../components/404'
import { SvgHolder404 } from '../primitives/SvgHolder'
import { ResponsiveContainer } from '../primitives/NotFoundErrorStyles'
import { H1 } from '../primitives/Headings'
import { StyledLink, SmallText } from '../primitives/Typography'
import i18next from 'i18next'
import vars from '../styles/variables'
import { BringLogo } from '../primitives/Logo'
import { BrandingLogo } from '../primitives/BrandingLogo'

function reloadPage() {
  window.location.reload()
}

const PageError = () => {
  return (
    <main>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: vars.colors.white
        }}
      >
        <BringLogo>
          <BrandingLogo />
        </BringLogo>
        <ResponsiveContainer>
          <SvgHolder404 large>{notFound}</SvgHolder404>
        </ResponsiveContainer>
        <ResponsiveContainer center noPadding>
          <H1>{i18next.t('error.title')}</H1>
          <SmallText>{i18next.t('error.errorMessage')}</SmallText>
          <br />
          <StyledLink to={'#'} onClick={() => reloadPage()}>
            {i18next.t('error.errorClickToReload')}
          </StyledLink>
        </ResponsiveContainer>
      </div>
    </main>
  )
}

export const InlinePageError = () => {
  return (
    <Fragment>
      <ResponsiveContainer>
        <SvgHolder404 large>{notFound}</SvgHolder404>
      </ResponsiveContainer>
      <ResponsiveContainer center noPadding>
        <H1>{i18next.t('error.title')}</H1>
        <SmallText>{i18next.t('error.errorMessage')}</SmallText>
        <br />
        <StyledLink to={'#'} onClick={() => reloadPage()}>
          {i18next.t('error.errorClickToReload')}
        </StyledLink>
      </ResponsiveContainer>
    </Fragment>
  )
}

export default PageError
