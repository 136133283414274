import styled from 'styled-components'
import variables from '../styles/variables'

const SuccessContainer = styled.div<{ center?: boolean }>`
  padding: 1em;
  text-align: ${(props) => props.center && 'center'};
  background: ${variables.newColors.lightGreen};
  border: 1px solid ${variables.newColors.intenseGreen};
  position: relative;

  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`

export default SuccessContainer
