import React from 'react'
import { Map } from 'immutable'
import map from '../assets/icons/map.png'
import { EventType, shouldShowMapIcon } from './consignmentEvent'
import styled from 'styled-components'
import { ImmutableMap } from '../types/immutableTypes'

export const getMapButton = (type: EventType, lat: string, lng: string) => {
  if (shouldShowMapIcon(type)) {
    return (
      <MapButton onClick={() => window.open(`https://maps.google.com/?q=${lat},${lng}`, '_blank')}>
        <img src={map} style={{ width: '16px', height: '16px', marginLeft: '5px' }} alt="open map" />
      </MapButton>
    )
  } else {
    return null
  }
}

export const getMapForLocationCoordinates = (data: ImmutableMap<any> | null, type: EventType) => {
  const location = data ? data.get('location') : null
  if (location !== null && shouldShowMapIcon(type)) {
    const lat = location?.get?.('latitude')
    const lng = location?.get?.('longitude')
    if (!lat || !lng) return null
    return getMapButton(type, lat, lng)
  }
  return null
}

export const getMapForDeviatedLocation = (deviatedLocation: Map<string, any>, type: EventType) => {
  const lat = deviatedLocation?.get?.('latitude')
  const lng = deviatedLocation?.get?.('longitude')

  return !lat || !lng ? null : getMapButton(type, lat, lng)
}

const MapButton = styled.button`
  background: none;
  display: contents;
  cursor: pointer;
`
