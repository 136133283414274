import React from 'react'
import { InfoWindow, Marker } from '@react-google-maps/api'
import {
  getSvgForConsignmentState,
  getSvgForCustomerConsignmentState,
  getSvgForNumber
} from '../primitives/fontAwesomeSVG'
import { ConsignmentState, isNotCollected } from '../utils/consignmentState'

type Props = {
  id?: any
  lat: number
  lng: number
  heading: number
  zIndex: number
  information: any[]
  interactive: boolean
  states: ConsignmentState[]
  count: number
  icon?: any
  showCount?: boolean
  clusterer: any
  isCustomer?: boolean
}
export class CustomerMarkerWithInfoWindow extends React.Component<Props, { isOpen: boolean }> {
  constructor(props: Props) {
    super(props)
    this.state = { isOpen: false }
  }

  onToggleOpen() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const {
      id,
      lat,
      lng,
      heading,
      zIndex,
      information,
      interactive,
      states,
      count,
      icon,
      showCount = false,
      clusterer,
      isCustomer = false
    } = this.props

    let iconToUse = icon

    if (!icon && count > 1 && showCount) {
      const svgForIcons = isCustomer
        ? getSvgForCustomerConsignmentState(states[0])
        : getSvgForConsignmentState(states[0])

      iconToUse = {
        url:
          'data:image/svg+xml;charset=utf-8,' +
          encodeURIComponent(svgForIcons.replace('{{count}}', getSvgForNumber(count))),
        origin: { x: 10, y: 10 },
        anchor: { x: 0, y: 15 },
        size: { width: 30, height: 50 },
        scalesSize: { width: 30, height: 40 }
      }
    } else if (!icon) {
      iconToUse = isCustomer && isNotCollected(states[0]) ? '/resolved.png' : `/${states[0].toLowerCase()}.png`
    }

    const pos = {
      lat: lat,
      lng: lng
    }

    return (
      <Marker
        key={id}
        onClick={() => this.onToggleOpen()}
        position={pos}
        zIndex={zIndex}
        icon={iconToUse}
        clusterer={clusterer}
      >
        {interactive && (heading || information.length > 0) && this.state.isOpen && (
          <InfoWindow
            position={pos}
            options={{
              // @ts-expect-error
              visible: true,
              enableEventPropagation: false,
              closeBoxMargin: '2px',
              pane: 'overlayLayer'
            }}
            onCloseClick={() => this.onToggleOpen()}
          >
            <div>
              {heading && <h3 style={{ marginTop: 0 }}>{heading}</h3>}
              <div
                style={{
                  maxHeight: '300px',
                  overflowY: 'auto'
                }}
              >
                {information.map((i) => i())}
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
    )
  }
}
