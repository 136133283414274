import React, { CSSProperties } from 'react'
import { Input, SearchInput } from '../primitives/Forms'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { AutocompleteContainer, SuggestionActive, SuggestionInactive } from '../primitives/Autocomplete'
import i18next from 'i18next'
import { isNotEmpty } from '../utils/collectionUtils'
import uniqueId from 'lodash/uniqueId'

interface Props {
  address: string
  countryCode: string
  onFoundAddress: (value: any) => void
  onComplete: (value: any) => void
  searchAroundArea: { lat: number; lng: number }
  rounded: boolean
  disabled: boolean
  style: CSSProperties
}

interface State {
  address: string
  ariaControlsId: string
}
export default class extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      address: props.address ? props.address : '',
      ariaControlsId: uniqueId('ariaControlsId')
    }
  }

  componentDidMount() {
    if (this.props.address) {
      const autocompleteService = new google.maps.places.AutocompleteService() // eslint-disable-line no-undef
      const request = this.props.countryCode
        ? { input: this.props.address, componentRestrictions: { country: this.props.countryCode } }
        : { input: this.props.address }
      autocompleteService.getPlacePredictions(request, (predictions: any, status) => {
        if (status === 'OK' && predictions.length > 0) {
          const placesService = new google.maps.places.PlacesService(document.createElement('div')) // eslint-disable-line no-undef
          placesService.getDetails({ placeId: predictions[0].place_id }, (result, status) => {
            if (status === 'OK') {
              const address = this.getAddressFromPlace(result)
              this.props.onFoundAddress && this.props.onFoundAddress(address)
            }
          })
        }
      })
    }
  }

  handleSelect(address: any) {
    geocodeByAddress(address).then((results: any[]) => {
      const address = this.getAddressFromPlace(results[0])
      this.props.onComplete && this.props.onComplete(address)
    })
  }

  getAddressFromPlace(placeResult: google.maps.places.PlaceResult | null) {
    const town = this.findAddressComponent(placeResult, 'postal_town')
    return {
      streetname: `${this.findAddressComponent(placeResult, 'route')} ${this.findAddressComponent(
        placeResult,
        'street_number'
      )}`,
      zipCode: this.findAddressComponent(placeResult, 'postal_code'),
      zipArea: town ? town : this.findAddressComponent(placeResult, 'locality'),
      countryCode: this.findAddressComponent(placeResult, 'country', true),
      latLng: {
        lat: placeResult?.geometry?.location?.lat(),
        lng: placeResult?.geometry?.location?.lng()
      }
    }
  }

  findAddressComponent(place: google.maps.places.PlaceResult | null, prop: string, shortName = false) {
    const addressComponent = place?.address_components?.find((comp) => comp.types[0] === prop)

    if (!addressComponent) {
      return ''
    }

    return shortName ? addressComponent.short_name : addressComponent.long_name
  }

  render() {
    const { searchAroundArea, rounded, disabled, style } = this.props
    const placeholder = i18next.t('application.autoComplete')

    const searchOptions = searchAroundArea
      ? {
          location: new google.maps.LatLng(searchAroundArea.lat, searchAroundArea.lng), // eslint-disable-line no-undef
          radius: 10000,
          types: ['address']
        }
      : undefined

    return (
      <PlacesAutocomplete
        searchOptions={searchOptions}
        value={this.state.address}
        onChange={(address: any) => this.setState({ address })}
        onSelect={(address: any) => this.handleSelect(address)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }: any) => (
          <div>
            {rounded ? (
              <SearchInput
                style={style}
                {...getInputProps({ placeholder })}
                disabled={disabled}
                aria-controls={this.state.ariaControlsId}
              />
            ) : (
              <Input
                {...getInputProps({ placeholder })}
                disabled={disabled}
                aria-controls={this.state.ariaControlsId}
              />
            )}
            {isNotEmpty(suggestions) && (
              <AutocompleteContainer id={this.state.ariaControlsId}>
                {suggestions.map((suggestion: { active: any; placeId: any; description: any }) => {
                  return suggestion.active ? (
                    <SuggestionActive {...getSuggestionItemProps(suggestion)} key={suggestion.placeId}>
                      <span>{suggestion.description}</span>
                    </SuggestionActive>
                  ) : (
                    <SuggestionInactive {...getSuggestionItemProps(suggestion)} key={suggestion.placeId}>
                      <span>{suggestion.description}</span>
                    </SuggestionInactive>
                  )
                })}
              </AutocompleteContainer>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}
