import { api } from '../../http/httpHelper'
import { AddressProps, IAddress } from '../../pages/instant/bookingOrderTypes'
import {
  CLEAR_SEARCH_ADDRESSES_FOR_ADDRESSES_PAGE,
  CREATE_OR_UPDATE_ADDRESS,
  DELETE_ADDRESSES,
  SEARCH_ADDRESSES
} from '../actionTypes'
import { ThunkResult } from './baseHelpers'
import { List, Set } from 'immutable'
import { departmentIdsToList } from './helpers'
import { ThunkDispatch } from 'redux-thunk'
import { AppStateType } from '../../utils/appStateReduxStore'
import { AnyAction } from 'redux'

export const searchAddresses: (
  departmentIds: number | List<number>,
  searchPhrase: string
) => ThunkResult<Promise<Response>> = (departmentIds: number | List<number>, searchPhrase: string) => (dispatch) => {
  return dispatch(
    api.asyncQuery(SEARCH_ADDRESSES, {
      query: 'searchAddresses',
      params: {
        departmentIds: departmentIdsToList(departmentIds),
        searchPhrase
      }
    })
  )
}

export const searchAddressesForAddressesPage: (
  searchPhrase: string,
  departmentId?: number
) => ThunkResult<Promise<Response>> = (searchPhrase: string, departmentId?: number) => (dispatch) => {
  return dispatch(
    api.asyncQuery(SEARCH_ADDRESSES, {
      query: 'searchAddresses',
      params: {
        departmentId,
        searchPhrase,
        limit: 500
      }
    })
  )
}

export type AddressOptions = {
  defaultForCustomerId?: number
}

export const createOrUpdateAddress: (address: IAddress, options?: AddressOptions) => ThunkResult<Promise<Response>> =
  (address: IAddress, options?: AddressOptions) => (dispatch) => {
    const payload = address.toJS() as AddressProps & AddressOptions
    if (options) {
      payload.defaultForCustomerId = options.defaultForCustomerId
    }
    return dispatch(
      api.asyncCommand(CREATE_OR_UPDATE_ADDRESS, {
        type: 'CREATE_OR_UPDATE_ADDRESS',
        payload
      })
    )
  }

export const deleteAddresses: (addressIds: Set<number>) => ThunkResult<void> =
  (addressIds: Set<number>) => (dispatch) => {
    return dispatch(
      api.asyncDeleteCommand(DELETE_ADDRESSES, { type: 'DELETE_ADDRESSES', payload: { addressIds: addressIds } })
    )
  }

export const clearSearchAddressesForAddressesPage = () => (dispatch: ThunkDispatch<AppStateType, {}, AnyAction>) => {
  return dispatch({
    type: CLEAR_SEARCH_ADDRESSES_FOR_ADDRESSES_PAGE
  })
}
