import React from 'react'
import i18next from 'i18next'
import { EventMap } from './event-map'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { eventDataValue } from '@glow/entity-types'

export const AssignedMessage = (props: { data: AnyData }): React.ReactElement => {
  const routeName = eventDataValue(props.data, ['slotName'])
  const unitName = eventDataValue(props.data, ['unitName'])

  return (
    <>
      <EventMap data={props.data} />
      <div>
        {routeName && (
          <p className="text-sm text-emphasis-medium mb-1">
            {i18next.t('consignmentEvent.message.plannedOnRouteWithRouteInfo', { routeName })}
          </p>
        )}
        {unitName && <p className="text-emphasis-high">{unitName}</p>}
      </div>
    </>
  )
}
