import ImmutableComponent from '../../primitives/ImmutableComponent'
import i18next from 'i18next'
import * as React from 'react'
import styled from 'styled-components'

export class PickupAndDeliveryTimeInfo extends ImmutableComponent<Props> {
  render() {
    return (
      <div>
        <TimeInfoText style={{ color: 'gray' }}>{i18next.t('customer.booking.pickup')}</TimeInfoText>
        <TimeInfoText>
          {this.props.pickupTimeEarliest} - {this.props.pickupTimeLatest}
        </TimeInfoText>
        <TimeInfoText style={{ color: 'gray' }}>{i18next.t('customer.booking.delivery')}</TimeInfoText>
        <TimeInfoText>
          {this.props.deliveryTimeEarliest} - {this.props.deliveryTimeLatest}
        </TimeInfoText>
      </div>
    )
  }
}

const TimeInfoText = styled.p`
  display: inline;
  font-size: 0.9rem;
  margin-bottom: 0;
  margin-right: 0.5rem;
`

interface Props {
  pickupTimeEarliest: string
  pickupTimeLatest: string
  deliveryTimeEarliest: string
  deliveryTimeLatest: string
}
