import React from 'react'
import i18next from 'i18next'
import { SmallText } from '../primitives/Typography'

interface Props {
  unitName: string | undefined
  driverName: string | undefined
}

export function ConsignmentEventUnitAndDriver(props: Props) {
  return (
    <>
      {props.unitName && (
        <SmallText noMargin>
          {i18next.t('consignmentEvent.message.unitName')}
          {': '}
          {props.unitName}
        </SmallText>
      )}
      {props.driverName && (
        <SmallText noMargin>
          {i18next.t('consignmentEvent.message.driverName')}
          {': '}
          {props.driverName}
        </SmallText>
      )}
    </>
  )
}
