import { Collection, List, Map, OrderedMap, OrderedSet, Seq, Set } from 'immutable'
import { DateTime, Interval } from 'luxon'
import { consignmentStates } from '../domain/consignment'
import { Customer, CustomerIdType } from '../domain/customer'
import { ShipmentFilter } from '../pages/instant/ShipmentStatusFilter'
import { sortByColumn, sortedSelector } from '../pages/instant/sortingSelectors'
import { getShipments } from '../query/shipmentQuery'
import {
  Consignment,
  ConsignmentEvent,
  ConsignmentIdType,
  DepartmentIdType,
  OrderIdType,
  OrderNote,
  Shipment,
  ShipmentProps,
  SlotIdType,
  Unit,
  UnitIdType
} from '../types/coreEntitiesTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { isNotEmpty } from '../utils/collectionUtils'
import {
  ConsignmentState,
  consignmentCanBeCollected,
  isAnyPackageDuplicable,
  isConsignmentDeletable,
  isNotCollected
} from '../utils/consignmentState'
import { ReturnType, areAllPackagesInOrderReturned, getFilteredConsignmentsData } from '../utils/customerUtils'
import { isSameDayLuxon, toLocaleDate } from '../utils/dateTime'
import { DepartmentType } from '../utils/departmentAndDepartmentGroupUtils'
import { isDeviationEvent } from '../utils/deviation'
import { GroupedServicesAndVasesTexts, getServiceText, getVasText } from '../utils/serviceUtils'
import {
  ConsignmentEventsMapType,
  FilteredShipmentsListReturnType,
  MappedConsignmentsType,
  OrderNoteMapType,
  SelectedShipmentsAllowedActionsReturnType,
  eligibleForPrePickupSms,
  filterConsignmentsByState,
  groupConsignmentsToShipments,
  isHappyFlowShipment,
  isShipmentDeleted,
  isShipmentsUnassignable
} from '../utils/shipmentsUtils'
import {
  consignmentEventsPerConsignmentIdForSearchSelector,
  consignmentEventsToMapOfConsignmentIdsToEventMap,
  consignmentEventsWithOrderIdSelector,
  consignmentEventsWithOrderSearchIdPerConsignmentIdOrderIdSelector,
  consignmentsByCustomerIdsSelector,
  consignmentsByDepartmentIdAndDateAndAtLeastResolvedSelector,
  consignmentsByDepartmentIdsAndDateAndAtLeastResolvedSelector,
  consignmentsByOrderIdSelector,
  consignmentsByOrderIdsSelector,
  consignmentsByOrderSearchSelector,
  consignmentsBySearchSelector,
  consignmentsSelector,
  orderNotePerOrderIdAndOrderSearchsForSearchSelector,
  orderNotePerOrderIdForSearchSelector,
  orderNotePerOrderIdSelector
} from './consignmentSelectors'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'
import { customersSelector } from './customerSelector'
import {
  isSortingOrderDescendingSelector,
  selectedIdsByGridKeySelector,
  sortedColumnKeyByGridKeySelector
} from './gridSelectors'
import { servicesAndVasesTextsPerCodeSelector } from './servicesSelectors'

export const SHIPMENTS_COUNT_LIMIT = 5000
export type ConsignmentsByOrderIdType = Map<OrderIdType, Collection<ConsignmentIdType, Consignment>>

export const shipmentsByDepartmentIdAndDateAndAtLeastResolvedSelector = createImmutableEqualSelector(
  consignmentsByDepartmentIdAndDateAndAtLeastResolvedSelector,
  (consignments) => (consignments ? consignments.valueSeq().groupBy((c) => c.get('orderId')) : List())
)

export const shipmentsWithinTimeRangeSelector: (
  shipments: List<Shipment>,
  interval: Interval,
  slotIds: List<SlotIdType>
) => List<Shipment> = createImmutableEqualSelector(
  (shipments: List<Shipment>, interval: Interval, slotIds: List<SlotIdType>) =>
    shipments.filter((shipment: Shipment) => {
      const consignment = shipment.first()
      const deliveryEarliest = consignment.get('deliveryTimeEarliestCached')
      const deliveryLatest = consignment.get('deliveryTimeLatestCached')
      const consignmentInterval = Interval.fromDateTimes(deliveryEarliest, deliveryLatest)
      return (
        interval.overlaps(consignmentInterval) &&
        (!consignment.get('slotId') || slotIds.contains(consignment.get('slotId')))
      )
    }),
  (shipments) => (shipments ? shipments : List())
)

export const shipmentsByDepartmentIdsAndDateAndAtLeastResolved: (
  state: AppStateType,
  departmentIds: List<number>,
  currentDate: DateTime,
  toDate: DateTime
) => Map<number, Shipment> = createImmutableEqualSelector(
  consignmentsByDepartmentIdsAndDateAndAtLeastResolvedSelector,
  (consignments: Collection.Indexed<Consignment>) =>
    consignments ? getShipments(consignments) : Map<number, Shipment>()
)

export const shipmentsNotAssignedSelector: (
  state: AppStateType,
  departmentIds: List<number>,
  currentDate: DateTime,
  toDate: DateTime
) => List<Shipment> = createImmutableEqualSelector(
  shipmentsByDepartmentIdsAndDateAndAtLeastResolved,
  (shipments) =>
    shipments
      .valueSeq()
      .filter((c) => consignmentStates[c.get('state')] < consignmentStates.REJECTED)
      ?.toList() || List()
)

export const shipmentsNotAssignedWithDatesSelector: (
  state: AppStateType,
  departmentIds: List<number>,
  currentDate: DateTime,
  toDate: DateTime
) => List<Shipment> = createImmutableEqualSelector(
  shipmentsByDepartmentIdsAndDateAndAtLeastResolved,
  (shipments) =>
    shipments
      .valueSeq()
      .filter(
        (shipment: Shipment) =>
          consignmentStates[shipment.get('state')] <= consignmentStates.REJECTED &&
          !!shipment.get('pickupTimeEarliest') &&
          !!shipment.get('pickupTimeLatest') &&
          !!shipment.get('deliveryTimeEarliest') &&
          !!shipment.get('deliveryTimeLatest')
      )
      ?.toList() || List()
)

export const shipmentsNotAssignedWithExtraDetailsSelector = createImmutableEqualSelector(
  shipmentsNotAssignedWithDatesSelector,
  orderNotePerOrderIdSelector,
  servicesAndVasesTextsPerCodeSelector,
  (
    shipments: List<Shipment>,
    orderNote: Map<OrderIdType, Collection<number, OrderNote>>,
    servicesAndVasesTexts: GroupedServicesAndVasesTexts
  ) =>
    shipments?.map((shipment: Shipment) => {
      const orderNoteText = orderNote.get(shipment.get('orderId'))?.first<OrderNote>()?.get('note')
      const serviceCode = shipment.get('serviceCode')
      const vasCodes = shipment.get('vasCodes')
      return shipment.withMutations((s) => {
        s.set('note', orderNoteText)
          .set('serviceCodeText', getServiceText(servicesAndVasesTexts, serviceCode, shipment.get('custAlystraId')))
          .set(
            'vasCodesTexts',
            vasCodes
              .map((vasCode) => getVasText(servicesAndVasesTexts, vasCode, shipment.get('custAlystraId')))
              .join(', ')
          )
      })
    })
)

export const shipmentsRejectedSelector: (
  state: AppStateType,
  departmentIds: List<number>,
  currentDate: DateTime,
  toDate: DateTime
) => List<Shipment> = createImmutableEqualSelector(
  shipmentsByDepartmentIdsAndDateAndAtLeastResolved,
  (shipments) =>
    shipments
      .valueSeq()
      .filter((c) => consignmentStates[c.get('state')] === consignmentStates.REJECTED)
      ?.toList() || List()
)

export const shipmentsSelector: (state: AppStateType) => Map<number, Shipment> = createImmutableEqualSelector(
  consignmentsSelector,
  consignmentEventsToMapOfConsignmentIdsToEventMap,
  (consignments: Collection.Indexed<Consignment>, events: ConsignmentEventsMapType) =>
    getShipments(consignments, events)
)

export const shipmentsForDateBySlotId: (
  state: AppStateType,
  currentDate: DateTime
) => Map<number, Collection<number, Shipment>> = createImmutableEqualSelector(
  shipmentsSelector,
  (state: AppStateType, currentDate: DateTime) => currentDate,
  (shipments: Map<number, Shipment>, currentDate: DateTime) => {
    return shipments
      .valueSeq()
      .filter(
        (shipment) => isSameDayLuxon(shipment.get('deliveryTimeEarliestCached'), currentDate) && shipment.get('slotId')
      )
      .toList()
      .groupBy((shipment) => shipment.get('slotId')!) // eslint-disable-line @typescript-eslint/no-non-null-assertion
  }
)

export const shipmentsForSlotIds: (state: AppStateType, slotIds: number[]) => Map<number, Shipment> =
  createImmutableEqualSelector(
    shipmentsSelector,
    (state: AppStateType, slotIds: number[]) => slotIds,
    (shipments: Map<number, Shipment>, slotIds: number[]) => {
      return shipments.filter((shipment) => slotIds.includes(shipment.get('slotId') ?? 0))
    }
  )

export const shipmentByIdSelector: (state: AppStateType, shipmentId: number) => Shipment = createImmutableEqualSelector(
  consignmentsByOrderIdSelector,
  (consignments) => getShipments(consignments).first(Map()) || Map()
)

export const shipmentsByShipmentIdSelector: (state: AppStateType, shipmentId: string) => Map<number, Shipment> =
  createImmutableEqualSelector(
    shipmentsSelector,
    (state: AppStateType, shipmentId: string) => shipmentId,
    (shipments: Map<number, Shipment>, shipmentId: string) =>
      shipments.filter((s) => s.get('shipmentId') === shipmentId)
  )

export const shipmentsForCustomerByOrderIdSelector: (
  state: AppStateType,
  events: Map<ConsignmentIdType, List<ConsignmentEvent>>
) => Map<number, Shipment> = createImmutableEqualSelector(
  consignmentsSelector,
  (state: AppStateType, events?: Map<ConsignmentIdType, List<ConsignmentEvent>>) => events,
  (consignments: Collection.Indexed<Consignment>, events?: Map<ConsignmentIdType, List<ConsignmentEvent>>) =>
    getShipments(consignments, events)
)

export const shipmentForCustomerSelector: (
  state: AppStateType,
  events: Map<ConsignmentIdType, List<ConsignmentEvent>>,
  shipmentId: number
) => Shipment = createImmutableEqualSelector(
  shipmentsForCustomerByOrderIdSelector,
  (state: AppStateType, events?: Map<ConsignmentIdType, List<ConsignmentEvent>>, shipmentId?: number) => shipmentId,
  (shipments: Map<OrderIdType, Shipment>, shipmentId?: number) => (shipmentId && shipments.get(shipmentId)) || Map()
)

export const shipmentsByIdsSelector: (state: AppStateType, shipmentIds: Set<number>) => Map<number, Shipment> =
  createImmutableEqualSelector(
    shipmentsSelector,
    (state: AppStateType, shipmentIds: Set<number>) => shipmentIds,
    (shipments, shipmentIds) => shipments.filter((s) => shipmentIds.includes(s.get('id'))) || Map()
  )

export const shipmentsWithCustomerSelector: (state: AppStateType) => Map<number, Shipment> =
  createImmutableEqualSelector(
    shipmentsSelector,
    customersSelector,
    (shipments: Map<number, Shipment>, customers: Map<number, Customer>) =>
      shipments.map((shipment) => {
        const customer = customers.get(shipment.get('customerId'))
        return customer ? shipment.set('customer', customer) : shipment
      })
  )

export const shipmentsByDepartmentIdSelector = createImmutableEqualSelector(
  shipmentsWithCustomerSelector,
  (state: AppStateType, departmentId: number) => departmentId,
  (shipments: Map<number, Shipment>, departmentId) => shipments.filter((s) => s.get('departmentId') === departmentId)
)

const shipmentsByDepartmentIdsSelector = createImmutableEqualSelector(
  consignmentsBySearchSelector,
  consignmentEventsToMapOfConsignmentIdsToEventMap,
  (state: AppStateType, departmentIds: List<DepartmentIdType>) => departmentIds,
  (consignments: Seq.Indexed<Consignment>, events: ConsignmentEventsMapType, departmentIds: List<DepartmentIdType>) =>
    getShipments(
      consignments.filter((s) => departmentIds.includes(s.get('departmentId'))),
      events
    )
)

/**
 * Preserves the order of parameter orderIds using an OrderedMap
 */
export const shipmentsByOrderIdsSelector: (state: AppStateType, orderIds: List<number>) => Map<OrderIdType, Shipment> =
  createImmutableEqualSelector(
    shipmentsWithCustomerSelector,
    (state: AppStateType, orderIds: List<number>) => orderIds,
    (shipments: Map<number, Shipment>, orderIds: List<number>) => {
      return orderIds
        .reduce((acc, id) => acc.set(id, shipments.get(id, Map())), OrderedMap<number, Shipment>())
        .filter((v) => !v.isEmpty())
    }
  )

export const shipmentsByOrderId: (state: AppStateType, orderId: number) => FilteredShipmentsListReturnType =
  createImmutableEqualSelector(
    (state: AppStateType, orderId: number) =>
      consignmentsByOrderIdSelector(state, orderId)
        .groupBy((it) => it.get('orderId'))
        .toMap(),
    (state: AppStateType, orderId: number) =>
      consignmentEventsWithOrderIdSelector(state, orderId).groupBy((it: ConsignmentEvent) => it.get('consignmentId')),
    (state: AppStateType) => orderNotePerOrderIdSelector(state),
    servicesAndVasesTextsPerCodeSelector,
    (consignments, events, notes, vasAndTexts) => createShipments(vasAndTexts, notes, events, consignments, null)
  )

export const shipmentsByDepartmentIdAndDateRangeSelector: (
  state: AppStateType,
  departmentId: number,
  fromDate: DateTime,
  toDate: DateTime
) => Map<number, Shipment> = createImmutableEqualSelector(
  shipmentsByDepartmentIdSelector,
  (state: AppStateType, departmentId: number, fromDate: DateTime) => fromDate,
  (state: AppStateType, departmentId: number, fromDate: DateTime, toDate: DateTime) => toDate,
  (shipments: Map<number, Shipment>, fromDate: DateTime, toDate: DateTime) =>
    shipments.filter((s) => {
      const deliveryEarliest = toLocaleDate(s.get('deliveryTimeEarliestCached'))
      return fromDate <= deliveryEarliest && deliveryEarliest <= toDate.plus({ days: 1 })
    })
)

export const shipmentsByDepartmentIdsAndDateRangeSelector: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>,
  fromDate: DateTime,
  toDate: DateTime
) => Map<number, Shipment> = createImmutableEqualSelector(
  shipmentsByDepartmentIdsSelector,
  (state: AppStateType, departmentIds: List<DepartmentIdType>, fromDate: DateTime) => fromDate,
  (state: AppStateType, departmentIds: List<DepartmentIdType>, fromDate: DateTime, toDate: DateTime) => toDate,
  (shipments: Map<number, Shipment>, fromDate: DateTime, toDate: DateTime) =>
    shipments.filter((s) => {
      const deliveryEarliest = toLocaleDate(s.get('deliveryTimeEarliestCached'))
      return fromDate <= deliveryEarliest && deliveryEarliest <= toDate.plus({ days: 1 })
    })
)

export const filteredShipments = createImmutableEqualSelector(
  (shipments: Map<number, Shipment>, shipmentFilter: ShipmentFilter) => shipmentFilter,
  (shipments: Map<number, Shipment>) => shipments,
  (shipmentFilter: ShipmentFilter, shipments: Map<number, Shipment>) =>
    shipments.filter((s) =>
      shipmentFilter === 'ALL'
        ? true
        : shipmentFilter === 'NOT_COLLECTED'
          ? isNotCollected(s.get('state'))
          : shipmentFilter !== 'NOTHING'
    )
)

export const shipmentsDataForSelectedShipmentsByGridKeySelector: (
  state: AppStateType,
  gridKey: string,
  onConsignmentId: boolean
) => Collection.Indexed<Shipment> = createImmutableEqualSelector(
  shipmentsSelector,
  selectedIdsByGridKeySelector,
  (state: AppStateType, gridKey: string, onConsignmentId: boolean) => onConsignmentId,
  (shipments: Map<number, Shipment>, selectedIds: List<number>, onConsignmentId: boolean) =>
    shipments
      .valueSeq()
      .filter((shipment: Shipment) =>
        onConsignmentId ? consignmentFilter(selectedIds, shipment) : shipmentFilter(selectedIds, shipment)
      )
)

const consignmentFilter: (selectedIds: List<number>, shipment: Shipment) => boolean = (
  selectedIds: List<number>,
  shipment: Shipment
) => selectedIds?.some((id: number) => shipment.get('consignmentIds').has(id))
const shipmentFilter: (selectedIds: List<number>, shipment: Shipment) => boolean = (
  selectedIds: List<number>,
  shipment: Shipment
) => selectedIds?.has(shipment.get('id'))

export const selectedShipmentsAllowedActionsSelector: (
  state: AppStateType,
  gridKey: string,
  onConsignmentId: boolean
) => SelectedShipmentsAllowedActionsReturnType = createImmutableEqualSelector(
  (state: AppStateType, gridKey: string, onConsignmentId: boolean) =>
    shipmentsDataForSelectedShipmentsByGridKeySelector(state, gridKey, onConsignmentId),
  (shipments: Collection.Indexed<Shipment>) => ({
    selectedShipmentsDeletable: isNotEmpty(shipments)
      ? shipments.every((shipment: Shipment) => isConsignmentDeletable(shipment))
      : false,
    selectedShipmentsReturnable: isNotEmpty(shipments)
      ? shipments.every((shipment: Shipment) => shipment.get('state') === ConsignmentState.DEVIATED)
      : false,
    selectedShipmentsUnassignable: isShipmentsUnassignable(shipments) || false,
    selectedShipmentsCanBeCollected: isNotEmpty(shipments)
      ? shipments.every((shipment: Shipment) => consignmentCanBeCollected.contains(shipment.get('state')))
      : false,
    canSendPrePickupSmsForSelectedShipments: isNotEmpty(shipments) ? shipments.every(eligibleForPrePickupSms) : false,
    canSendManualDelaySmsForSelectedShipments: isNotEmpty(shipments)
      ? shipments.every((shipment: Shipment) => shipment.get('state') !== ConsignmentState.DELIVERED)
      : false,
    canGenerateRouteReceiptListForSelectedShipments: isNotEmpty(shipments)
      ? shipments.every((shipment: Shipment) => consignmentStates[shipment.get('state')] >= consignmentStates.ASSIGNED)
      : false,
    selectedShipmentsFromHappyFlow: isNotEmpty(shipments)
      ? shipments.every((shipment: Shipment) => isHappyFlowShipment(shipment))
      : false,
    selectedShipmentsEditable: shipments.count() === 1,
    selectedShipmentsCopyable: shipments.count() === 1 ? !isShipmentDeleted(shipments?.first<Shipment>()) : false,
    selectedShipmentsDuplicable:
      shipments.count() === 1
        ? shipments?.every(
            (shipment) =>
              isAnyPackageDuplicable(shipment.get('consignments').toSet()) &&
              shipment.get('departmentType') === DepartmentType.Courier
          )
        : false
  })
)

export const consignmentIdsOfSelectedShipmentsByDepartmentTypeSelector: (
  state: AppStateType,
  gridKey: string,
  onConsignment: boolean
) => Map<DepartmentType, Set<ConsignmentIdType>> = createImmutableEqualSelector(
  shipmentsDataForSelectedShipmentsByGridKeySelector,
  (shipments: Collection.Indexed<Shipment>) =>
    shipments
      .groupBy((it) => it.get('departmentType'))
      .map((it) => it.flatMap((shipment: Shipment) => shipment.get('consignmentIds')).toSet())
)

export const sortedOrderIdsForSelectedShipmentsByGridKeySelector: (
  state: AppStateType,
  gridKey: string,
  defaultSortingColumn: string,
  isDefaultSortingOrderDescending: boolean,
  onConsignmentId: boolean
) => OrderedSet<OrderIdType> = createImmutableEqualSelector(
  (
    state: AppStateType,
    gridKey: string,
    defaultSortingColumn: string,
    isDefaultSortingOrderDescending: boolean,
    onConsignmentId: boolean
  ) => shipmentsDataForSelectedShipmentsByGridKeySelector(state, gridKey, onConsignmentId),
  sortedColumnKeyByGridKeySelector,
  isSortingOrderDescendingSelector,
  (shipments: Collection.Indexed<Shipment>, sortedColumnKey: string, isSortingOrderDescending: boolean) =>
    sortedSelector(
      shipments,
      sortByColumn<ShipmentProps>(sortedColumnKey as keyof ShipmentProps),
      isSortingOrderDescending
    )
      .map((shipment: Shipment) => shipment.get('orderId'))
      .toOrderedSet()
)

export const labelUrlsSelector: (state: AppStateType) => List<Map<string, any>> = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'labelUrls']),
  (labelUrls: Collection.Indexed<Map<string, any>>): List<Map<string, any>> =>
    labelUrls ? labelUrls.valueSeq().toList() : List()
)

export const urlsFromLabelUrlsSelector: (state: AppStateType, orderLabelId: number) => OrderedSet<string> =
  createImmutableEqualSelector(
    labelUrlsSelector,
    (state: AppStateType, orderLabelId: number) => orderLabelId,
    (labelUrls: List<Map<string, any>>, labelId: number) =>
      labelUrls
        .filterNot((labelUrl: Map<string, any>) => labelUrl.get('id') == 0 && labelUrl.get('errormessage') == null)
        .filter(
          (labelUrl: Map<string, any>) =>
            labelUrl.get('orderLabelId') == labelId && labelUrl.get('errorMessage') == null
        )
        .sortBy((labelUrl: Map<string, any>) => labelUrl.get('id'))
        .map((labelUrl: Map<string, any>) => labelUrl.get('url'))
        .toOrderedSet()
  )

export const jobErrorSelector: (state: AppStateType, orderLabelId: number) => List<string> =
  createImmutableEqualSelector(
    labelUrlsSelector,
    (state: AppStateType, orderLabelId: number) => orderLabelId,
    (labelUrls: List<Map<string, any>>, labelId: number) =>
      labelUrls
        .filter(
          (labelUrl: Map<string, any>) =>
            labelUrl.get('orderLabelId') == labelId && labelUrl.get('errorMessage') != null
        )
        .map((labelUrl: Map<string, any>) => labelUrl.get('errorMessage'))
        .take(1) || List()
  )

export const labelUrlsJobStatusSelector: (state: AppStateType, orderLabelId: number) => boolean =
  createImmutableEqualSelector(
    labelUrlsSelector,
    (state: AppStateType, orderLabelId: number) => orderLabelId,
    (labelUrls: List<Map<string, any>>, labelId: number) =>
      labelUrls
        .filter((labelUrl: Map<string, any>) => labelUrl.get('orderLabelId') == labelId)
        .some(
          (labelUrl: Map<string, any>) =>
            labelUrl.get('orderLabelId') == labelId &&
            labelUrl.keySeq().contains('finished') &&
            labelUrl.get('errorMessage') == null &&
            labelUrl.get('finished') == true
        )
  )

export const searchConsignmentsSelector: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>
) => Map<OrderIdType, Collection<ConsignmentIdType, Consignment>> = createImmutableEqualSelector(
  consignmentsBySearchSelector,
  (state: AppStateType, departmentIds: List<DepartmentIdType>) => departmentIds,
  (consignments: Seq.Indexed<Consignment>, departmentIds: List<DepartmentIdType>) =>
    consignments
      .filter((consignment) => departmentIds.includes(consignment.get('departmentId')))
      .groupBy((cons: Consignment) => cons.get('orderId'))
      .toMap()
)

export const searchConsignmentByOrderSearchsSelector: (state: AppStateType) => MappedConsignmentsType =
  createImmutableEqualSelector(consignmentsByOrderSearchSelector, (consignments: Seq.Indexed<Consignment>) =>
    consignments.groupBy((cons: Consignment) => cons.get('orderId')).toMap()
  )

export const searchDeletedConsignmentsSelector: (
  state: AppStateType
) => Map<OrderIdType, Collection<ConsignmentIdType, Consignment>> = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'searchDeletedOrders']) as Map<number, Consignment>,
  (state: AppStateType) => state.getIn(['searchConsignments', 'searchDeletedOrders']),
  (
    consignments: Map<number, Consignment>,
    consignmentKeys: List<string>
  ): Map<OrderIdType, Collection<ConsignmentIdType, Consignment>> =>
    consignments && consignmentKeys
      ? consignmentKeys
          .map((key) => {
            const entityId = parseInt(key, 10)
            return consignments.getIn([entityId]) as Consignment
          })
          .valueSeq()
          .filter((it) => !!it)
          .groupBy((c) => c.get('orderId'))
          .toMap()
      : Map()
)

const searchConsignmentsForCustomersSelector: (
  state: AppStateType,
  customerIds: Set<CustomerIdType>
) => Seq.Indexed<Consignment> = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'consignments']) as Map<number, Consignment>,
  (state: AppStateType) => state.getIn(['searchConsignments', 'consignments']) as List<string>,
  (state: AppStateType, customerIds: Set<CustomerIdType>) => customerIds,
  (
    consignments: Map<number, Consignment>,
    consignmentKeys: List<string>,
    customerIds: Set<CustomerIdType>
  ): Seq.Indexed<Consignment> =>
    consignments && consignmentKeys
      ? consignmentKeys
          .map((cId) => {
            const entityId = parseInt(cId, 10)
            return consignments.getIn([entityId]) as Consignment
          })
          .valueSeq()
          .filter((it: any) => !!it && customerIds.contains(it.get('customerId')))
      : Seq.Indexed<Consignment>()
)

export const searchConsignmentsForCustomersByOrderIdSelector: (
  state: AppStateType,
  customerIds: Set<CustomerIdType>
) => Map<OrderIdType, Collection<ConsignmentIdType, Consignment>> = createImmutableEqualSelector(
  searchConsignmentsForCustomersSelector,
  (consignments: Seq.Indexed<Consignment>) => consignments.groupBy((cons: Consignment) => cons.get('orderId')).toMap()
)

const createShipments = (
  servicesAndVasesTexts: GroupedServicesAndVasesTexts,
  orderNoteMap: OrderNoteMapType,
  consignmentEventsMap: ConsignmentEventsMapType,
  mappedConsignments: MappedConsignmentsType,
  filter: string | null,
  units?: Map<UnitIdType, Unit>
) => {
  const shipments = mappedConsignments
    .filter((consignments) =>
      filter === 'DEVIATED'
        ? filterConsignmentsWithDeviation(consignmentEventsMap, consignments.toList())
        : filterConsignmentsByState(consignments.toList(), filter)
    )
    .map((consignments) =>
      groupConsignmentsToShipments(
        consignments.toList(),
        consignmentEventsMap,
        orderNoteMap,
        servicesAndVasesTexts,
        units
      )
    )
  return {
    showLimitedShipments: shipments.size >= SHIPMENTS_COUNT_LIMIT,
    filteredShipments: shipments.take(SHIPMENTS_COUNT_LIMIT),
    filteredShipmentEvents: filterShipmentEvents(shipments, consignmentEventsMap, mappedConsignments)
  }
}
export const filteredShipmentsSelector: (
  state: AppStateType,
  mappedConsignments: MappedConsignmentsType,
  filter: string | null
) => FilteredShipmentsListReturnType = createImmutableEqualSelector(
  servicesAndVasesTextsPerCodeSelector,
  orderNotePerOrderIdForSearchSelector,
  consignmentEventsPerConsignmentIdForSearchSelector,
  (state: AppStateType, mappedConsignments: MappedConsignmentsType) => mappedConsignments,
  (state: AppStateType, mappedConsignments: MappedConsignmentsType, filter: string | null) => filter,
  (
    servicesAndVasesTexts: GroupedServicesAndVasesTexts,
    orderNoteMap: OrderNoteMapType,
    consignmentEventsMap: ConsignmentEventsMapType,
    mappedConsignments: MappedConsignmentsType,
    filter: string | null
  ) => createShipments(servicesAndVasesTexts, orderNoteMap, consignmentEventsMap, mappedConsignments, filter)
)

export const filteredShipmentsByOrderSearchSelector: (
  state: AppStateType,
  mappedConsignments: MappedConsignmentsType,
  filter: string | null
) => FilteredShipmentsListReturnType = createImmutableEqualSelector(
  servicesAndVasesTextsPerCodeSelector,
  orderNotePerOrderIdAndOrderSearchsForSearchSelector,
  consignmentEventsWithOrderSearchIdPerConsignmentIdOrderIdSelector,
  (state: AppStateType, mappedConsignments: MappedConsignmentsType) => mappedConsignments,
  (state: AppStateType, mappedConsignments: MappedConsignmentsType, filter: string | null) => filter,
  (state: AppStateType) => state.getIn(['entities', 'units']),
  (
    servicesAndVasesTexts: GroupedServicesAndVasesTexts,
    orderNoteMap: OrderNoteMapType,
    consignmentEventsMap: ConsignmentEventsMapType,
    mappedConsignments: MappedConsignmentsType,
    filter: string | null,
    units: Map<UnitIdType, Unit> | undefined
  ) => createShipments(servicesAndVasesTexts, orderNoteMap, consignmentEventsMap, mappedConsignments, filter, units)
)

const filterConsignmentsWithDeviation = (
  consignmentEventsMap: ConsignmentEventsMapType,
  consignments: List<Consignment>
) =>
  !!consignments.find((consignment) => {
    const consignmentEvents = consignmentEventsMap.get(consignment.get('id'))
    return !!consignmentEvents?.find((event) => isDeviationEvent(event))
  })

const filterShipmentEvents = (
  shipments: Map<OrderIdType, Shipment>,
  events: ConsignmentEventsMapType,
  mappedConsignments: MappedConsignmentsType
) =>
  shipments.map((shipment) => {
    const consignmentIds =
      mappedConsignments
        .get(shipment.get('orderId'))
        ?.map((cv) => cv.get('id'))
        .toSet() || Set()
    return consignmentIds.reduce((res, curr: OrderIdType) => {
      const e = events.get(curr)?.toList()
      return e ? res.merge(e) : res
    }, List<ConsignmentEvent>())
  })

export const shipmentsByCustomerIdsAndDateSelector: (
  state: AppStateType,
  customerIds: Set<CustomerIdType>,
  currentDate: DateTime,
  consignmentEventsMap: ConsignmentEventsMapType
) => ConsignmentsByOrderIdType = createImmutableEqualSelector(
  consignmentsByCustomerIdsSelector,
  (state: AppStateType, customerIds: Set<CustomerIdType>, currentDate: DateTime) => currentDate,
  (
    state: AppStateType,
    customerIds: Set<CustomerIdType>,
    currentDate: DateTime,
    consignmentEventsMap: ConsignmentEventsMapType
  ) => consignmentEventsMap,
  (consignments: Seq.Indexed<Consignment>, currentDate: DateTime, consignmentEventsMap: ConsignmentEventsMapType) =>
    consignments
      .valueSeq()
      .filter((c) => isSameDayLuxon(c.get('deliveryTimeEarliestCached'), currentDate))
      .groupBy((c) => c.get('orderId'))
      .map((consignmentsByOrderId) => {
        const allPackagesInOrderReturned = areAllPackagesInOrderReturned(consignmentsByOrderId, consignmentEventsMap)
        return consignmentsByOrderId.map((consignment) =>
          allPackagesInOrderReturned ? consignment.set('state', ConsignmentState.RETURNED) : consignment
        )
      })
      .toMap()
)

export const filteredConsignmentsDataSelector: (
  state: AppStateType,
  consignmentsByOrderId: ConsignmentsByOrderIdType,
  filterOnStates: Set<ConsignmentState>,
  consignmentEventsMap: ConsignmentEventsMapType
) => ReturnType = createImmutableEqualSelector(
  (state: AppStateType) => state,
  (state: AppStateType, consignmentsByOrderId: ConsignmentsByOrderIdType) => consignmentsByOrderId,
  (state: AppStateType, consignmentsByOrderId: ConsignmentsByOrderIdType, filterOnStates: Set<ConsignmentState>) =>
    filterOnStates,
  (
    state: AppStateType,
    consignmentsByOrderId: ConsignmentsByOrderIdType,
    filterOnStates: Set<ConsignmentState>,
    consignmentEventsMap: ConsignmentEventsMapType
  ) => consignmentEventsMap,
  (
    state: AppStateType,
    consignmentsByOrderId: ConsignmentsByOrderIdType,
    filterOnStates: Set<ConsignmentState>,
    consignmentEventsMap: ConsignmentEventsMapType
  ) => getFilteredConsignmentsData(consignmentsByOrderId, filterOnStates, consignmentEventsMap)
)

export const shouldCourierBeVisibleByCourierIdSelector: (
  shipments: ConsignmentsByOrderIdType
) => Map<UnitIdType | undefined, boolean> = createImmutableEqualSelector(
  (shipments: ConsignmentsByOrderIdType) => shipments,
  (shipments: ConsignmentsByOrderIdType) =>
    shipments
      .groupBy((shipment) => shipment.first<Consignment>()?.get('courierId'))
      .map((shipmentsByCourierId) =>
        shipmentsByCourierId.some((shipment) =>
          shipment.some((consignment) => consignment.get('serviceLevel') === 'premium')
        )
      )
)

const shipmentsForOrderIdsSelector: (
  state: AppStateType,
  selectedOrderIds: List<OrderIdType>
) => Map<number, Shipment> = createImmutableEqualSelector(consignmentsByOrderIdsSelector, (consignments) =>
  getShipments(consignments)
)

export const uniqueShipmentStatesSelector: (
  state: AppStateType,
  selectedOrderIds: List<OrderIdType>
) => Set<ConsignmentState> = createImmutableEqualSelector(shipmentsForOrderIdsSelector, (shipments) =>
  shipments.map((s: Shipment) => s.get('state')).toSet()
)

export const anyShipmentsWithoutDateSelector: (state: AppStateType, selectedOrderIds: List<OrderIdType>) => boolean =
  createImmutableEqualSelector(shipmentsForOrderIdsSelector, (shipments) =>
    shipments.some(
      (s: Shipment) => s.get('deliveryTimeEarliest') === null || s.get('deliveryTimeEarliestCached') === null
    )
  )
