import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { eventDataValue } from '@glow/entity-types'

export function EventUnitAdded(props: { data: AnyData }) {
  const unitAlystraId = eventDataValue(props.data, ['unitAlystraId'])
  const unitName = eventDataValue(props.data, ['unitName'])

  return <span>{`0 → ${unitName} (${unitAlystraId})`}</span>
}
