import { useMemo } from 'react'
import { BannerWithTexts } from '../../selectors/bannerSelectors'
import { WhatsThisModalWithTexts } from '../../selectors/whatsThisModalSelector'

const DEPARTMENT_ID_WILDCARD = '*department-id*'
const DATE_WILDCARD = '*date*'
function getReplacedPreviousPageLink(url?: string, departmentId?: string, date?: string) {
  if (!url) return undefined
  let replacedUrl = url.replace(window.location.origin, '')
  if (departmentId) {
    replacedUrl = replacedUrl.replace(DEPARTMENT_ID_WILDCARD, departmentId)
  }
  if (date) {
    replacedUrl = replacedUrl.replace(DATE_WILDCARD, date)
  }
  return replacedUrl
}

export const useActiveBanner = (
  activeBanner: BannerWithTexts | undefined,
  pathname: string,
  currentLanguageCode: string,
  params?: {
    departmentId?: string | undefined
    date?: string | undefined
  }
) => {
  return useMemo(() => {
    if (activeBanner) {
      const englishText = activeBanner.get('texts').find((text) => text.get('language').toLowerCase() === 'en')
      const englishLinkText = activeBanner
        .get('additionalLinkTexts')
        ?.find((text) => text.get('language').toLowerCase() === 'en')
      return {
        additionalUrl: activeBanner.get('additionalUrl'),
        additionalUrlText:
          activeBanner
            .get('additionalLinkTexts')
            ?.find((text) => text.get('language') === currentLanguageCode)
            ?.get('text') ?? englishLinkText?.get('text'),
        type: activeBanner.get('type'),
        text:
          activeBanner
            .get('texts')
            .find((text) => text.get('language') === currentLanguageCode)
            ?.get('text') ?? englishText?.get('text'),
        previousPageLink: params
          ? getReplacedPreviousPageLink(activeBanner.get('previousPageUrl'), params.departmentId, params.date)
          : undefined
      }
    }
    return undefined
  }, [pathname, activeBanner, currentLanguageCode])
}
export const useActiveWhatsThisModalText = (
  whatsThisModal: WhatsThisModalWithTexts | undefined,
  pathname: string,
  currentLanguageCode: string
) => {
  return useMemo(() => {
    if (whatsThisModal) {
      const englishText = whatsThisModal.get('texts').find((text) => text.get('language').toLowerCase() === 'en')
      return (
        whatsThisModal
          .get('texts')
          .find((text) => text.get('language') === currentLanguageCode)
          ?.get('text') ?? englishText?.get('text')
      )
    }
    return undefined
  }, [pathname, whatsThisModal, currentLanguageCode])
}
