import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getUnits } from '../../../actions/creators/helpers'
import { List, OrderedSet } from 'immutable'
import { unitsWithoutUsersInDepartmentSelectorSorted } from '../../../selectors/unitSelectors'
import { AppStateType } from '../../../utils/appStateReduxStore'
import Select from '../../../primitives/Select'
import { courierUsersWithAlcoholDeliveryEducationFilterByUnitIdSelectorList } from '../../unit/UnitHelpers'
import { requiredFormik } from '../../../utils/formikInputValidation'
import { Field, useFormikContext } from 'formik'
import { ManualOverrideFormProps } from './ManualOverride'
import { ValidationError } from '../../../primitives/ErrorMessages'
import { H2 } from '../../../primitives/Headings'
import i18next from 'i18next'
import { ImmutableMap } from '../../../types/immutableTypes'
import { CourierUserWithActionProps } from '../../unit/CourierUserGrid'
import { consignmentsByOrderIdsSelector } from '../../../selectors/consignmentSelectors'
import { DepartmentIdType, OrderIdType } from '../../../types/coreEntitiesTypes'
import { SYSTEMBOLAGET_ALYSTRA_ID } from './ManualOverrideUtils'
import { useAppDispatch } from '../../../reducers/redux-hooks'

interface SelectManualEventUnitProps {
  orderIds: OrderedSet<OrderIdType>
  departmentIds: List<DepartmentIdType>
}

export function SelectManualEventUnit({ orderIds, departmentIds }: SelectManualEventUnitProps) {
  const dispatch = useAppDispatch()
  const { setValues, values, errors, touched } = useFormikContext<ManualOverrideFormProps>()

  useEffect(() => {
    dispatch(getUnits(departmentIds.toSet()))
  }, [departmentIds])

  const consignments = useSelector((state: AppStateType) => {
    return consignmentsByOrderIdsSelector(state, orderIds.toList())
  })
  const courierMustHaveAlcoholEducation = consignments.some((c) => c.get('custAlystraId') == SYSTEMBOLAGET_ALYSTRA_ID)
  const units = useSelector((state: AppStateType) => {
    return unitsWithoutUsersInDepartmentSelectorSorted(state, departmentIds)
  })
  const users = useSelector((state: AppStateType) => {
    return courierUsersWithAlcoholDeliveryEducationFilterByUnitIdSelectorList(
      state,
      values.unitId ? parseInt(values.unitId) : -1,
      courierMustHaveAlcoholEducation
    )
  })

  useEffect(() => {
    setValues((prev) => {
      if (users && users.size === 1) {
        const driverUserId = users.first(undefined)?.get('id').toString() ?? ''
        return { ...prev, driverUserId }
      } else {
        return { ...prev, driverUserId: '' }
      }
    })
  }, [users, values.unitId])

  return (
    <div style={{ display: 'flex', gap: '10px', marginLeft: '1px', marginBottom: '30px' }}>
      <div>
        <H2 size="s">{i18next.t('manualOverride.selectUnit')}</H2>
        <Select>
          <Field as="select" name="unitId" style={{ width: '100%' }} validate={requiredFormik}>
            <option value="" />
            {units.map((c) => (
              <option key={c.get('id')} value={c.get('id')}>
                {c.get('name')} - {c.get('alystraId')}
              </option>
            ))}
          </Field>
        </Select>
        {errors.unitId && touched.unitId && <ValidationError>{errors.unitId}</ValidationError>}
      </div>
      <div>
        <H2 size="s">{i18next.t('manualOverride.selectDriver')}</H2>
        <Select>
          <Field as="select" name="driverUserId" style={{ width: '100%' }} validate={requiredFormik}>
            <option value="" />
            {users?.map((user) => (
              <option key={user.get('id')} value={user.get('id')}>
                {formatUserOptionText(user)}
              </option>
            ))}
          </Field>
        </Select>
        {errors.driverUserId && touched.driverUserId && <ValidationError>{errors.driverUserId}</ValidationError>}
      </div>
    </div>
  )
}

function formatUserOptionText(user: ImmutableMap<CourierUserWithActionProps>): string {
  if (user.get('driverId')) return user.get('name') + ' - ' + user.get('driverId')
  else return user.get('name')
}
