import { List, Map } from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
import { setPageStateValue } from '../../../actions/creators/helpers'
import ImmutableComponent from '../../../primitives/ImmutableComponent'
import { Department } from '../../../types/coreEntitiesTypes'
import { AppStateType } from '../../../utils/appStateReduxStore'
import { isEmpty, isNotEmpty } from '../../../utils/collectionUtils'
import { emptyVAS, getPhonePrefixFromDepartment } from '../bookingOrderFunctions'
import { OrderAction } from '../bookingSupport'
import { mergeFormValues } from '../../../actions/creators/formHelpers'
import H2BookingForm from './H2BookingForm'
import { h2BookingForm } from './H2BookingSupport'
import { departmentFromIdSelector } from '../../../selectors/departmentsSelector'
import Container from '../../../primitives/Container'
import { Loading } from '../../../components/Loading'
import { compose } from 'redux'
import { RouteComponentProps, withRouter } from '../../../hooks/withRouter'

export function getDefaultPresetMeasurementForH2() {
  return Map({ packageId: '', weight: '', height: '', length: '', width: '' })
}

class H2Booking extends ImmutableComponent<Props> {
  componentDidUpdate(prevProps: Readonly<Props>) {
    if (isEmpty(prevProps.department) && isNotEmpty(this.props.department))
      this.props.mergeFormValues(
        h2BookingForm,
        Map({
          pickupPhone: getPhonePrefixFromDepartment(this.props.department),
          deliveryPhone: getPhonePrefixFromDepartment(this.props.department)
        })
      )
  }

  componentDidMount() {
    this.props.setPageStateValue('showPickupDeliveryTimes', false)
    this.props.mergeFormValues(
      h2BookingForm,
      Map({
        measurements: List([getDefaultPresetMeasurementForH2()]),
        pickupPhone: getPhonePrefixFromDepartment(this.props.department),
        deliveryPhone: getPhonePrefixFromDepartment(this.props.department),
        additionalServices: List.of(emptyVAS())
      })
    )
  }

  render() {
    if (this.props.department === undefined) {
      return <Loading />
    }
    return (
      <Container full noTopPadding>
        <H2BookingForm
          orderAction={OrderAction.Create}
          isLoading={false}
          subTitleKey={'instant.booking.headerCreate'}
          department={this.props.department}
        />
      </Container>
    )
  }
}

interface StateProps {
  department: Department
}

interface DispatchProps {
  mergeFormValues: (formName: string, values: Map<string, any>) => void
  setPageStateValue: (key: string, value: any) => void
}

type PathParamsType = { departmentId: string }
type OwnProps = RouteComponentProps<PathParamsType>
type Props = StateProps & DispatchProps & OwnProps

export default compose(
  withRouter,
  connect<StateProps, DispatchProps, OwnProps, AppStateType>(
    (state, ownProps) => {
      const departmentId = parseInt(ownProps.router.params.departmentId, 10)
      return {
        department: departmentFromIdSelector(state, departmentId)
      }
    },
    {
      mergeFormValues,
      setPageStateValue
    }
  )
)(H2Booking)
