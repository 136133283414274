import { Map } from 'immutable'
import React, { useCallback, useMemo } from 'react'
import { DeliveryTime, PickupTime } from './Shipment'
import { DescriptionList, TermDescriptionGroup } from '../../DescriptionList'
import i18next from 'i18next'
import { format24HourTime, formatDateRelativeWithLocale, getTimePeriodTime } from '../../../utils/dateTime'
import { DateTime } from 'luxon'
import { GooglePlacesAutocompleteWrapper } from '../../GooglePlacesAutompleteWrapper'
import { Field, FormikErrors, FormikTouched, useFormikContext } from 'formik'
import { QuickEditFormProps } from '../shipmentModalUtils'
import { Department } from '../../../types/coreEntitiesTypes'
import variables from '../../../styles/variables'
import AutoSuggestWrapper from '../../AutoSuggestWrapper'
import { requiredFormik } from '../../../utils/formikInputValidation'
import { AUTOSUGGEST_ADDRESSES } from '../../../actions/actionTypes'
import { IAddress } from '../../../pages/instant/bookingOrderTypes'
import { renderAddressSuggestion } from '../../../pages/instant/bookingFormComponents/commonHelpers'
import { ValidationError } from '../../../primitives/ErrorMessages'
import toLower from 'lodash/toLower'
import { getPhonePrefixFromDepartment } from '../../../pages/instant/bookingOrderFunctions'

type ComponentType = 'pickup' | 'delivery'

export const PickupTimeComponent: React.FC<{ pickupTime?: PickupTime; eta?: DateTime; ata?: DateTime }> = ({
  pickupTime,
  eta,
  ata
}) => (
  <>
    <DescriptionList>
      <TermDescriptionGroup
        term={i18next.t('shipment.pickupDate')}
        description={pickupTime ? formatDateRelativeWithLocale(pickupTime.earliest) : null}
        inline
      />
      <TermDescriptionGroup
        term={i18next.t('shipment.pickupTime')}
        description={pickupTime ? getTimePeriodTime(pickupTime.earliest, pickupTime.latest) : null}
        inline
      />
      <TermDescriptionGroup inline term={i18next.t('shipment.eta')} description={eta ? format24HourTime(eta) : null} />
      <TermDescriptionGroup
        inline
        noMargin
        term={i18next.t('shipment.ata')}
        description={ata ? format24HourTime(ata) : null}
      />
    </DescriptionList>
  </>
)

export const DeliveryTimeComponent: React.FC<{ deliveryTime?: DeliveryTime; eta?: DateTime; ata?: DateTime }> = ({
  deliveryTime,
  eta,
  ata
}) => (
  <>
    <DescriptionList>
      <TermDescriptionGroup
        inline
        term={i18next.t('shipment.deliveryDate')}
        description={deliveryTime ? formatDateRelativeWithLocale(deliveryTime.earliest) : null}
      />
      <TermDescriptionGroup
        inline
        term={i18next.t('shipment.deliveryTime')}
        description={deliveryTime ? getTimePeriodTime(deliveryTime.earliest, deliveryTime.latest) : null}
      />
      <TermDescriptionGroup inline term={i18next.t('shipment.eta')} description={eta ? format24HourTime(eta) : null} />
      <TermDescriptionGroup
        inline
        noMargin
        term={i18next.t('shipment.ata')}
        description={ata ? format24HourTime(ata) : null}
      />
    </DescriptionList>
  </>
)

export const Reference: React.FC<{ reference?: string; title: string }> = ({ reference, title }) => (
  <>
    <DescriptionList>
      <TermDescriptionGroup term={title} description={reference} />
    </DescriptionList>
  </>
)

export const MawbAwbComponent: React.FC<{ awbNo?: string; mawbNo?: string }> = ({ awbNo, mawbNo }) => (
  <>
    <DescriptionList>
      <TermDescriptionGroup inline term={i18next.t('airexpress.info.mawb')} description={mawbNo} />
      <TermDescriptionGroup inline term={i18next.t('airexpress.info.awb')} description={awbNo} />
    </DescriptionList>
  </>
)

export const SearchPlaces: React.FC<{ type: ComponentType; department?: Department }> = ({ type, department }) => {
  const formik = useFormikContext<QuickEditFormProps>()
  const onComplete = useCallback(
    (address: IAddress) => {
      formik.setValues({
        ...formik.values,
        ...doPlaceChange(Map(address), type)
      })
    },
    [formik]
  )

  return useMemo(
    () => (
      <div style={{ position: 'relative', alignSelf: 'end' }}>
        <GooglePlacesAutocompleteWrapper
          style={{ width: 'auto' }}
          searchAroundArea={{
            lat: department?.get('lat'),
            lng: department?.get('lng')
          }}
          rounded={true}
          onComplete={onComplete}
        />
      </div>
    ),
    [formik, onComplete]
  )
}

const doPlaceChange = (address: IAddress, type: ComponentType) => ({
  [`${type}Address`]: address.get('streetname') || address.get('address') || '',
  [`${type}ZipCode`]: address.get('zipCode') || '',
  [`${type}ZipArea`]: address.get('zipArea') || '',
  [`${type}Country`]: toLower(address.get('countryCode') || address.get('country') || ''),
  [`${type}Lat`]: address.get('latLng')?.lat || NaN,
  [`${type}Lng`]: address.get('latLng')?.lng || NaN
})

export const NameWithSearchAddress: React.FC<{
  type: ComponentType
  errors: FormikErrors<QuickEditFormProps>
  touched: FormikTouched<QuickEditFormProps>
  department?: Department
}> = ({ errors, touched, type, department }) => {
  const formik = useFormikContext<QuickEditFormProps>()

  const onSelect = (address: IAddress) => {
    formik.setValues({
      ...formik.values,
      ...doPlaceChange(address, type),
      [`${type}Name`]: address.get('name'),
      [`${type}Phone`]: address.get('phone') || getPhonePrefixFromDepartment(department),
      [`${type}Instructions`]: address.get('instructions') || ''
    })
  }

  return useMemo(
    () => (
      <div style={{ gridColumn: '1/-1' }}>
        <div style={{ color: variables.newColors.darkGrey, fontSize: '0.75rem', marginBottom: '2px' }}>
          {i18next.t('shipment.name')}
        </div>
        <div style={{ position: 'relative' }}>
          <Field
            style={{ height: '40px' }}
            as={AutoSuggestWrapper}
            id={`${type}Name`}
            name={`${type}Name`}
            validate={requiredFormik}
            searchType={AUTOSUGGEST_ADDRESSES}
            onSelect={onSelect}
            departmentId={department?.get('id')}
            suggestionLabel={renderAddressSuggestion}
            value={type === 'pickup' ? formik.values.pickupName : formik.values.deliveryName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.hasOwnProperty('target')) {
                formik.setFieldValue(`${type}Name`, e.target.value)
              }
            }}
          />
          {type === 'pickup'
            ? errors.pickupName && touched.pickupName && <ValidationError>{errors.pickupName}</ValidationError>
            : errors.deliveryName && touched.deliveryName && <ValidationError>{errors.deliveryName}</ValidationError>}
        </div>
      </div>
    ),
    [formik, name]
  )
}
