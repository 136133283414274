import React from 'react'
import { HorizontalSpace } from '../shared/Styles'
import { DescriptionList, TermDescriptionGroup } from '../../DescriptionList'
import { SenderComponent } from './SenderComponent'
import { Pickup } from '../shared/Shipment'
import i18next from 'i18next'
import { H2 } from '../../../primitives/Headings'
import { Reference, PickupTimeComponent, MawbAwbComponent } from '../shared/CommonComponents'
import { RoutePoint } from '../../../types/coreEntitiesTypes'
import { getAta } from '../../routePointHelper'

export const PickupComponent: React.FC<{
  pickup: Pickup
  pickupRoutePoint?: RoutePoint
  displayMawbAwb?: boolean
  awbNo?: string
  mawbNo?: string
}> = ({ pickup, pickupRoutePoint, displayMawbAwb, awbNo, mawbNo }) => (
  <>
    <H2>{i18next.t('shipment.pickupInfo')}</H2>
    <SenderComponent sender={pickup.sender} />
    <HorizontalSpace />
    <PickupTimeComponent pickupTime={pickup.pickupTime} eta={pickup.eta} ata={getAta(pickupRoutePoint)} />
    <HorizontalSpace />
    <Reference reference={pickup.sender.reference} title={i18next.t('shipment.customerReference')} />
    <HorizontalSpace />
    <PickupInstructions instructions={pickup.pickupInstructions} />
    {displayMawbAwb && (
      <>
        <HorizontalSpace />
        <MawbAwbComponent awbNo={awbNo} mawbNo={mawbNo} />
      </>
    )}
  </>
)

const PickupInstructions: React.FC<{ instructions: string }> = ({ instructions }) => (
  <>
    <DescriptionList>
      <TermDescriptionGroup term={i18next.t('shipment.pickupInstructions')} description={instructions} />
    </DescriptionList>
  </>
)
