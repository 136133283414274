import i18next from 'i18next'
import {
  email,
  isValidTimezone,
  phone,
  required,
  validDate,
  validDateOrBlank,
  validDeviationCode,
  validNumber,
  validPhonePrefix,
  validPositiveNumber,
  validTime,
  validTimeOrBlank,
  validTrimmedToNumber,
  validZipCode
} from './inputValidation'
import { isEmpty } from './collectionUtils'
import {
  allowedZipCodeRanges,
  openBookingIsZipCodesInSameRange,
  openBookingZipCodeInAValidAreaRanges,
  openBookingZipCodeInAValidRange
} from '../pages/open/booking/OpenBookingValidation'
import {
  dateInTheFuture,
  firstTimeIsBeforeSecondTime,
  isWeekendDay,
  isWithinServiceTimeWindow,
  timeMinusHours,
  toLocaleDate
} from './dateTime'
import { isEmpty as isEmptyString } from './stringUtils'
import { DateTime } from 'luxon'

export const requiredFormik = (value: any) => (required(value) ? undefined : i18next.t('application.requiredShort'))

export const validNumberOrBlankFormik = (value: string) =>
  !value || validNumber(value) ? undefined : i18next.t('error.notAValidNumber')

export const positiveNumberOrBlankFormik = (value: string) =>
  !value || validPositiveNumber(value) ? undefined : i18next.t('error.notAValidNumber')

export const validEmailFormik = (value: string) =>
  email(value) ? undefined : i18next.t('instant.booking.invalidEmail')

export const requiredValidEmail = (value: string) =>
  required(value) ? validEmailFormik(value) : i18next.t('application.requiredShort')

export const validDateFormik = (value: string) => (validDate(value) ? undefined : i18next.t('error.notAValidDate'))

export const validTimezoneFormik = (value: string) =>
  required(value) ? (isValidTimezone(value) ? undefined : i18next.t('error.notAValidTimezone')) : undefined

export const validDeviationCodeFormik = (value: string) =>
  required(value) ? (validDeviationCode(value) ? undefined : i18next.t('application.validDeviationCode')) : undefined

export const validDateOrBlankFormik = (value: string) =>
  validDateOrBlank(value) ? undefined : i18next.t('error.notAValidDate')

export const validTimeFormik = (value: string) => (validTime(value) ? undefined : i18next.t('error.notAValidTime'))

export const validTimeOrBlankFormik = (value: string) =>
  validTimeOrBlank(value) ? undefined : i18next.t('error.notAValidTime')

export const validMultiSelectFormik = (value: any) => (isEmpty(value) ? i18next.t('error.selectAtLeastOne') : undefined)

export const validTrimmedToNumberFormik = (value: any) =>
  required(value)
    ? validTrimmedToNumber(value)
      ? undefined
      : i18next.t('error.notAValidNumber')
    : i18next.t('application.requiredShort')

export const requiredPhonePrefixFormik = (value: any) => requiredFormik(value) || validPhonePrefixFormik(value)

export const validPhonePrefixFormik = (value: any) =>
  phone(value)
    ? validPhonePrefix(value)
      ? undefined
      : i18next.t('error.phoneNumberPrefix')
    : i18next.t('error.phoneNumber')

export const validZipCodeFormik = (value: string) =>
  required(value)
    ? validZipCode(value)
      ? undefined
      : i18next.t('error.notAValidZipCode')
    : i18next.t('application.requiredShort')

export const optionalValidZipCodeFormik = (value: string) =>
  required(value) ? (validZipCode(value) ? undefined : i18next.t('error.notAValidZipCode')) : undefined

export const zipCodesInSameRange = (value1: string, value2: string) =>
  openBookingIsZipCodesInSameRange(value1, value2)
    ? validZipCodeFormik(value1) && validZipCodeFormik(value2)
    : i18next.t('openBooking.error.notInSameZipCodeRange')

export const getZipCodeInAreaRangeError = (zipCode: string, zipArea: string) =>
  openBookingZipCodeInAValidAreaRanges(zipCode, zipArea)
    ? undefined
    : i18next.t('openBooking.error.notInAreaRangeZipCode')

export const zipCodeInRanges = (value: string, ranges: number[][] = allowedZipCodeRanges) =>
  openBookingZipCodeInAValidRange(value, ranges)
    ? validZipCodeFormik(value)
    : i18next.t('openBooking.error.notInRangeZipCode')

export const validPasswordFormik = (value: string) => {
  let error: string | undefined = undefined
  if (!required(value)) {
    error = i18next.t('application.requiredShort')
  } else if (!/\d/.test(value)) {
    error = 'Must contain at least one number'
  } else if (!/[a-z]/.test(value)) {
    error = 'Must contain at least one lowercase letter'
  } else if (!/[A-Z]/.test(value)) {
    error = 'Must contain at least one uppercase letter'
  } else if (value.length < 15) {
    error = 'Must be minimum 15 characters'
  }
  return error
}

export const validateConfirmPasswordFormik = (value1: string, value2: string) =>
  value1 !== value2 ? "Passwords don't match" : undefined

export const validateEmailFormik = (value1: string, value2: string) =>
  value1 !== value2 ? "Emails don't match" : undefined

export const getPickupTimeError = (
  servicePickupTimeFrom: string,
  servicePickupTimeTo: string,
  pickupTime: string,
  servicePickupTimeInterval: string,
  pickupDate: string
) => {
  if (isEmptyString(pickupTime)) return i18next.t('application.requiredShort')

  if (
    !isWithinServiceTimeWindow(
      toLocaleDate(servicePickupTimeFrom),
      timeMinusHours(toLocaleDate(servicePickupTimeTo), parseInt(servicePickupTimeInterval)),
      toLocaleDate(pickupTime)
    )
  )
    return i18next.t('openBooking.error.pickupTimeNotWithinServiceTimeWindow')

  if (
    firstTimeIsBeforeSecondTime(toLocaleDate(pickupTime), toLocaleDate(servicePickupTimeFrom)) ||
    (firstTimeIsBeforeSecondTime(toLocaleDate(pickupTime), DateTime.local()) && !dateInTheFuture(pickupDate))
  )
    return i18next.t('openBooking.error.pickupTimeBeforeEarliestServicePickupTime')

  return undefined
}

export const getDateError = (pickupDate: string) =>
  isWeekendDay(toLocaleDate(pickupDate)) ? i18next.t('openBooking.error.pickupNotAvailableForWeekend') : undefined

export const organisationNameAndNumberCannotBeEqual = (organisationName: string, organisationNumber: string) => {
  return organisationName.trim().toLowerCase() === organisationNumber.trim().toLowerCase()
    ? i18next.t('openBooking.error.invalidOrganisationNumber')
    : undefined
}
