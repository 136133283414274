import {
  FormikDate,
  FormikInput,
  FormikRadioSelect,
  FormikTime,
  formikValidationRequired
} from '@glow/formik-components'
import { IconFa, LayoutFormRow, LayoutFormSection, Option } from '@glow/ui-components'
import i18next from 'i18next'
import { List } from 'immutable'
import React, { ReactNode, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { departmentsSelector } from '../../../selectors/departmentsSelector'
import { DepartmentIdType, OrderIdType } from '../../../types/coreEntitiesTypes'
import { AppStateType } from '../../../utils/appStateReduxStore'
import { userLanguage } from '../../../utils/dateTime'
import { selectDeviationOptions } from '../../../utils/deviation'
import { ManualOverrideFormProps, PartialConsignmentMap } from './ManualOverrideView'
import { UnitDriverForm } from './deviations/UnitDriverForm'

interface FormProps {
  values: ManualOverrideFormProps
  orderIds: List<OrderIdType>
  departmentIds: List<DepartmentIdType>
}

interface DeviatedFormProps extends FormProps {
  consignments: List<PartialConsignmentMap>
}

const UnitDriverDateBaseForm = ({
  values,
  orderIds,
  departmentIds,
  children
}: Omit<FormProps, 'setFieldValue'> & { children?: ReactNode }) => (
  <LayoutFormSection>
    <UnitDriverForm orderIds={orderIds} departmentIds={departmentIds} />
    <LayoutFormRow columns={3}>
      <FormikDate
        value={values.date}
        icon={<IconFa icon={['far', 'calendar-alt']} />}
        name="date"
        lang={userLanguage}
        label={i18next.t('shipmentDetails.selectDate')}
      />
      <FormikTime name="time" label={i18next.t('shipmentDetails.time')} />
      {children}
    </LayoutFormRow>
  </LayoutFormSection>
)

export const CollectedForm = ({ values, orderIds, departmentIds }: FormProps) => (
  <UnitDriverDateBaseForm values={values} orderIds={orderIds} departmentIds={departmentIds} />
)
export const ReturnedAndDeliveredForm = ({ values, orderIds, departmentIds }: FormProps) => (
  <UnitDriverDateBaseForm values={values} orderIds={orderIds} departmentIds={departmentIds}>
    <FormikInput
      name="name"
      validate={(value: string) => formikValidationRequired(value, i18next.t('shipmentDetails.required'))}
      label={i18next.t('consignment.recipientName')}
      required
    />
  </UnitDriverDateBaseForm>
)
export const DeviatedForm = ({ values, orderIds, departmentIds, consignments }: DeviatedFormProps) => {
  const options = selectDeviationOptions(consignments)

  const selectedDeviationLabel = useMemo(() => {
    return options.find((courier) => courier.type !== 'header' && courier.code === values.deviationCode)?.label
  }, [values.deviationCode])
  return (
    <UnitDriverDateBaseForm values={values} orderIds={orderIds} departmentIds={departmentIds}>
      <FormikRadioSelect
        name="deviationCode"
        className="mr-4"
        options={options}
        label={i18next.t('shipmentDetails.selectDeviation')}
        aria-label={i18next.t('shipmentDetails.selectDeviation')}
        placeholder={i18next.t('shipmentDetails.selectDeviation')}
        selectedLabel={selectedDeviationLabel}
      />
    </UnitDriverDateBaseForm>
  )
}

export const ScannedForm = ({ values }: { values: ManualOverrideFormProps }) => {
  const departments = useSelector((state: AppStateType) => departmentsSelector(state))
  const departmentsForUser: Option[] = useMemo(
    () =>
      departments.map((department) => ({ code: department.get('id') + '', label: department.get('name') })).toArray(),
    [departments]
  )

  const selectedDepartmentLabel = useMemo(() => {
    return departmentsForUser.find((department) => department.code == values.departmentId)?.label
  }, [departmentsForUser, values.departmentId])

  return (
    <LayoutFormRow columns={3}>
      <FormikDate
        value={values.date}
        icon={<IconFa icon={['far', 'calendar-alt']} />}
        name="date"
        lang={userLanguage}
        label={i18next.t('shipmentDetails.selectDate')}
      />
      <FormikTime name="time" label={i18next.t('shipmentDetails.time')} type="time" />
      <FormikRadioSelect
        name="departmentId"
        options={departmentsForUser}
        label={i18next.t('manualOverride.selectDepartment')}
        aria-label={i18next.t('manualOverride.selectDepartment')}
        placeholder={i18next.t('manualOverride.selectDepartment')}
        selectedLabel={selectedDepartmentLabel}
      />
    </LayoutFormRow>
  )
}

export const SortedForm = ({ values }: { values: ManualOverrideFormProps }) => (
  <div className="flex gap-4">
    <div className="w-36">
      <FormikDate
        value={values.date}
        icon={<IconFa icon={['far', 'calendar-alt']} />}
        name="date"
        lang={userLanguage}
        label={i18next.t('shipmentDetails.selectDate')}
      />
    </div>

    <div className="w-36">
      <FormikTime name="time" label={i18next.t('shipmentDetails.time')} />
    </div>
  </div>
)
