import React from 'react'
import { FlexRow } from '../../../../primitives/Flex'
import { Shipment } from '../../../../types/coreEntitiesTypes'
import variables from '../../../../styles/variables'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TertiaryButton } from '../../../../primitives/Button'
import { formatTimeWindow, getDeliveryTime, isValidDeliveryTime } from './bookingTimeUtils'
import { DateTime } from 'luxon'
import i18next from 'i18next'

export const BookedInfoRow: React.FC<{
  shipment: Shipment
  onRemoveBookingTimes: () => void
}> = ({ shipment, onRemoveBookingTimes }) => {
  const hasBooked = isValidDeliveryTime(getDeliveryTime(shipment))
  return hasBooked ? (
    <BookedRow justifyContent={'space-between'} alignItems={'center'} style={{ padding: '0.4em' }}>
      <FlexRow justifyContent={'start'}>
        <FontAwesomeIcon
          style={{ marginLeft: '5px', marginRight: '10px', marginTop: '2px' }}
          icon="check"
          color={variables.newColors.black}
        />
        <div>
          {i18next.t('bookingTimeWindow.bookedInfo', {
            date: DateTime.fromISO(shipment.get('deliveryTimeEarliest')).toFormat('EEEE, d. MMM'),
            timeWindow: formatTimeWindow(getDeliveryTime(shipment))
          })}
        </div>
      </FlexRow>
      <RemoveButton onClick={() => onRemoveBookingTimes()}>
        {i18next.t('bookingTimeWindow.removeBookingButton')}
      </RemoveButton>
    </BookedRow>
  ) : (
    <NotBookedRow justifyContent={'center'} alignItems={'center'}>
      <FlexRow>
        <FontAwesomeIcon
          style={{ marginRight: '1em', marginTop: '2px' }}
          icon="calendar-alt"
          color={variables.newColors.darkGrey}
        />
        <div>{i18next.t('bookingTimeWindow.notBookedInfo')}</div>
      </FlexRow>
    </NotBookedRow>
  )
}

const BookedRow = styled(FlexRow)`
  margin-bottom: 1.9em;
  background: ${variables.newColors.lightGreen};
  border-radius: 10px;
`

const NotBookedRow = styled(FlexRow)`
  padding: 0.7em;
  margin-bottom: 2em;
  background: ${variables.newColors.orbitGrey};
  border-radius: 10px;
`

const RemoveButton = styled(TertiaryButton)`
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-color: ${variables.newColors.spaceGrey};
  height: 30px;
  background: ${variables.newColors.lightGreen};
`
