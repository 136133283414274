import React from 'react'
import { HorizontalSpace } from '../shared/Styles'
import { DescriptionList, TermDescriptionGroup } from '../../DescriptionList'
import { ReceiverComponent } from './ReceiverComponent'
import { Delivery } from '../shared/Shipment'
import { ExternalLink } from '../../../primitives/Typography'
import { FlexRow } from '../../../primitives/Flex'
import i18next from 'i18next'
import { H2 } from '../../../primitives/Headings'
import { DeliveryTimeComponent, MawbAwbComponent, Reference } from '../shared/CommonComponents'
import { RoutePoint } from '../../../types/coreEntitiesTypes'
import { getAta } from '../../routePointHelper'
import { ServiceLevelBackend } from '../../../domain/customer'

export const DeliveryComponent: React.FC<{
  delivery: Delivery
  serviceLevel: ServiceLevelBackend
  deliveryRoutePoint?: RoutePoint
  displayMawbAwb?: boolean
  awbNo?: string
  mawbNo?: string
}> = ({ delivery, serviceLevel, deliveryRoutePoint, displayMawbAwb, awbNo, mawbNo }) => {
  const { receiver, trackingURL } = delivery
  return (
    <>
      <H2>{i18next.t('shipment.deliveryInfo')}</H2>
      <ReceiverComponent {...{ receiver, serviceLevel }} />
      <HorizontalSpace />
      <DeliveryTimeComponent deliveryTime={delivery.deliveryTime} eta={delivery.eta} ata={getAta(deliveryRoutePoint)} />
      <HorizontalSpace />
      <Reference reference={receiver.reference} title={i18next.t('shipment.receiverReference')} />
      <HorizontalSpace />
      <DeliveryInstructions instructions={delivery.deliveryInstructions} />
      {displayMawbAwb && (
        <>
          <HorizontalSpace />
          <MawbAwbComponent awbNo={awbNo} mawbNo={mawbNo} />
        </>
      )}
      {delivery.showTrackingUrl && (
        <>
          <HorizontalSpace />
          {trackingURL && <TrackingLink url={trackingURL} />}
        </>
      )}
    </>
  )
}

const DeliveryInstructions: React.FC<{ instructions: string }> = ({ instructions }) => (
  <>
    <DescriptionList>
      <TermDescriptionGroup term={i18next.t('shipment.deliveryInstructions')} description={instructions} />
    </DescriptionList>
  </>
)

const TrackingLink: React.FC<{ url: string }> = ({ url }) => (
  <FlexRow justifyContent="end">
    <ExternalLink href={url} target="_blank" rel="noopener noreferrer">
      {i18next.t('shipment.trackingPage')}
    </ExternalLink>
  </FlexRow>
)
