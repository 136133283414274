import { eventDataValue } from '@glow/entity-types'
import i18next from 'i18next'
import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { ConsignmentEventConfirmed } from '../../../../components/ConsignmentEventConfirmed'
import { IConsignmentEvent } from '../../../../types/coreEntitiesTypes'
import { ImmutableMap } from '../../../../types/immutableTypes'
import { EventImageLink, EventImageTag } from './event-image'
import { EventMap } from './event-map'
import { EventRecipient } from './event-recipient'
import { EventSelfBilling } from './event-selfbilling'

export function EventDefault(props: { data: AnyData; consignmentEvent: ImmutableMap<IConsignmentEvent> }) {
  return (
    <>
      <DeliveredWithoutSignatureReason data={props.data} />
      <EventImageTag data={props.data} path={['filename']} />
      <EventRecipient data={props.data} />
      <EventMap data={props.data} />
      <EventImageLink data={props.data} path={['flexImageFilename']} />
      <ConsignmentEventConfirmed
        consignmentEventId={props.consignmentEvent.get('id')}
        consignmentEventType={props.consignmentEvent.get('type')}
      />
      <EventSelfBilling data={props.data} />
    </>
  )
}

function DeliveredWithoutSignatureReason(props: { data: AnyData }) {
  const deliverWithoutSignatureReason = eventDataValue(props.data as Record<string, unknown> | null, [
    'deliverWithoutSignatureReason'
  ])

  if (!deliverWithoutSignatureReason) {
    return null
  }

  return (
    <p>
      <strong>{i18next.t('consignmentEvent.message.deliveredWithoutSignature')}: </strong>
      {/* dynamic i18next */}
      {i18next.t(`consignmentEvent.message.deliveredWithoutSignatureReason.${deliverWithoutSignatureReason}`)}
    </p>
  )
}
