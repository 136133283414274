import React from 'react'
import ImmutableComponent from '../primitives/ImmutableComponent'
import { DateTime } from 'luxon'
import {
  closeDrawer,
  openDrawer,
  openModal,
  setPageStateValue,
  unassignConsignmentsFromSlot
} from '../actions/creators/helpers'
import { connect } from 'react-redux'
import i18next from 'i18next'
import ShipmentEditModal from './ShipmentEditModal'
import ConnectedShipmentModal from './ShipmentModal'
import { stopPropagating } from '../pages/instant/InstantGridRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Set } from 'immutable'
import { AppStateType } from '../utils/appStateReduxStore'
import { Shipment, Slot } from '../types/coreEntitiesTypes'
import { AnyData } from '../actions/creators/baseHelpers'
import { isNotEmpty } from '../utils/collectionUtils'
import { noop } from '../pages/instant/InstantGrid'
import variables from '../styles/variables'

export const unassignTooltip = i18next.t('instant.instantGrid.unassignColumnTooltip', 'Click to unassign shipment')
export const UnassignIcon = <FontAwesomeIcon icon="sign-out-alt" title={unassignTooltip} />

class PickupDeliveryInformation extends ImmutableComponent<PickupDeliveryInformationProps> {
  render() {
    const { shipment, slot, shouldOpenModal = true, assignToSlotSelector = null } = this.props
    const pickupTimeEarliest = shipment?.get('pickupTimeEarliestCached')
    const pickupTimeLatest = shipment?.get('pickupTimeLatestCached')
    const deliveryTimeEarliest = shipment?.get('deliveryTimeEarliestCached')
    const deliveryTimeLatest = shipment?.get('deliveryTimeLatestCached')
    const backgroundColor = slot ? slot.get('color') : '#000000'

    const onClick = shouldOpenModal
      ? () => {
          const modalToUse = this.props.allowEdit ? ShipmentEditModal : ConnectedShipmentModal
          this.props.openModal(modalToUse, {
            shipmentId: shipment?.get('orderId'),
            includeOverlay: false
          })
        }
      : () => {
          const prevId = this.props.shipmentId
          const id = shipment?.get('orderId')
          if (id !== null && id !== prevId) {
            this.props.openDrawer('ShipmentInfo', { shipmentId: id })
            this.props.setPageStateValue('shipmentId', id)
          } else {
            this.props.closeDrawer()
            this.props.setPageStateValue('shipmentId', null)
          }
        }

    const onUnassignFromSlotClick = () => {
      const slotId = shipment?.get('slotId')
      if (slotId && slotId !== null && isNotEmpty(shipment)) {
        // @ts-ignore
        this.props.unassignConsignmentsFromSlot(slotId, shipment?.get('consignmentIds')?.toSet())
      }
    }

    const weightValue = shipment?.get('weightValue') || 0

    return (
      <div
        style={{ backgroundColor: backgroundColor + '15', padding: '12px', cursor: 'pointer', marginBottom: '10px' }}
      >
        <div onClick={stopPropagating(onClick)}>
          <strong>
            {i18next.t(`planner.${this.props.type}`)} ({shipment?.get('shipmentId')})
          </strong>
          :
          {this.props.allowUnassign && (
            <button type="button" title={unassignTooltip} onClick={stopPropagating(onUnassignFromSlotClick)}>
              {UnassignIcon}&nbsp;
            </button>
          )}
          <br />
          {weightValue > 0 && (
            <>
              {i18next.t('instant.booking.totalWeight', { weight: weightValue.toFixed(1) })} kg
              <br />
            </>
          )}
          {i18next.t('planner.slot')}:{' '}
          {slot ? (
            <span style={{ color: variables.colors.link }}>{slot.get('name')}</span>
          ) : (
            <span>{i18next.t('planner.notAssigned')}</span>
          )}
          <br />
          {this.props.type === 'delivery' && (
            <>
              {deliveryTimeEarliest?.toLocaleString(DateTime.TIME_24_SIMPLE)} -{' '}
              {deliveryTimeLatest?.toLocaleString(DateTime.TIME_24_SIMPLE)}
              <br />({i18next.t('planner.pickup')}: {shipment?.get('pickupAddress')},{' '}
              {pickupTimeEarliest?.toLocaleString(DateTime.TIME_24_SIMPLE)} -{' '}
              {pickupTimeLatest?.toLocaleString(DateTime.TIME_24_SIMPLE)})
            </>
          )}
          {this.props.type === 'pickup' && (
            <>
              {pickupTimeEarliest?.toLocaleString(DateTime.TIME_24_SIMPLE)} -{' '}
              {pickupTimeLatest?.toLocaleString(DateTime.TIME_24_SIMPLE)}
              <br />({i18next.t('planner.delivery')}: {shipment?.get('deliveryAddress')},{' '}
              {deliveryTimeEarliest?.toLocaleString(DateTime.TIME_24_SIMPLE)} -{' '}
              {deliveryTimeLatest?.toLocaleString(DateTime.TIME_24_SIMPLE)})
            </>
          )}
        </div>
        {!slot && assignToSlotSelector && (
          <div style={{ marginTop: '12px', breakBefore: 'all' }} onClick={stopPropagating(noop)}>
            {assignToSlotSelector(shipment?.get('orderId') ?? -1)}
          </div>
        )}
      </div>
    )
  }
}

interface StateProps {
  shipmentId: number
}

interface DispatchProps {
  openModal: (modalClass: any, props: any) => void
  openDrawer: (drawerClass: string, props: AnyData) => void
  closeDrawer: () => void
  setPageStateValue: (key: string, value: any) => void
  unassignConsignmentsFromSlot: (slotId: number, consignmentIds: Set<number>) => void
}

interface OwnProps {
  slot: Slot
  shouldOpenModal?: boolean
  allowEdit?: boolean
  allowUnassign?: boolean
  shipment?: Shipment
  type: 'pickup' | 'delivery'
  assignToSlotSelector?: (shipmentId: number) => JSX.Element
}

type PickupDeliveryInformationProps = StateProps & DispatchProps & OwnProps

export default connect<StateProps, DispatchProps, OwnProps, AppStateType>(
  (state) => {
    return {
      shipmentId: state.getIn(['pageState', 'shipmentId'])
    }
  },
  { openModal, openDrawer, closeDrawer, setPageStateValue, unassignConsignmentsFromSlot }
)(PickupDeliveryInformation)
