import { ELLIPSIS, formatFieldName, subStringWithSuffixOverLimit } from '@glow/common'
import { eventDataValue } from '@glow/entity-types'
import i18next from 'i18next'
import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { Label, LabelGroup } from '../ShipmentDetailsStyles'

export function EventUpdated(props: { data: AnyData }) {
  return (
    <>
      <EventUpdatedComment data={props.data} />
      <EventUpdatedList data={props.data} />
    </>
  )
}

function EventUpdatedComment(props: { data: AnyData }) {
  const comment = eventDataValue(props.data as Record<string, unknown> | null, ['comment'])

  if (!comment) {
    return null
  }

  return (
    <p>
      <strong>{i18next.t('consignment.comment')}: </strong>
      {comment}
    </p>
  )
}

function EventUpdatedList(props: { data: AnyData }) {
  const data = props.data as Record<string, unknown> | null
  if (!data) {
    return null
  }

  const updates = Object.keys(data).filter((k) => k !== 'comment')
  if (updates.length === 0) {
    return null
  }

  return (
    <ul className="list-plain">
      {updates.map((u) => (
        <li key={u} className="list-element">
          <EventUpdatedItem field={u} value={data[u]} />
        </li>
      ))}
    </ul>
  )
}

function EventUpdatedItem(props: { field: string; value: unknown }) {
  if (hasFromToFields(props.value)) {
    return (
      <LabelGroup className="body2">
        <Label>{formatFieldName(props.field)}</Label>
        {subStringWithSuffixOverLimit(formatEventsDataOrBlank(props.value.from), 50, ELLIPSIS)}
        {' → '}
        {subStringWithSuffixOverLimit(formatEventsDataOrBlank(props.value.to), 50, ELLIPSIS)}
      </LabelGroup>
    )
  }

  return (
    <LabelGroup className="body2">
      <Label>{formatFieldName(props.field)}</Label>
      {formatEventsDataOrBlank(props.value)}
    </LabelGroup>
  )
}

function hasFromToFields(value: unknown): value is {
  from: unknown
  to: unknown
} {
  return Boolean(value) && typeof value === 'object' && value !== null && 'from' in value && 'to' in value
}

export function formatEventsDataOrBlank(value: unknown) {
  if (typeof value === 'string' || typeof value === 'number' || value === null || value === undefined) {
    return value || i18next.t('consignmentEvent.blankText')
  } else if (typeof value === 'object' && !Array.isArray(value)) {
    return Object.entries(value)
      .map(([key, value]) => key + ': ' + value)
      .join(', ')
  } else {
    return JSON.stringify(value)
  }
}
