import { H3 } from '../../../primitives/Headings'
import i18next from 'i18next'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { InputForLabel } from '../../../primitives/Forms'
import Select from '../../../primitives/Select'
import React from 'react'
import { getMeasurementVolume } from '../bookingOrderFunctions'
import { LabeledInput } from '../../../primitives/LabeledInput'
import { required, validNumber } from '../../../utils/inputValidation'
import { SmallValidationError } from '../../../primitives/ErrorMessages'
import { TertiaryButton } from '../../../primitives/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ClearInstantBookingFormButton } from '../../../components/ClearInstantBookingFormButton'
import { measurementComponentNames } from '../bookingSupport'
import InputGroup from '../InputGroup'
import { IOrderForm, ISize } from '../bookingOrderTypes'
import ImmutableComponent from '../../../primitives/ImmutableComponent'
import { isNotEmpty } from '../../../utils/collectionUtils'
import { List, Map } from 'immutable'
import { CustomMeasurement } from '../../../types/coreEntitiesTypes'
import { BookingTotalWeightAndVolume } from '../BookingTotalWeightAndVolume'
import { PlainButton, IconFa, Tooltip } from '@glow/ui-components'
import { Disclosure } from '@headlessui/react'
import { getDefaultPresetMeasurement } from '../Booking'

interface Props {
  formValues: IOrderForm
  mergeFormValues: (model: string, values: Map<string, any>) => void
  addToFormList: (formName: string, values: Map<string, string>) => void
  removeFromFormList: (model: string, index: number) => void
  showClearButton?: boolean
  availableMeasurements: List<ISize>
  customMeasurements?: List<CustomMeasurement>
  openCustomMeasurementsModal?: () => void
  formName: string
  clearComponents?: (components: List<string>) => void
  specificationRequired?: boolean
}

class BookingFormGoods extends ImmutableComponent<Props> {
  onTemplateDimensionChanged = (e: React.ChangeEvent<HTMLInputElement>, model: string) => {
    let v = this.props.availableMeasurements.find((it: ISize) => it.get('presetDimension') === e.target.value)

    if (!v && this.props.customMeasurements) {
      v = this.props.customMeasurements.find(
        (it: CustomMeasurement) => it.get('presetDimension') === e.target.value
      ) as unknown as ISize
    }
    if (isNotEmpty(v) && v) {
      this.props.mergeFormValues(
        this.props.formName + model,
        Map({
          weight: v.get('weight').toString(),
          height: v.get('height').toString(),
          length: v.get('length').toString(),
          width: v.get('width').toString(),
          presetDimension: v.get('presetDimension')
        })
      )
    }
  }

  copyMeasurement = (i: number) => (e: React.MouseEvent) => {
    e.preventDefault()
    const measurements = this.props.formValues.get('measurements')
    if (measurements && measurements.count() > i) {
      const measurementToCopy = measurements.get(i)
      if (measurementToCopy) {
        this.props.addToFormList(`${this.props.formName}.measurements`, measurementToCopy.delete('packageId'))
      }
    }
  }
  addMeasurement = (e: React.MouseEvent) => {
    e.preventDefault()
    this.props.addToFormList(
      `${this.props.formName}.measurements`,
      getDefaultPresetMeasurement(this.props.specificationRequired)
    )
  }

  removeMeasurement = (i: number) => (e: React.MouseEvent) => {
    e.preventDefault()
    if (this.props.formValues.get('measurements').count() > 1) {
      this.props.removeFromFormList(`${this.props.formName}.measurements`, i)
    }
  }

  getMeasurementVolumeByIndex = (index: number) => {
    const measurement = this.props.formValues.get('measurements').get(index)
    if (measurement) {
      return getMeasurementVolume(measurement)
    } else {
      return 0
    }
  }

  render() {
    const { formValues, showClearButton = true, clearComponents, specificationRequired = false } = this.props

    const canRemoveMeasurements = formValues.get('measurements', List()).count() > 1

    let defaultMeasurements = this.props.availableMeasurements.map((size: ISize) => {
      const id = size.get('presetDimension')
      return (
        <option key={`dimension-${id}`} value={id}>
          {i18next.t(`instant.goods.${size.get('presetDimension')}`)}
        </option>
      )
    })

    let customMeasurements = this.props.customMeasurements?.map((size: CustomMeasurement) => {
      return (
        <option key={`dimension-${size.get('id')}`} value={size.get('presetDimension')}>
          {size.get('name')}
        </option>
      )
    })

    return (
      <InputGroup aria-label="Booking goods form">
        <H3> {i18next.t('instant.booking.dimensions')}</H3>
        {formValues.get('measurements') &&
          formValues.get('measurements').map((measurement, i) => {
            const packageId = formValues.get('measurements').get(i)?.get('packageId')
            return (
              <React.Fragment key={`${packageId}_${i}`}>
                {packageId && (
                  <div
                    style={{
                      marginBottom: 8,
                      fontWeight: 'bold'
                    }}
                  >
                    {i18next.t('consignment.packageId')}: {packageId}
                  </div>
                )}
                <Disclosure defaultOpen={i === 0}>
                  {({ open }) => (
                    <div className="mb-4">
                      <div
                        key={i}
                        className="grid gap-2 mb-2"
                        style={{
                          gridTemplateColumns: 'auto 1em 1em 10fr 6fr 6fr 6fr 6fr auto auto 100px'
                        }}
                      >
                        <div>
                          <Control.text
                            component={InputForLabel}
                            model={`.measurements[${i}].packageId`}
                            id={`packageId[${i}]`}
                            type="hidden"
                            style={{ height: 0 }}
                          />
                        </div>

                        <div style={{ marginRight: '1em', padding: '0.5em' }}>
                          <strong>{i + 1}</strong>
                        </div>
                        <Disclosure.Button>
                          <IconFa icon={['fas', open ? 'angle-down' : 'angle-right']} />
                        </Disclosure.Button>
                        <div>
                          <Select expand>
                            <Control.select
                              style={{ paddingTop: '0.5rem' }}
                              model={`.measurements[${i}].presetDimension`}
                              id={`presetDimension-${i}`}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                this.onTemplateDimensionChanged(e, `.measurements[${i}]`)
                              }
                            >
                              {customMeasurements && customMeasurements.count() ? (
                                <>
                                  <optgroup label={i18next.t('customMeasurements.customGoodsTypes')}>
                                    {customMeasurements}
                                  </optgroup>
                                  <optgroup label={i18next.t('customMeasurements.defaultGoodsTypes')}>
                                    {defaultMeasurements}
                                  </optgroup>
                                </>
                              ) : (
                                defaultMeasurements
                              )}
                            </Control.select>
                          </Select>
                        </div>

                        <div>
                          <LabeledInput label={i18next.t('instant.booking.weightPlaceholder')} htmlFor={`weight-${i}`}>
                            <Control.text
                              autoComplete="nope"
                              model={`.measurements[${i}].weight`}
                              id={`weight-${i}`}
                              component={InputForLabel}
                              validators={{ validNumber, required }}
                            />
                          </LabeledInput>
                          <Errors
                            className="errors"
                            model={`.measurements[${i}].weight`}
                            show="touched"
                            wrapper={SmallValidationError}
                            messages={{
                              validNumber: `${i18next.t('error.notAValidNumber')}`,
                              required: `${i18next.t('application.required')}`
                            }}
                          />
                        </div>
                        <div>
                          <LabeledInput label={i18next.t('instant.booking.lengthPlaceholder')} htmlFor={`length-${i}`}>
                            <Control.text
                              autoComplete="nope"
                              model={`.measurements[${i}].length`}
                              id={`length-${i}`}
                              component={InputForLabel}
                              validators={{ validNumber, required }}
                            />
                          </LabeledInput>
                          <Errors
                            className="errors"
                            model={`.measurements[${i}].length`}
                            show="touched"
                            wrapper={SmallValidationError}
                            messages={{
                              validNumber: `${i18next.t('error.notAValidNumber')}`,
                              required: `${i18next.t('application.required')}`
                            }}
                          />
                        </div>

                        <div>
                          <LabeledInput label={i18next.t('instant.booking.widthPlaceholder')} htmlFor={`width-${i}`}>
                            <Control.text
                              autoComplete="nope"
                              model={`.measurements[${i}].width`}
                              id={`width-${i}`}
                              component={InputForLabel}
                              validators={{ validNumber, required }}
                            />
                          </LabeledInput>
                          <Errors
                            className="errors"
                            model={`.measurements[${i}].width`}
                            show="touched"
                            wrapper={SmallValidationError}
                            messages={{
                              validNumber: `${i18next.t('error.notAValidNumber')}`,
                              required: `${i18next.t('application.required')}`
                            }}
                          />
                        </div>

                        <div>
                          <LabeledInput label={i18next.t('instant.booking.heightPlaceholder')} htmlFor={`height-${i}`}>
                            <Control.text
                              autoComplete="nope"
                              model={`.measurements[${i}].height`}
                              id={`height-${i}`}
                              component={InputForLabel}
                              validators={{ validNumber, required }}
                            />
                          </LabeledInput>

                          <Errors
                            className="errors"
                            model={`.measurements[${i}].height`}
                            show="touched"
                            wrapper={SmallValidationError}
                            messages={{
                              validNumber: `${i18next.t('error.notAValidNumber')}`,
                              required: `${i18next.t('application.required')}`
                            }}
                          />
                        </div>

                        <div>
                          <Tooltip
                            referenceId={packageId ?? '' + i + 'copy'}
                            label={i18next.t('instant.booking.copyPackageRow')}
                          >
                            <PlainButton
                              type="button"
                              id={packageId ?? '' + i + 'copy'}
                              className="p-2"
                              onClick={this.copyMeasurement(i)}
                            >
                              <IconFa icon={['far', 'copy']} />
                            </PlainButton>
                          </Tooltip>
                        </div>
                        <div>
                          <Tooltip
                            referenceId={packageId ?? '' + i + 'delete'}
                            label={i18next.t(
                              `instant.booking.${
                                canRemoveMeasurements ? 'removePackageRow' : 'cannotRemoveLastPackageRow'
                              }`
                            )}
                          >
                            <PlainButton
                              type="button"
                              id={packageId ?? '' + i + 'delete'}
                              className="p-2"
                              onClick={this.removeMeasurement(i)}
                              disabled={!canRemoveMeasurements}
                            >
                              <IconFa icon={['far', 'minus-circle']} />
                            </PlainButton>
                          </Tooltip>
                        </div>

                        <div style={{ padding: '0.5em', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                          <div>{this.getMeasurementVolumeByIndex(i).toFixed(1)} dm³</div>
                        </div>
                      </div>
                      <Disclosure.Panel unmount={false}>
                        <div className="pl-[3.25rem] pr-[7rem]">
                          <LabeledInput
                            label={i18next.t('instant.booking.specificationDescriptionPlaceholder')}
                            htmlFor={'specificationDescription' + i}
                          >
                            <Control.text
                              autoComplete="nope"
                              model={`.measurements[${i}].specificationDescription`}
                              id={'specificationDescription' + i}
                              component={InputForLabel}
                              validators={specificationRequired ? { required } : {}}
                            />
                          </LabeledInput>
                          <Errors
                            className="errors"
                            model={`.measurements[${i}].specificationDescription`}
                            show="touched"
                            wrapper={SmallValidationError}
                            messages={{ required: `${i18next.t('application.required')}` }}
                          />
                        </div>
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              </React.Fragment>
            )
          })}
        <TertiaryButton type="button" onClick={this.addMeasurement}>
          <FontAwesomeIcon icon="plus" />
          <span style={{ paddingLeft: '0.5em' }}>{i18next.t('instant.booking.addPackage')}</span>
        </TertiaryButton>

        {this.props.openCustomMeasurementsModal && (
          <div>
            <TertiaryButton type="button" onClick={this.props.openCustomMeasurementsModal}>
              {i18next.t('customMeasurements.customizeGoodsTypes')}
            </TertiaryButton>
          </div>
        )}

        {formValues.get('measurements') && (
          <BookingTotalWeightAndVolume measurements={formValues.get('measurements')} />
        )}

        {showClearButton && clearComponents && (
          <ClearInstantBookingFormButton clearComponents={clearComponents} components={measurementComponentNames} />
        )}
      </InputGroup>
    )
  }
}

export default BookingFormGoods
