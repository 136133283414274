import { ConsignmentEventAction } from '../types/consignmentEventActionTypes'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'
import { consignmentEventsSelector } from './consignmentSelectors'
import { AppStateType } from '../utils/appStateReduxStore'
import {
  ConsignmentEvent,
  ConsignmentEventActionId,
  ConsignmentEventId,
  OrderIdType,
  Slot,
  SlotIdType
} from '../types/coreEntitiesTypes'
import { List, Map } from 'immutable'
import { invoiceCallbackEvents, liveDeviationEvents } from '../utils/consignmentEvent'
import { slotsByIdsSelector } from './slotSelectors'
import { DateTime } from 'luxon'

export const unsortedLiveDeviationEvents = createImmutableEqualSelector(
  consignmentEventsSelector,
  slotsByIdsSelector,
  (events, slots): Map<SlotIdType, List<ConsignmentEvent>> =>
    events
      .filter(isLiveDeviationEvent)
      .filter((e) => isEventDuringSlot(e, slotForEvent(e, slots)))
      .groupBy((e) => e.get('slotId') ?? -1)
      .toMap()
      .map((it) => it.toList())
)

export const consignmentEventActionsSelector = createImmutableEqualSelector(
  (state: AppStateType): Map<ConsignmentEventActionId, ConsignmentEventAction> =>
    state.getIn(['entities', 'consignmentEventActions']),
  (actions): Map<ConsignmentEventId, List<ConsignmentEventAction>> =>
    actions
      .toList()
      .groupBy((a) => a.get('consignmentEventId'))
      .toMap()
      .map((it) => it.toList().sortBy((a) => a.get('createdAt')))
)

export const latestConsignmentEventActionsSelector = createImmutableEqualSelector(
  consignmentEventActionsSelector,
  (actions) => actions.map((a) => a.last(undefined))
)

export const invoiceUrlFromConsignmentEventSelector = createImmutableEqualSelector(
  consignmentEventsSelector,
  (_state: AppStateType, orderId: OrderIdType) => orderId,
  (events, orderId): string | undefined =>
    events
      .filter((e) => e.get('orderId') === orderId)
      .filter(isInvoiceCallbackEvent)
      .filter((e) => e.get('data')?.get('linkToInvoice'))
      .map((e) => e.get('data')?.get('linkToInvoice'))
      .first()
)

// The live page only shows a few types of consignment events.
function isLiveDeviationEvent(event: ConsignmentEvent): boolean {
  return liveDeviationEvents.includes(event.get('type'))
}

function isInvoiceCallbackEvent(event: ConsignmentEvent): boolean {
  return invoiceCallbackEvents.includes(event.get('type'))
}

// Filter out events that happened before the slot start time.
// These earlier events, like damage events that happened
// before the slot started, should not show on the live page.
function isEventDuringSlot(event: ConsignmentEvent, slot: Slot | undefined): boolean {
  if (!slot) {
    return false
  }

  const timeFrom = DateTime.fromISO(slot.get('timeFrom'), { zone: slot.get('tzTimeFrom') })
  const eventTime = DateTime.fromISO(event.get('eventTime'))

  return eventTime >= timeFrom
}

function slotForEvent(event: ConsignmentEvent, slots: Map<SlotIdType, Slot>): Slot | undefined {
  return slots.get(event.get('slotId') ?? -1)
}
