import { ConsignmentState as states } from '../utils/consignmentState'

const viewBox = '130 100 700 600'
const width = '53'
const height = '63'

const unresolvedSvg = `
<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="${viewBox}">
  <ellipse cx="450.249" cy="703.245" rx="142.951" ry="15.005" fill="#414141" opacity=".4" style="isolation:isolate"/>
  <path d="M450 181.75c-102.792 0-186.16 83.367-186.16 186.16 0 141.331 157.52 311.303 186.16 329.359 28.64-18.056 186.16-188.028 186.16-329.36 0-102.792-83.368-186.159-186.16-186.159z" fill="#f7a4a4" stroke="#414141" stroke-miterlimit="10" stroke-width="10"/>
  <path d="M555 369.777a105 105 0 1 1-105-105c58.5 0 105 48 105 105zm-102-69.001c-22.5 0-37.5 9.001-49.5 27-1.5 1.502-1.5 5.999 1.5 7.5l15 10.5c1.5 1.501 6 1.501 7.5-1.502 7.5-9.001 12-15 24-15 9 0 19.5 5.999 19.5 13.499 0 5.998-4.5 9.001-13.5 15-10.5 5.998-22.5 12-22.5 30v1.501c0 3 3 4.5 4.5 4.5h24c3 0 4.5-2.998 4.5-4.5 0-12 34.5-12 34.5-45 0-25.495-25.5-43.498-49.5-43.498zm-3 105a19.5 19.5 0 1 0 19.5 19.5c0-10.499-7.5-19.5-19.5-19.5z" fill="#fff"/>
  {{count}}
</svg>`

const createdSvg = `
<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="${viewBox}">
  <ellipse cx="450.062" cy="703.245" rx="142.951" ry="15.005" fill="#414141" opacity=".4" style="isolation:isolate"/>
  <path d="M450.062 181.75c-102.792 0-186.16 83.367-186.16 186.16 0 141.331 157.52 311.303 186.16 329.359 28.64-18.056 186.16-188.028 186.16-329.36 0-102.792-83.367-186.159-186.16-186.159z" fill="#ffa400" stroke="#414141" stroke-miterlimit="10" stroke-width="10"/>
  <path d="M439.925 279.549L415.46 329.92l-54.69 8.636c-10.074 1.44-14.392 12.952-7.196 20.148l40.298 38.857-10.075 54.69c-1.439 10.074 8.636 17.27 17.27 12.952L450 439.298l48.933 25.906c8.635 4.319 18.71-2.877 17.27-12.951l-10.074-54.69 40.297-38.858c7.196-7.196 2.879-18.71-7.196-20.147l-54.69-8.637-24.466-50.372c-2.879-8.637-15.832-8.637-20.15 0z" fill="#fff"/>
  {{count}}
</svg>`

const resolvedSvg = `
<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="${viewBox}">
  <ellipse cx="450.249" cy="703.245" rx="142.951" ry="15.005" fill="#414141" opacity=".4" style="isolation:isolate"/>
  <path d="M450 181.75c-102.792 0-186.16 83.367-186.16 186.16 0 141.331 157.52 311.303 186.16 329.359 28.64-18.056 186.16-188.028 186.16-329.36 0-102.792-83.368-186.159-186.16-186.159z" fill="#414141"/>
  <circle cx="450" cy="369.777" r="105" fill="#fff"/>
  {{count}}
</svg>`

const assignedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="${viewBox}">
  <ellipse cx="450.062" cy="703.245" rx="142.951" ry="15.005" fill="#414141" opacity=".4" style="isolation:isolate"/>
  <path d="M450.062 181.75c-102.792 0-186.16 83.367-186.16 186.16 0 141.331 157.52 311.303 186.16 329.359 28.64-18.056 186.16-188.028 186.16-329.36 0-102.792-83.367-186.159-186.16-186.159z" fill="#0088b5" stroke="#414141" stroke-miterlimit="10" stroke-width="10"/>
  <path d="M506.227 294.435H474.72a24.658 24.658 0 1 0-49.316 0h-31.507a18.728 18.728 0 0 0-19.178 19.177V450.6a18.726 18.726 0 0 0 19.178 19.177h112.33a18.728 18.728 0 0 0 19.178-19.177V313.612a18.726 18.726 0 0 0-19.178-19.177zm-56.164-9.591a9.588 9.588 0 1 1-9.59 9.588c1.37-5.478 5.48-9.588 9.59-9.588zm47.945 90.413l-56.165 54.795c-1.37 1.371-4.11 1.371-6.85 0l-31.506-32.876c-1.37-1.37-1.37-4.11 0-6.85l10.959-10.96c1.37-1.37 4.11-1.37 6.85 0l17.807 17.81L480.2 356.08c1.37-1.371 4.11-1.371 6.85 0l10.958 10.96c1.37 2.739 1.37 6.846 0 8.217z" fill="#fff"/>
  {{count}}
</svg>`
const offeredSvg = assignedSvg

const collectedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="${viewBox}">
  <ellipse cx="450.062" cy="703.245" rx="142.951" ry="15.005" fill="#414141" opacity=".4" style="isolation:isolate"/>
  <path d="M450.062 181.75c-102.792 0-186.16 83.367-186.16 186.16 0 141.331 157.52 311.303 186.16 329.359 28.64-18.056 186.16-188.028 186.16-329.36 0-102.792-83.367-186.159-186.16-186.159z" fill="#414141"/>
  <path d="M549.97 399.786h-4.617v-34.63c0-3.463-1.154-8.079-4.617-10.387l-31.166-31.165c-2.309-2.308-6.926-4.616-10.389-4.616H485.33V303.98a15.28 15.28 0 0 0-15.006-15.006H368.745a15.28 15.28 0 0 0-15.005 15.006V405.56a15.28 15.28 0 0 0 15.006 15.006h4.617c0 16.16 13.852 30.013 30.012 30.013s30.011-13.85 30.011-30.013h40.4c0 16.16 13.852 30.013 30.012 30.013s30.012-13.85 30.012-30.013h15.006a4.965 4.965 0 0 0 4.617-4.616v-10.387c1.154-3.47-1.155-5.777-3.463-5.777zm-146.596 34.628a15.006 15.006 0 1 1 15.006-15.006 15.28 15.28 0 0 1-15.006 15.006zm101.578 0a15.006 15.006 0 1 1 15.006-15.006 15.28 15.28 0 0 1-15.006 15.006zm25.395-65.794h-45.018v-35.784h13.852l31.166 31.165v4.619z" fill="#fff"/>
  {{count}}
</svg>`

const deviatedSvg = `
<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="${viewBox}">
  <ellipse cx="450.249" cy="703.245" rx="142.951" ry="15.005" fill="#414141" opacity=".4" style="isolation:isolate"/>
  <path d="M450 181.75c-102.792 0-186.16 83.367-186.16 186.16 0 141.331 157.52 311.303 186.16 329.359 28.64-18.056 186.16-188.028 186.16-329.36 0-102.792-83.368-186.159-186.16-186.159z" fill="#e61919" stroke="#414141" stroke-miterlimit="10" stroke-width="10"/>
  <path d="M550.638 416.907c5.706 11.412-1.426 24.249-14.265 24.249H369.478c-12.838 0-21.397-14.265-14.265-24.25l82.735-144.074c5.706-11.412 22.824-11.412 28.53 0l84.161 144.075zm-97-29.958a15.693 15.693 0 1 0 15.692 15.693 15.83 15.83 0 0 0-15.692-15.693zm-15.691-57.058l2.853 47.074q0 4.278 4.28 4.28h17.117q4.28 0 4.28-4.28l2.853-47.074q0-4.279-4.28-4.28h-21.397c-2.853.003-5.706 1.428-5.706 4.28z" fill="#fff"/>
  {{count}}
</svg>`

const deliveredSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="${viewBox}">
  <ellipse cx="450.249" cy="703.245" rx="142.951" ry="15.005" fill="#414141" opacity=".4" style="isolation:isolate"/>
  <path d="M450 181.75c-102.792 0-186.16 83.367-186.16 186.16 0 141.331 157.52 311.303 186.16 329.359 28.64-18.056 186.16-188.028 186.16-329.36 0-102.792-83.368-186.159-186.16-186.159z" fill="#7bc144" stroke="#414141" stroke-miterlimit="10" stroke-width="10"/>
  <path d="M555 369.777a105 105 0 1 1-105-105c59.552 1.566 105 48.58 105 105zm-115.97 56.416l76.791-76.79q4.701-4.7 0-9.404l-9.403-9.405q-4.701-4.7-9.403 0l-62.686 62.687-29.777-29.775q-4.701-4.7-9.403 0l-9.403 9.405q-4.701 4.7 0 9.404l43.881 43.881a9.993 9.993 0 0 0 9.403-.003z" fill="#fff"/>
  {{count}}
</svg>`

const rejectedSvg = `
<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="${viewBox}">
  <ellipse cx="450.249" cy="703.245" rx="142.951" ry="15.005" fill="#414141" opacity=".4" style="isolation:isolate"/>
  <path d="M450 181.75c-102.792 0-186.16 83.367-186.16 186.16 0 141.331 157.52 311.303 186.16 329.359 28.64-18.056 186.16-188.028 186.16-329.36 0-102.792-83.368-186.159-186.16-186.159z" fill="#e61919" stroke="#414141" stroke-miterlimit="10" stroke-width="10"/>
  <path d="M550.638 416.907c5.706 11.412-1.426 24.249-14.265 24.249H369.478c-12.838 0-21.397-14.265-14.265-24.25l82.735-144.074c5.706-11.412 22.824-11.412 28.53 0l84.161 144.075zm-97-29.958a15.693 15.693 0 1 0 15.692 15.693 15.83 15.83 0 0 0-15.692-15.693zm-15.691-57.058l2.853 47.074q0 4.278 4.28 4.28h17.117q4.28 0 4.28-4.28l2.853-47.074q0-4.279-4.28-4.28h-21.397c-2.853.003-5.706 1.428-5.706 4.28z" fill="#fff"/>
  {{count}}
</svg>`

export function getSvgForCustomerConsignmentState(state: string | undefined) {
  switch (state ? state.toUpperCase() : '') {
    case states.CREATED:
    case states.UNRESOLVED:
    case states.RESOLVED:
    case states.OFFERED:
    case states.ASSIGNED:
    case states.REJECTED:
      return resolvedSvg
    case states.COLLECTED:
      return collectedSvg
    case states.DELIVERED:
      return deliveredSvg
    case states.DEVIATED:
      return deviatedSvg
    default:
      return ''
  }
}

export function getSvgForConsignmentState(state: string | undefined) {
  switch (state ? state.toUpperCase() : '') {
    case states.CREATED:
      return createdSvg
    case states.UNRESOLVED:
      return unresolvedSvg
    case states.RESOLVED:
      return resolvedSvg
    case states.OFFERED:
      return offeredSvg
    case states.ASSIGNED:
      return assignedSvg
    case states.COLLECTED:
      return collectedSvg
    case states.DELIVERED:
      return deliveredSvg
    case states.DEVIATED:
      return deviatedSvg
    case states.REJECTED:
      return rejectedSvg
    default:
      return ''
  }
}

export function getSvgForNumber(count: number) {
  if (count === 1) {
    return ''
  } else if (count < 10) {
    return `<circle cx="569.1642" cy="201.1897" r="76" fill="#fff" stroke="#414141" stroke-miterlimit="10" stroke-width="15"/>
  <text transform="translate(539.338 240.3103)" font-size="112.9855" fill="#414141" font-family="ArialMT, Arial" style="isolation: isolate">${count}</text>`
  } else if (count < 100) {
    return `<path d="M642.07,277.5176H569.0072A76.5523,76.5523,0,0,1,492.6793,201.19h0a76.5524,76.5524,0,0,1,76.3279-76.328H642.07a76.5523,76.5523,0,0,1,76.3279,76.328h0A76.5522,76.5522,0,0,1,642.07,277.5176Z" fill="#fff" stroke="#414141" stroke-miterlimit="10" stroke-width="15"/>
  <text transform="translate(542.2885 240.3125)" font-size="112.9855" fill="#414141" font-family="ArialMT, Arial" style="isolation: isolate">${count}</text>`
  } else if (count < 1000) {
    return `<path d="M702.07,277.5176H569.0072A76.5523,76.5523,0,0,1,492.6793,201.19h0a76.5524,76.5524,0,0,1,76.3279-76.328H702.07a76.5523,76.5523,0,0,1,76.3279,76.328h0A76.5522,76.5522,0,0,1,702.07,277.5176Z" fill="#fff" stroke="#414141" stroke-miterlimit="10" stroke-width="15"/>
  <text transform="translate(536.3376 240.3098)" font-size="112.9855" fill="#414141" font-family="ArialMT, Arial" style="isolation: isolate">${count}</text>`
  }
  return `<path d="M752.07,277.5176H569.0072A76.5523,76.5523,0,0,1,492.6793,201.19h0a76.5524,76.5524,0,0,1,76.3279-76.328H752.07a76.5523,76.5523,0,0,1,76.3279,76.328h0A76.5522,76.5522,0,0,1,752.07,277.5176Z" fill="#fff" stroke="#414141" stroke-miterlimit="10" stroke-width="15"/>
  <text transform="translate(532.3378 240.3096)" font-size="112.9855" fill="#414141" font-family="ArialMT, Arial" style="isolation: isolate">${count}</text>
  `
}
