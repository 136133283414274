import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { fromJS, List } from 'immutable'
import debounce from 'lodash/debounce'
import isFunction from 'lodash/isFunction'
import React from 'react'
import Autosuggest from 'react-autosuggest'
import { connect } from 'react-redux'
import { AUTOSUGGEST_ADDRESSES, AUTOSUGGEST_CUSTOMERS } from '../actions/actionTypes'
import { searchAddresses } from '../actions/creators/addressHelpers'
import { changeFormValue } from '../actions/creators/formHelpers'
import { clearSearchSuggestions, searchCustomerAllCountries, searchCustomers } from '../actions/creators/helpers'
import { sortByName } from '../pages/instant/bookingOrderFunctions'
import { SuggestionsContainer } from '../primitives/AutoSuggest'
import { Input, InputForLabel, SearchInput } from '../primitives/Forms'
import ImmutableComponent from '../primitives/ImmutableComponent'
import { LabeledInput } from '../primitives/LabeledInput'
import vars from '../styles/variables'
import { DepartmentIdType } from '../types/coreEntitiesTypes'
import { isNotNullOrUndefined } from '../types/immutableTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { isNotEmpty } from '../utils/collectionUtils'

const isSelecting = (method: string) => method === 'click' || method === 'enter'
const isTyping = (method: string) => method === 'type'

export const autoSuggestTheme = { suggestionsList: { margin: '1rem 0', paddingLeft: '40px', listStyleType: 'disc' } }

export function renderSuggestion(label: string, query: string, isHighlighted: any) {
  const matches = match(label, query)
  const parts = parse(label, matches)

  return (
    <span style={{ background: isHighlighted ? vars.colors.gray : vars.colors.white }}>
      <span style={{ paddingRight: '0.75rem' }}>
        {parts.map((part, index) => (
          <span
            key={String(index)}
            style={{ fontWeight: 500, textDecorationLine: part.highlight ? 'underline' : 'none' }}
          >
            {part.text}
          </span>
        ))}
      </span>
    </span>
  )
}

class AutoSuggestWrapper extends ImmutableComponent<Props> {
  private searchDebounced: (value: string, type: string) => void
  constructor(props: Props) {
    super(props)
    this.searchDebounced = debounce(this.search.bind(this), 300)
  }

  onSuggestionSelected = (event: { preventDefault: () => void }) => {
    event.preventDefault()
  }

  renderInputComponent = (inputProps: any) => {
    if (inputProps.labeled) {
      return (
        <LabeledInput label={this.props.placeholder ?? ''} htmlFor={inputProps.id}>
          <InputForLabel {...inputProps} />
        </LabeledInput>
      )
    } else if (this.props.search) {
      return <SearchInput {...inputProps} />
    } else {
      return <Input {...inputProps} />
    }
  }

  renderSuggestionsContainer = ({ containerProps, children }: any) => (
    <SuggestionsContainer show={!!children} {...containerProps}>
      {children}
    </SuggestionsContainer>
  )

  search = (value: string, type: string) => {
    const departmentIds = this.props.departmentIds
    if (type === AUTOSUGGEST_ADDRESSES) {
      this.props.searchAddresses(this.props.departmentIds, value)
    } else {
      if (departmentIds && isNotNullOrUndefined(departmentIds.first())) {
        this.props.searchCustomers(departmentIds, value)
      } else {
        console.log('on search in auto', value, this.props.departmentIds.toArray())
        this.props.searchCustomerAllCountries(value, this.props.departmentIds.toArray())
      }
    }
  }

  suggestionLabel = (suggestion: { name: any }) => suggestion.name

  renderSuggestion = (suggestion: { name: any }, { query, isHighlighted }: any) => {
    const label = this.props.suggestionLabel ? this.props.suggestionLabel(suggestion) : this.suggestionLabel(suggestion)
    return renderSuggestion(label, query, isHighlighted)
  }

  onInputChanged = (event: { preventDefault: () => void }, value: { name: any }, method: string) => {
    if (isTyping(method)) {
      this.props.onChange?.(event)
    } else if (isSelecting(method)) {
      event.preventDefault()
      const label = isFunction(this.props.getLabel) ? this.props.getLabel(value) : value.name || value
      this.props.onChange?.(label)
      this.props.onSelect?.(fromJS(value))
    }
  }

  render() {
    const {
      id,
      placeholder,
      labeled,
      value,
      searchResults,
      searchType = AUTOSUGGEST_CUSTOMERS,
      getSuggestionValue = (v) => v,
      highlightFirstSuggestion = true,
      focusInputOnSuggestionClick = false,
      shouldRenderSuggestions = (v) => Boolean(v && v.trim().length > 2),
      style = {}
    } = this.props

    return (
      <Autosuggest
        suggestions={searchResults}
        onSuggestionsFetchRequested={({ value }) => this.searchDebounced(value, searchType)}
        onSuggestionsClearRequested={() => this.props.clearSearchSuggestions?.(searchType)}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderInputComponent={this.renderInputComponent}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        onSuggestionSelected={this.onSuggestionSelected}
        highlightFirstSuggestion={highlightFirstSuggestion}
        focusInputOnSuggestionClick={focusInputOnSuggestionClick}
        shouldRenderSuggestions={shouldRenderSuggestions}
        inputProps={
          {
            id: id,
            autoComplete: 'nope',
            placeholder: labeled ? '' : placeholder,
            value: value || '',
            labeled: labeled,
            onChange: (event: { preventDefault: () => void }, { newValue, method }: any) =>
              this.onInputChanged(event, newValue, method),
            style
          } as any
        }
        theme={autoSuggestTheme}
      />
    )
  }
}

interface Props {
  searchType: string
  departmentIds: List<number>
  departmentId: string
  userAccessLevel: string
  customerId: number
  id: string
  placeholder?: string
  labeled?: boolean
  value?: string
  search?: string
  searchResults?: any
  highlightFirstSuggestion?: boolean
  focusInputOnSuggestionClick?: boolean
  style?: React.CSSProperties
  getSuggestionValue?: (v: any) => any
  clearSearchSuggestions: (v: string) => void
  onChange?: (event: any) => void
  onSelect?: (event: any) => void
  getLabel?: (event: any) => void
  suggestionLabel?: (event: any) => void
  shouldRenderSuggestions?: (v: string) => boolean
  searchAddresses: (departmentIds: List<number>, searchPhrase: string) => any
  searchCustomers: (departmentIds: List<number>, searchPhrase: string) => any
  searchCustomerAllCountries: (searchPhrase: string, departmentIds: DepartmentIdType[]) => any
  changeFormValue: (model: any, value: any) => any
}
export default connect(
  (state: AppStateType, ownProps: Props) => {
    const searchType = ownProps.searchType
    const departmentIds = ownProps.departmentIds || List([ownProps.departmentId])
    const userAccessLevel = ownProps.userAccessLevel || ''
    const customerId = ownProps.customerId
    const id = searchType + ownProps.id
    const searchResults = state.getIn(['searchReducer', searchType])
    return {
      searchResults: isNotEmpty(searchResults) ? searchResults.valueSeq().sort(sortByName).toJS() : [],
      searchType: searchType,
      id: id,
      modelAndId: ownProps.id,
      departmentIds,
      customerId,
      userAccessLevel
    }
  },
  {
    clearSearchSuggestions,
    searchCustomers,
    searchCustomerAllCountries,
    searchAddresses,
    changeFormValue
  }
)(AutoSuggestWrapper)
