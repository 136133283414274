import React from 'react'
import { AppStateType } from '../utils/appStateReduxStore'
import { connect } from 'react-redux'
import { setReactRenderError } from '../actions/creators/helpers'
import { Link } from 'react-router-dom'
import PageError from '../pages/Error'
import { isProduction } from '@glow/common'

class AppErrorComponent extends React.Component<any> {
  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.props.setReactRenderError(error, info)
  }

  render() {
    if (this.props.error) {
      if (!isProduction()) {
        return (
          <div>
            <h2>An unknown error occurred</h2>
            <p>
              <Link to={'/'}>Go to home page</Link>
            </p>
            <p>
              {this.props.error.message} ({this.props.error.fileName}, {this.props.error.lineNumber}:
              {this.props.error.columnNumber})
            </p>
            {this.props.info && this.props.info.componentStack && (
              <div style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>{this.props.info.componentStack}</div>
            )}
          </div>
        )
      } else return React.createElement(PageError)
    } else {
      return this.props.children
    }
  }
}

export const AppError = connect(
  (state: AppStateType) => ({
    error: state.getIn(['reactRenderError', 'error']),
    info: state.getIn(['reactRenderError', 'info'])
  }),
  { setReactRenderError }
)(AppErrorComponent)
