import { Half } from '../../primitives/Layout'
import { Label } from '../../primitives/Forms'
import i18next from 'i18next'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { ValidationError } from '../../primitives/ErrorMessages'
import React from 'react'
import { GooglePlacesAutocompleteWrapper } from '../GooglePlacesAutompleteWrapper'
import { Map } from 'immutable'
import GoogleMaps from '../GoogleMaps'

export interface UnitStartStopProps {
  showReturnCourierMap: boolean
  lat: number
  lng: number
}

export const UnitStartStop = ({ showReturnCourierMap, lat, lng }: UnitStartStopProps) => (
  <div style={{ display: 'flex', clear: 'both' }}>
    <Half marginRight>
      <Label style={{ marginBottom: '1.3rem' }}>
        {i18next.t('courier.defaultStartLocation')}
        <Errors
          className="errors"
          model=".startLocation"
          show="touched"
          wrapper={ValidationError}
          messages={{
            requiredImmutable: `${i18next.t('application.required')}`
          }}
        />
      </Label>
      <UnitLocation model=".startLocation" lat={lat} lng={lng} />
    </Half>
    <Half marginRight>
      <div>
        {i18next.t('courier.defaultStopLocation')}
        <Label noMargin>
          <Control.checkbox model=".shouldDriverNotReturn" id="shouldDriverNotReturn" />{' '}
          {i18next.t('application.driverReturnToAddress')}
        </Label>
        {showReturnCourierMap && <UnitLocation model=".endLocation" lat={lat} lng={lng} />}
      </div>
    </Half>
  </div>
)

interface UnitLocationProps {
  model: string
  lat: number
  lng: number
}

export const UnitLocation = ({ model, lat, lng }: UnitLocationProps) => (
  <>
    <Control
      model={model}
      component={GooglePlacesAutocompleteWrapper}
      searchAroundArea={{ lat, lng }}
      mapProps={{
        onComplete: (props: any) => (address: any) => props.onChange(Map(address.latLng))
      }}
    />
    <Control
      model={model}
      component={GoogleMaps}
      interactive
      updateLocation
      showMarkerOnLocation
      style={{
        height: '300px',
        width: '100%',
        marginTop: '0.5em'
      }}
      mapProps={{
        location: (props: any) => props.modelValue && props.modelValue.toJS(),
        onClickEmptySpace: (props: any) => (location: any) => props.onChange(Map(location))
      }}
    />
  </>
)
