import i18next from 'i18next'
import React from 'react'
import notFound from '../components/404'
import { BrandingLogo } from '../primitives/BrandingLogo'
import { H1 } from '../primitives/Headings'
import { BringLogo } from '../primitives/Logo'
import { ResponsiveContainer } from '../primitives/NotFoundErrorStyles'
import { SvgHolder404 } from '../primitives/SvgHolder'
import { ExternalLink, SmallText } from '../primitives/Typography'
import vars from '../styles/variables'

class PageNotFound extends React.PureComponent {
  componentDidMount(): void {
    // Log to Sentry
    console.warn('Page not found', window.location.href)
  }

  render() {
    return (
      <main>
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: vars.colors.white
          }}
        >
          <BringLogo>
            <BrandingLogo className="w-36" />
          </BringLogo>
          <ResponsiveContainer>
            <SvgHolder404 large>{notFound}</SvgHolder404>
          </ResponsiveContainer>
          <ResponsiveContainer center noPadding>
            <H1>{i18next.t('error.404Title')}</H1>
            <SmallText>{i18next.t('error.404Message')}</SmallText>
            <br />
            <ExternalLink href={'http://www.bring.com'}>www.bring.com</ExternalLink>
          </ResponsiveContainer>
        </div>
      </main>
    )
  }
}

export { PageNotFound }
export default PageNotFound
