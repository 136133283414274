import { List, Map } from 'immutable'
import { DateTime } from 'luxon'
import React from 'react'
import { connect } from 'react-redux'
import { setPageStateValue } from '../../actions/creators/helpers'
import { Loading } from '../../components/Loading'
import ImmutableComponent from '../../primitives/ImmutableComponent'
import { Department } from '../../types/coreEntitiesTypes'
import { AppStateType } from '../../utils/appStateReduxStore'
import { isEmpty, isNotEmpty } from '../../utils/collectionUtils'
import { emptyVAS, getPhonePrefixFromDepartment } from './bookingOrderFunctions'
import BookingForm from './BookingForm'
import { OrderAction } from './bookingSupport'
import { mergeFormValues } from '../../actions/creators/formHelpers'
import { departmentFromIdSelector } from '../../selectors/departmentsSelector'
import Container from '../../primitives/Container'
import { compose } from 'redux'
import { RouteComponentProps, withRouter } from '../../hooks/withRouter'

export function getDefaultPresetMeasurement(withSpecificationDescription?: boolean) {
  return withSpecificationDescription
    ? Map({
        presetDimension: '',
        weight: '',
        height: '',
        length: '',
        width: '',
        specificationDescription: '',
        volume: ''
      })
    : Map({ presetDimension: '', weight: '', height: '', length: '', width: '', volume: '' })
}

class Booking extends ImmutableComponent<Props> {
  componentDidUpdate(prevProps: Readonly<Props>) {
    if (isEmpty(prevProps.department) && isNotEmpty(this.props.department))
      this.props.mergeFormValues(
        'createOrderForm',
        Map({
          pickupPhone: getPhonePrefixFromDepartment(this.props.department),
          deliveryPhone: getPhonePrefixFromDepartment(this.props.department)
        })
      )
  }

  componentDidMount() {
    this.props.setPageStateValue('showPickupDeliveryTimes', false)
    this.props.mergeFormValues(
      'createOrderForm',
      Map({
        measurements: List([getDefaultPresetMeasurement()]),
        pickupDate: DateTime.local().toISODate(),
        pickupTimeEarliest: '00:00',
        pickupTimeLatest: '00:00',
        deliveryTimeEarliest: '00:00',
        deliveryTimeLatest: '00:00',
        additionalServices: List([emptyVAS()]),
        adrGoods: List([]),
        pickupPhone: getPhonePrefixFromDepartment(this.props.department),
        deliveryPhone: getPhonePrefixFromDepartment(this.props.department),
        serviceId: ''
      })
    )
  }

  render() {
    if (isEmpty(this.props.department)) {
      return <Loading />
    }
    return (
      <Container full noTopPadding>
        <BookingForm
          departmentId={this.props.department.get('id')}
          isLoading={false}
          orderId={undefined}
          orderAction={OrderAction.Create}
          subTitleKey={'instant.booking.headerCreate'}
        />
      </Container>
    )
  }
}

interface StateProps {
  department: Department
}

interface DispatchProps {
  mergeFormValues: (formName: string, values: Map<string, any>) => void
  setPageStateValue: (key: string, value: any) => void
}

type PathParamsType = { departmentId: string }
type OwnProps = RouteComponentProps<PathParamsType>

type Props = StateProps & DispatchProps & OwnProps

export default compose(
  withRouter,
  connect<StateProps, DispatchProps, OwnProps, AppStateType>(
    (state, ownProps) => {
      const departmentId = parseInt(ownProps.router.params.departmentId, 10)
      return {
        department: departmentFromIdSelector(state, departmentId)
      }
    },
    {
      mergeFormValues,
      setPageStateValue
    }
  )
)(Booking)
