import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { eventDataValue } from '@glow/entity-types'
import { PlainLink } from '@glow/ui-components'
import { formatDateTimeTechnical } from '../../../../utils/dateTime'
import i18next from 'i18next'

export function EventInvoiceCallback(props: { data: AnyData }) {
  const externalSystemId = eventDataValue(props.data, ['externalSystemId'])
  const processedAt = eventDataValue(props.data, ['processedAt'])
  const transactionNumber = eventDataValue(props.data, ['transactionNumber'])
  const linkToInvoice = eventDataValue(props.data, ['linkToInvoice'])

  return (
    <ul>
      {externalSystemId && transactionNumber && (
        <TransactionNumber systemId={externalSystemId} transactionNumber={transactionNumber} />
      )}
      {processedAt && <TimeOfTransaction timestamp={formatDateTimeTechnical(processedAt.toString())} />}
      {linkToInvoice && <LinkToInvoice linkToInvoice={linkToInvoice.toString()} />}
    </ul>
  )
}

const TimeOfTransaction = (props: { timestamp: string | number }) => (
  <InvoiceInfoItem label={i18next.t('consignmentEvent.invoiceCallback.timeOfTransaction')} value={props.timestamp} />
)

const TransactionNumber = (props: { systemId: string | number; transactionNumber: string | number }) => (
  <InvoiceInfoItem
    label={i18next.t('consignmentEvent.invoiceCallback.transactionNumber', { systemId: props.systemId })}
    value={props.transactionNumber}
  />
)

const LinkToInvoice = (props: { linkToInvoice: string }) => (
  <li>
    <PlainLink href={props.linkToInvoice} target="new">
      {i18next.t('consignmentEvent.invoiceCallback.invoiceLink')}
    </PlainLink>
  </li>
)

const InvoiceInfoItem = (props: { label: string; value: string | number }) => (
  <li>
    <span className="font-bold mr-1">{props.label}:</span>
    <span>{props.value}</span>
  </li>
)
