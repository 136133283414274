import React from 'react'
import { ConsignmentEventId } from '../types/coreEntitiesTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { EventType, liveDeviationEvents } from '../utils/consignmentEvent'
import { useSelector } from 'react-redux'
import { latestConsignmentEventActionsSelector } from '../selectors/consignmentEventSelectors'
import { ConsignmentEventActionType } from '../types/consignmentEventActionTypes'
import { toLocaleDate, withWeekdayAndTime } from '../utils/dateTime'
import styled from 'styled-components'
import i18next from 'i18next'

interface Props {
  consignmentEventId: ConsignmentEventId
  consignmentEventType: EventType
}

export function ConsignmentEventConfirmed(props: Props) {
  // Consignment event actions will only exist for event types
  // that are listed on the Live 2.0 page.
  if (!liveDeviationEvents.contains(props.consignmentEventType)) {
    return null
  }

  return <ConfirmedText {...props} />
}

function ConfirmedText(props: Props) {
  const action = useSelector((state: AppStateType) => {
    return latestConsignmentEventActionsSelector(state).get(props.consignmentEventId)
  })

  if (!action) {
    return null
  }

  const actionType = action.get('type')
  const userName = action.get('userName')
  const createdAt = action.get('createdAt')

  if (!userName || !createdAt || !actionType) {
    return null
  }

  if (actionType !== ConsignmentEventActionType.CONFIRM) {
    return null
  }

  return (
    <StyledText>
      {i18next.t('live.confirmedBy', {
        name: userName,
        time: withWeekdayAndTime(toLocaleDate(createdAt))
      })}
    </StyledText>
  )
}

const StyledText = styled.p`
  margin: 1em 0 0 0;
`
