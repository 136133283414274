import { Collection, List, Map } from 'immutable'
import {
  Consignment,
  ConsignmentIdType,
  OrderIdType,
  RoutePoint,
  Shipment,
  SlotIdType
} from '../types/coreEntitiesTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import {
  consignmentIdsBySlotdIdSetSelector,
  consignmentIdsForSlotIdsSelector,
  consignmentIdsSetSelector,
  consignmentsByOrderIdsSelector,
  consignmentsBySlotIdsSelector
} from './consignmentSelectors'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'
import { hasNotNullOrUndefinedValues, isNotEmpty } from '../utils/collectionUtils'
import {
  getHomeDeliveryLoadList,
  getHomeDeliveryRoutes,
  getRouteReceipts,
  RoutePointsByConsignmentIdType
} from '../utils/routeUtils'
import { RouteReceipt } from '../types/hdRouteTypes'
import { servicesAndVasesTextsPerCodeSelector } from './servicesSelectors'
import { getShipments } from '../query/shipmentQuery'
import { LoadList } from '../pages/planner/planning2/Planning2LoadList'

export const routePointsSelector: (state: AppStateType) => Map<number, RoutePoint> = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'routePoints']) as Map<number, RoutePoint>,
  (rp) => rp || Map()
)

export const routePointsForSlotsBySlotIdSelector: (state: AppStateType) => Map<number, Collection<number, RoutePoint>> =
  createImmutableEqualSelector(routePointsSelector, (routePoints) =>
    routePoints.groupBy((rp) => rp.get('slotId')).toMap()
  )

export const routePointDeliveriesByConsignmentIdSelector = createImmutableEqualSelector(
  routePointsSelector,
  consignmentIdsSetSelector,
  (routePoints, consignmentIdsSet) =>
    routePoints
      .valueSeq()
      .filter((it) => it.get('type') === 'delivery' && consignmentIdsSet.has(it.get('consignmentId')))
)

export const routePointsForSlotSelector: (state: AppStateType, slotId: SlotIdType) => Map<number, RoutePoint> =
  createImmutableEqualSelector(
    routePointsSelector,
    (state: AppStateType, slotId: SlotIdType) => slotId,
    (routePoints, slotId) => routePoints && routePoints.filter((rp) => rp.get('slotId') === slotId)
  )

export const routePointsForSlotsSelector: (state: AppStateType, slotIds: List<SlotIdType>) => Map<number, RoutePoint> =
  createImmutableEqualSelector(
    routePointsSelector,
    (state: AppStateType, slotIds: List<SlotIdType>) => slotIds,
    (routePoints, slotIds) => routePoints.filter((rp) => slotIds.includes(rp.get('slotId')))
  )

export const routePointsForSlotsSortedByArrivalSelector: (
  state: AppStateType,
  slotIds: List<SlotIdType>
) => Map<SlotIdType, Collection<number, RoutePoint>> = createImmutableEqualSelector(
  routePointsForSlotsSelector,
  (routePoints) => routePoints.groupBy((it) => it.get('slotId')).map((it) => it.sortBy((it) => it.get('arrivalCached')))
)

export const routePointsForSlotIdAndConsignmentIdsSelector = createImmutableEqualSelector(
  routePointsForSlotSelector,
  consignmentIdsBySlotdIdSetSelector,
  (routePoints, consignmentIdsSet) =>
    routePoints.valueSeq().filter((it) => consignmentIdsSet.has(it.get('consignmentId')))
)

export const routePointsForSlotIdsAndConsignmentIdsSelector = createImmutableEqualSelector(
  routePointsForSlotsSelector,
  consignmentIdsForSlotIdsSelector,
  (routePoints, consignmentIdsSet) => routePoints.filter((it) => consignmentIdsSet.has(it.get('consignmentId')))
)

export const routePointsForSlotIdSelector: (
  state: AppStateType,
  slotId: SlotIdType
) => Map<ConsignmentIdType, Collection<number, RoutePoint>> = createImmutableEqualSelector(
  routePointsForSlotSelector,
  (routePointsForSlot) =>
    (routePointsForSlot && routePointsForSlot.groupBy((it) => it.get('consignmentId'))) ||
    Map<ConsignmentIdType, Collection<number, RoutePoint>>()
)

export const consignmentsForSlotIdSelector = createImmutableEqualSelector(
  (state: AppStateType) =>
    (state.getIn(['entities', 'consignments']) as Map<ConsignmentIdType, Consignment>) ||
    Map<ConsignmentIdType, Consignment>(),
  (state: AppStateType, slotId: SlotIdType) => slotId,
  (consignments, slotId) => consignments.filter((it: Consignment) => it.get('slotId') === slotId)
)

const shipmentsBySlotIdSelector: (state: AppStateType, slotId: SlotIdType) => Map<number, Shipment> =
  createImmutableEqualSelector(consignmentsForSlotIdSelector, (consignments: Map<number, Consignment>) =>
    getShipments(consignments.valueSeq())
  )

export const consignmentsForSlotIdsSelector = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'consignments']) || Map<ConsignmentIdType, Consignment>(),
  (state: AppStateType, slotIds: List<SlotIdType>) => slotIds,
  (consignments, slotIds) => consignments.filter((it: Consignment) => slotIds.includes(it.get('slotId') || 0))
)

const shipmentsBySlotIdsSelector: (
  state: AppStateType,
  slotIds: List<SlotIdType>
) => Map<SlotIdType, Collection<number, Shipment>> = createImmutableEqualSelector(
  consignmentsForSlotIdsSelector,
  (consignments: Map<number, Consignment>) =>
    getShipments(consignments.valueSeq()).groupBy((it) => it.get('slotId') || 0)
)

export const routePointsForSlotIdByConsignmentIdSelector: (
  state: AppStateType,
  slotId: SlotIdType
) => RoutePointsByConsignmentIdType = createImmutableEqualSelector(
  routePointsForSlotIdAndConsignmentIdsSelector,
  (state: AppStateType, slotId: SlotIdType) => slotId,
  (routePoints, slotId) =>
    routePoints
      .filter((it: RoutePoint) => it.get('slotId') === slotId)
      .groupBy((it: RoutePoint) => it.get('consignmentId'))
)

export const routePointsByConsignmentIdForSlotIdsSelector: (
  state: AppStateType,
  slotIds: List<SlotIdType>
) => Map<SlotIdType, RoutePointsByConsignmentIdType> = createImmutableEqualSelector(
  routePointsForSlotIdsAndConsignmentIdsSelector,
  (routePoints: Map<number, RoutePoint>) =>
    routePoints
      .groupBy((it: RoutePoint) => it.get('slotId'))
      .map((it) => it.groupBy((it: RoutePoint) => it.get('consignmentId')))
)

export const homeDeliveryRoutesSelector: (state: AppStateType, slotId: SlotIdType) => List<RouteReceipt> =
  createImmutableEqualSelector(
    consignmentsForSlotIdSelector,
    routePointsForSlotIdByConsignmentIdSelector,
    servicesAndVasesTextsPerCodeSelector,
    (consignmentsForSlotId, routePointsByConsignmentId, servicesAndVasesTexts) =>
      (hasNotNullOrUndefinedValues(routePointsByConsignmentId) &&
        getHomeDeliveryRoutes(consignmentsForSlotId, routePointsByConsignmentId, servicesAndVasesTexts)) ||
      List()
  )

export const routeReceiptsSelector: (state: AppStateType, orderIds: List<OrderIdType>) => List<RouteReceipt> =
  createImmutableEqualSelector(
    consignmentsByOrderIdsSelector,
    servicesAndVasesTextsPerCodeSelector,
    (consignments, servicesAndVasesTexts) => getRouteReceipts(consignments, servicesAndVasesTexts) || List()
  )

export const routesForSlotsSelector: (
  state: AppStateType,
  slotIds: List<SlotIdType>
) => Map<SlotIdType, List<RouteReceipt>> = createImmutableEqualSelector(
  consignmentsBySlotIdsSelector,
  routePointsByConsignmentIdForSlotIdsSelector,
  servicesAndVasesTextsPerCodeSelector,
  (consignmentsBySlotId, routePointsByConsignmentIdForSlotIds, servicesAndVasesTexts) => {
    let routeListsMap = Map<SlotIdType, List<RouteReceipt>>()
    isNotEmpty(consignmentsBySlotId) &&
      isNotEmpty(routePointsByConsignmentIdForSlotIds) &&
      consignmentsBySlotId.map((consignments, slotId) => {
        const routePointsForSlot: RoutePointsByConsignmentIdType =
          routePointsByConsignmentIdForSlotIds.get(slotId) || Map()
        routeListsMap = routeListsMap.set(
          slotId,
          getHomeDeliveryRoutes(consignments.toMap(), routePointsForSlot, servicesAndVasesTexts)
        )
      })
    return routeListsMap
  }
)

export const homeDeliveryLoadListSelector: (state: AppStateType, slotId: SlotIdType) => List<LoadList> =
  createImmutableEqualSelector(
    shipmentsBySlotIdSelector,
    routePointsForSlotIdByConsignmentIdSelector,
    (shipmentsForSlot, routePoints) => getHomeDeliveryLoadList(shipmentsForSlot.valueSeq().toList(), routePoints)
  )

export const loadListForSlotIdsSelector: (
  state: AppStateType,
  slotIds: List<SlotIdType>
) => Map<SlotIdType, List<LoadList>> = createImmutableEqualSelector(
  shipmentsBySlotIdsSelector,
  routePointsByConsignmentIdForSlotIdsSelector,
  (state: AppStateType, slotIds: List<SlotIdType>) => slotIds,
  (shipmentsForSlots, routePoints, slotIds) => {
    let loadListsMap = Map<SlotIdType, List<LoadList>>()
    isNotEmpty(shipmentsForSlots) &&
      isNotEmpty(routePoints) &&
      slotIds.map((slotId) => {
        const routePointsForSlot: RoutePointsByConsignmentIdType = routePoints.get(slotId) || Map()
        const shipmentsForSlot = shipmentsForSlots.get(slotId) || (List() as List<Shipment>)
        loadListsMap = loadListsMap.set(slotId, getHomeDeliveryLoadList(shipmentsForSlot.toList(), routePointsForSlot))
      })
    return loadListsMap
  }
)

export const routePointsForConsignmentIdSelector: (
  state: AppStateType,
  consignmentId: ConsignmentIdType
) => List<RoutePoint> = createImmutableEqualSelector(
  routePointsSelector,
  (state: AppStateType, consignmentId: ConsignmentIdType) => consignmentId,
  (routePoints: Map<number, RoutePoint>, consignmentId) => {
    return routePoints
      .filter((routePoint) => {
        return routePoint?.get('consignmentId') === consignmentId
      })
      .toList()
  }
)
