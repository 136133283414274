import { FormikRadioSelect, formikValidationRequired } from '@glow/formik-components'
import { ButtonBase, IconFa, ModalConfirm, Option } from '@glow/ui-components'
import { Form, Formik } from 'formik'
import i18next from 'i18next'
import { List, fromJS } from 'immutable'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { getAllDepartmentsWithAdminLevelAccess, moveShipment } from '../../../actions/creators/helpers'
import { ToastContext } from '../../../contexts/ToastContext'
import { getFirstErrorMessage } from '../../../http/httpHelper'
import { useAppDispatch } from '../../../reducers/redux-hooks'
import { allDepartmentsSelector } from '../../../selectors/departmentsSelector'
import { getRawErrorTexts } from '../../../selectors/httpStatusSelectors'
import { IError } from '../../../selectors/optimizeJobResultsSelector'
import { OrderIdType } from '../../../types/coreEntitiesTypes'
import { AppStateType } from '../../../utils/appStateReduxStore'

const ConfirmModalDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const ChangeDepartmentForm = (props: { orderIds: List<OrderIdType>; departmentId: string }) => {
  const [showConfirm, setShowConfirm] = useState(false)
  const dispatch = useAppDispatch()
  const { setToast } = useContext(ToastContext)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getAllDepartmentsWithAdminLevelAccess())
  }, [])

  const departments = useSelector((state: AppStateType) => allDepartmentsSelector(state))
  const departmentsForUser: Option[] = useMemo(
    () =>
      departments
        .map((department) => ({ code: department.get('id'), label: department.get('name') }))
        .sortBy((option) => option.label)
        .toArray(),
    [departments]
  )

  const changeDepartment = async (values: { departmentId: string }) => {
    try {
      await moveShipment(props.orderIds, Number(values.departmentId))(dispatch)
      navigate(`../../${props.orderIds.first()}/success-moved-shipment`)
    } catch (err: any) {
      const errorObject = getFirstErrorMessage(err)
      let errorMessage = i18next.t('Shipment could not be moved')
      if (errorObject) {
        const error = getRawErrorTexts(List.of(fromJS(errorObject) as IError))
        errorMessage = !error.isEmpty() ? error.first() : i18next.t('Shipment could not be moved')
      }
      setToast({ variant: 'error-dark', text: errorMessage })
      setShowConfirm(false)
    }
  }

  const getModalDescription = (selectedDepartmentId: string) => {
    return (
      <ConfirmModalDescription>
        {departmentsForUser.find((x) => x.code === props.departmentId)?.label}
        <IconFa icon={['far', 'arrow-right']} />
        {departmentsForUser.find((x) => x.code === selectedDepartmentId)?.label}
      </ConfirmModalDescription>
    )
  }

  return (
    <Formik onSubmit={() => setShowConfirm(true)} initialValues={{ departmentId: '' }}>
      {({ values }) => (
        <Form style={{ display: 'flex', paddingTop: '1rem', gap: '1rem', alignItems: 'flex-end' }}>
          <FormikRadioSelect
            name="departmentId"
            options={departmentsForUser}
            validate={(value: string) => formikValidationRequired(value, i18next.t('shipmentDetails.required'))}
            label={i18next.t('shipmentDetails.changeDepartment')}
            placeholder={i18next.t('consignment.moveOrderDefault')}
            selectedLabel={departmentsForUser.find((department) => department.code === values.departmentId)?.label}
          />
          <ButtonBase variant="primary" disabled={!Boolean(values.departmentId)}>
            {i18next.t('shipmentDetails.moveDepartment')}
          </ButtonBase>
          <ModalConfirm
            open={showConfirm}
            questionText={i18next.t('shipmentDetails.moveShipmentConfirmText')}
            description={getModalDescription(values.departmentId)}
            confirm={i18next.t('live.confirm')}
            close={i18next.t('button.close')}
            cancel={i18next.t('button.cancel')}
            onClose={() => setShowConfirm(false)}
            onConfirm={() => changeDepartment(values)}
          />
        </Form>
      )}
    </Formik>
  )
}
