import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { eventDataValue } from '@glow/entity-types'
import i18next from 'i18next'
import { Link, useParams } from 'react-router-dom'

export function EventOrderCredited(props: { data: AnyData }) {
  const billingOrderId = eventDataValue(props.data, ['billingOrderId'])
  const billingShipmentId = eventDataValue(props.data, ['billingShipmentId'])
  const params = useParams<{ departmentId: string }>()
  return (
    <ul>
      <li>
        <Link to={`/planner/${params.departmentId}/shipments-search/${billingOrderId}/details`}>
          {i18next.t('billingOrder.credit') + ': ' + billingShipmentId}
        </Link>
      </li>
    </ul>
  )
}
