import React from 'react'
import { LabeledInput } from '../../../primitives/LabeledInput'
import i18next from 'i18next'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { InputForLabel } from '../../../primitives/Forms'
import { required } from '../../../utils/inputValidation'
import { SmallValidationError } from '../../../primitives/ErrorMessages'
import AutoSuggestWrapper from '../../../components/AutoSuggestWrapper'
import { AUTOSUGGEST_ADDRESSES } from '../../../actions/actionTypes'
import { IAddress } from '../bookingOrderTypes'
import { renderAddressSuggestion } from './commonHelpers'
import { Department } from '../../../types/coreEntitiesTypes'

interface Props {
  isH2Booking: boolean
  department: Department
  onDeliveryPlaceChanged: (address: IAddress) => void
}

export function DeliveryName({ isH2Booking, department, onDeliveryPlaceChanged }: Props) {
  return (
    <>
      {isH2Booking ? (
        <H2DeliveryNameComponent />
      ) : (
        <StandardDeliveryNameComponent department={department} onDeliveryPlaceChanged={onDeliveryPlaceChanged} />
      )}
    </>
  )
}

const H2DeliveryNameComponent = () => (
  <>
    <LabeledInput label={i18next.t('instant.booking.deliveryNamePlaceholder')} htmlFor="deliveryName">
      <Control.text
        autoComplete="nope"
        model=".deliveryName"
        id="deliveryName"
        component={InputForLabel}
        validators={{ required }}
        defaultValue={''}
      />
    </LabeledInput>
    <Errors
      className="errors"
      model=".deliveryName"
      show="touched"
      wrapper={SmallValidationError}
      messages={{ required: `${i18next.t('application.required')}` }}
    />
  </>
)

interface StandardDeliveryNameProps {
  department: Department
  onDeliveryPlaceChanged: (address: IAddress) => void
}

const StandardDeliveryNameComponent = ({ department, onDeliveryPlaceChanged }: StandardDeliveryNameProps) => (
  <>
    <Control.text
      component={AutoSuggestWrapper}
      model=".deliveryName"
      id="deliveryName"
      validators={{ required }}
      defaultValue={''}
      labeled={true}
      placeholder={i18next.t('instant.booking.deliveryNamePlaceholder')}
      searchType={AUTOSUGGEST_ADDRESSES}
      onSelect={(address: IAddress) => onDeliveryPlaceChanged(address)}
      departmentId={department.get('id')}
      suggestionLabel={renderAddressSuggestion}
    />
    <Errors
      className="errors"
      model=".deliveryName"
      show="touched"
      wrapper={SmallValidationError}
      messages={{ required: `${i18next.t('application.required')}` }}
    />
  </>
)
