import Immutable, { Collection, List, Map, Seq, Set } from 'immutable'
import { DateTime } from 'luxon'
import { consignmentStates } from '../domain/consignment'
import {
  Consignment,
  ConsignmentEvent,
  ConsignmentEventId,
  ConsignmentIdType,
  DepartmentIdType,
  OrderIdType,
  OrderNote,
  PackageIdType,
  PreAdvice,
  SlotIdType
} from '../types/coreEntitiesTypes'
import { AppStateType, ImmutableEntity } from '../utils/appStateReduxStore'
import { ConsignmentState } from '../utils/consignmentState'
import { isDateBetweenIntervalLuxon, toLocaleDate } from '../utils/dateTime'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'
import { CustomerIdType } from '../domain/customer'
import {
  ConsignmentEventsMapType,
  GroupedPreAdvicesByOrderId,
  MappedConsignmentsType,
  OrderNoteMapType
} from '../utils/shipmentsUtils'
import { ordersNotesSelector } from './orderNoteSelectors'
import { AirExpressConsignmentEvent } from '../types/airexpressEntitiesTypes'
import { ImmutableMap } from '../types/immutableTypes'

export const consignmentsSelector = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'consignments']) as Map<string, ImmutableEntity>,
  (consignments) => (consignments ? consignments.valueSeq() : Seq.Indexed([])) as Seq.Indexed<Consignment>
)

export const consignmentsEntitySelector = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'consignments']),
  (consignments) => consignments || Map<ConsignmentIdType, Consignment>()
)

export const consignmentsByOrderIdSelector = createImmutableEqualSelector(
  consignmentsSelector,
  (_state: AppStateType, orderId: OrderIdType) => orderId,
  (consignments, orderId) => consignments.filter((c) => c.get('orderId') === orderId)
)

export const firstConsignmentByOrderIdSelector = createImmutableEqualSelector(
  consignmentsByOrderIdSelector,
  (consignments) => consignments.get(0)
)

export const consignmentsByIdsSelector: (state: AppStateType, ids: Set<ConsignmentIdType>) => Seq.Indexed<Consignment> =
  createImmutableEqualSelector(
    consignmentsSelector,
    (_state: AppStateType, ids: Set<ConsignmentIdType>) => ids,
    (consignments, ids) => consignments.filter((c) => ids.contains(c.get('id')))
  )

export const consignmentIdToPackageIdSelector: (
  state: AppStateType,
  ids: Set<ConsignmentIdType>
) => Seq.Keyed<ConsignmentIdType, PackageIdType> = createImmutableEqualSelector(
  consignmentsByIdsSelector,
  (consignments) => Map(consignments.map((c) => [c.get('id'), c.get('packageId')])).toKeyedSeq()
)

export const consignmentsByOrderIdsSelector = createImmutableEqualSelector(
  consignmentsSelector,
  (_state: AppStateType, orderIds: List<OrderIdType>) => orderIds,
  (consignments, orderIds) => consignments.filter((c) => orderIds.includes(c.get('orderId')))
)

export const consignmentIdsSetSelector = createImmutableEqualSelector(consignmentsSelector, (consignments) =>
  Set<ConsignmentIdType>(consignments.map((it) => it.get('id')))
)

export const consignmentIdsBySlotdIdSetSelector = createImmutableEqualSelector(
  consignmentsSelector,
  (_state: AppStateType, slotId: number) => slotId,
  (consignments, slotId) =>
    consignments
      ? Set<ConsignmentIdType>(
          consignments
            .valueSeq()
            .filter((it) => it.get('slotId') === slotId)
            .map((it) => it.get('id'))
        )
      : Set<ConsignmentIdType>()
)

export const consignmentIdsForSlotIdsSelector: (
  state: AppStateType,
  slotIds: List<SlotIdType>
) => Set<ConsignmentIdType> = createImmutableEqualSelector(
  consignmentsSelector,
  (_state: AppStateType, slotIds: List<SlotIdType>) => slotIds,
  (consignments, slotIds) =>
    consignments
      .filter((it) => slotIds.includes(it.get('slotId') || 0))
      .map((it) => it.get('id'))
      .toSet() || Set()
)

export const consignmentEventsSelector = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'consignmentEvents']),
  (events) => (events ? List<ConsignmentEvent>(events.valueSeq()) : List<ConsignmentEvent>())
)

export const consignmentEventsWithOrderIdSelector = createImmutableEqualSelector(
  consignmentEventsSelector,
  (_state: AppStateType, orderId: OrderIdType) => orderId,
  (consignmentEvents, orderId) => consignmentEvents.filter((c) => c.get('orderId') === orderId)
)

export const consignmentEventsWithOrderIdsGroupByOrderIdSelector: (
  state: AppStateType,
  orderIds: List<OrderIdType>
) => Map<number, Collection<number, ConsignmentEvent>> = createImmutableEqualSelector(
  consignmentEventsSelector,
  (_state: AppStateType, orderIds: List<OrderIdType>) => orderIds,
  (consignmentEvents, orderIds) =>
    consignmentEvents.filter((c) => orderIds.includes(c.get('orderId'))).groupBy((it) => it.get('orderId'))
)

export const consignmentEventsToMapOfConsignmentIdsToEventMap = createImmutableEqualSelector(
  consignmentEventsSelector,
  (consignmentEvents) => consignmentEvents.valueSeq().groupBy((it) => it.get('consignmentId'))
)

export const airExpressConsignmentEventsSelector = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'airExpressConsignmentEvents']),
  (events) => (events ? List<AirExpressConsignmentEvent>(events.valueSeq()) : List<AirExpressConsignmentEvent>())
)

export const airExpressConsignmentEventsToMapOfConsignmentIdsToEventMap = createImmutableEqualSelector(
  airExpressConsignmentEventsSelector,
  (airExpressConsignmentEvents) => airExpressConsignmentEvents.valueSeq().groupBy((it) => it.get('consignmentId'))
)

const consignmentEventsForSearchSelector: (state: AppStateType) => Seq.Indexed<ConsignmentEvent> =
  createImmutableEqualSelector(
    (state: AppStateType) => state.getIn(['entities', 'consignmentEvents']),
    (state: AppStateType) => state.getIn(['searchConsignments', 'consignmentEvents']),
    (ce, ceIds) =>
      ce && ceIds
        ? ceIds
            .map((id: string) => {
              const entityId = parseInt(id, 10)
              return ce.getIn([entityId])
            })
            .valueSeq()
            .filter((it: any) => !!it)
        : Seq.Indexed()
  )

export const consignmentEventsPerConsignmentIdForSearchSelector: (state: AppStateType) => ConsignmentEventsMapType =
  createImmutableEqualSelector(consignmentEventsForSearchSelector, (ce: Seq.Indexed<ConsignmentEvent>) =>
    ce.groupBy((it) => it.get('consignmentId'))
  )

export const orderNotesForSearchSelector: (state: AppStateType) => Seq.Indexed<OrderNote> =
  createImmutableEqualSelector(
    (state: AppStateType) => state.getIn(['entities', 'orderNotes']),
    (state: AppStateType) => state.getIn(['searchConsignments', 'orderNotes']),
    (orderNotes, orderNoteIds: List<string>) =>
      orderNotes && orderNoteIds
        ? orderNoteIds
            .map((id) => {
              const entityId = parseInt(id, 10)
              return orderNotes?.getIn([entityId])
            })
            .valueSeq()
            .filter((it) => !!it)
        : Seq.Indexed()
  )

export const orderNotePerOrderIdForSearchSelector: (
  state: AppStateType
) => Map<OrderIdType, Collection<number, OrderNote>> = createImmutableEqualSelector(
  orderNotesForSearchSelector,
  (orderNote: Seq.Indexed<OrderNote>) => orderNote.groupBy((it) => it.get('orderId'))
)

export const orderNotePerOrderIdSelector: (state: AppStateType) => Map<OrderIdType, Collection<number, OrderNote>> =
  createImmutableEqualSelector(ordersNotesSelector, (orderNote: Seq.Indexed<OrderNote>) =>
    orderNote.groupBy((it) => it.get('orderId'))
  )

export const consignmentsBySlotIdSelector = createImmutableEqualSelector(
  consignmentsSelector,
  (state: AppStateType, slotId: SlotIdType) => slotId,
  (consignments, slotId) => consignments.valueSeq().filter((it) => it.get('slotId') === slotId)
)

export const consignmentsBySlotIdsSelector: (
  state: AppStateType,
  slotIds: List<SlotIdType>
) => Seq.Keyed<SlotIdType, Collection<ConsignmentIdType, Consignment>> = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'consignments']) || Map<ConsignmentIdType, Consignment>(),
  (state: AppStateType, slotIds: List<SlotIdType>) => slotIds,
  (consignments, slotIds) =>
    consignments
      .filter((it: Consignment) => slotIds.includes(it.get('slotId') || 0))
      .groupBy((it: Consignment) => it.get('slotId'))
)

export const consignmentsByDepartmentIdSelector = createImmutableEqualSelector(
  consignmentsSelector,
  (state: AppStateType, departmentId: number) => departmentId,
  (consignments, departmentId) =>
    consignments.valueSeq().filter((it: Consignment) => it.get('departmentId') === departmentId)
)

export const consignmentsByDepartmentIdsSelector: (
  state: AppStateType,
  departmentIds: Set<DepartmentIdType>
) => Map<ConsignmentIdType, Consignment> = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'consignments']) as Map<ConsignmentIdType, Consignment>,
  (state: AppStateType, departmentIds: Set<DepartmentIdType>) => departmentIds,
  (consignments, departmentIds) =>
    consignments.filter((it: Consignment) => departmentIds.includes(it.get('departmentId')))
)

export const consignmentsByDepartmentIdAndDateSelector = createImmutableEqualSelector(
  consignmentsByDepartmentIdSelector,
  (state: AppStateType, departmentId: DepartmentIdType) => departmentId,
  (state: AppStateType, _departmentId: DepartmentIdType, currentDate: DateTime) => currentDate,
  (state: AppStateType, _departmentId: DepartmentIdType, currentDate: DateTime, toDate: DateTime) => toDate,
  (consignments, departmentId, currentDate, toDate) =>
    consignments.valueSeq().filter((c) => {
      return (
        isDateBetweenIntervalLuxon(
          toLocaleDate(currentDate),
          c.get('deliveryTimeEarliestCached'),
          toLocaleDate(toDate)
        ) && c.get('departmentId') === departmentId
      )
    })
)

export const consignmentsByDepartmentIdsAndDateSelector: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>,
  fromDate: DateTime,
  toDate: DateTime
) => Seq.Indexed<Consignment> = createImmutableEqualSelector(
  consignmentsSelector,
  (state: AppStateType, departmentIds: List<DepartmentIdType>) => departmentIds,
  (state: AppStateType, departmentIds: List<DepartmentIdType>, currentDate: DateTime) => currentDate,
  (state: AppStateType, departmentIds: List<DepartmentIdType>, currentDate: DateTime, toDate: DateTime) => toDate,
  (consignments, departmentIds, currentDate, toDate) =>
    consignments.filter((c) => {
      return (
        departmentIds.includes(c.get('departmentId')) &&
        isDateBetweenIntervalLuxon(toLocaleDate(currentDate), c.get('deliveryTimeEarliestCached'), toLocaleDate(toDate))
      )
    })
)

export const orderSearchsSelector: (state: AppStateType) => List<number> = createImmutableEqualSelector(
  (state: AppStateType) =>
    (state.getIn(['searchConsignments', 'orderSearchs']) as Map<string, ImmutableEntity>) || List(),
  (orderSearchs) => orderSearchs.map((orderSearch) => orderSearch.get('orderId')).toList()
)

export const consignmentEventsByOrderId: (
  state: AppStateType
) => Map<OrderIdType, Collection<ConsignmentEventId, ConsignmentEvent>> = createImmutableEqualSelector(
  (state: AppStateType) =>
    (state.getIn(['entities', 'consignmentEvents']) as Map<ConsignmentEventId, ConsignmentEvent>) || List(),
  (events) =>
    events
      .valueSeq()
      .groupBy((cons) => cons.get('orderId'))
      .toMap()
)

export const consignmentNotesByOrderId: (state: AppStateType) => OrderNoteMapType = createImmutableEqualSelector(
  (state: AppStateType) => (state.getIn(['entities', 'orderNotes']) as Map<number, OrderNote>) || List(),
  (events) => events.valueSeq().groupBy((cons) => cons.get('orderId'))
)

const consignmentEventsWithSearchOrderIdSelector: (state: AppStateType) => Seq.Indexed<ConsignmentEvent> =
  createImmutableEqualSelector(consignmentEventsByOrderId, orderSearchsSelector, (cEventsByOrderId, orderIds) => {
    return cEventsByOrderId && orderIds
      ? (orderIds
          .map((orderId: number) => {
            const events = cEventsByOrderId.get(orderId) as Collection<number, ConsignmentEvent> | undefined
            return events ? events.valueSeq() : List()
          })
          .flatten(1) as Seq.Indexed<ConsignmentEvent>)
      : Seq.Indexed()
  })
const orderNotesForOrderSearchsForSearchSelector: (state: AppStateType) => Seq.Indexed<OrderNote> =
  createImmutableEqualSelector(consignmentNotesByOrderId, orderSearchsSelector, (cEventsByOrderId, orderIds) => {
    return cEventsByOrderId && orderIds
      ? (orderIds
          .map((orderId: number) => {
            const events = cEventsByOrderId.get(orderId) as Collection<number, OrderNote> | undefined
            return events ? events.valueSeq() : List()
          })
          .flatten(1) as Seq.Indexed<OrderNote>)
      : Seq.Indexed()
  })

export const consignmentEventsWithOrderSearchIdPerConsignmentIdOrderIdSelector: (
  state: AppStateType
) => ConsignmentEventsMapType = createImmutableEqualSelector(
  consignmentEventsWithSearchOrderIdSelector,
  (ce: Seq.Indexed<ConsignmentEvent>) => ce.groupBy((it) => it.get('consignmentId'))
)
export const consignmentEventsFilteredOnOrderSearchsGroupedByOrderIdSelector: (
  state: AppStateType
) => ConsignmentEventsMapType = createImmutableEqualSelector(
  consignmentEventsWithSearchOrderIdSelector,
  (ce: Seq.Indexed<ConsignmentEvent>) => ce.groupBy((it) => it.get('orderId'))
)

export const orderNotePerOrderIdAndOrderSearchsForSearchSelector: (state: AppStateType) => OrderNoteMapType =
  createImmutableEqualSelector(orderNotesForOrderSearchsForSearchSelector, (orderNote: Seq.Indexed<OrderNote>) =>
    orderNote.groupBy((it) => it.get('orderId'))
  )

export const getConsignmentsByOrderIdSelector = (state: AppStateType): MappedConsignmentsType => {
  const consignments = (state.getIn(['entities', 'consignments']) as Map<ConsignmentEventId, Consignment>) || List()
  return consignments
    .valueSeq()
    .groupBy((it) => it.get('orderId'))
    .toMap()
}

export const consignmentsByDepartmentIdsAndDateGroupByOrderIdSelector = createImmutableEqualSelector(
  consignmentsByDepartmentIdsAndDateSelector,
  (consignments) => consignments.groupBy((it) => it.get('orderId')).toMap()
)

const getPreAdviceConsignmentsByOrderIdSelector = (state: AppStateType): GroupedPreAdvicesByOrderId => {
  const preAdvices =
    (state.getIn(['entities', 'consignmentPreAdvices']) as Map<ConsignmentEventId, PreAdvice>) || List()
  return preAdvices.groupBy((it) => it.get('orderId')).toMap()
}

export const consignmentsByOrderSearchSelector: (state: AppStateType) => Seq.Indexed<Consignment> =
  createImmutableEqualSelector(
    getConsignmentsByOrderIdSelector,
    getPreAdviceConsignmentsByOrderIdSelector,
    orderSearchsSelector,
    (consignments, consignmentPreAdvices, orderIds: List<number>) =>
      consignments
        ? (orderIds
            .map((orderId) => {
              const consignment = consignments?.get(orderId)
              const preAdvice = consignmentPreAdvices?.get(orderId)
              return preAdvice
                ? consignment?.map((it) => {
                    const preAdviceConsignment = preAdvice.get(it.get('id'))
                    if (preAdviceConsignment) {
                      return it.merge(preAdviceConsignment)
                    }
                    return it
                  })
                : consignment
            })
            .filter((it) => !!it)
            .flatten(1) as Seq.Indexed<Consignment>)
        : Seq.Indexed()
  )

export const consignmentsBySearchSelector: (state: AppStateType) => Seq.Indexed<Consignment> =
  createImmutableEqualSelector(
    (state: AppStateType) => state.getIn(['entities', 'consignments']) as Map<string, ImmutableEntity>,
    (state: AppStateType) => state.getIn(['entities', 'invoiceItems']) as Map<string, ImmutableEntity>,
    (state: AppStateType) => state.getIn(['entities', 'consignmentPreAdvices']) as Map<string, ImmutableEntity>,
    (state: AppStateType) => state.getIn(['searchConsignments', 'consignments']),
    (state: AppStateType) => state.getIn(['searchConsignments', 'invoiceItems']),
    (consignments, invoiceItems, consignmentPreAdvices, consignmentIds: List<string>, invoiceItemIds: List<string>) => {
      let cons: Immutable.Seq.Indexed<Consignment> = consignments
        ? consignmentIds
            .map((id) => {
              const entityId = parseInt(id, 10)
              const consignment = consignments?.getIn([entityId]) as Consignment | undefined
              const preAdvice = consignmentPreAdvices?.getIn([entityId]) as ImmutableEntity | undefined
              if (preAdvice) {
                return consignment?.merge(preAdvice)
              } else {
                return consignment
              }
            })
            .valueSeq()
            .filter((it): it is Consignment => !!it)
        : Seq.Indexed()
      let invItems: Immutable.Seq.Indexed<Consignment> = invoiceItemIds
        ? invoiceItemIds
            .map((id) => {
              const entityId = parseInt(id, 10)
              let item = invoiceItems?.getIn([entityId]) as ImmutableMap<any>
              return item.concat({
                pickupTimeEarliestCached: item.get('createdAt'),
                state: ConsignmentState.DELIVERED
              }) as Consignment
            })
            .valueSeq()
            .filter((it) => !!it)
        : Seq.Indexed()
      return cons.concat(invItems)
    }
  )

export const consignmentsByDepartmentIdAndDateAndAtLeastResolvedSelector = createImmutableEqualSelector(
  consignmentsByDepartmentIdAndDateSelector,
  (consignments) =>
    consignments.valueSeq().filter((c) => consignmentStates[c.get('state')] >= consignmentStates.RESOLVED)
)

export const consignmentsByDepartmentIdsAndDateAndAtLeastResolvedSelector = createImmutableEqualSelector(
  consignmentsByDepartmentIdsAndDateSelector,
  (consignments) =>
    consignments.valueSeq().filter((c) => consignmentStates[c.get('state')] >= consignmentStates.RESOLVED)
)

export const consignmentsByCustomerIdsSelector = createImmutableEqualSelector(
  consignmentsSelector,
  (state: AppStateType, customerIds: Set<CustomerIdType>) => customerIds,
  (consignments, customerIds) => consignments.valueSeq().filter((c) => customerIds.contains(c.get('custId')))
)

export const getShipmentIdToOrderIdSelector: (
  state: AppStateType,
  orderIds: OrderIdType[]
) => Map<OrderIdType, string> = createImmutableEqualSelector(
  consignmentsEntitySelector,
  (state: AppStateType, orderIds: OrderIdType[]) => orderIds,
  (consignments: Map<ConsignmentIdType, Consignment>, orderIds: OrderIdType[]) =>
    orderIds.reduce(
      (res, orderId: number) =>
        res.set(orderId, consignments.find((c) => c.get('orderId') == orderId)?.get('shipmentId') ?? ''),
      Map<OrderIdType, string>()
    )
)
