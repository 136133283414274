import React from 'react'
import { Label, LabelText, TextAreaInputWithLabel, TextWithLabel } from '../../../primitives/Forms'
import { LinkButton, SecondaryButton } from '../../../primitives/Button'
import i18next from 'i18next'
// @ts-expect-error
import { Control } from 'react-redux-form/lib/immutable'
import { LabeledInput } from '../../../primitives/LabeledInput'
import { Map } from 'immutable'
import { GridDiv } from '../../../primitives/GridDiv'

interface Props {
  mergeFormValues: (model: string, newVals: Map<string, any>) => void
  selectedCustomerId: number
  setPageStateValue: (key: string, value: any) => void
  customerInfo: string | undefined
  originalCustomerInformation: string | undefined
  updateCustomerInformation: (customerId: number, information: string) => void
  showEditCustomerSpecificInformation: boolean
}

const CustomerSpecificInformation = (props: Props) => (
  <div>
    {props.selectedCustomerId && !props.showEditCustomerSpecificInformation && (
      <ViewCustomerSpecificInformation {...props} />
    )}
    {props.selectedCustomerId && props.showEditCustomerSpecificInformation && (
      <EditCustomerSpecificInformation {...props} />
    )}
  </div>
)

const ViewCustomerSpecificInformation = (props: Props) => {
  const customerSpecificInformation =
    props.customerInfo || (props.customerInfo === '' ? '' : props.originalCustomerInformation)
  return (
    <TextWithLabel style={{ minHeight: '60px' }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '10fr 1fr'
        }}
      >
        <Label
          style={{
            margin: 0,
            fontSize: '0.625rem',
            gridRow: 1,
            gridColumn: 1
          }}
        >
          {i18next.t('instant.booking.customerInfoPlaceholder')}
        </Label>
        <LinkButton
          type="button"
          style={{
            height: '14px',
            fontSize: '0.75rem',
            gridRow: 1,
            gridColumn: 2,
            marginRight: 0,
            marginLeft: 'auto'
          }}
          onClick={() => {
            props.setPageStateValue('showEditCustomerSpecificInformation', true)
          }}
        >
          {i18next.t('button.edit')}
        </LinkButton>
        <LabelText
          style={{
            margin: 0,
            fontSize: '0.875rem',
            gridRow: 2,
            gridColumn: 1
          }}
        >
          {customerSpecificInformation}
        </LabelText>
      </div>
    </TextWithLabel>
  )
}

const EditCustomerSpecificInformation = (props: Props) => {
  const customerSpecificInformation =
    props.customerInfo || (props.customerInfo === '' ? '' : props.originalCustomerInformation)
  return (
    <TextWithLabel extraPadding>
      <LabeledInput whiteBackground label={i18next.t('instant.booking.customerInfoPlaceholder')} htmlFor="customerInfo">
        <Control.textarea model=".customerInfo" id="customerInfo" rows={2} component={TextAreaInputWithLabel} />
      </LabeledInput>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '2fr 1fr auto',
          gridGap: '1rem',
          marginTop: '1rem',
          marginBottom: '1rem'
        }}
      >
        <LabelText
          style={{
            fontSize: '0.625rem',
            gridRow: 1,
            gridColumn: 1
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: i18next.t('instant.booking.customerInfoHelper') }} />
        </LabelText>
        <GridDiv row={2} column={2} justifySelf="end">
          <LinkButton
            type="button"
            style={{
              gridRow: 2,
              gridColumn: 3,
              width: '98px',
              height: '19px',
              alignSelf: 'center'
            }}
            onClick={() => {
              props.setPageStateValue('showEditCustomerSpecificInformation', false)
              props.mergeFormValues('createOrderForm', Map({ customerInfo: props.originalCustomerInformation }))
            }}
          >
            {i18next.t('button.cancel')}
          </LinkButton>
          <SecondaryButton
            type="button"
            onClick={() => {
              props.setPageStateValue('showEditCustomerSpecificInformation', false)
              if (
                customerSpecificInformation !== undefined &&
                customerSpecificInformation !== props.originalCustomerInformation
              ) {
                props.updateCustomerInformation(props.selectedCustomerId, customerSpecificInformation)
                props.setPageStateValue('originalCustomerInformation', customerSpecificInformation)
              }
            }}
          >
            {i18next.t('button.save')}
          </SecondaryButton>
        </GridDiv>
      </div>
    </TextWithLabel>
  )
}

export default CustomerSpecificInformation
