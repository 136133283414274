import React, { useRef } from 'react'
import { FlexRow } from '../../../../primitives/Flex'
import { DateTime } from 'luxon'
import variables from '../../../../styles/variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from '../../../DatePicker'
import styled from 'styled-components'
import { LinkButton } from '../../../../primitives/Button'
import { localizedDayPickerProps } from '../../../../utils/dateTime'

export const MonthPicker: React.FC<{
  endDate: DateTime
  setEndDate: (date: DateTime) => void
}> = ({ endDate, setEndDate }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const prevMonthEnabled = endDate.startOf('month') > DateTime.now()
  const nextMonthEnabled = endDate < DateTime.now().plus({ months: 6 })

  return (
    <FlexRow style={{ marginTop: '0.2rem' }}>
      <FontAwesomeIcon
        style={{ marginTop: '3px' }}
        icon={['far', 'calendar-alt']}
        color={variables.newColors.darkGrey}
        onClick={() => inputRef?.current?.focus()}
      />
      <MonthPageButton
        onClick={() => setEndDate(DateTime.max(endOfPrevMonth(endDate), DateTime.now().endOf('day')))}
        disabled={!prevMonthEnabled}
      >
        <FontAwesomeIcon
          icon="chevron-left"
          color={prevMonthEnabled ? variables.newColors.darkGrey : variables.newColors.lightGrey}
          size="sm"
        />
      </MonthPageButton>
      <DatePicker
        value={formatMonth(endDate)}
        onDayChange={(newDate: Date) => setEndDate(DateTime.fromJSDate(newDate))}
        formatDate={formatMonthFromDate}
        parseDate={() => endDate.toJSDate()}
        inputProps={{
          style: { ...customDatePickerInputStyle, width: getInputWidth(formatMonth(endDate)) },
          readOnly: true,
          ref: inputRef
        }}
        dayPickerProps={{
          ...localizedDayPickerProps,
          disabledDays: [{ before: new Date() }]
        }}
      />
      <MonthPageButton
        style={{ marginLeft: '0.0em', fontWeight: 'bold' }}
        onClick={() => setEndDate(endOfNextMonth(endDate))}
        disabled={!nextMonthEnabled}
      >
        <FontAwesomeIcon
          icon="chevron-right"
          color={nextMonthEnabled ? variables.newColors.darkGrey : variables.newColors.lightGrey}
          size="sm"
        />
      </MonthPageButton>
    </FlexRow>
  )
}

const formatMonth = (dateTime: DateTime): string => dateTime.toFormat('MMMM')

const formatMonthFromDate = (date: Date): string => formatMonth(DateTime.fromJSDate(date))

const endOfNextMonth = (dateTime: DateTime): DateTime => dateTime.plus({ month: 1 }).endOf('month')

const endOfPrevMonth = (dateTime: DateTime): DateTime => dateTime.minus({ month: 1 }).endOf('month')

const getInputWidth = (month: string): number => {
  const tmp = document.createElement('span')
  tmp.innerHTML = month.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
  document.body.appendChild(tmp)
  const width = tmp.getBoundingClientRect().width
  document.body.removeChild(tmp)
  return width + 5
}

const MonthPageButton = styled(LinkButton)`
  margin: 0 0.3rem;
  height: 1rem;
`

const customDatePickerInputStyle = {
  fontSize: '0.9rem',
  cursor: 'default',
  outlineWidth: 0
}
