import { eventDataValue } from '@glow/entity-types'
import i18next from 'i18next'
import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'

export function EventRecipient(props: { data: AnyData; label?: string }) {
  const recipientName = eventDataValue(props.data as Record<string, unknown> | null, ['recipientName'])
  const recipientNameText = eventDataValue(props.data as Record<string, unknown> | null, ['recipientNameText'])
  const recipientNameFinal = recipientName || recipientNameText

  if (!recipientNameFinal) {
    return null
  }

  return (
    <div>
      <p className="text-sm text-emphasis-medium mb-1">
        {props.label ? props.label : i18next.t('consignmentEvent.message.recipientName')}
      </p>
      <p className="text-emphasis-high">{recipientNameFinal}</p>
    </div>
  )
}
