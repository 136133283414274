import { H3 } from '../../primitives/Headings'
import i18next from 'i18next'
import { IconFa } from '@glow/ui-components'
import variables from '../../styles/variables'
import { Trans } from 'react-i18next'
import { Label } from '../../primitives/Forms'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import ValidationInput from '../ValidationInput'
import { ValidationError } from '../../primitives/ErrorMessages'
import TimePicker from '../../pages/instant/TimePicker'
import React from 'react'
import { UnitFormPropsType, UnitSelect } from './UnitForm'
import { BreakType } from '../../actions/creators/helpers'
import { driveTimeHours } from './UnitHelpers'

type Props = {
  changeFormValue: (model: string, value: string) => void
  model: string
  formValues: UnitFormPropsType
}

export const BreakFormFragment = ({ changeFormValue, model, formValues }: Props) => {
  const clearBreakValues = () => {
    changeFormValue(model + '.breakTimeWindowDuration', '')
    changeFormValue(model + '.breakDrivingTimeDuration', '')
    changeFormValue(model + '.breakEarliest', '')
    changeFormValue(model + '.breakLatest', '')
    changeFormValue(model + '.breakMaxDrivingTime', '')
  }

  const graphHopperHasFixedDriveTimeBreak = false
  return (
    <div className="mt-10 mb-6 w-[calc(98%)]">
      <H3>
        {i18next.t('courier.breakTime')}
        <IconFa icon={['far', 'info-circle']} className="ml-2" id={'id'} title={i18next.t('courier.breakTooltip')} />
      </H3>
      <div className="flex gap-6 mt-4">
        <div>
          <label>
            <Control.radio
              model=".breakType"
              value={BreakType.NoBreakTime}
              checked={formValues.get('breakType') === ''}
              onChange={clearBreakValues}
              id="breakType"
            />{' '}
            {i18next.t('courier.radioButtons.noBreakTime')}
          </label>
        </div>

        <div>
          <label>
            <Control.radio
              model=".breakType"
              value={BreakType.TimeWindow}
              checked={formValues.get('breakType') === BreakType.TimeWindow}
              onChange={clearBreakValues}
              id="breakType"
            />{' '}
            {i18next.t('courier.radioButtons.timeWindow')}
          </label>
        </div>

        {graphHopperHasFixedDriveTimeBreak && (
          <div>
            <label>
              <Control.radio
                model=".breakType"
                value={BreakType.DriveTime}
                checked={formValues.get('breakType') === BreakType.DriveTime}
                onChange={clearBreakValues}
                id="breakType"
              />{' '}
              {i18next.t('courier.radioButtons.driveTime')}
            </label>
          </div>
        )}
      </div>

      {formValues.get('breakType') !== BreakType.NoBreakTime && (
        <div className="mt-4">
          <p style={{ color: variables.newColors.darkGrey }} className="text-base">
            <Trans
              i18nKey={
                formValues.get('breakType') === BreakType.TimeWindow
                  ? 'courier.breakTimeDescription'
                  : 'courier.driveTimeDescription'
              }
            />
          </p>
          <div className="flex gap-4 text-base mt-4">
            {formValues.get('breakType') === BreakType.TimeWindow ? (
              <Label inline noMargin>
                {i18next.t('courier.timeWindow')}
                <div>
                  <Control.text
                    model=".breakEarliest"
                    component={TimePicker}
                    style={{ width: 150, padding: '0.75rem' }}
                  />
                  <span className="m-2">–</span>
                  <Control.text
                    model=".breakLatest"
                    component={TimePicker}
                    style={{ width: 150, padding: '0.75rem' }}
                  />
                  <Errors
                    className="errors"
                    model=".breakEarliest"
                    show="touched"
                    wrapper={ValidationError}
                    messages={{ validTimeWindow: `${i18next.t('error.notAValidTimeWindow')}` }}
                  />
                </div>
              </Label>
            ) : (
              <Label inline noMargin>
                <div>{i18next.t('courier.maxDrivingTime')}</div>
                <UnitSelect white style={{ width: '14rem' }}>
                  <Control.select model=".breakMaxDrivingTime" id="maxDrivingTime" style={{ width: '14rem' }}>
                    <option key={0} value={''}>
                      {i18next.t('courier.selectMaxDriveTime')}
                    </option>
                    {driveTimeHours.map((hours) => {
                      return (
                        <option key={`driveTime-${hours.code}`} value={hours.code}>
                          {hours.label}
                        </option>
                      )
                    })}
                  </Control.select>
                  <Errors
                    className="errors"
                    model=".breakMaxDrivingTime"
                    show="touched"
                    wrapper={ValidationError}
                    messages={{ requiredIfDriveTimeDuration: `${i18next.t('error.requiredBreakDuration')}` }}
                  />
                </UnitSelect>
              </Label>
            )}
            <Label inline noMargin>
              {i18next.t('courier.breakDuration')}
              <Control.text
                model={
                  formValues.get('breakType') === BreakType.TimeWindow
                    ? '.breakTimeWindowDuration'
                    : '.breakDrivingTimeDuration'
                }
                id="breakDuration"
                component={ValidationInput}
              />
              <Errors
                className="errors"
                model={'.breakTimeWindowDuration'}
                show="touched"
                wrapper={ValidationError}
                messages={{
                  requiredIfTimeWindow: `${i18next.t('error.requiredBreakDuration')}`,
                  validNumberOrEmpty: `${i18next.t('error.notAValidNumber')}`
                }}
              />
              <Errors
                className="errors"
                model={'.breakDrivingTimeDuration'}
                show="touched"
                wrapper={ValidationError}
                messages={{
                  validNumberOrEmpty: `${i18next.t('error.notAValidNumber')}`,
                  requiredIfMaxDrivingTime: `${i18next.t('error.requiredBreakDuration')}`
                }}
              />
            </Label>
          </div>
        </div>
      )}
    </div>
  )
}
