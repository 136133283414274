import { OrderAction } from '../bookingSupport'
import i18next from 'i18next'
import { List } from 'immutable'

export const getButtonTitle = (actionType: OrderAction) => {
  switch (actionType) {
    case OrderAction.Edit:
      return i18next.t('instant.booking.editButton')
    case OrderAction.Copy:
      return i18next.t('instant.booking.copyButton')
    case OrderAction.Create:
      return i18next.t('instant.booking.orderButton')
    case OrderAction.Restore:
      return i18next.t('instant.booking.restoreButton')
  }
}

const daysMapping = List(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'])

export const getDayOfWeekTranslation = (dayOfWeek: number, short: boolean = false) => {
  const header = short ? 'weekdaysShort' : 'weekdays'
  const dayKey = daysMapping.get(dayOfWeek)
  return dayKey ? i18next.t(header + '.' + dayKey) : ''
}

export const renderAddressSuggestion = (suggestion: any) =>
  `${suggestion.name} - ${suggestion.address}, ${suggestion.zipCode} ${suggestion.zipArea}, ${(
    suggestion.country || ''
  ).toUpperCase()} - ${suggestion.phone || ''} ${suggestion.contactPerson || ''}`
