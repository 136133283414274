import { BackButton, ButtonBase, LayoutMain } from '@glow/ui-components'
import i18next from 'i18next'
import React, { ReactNode } from 'react'
import { CMSPageHeading } from '../../../components/cms/CMSPageHeading'

export const BulkActionLayout = (props: {
  children: ReactNode
  goBack: () => void
  title: string
  disableSaveButton?: boolean
  isSubmitting?: boolean
  selectedCount: number
}) => (
  <LayoutMain
    title={
      <CMSPageHeading
        backButton={<BackButton type="button" onClick={props.goBack} />}
        rightContainer={
          <div>
            <ButtonBase onClick={props.goBack} className="mr-4" type="button" variant="secondary">
              {i18next.t('button.cancel')}
            </ButtonBase>
            <ButtonBase
              type="submit"
              disabled={props.disableSaveButton}
              isLoading={props.isSubmitting}
              variant="primary"
            >
              {i18next.t('button.save')}
            </ButtonBase>
          </div>
        }
      >
        {props.title}
      </CMSPageHeading>
    }
  >
    <div className="-mt-7 ml-8 mb-4 text-sm text-emphasis-medium">
      {i18next.t('plannerDashboard.nrOfSelected', { count: props.selectedCount })}
    </div>
    {props.children}
  </LayoutMain>
)
