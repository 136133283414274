import i18next from 'i18next'
import React from 'react'
import { eventDataValue } from '@glow/entity-types'
import { formatEventsDataOrBlank } from './event-updated'
import { AnyData } from '../../../../actions/creators/baseHelpers'

export function EventServiceUpgradePurchased(props: { data: AnyData }) {
  const deliveryTimeEarliestFrom = eventDataValue(props.data as Record<string, unknown> | null, [
    'deliveryTimeEarliest',
    'from'
  ])
  const deliveryTimeEarliestTo = eventDataValue(props.data as Record<string, unknown> | null, [
    'deliveryTimeEarliest',
    'to'
  ])
  const deliveryTimeLatestFrom = eventDataValue(props.data as Record<string, unknown> | null, [
    'deliveryTimeLatest',
    'from'
  ])
  const deliveryTimeLatestTo = eventDataValue(props.data as Record<string, unknown> | null, [
    'deliveryTimeLatest',
    'to'
  ])

  return (
    <div className="flex flex-col gap-1">
      <p className="text-emphasis-high">{i18next.t('consignmentEvent.message.serviceUpgradePurchasedDescription')}</p>
      {(deliveryTimeEarliestFrom || deliveryTimeEarliestTo) && (
        <p>
          <span className="text-emphasis-medium">{i18next.t('consignment.earliestDeliveryTime')}: </span>
          <span className="text-emphasis-high">
            {formatEventsDataOrBlank(deliveryTimeEarliestFrom)}
            {' → '}
            {formatEventsDataOrBlank(deliveryTimeEarliestTo)}
          </span>
        </p>
      )}
      {(deliveryTimeLatestFrom || deliveryTimeLatestTo) && (
        <p>
          <span className="text-emphasis-medium">{i18next.t('consignment.latestDeliveryTime')}: </span>
          <span className="text-emphasis-high">
            {formatEventsDataOrBlank(deliveryTimeLatestFrom)}
            {' → '}
            {formatEventsDataOrBlank(deliveryTimeLatestTo)}
          </span>
        </p>
      )}
    </div>
  )
}
