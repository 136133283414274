import i18next from 'i18next'
import { List, Map, Seq, Set } from 'immutable'
import toLower from 'lodash/toLower'
import {
  Department,
  DepartmentGroup,
  DepartmentGroupIdType,
  DepartmentIdType,
  DepartmentWithNumberOfCourierAndPlanner,
  isAirExpressDepartment
} from '../types/coreEntitiesTypes'
import { AppStateType, ImmutableEntity } from '../utils/appStateReduxStore'
import { DepartmentType, getSelectedDepartmentIds, isGroupId } from '../utils/departmentAndDepartmentGroupUtils'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'

export const allDepartmentsFromStateSelector: (state: AppStateType) => Map<DepartmentIdType, Department> =
  createImmutableEqualSelector(
    (state: AppStateType) => state.getIn(['entities', 'departments']),
    (departments) => departments || Map()
  )

export const allDepartmentsExceptAirExpressFromStateSelector: (
  state: AppStateType
) => Map<DepartmentIdType, Department> = createImmutableEqualSelector(allDepartmentsFromStateSelector, (departments) =>
  departments.filter((d) => !isAirExpressDepartment(d))
)

export const departmentsSelector: (state: AppStateType) => List<Department> = createImmutableEqualSelector(
  allDepartmentsExceptAirExpressFromStateSelector,
  (departments) => (departments ? List<Department>(departments.valueSeq()) : List<Department>())
)

export const airExpressDepartmentsSelector = createImmutableEqualSelector(
  allDepartmentsFromStateSelector,
  (departments) =>
    departments ? List<Department>(departments.valueSeq()).filter((d) => isAirExpressDepartment(d)) : List<Department>()
)

export const allDepartmentsSelector = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'allDepartments']) as Map<string, ImmutableEntity>,
  (departments) =>
    departments ? List<Department>(departments.valueSeq() as Seq.Indexed<Department>) : List<Department>()
)

export const selectedDepartmentIdsSelector = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'departmentGroups']),
  (state: AppStateType, departmentOrGroupId?: string) => departmentOrGroupId,
  (allDepartmentGroups: Map<DepartmentGroupIdType, DepartmentGroup>, departmentId?: string) => {
    const props = { match: { params: { departmentId } } }
    const isDepartmentGroup = departmentId && isGroupId(departmentId)

    return allDepartmentGroups
      ? getSelectedDepartmentIds(allDepartmentGroups, props)
      : !isDepartmentGroup
        ? List.of(Number(departmentId))
        : List<DepartmentIdType>()
  }
)

export const selectedDepartmentsSelector: (state: AppStateType, departmentOrGroupId: string) => List<Department> =
  createImmutableEqualSelector(
    allDepartmentsFromStateSelector,
    selectedDepartmentIdsSelector,
    (departments, selectedDepartmentIds) => {
      const depIds = Set(selectedDepartmentIds)
      return departments
        .valueSeq()
        .filter((d) => depIds.contains(d.get('id')))
        .toList()
    }
  )
export const selectedDepartmentsByIdSelector: (
  state: AppStateType,
  departmentOrGroupId: string
) => Map<DepartmentIdType, Department> = createImmutableEqualSelector(
  allDepartmentsFromStateSelector,
  selectedDepartmentIdsSelector,
  (departments, selectedDepartmentIds) => {
    const depIds = Set(selectedDepartmentIds)
    return departments.filter((d) => depIds.contains(d.get('id')))
  }
)

export const departmentGroupsSelector = createImmutableEqualSelector(
  (state: AppStateType) =>
    state.getIn(['entities', 'departmentGroups']) || Map<DepartmentGroupIdType, DepartmentGroup>(),
  (departmentGroups: Map<DepartmentGroupIdType, DepartmentGroup>) => departmentGroups
)
export const departmentGroupListSelector = createImmutableEqualSelector(
  (state: AppStateType) =>
    (state.getIn(['entities', 'departmentGroups'])?.valueSeq() as List<DepartmentGroup>) || List<DepartmentGroup>(),
  (departmentGroups: List<DepartmentGroup>) => departmentGroups.sortBy((it) => it.get('name'))
)

export const allDepartmentsInSameCountrySelector = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'sameCountryDepartments']) as Map<string, ImmutableEntity>,
  (departments) =>
    departments ? List<Department>(departments.valueSeq() as Seq.Indexed<Department>) : List<Department>()
)

export const departmentFromIdSelector: (state: AppStateType, departmentId: DepartmentIdType) => Department =
  createImmutableEqualSelector(
    allDepartmentsFromStateSelector,
    (state: AppStateType, departmentId: DepartmentIdType) => departmentId,
    (departmentsById, departmentId) => departmentsById.get(departmentId) || Map()
  )

export const departmentsOfTypeSelector: (
  state: AppStateType,
  departmentType: DepartmentType
) => Map<DepartmentIdType, Department> = createImmutableEqualSelector(
  allDepartmentsFromStateSelector,
  (state: AppStateType, departmentType: DepartmentType) => departmentType,
  (departments, departmentType) =>
    departments.filter((department) => department.get('departmentType') === departmentType)
)

export const departmentsFromIdsSelector: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>
) => Map<number, Department> = createImmutableEqualSelector(
  allDepartmentsFromStateSelector,
  (state: AppStateType, departmentIds: List<DepartmentIdType>) => departmentIds,
  (departmentsById: Map<number, Department>, departmentIds: List<DepartmentIdType>) =>
    departmentsById.filter((d) => departmentIds.includes(d.get('id'))) || List()
)

export const departmentsInSameCountrySelector: (state: AppStateType, countryCode: string) => List<Department> =
  createImmutableEqualSelector(
    allDepartmentsInSameCountrySelector,
    (state: AppStateType, countryCode: string) => countryCode,
    (departments, countryCode) => departments.filter((d) => toLower(d.get('country')) === toLower(countryCode))
  )

export const departmentNameSelector: (state: AppStateType, departmentOrGroupId: string) => string =
  createImmutableEqualSelector(
    allDepartmentsFromStateSelector,
    (state: AppStateType) =>
      (state.getIn(['entities', 'departmentGroups']) as List<DepartmentGroup>) || List<DepartmentGroup>(),
    (state: AppStateType, departmentOrGroupId: string) => departmentOrGroupId,
    (departments, departmentGroups, departmentOrGroupId) => {
      if (isGroupId(departmentOrGroupId)) {
        const departmentGroupId = parseInt(departmentOrGroupId.split('_')[1], 10)

        return (
          departmentGroups.find((group) => group.get('id') === departmentGroupId)?.get('name') ?? departmentOrGroupId
        )
      } else {
        return (
          departments.find((department) => department.get('id') === Number(departmentOrGroupId))?.get('name') ??
          departmentOrGroupId
        )
      }
    }
  )

const departmentGroupCountByDepartmentId: (state: AppStateType) => Map<DepartmentIdType, number> =
  createImmutableEqualSelector(
    (state: AppStateType) => state.getIn(['entities', 'departmentGroups']) as Set<DepartmentGroup>,
    (depGroups) => getEntityCountGroupByDepartmentId(depGroups)
  )

export const departmentsWithNumberOfCourierAndPlannerSelector: (
  state: AppStateType
) => Map<DepartmentIdType, DepartmentWithNumberOfCourierAndPlanner> = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['aggregates', 'userCount']) as Map<DepartmentIdType, number>,
  departmentGroupCountByDepartmentId,
  allDepartmentsFromStateSelector,
  (plannerDepartmentGroup, departmentGroupGroup, departments) => {
    return departments.map((department) => {
      const plannersForThisDepartment = plannerDepartmentGroup?.get(department.get('id'))
      const departmentGroupsForThisDepartment = departmentGroupGroup.get(department.get('id'))
      return department.withMutations((dept: any) => {
        dept
          .set('numberOfPlanners', plannersForThisDepartment || i18next.t('department.noPlanner'))
          .set('numberOfDepartmentGroups', departmentGroupsForThisDepartment || 0)
      }) as DepartmentWithNumberOfCourierAndPlanner
    })
  }
)

const getEntityCountGroupByDepartmentId = <T extends Map<any, any>>(entity: Set<T>): Map<DepartmentIdType, number> => {
  if (!entity) return Map()
  const groupByDepartmentIds = entity.groupBy((grouper: T) => grouper.get('departments'))
  let entityCountObject: Map<DepartmentIdType, number> = Map()
  groupByDepartmentIds.map((entityMap, listOfDepartmentIds: List<DepartmentIdType>) => {
    listOfDepartmentIds.map((departmentId) => {
      entityCountObject = entityCountObject.withMutations((mutator) => {
        mutator.set(departmentId, entityMap.count() + (entityCountObject.get(departmentId) || 0))
      })
    })
  })
  return entityCountObject
}
