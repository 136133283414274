import i18next from 'i18next'
import { Map } from 'immutable'
import padStart from 'lodash/padStart'
import toString from 'lodash/toString'
import { DateTime, Duration, Interval, Settings } from 'luxon'
import { ISODateString, ISODateTimeString, ISOTimeString } from '../types/coreEntitiesTypes'

export const languages = ['en', 'nb', 'sv', 'dk', 'fi']
export const fallbackLanguage = 'en'
export const userLanguage = i18next.language || navigator.language || fallbackLanguage
Settings.defaultLocale = userLanguage

export const zoneUTC = 'utc'

export function isValidDate(date: any) {
  return (DateTime.isDateTime(date) && date.isValid) || (date && DateTime.fromISO(date).isValid)
}

/**
 * Today's date as DateTime using default locale and setting all time fields to 0
 */
export function today() {
  return DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
}

export function isSameDayLuxon(date1: string | DateTime, date2: string | DateTime) {
  const d1 = toLocaleDate(date1)
  const d2 = toLocaleDate(date2)
  return d1.hasSame(d2, 'day')
}

export function isTodayOrTomorrow(date1: string | DateTime) {
  return date1 && (isSameDayLuxon(date1, DateTime.local()) || isSameDayLuxon(date1, DateTime.local().plus({ days: 1 })))
}

export function isInThePast(date: string | null) {
  if (!date) return false
  const duration = toLocaleDate(date).diffNow()
  return duration.milliseconds < 0
}

export function isDateBetweenIntervalLuxon(fromDate: DateTime, consignmentDate: DateTime, toDate: DateTime) {
  return Interval.fromDateTimes(fromDate.startOf('day'), toDate.plus({ days: 1 }).startOf('day')).contains(
    consignmentDate
  )
}

export function isFirstDateBeforeOrEqualToSecondDateLuxon(firstDate: DateTime, secondDate: DateTime) {
  return firstDate.startOf('day').valueOf() <= secondDate.startOf('day').valueOf()
}

export function getDurationInDaysLuxon(firstDate: DateTime, secondDate: DateTime) {
  return Math.floor(Interval.fromDateTimes(firstDate, secondDate).toDuration('days').days)
}

export function getTimePeriodDateTime(early: DateTime, late: DateTime) {
  return `${early.toFormat('D: HH:mm')} - ${format24HourTime(late)}`
}

export function getTimePeriodTimeFromString(earliest: string, latest: string) {
  const early = toLocaleDate(earliest)
  const late = toLocaleDate(latest)
  return getTimePeriodTime(early, late)
}

export function getTimePeriodTimeFromStringOrUndefined(earliest: string | undefined, latest: string | undefined) {
  const early = earliest ? toLocaleDate(earliest) : undefined
  const late = latest ? toLocaleDate(latest) : undefined
  return `${early ? format24HourTime(early) : '-'} - ${late ? format24HourTime(late) : '-'}`
}

export function getTimePeriodTime(early: DateTime, late: DateTime) {
  return `${format24HourTime(early)} - ${format24HourTime(late)}`
}

export function calculateTimeDiff(dateStr1: string, dateStr2: string) {
  const date1 = toLocaleDate(dateStr1)
  const date2 = toLocaleDate(dateStr2)
  return date2.diff(date1)
}

export function formatDuration(diff: Duration) {
  return diff.toISOTime({ suppressMilliseconds: true })
}

export function getTimePeriod(earliest: string, latest: string) {
  const early = toLocaleDate(earliest)
  const late = toLocaleDate(latest)

  return earliest && latest && getTimePeriodDateTime(early, late)
}

export function toLocaleDatePreserveNull(date: string | DateTime | null | undefined): DateTime | null {
  if (DateTime.isDateTime(date)) return date
  const dateTime = date ? DateTime.fromISO(date) : null
  return dateTime?.isValid ? dateTime : null
}

export function toLocaleDate(date: string | DateTime | null | undefined): DateTime {
  if (DateTime.isDateTime(date)) return date
  const dateTime = date ? DateTime.fromISO(date) : DateTime.local()
  return dateTime.isValid ? dateTime : DateTime.local()
}

export function toShortDateWithTime(date: string) {
  return DateTime.fromISO(date).toFormat('dd MMM')
}

export function dateShort(date: DateTime | null) {
  if (!date) return ''
  return date.toLocaleString(DateTime.DATE_SHORT)
}

export function formatDateShorter(date: DateTime) {
  return date.toFormat('dd/MM')
}

export function format24HourTime(dateTime: DateTime) {
  return dateTime.toLocaleString(DateTime.TIME_24_SIMPLE)
}

export function formatAsLocalDate(date: DateTime) {
  return date.toFormat("yyyy-MM-dd'T'HH:mm:ss")
}

export function hourMinute(dateTime: string | DateTime | null | undefined) {
  if (!dateTime) return ''
  return hourMinuteWithDateTime(toLocaleDate(dateTime))
}

export function hourMinuteWithDateTime(dateTime: DateTime) {
  if (!dateTime) return ''
  // We've been getting some errors in Sentry (GLOW-16E) about invalid locales
  // This forces a locale, thus avoiding the detection code that fails
  // For the simple format here, locale shouldn't matter
  return dateTime.setLocale('en-US').toFormat('HH:mm')
}

export function format24HourTimeWithSeconds(dateTime: DateTime) {
  return dateTime.toLocaleString(DateTime.TIME_24_WITH_SECONDS)
}

export function formatDateFromIso(date: ISODateTimeString) {
  return formatDate(DateTime.fromISO(date))
}

export function formatDate(dateTime: DateTime) {
  return dateTime.toLocaleString(DateTime.DATE_MED)
}

export function formatDateTimeOrEmpty(dateTime: DateTime | string) {
  return dateTime ? formatDateTime(dateTime) : ''
}

export function formatDateTime(dateTime: DateTime | string) {
  return toLocaleDate(dateTime).toLocaleString(DateTime.DATETIME_MED)
}

export function atMidnight(dateTime: DateTime) {
  return dateTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
}

export const withWeekday = (dateTime: DateTime) => {
  return dateTime.setLocale(userLanguage).toLocaleString(DateTime.DATE_HUGE)
}

export const withWeekDayWithoutYear = (dateTime: DateTime) => {
  try {
    return dateTime.setLocale(userLanguage).toFormat('EEEE d. MMMM')
  } catch (err) {
    console.error(`Cannot parse dateTime. Locale is - ${userLanguage}, dateTime is - ${dateTime}`, err)
    return dateTime.setLocale(fallbackLanguage).toFormat('EEEE d. MMMM')
  }
}

export function withWeekdayAndTime(dateTime: DateTime) {
  return `${format24HourTime(dateTime)} ${withWeekday(dateTime)}`
}

export function withTimeAndDate(dateTime: DateTime) {
  return dateTime.setLocale(userLanguage).toLocaleString(DateTime.DATETIME_SHORT)
}

export function formatDateTimeTechnical(dateTime: DateTime | string) {
  return toLocaleDate(dateTime).toFormat('yyyy-MM-dd HH:mm')
}

export const sameDay = (dateTime1: DateTime, dateTime2: DateTime) =>
  dateTime1.year === dateTime2.year && dateTime1.month === dateTime2.month && dateTime1.day === dateTime2.day

export function isToday(date: DateTime) {
  const now = DateTime.local()
  return sameDay(now, date)
}

export function isYesterday(date: DateTime) {
  const yesterday = DateTime.local().minus({ days: 1 })
  return date.hasSame(yesterday, 'year') && date.hasSame(yesterday, 'month') && date.hasSame(yesterday, 'day')
}

export function isTomorrow(date: DateTime) {
  const tomorrow = DateTime.local().plus({ days: 1 })
  return date.hasSame(tomorrow, 'year') && date.hasSame(tomorrow, 'month') && date.hasSame(tomorrow, 'day')
}

export function formatDateRelativeWithLocale(date: DateTime) {
  if (isToday(date)) return i18next.t('date.today')
  else if (isYesterday(date)) return i18next.t('date.yesterday')
  else if (isTomorrow(date)) return i18next.t('date.tomorrow')
  else return date.toLocaleString(DateTime.DATE_SHORT)
}

export function getServiceTimeWindow(consignment: Map<string, any>) {
  if (!(consignment && consignment.get('deliveryTimeLatest') && consignment.get('deliveryTimeEarliest'))) return '-'
  else
    return `${format24HourTime(toLocaleDate(consignment.get('deliveryTimeEarliest')))} - ${format24HourTime(
      toLocaleDate(consignment.get('deliveryTimeLatest'))
    )}`
}

export function getDeliveryTimeEstimatedPeriod(
  original: string | DateTime | null,
  adjusted: string | DateTime | null,
  halfTimeWindow: number
) {
  const org = toLocaleDate(original)
  const adj = adjusted ? toLocaleDate(adjusted) : null

  const useableDateTime = shouldUseOriginalTime(org, adj) || adj === null ? org : adj
  const useableRounded = useableDateTime.set({ minute: Math.round(useableDateTime.minute / 10) * 10 })

  const fromTime = calculateRecipientFromTime(useableRounded, halfTimeWindow)
  const toTime = calculateRecipientToTime(useableRounded, halfTimeWindow)
  return `${format24HourTime(fromTime)} - ${format24HourTime(toTime)}`
}

export function diffInMinutesBetweenTwoDates(original: string | undefined, adjusted: string | undefined) {
  if (!original || !adjusted) return 0
  const adjustedLocalDate = toLocaleDate(adjusted)
  const originalLocalDate = toLocaleDate(original)
  const diff = originalLocalDate.diff(adjustedLocalDate, 'minutes').as('minutes')
  return Math.round(diff)
}

function shouldUseOriginalTime(original: DateTime, adjusted: DateTime | null) {
  if (!adjusted) return true
  const diff = original.diff(adjusted, 'minutes').as('minutes')
  return -20 <= diff && diff <= 20
}

function calculateRecipientFromTime(date: DateTime, halfTimeWindow: number) {
  return date.minus({ minute: halfTimeWindow })
}

function calculateRecipientToTime(date: DateTime, halfTimeWindow: number) {
  return date.plus({ minute: halfTimeWindow })
}

export function getTimeZoneFromCountry(country: string) {
  switch (country) {
    case 'NO':
      return 'Europe/Oslo'
    case 'SE':
      return 'Europe/Stockholm'
    case 'DK':
      return 'Europe/Copenhagen'
    case 'FI':
      return 'Europe/Helsinki'
    case 'UK':
    case 'IS':
      return 'Europe/London'
    default:
      return 'Europe/Oslo'
  }
}

export const formatMillisecsToHHMMSS = (duration: number) => Duration.fromMillis(duration).toFormat('hh:mm:ss')

export const formatSecsToHHMM = (duration: number) =>
  Duration.fromMillis(Math.round(duration / 60) * 60 * 1000).toFormat('hh:mm')

export const timeToStringWithZeroPadding = (time: DateTime) =>
  hourMinuteToStringWithZeroPadding(time.get('hour'), time.get('minute'))

export const hourMinuteToStringWithZeroPadding = (hour: number | undefined, minute: number | undefined) =>
  `${padStart(toString(hour), 2, '0')}:${padStart(toString(minute), 2, '0')}`

const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
]

// day-picker-input defaults to sunday first, which is the order in which parameters must be given
const DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export const localizedDayPickerProps = {
  locale: i18next.language,
  firstDayOfWeek: 1,
  months: MONTHS.map((mo) => i18next.t('months.' + mo)),
  weekdaysLong: DAYS.map((day) => i18next.t('weekdays.' + day)),
  weekdaysShort: DAYS.map((day) => i18next.t('weekdaysShort.' + day))
}

export const getTimeIfMatchesOtherDate = (
  dateTimeString: ISODateTimeString | undefined,
  dateTime: DateTime,
  zone: string = zoneUTC
): string | boolean | undefined =>
  dateTimeString &&
  sameDay(DateTime.fromISO(dateTimeString, { zone: zone }), dateTime) &&
  format24HourTime(DateTime.fromISO(dateTimeString))

export const makeISODateTime = (date: ISODateString, time?: ISOTimeString): ISODateTimeString | ISODateString =>
  date + (time ? 'T' + time : '')

export const parseDateTime = (dateOnly: ISODateString, timeWithOffset: ISOTimeString): DateTime =>
  DateTime.fromISO(dateOnly + 'T' + timeWithOffset)

export interface Time {
  hours: number
  minutes: number
}

export const getHoursMinutes = (time: ISOTimeString): Time => {
  const hourMinutes = time.split(':')
  return {
    hours: Number(hourMinutes[0]),
    minutes: Number(hourMinutes[1])
  }
}

export const pickDate = (dateTime?: ISODateTimeString): ISODateString | undefined => {
  const parsed = toLocaleDatePreserveNull(dateTime)
  return parsed ? atMidnight(parsed).toISODate() : undefined
}

export const pickTime = (dateTime?: ISODateTimeString): ISOTimeString | undefined => {
  const parsed = toLocaleDatePreserveNull(dateTime)
  return parsed ? parsed.toLocaleString(DateTime.TIME_24_SIMPLE) : undefined
}

export const getEarliestPickupTime = (
  servicePickupTimeInterval: string,
  pickupTimeFrom?: ISOTimeString,
  pickupTimeTo?: ISOTimeString
): ISOTimeString => {
  const timeNow = DateTime.local()

  if (pickupTimeTo && pickupTimeFrom) {
    const from = toLocaleDate(pickupTimeFrom)
    const latestPickupTime = timeMinusHours(toLocaleDate(pickupTimeTo), parseInt(servicePickupTimeInterval))

    return isWithinServiceTimeWindow(from, latestPickupTime, timeNow) ? timeNow.toFormat('HH:mm') : pickupTimeFrom
  }

  return timeNow.toFormat('HH:mm')
}

export const timeMinusHours = (time: DateTime, hours: number): DateTime => time.minus({ hours })
const dateWithAddedDays = (day: number): DateTime => DateTime.local().plus({ day })

export const getEarliestPickupDate = (pickupTime?: ISOTimeString, pickupTimeFrom?: ISOTimeString): ISODateString =>
  pickupTime === pickupTimeFrom ? dateWithAddedDays(1).toISODate() : DateTime.local().toISODate()

export const isWithinServiceTimeWindow = (
  servicePickupTimeFrom: DateTime,
  servicePickupTimeTo: DateTime,
  pickupTime: DateTime
) => servicePickupTimeFrom <= pickupTime && pickupTime <= servicePickupTimeTo

export const firstTimeIsBeforeSecondTime = (pickupTime: DateTime, pickupTimeFrom: DateTime) =>
  pickupTime < pickupTimeFrom
export const isWeekendDay = (date: DateTime) => [6, 7].includes(date.weekday)
export const dateInTheFuture = (date: ISODateString) => {
  const pickedDate = toLocaleDate(date)
  const dateNow = DateTime.local()

  return pickedDate.startOf('day') > dateNow.startOf('day')
}

export function isDate(value: unknown): value is Date {
  return value instanceof Date && !isNaN(value.valueOf())
}

export const isDayInThePast = (date: Date) => DateTime.fromJSDate(date).startOf('day') < DateTime.now().startOf('day')
