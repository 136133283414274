import i18next from 'i18next'
import React from 'react'
import { toLocaleDate, withTimeAndDate } from '../../../../utils/dateTime'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { eventDataValue } from '@glow/entity-types'

export function EventEtaSmsSent(props: { data: AnyData }) {
  const calculatedEta = eventDataValue(props.data, ['calculated_eta'])

  if (!calculatedEta) {
    return null
  }

  const eta = withTimeAndDate(toLocaleDate(calculatedEta as string))

  return (
    <div>
      <p className="text-sm text-emphasis-medium mb-1">{i18next.t('consignmentEvent.message.etaSmsLabel')}</p>
      <p className="text-emphasis-high">{eta}</p>
    </div>
  )
}
