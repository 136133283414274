import { Map } from 'immutable'
import React, { FunctionComponent } from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { IConsignmentEvent } from '../../../../types/coreEntitiesTypes'
import { ImmutableMap } from '../../../../types/immutableTypes'
import { EventType } from '../../../../utils/consignmentEvent'
import { DeviationOrderPropsType } from '../../../admin/orderDeviationTypes'
import { EventFromToDepartment } from './EventFromToDepartment'
import { ArrivedAtTerminalMessage } from './arrived-at-terminal'
import { AssignedMessage } from './assigned-message'
import { DeliveryIdCheck } from './delivery-id-check'
import { DeliveryLocationDeviationMessage } from './delivery-location-deviation'
import { DeliveryTimeEstimate } from './delivery-time-estimated'
import { DigitalIdentityVerifiedMessage } from './digitally-identification-verified'
import { EventComment } from './event-comment'
import { EventDamaged } from './event-damaged'
import { EventDefault } from './event-default'
import { EventDeliveryTimeOrdered } from './event-delivery-time-ordered'
import { EventDeviated } from './event-deviated'
import { EventEtaSmsSent } from './event-eta-sms-sent'
import { EventImage } from './event-image'
import { EventInvoice } from './event-invoice'
import { EventInvoiceCallback } from './event-invoice-callback'
import { EventInvoicingAction } from './event-invoicing-action'
import { EventManuallyOverridden } from './event-manually-overridden'
import { EventNotification } from './event-notification'
import { EventOrderCredited } from './event-order-credited'
import { ParcelLockerDeliverySelected } from './event-parcel-locker-delivery-selected'
import { EventPreAdviceResult } from './event-preadvice-result'
import { EventRejected } from './event-rejected'
import { EventReportedDamaged, EventReportedMissing } from './event-reported-damaged-or-missing'
import { EventServiceUpgradePurchased } from './event-service-upgrade-purchased'
import { EventUnitAdded } from './event-unit-added'
import { FirstEstimatedTimesSeen } from './first-estimated-times-seen'
import { SignatureSmsSent } from './signature-sms-sent'
import { EventManualPriceEdit } from './event-manual-price-edit'
import { EventUpdated } from './event-updated'

interface Props {
  consignmentEvent: ImmutableMap<IConsignmentEvent>
  deviations: Map<number, DeviationOrderPropsType>
}

export function ConsignmentEventDetails({ consignmentEvent, ...props }: Props) {
  const Component = customerEventDetailsComponent(consignmentEvent.get('type'))

  if (!Component) {
    return null
  }

  const data: AnyData = consignmentEvent.get('data')?.toJS()

  return <Component {...props} consignmentEvent={consignmentEvent} data={data} />
}

interface FunctionComponentProps {
  consignmentEvent: ImmutableMap<IConsignmentEvent>
  deviations: Map<number, DeviationOrderPropsType>
  data: AnyData
}

function customerEventDetailsComponent(type: EventType): FunctionComponent<FunctionComponentProps> {
  switch (type) {
    case EventType.COMMENT:
      return EventComment
    case EventType.IMAGE:
      return EventImage
    case EventType.DEVIATED:
    case EventType.DEVIATED_MANUALLY_OVERRIDDEN:
      return EventDeviated
    case EventType.NOTIFICATION:
      return EventNotification
    case EventType.PICKUP_CONSIGNMENT_DATA_CHANGED:
    case EventType.SPECIFICATION_SIZE_CHANGED:
    case EventType.SPECIFICATION_DESCRIPTION_CHANGED:
    case EventType.DELIVERY_CONSIGNMENT_DATA_CHANGED:
    case EventType.EXTERNAL_ADDRESS_WASH:
    case EventType.ORDER_NOTE_CHANGED:
    case EventType.ORDER_DATA_CHANGED:
    case EventType.PACKAGE_MEASUREMENTS_CHANGED:
    case EventType.PRE_ADVICE_STATUS_CHANGED:
    case EventType.DELIVERY_TIME_UPDATED_FROM_HF_SYNC:
    case EventType.PACKAGE_DATE_CHANGED:
    case EventType.TIME_WINDOW_CANCELLED_BY_RECIPIENT:
      return EventUpdated
    case EventType.MANUAL_PRICE_OVERRIDE:
      return EventManualPriceEdit
    case EventType.DAMAGED:
      return EventDamaged
    case EventType.ETA_SMS_SENT:
      return EventEtaSmsSent
    case EventType.DELIVERY_TIME_ORDERED:
    case EventType.DELIVERY_TIME_ORDERED_INTERNAL:
      return EventDeliveryTimeOrdered
    case EventType.DIGITAL_IDENTITY_VERIFIED:
      return DigitalIdentityVerifiedMessage
    case EventType.DELIVERY_LOCATION_DEVIATION:
      return DeliveryLocationDeviationMessage
    case EventType.ARRIVED_AT_TERMINAL:
    case EventType.SCANNED:
      return ArrivedAtTerminalMessage
    case EventType.DELIVERY_TIME_ESTIMATED:
      return DeliveryTimeEstimate
    case EventType.FIRST_ESTIMATED_TIMES_SEEN:
      return FirstEstimatedTimesSeen
    case EventType.SIGNATURE_SMS_SENT:
      return SignatureSmsSent
    case EventType.ASSIGNED:
      return AssignedMessage
    case EventType.REPORTED_DAMAGED:
      return EventReportedDamaged
    case EventType.REPORTED_MISSING_ARTICLE:
      return EventReportedMissing
    case EventType.DELIVERED_MANUALLY_OVERRIDDEN:
    case EventType.COLLECTED_MANUALLY_OVERRIDDEN:
    case EventType.RETURNED_MANUALLY_OVERRIDDEN:
      return EventManuallyOverridden
    case EventType.REJECTED:
      return EventRejected
    case EventType.ORDER_MOVED_TO_DEPARTMENT:
      return EventFromToDepartment
    case EventType.SERVICE_UPGRADE_PURCHASED:
      return EventServiceUpgradePurchased
    case EventType.DELIVERY_ID_CHECK:
      return DeliveryIdCheck
    case EventType.INVOICED_OK:
    case EventType.INVOICING_FAILED:
      return EventInvoice
    case EventType.PARCEL_LOCKER_DELIVERY_SELECTED:
      return ParcelLockerDeliverySelected
    case EventType.PRE_ADVICE_RESULT:
      return EventPreAdviceResult
    case EventType.ORDER_PAUSED_FROM_INVOICING:
    case EventType.ORDER_RELEASED_FOR_INVOICING:
    case EventType.MANUALLY_SET_NOT_INVOICED_FOR_ORDER_INVOICING:
    case EventType.MANUALLY_SET_INVOICING_OK_FOR_ORDER_INVOICING:
    case EventType.MANUALLY_SET_EXCLUDED_FROM_INVOICING:
      return EventInvoicingAction
    case EventType.UNIT_MANUALLY_ADDED_FOR_INVOICING:
      return EventUnitAdded
    case EventType.INVOICE_ACKNOWLEDGED_BY_EXTERNAL_SYSTEM:
    case EventType.INVOICE_CREATED_IN_EXTERNAL_SYSTEM:
    case EventType.INVOICE_SENT_TO_CUSTOMER:
    case EventType.INVOICE_PAID_BY_CUSTOMER:
      return EventInvoiceCallback
    case EventType.ORDER_CREDITED:
      return EventOrderCredited
    default:
      return EventDefault
  }
}
