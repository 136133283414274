import i18next from 'i18next'
import { Collection, List, Map } from 'immutable'
import { DateTime } from 'luxon'
import React, { ReactNode } from 'react'
import PickupDeliveryInformation from '../components/PickupDeliveryInformation'
import { WayPoint } from '../query/liveViewQuery'
import { Consignment, CourierLocation, Shipment, Slot } from '../types/coreEntitiesTypes'
import { isNotEmpty, zipmapBy } from './collectionUtils'
import { userLanguage } from './dateTime'
import { courierShouldBeVisibleInMap } from './googleMapsMarkersHelpers'
import { groupMarkersOnPosition } from './mapUtils'

export interface MarkersFromShipments {
  id: string
  lat: number
  lng: number
  colors: string[]
  display: string
  icon?: string
  heading: string
  zIndex?: number
  information: ReactNode
}

export function generateMarkersFromShipments(
  shipments: List<Collection<number, Consignment>>,
  slots: List<Slot>,
  showPickups = true,
  showDeliveries = true,
  showAssigned = true,
  showUnassigned = true,
  allowEdit = true,
  useSlotColors = true,
  assignToSlotSelector?: (shipmentId: number) => JSX.Element
) {
  const slotsById = isNotEmpty(slots) ? (zipmapBy('id', slots) as Map<unknown, Slot>) : (Map() as Map<unknown, Slot>)
  const pickups = showPickups
    ? shipments
        .filter((c) => c.first()?.get('pickupLat') !== null && c.first()?.get('pickupLng') !== null)
        .filter((shipment) => (shipment.first()?.get('slotId') !== null ? showAssigned : true))
        .filter((shipment) => (shipment.first()?.get('slotId') === null ? showUnassigned : true))
        .map((shipment) => {
          const consignment = shipment.first()
          const slot = slotsById.get(consignment?.get('slotId'))
          const color = slot && useSlotColors ? slot.get('color') : '#cccccc'
          return {
            id: consignment?.get('orderId') + '.pickup',
            lat: consignment?.get('pickupLat'),
            lng: consignment?.get('pickupLng'),
            colors: [color],
            display: useSlotColors ? 'P' : '',
            icon: '',
            heading: `${consignment?.get('pickupAddress')}, ${consignment?.get('pickupZipCode')} ${consignment?.get(
              'pickupZipArea'
            )}`,
            zIndex: consignment?.get('id'),
            consignmentIds: [consignment?.get('id')],
            information: [
              () => (
                <PickupDeliveryInformation
                  type={'pickup'}
                  key={`${consignment?.get('orderId')}-pickup`}
                  shipment={consignment as Shipment}
                  slot={slot as Slot}
                  allowEdit={allowEdit}
                  assignToSlotSelector={assignToSlotSelector}
                  shouldOpenModal
                  allowUnassign={false}
                />
              )
            ]
          } as MarkersFromShipments
        })
        .valueSeq()
    : List<MarkersFromShipments>()

  const deliveries = showDeliveries
    ? shipments
        .filter((c) => c.first()?.get('deliveryLat') !== null && c.first()?.get('deliveryLng') !== null)
        .filter((shipment) => (shipment.first()?.get('slotId') !== null ? showAssigned : true))
        .filter((shipment) => (shipment.first()?.get('slotId') === null ? showUnassigned : true))
        .map((shipment) => {
          const consignment = shipment.first()
          const slot = slotsById.get(consignment?.get('slotId'))
          const color = slot && useSlotColors ? slot.get('color') : '#cccccc'
          return {
            id: consignment?.get('orderId') + '.delivery',
            lat: consignment?.get('deliveryLat'),
            lng: consignment?.get('deliveryLng'),
            colors: [color],
            display: useSlotColors ? 'D' : '',
            icon: useSlotColors ? '' : undefined,
            heading: `${consignment?.get('deliveryAddress')}, ${consignment?.get('deliveryZipCode')} ${consignment?.get(
              'deliveryZipArea'
            )}`,
            zIndex: consignment?.get('id'),
            information: [
              () => (
                <PickupDeliveryInformation
                  type={'delivery'}
                  key={`${consignment?.get('orderId')}-delivery`}
                  shipment={consignment as Shipment}
                  slot={slot as Slot}
                  allowEdit={allowEdit}
                  assignToSlotSelector={assignToSlotSelector}
                  shouldOpenModal
                  allowUnassign={false}
                />
              )
            ]
          } as MarkersFromShipments
        })
        .valueSeq()
    : List<MarkersFromShipments>()

  const pickupsAndDeliveries = List<MarkersFromShipments>()
    .concat(pickups)
    .concat(deliveries)
    .sort((a, b) => b.colors[0].localeCompare(a.colors[0]))

  return groupMarkersOnPosition(pickupsAndDeliveries)
}

export function generateMarkersFromCouriers(
  couriers: List<any>,
  userRole: string,
  courierIcon:
    | {
        url: string
        size: { width: number; height: number }
        origin: { x: number; y: number }
        anchor: { x: number; y: number }
        scaledSize: { width: number; height: number }
      }
    | {
        url: string
        size: { width: number; height: number }
        anchor: { x: number; y: number }
        scaledSize: { width: number; height: number }
        origin?: undefined
      }
) {
  const liveCouriers = couriers
    .filter(courierShouldBeVisibleInMap)
    .map((courier) => {
      const diffInHours = DateTime.fromISO(courier.getIn(['location', 'updatedAt']), { locale: userLanguage }).diffNow(
        'hours'
      ).hours
      const color = diffInHours < -6 ? '#3A3A3A' : '#666'
      return {
        id: `${courier.get('id')}.courier`,
        lat: courier.getIn(['location', 'lat']),
        lng: courier.getIn(['location', 'lng']),
        icon: courierIcon,
        colors: [color],
        display: 'C',
        zIndex: Number.MAX_SAFE_INTEGER,
        title: courier.get('name'),
        information: [
          () => (
            <div key={`courier-${courier.get('id')}`}>
              <h3>{courier.get('name')}</h3>
              <hr />
              <p>{courier.get('phoneNumber')}</p>
              <p>
                {i18next.t('grid.columns.lastUpdatedAt')}:{' '}
                {DateTime.fromISO(courier.getIn(['location', 'updatedAt']), { locale: userLanguage }).toLocaleString(
                  DateTime.DATETIME_MED
                )}
              </p>
            </div>
          )
        ]
      }
    })
    .filter((liveCourier) => liveCourier.lat && liveCourier.lng)

  return groupMarkersOnPosition(liveCouriers as any)
}

export function generateMarkersFromCourierLocations(
  courierLocations: Map<number, CourierLocation>,
  userRole: string,
  courierIcon:
    | {
        url: string
        size: { width: number; height: number }
        origin: { x: number; y: number }
        anchor: { x: number; y: number }
        scaledSize: { width: number; height: number }
      }
    | {
        url: string
        size: { width: number; height: number }
        anchor: { x: number; y: number }
        scaledSize: { width: number; height: number }
        origin?: undefined
      }
) {
  const liveCouriers = courierLocations.map((courier) => {
    const diffInHours = DateTime.fromISO(courier.get('updatedAt'), { locale: userLanguage }).diffNow('hours').hours
    const color = diffInHours < -6 ? '#3A3A3A' : '#666'
    return {
      id: `${courier.get('id')}.courier-location`,
      lat: courier.get('lat'),
      lng: courier.get('lng'),
      icon: courierIcon,
      colors: [color],
      display: 'C',
      zIndex: Number.MAX_SAFE_INTEGER,
      title: courier.get('userName'),
      information: [
        () => (
          <div key={`courier-location-${courier.get('id')}`}>
            <h3>{courier.get('userName')}</h3>
            <hr />
            <p>{courier.get('userPhoneNumber')}</p>
            <p>
              {i18next.t('grid.columns.lastUpdatedAt')}:{' '}
              {DateTime.fromISO(courier.get('updatedAt'), { locale: userLanguage }).toLocaleString(
                DateTime.DATETIME_MED
              )}
            </p>
          </div>
        )
      ]
    }
  })

  return groupMarkersOnPosition(liveCouriers as any)
}

export function generateMarkersFromWaypoints(waypoints: List<WayPoint>, slots = List<Slot>(), useSlotColors = true) {
  if (!waypoints || waypoints.isEmpty()) return []
  const wp = waypoints.map((waypoint, index) => {
    const color = waypoint.pickups.count() > 0 ? '#f4aefa' : '#93f1ee'
    const pickupInfo = waypoint.pickups
      .map((t) => t.get('shipment'))
      .map((s) => (
        <PickupDeliveryInformation
          type={'pickup'}
          key={`${waypoint.id}-pickup-${s?.get('id')}`}
          shipment={s}
          slot={slots.first() as Slot}
          allowUnassign={true}
          shouldOpenModal
        />
      ))
    const deliveryInfo = waypoint.deliveries
      .map((t) => t.get('shipment'))
      .map((s) => (
        <PickupDeliveryInformation
          type={'delivery'}
          key={`${waypoint.id}-delivery-${s?.get('id')}`}
          shipment={s}
          slot={slots.first() as Slot}
          allowUnassign={true}
          shouldOpenModal
        />
      ))

    const showInfo = List().concat(pickupInfo).concat(deliveryInfo)
    return {
      id: waypoint.id + '.' + waypoint.type,
      lat: waypoint.lat,
      lng: waypoint.lng,
      colors: [color],
      display: index + 1,
      heading: `${waypoint.address}, ${waypoint.zipCode} ${waypoint.zipArea}`,
      zIndex: waypoint.id,
      information: [() => showInfo]
    }
  })

  const slotStartMarkers = slots.map((slot) => {
    const color = slot && useSlotColors ? slot.get('color') : '#717171'
    return {
      id: slot?.get('id') + '.slotStart',
      lat: slot?.get('startLocationLat'),
      lng: slot?.get('startLocationLng'),
      colors: [color],
      display: 'S',
      heading: `Start: ${slot?.get('name')}`,
      zIndex: slot?.get('id'),
      information: [() => 'Slot start']
    }
  })

  const slotEndMarkers = slots
    .filter((slot) => slot.get('driverReturnToAddress'))
    .map((slot) => {
      const color = slot && useSlotColors ? slot.get('color') : '#717171'
      return {
        id: slot.get('id') + '.slotEnd',
        lat: slot.get('endLocationLat'),
        lng: slot.get('endLocationLng'),
        colors: [color],
        display: 'F',
        heading: `Finish: ${slot.get('name')}`,
        zIndex: slot.get('id'),
        information: [() => 'Slot finish']
      }
    })

  return wp.concat(slotStartMarkers).concat(slotEndMarkers).toJS()
}
