import i18next from 'i18next'
import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { ConsignmentEventConfirmed } from '../../../../components/ConsignmentEventConfirmed'
import { ImmutableMap } from '../../../../types/immutableTypes'
import { IConsignmentEvent } from '../../../../types/coreEntitiesTypes'

interface DamageData {
  filenames?: string[]
  causeCode: string
  damageType: string
  courierInput: string
}

export function EventDamaged(props: { data: AnyData; consignmentEvent: ImmutableMap<IConsignmentEvent> }) {
  const damage = props.data.damage as DamageData | undefined | null
  const filenames = damage?.filenames || []

  if (!damage) {
    return null
  }

  return (
    <>
      <p>
        <strong>{i18next.t('consignmentEvent.damaged.damageType')}: </strong>
        {/* dynamic i18next */}
        {i18next.t(`consignmentEvent.damaged.type.${damage.damageType}`, 'N/A')}
      </p>
      {filenames.length > 0 && (
        <ul>
          {filenames.map((f, i) => (
            <li key={f}>
              <a href={`/api/upload-multiple/redirect/${f}`} target="_blank" rel="noreferrer" className="underline">
                {i18next.t('consignmentEvent.damaged.showPictureLink', { idx: i + 1 })}
              </a>
            </li>
          ))}
        </ul>
      )}
      <ConsignmentEventConfirmed
        consignmentEventId={props.consignmentEvent.get('id')}
        consignmentEventType={props.consignmentEvent.get('type')}
      />
    </>
  )
}
