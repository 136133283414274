import { Form, Formik, FormikValues } from 'formik'
import i18next from 'i18next'
import { FormikInput, FormikSelect, FormikTime, formikValidationRequired } from '@glow/formik-components'
import { ButtonBase, IconFa, PlainButton } from '@glow/ui-components'
import React from 'react'
import { validBreakTimeWindow } from '../unit/breakInputValidation'
import { Map } from 'immutable'
import { Break } from '../unit/UnitForm'
import { BreakType } from '../../actions/creators/helpers'
import { Duration } from 'luxon'
import { driveTimeHours } from '../unit/UnitHelpers'

function validateTimeWindow(values: FormikValues) {
  return !!values.earliest && !!values.latest && !!values.duration
    ? validBreakTimeWindow(values.earliest, values.latest, values.duration)
      ? undefined
      : i18next.t('error.notAValidTimeWindow')
    : undefined
}

type Props = {
  updatedBreak?: Break
  courierBreak: Map<string, string>
  editBreakValues: (values: FormikValues) => void
  setEditMode: (value: boolean) => void
  breakDuration: number
  updatedBreakDuration?: number | string
}

export const BreakForm = ({
  updatedBreak,
  courierBreak,
  editBreakValues,
  setEditMode,
  breakDuration,
  updatedBreakDuration
}: Props) => {
  const maxDrivingTime = courierBreak.get('maxDrivingTime')
    ? Duration.fromISO(courierBreak.get('maxDrivingTime') || '').hours
    : ''
  const updatedMaxDrivingTime =
    updatedBreak?.maxDrivingTime && Duration.fromISO(updatedBreak?.maxDrivingTime || '').hours

  return (
    <Formik
      initialValues={{
        earliest: updatedBreak?.earliest ?? courierBreak.get('earliest'),
        latest: updatedBreak?.latest ?? courierBreak.get('latest'),
        duration: updatedBreakDuration ?? breakDuration,
        maxDrivingTime: updatedMaxDrivingTime ?? maxDrivingTime
      }}
      onSubmit={(values) => {
        editBreakValues(values)
      }}
    >
      {({ values }) => (
        <Form aria-label={i18next.t('courier.editBreakTime')} className="flex justify-between p-4">
          <div className="flex gap-4">
            {courierBreak.get('type') === BreakType.DriveTime ? (
              <FormikSelect
                name={'maxDrivingTime'}
                options={driveTimeHours}
                label={i18next.t('courier.maxDrivingTime')}
              ></FormikSelect>
            ) : (
              <div className="flex gap-2">
                <FormikTime
                  label={i18next.t('courier.timeWindow')}
                  name="earliest"
                  validate={(value) =>
                    formikValidationRequired(value, i18next.t('error.requiredEarliestStartBreak')) ||
                    validateTimeWindow(values)
                  }
                  className="w-44"
                />
                <span className="mt-7">–</span>
                <div>
                  <FormikTime
                    name="latest"
                    label={i18next.t('courier.latestBreakStart')}
                    hideLabel={true}
                    className="w-44 mt-[1.1rem]"
                    validate={(value) => formikValidationRequired(value, i18next.t('error.requiredLatestStartBreak'))}
                  />
                </div>
              </div>
            )}
            <FormikInput
              name={'duration'}
              label={i18next.t('courier.breakDuration')}
              validate={(value: string) => formikValidationRequired(value, i18next.t('error.requiredBreakDuration'))}
            />
            <ButtonBase variant={'secondary'} type="submit" className="mt-[1.1rem]">
              {i18next.t('courier.updateBreakTime')}
            </ButtonBase>
          </div>
          <div>
            <PlainButton onClick={() => setEditMode(false)}>
              <IconFa icon={['far', 'xmark']} size="lg" />
            </PlainButton>
          </div>
        </Form>
      )}
    </Formik>
  )
}
