export const consignmentStates: ConsignmentStateInterface = {
  CREATED: 0,
  UNCONFIRMED: 1,
  UNRESOLVED: 2,
  RESOLVED: 3,
  REJECTED: 4,
  OFFERED: 5,
  REFUSED: 6,
  ASSIGNED: 7,
  COLLECTED: 8,
  DEVIATED: 9,
  DELIVERED: 10,
  RETURNED: 11,
  DELETED: 12
}

interface ConsignmentStateInterface {
  [key: string]: number
}
