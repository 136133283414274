import { OrderType } from '@glow/entity-types'
import i18next from 'i18next'
import Immutable, { Collection, List, Map, Set } from 'immutable'
import { SEARCH_CONSIGNMENTS, SEARCH_DELETED_ORDERS } from '../actions/actionTypes'
import { consignmentStates } from '../domain/consignment'
import { MeasurementUnit, convert, sum } from '../domain/measurementUnits'
import { responded } from '../selectors/httpStatusSelectors'
import {
  filteredShipmentsByOrderSearchSelector,
  filteredShipmentsSelector,
  searchConsignmentByOrderSearchsSelector,
  searchConsignmentsSelector,
  searchDeletedConsignmentsSelector
} from '../selectors/shipmentSelectors'
import {
  BaseConsignmentEvent,
  Consignment,
  ConsignmentEvent,
  ConsignmentIdType,
  DepartmentIdType,
  OrderIdType,
  OrderNote,
  PreAdvice,
  SearchShipment,
  Shipment,
  ShipmentSyncedStatus,
  Unit,
  UnitIdType
} from '../types/coreEntitiesTypes'
import { ImmutableMap, isNotNullOrUndefined } from '../types/immutableTypes'
import { AppStateType } from './appStateReduxStore'
import {
  EventType,
  collectedConsignmentEvents,
  deliveredConsignmentEvents,
  getActualArrivalTimeFromSortedEvents,
  getActualPickupTimeFromSortedEvents
} from './consignmentEvent'
import {
  ConsignmentState,
  allowedUnassignableShipmentStatesForNonCourierDepartment,
  getHighestStateFromShipment,
  isAnyPackageDuplicable,
  isConsignmentDeletable,
  isConsignmentEditable,
  isConsignmentSemiEditable,
  isShipmentEditable,
  isShipmentReturnsUnassignableStatesForCourierDepartment,
  isShipmentSemiEditable,
  isShipmentUnassignableStatesForCourierDepartment
} from './consignmentState'
import { areAllPackagesInOrderReturned, isOrderCredited } from './customerUtils'
import { DepartmentType, isGroupId } from './departmentAndDepartmentGroupUtils'
import { isShipmentReturn } from './hdUtils'
import { GroupedServicesAndVasesTexts, getServiceText, getVasText, serviceCodesWithoutPickupSms } from './serviceUtils'

export type ConsignmentEventsMapType = Map<ConsignmentIdType, Collection<number, ConsignmentEvent>>
export type OrderNoteMapType = Map<OrderIdType, Collection<number, OrderNote>>
export type MappedConsignmentsType = Map<OrderIdType, Collection<ConsignmentIdType, Consignment>>
export type GroupedPreAdvicesByOrderId = Map<OrderIdType, Collection<ConsignmentIdType, PreAdvice>>

const orderSources = Set([
  'expressit',
  'glow',
  'glow.customerBooking',
  'glow.excelImportV2',
  'excel',
  'HappyFlow',
  '98466118503',
  '98466118504',
  'glow.recurring',
  'ShipmentAPI'
])

export interface FilteredShipmentsListReturnType {
  showLimitedShipments: boolean
  filteredShipments: Map<OrderIdType, Shipment>
  filteredShipmentEvents: Map<OrderIdType, List<ConsignmentEvent>>
}

export interface ShipmentsDataReturnType extends FilteredShipmentsListReturnType {
  shipment: Collection<ConsignmentIdType, Consignment> | undefined
}

export const getDepartmentOrGroupDetails = (departmentOrGroupDetails: string) => {
  const isGroup = isGroupId(departmentOrGroupDetails)
  const departmentOrGroupId = isGroup
    ? departmentOrGroupDetails.substr(departmentOrGroupDetails.indexOf('_') + 1)
    : departmentOrGroupDetails
  return {
    [isGroup ? 'departmentGroup' : 'departmentId']: Number(departmentOrGroupId)
  }
}

export const consignmentBelongsToDepartments = (
  consignment: Consignment,
  departmentIds: List<DepartmentIdType>
): boolean => departmentIds.some((departmentId) => departmentId === consignment.get('departmentId'))

export const filterConsignmentsByState = (consignments: List<Consignment>, filter: string | null): boolean => {
  const states: Set<ConsignmentState> = consignments.map((c) => c.get('state')).toSet()
  const isEditable = isShipmentEditable(states) || isShipmentSemiEditable(states)
  return (
    !filter ||
    (filter === 'EDITABLE' && isEditable) ||
    (filter === 'NOT_ASSIGNED' &&
      consignmentStates[consignments.getIn([0, 'state']) as string] < consignmentStates.ASSIGNED)
  )
}

export const groupConsignmentsToShipments = (
  consignments: List<Consignment>,
  consignmentEventsMap: ConsignmentEventsMapType,
  orderNoteMap: OrderNoteMapType,
  servicesAndVasesTexts: GroupedServicesAndVasesTexts,
  units: Map<UnitIdType, Unit> | undefined
): SearchShipment => {
  const firstConsignment = consignments.first<Consignment>()
  const isDeletedShipment = isShipmentDeleted(firstConsignment as Shipment)
  const isReturned = areAllPackagesInOrderReturned(consignments, consignmentEventsMap)
  const isCredited = isOrderCredited(consignments, consignmentEventsMap)
  const highestShipmentState = getHighestStateFromShipment(consignments.toSet())
  const shipmentState = isDeletedShipment ? ConsignmentState.DELETED : highestShipmentState

  const consignmentNote = orderNoteMap.get(firstConsignment.get('orderId'))?.first<OrderNote>()?.get('note')
  const events =
    consignmentEventsMap
      .get(firstConsignment.get('id'))
      ?.toList()
      ?.sortBy((e) => e.get('eventTime')) || List<ConsignmentEvent>()
  const deletedOrderDetailsPresent = (firstConsignment as Shipment).get('deletedOrderDetailsPresent')

  const { packagesArrivedAtDip, packagesCollected, packagesDelivered } = getShipmentSyncedStatusProps(
    consignments,
    consignmentEventsMap
  )
  const customerAlystraId = firstConsignment.get('custAlystraId')

  const totalVolumeValue = getTotalVolumeValueUsingConsignments(firstConsignment, consignments, MeasurementUnit.DMQ)
  const totalWeightValue = getTotalWeightValueUsingConsignments(firstConsignment, consignments, MeasurementUnit.KGM)
  const unit = units?.get(firstConsignment.get('courierId') ?? -1)

  return firstConsignment.toMap().merge({
    noOfPackages: getNoOfPackages(isDeletedShipment, firstConsignment, consignments),
    shipmentState,
    consignments,
    consignmentIds: consignments.map((x) => x.get('id')),
    showEditCheckbox: isConsignmentEditable(shipmentState) || isConsignmentSemiEditable(shipmentState),
    isEditable: true,
    isDeletable: isConsignmentDeletable(firstConsignment),
    isDuplicable:
      isAnyPackageDuplicable(consignments.toSet()) && firstConsignment.get('departmentType') === DepartmentType.Courier,
    deliveredTime: getActualArrivalTimeFromSortedEvents(events),
    actualPickupTime: getActualPickupTimeFromSortedEvents(events),
    isReturned,
    isCredited,
    note: consignmentNote,
    isDeletedShipmentCopyable: deletedOrderDetailsPresent,
    isDeletedShipmentRestorable: deletedOrderDetailsPresent && !(firstConsignment as Shipment).get('isMultileg'),
    state: isDeletedShipment ? ConsignmentState.DELETED : firstConsignment.get('state'),
    isDeletedShipment,
    packagesArrivedAtDip,
    driverName: unit?.get('name'),
    packagesCollected,
    packagesDelivered,
    serviceCodeText: getServiceText(servicesAndVasesTexts, firstConsignment.get('serviceCode'), customerAlystraId),
    vasCodesTexts:
      firstConsignment
        .get('vasCodes')
        ?.map((vasCode) => getVasText(servicesAndVasesTexts, vasCode, customerAlystraId))
        ?.sort()
        ?.join(', ') || '',
    totalVolumeValue,
    totalVolumeUnit: MeasurementUnit.DMQ,
    totalWeightValue,
    totalWeightUnit: MeasurementUnit.KGM
  })
}

export const getNoOfPackages = (
  isDeletedShipment: boolean,
  firstConsignment: Consignment,
  consignments: Immutable.List<Consignment>
) => (isDeletedShipment ? firstConsignment.get('quantity') : consignments.count())

export const isShipmentDeleted = (shipment: Shipment): boolean => isNotNullOrUndefined(shipment.get('deletedBy'))

export const getOrderSourceText = (source: string = '') => {
  const orderSourceTranslationKey = orderSources.some((orderSource) => source.startsWith(orderSource))
    ? source.startsWith('glow.recurring')
      ? 'glow_recurring'
      : source.replaceAll('.', '_')
    : 'others'
  return i18next.t(`consignment.orderSources.${orderSourceTranslationKey}`)
}

export const getCustomerDepartmentOrGroupDetails = (customerId: string, departmentOrGroupId: string) =>
  isNotNullOrUndefined(customerId)
    ? { customerId: Number(customerId) }
    : getDepartmentOrGroupDetails(departmentOrGroupId)

export interface SelectedShipmentsAllowedActionsReturnType {
  selectedShipmentsDeletable: boolean
  selectedShipmentsReturnable: boolean
  selectedShipmentsUnassignable: boolean
  selectedShipmentsCanBeCollected: boolean
  canSendPrePickupSmsForSelectedShipments: boolean
  canSendManualDelaySmsForSelectedShipments: boolean
  canGenerateRouteReceiptListForSelectedShipments: boolean
  selectedShipmentsFromHappyFlow: boolean
  selectedShipmentsEditable: boolean
  selectedShipmentsCopyable: boolean
  selectedShipmentsDuplicable: boolean
}

export const groupShipmentsByOrderId = (shipment: Shipment) => shipment.get('orderId').toString()

export const getShipmentsByOrderSearchs = (state: AppStateType) => {
  const mappedConsignments = searchConsignmentByOrderSearchsSelector(state)

  return filteredShipmentsByOrderSearchSelector(state, mappedConsignments, null)
}

export const getFilteredShipmentsByOrderSearchsSorted = (state: AppStateType) => {
  const shipmentsByOrderSearchs = getShipmentsByOrderSearchs(state)
  const orderSearchs = state.getIn(['searchConsignments', 'orderSearchs']) as Map<
    string,
    ImmutableMap<{ orderId: number; rowNumber: number }>
  >
  const sortedOrderSearchs = orderSearchs.valueSeq().sortBy((value) => value.get('rowNumber'))

  return sortedOrderSearchs
    .map((orderSearch) => shipmentsByOrderSearchs.filteredShipments.get(orderSearch.get('orderId')))
    .filter((x): x is Shipment => x !== undefined)
    .toList()
}

export const getShipmentsData = (
  state: AppStateType,
  orderId: number,
  filter: string | null,
  showDeleted: boolean,
  departmentIds: List<DepartmentIdType>
): ShipmentsDataReturnType => {
  const mappedConsignments = showDeleted
    ? searchDeletedConsignmentsSelector(state)
    : searchConsignmentsSelector(state, departmentIds)
  const shipment = mappedConsignments.get(orderId)
  const filteredShipmentsData = filteredShipmentsSelector(state, mappedConsignments, filter)
  return {
    shipment,
    ...filteredShipmentsData
  }
}

export const isLoadingShipmentSearch = (state: AppStateType, showDeleted: boolean): boolean => {
  let isLoadingSearch
  if (showDeleted) {
    isLoadingSearch = !responded(state, SEARCH_DELETED_ORDERS)
  } else {
    isLoadingSearch = !responded(state, SEARCH_CONSIGNMENTS)
  }
  return isLoadingSearch
}

export const getTotalWeightValue = (
  consignment: Consignment,
  consignmentsPerOrderId: Collection<string, Consignment>,
  toUnit: MeasurementUnit
): number =>
  consignment.get('totalWeightValue')
    ? convert(consignment.get('totalWeightUnit'), toUnit, consignment.get('totalWeightValue')) || 0
    : sum(consignmentsPerOrderId.map((cons) => convert(cons.get('weightUnit'), toUnit, cons.get('weightValue')) || 0))

export const getTotalWeightValueUsingConsignments = (
  consignment: Consignment,
  consignments: List<Consignment>,
  toUnit: MeasurementUnit
): number =>
  consignment.get('totalWeightValue')
    ? convert(consignment.get('totalWeightUnit'), toUnit, consignment.get('totalWeightValue')) || 0
    : sum(consignments.map((cons) => convert(cons.get('weightUnit'), toUnit, cons.get('weightValue')) || 0))

export const getTotalVolumeValueUsingConsignments = (
  consignment: Consignment,
  consignments: List<Consignment>,
  toUnit: MeasurementUnit
): number =>
  consignment.get('totalVolumeValue')
    ? convert(consignment.get('totalVolumeUnit'), toUnit, consignment.get('totalVolumeValue')) || 0
    : sum(consignments.map((cons) => convert(cons.get('volumeUnit'), toUnit, cons.get('volumeValue')) || 0))

export const getTotalVolumeValue = (
  consignment: Consignment,
  consignmentsPerOrderId: Collection<string, Consignment>,
  toUnit: MeasurementUnit
): number =>
  consignment.get('totalVolumeValue')
    ? convert(consignment.get('totalVolumeUnit'), toUnit, consignment.get('totalVolumeValue')) || 0
    : sum(consignmentsPerOrderId.map((cons) => convert(cons.get('volumeUnit'), toUnit, cons.get('volumeValue')) || 0))

export const getUniqueConsignmentsPerOrderId = (
  consignmentsDataForOrderId: Collection<ConsignmentIdType, Consignment>
): Collection<string, Consignment> =>
  consignmentsDataForOrderId.groupBy((c) => c.get('packageId')).map((v) => v.first(Map()) as Consignment)

export const isHappyFlowShipment = (shipment: Shipment): boolean => shipment.get('source') === 'HappyFlow'

export interface ShipmentSyncedStatusProps {
  packagesArrivedAtDip: ShipmentSyncedStatus | null
  packagesCollected: ShipmentSyncedStatus | null
  packagesDelivered: ShipmentSyncedStatus | null
}

export function getShipmentSyncedStatusProps(
  consignments: List<Consignment>,
  consignmentEventsMap?: ConsignmentEventsMapType
): ShipmentSyncedStatusProps {
  const totalPackagesInOrder = consignments.count()

  const arrivedAtDip = consignments.count((it) => it.get('arrivedAtDip') === true)
  const packagesArrivedAtDip =
    consignments.first<Consignment>().get('source') === 'HappyFlow' ||
    consignments.first<Consignment>().get('source') === 'BaggageSolutions'
      ? getShipmentSyncedStatus(arrivedAtDip, totalPackagesInOrder)
      : null

  let packagesCollected: ShipmentSyncedStatus | null = null,
    packagesDelivered: ShipmentSyncedStatus | null = null

  if (consignmentEventsMap) {
    packagesCollected = getShipmentSyncedStatus(
      matchingEligibleEventsCount(consignments, consignmentEventsMap, collectedConsignmentEvents),
      totalPackagesInOrder
    )
    packagesDelivered = getShipmentSyncedStatus(
      matchingEligibleEventsCount(consignments, consignmentEventsMap, deliveredConsignmentEvents),
      totalPackagesInOrder
    )
  }

  return {
    packagesArrivedAtDip,
    packagesCollected,
    packagesDelivered
  }
}

export const matchingEligibleEventsCount = (
  consignments: List<Consignment>,
  consignmentEventsMap: ConsignmentEventsMapType,
  eligibleEvents: Set<EventType>
): number =>
  consignments.count(
    (c) =>
      consignmentEventsMap.get(c.get('id'))?.some((ce: ConsignmentEvent) => eligibleEvents.contains(ce.get('type'))) ||
      false
  )

export function getShipmentSyncedStatus(packagesInSync: number, totalPackagesInShipment: number): ShipmentSyncedStatus {
  if (packagesInSync === null || packagesInSync === 0) return 'no'
  if (packagesInSync === totalPackagesInShipment) return 'yes'
  return 'partial'
}

const isShipmentReturned = (shipment: Shipment): boolean => shipment && shipment.get('isReturned', false)

export const isShipmentsUnassignable = (shipments: Collection.Indexed<Shipment>) =>
  shipments?.every(
    (shipment: Shipment) =>
      isShipmentUnassignableAndBelongsToCourierDepartment(shipment) ||
      isShipmentUnassignableAndBelongsToNonCourierDepartment(shipment)
  )

export const isShipmentUnassignableAndBelongsToCourierDepartment = (shipment: Shipment): boolean =>
  shipment.get('slotId') != null &&
  shipment.get('departmentType') === DepartmentType.Courier &&
  ((!(isShipmentReturned(shipment) && isShipmentReturn(shipment)) &&
    isShipmentUnassignableStatesForCourierDepartment.contains(shipment.get('state'))) ||
    ((isShipmentReturned(shipment) || isShipmentReturn(shipment)) &&
      isShipmentReturnsUnassignableStatesForCourierDepartment.contains(shipment.get('state'))))

export const isShipmentUnassignableAndBelongsToNonCourierDepartment = (shipment: Shipment): boolean =>
  shipment.get('slotId') != null &&
  shipment.get('departmentType') !== DepartmentType.Courier &&
  allowedUnassignableShipmentStatesForNonCourierDepartment.contains(shipment.get('state'))

export const getShipmentConsignmentEvents = <T extends BaseConsignmentEvent = ConsignmentEvent>(
  shipment: Shipment,
  events: Map<ConsignmentIdType, List<T>>
) =>
  shipment?.get('consignments')?.map((consignment) => {
    const eventsByConsignmentId = events && events.get(consignment.get('id'))
    return eventsByConsignmentId?.map((e) => e.setIn(['packageId'], consignment.get('packageId'))) || List<T>()
  })

export const getTotalShipmentWeight = (shipment: Shipment) =>
  shipment
    .get('consignments')
    .map((cons) => cons.get('weightValue'))
    .reduce((a, b) => a + b, 0)

export const eligibleForPrePickupSms = (shipment: Shipment) =>
  shipment.get('state') === ConsignmentState.ASSIGNED &&
  !serviceCodesWithoutPickupSms.includes(shipment.get('serviceCode'))

export function filterConsignmentEvents<T extends BaseConsignmentEvent>(
  consignmentEvents: List<T>,
  orderType: OrderType | null
): List<T> {
  return consignmentEvents.filterNot(
    (event) => orderType === 'Apollo' && event.get('type') === EventType.PLANNED_DELIVERY_DATE_ORDERED
  )
}
