import { eventDataValue } from '@glow/entity-types'
import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import i18next from 'i18next'
import { formatEventsDataOrBlank } from './event-updated'

export function EventDeliveryTimeOrdered(props: { data: AnyData }) {
  const deliveryDate = eventDataValue(props.data as Record<string, unknown> | null, ['deliveryDate'])

  if (deliveryDate) {
    return <p>{deliveryDate}</p>
  }

  const deliveryTimeLatestFrom = eventDataValue(props.data as Record<string, unknown> | null, [
    'deliveryTimeLatest',
    'from'
  ])
  const deliveryTimeLatestTo = eventDataValue(props.data as Record<string, unknown> | null, [
    'deliveryTimeLatest',
    'to'
  ])
  const deliveryTimeEarliestFrom = eventDataValue(props.data as Record<string, unknown> | null, [
    'deliveryTimeEarliest',
    'from'
  ])
  const deliveryTimeEarliestTo = eventDataValue(props.data as Record<string, unknown> | null, [
    'deliveryTimeEarliest',
    'to'
  ])

  return (
    <div>
      <p className="mb-1">
        <span className="text-emphasis-medium">{i18next.t('consignment.earliestDeliveryTime')}: </span>
        <span className="text-emphasis-high">
          {formatEventsDataOrBlank(deliveryTimeEarliestFrom)}
          {' → '}
          {formatEventsDataOrBlank(deliveryTimeEarliestTo)}
        </span>
      </p>
      <p>
        <span className="text-emphasis-medium">{i18next.t('consignment.latestDeliveryTime')}: </span>
        <span className="text-emphasis-high">
          {formatEventsDataOrBlank(deliveryTimeLatestFrom)}
          {' → '}
          {formatEventsDataOrBlank(deliveryTimeLatestTo)}
        </span>
      </p>
    </div>
  )
}
