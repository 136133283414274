import styled from 'styled-components'

export const Group = styled.div`
  display: flex;
  column-gap: 5em;
  row-gap: 2em;
  flex-wrap: wrap;
  padding-top: 1.5em;
`
export const CardContainer = styled.div`
  padding: 2em;
  padding-top: 1em;
`

export const LabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-emphasis-high);
`

export const Label = styled.span`
  color: var(--color-emphasis-medium);
  padding-bottom: 0.5em;
`

export const TimeLabel = styled.div`
  display: flex;
  gap: 0.5em;
  color: var(--color-emphasis-high);
`

export const ContactLabel = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 0.5em;
  color: var(--color-emphasis-high);
  padding-bottom: 1em;
  align-items: center;
  display: flex;
`

export const H5 = styled.h5`
  white-space: nowrap;
  color: var(--color-emphasis-high);
`

export const H4 = styled.h4`
  white-space: nowrap;
  color: var(--color-emphasis-high);
`
