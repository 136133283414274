import React from 'react'
import styled from 'styled-components'
import variables from '../../../styles/variables'
import i18next from 'i18next'
import { Field } from 'formik'
import { TextAreaInput } from '../../../primitives/Forms'

export const InternalNote: React.FC<{ note?: string; editing: boolean }> = ({ note, editing }) =>
  editing ? (
    <Box>
      <Heading>{i18next.t('shipment.internalNote')}</Heading>
      <Field as={TextAreaInput} name="orderNote" rows={2} />
    </Box>
  ) : !note ? null : (
    <Box>
      <Heading>{i18next.t('shipment.internalNote')}</Heading>
      <Note>{note}</Note>
    </Box>
  )

const Box = styled.div`
  background: ${variables.colors.event};
  padding: 1.25rem;
  border-radius: 8px;
`

const Heading = styled.h2`
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0 0 1.25rem 0;
  color: ${variables.newColors.black};
`

const Note = styled.p`
  color: ${variables.newColors.black};
  font-size: 1rem;
  margin: 0;
`
