import styled from 'styled-components'

import variables from '../../styles/variables'

export default styled.section`
  position: relative;
  background-color: ${variables.colors.white};
  border: 1px solid;
  border-color: ${variables.colors.gray5};
  border-radius: 3px;
  padding: 16px;
  height: 100%;
`
