import { FormikRadioSelect, formikValidationRequired } from '@glow/formik-components'
import { ButtonBase, FeedbackBox, IconFa, ModalConfirm, Option } from '@glow/ui-components'
import { Form, Formik } from 'formik'
import i18next from 'i18next'
import { List, Map, Set } from 'immutable'
import { DateTime } from 'luxon'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getLatestConsignmentEventActions } from '../../../actions/creators/consignmentEventHelpers'
import {
  getSlotById,
  getSlots,
  plainAddConsignmentsToSlotAndReoptimize,
  plainChangeSlotForConsignments,
  plainUnassignConsignmentsFromSlot
} from '../../../actions/creators/helpers'
import { ToastContext } from '../../../contexts/ToastContext'
import { getFirstErrorMessage } from '../../../http/httpHelper'
import {
  IOptimizeJobResult,
  optimizeJobsResultsByDepartmentIdSelector
} from '../../../selectors/optimizeJobResultsSelector'
import { slotsByDateAndDepartmentIdSelector } from '../../../selectors/slotSelectors'
import { Consignment, Slot } from '../../../types/coreEntitiesTypes'
import { AppStateType } from '../../../utils/appStateReduxStore'
import { isEmpty, isNotEmpty } from '../../../utils/collectionUtils'
import { isSameDayLuxon } from '../../../utils/dateTime'
import { isSlotEditable, isSlotStarted } from '../../../utils/slotStates'
import { useAppDispatch } from '../../../reducers/redux-hooks'

enum ViewState {
  HasSlotCanChangeSlot,
  HasSlotCanRemoveSlot,
  NoSlotOptimisationInProgress,
  NoSlotCanAddSlot,
  NotIneligible
}

export const ChangeSlotForm = (props: { consignments: List<Consignment>; departmentId: number }) => {
  const [showConfirm, setShowConfirm] = useState(false)
  const dispatch = useAppDispatch()
  const { setToast } = useContext(ToastContext)
  const slotId = props.consignments.first()?.get('slotId')
  const pickupTimeEarliestCached = props.consignments.get(0)?.get('pickupTimeEarliestCached')

  const slot: Slot = useSelector((state: AppStateType) => {
    return slotId ? (state.getIn(['entities', 'slots', slotId], Map()) as Slot) : Map()
  })

  useEffect(() => {
    if (slotId) {
      dispatch(getLatestConsignmentEventActions(Set([slotId])))
    }
    if (slotId && isEmpty(slot)) {
      dispatch(getSlotById(slotId, true))
    }
  }, [slotId, slot])

  useEffect(() => {
    if (
      pickupTimeEarliestCached &&
      (isSameDayLuxon(pickupTimeEarliestCached, DateTime.local()) ||
        isSameDayLuxon(pickupTimeEarliestCached, DateTime.local().plus({ days: 1 })))
    ) {
      dispatch(
        getSlots(
          props.departmentId,
          pickupTimeEarliestCached.toISODate(),
          pickupTimeEarliestCached.plus({ days: 1 }).toISODate(),
          true,
          true
        )
      )
    }
  }, [props.departmentId, props.consignments])

  const optimizeJobResults = useSelector((state: AppStateType) => {
    return optimizeJobsResultsByDepartmentIdSelector(state, props.departmentId)
  })

  const availableSlots: List<Slot> = useSelector((state: AppStateType) => {
    return pickupTimeEarliestCached
      ? slotsByDateAndDepartmentIdSelector(state, props.departmentId, pickupTimeEarliestCached)
      : List()
  })

  const viewState = useMemo(() => {
    const slotState = slot.get('state')

    if (isNotEmpty(slot) && isSlotEditable(slotState)) {
      return ViewState.HasSlotCanRemoveSlot
    } else if (
      isNotEmpty(slot) &&
      isSlotStarted(slotState) &&
      availableSlots.some((s) => s.get('id') !== slot.get('id'))
    ) {
      return ViewState.HasSlotCanChangeSlot
    } else if (optimizeJobResults.last() && !optimizeJobResults.last(Map() as IOptimizeJobResult).get('finished')) {
      return ViewState.NoSlotOptimisationInProgress
    } else if (availableSlots.some((s) => isSlotEditable(s.get('state')))) {
      return ViewState.NoSlotCanAddSlot
    } else {
      return ViewState.NotIneligible
    }
  }, [slot, optimizeJobResults, availableSlots])

  const slotOptions: Option[] = useMemo(() => {
    if (viewState === ViewState.HasSlotCanChangeSlot) {
      return availableSlots
        .filter((s) => s.get('id') !== slot.get('id'))
        .map((s) => ({ code: s.get('id'), label: s.get('name') }))
        .toArray()
    } else if (viewState === ViewState.NoSlotCanAddSlot) {
      return availableSlots
        .filter((s) => isSlotEditable(s.get('state')))
        .map((s) => ({ code: s.get('id'), label: s.get('name') }))
        .toArray()
    }
    return []
  }, [viewState, availableSlots])

  const onConfirm = async ({ slotId }: { slotId: number }) => {
    const consignmentIds = Set(props.consignments.map((consignment) => consignment.get('id')))
    try {
      if (viewState === ViewState.HasSlotCanChangeSlot) {
        await plainChangeSlotForConsignments(slotId + '', slot.get('id'), consignmentIds)(dispatch)
        setToast({ variant: 'success-dark', text: i18next.t('shipmentDetails.successChangeSlot') })
      } else if (viewState === ViewState.NoSlotCanAddSlot) {
        await plainAddConsignmentsToSlotAndReoptimize(slotId + '', consignmentIds)(dispatch)
        setToast({ variant: 'success-dark', text: i18next.t('shipmentDetails.successAddToSlot') })
      } else if (viewState === ViewState.HasSlotCanRemoveSlot) {
        await plainUnassignConsignmentsFromSlot(slot.get('id'), consignmentIds)(dispatch)
        setToast({ variant: 'success-dark', text: i18next.t('shipmentDetails.successRemoveFromSlot') })
      } else {
        console.error('Should not be in this function with this state!!!!')
      }
      setShowConfirm(false)
    } catch (error) {
      setShowConfirm(false)

      const errorObject = getFirstErrorMessage(error)
      setToast({
        variant: 'error-dark',
        text: errorObject ? errorObject.description : i18next.t('application.anErrorOccurred')
      })
    }
    setShowConfirm(false)
  }

  const openConfirm = () => {
    setShowConfirm(true)
  }

  const confirmModalText = useCallback(
    (slotId: number) => {
      const slotName = slotOptions.find((slot) => slot.code === slotId)?.label

      if (viewState === ViewState.HasSlotCanChangeSlot) {
        return i18next.t('shipmentDetails.changeSlotConfirm', { slot: slotName ?? 'slot' })
      }
      if (viewState === ViewState.NoSlotCanAddSlot) {
        return i18next.t('shipmentDetails.addShipmentToSlot', { slot: slotName ?? 'slot' })
      }
      return ''
    },
    [viewState]
  )

  if (viewState === ViewState.NoSlotOptimisationInProgress) {
    return (
      <div style={{ paddingBottom: '2.5rem' }}>
        <h5 className="h5">{i18next.t('shipmentDetails.changeSlot')}</h5>
        <FeedbackBox
          variant="warning-light"
          icon={<IconFa icon={['fas', 'circle-info']} />}
          text={i18next.t('shipmentDetails.optimisationInProgress')}
        />
      </div>
    )
  }

  if (viewState === ViewState.HasSlotCanRemoveSlot) {
    return (
      <div style={{ paddingBottom: '2.5rem' }} data-testid="test">
        <h5 style={{ marginBottom: '1rem' }} className="h5">
          {i18next.t('shipmentDetails.removeFromSlot')}
        </h5>
        <ButtonBase variant="secondary" onClick={openConfirm}>
          {i18next.t('shipmentDetails.removeShipmentFromSlot')}
        </ButtonBase>
        <ModalConfirm
          open={showConfirm}
          questionText={i18next.t('shipmentDetails.removeShipmentFromSlotHeading')}
          confirm={i18next.t('shipmentDetails.removeFromSlot')}
          close={i18next.t('button.close')}
          cancel={i18next.t('button.cancel')}
          onClose={() => setShowConfirm(false)}
          onConfirm={() => onConfirm({ slotId: slot.get('id') })}
        />
      </div>
    )
  }

  if (viewState === ViewState.HasSlotCanChangeSlot || viewState == ViewState.NoSlotCanAddSlot)
    return (
      <>
        <h5 className="h5">
          {viewState === ViewState.HasSlotCanChangeSlot
            ? i18next.t('shipmentDetails.changeSlot')
            : i18next.t('shipmentDetails.AddToSlot')}
        </h5>
        <div>
          {viewState === ViewState.HasSlotCanChangeSlot
            ? i18next.t('shipmentDetails.changeSlotDescription')
            : i18next.t('shipmentDetails.addToSlotDescription')}
        </div>
        <Formik onSubmit={openConfirm} initialValues={{ slotId: 0 }}>
          {({ values }) => (
            <Form
              style={{
                display: 'flex',
                paddingTop: '1rem',
                paddingBottom: '2.5rem',
                gap: '1rem',
                alignItems: 'flex-end'
              }}
            >
              <FormikRadioSelect
                name="slotId"
                options={slotOptions}
                validate={(value: string) => formikValidationRequired(value, i18next.t('shipmentDetails.required'))}
                label={i18next.t('shipmentDetails.selectSlot')}
                aria-label={i18next.t('shipmentDetails.selectSlot')}
                placeholder={i18next.t('shipmentDetails.selectSlot')}
                selectedLabel={slotOptions.find((slot) => slot.code === values.slotId)?.label}
              />
              <ButtonBase variant="primary" disabled={!Boolean(values.slotId)}>
                {viewState === ViewState.HasSlotCanChangeSlot
                  ? i18next.t('shipmentDetails.changeSlot')
                  : i18next.t('shipmentDetails.AddToSlot')}
              </ButtonBase>
              <ModalConfirm
                open={showConfirm}
                questionText={confirmModalText(values.slotId)}
                confirm={
                  viewState === ViewState.HasSlotCanChangeSlot
                    ? i18next.t('shipmentDetails.changeSlot')
                    : i18next.t('shipmentDetails.AddToSlot')
                }
                close={i18next.t('button.close')}
                cancel={i18next.t('button.cancel')}
                onClose={() => setShowConfirm(false)}
                onConfirm={() => onConfirm(values)}
              />
            </Form>
          )}
        </Formik>
      </>
    )

  return null
}
