import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import variables from '../../../styles/variables'
import { ContactInfoGrid, ContactInfoText } from '../shared/Styles'
import { Sender } from '../shared/Shipment'
import { FlexRow } from '../../../primitives/Flex'

export const SenderComponent: React.FC<{ sender: Sender }> = ({ sender }) =>
  !sender ? null : (
    <ContactInfoGrid>
      <FontAwesomeIcon icon="arrow-from-bottom" color={variables.newColors.stellarGrey} />
      <ContactInfoText>{sender.name}</ContactInfoText>
      <FontAwesomeIcon icon="map-marker-alt" color={variables.newColors.stellarGrey} />
      <ContactInfoText>
        {sender.address.streetName}, {sender.address.postalCode} {sender.address.area}
      </ContactInfoText>
      <FontAwesomeIcon icon="phone" color={variables.newColors.stellarGrey} />
      <ContactInfoText>
        <FlexRow gap={10}>
          <span>{sender.phone}</span>
          {sender.secondPhone && (
            <>
              <span>|</span>
              <span>{sender.secondPhone}</span>
            </>
          )}
        </FlexRow>
      </ContactInfoText>
      {sender.email && (
        <>
          <FontAwesomeIcon icon="envelope" color={variables.newColors.stellarGrey} />
          <ContactInfoText>{sender.email}</ContactInfoText>
        </>
      )}
    </ContactInfoGrid>
  )
