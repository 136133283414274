import styled from 'styled-components'
import vars from '../../styles/variables'

export const GridCustomerLiveContent = styled.div`
  display: grid;
  grid-gap: 2px;
  height: 100%;
  grid-template-areas: 'leftContent';

  @media (min-width: 700px) {
    grid-template-columns: 500px 1fr;
    grid-auto-rows: auto;
    grid-template-areas: 'leftContent consignment';
  }

  @media (min-width: 1000px) {
    grid-template-columns: 500px 0fr 7fr;
    grid-auto-rows: auto;
    grid-template-areas: 'leftContent consignment map';
  }
`

export const GridMap = styled.div`
  grid-area: map;

  @media (max-width: 1000px) {
    display: none;
  }
`

export const GridLeftContent = styled.div`
  height: calc(100vh - 4rem);
  grid-area: leftContent;
  padding: 10px;
  z-index: 1;
  box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
`

export const GridConsignment = styled.div`
  grid-area: consignment;
  box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 44;

  @media (max-width: 699px) {
    position: fixed;
    background: ${vars.colors.white};
    min-width: 200px;
    max-width: 699px;
    left: 0;
    right: 0;
    top: 55vh;
    transition: transform 200ms;
    transform: translateY(-50%);
    z-index: 9999;
    max-height: 99vh;
    overflow: auto;
  }
`

export const GridDepartments = styled.div`
  display: grid;
  grid-gap: 5px;
  padding: 5px 0 0 0;
  grid-area: departments;
`

export const GridSlot = styled.div`
  display: grid;
  grid-gap: 5px;
  min-height: 80px;
  max-height: 100px;
  border-radius: ${vars.borderRadius.all.m};
  box-shadow: ${vars.boxShadow.customerMedium};
  transition: transform 100ms;
  outline: 0px solid ${vars.newColors.primary};
  grid-template-columns: 10px 2fr 2fr 1fr 1fr 1fr 1fr 10px;
  grid-template-rows: 3fr 1fr;
  grid-template-areas:
    'slotColour slotName courierName status delay visible following empty'
    'slotStatus slotStatus slotStatus slotStatus slotStatus slotStatus slotStatus slotStatus';
  background: ${vars.colors.white};
  cursor: pointer;
`

export const GridDatePicker = styled.div`
  background: ${vars.colors.white};
  transition: transform 100ms;
  outline: 0px solid ${vars.newColors.primary};
  border-radius: ${vars.borderRadius.all.m};
`

export const GridStatus = styled.div<{ paddingTop?: boolean }>`
  grid-area: slotStatus;
  border-bottom: 1px solid ${vars.colors.grayDark};
  border-radius: ${vars.borderRadius.bottom.m};
  padding: ${(props) => (props.paddingTop ? vars.padding.top.m : '')};
`

export const SmallHeader = styled.p<{ inline?: boolean; left?: boolean; color?: string }>`
  font-size: 0.6rem;
  font-weight: 600;
  display: ${(props) => props.inline && 'inline'};
  margin: auto;
  text-align: ${(props) => (props.left ? 'left' : 'center')};
  color: ${(props) => (props.color ? props.color : vars.colors.gray9)};
`

export const GridHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr 1fr;
  grid-template-areas: 'status address estimated actual';
  background-color: ${vars.colors.white};
  border-radius: ${vars.borderRadius.all.m};
  min-height: 35px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: ${vars.boxShadow.customerMedium};
`

export const GridHeaderColumn = styled.div<{ area?: string }>`
  grid-area: ${(props) => (props.area ? props.area : '')};
  margin: auto 0;
  font-weight: 600;
`

export const GridRows = styled.div`
  overflow-y: auto;
  min-height: 1px;
  max-height: calc(75vh - 50px);
`

export const Row = styled.div`
  margin-bottom: ${vars.padding.all.m};
`

export const GridRow = styled.div<{ selected?: boolean; otherSelected?: boolean }>`
  display: grid;
  margin: 3px 2px 3px 2px;
  border-radius: ${vars.borderRadius.all.m};
  grid-template-columns: 1fr 4fr 1fr 1fr;
  background-color: ${(props) =>
    props.selected ? vars.colors.white : props.otherSelected ? vars.colors.gray : vars.colors.white};
  min-height: 50px;
  box-shadow: ${vars.boxShadow.customerMedium};
  outline: 0px solid ${vars.colors.white};
  transition: transform 100ms;
  cursor: pointer;
  grid-template-areas: 'status address estimated actual';

  &:hover,
  &:focus {
    background-color: ${(props) =>
      props.selected ? vars.colors.white : props.otherSelected ? vars.colors.white : vars.colors.gray};
  }
`

export const GridColumn = styled.div<{
  area?: string
  background?: string
  color?: string
  marginRight?: boolean
  borderRadius?: string
  center?: boolean
}>`
  grid-area: ${(props) => (props.area ? props.area : '')};
  background: ${(props) => (props.background ? props.background : '')};
  color: ${(props) => (props.color ? props.color : '')};
  margin: ${(props) => (props.marginRight ? vars.margin.right.m : props.center ? 'auto' : 0)};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : vars.borderRadius.left.m)};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  align-items: center;
  padding: 5px;
`

export const GridColumnMiddle = styled.div<{
  area?: string
  background?: string
  color?: string
  marginRight?: boolean
  borderRadius?: string
  center?: boolean
}>`
  grid-area: ${(props) => (props.area ? props.area : '')};
  background: ${(props) => (props.background ? props.background : '')};
  color: ${(props) => (props.color ? props.color : '')};
  margin: ${(props) => (props.marginRight ? vars.margin.right.m : props.center ? 'auto' : 0)};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : vars.borderRadius.left.m)};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`

export const GridBox = styled.div`
  border-radius: ${vars.borderRadius.all.m};
  box-shadow: ${vars.boxShadow.customerMedium};
  transition: transform 100ms;
  outline: 0px solid ${vars.newColors.primary};
  background: ${vars.colors.white};
`
