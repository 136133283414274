import i18next from 'i18next'
import { List, Map } from 'immutable'
import toLower from 'lodash/toLower'
import { CSSProperties, ReactElement } from 'react'
import { GroupRecipientProps } from '../../pages/instant/MultistopGroupForm'
import { OrderInvoicingExecutionSummaryProps } from '../../pages/instant/orderInvoicingTypes'
import {
  RecurringOrderExceptionProps,
  RecurringOrderExecutionSummaryProps,
  RecurringOrderProps,
  RecurringOrderShipmentProps
} from '../../pages/instant/recurringOrderTypes'
import { IRoutePointWithExtraDataProps } from '../../query/instantCourierQuery'
import vars from '../../styles/variables'
import {
  AdminCustomerDeviationProps,
  AdminCustomerEmailProps,
  ConsignmentEvent,
  Entity,
  OrderIdType,
  ShipmentProps
} from '../../types/coreEntitiesTypes'
import {
  adminCustomerEmailRenderer,
  airexpressFrequencyRenderer,
  alreadyAssignedRenderer,
  amountRenderer,
  booleanCheckRenderer,
  checkboxRenderer,
  customerRenderer,
  dateRenderer,
  dateTimeRenderer,
  dateTimeValueRenderer,
  daysRenderer,
  daysValueRenderer,
  deliveryRenderer,
  deviationTextRenderer,
  deviationTypeRenderer,
  durationRenderer,
  durationValueRenderer,
  endDateRenderer,
  errorRenderer,
  exceptionsRenderer,
  expiredRenderer,
  extraInfoRenderer,
  frequencyRenderer,
  GridGroupRendererParams,
  GridRendererParams,
  GridTitleRenderParams,
  groupExtraInfoRenderer,
  groupNameRenderer,
  groupRenderer,
  hide,
  holidaysRenderer,
  mapLinkRenderer,
  multilegRenderer,
  numberOfOrders,
  numberOfOrdersValue,
  pickupRenderer,
  priceRenderer,
  priceValueRenderer,
  routePointTypeRenderer,
  selectAllTitleRenderer,
  serviceLevelRenderer,
  shipmentSyncStatusRenderer,
  sourceRenderer,
  statusRenderer,
  statusValueRenderer,
  subCustomerRenderer,
  subCustomerValueRenderer,
  suggestedDriverWithPackagesRenderer,
  text,
  timeDiffRenderer,
  timeRenderer,
  timeRenderIfNotManual,
  timeValueRenderer,
  timeWindowRenderer,
  unitRenderer,
  vehicleTypeRenderer,
  yesNoRenderer,
  yesNoValueRenderer
} from './GridRenderers'
import { AddressProps } from '../../pages/instant/bookingOrderTypes'
import { MeasurementUnit } from '../../domain/measurementUnits'
import {
  RecurringAirExpressOrderTemplateProps,
  RecurringAirExpressSchedule
} from '../../domain/airexpress/airExpressRecurringOrderTypes'
import { SuggestedUnitsProps } from '../../pages/instant/SuggestedUnits'
import { isBring } from '@glow/common'

export interface ColumnDefinition<T> {
  name: string
  legend?: string
  dataIndex: keyof T
  hidden?: boolean
  createKeyFrom?: boolean
  width?: string
  minWidth?: string
  maxWidth?: string
  title?: boolean
  titleRenderer?: (params: GridTitleRenderParams) => ReactElement
  renderer?: (params: GridRendererParams) => ReactElement
  groupRenderer?: (params: GridGroupRendererParams) => ReactElement
  valueRenderer?: (params: GridRendererParams) => string
  summaryRowPicker?: (params: GridGroupRendererParams) => Entity<T> | null
  style?: CSSProperties
  rightAlignSortingArrow?: boolean
  disableFilter?: boolean
}

export const orderColumns: List<ColumnDefinition<ShipmentProps>> = List.of(
  {
    name: i18next.t('grid.columns.id'),
    dataIndex: 'id',
    hidden: true,
    createKeyFrom: true
  },
  {
    name: i18next.t('grid.columns.puearly'),
    dataIndex: 'pickupTimeEarliest',
    renderer: timeRenderer,
    valueRenderer: timeValueRenderer
  },
  {
    name: i18next.t('grid.columns.pulate'),
    dataIndex: 'pickupTimeLatest',
    renderer: timeRenderer,
    valueRenderer: timeValueRenderer
  },
  {
    name: i18next.t('grid.columns.delearly'),
    dataIndex: 'deliveryTimeEarliest',
    renderer: timeRenderer,
    valueRenderer: timeValueRenderer
  },
  {
    name: i18next.t('grid.columns.dellate'),
    dataIndex: 'deliveryTimeLatest',
    renderer: timeRenderer,
    valueRenderer: timeValueRenderer
  },
  {
    name: i18next.t('grid.columns.service'),
    dataIndex: 'serviceCodeText',
    title: true,
    width: '100px'
  },
  {
    name: i18next.t('grid.columns.customerNumber'),
    dataIndex: 'custAlystraSubcustomer',
    renderer: subCustomerRenderer('100px'),
    valueRenderer: subCustomerValueRenderer,
    width: '100px',
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.pickup'),
    dataIndex: 'pickupName',
    width: '100px',
    title: true
  },
  {
    name: i18next.t('grid.columns.address'),
    dataIndex: 'pickupAddress',
    title: true
  },
  {
    name: i18next.t('grid.columns.zip'),
    dataIndex: 'pickupZipCode'
  },
  {
    name: i18next.t('grid.columns.area'),
    dataIndex: 'pickupZipArea',
    title: true,
    width: '110px'
  },
  {
    name: i18next.t('grid.columns.delivery'),
    dataIndex: 'deliveryName',
    width: '150px',
    title: true,
    groupRenderer: text('Multistop')
  },
  {
    name: i18next.t('grid.columns.address'),
    dataIndex: 'deliveryAddress',
    width: '140px',
    title: true,
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.zip'),
    dataIndex: 'deliveryZipCode',
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.area'),
    dataIndex: 'deliveryZipArea',
    width: '110px',
    title: true,
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.shipmentId'),
    dataIndex: 'shipmentId',
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.weight'),
    dataIndex: 'totalWeightValue',
    width: '60px',
    renderer: amountRenderer('totalWeightUnit'),
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.volume'),
    dataIndex: 'totalVolumeValue',
    width: '60px',
    renderer: amountRenderer('totalVolumeUnit'),
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.qty'),
    dataIndex: 'noOfPackages',
    groupRenderer: hide
  },
  {
    name: i18next.t('consignment.customerReference'),
    dataIndex: 'customerRef',
    title: true,
    width: '100px',
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.pickupContactPerson'),
    dataIndex: 'pickupContactPerson',
    width: '100px',
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.deliveryReferencePlaceholder'),
    dataIndex: 'recipientRef',
    width: '100px',
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.specificationDescriptionPlaceholder'),
    dataIndex: 'specificationDescription',
    width: '100px',
    title: true,
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.orderNote'),
    dataIndex: 'note',
    title: true,
    width: '120px',
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.preplanned'),
    dataIndex: 'preplannedToUnit',
    title: true,
    width: '90px',
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.orderGrid.extraInfo'),
    dataIndex: 'groupName',
    renderer: extraInfoRenderer,
    title: true,
    maxWidth: '80px',
    groupRenderer: groupExtraInfoRenderer
  },
  {
    name: i18next.t('grid.columns.orderGrid.vehicleType'),
    dataIndex: 'vehicleType',
    renderer: vehicleTypeRenderer,
    title: true,
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.orderSource'),
    dataIndex: 'source',
    renderer: sourceRenderer
  },
  {
    name: i18next.t('grid.columns.deliveryContactPerson'),
    dataIndex: 'deliveryContactPerson',
    width: '120px',
    title: true
  },
  {
    name: i18next.t('instant.booking.customerContact'),
    dataIndex: 'custContact',
    width: '120px',
    title: true
  },
  {
    name: i18next.t('serviceLevel.label'),
    dataIndex: 'serviceLevel',
    renderer: serviceLevelRenderer
  }
)

export const orderColumnsShort: List<ColumnDefinition<ShipmentProps>> = List.of(
  {
    name: i18next.t('grid.columns.id'),
    dataIndex: 'id',
    hidden: true,
    createKeyFrom: true,
    width: 'auto'
  },
  {
    name: i18next.t('grid.columns.id'),
    dataIndex: 'shipmentId',
    title: true
  },
  {
    name: i18next.t('grid.columns.pickup'),
    dataIndex: 'pickupTimeEarliest',
    renderer: pickupRenderer
  },
  {
    name: i18next.t('grid.columns.delivery'),
    dataIndex: 'deliveryTimeEarliest',
    renderer: deliveryRenderer
  },
  {
    name: i18next.t('grid.columns.service'),
    dataIndex: 'serviceCode'
  },
  {
    name: i18next.t('grid.columns.pickup'),
    dataIndex: 'pickupName',
    title: true
  },
  {
    name: i18next.t('grid.columns.address'),
    dataIndex: 'pickupAddress',
    title: true
  },
  {
    name: i18next.t('grid.columns.zip'),
    dataIndex: 'pickupZipCode'
  },
  {
    name: i18next.t('grid.columns.delivery'),
    dataIndex: 'deliveryName',
    title: true
  },
  {
    name: i18next.t('grid.columns.address'),
    dataIndex: 'deliveryAddress',
    title: true
  },
  {
    name: i18next.t('grid.columns.zip'),
    dataIndex: 'deliveryZipCode'
  },
  {
    name: i18next.t('grid.columns.qty'),
    dataIndex: 'noOfPackages'
  }
)

export const orderColumnsOffered = orderColumnsShort.insert(0, {
  name: i18next.t('grid.columns.deadline'),
  dataIndex: 'offeredTimeLatest',
  renderer: expiredRenderer,
  width: '60px'
})

export const dispatchModalColumns: List<ColumnDefinition<IRoutePointWithExtraDataProps>> = List.of(
  {
    name: i18next.t('grid.columns.dispatch.id'),
    dataIndex: 'id',
    createKeyFrom: true,
    hidden: true
  },
  {
    name: 'Flex',
    dataIndex: 'isFlex',
    renderer: checkboxRenderer,
    titleRenderer: selectAllTitleRenderer
  },
  {
    name: 'Force',
    dataIndex: 'isForcedOrder',
    renderer: checkboxRenderer,
    titleRenderer: selectAllTitleRenderer
  },
  {
    name: i18next.t('grid.columns.dispatch.id'),
    dataIndex: 'shipmentId',
    title: true
  },
  {
    name: i18next.t('grid.columns.dispatch.customerName'),
    dataIndex: 'customerName',
    title: true
  },
  {
    name: i18next.t('grid.columns.dispatch.name'),
    dataIndex: 'name',
    title: true
  },
  {
    name: i18next.t('grid.columns.dispatch.service'),
    dataIndex: 'service',
    title: true
  },
  {
    name: i18next.t('grid.columns.dispatch.address'),
    dataIndex: 'address',
    title: true,
    width: '250px'
  },
  {
    name: i18next.t('grid.columns.dispatch.type'),
    dataIndex: 'type',
    renderer: routePointTypeRenderer,
    title: true
  },
  {
    name: i18next.t('grid.columns.dispatch.timeWindow'),
    dataIndex: 'start',
    renderer: timeWindowRenderer
  },
  {
    name: i18next.t('grid.columns.trip'),
    dataIndex: 'tripNumber'
  },
  {
    name: i18next.t('grid.columns.qty'),
    dataIndex: 'noOfPackages'
  },
  {
    name: i18next.t('grid.columns.weight'),
    dataIndex: 'totalWeightValue',
    width: '60px',
    renderer: amountRenderer('totalWeightUnit'),
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.volume'),
    dataIndex: 'totalVolumeValue',
    width: '60px',
    renderer: amountRenderer('totalVolumeUnit'),
    groupRenderer: hide
  },
  {
    name: i18next.t('grid.columns.dispatch.estimatedArrivalTime'),
    dataIndex: 'arrivalCached',
    renderer: timeRenderIfNotManual
  },
  {
    name: i18next.t('grid.columns.dispatch.estimatedDepartureTime'),
    dataIndex: 'endTime',
    renderer: timeRenderIfNotManual
  },
  {
    name: i18next.t('grid.columns.waitingTime'),
    dataIndex: 'waitingTime',
    title: true
  },
  {
    name: i18next.t('grid.columns.dispatch.adjusted'),
    dataIndex: 'previousArrival',
    renderer: timeDiffRenderer
  },
  {
    name: '',
    dataIndex: 'onCar',
    renderer: alreadyAssignedRenderer
  }
)

export const slotColumns: List<ColumnDefinition<IRoutePointWithExtraDataProps>> = List.of(
  {
    name: i18next.t('grid.columns.id'),
    dataIndex: 'id',
    createKeyFrom: true,
    hidden: true
  },
  {
    name: i18next.t('grid.columns.id'),
    dataIndex: 'shipmentId',
    title: true
  },
  {
    name: i18next.t('grid.columns.type'),
    dataIndex: 'type',
    renderer: routePointTypeRenderer,
    title: true
  },
  {
    name: i18next.t('grid.columns.estimated'),
    dataIndex: 'arrivalCached',
    renderer: timeRenderer
  },
  {
    name: i18next.t('grid.columns.adjusted'),
    dataIndex: 'adjustedArrivalCached',
    renderer: timeRenderer
  },
  {
    name: i18next.t('grid.columns.name'),
    dataIndex: 'name',
    title: true
  },
  {
    name: i18next.t('grid.columns.address'),
    dataIndex: 'address',
    title: true
  },
  {
    name: i18next.t('grid.columns.start'),
    dataIndex: 'start',
    renderer: timeRenderer
  },
  {
    name: i18next.t('grid.columns.stop'),
    dataIndex: 'stop',
    renderer: timeRenderer
  },
  {
    name: i18next.t('grid.columns.qty'),
    dataIndex: 'noOfPackages'
  },
  {
    name: i18next.t('grid.columns.orderGrid.extraInfo'),
    dataIndex: 'groupName',
    maxWidth: '80px',
    renderer: extraInfoRenderer,
    title: true
  }
)

export const finishedRoutePointsColumns: List<ColumnDefinition<IRoutePointWithExtraDataProps>> = List.of(
  {
    name: i18next.t('grid.columns.id'),
    dataIndex: 'id',
    createKeyFrom: true,
    hidden: true
  },
  {
    name: i18next.t('grid.columns.id'),
    dataIndex: 'shipmentId',
    title: true
  },
  {
    name: i18next.t('grid.columns.type'),
    dataIndex: 'type',
    renderer: routePointTypeRenderer,
    title: true
  },
  {
    name: i18next.t('grid.columns.estimatedArrivalTime'),
    dataIndex: 'arrivalCached',
    renderer: timeRenderer
  },
  {
    name: i18next.t('grid.columns.estimatedDepartureTime'),
    dataIndex: 'endTime',
    renderer: timeRenderer
  },
  {
    name: i18next.t('grid.columns.name'),
    dataIndex: 'name',
    title: true
  },
  {
    name: i18next.t('grid.columns.address'),
    dataIndex: 'address',
    title: true
  }
)

export const recurringOrdersColumns: List<ColumnDefinition<RecurringOrderProps>> = List.of(
  {
    name: i18next.t('grid.columns.id'),
    dataIndex: 'id',
    createKeyFrom: true,
    hidden: true
  },
  {
    name: i18next.t('grid.columns.name'),
    dataIndex: 'name',
    width: '150px',
    valueRenderer: ({ value }: GridRendererParams) => toLower(value),
    title: true
  },
  {
    name: i18next.t('grid.columns.frequency'),
    dataIndex: 'frequency',
    renderer: frequencyRenderer,
    width: '110px',
    title: true
  },
  {
    name: i18next.t('grid.columns.days'),
    dataIndex: 'days',
    renderer: daysRenderer,
    valueRenderer: daysValueRenderer,
    width: '160px',
    title: true
  },
  {
    name: i18next.t('grid.columns.holidays'),
    dataIndex: 'holidays',
    renderer: holidaysRenderer,
    width: '50px'
  },
  {
    name: i18next.t('grid.columns.startDate'),
    dataIndex: 'startDate',
    width: '80px'
  },
  {
    name: i18next.t('grid.columns.enddate'),
    dataIndex: 'endDate',
    renderer: endDateRenderer,
    width: '80px'
  },
  {
    name: i18next.t('grid.columns.orders'),
    dataIndex: 'orders',
    renderer: numberOfOrders,
    valueRenderer: numberOfOrdersValue,
    width: '50px'
  },
  {
    name: i18next.t('grid.columns.dispatchAsGroup'),
    dataIndex: 'dispatchAsGroup',
    renderer: booleanCheckRenderer,
    width: '80px',
    title: true
  }
)

export const recurringAirExpressOrdersColumns: List<ColumnDefinition<RecurringAirExpressSchedule>> = List.of(
  {
    name: i18next.t('grid.columns.id'),
    dataIndex: 'id',
    createKeyFrom: true,
    hidden: true
  },
  {
    name: i18next.t('grid.columns.name'),
    dataIndex: 'name',
    width: '150px',
    valueRenderer: ({ value }: GridRendererParams) => toLower(value),
    title: true
  },
  {
    name: i18next.t('grid.columns.frequency'),
    dataIndex: 'frequency',
    renderer: airexpressFrequencyRenderer,
    width: '110px',
    title: true
  },
  {
    name: i18next.t('grid.columns.days'),
    dataIndex: 'days',
    renderer: daysRenderer,
    valueRenderer: daysValueRenderer,
    width: '160px',
    title: true
  },
  {
    name: i18next.t('grid.columns.holidays'),
    dataIndex: 'includeHolidays',
    renderer: holidaysRenderer,
    width: '50px'
  },
  {
    name: i18next.t('grid.columns.startDate'),
    dataIndex: 'startDate',
    width: '80px'
  },
  {
    name: i18next.t('grid.columns.enddate'),
    dataIndex: 'endDate',
    renderer: endDateRenderer,
    width: '80px'
  }
)

export const orderColumnsForRecurring: List<ColumnDefinition<RecurringOrderShipmentProps>> = List.of(
  {
    name: i18next.t('grid.columns.id'),
    dataIndex: 'id',
    hidden: true,
    createKeyFrom: true,
    width: '40px'
  },
  {
    name: i18next.t('grid.columns.customer'),
    dataIndex: 'custName',
    renderer: customerRenderer,
    width: '120px'
  },
  {
    name: i18next.t('grid.columns.pickup'),
    dataIndex: 'pickupTimeEarliest',
    renderer: pickupRenderer
  },
  {
    name: i18next.t('grid.columns.delivery'),
    dataIndex: 'deliveryTimeEarliest',
    renderer: deliveryRenderer
  },
  {
    name: i18next.t('grid.columns.service'),
    dataIndex: 'serviceCode',
    width: '50px'
  },
  {
    name: i18next.t('grid.columns.pickup'),
    dataIndex: 'pickupName'
  },
  {
    name: i18next.t('grid.columns.address'),
    dataIndex: 'pickupAddress',
    width: '120px'
  },
  {
    name: i18next.t('grid.columns.zipcode'),
    dataIndex: 'pickupZipCode'
  },
  {
    name: i18next.t('grid.columns.area'),
    dataIndex: 'pickupZipArea'
  },
  {
    name: i18next.t('grid.columns.delivery'),
    dataIndex: 'deliveryName',
    width: '120px'
  },

  {
    name: i18next.t('grid.columns.address'),
    dataIndex: 'deliveryAddress',
    width: '120px'
  },
  {
    name: i18next.t('grid.columns.zipcode'),
    dataIndex: 'deliveryZipCode'
  },
  {
    name: i18next.t('grid.columns.area'),
    dataIndex: 'deliveryZipArea'
  },
  {
    name: i18next.t('grid.columns.weight'),
    dataIndex: 'totalWeightValue',
    renderer: amountRenderer('totalWeightUnit')
  },
  {
    name: i18next.t('grid.columns.volume'),
    dataIndex: 'totalVolumeValue',
    renderer: amountRenderer('totalVolumeUnit')
  },
  {
    name: i18next.t('grid.columns.qty'),
    dataIndex: 'noOfPackages'
  },
  {
    name: i18next.t('grid.columns.preplanned'),
    dataIndex: 'preplannedToUnit',
    title: true,
    width: '50px'
  },
  {
    name: i18next.t('grid.columns.exceptions'),
    dataIndex: 'editExceptions',
    renderer: exceptionsRenderer
  }
)

export const airExpressOrderColumnsForRecurring: List<ColumnDefinition<RecurringAirExpressOrderTemplateProps>> =
  List.of(
    {
      name: i18next.t('grid.columns.id'),
      dataIndex: 'id',
      hidden: true,
      createKeyFrom: true,
      width: '40px'
    },
    {
      name: i18next.t('grid.columns.customer'),
      dataIndex: 'custName',
      renderer: customerRenderer
    },
    {
      name: i18next.t('grid.columns.pickup'),
      dataIndex: 'pickupName'
    },
    {
      name: i18next.t('grid.columns.address'),
      dataIndex: 'pickupAddress',
      width: '120px'
    },
    {
      name: i18next.t('grid.columns.zipcode'),
      dataIndex: 'pickupZipCode'
    },
    {
      name: i18next.t('grid.columns.area'),
      dataIndex: 'pickupZipArea'
    },
    {
      name: i18next.t('grid.columns.delivery'),
      dataIndex: 'deliveryName',
      width: '120px'
    },
    {
      name: i18next.t('grid.columns.address'),
      dataIndex: 'deliveryAddress',
      width: '120px'
    },
    {
      name: i18next.t('grid.columns.zipcode'),
      dataIndex: 'deliveryZipCode'
    },
    {
      name: i18next.t('grid.columns.area'),
      dataIndex: 'deliveryZipArea'
    }
  )

export const recurringOrderExecutionSummaryColumns: List<ColumnDefinition<RecurringOrderExecutionSummaryProps>> =
  List.of(
    {
      name: i18next.t('grid.columns.id'),
      dataIndex: 'id',
      hidden: true,
      createKeyFrom: true,
      width: '50px'
    },
    {
      name: i18next.t('grid.columns.group'),
      dataIndex: 'recurringOrderName',
      width: '60px'
    },
    {
      name: i18next.t('grid.columns.shipmentId'),
      dataIndex: 'shipmentId'
    },
    {
      name: i18next.t('grid.columns.customer'),
      dataIndex: 'custName',
      renderer: customerRenderer
    },
    {
      name: i18next.t('grid.columns.from'),
      dataIndex: 'pickupAddress'
    },
    {
      name: i18next.t('grid.columns.to'),
      dataIndex: 'deliveryAddress'
    },
    {
      name: i18next.t('grid.columns.failedReason'),
      title: true,
      dataIndex: 'failedReason',
      width: '200px'
    }
  )

export const orderInvoicingExecutionSummaryColumns: List<ColumnDefinition<OrderInvoicingExecutionSummaryProps>> =
  List.of(
    {
      name: i18next.t('grid.columns.id'),
      dataIndex: 'id',
      hidden: true,
      createKeyFrom: true,
      width: '10px'
    },
    {
      name: i18next.t('grid.columns.date'),
      dataIndex: 'date',
      width: '10px'
    },
    {
      name: i18next.t('grid.columns.lastUpdatedAt'),
      dataIndex: 'updatedAt',
      width: '20px',
      renderer: dateTimeRenderer,
      valueRenderer: dateTimeValueRenderer
    },
    {
      name: i18next.t('grid.columns.finished'),
      dataIndex: 'finished',
      width: '20px',
      renderer: booleanCheckRenderer
    },
    {
      name: i18next.t('grid.columns.failed'),
      dataIndex: 'failed',
      width: '20px',
      renderer: booleanCheckRenderer
    },
    {
      name: i18next.t('grid.columns.totalOrders'),
      dataIndex: 'totalOrders',
      width: '20px'
    },
    {
      name: i18next.t('grid.columns.ordersInvoiced'),
      dataIndex: 'ordersInvoiced',
      width: '20px'
    },
    {
      name: i18next.t('grid.columns.ordersFailed'),
      dataIndex: 'ordersFailed',
      width: '20px',
      renderer: errorRenderer(vars.newColors.deepRed)
    }
  )

export const groupOrdersColumns: List<ColumnDefinition<ShipmentProps>> = List.of(
  {
    name: 'Id',
    dataIndex: 'id',
    hidden: false,
    createKeyFrom: true
  },
  {
    name: 'Pickup',
    dataIndex: 'pickupTimeEarliest',
    renderer: pickupRenderer
  },
  {
    name: 'Delivery',
    dataIndex: 'deliveryTimeEarliest',
    renderer: deliveryRenderer
  },
  {
    name: 'Service',
    dataIndex: 'serviceCode',
    title: true
  },
  {
    name: 'Pickup',
    dataIndex: 'pickupName',
    maxWidth: '150px',
    title: true
  },
  {
    name: 'Address',
    dataIndex: 'pickupAddress',
    maxWidth: '150px',
    title: true
  },
  {
    name: 'Zip code',
    dataIndex: 'pickupZipCode'
  },
  {
    name: 'Delivery',
    dataIndex: 'deliveryName',
    maxWidth: '150px',
    title: true
  },
  {
    name: 'Address',
    dataIndex: 'deliveryAddress',
    maxWidth: '170px',
    title: true
  },
  {
    name: 'Zip code',
    dataIndex: 'deliveryZipCode'
  },
  {
    name: 'Qty',
    dataIndex: 'noOfPackages'
  },
  {
    name: 'Group',
    dataIndex: 'groupName',
    renderer: groupRenderer,
    maxWidth: '60px',
    title: true
  }
)

export const splitOrdersColumns: List<ColumnDefinition<ShipmentProps>> = List.of(
  {
    name: 'Id',
    dataIndex: 'id',
    hidden: false,
    createKeyFrom: true,
    width: '50px'
  },
  {
    name: 'Pickup',
    dataIndex: 'pickupTimeEarliest',
    renderer: pickupRenderer
  },
  {
    name: 'Delivery',
    dataIndex: 'deliveryTimeEarliest',
    renderer: deliveryRenderer
  },
  {
    name: 'Service',
    dataIndex: 'serviceCode',
    width: '50px'
  },
  {
    name: 'Pickup',
    dataIndex: 'pickupName'
  },
  {
    name: 'Address',
    dataIndex: 'pickupAddress'
  },
  {
    name: 'Zip code',
    dataIndex: 'pickupZipCode',
    width: '50px'
  },
  {
    name: 'Delivery',
    dataIndex: 'deliveryName'
  },
  {
    name: 'Address',
    dataIndex: 'deliveryAddress',
    width: '170px'
  },
  {
    name: 'Zip code',
    dataIndex: 'deliveryZipCode',
    width: '50px'
  },
  {
    name: 'Qty',
    dataIndex: 'noOfPackages',
    width: '30px'
  }
)

export const groupRecipientColumns: List<ColumnDefinition<GroupRecipientProps>> = List.of(
  {
    name: 'Id',
    dataIndex: 'id',
    hidden: true,
    createKeyFrom: true
  },
  {
    name: i18next.t('grid.columns.addresses.name'),
    dataIndex: 'name'
  },
  {
    name: i18next.t('grid.columns.addresses.address'),
    dataIndex: 'address'
  },
  {
    name: i18next.t('grid.columns.addresses.zipCode'),
    dataIndex: 'zipCode',
    width: '120px'
  },
  {
    name: i18next.t('grid.columns.addresses.zipArea'),
    dataIndex: 'city'
  },
  {
    name: i18next.t('grid.columns.deliveryReferencePlaceholder'),
    dataIndex: 'reference'
  }
)

export const addExceptionsColumns: List<ColumnDefinition<ShipmentProps>> = List.of(
  {
    name: 'Id',
    dataIndex: 'id',
    hidden: true,
    createKeyFrom: true
  },
  {
    name: 'Pickup',
    dataIndex: 'pickupTimeEarliest',
    renderer: pickupRenderer
  },
  {
    name: 'Delivery',
    dataIndex: 'deliveryTimeEarliest',
    renderer: deliveryRenderer
  },
  {
    name: 'Service',
    dataIndex: 'serviceCode'
  },
  {
    name: 'Pickup',
    dataIndex: 'pickupName'
  },
  {
    name: 'Address',
    dataIndex: 'pickupAddress'
  },
  {
    name: 'Zip code',
    dataIndex: 'pickupZipCode'
  },
  {
    name: 'Delivery',
    dataIndex: 'deliveryName'
  },
  {
    name: 'Address',
    dataIndex: 'deliveryAddress'
  },
  {
    name: 'Zip code',
    dataIndex: 'deliveryZipCode'
  },
  {
    name: 'Qty',
    dataIndex: 'noOfPackages'
  },
  {
    name: 'Group',
    dataIndex: 'groupName',
    renderer: groupRenderer
  }
)

export const editExceptionsColumns: List<ColumnDefinition<RecurringOrderExceptionProps>> = List.of(
  {
    name: 'Id',
    dataIndex: 'id',
    hidden: true,
    createKeyFrom: true
  },
  {
    name: 'Start date',
    dataIndex: 'startDate',
    width: '100px'
  },
  {
    name: 'Enda date',
    dataIndex: 'endDate',
    width: '100px'
  }
)

interface ShipmentColumnsSettings {
  isDepartmentGroup: boolean
  filter?: string
  shipmentEvents?: Map<OrderIdType, List<ConsignmentEvent>>
  showWithoutDate?: boolean
  isExtendedSearch?: boolean
  showPreAdvice?: boolean
  showPrices: boolean
}

export const shipmentColumns = (settings: ShipmentColumnsSettings): List<ColumnDefinition<ShipmentProps>> =>
  List.of(
    {
      name: 'id',
      dataIndex: 'id',
      hidden: true,
      createKeyFrom: true
    },
    {
      name: i18next.t('consignment.state'),
      dataIndex: 'state',
      width: '130px',
      renderer: statusRenderer,
      valueRenderer: statusValueRenderer,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.packagesArrivedAtDip'),
      dataIndex: 'packagesArrivedAtDip',
      width: '70px',
      renderer: shipmentSyncStatusRenderer
    },
    {
      name: i18next.t('grid.columns.arrivedAtHub'),
      dataIndex: 'arrivedAtHub',
      renderer: yesNoRenderer,
      valueRenderer: yesNoValueRenderer
    },
    {
      name: i18next.t('grid.columns.packagesCollected'),
      dataIndex: 'packagesCollected',
      width: '70px',
      renderer: shipmentSyncStatusRenderer,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.packagesDelivered'),
      dataIndex: 'packagesDelivered',
      width: '70px',
      renderer: shipmentSyncStatusRenderer,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('customer.customerDeviations.deviationType'),
      dataIndex: 'deviationType',
      hidden: settings.filter !== 'DEVIATED' || settings.showPreAdvice,
      renderer: deviationTypeRenderer(settings.shipmentEvents || Map())
    },
    {
      name: i18next.t('customer.customerDeviations.deviation'),
      dataIndex: 'deviation',
      hidden: settings.filter !== 'DEVIATED' || settings.showPreAdvice,
      width: '214px',
      renderer: deviationTextRenderer(settings.shipmentEvents || Map())
    },
    {
      name: i18next.t('grid.columns.returned'),
      dataIndex: 'isReturned',
      renderer: yesNoRenderer,
      valueRenderer: yesNoValueRenderer,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.department'),
      dataIndex: 'deptName',
      width: '150px',
      title: true,
      hidden: !(settings.isDepartmentGroup || settings.isExtendedSearch)
    },
    {
      name: i18next.t('consignment.customer'),
      dataIndex: 'custName',
      title: true,
      width: '150px'
    },
    {
      name: i18next.t('consignment.subcustomer'),
      dataIndex: 'custAlystraSubcustomer',
      title: true,
      renderer: subCustomerRenderer('100px'),
      valueRenderer: subCustomerValueRenderer,
      width: '110px'
    },
    {
      name: i18next.t('grid.columns.date'),
      dataIndex: 'pickupTimeEarliestCached',
      renderer: dateRenderer,
      hidden: settings.showWithoutDate || settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.puearly'),
      dataIndex: 'pickupTimeEarliest',
      renderer: timeRenderer,
      valueRenderer: timeValueRenderer,
      hidden: settings.showWithoutDate || settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.pulate'),
      dataIndex: 'pickupTimeLatest',
      renderer: timeRenderer,
      valueRenderer: timeValueRenderer,
      hidden: settings.showWithoutDate || settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.delearly'),
      dataIndex: 'deliveryTimeEarliest',
      renderer: timeRenderer,
      valueRenderer: timeValueRenderer,
      hidden: settings.showWithoutDate || settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.dellate'),
      dataIndex: 'deliveryTimeLatest',
      renderer: timeRenderer,
      valueRenderer: timeValueRenderer,
      hidden: settings.showWithoutDate || settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.service'),
      dataIndex: 'serviceCodeText',
      maxWidth: '100px',
      title: true
    },
    {
      name: i18next.t('grid.columns.pickupDuration'),
      dataIndex: 'pickupDurationSeconds',
      renderer: durationRenderer,
      valueRenderer: durationValueRenderer,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.deliveryDuration'),
      dataIndex: 'deliveryDurationSeconds',
      renderer: durationRenderer,
      valueRenderer: durationValueRenderer,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.vas'),
      dataIndex: 'vasCodesTexts',
      width: '100px',
      title: true
    },
    {
      name: i18next.t('consignment.shipmentId'),
      dataIndex: 'shipmentId',
      width: '150px',
      groupRenderer: hide
    },
    {
      name: i18next.t('grid.columns.preAdvices'),
      dataIndex: 'preAdvices',
      hidden: !settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.createdAt'),
      dataIndex: 'createdAt',
      renderer: dateRenderer,
      hidden: !settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.preAdviceScheduledDate'),
      dataIndex: 'preAdviceScheduledDate',
      renderer: dateRenderer,
      hidden: !settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.deliveryAttempts'),
      dataIndex: 'deliveryAttempts',
      hidden: !settings.showPreAdvice
    },
    {
      name: i18next.t('preadvice.locked'),
      dataIndex: 'preAdviceStatus',
      renderer: (params: GridRendererParams) =>
        yesNoRenderer({ ...params, value: params.value == 'PRE_ADVICE_LOCKED' }),
      hidden: !settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.returnToSender'),
      dataIndex: 'returnToSender',
      renderer: yesNoRenderer,
      valueRenderer: yesNoValueRenderer,
      hidden: !settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.hasPreAdviceSettings'),
      dataIndex: 'hasPreAdviceSetting',
      renderer: yesNoRenderer,
      valueRenderer: yesNoValueRenderer,
      hidden: !settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.preAdviceFailed'),
      dataIndex: 'failed',
      renderer: yesNoRenderer,
      valueRenderer: yesNoValueRenderer,
      hidden: !settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.pickup'),
      dataIndex: 'pickupName',
      width: '140px',
      title: true
    },
    {
      name: i18next.t('grid.columns.address'),
      dataIndex: 'pickupAddress',
      width: '120px',
      title: true
    },
    {
      name: i18next.t('grid.columns.zip'),
      dataIndex: 'pickupZipCode'
    },
    {
      name: i18next.t('grid.columns.area'),
      dataIndex: 'pickupZipArea',
      width: '80px'
    },
    {
      name: i18next.t('grid.columns.instructions'),
      dataIndex: 'pickupInstructions',
      width: '120px',
      title: true,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.contactPerson'),
      dataIndex: 'pickupContactPerson',
      width: '130px',
      title: true,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.phone'),
      dataIndex: 'pickupPhone',
      width: '100px',
      title: true
    },
    {
      name: `${i18next.t('grid.columns.phone')} 2`,
      dataIndex: 'pickupSecondPhone',
      width: '100px',
      title: true
    },
    {
      name: i18next.t('grid.columns.delivery'),
      dataIndex: 'deliveryName',
      width: '140px',
      title: true,
      groupRenderer: text('Multistop')
    },
    {
      name: i18next.t('grid.columns.address'),
      dataIndex: 'deliveryAddress',
      width: '120px',
      title: true,
      groupRenderer: hide
    },
    {
      name: i18next.t('grid.columns.zip'),
      dataIndex: 'deliveryZipCode',
      groupRenderer: hide
    },
    {
      name: i18next.t('grid.columns.area'),
      dataIndex: 'deliveryZipArea',
      width: '80px',
      groupRenderer: hide
    },
    {
      name: i18next.t('grid.columns.instructions'),
      dataIndex: 'deliveryInstructions',
      width: '120px',
      title: true,
      groupRenderer: hide
    },
    {
      name: i18next.t('grid.columns.contactPerson'),
      dataIndex: 'deliveryContactPerson',
      width: '130px',
      title: true,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.phone'),
      dataIndex: 'deliveryPhone',
      width: '100px',
      title: true
    },
    {
      name: `${i18next.t('grid.columns.phone')} 2`,
      dataIndex: 'deliverySecondPhone',
      width: '100px',
      title: true
    },
    {
      name: i18next.t('grid.columns.qty'),
      dataIndex: 'noOfPackages',
      width: '45px',
      groupRenderer: hide,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.weight'),
      dataIndex: 'totalWeightValue',
      width: '60px',
      title: true,
      renderer: amountRenderer('totalWeightUnit'),
      groupRenderer: hide,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.volume'),
      dataIndex: 'totalVolumeValue',
      width: '80px',
      title: true,
      renderer: amountRenderer('totalVolumeUnit'),
      groupRenderer: hide,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('consignment.customerReference'),
      dataIndex: 'customerRef',
      width: '115px',
      title: true,
      groupRenderer: hide
    },
    {
      name: i18next.t('grid.columns.deliveryReferencePlaceholder'),
      dataIndex: 'recipientRef',
      width: '110px',
      groupRenderer: hide,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.specificationDescriptionPlaceholder'),
      dataIndex: 'specificationDescription',
      title: true,
      width: '115px',
      groupRenderer: hide,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.price'),
      dataIndex: 'netCustomerPrice',
      width: '60px',
      renderer: priceRenderer,
      valueRenderer: priceValueRenderer,
      groupRenderer: hide,
      hidden: settings.showPreAdvice || !settings.showPrices
    },
    {
      name: i18next.t('grid.columns.orderNote'),
      dataIndex: 'note',
      title: true,
      width: '120px',
      groupRenderer: hide
    },
    {
      name: i18next.t('grid.columns.multileg'),
      dataIndex: 'multilegId',
      renderer: multilegRenderer,
      width: '65px',
      groupRenderer: hide,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.groupName'),
      dataIndex: 'groupName',
      width: '100px',
      renderer: groupNameRenderer('100px'),
      groupRenderer: hide,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.timeOfPickup'),
      dataIndex: 'actualPickupTime',
      renderer: dateTimeRenderer,
      valueRenderer: dateTimeValueRenderer,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.timeOfDelivery'),
      dataIndex: 'deliveredTime',
      renderer: dateTimeRenderer,
      valueRenderer: dateTimeValueRenderer,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.deliveryEtaSmsSentAt'),
      dataIndex: 'etaSmsSentAt',
      renderer: dateTimeRenderer,
      valueRenderer: dateTimeValueRenderer,
      maxWidth: '190px',
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.route'),
      dataIndex: 'slotName',
      title: true,
      width: '70px',
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('planner.driverName'),
      dataIndex: 'courierName',
      width: '70px',
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.preplanned'),
      dataIndex: 'preplannedToUnit',
      title: true,
      width: '110px',
      groupRenderer: hide,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.orderSource'),
      dataIndex: 'source',
      renderer: sourceRenderer,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('instant.booking.customerContact'),
      dataIndex: 'custContact',
      width: '120px',
      title: true,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.plannedDeliveryDate'),
      dataIndex: 'plannedDeliveryDate',
      hidden: !settings.showWithoutDate,
      renderer: dateRenderer
    },
    {
      name: i18next.t('grid.columns.orderedDeliveryDate'),
      dataIndex: 'orderedDeliveryDate',
      hidden: !settings.showWithoutDate || settings.showPreAdvice,
      renderer: dateRenderer
    },
    {
      name: i18next.t('grid.columns.originalPickupAddress'),
      dataIndex: 'originalPickupAddress',
      width: '120px',
      title: true,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.originalPickupZipCode'),
      dataIndex: 'originalPickupZipCode',
      width: '80px',
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.originalPickupZipArea'),
      dataIndex: 'originalPickupZipArea',
      width: '80px',
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.originalPickupCountry'),
      dataIndex: 'originalPickupCountry',
      width: '80px',
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.originalDeliveryAddress'),
      dataIndex: 'originalDeliveryAddress',
      width: '120px',
      title: true,
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.originalDeliveryZipCode'),
      dataIndex: 'originalDeliveryZipCode',
      width: '80px',
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.originalDeliveryZipArea'),
      dataIndex: 'originalDeliveryZipArea',
      width: '80px',
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.originalDeliveryCountry'),
      dataIndex: 'originalDeliveryCountry',
      width: '80px',
      hidden: settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.originalDeliveryEarliest'),
      dataIndex: 'originalDeliveryTimeEarliest',
      renderer: dateRenderer,
      hidden: settings.showWithoutDate || settings.showPreAdvice
    },
    {
      name: i18next.t('grid.columns.originalDeliveryLatest'),
      dataIndex: 'originalDeliveryTimeLatest',
      renderer: dateRenderer,
      hidden: settings.showWithoutDate || settings.showPreAdvice
    },
    {
      name: i18next.t('serviceLevel.label'),
      dataIndex: 'serviceLevel',
      renderer: serviceLevelRenderer,
      hidden: settings.showPreAdvice
    }
  )

export const deletedShipmentColumns = (settings: ShipmentColumnsSettings): List<ColumnDefinition<ShipmentProps>> =>
  List.of(
    {
      name: 'id',
      dataIndex: 'id',
      hidden: true,
      createKeyFrom: true
    },
    {
      name: i18next.t('grid.columns.department'),
      dataIndex: 'deptName',
      width: '100px',
      hidden: !settings.isDepartmentGroup
    },
    {
      name: i18next.t('consignment.customer'),
      dataIndex: 'custName',
      maxWidth: '200px'
    },
    {
      name: i18next.t('consignment.subcustomer'),
      dataIndex: 'custAlystraSubcustomer',
      renderer: subCustomerRenderer('100px'),
      valueRenderer: subCustomerValueRenderer,
      width: '100px'
    },
    {
      name: i18next.t('consignment.shipmentId'),
      dataIndex: 'shipmentId'
    },
    {
      name: i18next.t('consignment.deletedBy'),
      dataIndex: 'deletedByName',
      width: '150px'
    },
    {
      name: i18next.t('consignment.deletionReason'),
      dataIndex: 'reason',
      width: '150px'
    },
    {
      name: i18next.t('grid.columns.date'),
      dataIndex: 'pickupTimeEarliestCached',
      renderer: dateRenderer
    },
    {
      name: i18next.t('grid.columns.puearly'),
      dataIndex: 'pickupTimeEarliest',
      renderer: timeRenderer,
      valueRenderer: timeValueRenderer
    },
    {
      name: i18next.t('grid.columns.pulate'),
      dataIndex: 'pickupTimeLatest',
      renderer: timeRenderer,
      valueRenderer: timeValueRenderer
    },
    {
      name: i18next.t('grid.columns.delearly'),
      dataIndex: 'deliveryTimeEarliest',
      renderer: timeRenderer,
      valueRenderer: timeValueRenderer
    },
    {
      name: i18next.t('grid.columns.dellate'),
      dataIndex: 'deliveryTimeLatest',
      renderer: timeRenderer,
      valueRenderer: timeValueRenderer
    },
    {
      name: i18next.t('grid.columns.service'),
      dataIndex: 'serviceCode',
      width: '60px'
    },
    {
      name: i18next.t('grid.columns.pickup'),
      dataIndex: 'pickupName',
      title: true
    },
    {
      name: i18next.t('grid.columns.address'),
      dataIndex: 'pickupAddress',
      title: true
    },
    {
      name: i18next.t('grid.columns.zip'),
      dataIndex: 'pickupZipCode'
    },
    {
      name: i18next.t('grid.columns.area'),
      dataIndex: 'pickupZipArea'
    },
    {
      name: i18next.t('grid.columns.delivery'),
      dataIndex: 'deliveryName',
      width: '140px',
      title: true,
      groupRenderer: text('Multistop')
    },
    {
      name: i18next.t('grid.columns.address'),
      dataIndex: 'deliveryAddress',
      title: true,
      groupRenderer: hide
    },
    {
      name: i18next.t('grid.columns.zip'),
      dataIndex: 'deliveryZipCode',
      groupRenderer: hide
    },
    {
      name: i18next.t('grid.columns.area'),
      dataIndex: 'deliveryZipArea',
      groupRenderer: hide
    },
    {
      name: i18next.t('grid.columns.qty'),
      dataIndex: 'noOfPackages',
      width: '40px',
      groupRenderer: hide
    }
  )

export const adminCustomerEmailColumn: List<ColumnDefinition<AdminCustomerEmailProps>> = List.of(
  {
    name: 'id',
    dataIndex: 'id',
    hidden: true,
    createKeyFrom: true
  },
  {
    name: i18next.t('customer.email.emailAddress'),
    dataIndex: 'emailId',
    width: '200px'
  },
  {
    name: i18next.t('customer.email.createdEvent'),
    dataIndex: 'createdEvent',
    width: '80px',
    renderer: adminCustomerEmailRenderer
  },
  {
    name: i18next.t('customer.email.collectedEvent'),
    dataIndex: 'collectedEvent',
    width: '80px',
    renderer: adminCustomerEmailRenderer
  },
  {
    name: i18next.t('customer.email.deliveredEvent'),
    dataIndex: 'deliveredEvent',
    width: '80px',
    renderer: adminCustomerEmailRenderer
  },
  {
    name: i18next.t('customer.email.returnToSender'),
    dataIndex: 'returnToSender',
    width: '80px',
    renderer: adminCustomerEmailRenderer,
    hidden: !isBring()
  },
  {
    name: i18next.t('customer.email.reminderToSender'),
    dataIndex: 'reminderToSender',
    width: '80px',
    renderer: adminCustomerEmailRenderer,
    hidden: !isBring()
  },
  {
    name: i18next.t('customer.email.claims'),
    dataIndex: 'claims',
    width: '80px',
    renderer: adminCustomerEmailRenderer,
    hidden: !isBring()
  }
)

export const adminCustomerDeviationColumn: List<ColumnDefinition<AdminCustomerDeviationProps>> = List.of(
  {
    name: 'id',
    dataIndex: 'id',
    hidden: true,
    createKeyFrom: true
  },
  {
    name: i18next.t('customer.customerDeviations.deviation'),
    dataIndex: 'headerEn',
    width: '130px'
  },
  {
    name: i18next.t('customer.customerDeviations.code'),
    dataIndex: 'deviationCode',
    width: '130px'
  }
)

export const addressColumns: List<ColumnDefinition<AddressProps>> = List.of(
  {
    name: 'id',
    dataIndex: 'id',
    hidden: true,
    createKeyFrom: true
  },
  {
    name: i18next.t('grid.columns.addresses.name'),
    dataIndex: 'name',
    width: '150px'
  },
  {
    name: i18next.t('grid.columns.addresses.address'),
    dataIndex: 'address',
    width: '150px'
  },
  {
    name: i18next.t('grid.columns.addresses.zipArea'),
    dataIndex: 'zipArea',
    width: '50px'
  },
  {
    name: i18next.t('grid.columns.addresses.zipCode'),
    dataIndex: 'zipCode',
    width: '50px'
  },
  {
    name: i18next.t('grid.columns.addresses.country'),
    dataIndex: 'country',
    width: '30px'
  },
  {
    name: i18next.t('grid.columns.addresses.loc'),
    legend: i18next.t('grid.columns.addresses.locTitle'),
    dataIndex: 'latLng',
    width: '30px',
    renderer: mapLinkRenderer,
    rightAlignSortingArrow: false
  },
  {
    name: i18next.t('grid.columns.addresses.contactPerson'),
    dataIndex: 'contactPerson',
    width: '150px'
  },
  {
    name: i18next.t('grid.columns.addresses.phone'),
    dataIndex: 'phone',
    width: '50px'
  },
  {
    name: i18next.t('grid.columns.addresses.instructions'),
    dataIndex: 'instructions',
    width: '50px',
    title: true
  }
)

interface SuggestedUnitsColumnsSettings {
  showSlotName?: boolean
}

export const suggestedUnitsColumns = (
  settings: SuggestedUnitsColumnsSettings
): List<ColumnDefinition<SuggestedUnitsProps>> =>
  List.of(
    {
      name: 'id',
      dataIndex: 'id',
      hidden: true,
      createKeyFrom: true
    },
    {
      name: i18next.t('instant.suggestedUnits.unitName'),
      dataIndex: 'unitName',
      maxWidth: '80px',
      title: true
    },
    {
      name: i18next.t('instant.suggestedUnits.driverName'),
      dataIndex: 'driverName',
      minWidth: '80px',
      maxWidth: '150px',
      title: true,
      renderer: suggestedDriverWithPackagesRenderer
    },
    {
      name: i18next.t('instant.suggestedUnits.slotName'),
      dataIndex: 'slotName',
      hidden: !settings.showSlotName,
      maxWidth: '80px',
      title: true
    },
    {
      name: i18next.t('instant.suggestedUnits.timeAtLocation'),
      dataIndex: 'timeAtLocation',
      renderer: dateTimeRenderer,
      valueRenderer: dateTimeValueRenderer,
      maxWidth: '100px',
      title: true
    },
    {
      name: i18next.t('instant.suggestedUnits.distanceFromLocation'),
      dataIndex: 'distanceFromLocation',
      renderer: unitRenderer(MeasurementUnit.KMT),
      maxWidth: '80px'
    }
  )
