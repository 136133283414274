import Select from '../../../primitives/Select'
// @ts-expect-error
import { Control } from 'react-redux-form/lib/immutable'
import i18next from 'i18next'
import { getServiceDisplayText, IOptimusService } from '../../../domain/optimusService'
import { LabeledInput } from '../../../primitives/LabeledInput'
import { InputForLabel } from '../../../primitives/Forms'
import React from 'react'
import { List } from 'immutable'

interface Props {
  isH2Booking?: boolean
  index: number
  disableSelection?: boolean
  valueAddedServices: List<IOptimusService>
  prefix?: string
}

export function VasCode({ isH2Booking, valueAddedServices, index, disableSelection, prefix }: Props) {
  return (
    <>
      {isH2Booking ? (
        <H2VasCodeComponent index={index} />
      ) : (
        <StandardVasCodeComponent
          index={index}
          valueAddedServices={valueAddedServices}
          disableSelection={disableSelection}
          prefix={prefix}
        />
      )}
    </>
  )
}

interface H2VasCodeProps {
  index: number
}

const H2VasCodeComponent = ({ index }: H2VasCodeProps) => (
  <div>
    <LabeledInput label={i18next.t('instant.booking.serviceCode')} htmlFor={`.additionalServices[${index}].vasCode`}>
      <Control.text
        autoComplete="nope"
        model={`.additionalServices[${index}].vasCode`}
        id={`.additionalServices[${index}].vasCode`}
        component={InputForLabel}
      />
    </LabeledInput>
  </div>
)

interface StandardVasCodeProps {
  index: number
  disableSelection?: boolean
  valueAddedServices: List<IOptimusService>
  prefix?: string
}

const StandardVasCodeComponent = ({
  index,
  disableSelection,
  valueAddedServices,
  prefix = ''
}: StandardVasCodeProps) => (
  <div>
    <Select expand>
      <Control.select
        autoComplete="nope"
        model={prefix + `.additionalServices[${index}].vasCode`}
        id={prefix + `.additionalServices[${index}].vasCode`}
        style={{ padding: '0.6rem' }}
        disabled={disableSelection}
      >
        <option value={''}>{i18next.t('instant.booking.serviceCode')}</option>
        {valueAddedServices.map((vas: IOptimusService, i) => {
          return (
            <option key={`vas-${i}`} value={vas.get('vasCode')}>
              {getServiceDisplayText(vas)}
            </option>
          )
        })}
      </Control.select>
    </Select>
  </div>
)
