import { ConsignmentEventId, SlotIdType } from '../../types/coreEntitiesTypes'
import { ConsignmentEventActionType } from '../../types/consignmentEventActionTypes'
import { fetchIfNotPresent, ThunkDispatch, ThunkResult } from './baseHelpers'
import { api, ConsumedResponse, QueryData } from '../../http/httpHelper'
import {
  ActionType,
  CREATE_CONSIGNMENT_EVENT_ACTION,
  GET_CONSIGNMENT_EVENTS,
  GET_LATEST_CONSIGNMENT_EVENT_ACTIONS
} from '../actionTypes'
import { List, Set } from 'immutable'
import { EventType } from '../../utils/consignmentEvent'

export function getConsignmentEvents(slotIds?: List<SlotIdType>, eventTypes?: List<EventType>) {
  return fetchConsignmentEventsIfNotPresent(GET_CONSIGNMENT_EVENTS, {
    query: 'consignmentEventsBySlotIds',
    params: { slotIds, eventTypes }
  })
}

export const fetchConsignmentEventsIfNotPresent: (
  actionType: ActionType,
  queryBody: QueryData
) => ThunkResult<Promise<ConsumedResponse>> =
  (actionType: ActionType, queryBody: QueryData) => (dispatch, getState) => {
    if (httpDoneForConsignmentEvents(getState, actionType, queryBody)) {
      return Promise.resolve(new Response(null, { status: 200 })) as Promise<ConsumedResponse>
    } else {
      return dispatch(api.asyncQuery(actionType, queryBody))
    }
  }

const requestFlag = 'request'
const successFlag = 'success'

export const httpDoneForConsignmentEvents = (
  getState: () => { getIn: any },
  actionType: { name: string },
  queryBody: { params: any }
) => {
  const httpStatus = getState().getIn(['httpStatus', actionType.name])
  const params = httpStatus.get('params')
  const status = httpStatus.get('status')

  const eventsForSlotsAlreadyFetched = queryBody.params?.slotIds?.every(
    (slot: string) => params.first() && params.flatMap((p: any) => p.get('slotIds')).includes(slot)
  )

  const eventsForTypesAlreadyFetched = queryBody.params?.eventTypes?.every(
    (event: string) => params.first() && params.flatMap((p: any) => p.get('eventTypes')).includes(event)
  )
  return (
    (status === successFlag || status === requestFlag) && eventsForSlotsAlreadyFetched && eventsForTypesAlreadyFetched
  )
}

export function getLatestConsignmentEventActions(slotIds: Set<SlotIdType>) {
  return fetchIfNotPresent(GET_LATEST_CONSIGNMENT_EVENT_ACTIONS, {
    query: 'latestConsignmentEventActions',
    params: { slotIds }
  })
}

export function createConsignmentEventAction(
  consignmentEventIds: List<ConsignmentEventId>,
  consignmentEventActionType: ConsignmentEventActionType
) {
  return (dispatch: ThunkDispatch) => {
    return dispatch(
      api.asyncCommand(CREATE_CONSIGNMENT_EVENT_ACTION, {
        type: 'CREATE_CONSIGNMENT_EVENT_ACTION',
        payload: {
          consignmentEventIds,
          consignmentEventActionType
        }
      })
    )
  }
}
