import i18next from 'i18next'
import React from 'react'
import eraseIcon from '../assets/icons/erase.png'
import { Icon } from '../primitives/InstantGridStyles'
import { List } from 'immutable'

interface Props {
  clearComponents: (components: List<string>) => void
  components: List<string>
}

export const ClearInstantBookingFormButton = ({ clearComponents, components }: Props) => (
  <button
    type="button"
    title={i18next.t('instant.booking.clearData')}
    style={{
      position: 'absolute',
      right: '5px',
      top: '5px',
      width: '16px',
      height: '16px',
      padding: '0',
      cursor: 'pointer',
      outline: 'none'
    }}
    onClick={() => clearComponents(components)}
  >
    <Icon
      src={eraseIcon}
      alt="erase"
      style={{
        width: '100%',
        bottom: '0'
      }}
    />
  </button>
)
