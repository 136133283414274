import { List, Map, Seq } from 'immutable'
import { Order, OrderIdType } from '../types/coreEntitiesTypes'
import { AppStateType, ImmutableEntity } from '../utils/appStateReduxStore'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'

export const ordersSelector = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'orders']) as Map<string, ImmutableEntity>,
  (orders) => (orders ? orders.valueSeq() : Seq.Indexed<Order>([])) as Seq.Indexed<Order>
)

export const orderFromIdSelector = createImmutableEqualSelector(
  ordersSelector,
  (state: AppStateType, orderId: OrderIdType) => orderId,
  (orders, orderId) => orders.find((d) => d.get('id') === orderId)
)

export const ordersFromShipmentIdsSelector = createImmutableEqualSelector(
  ordersSelector,
  (state: AppStateType, shipmentIds: List<string>) => shipmentIds,
  (orders, shipmentIds) => orders.filter((d) => shipmentIds.some((id) => d.get('shipmentId') === id))
)
