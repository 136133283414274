import { List, OrderedSet, Set } from 'immutable'
import { Consignment } from '../types/coreEntitiesTypes'

export enum ConsignmentState {
  CREATED = 'CREATED',
  UNCONFIRMED = 'UNCONFIRMED',
  UNRESOLVED = 'UNRESOLVED',
  RESOLVED = 'RESOLVED',
  OFFERED = 'OFFERED',
  REFUSED = 'REFUSED',
  ASSIGNED = 'ASSIGNED',
  COLLECTED = 'COLLECTED',
  DEVIATED = 'DEVIATED',
  DELIVERED = 'DELIVERED',
  REJECTED = 'REJECTED',
  RETURNED = 'RETURNED',
  DELETED = 'DELETED'
}

const orderedConsignmentStates = OrderedSet([
  ConsignmentState.CREATED,
  ConsignmentState.UNCONFIRMED,
  ConsignmentState.UNRESOLVED,
  ConsignmentState.RESOLVED,
  ConsignmentState.REJECTED,
  ConsignmentState.OFFERED,
  ConsignmentState.REFUSED,
  ConsignmentState.ASSIGNED,
  ConsignmentState.COLLECTED,
  ConsignmentState.DEVIATED,
  ConsignmentState.DELIVERED,
  ConsignmentState.RETURNED
])

const editableStates = List<ConsignmentState>([
  ConsignmentState.CREATED,
  ConsignmentState.UNRESOLVED,
  ConsignmentState.RESOLVED
])
const semiEditableStates = List<ConsignmentState>([ConsignmentState.ASSIGNED, ConsignmentState.COLLECTED])
const deletableStates = List<ConsignmentState>([
  ConsignmentState.CREATED,
  ConsignmentState.UNRESOLVED,
  ConsignmentState.RESOLVED,
  ConsignmentState.OFFERED,
  ConsignmentState.ASSIGNED
])
const editDescription = List<ConsignmentState>([
  ConsignmentState.CREATED,
  ConsignmentState.RESOLVED,
  ConsignmentState.UNRESOLVED,
  ConsignmentState.ASSIGNED
])
const notCollected = List<ConsignmentState>([
  ConsignmentState.RESOLVED,
  ConsignmentState.REJECTED,
  ConsignmentState.OFFERED,
  ConsignmentState.ASSIGNED
])
const deviatedStates = List<ConsignmentState>([ConsignmentState.DEVIATED, ConsignmentState.RETURNED])
const completedStates = List<ConsignmentState>([
  ConsignmentState.DELIVERED,
  ConsignmentState.DEVIATED,
  ConsignmentState.RETURNED
])

export const isShipmentUnassignableStatesForCourierDepartment = List<ConsignmentState>([
  ConsignmentState.ASSIGNED,
  ConsignmentState.COLLECTED,
  ConsignmentState.OFFERED,
  ConsignmentState.DEVIATED,
  ConsignmentState.REJECTED,
  ConsignmentState.RESOLVED
])
export const isShipmentReturnsUnassignableStatesForCourierDepartment =
  isShipmentUnassignableStatesForCourierDepartment.merge(
    List<ConsignmentState>([ConsignmentState.DELIVERED, ConsignmentState.RETURNED])
  )
export const allowedUnassignableShipmentStatesForNonCourierDepartment = List<ConsignmentState>([
  ConsignmentState.OFFERED,
  ConsignmentState.ASSIGNED,
  ConsignmentState.COLLECTED,
  ConsignmentState.REJECTED
])

export const getHighestState = (states: Set<ConsignmentState>) =>
  orderedConsignmentStates.reverse().find((o) => states.contains(o)) || ConsignmentState.CREATED
export const getHighestStateFromShipment = (consignments: Set<Consignment>) =>
  getHighestState(
    consignments
      .map((s) => s.get('state'))
      .valueSeq()
      .toSet()
  )
export const isAnyPackageDuplicable = (consignments: Set<Consignment>) =>
  consignments
    .map((c) => c.get('state'))
    .toSet()
    .some((state) => deviatedStates.contains(state))

export const isConsignmentOffered = (state: string) => ConsignmentState.OFFERED === state
export const isConsignmentRejected = (state: string) => ConsignmentState.REJECTED === state
export const isConsignmentDelivered = (state: string) => ConsignmentState.DELIVERED === state

export const isShipmentEditable = (sStates: Set<ConsignmentState>) => editableStates.contains(getHighestState(sStates))

export const isConsignmentCompleted = (cState: ConsignmentState) => {
  return completedStates.contains(cState)
}

export const isConsignmentDeviated = (cState: ConsignmentState) => {
  return deviatedStates.contains(cState)
}

export const isShipmentSemiEditable = (sStates: Set<ConsignmentState>) => {
  return semiEditableStates.contains(getHighestState(sStates))
}

export const isConsignmentEditable = (cState: ConsignmentState) => {
  return editableStates.contains(cState)
}

export const isConsignmentDeletable = (consignment: Consignment) => {
  return (
    deletableStates.contains(consignment.get('state')) ||
    ((consignment.get('type') === 'Invoice' || consignment.get('type') === 'Credit') &&
      consignment.get('orderInvoicingStatus') !== true)
  )
}

export const isConsignmentSemiEditable = (cState: ConsignmentState) => {
  return semiEditableStates.contains(cState)
}

export const isConsignmentDescriptionEditable = (cState: ConsignmentState) => {
  return editDescription.contains(cState)
}

export const isNotCollected = (cState: ConsignmentState) => {
  return notCollected.contains(cState)
}

export const notAssignedStates = Set([
  ConsignmentState.CREATED,
  ConsignmentState.UNRESOLVED,
  ConsignmentState.RESOLVED,
  ConsignmentState.REJECTED,
  ConsignmentState.OFFERED
])

export const notCollectedStates = notAssignedStates.add(ConsignmentState.ASSIGNED)

export const consignmentCanBeCollected = Set([
  ConsignmentState.OFFERED,
  ConsignmentState.ASSIGNED,
  ConsignmentState.COLLECTED,
  ConsignmentState.DEVIATED
])

export const readyForInvoicingStates = Set([
  ConsignmentState.DELIVERED,
  ConsignmentState.RETURNED,
  ConsignmentState.DEVIATED,
  ConsignmentState.COLLECTED
])

export const ineligibleStatesForEditShipmentsBulkAction = Set([
  ConsignmentState.DEVIATED,
  ConsignmentState.DELIVERED,
  ConsignmentState.REJECTED,
  ConsignmentState.RETURNED
])

export const eligibleStatesForMoveShipmentsBulkAction = Set([
  ConsignmentState.CREATED,
  ConsignmentState.RESOLVED,
  ConsignmentState.UNRESOLVED,
  ConsignmentState.REJECTED
])
