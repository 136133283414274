import React from 'react'
import { NewShipmentModal } from './NewShipmentModal'
import { Unit, Department, OrderNote, RoutePoint, Shipment, Slot } from '../../types/coreEntitiesTypes'
import { getServiceText, getVasText, GroupedServicesAndVasesTexts } from '../../utils/serviceUtils'
import {
  Consignment,
  Customer,
  Delivery,
  Goods,
  Pickup,
  Route,
  Services,
  ShipmentForShipmentModal,
  VAS
} from './shared/Shipment'
import { isValidDate, toLocaleDatePreserveNull } from '../../utils/dateTime'
import { ValueAddedService } from '../../pages/instant/bookingOrderTypes'
import { List } from 'immutable'
import { getTrackingUrl } from '../../utils/trackingUtils'
import { FlightInfoEntity } from '../../domain/bringOrder'

export const NewShipmentModalContainer: React.FC<{
  shipment: Shipment
  allTexts: GroupedServicesAndVasesTexts
  trackingBaseUrl?: string
  slot?: Slot
  unit?: Unit
  role?: string
  internalNote?: OrderNote
  enableQuickEdit?: boolean
  department?: Department
  routePoints?: List<RoutePoint>
  flightInfo?: FlightInfoEntity
  mawbNo?: string
  awbNo?: string
}> = ({
  shipment,
  allTexts,
  trackingBaseUrl,
  slot,
  unit,
  role,
  internalNote,
  enableQuickEdit,
  department,
  routePoints,
  flightInfo,
  mawbNo,
  awbNo
}) => (
  <NewShipmentModal
    shipment={toShipmentForModal(shipment, allTexts, trackingBaseUrl, slot, unit, role, internalNote, mawbNo, awbNo)}
    enableQuickEdit={enableQuickEdit}
    department={department}
    routePoints={routePoints}
    flightInfo={flightInfo}
  />
)

const toShipmentForModal = (
  shipment: Shipment,
  allTexts: GroupedServicesAndVasesTexts,
  trackingBaseUrl?: string,
  slot?: Slot,
  unit?: Unit,
  role?: string,
  internalNote?: OrderNote,
  mawbNo?: string,
  awbNo?: string
): ShipmentForShipmentModal => {
  return {
    shipmentNumber: shipment.get('shipmentId'),
    orderId: shipment.get('orderId'),
    departmentId: shipment.get('departmentId'),
    isEditable: true,
    isDeleted: shipment.get('isDeletedShipment'),
    state: shipment.get('state'),
    isMultileg: shipment.get('multilegId') || false,
    pickup: toPickup(shipment),
    delivery: toDelivery(shipment, trackingBaseUrl),
    customer: toCustomer(shipment),
    customerContact: shipment.get('custContact') ?? undefined,
    route: toRoute(shipment, slot, unit),
    services: toServices(shipment, allTexts),
    goods: toGoods(shipment),
    internalNote: internalNote?.get('note'),
    serviceLevel: shipment.get('serviceLevel'),
    isLastLeg: shipment.get('isLastLeg') || false,
    mawbNo,
    awbNo,
    type: shipment.get('type')
  } as ShipmentForShipmentModal
}

export const toPickup = (shipment: Shipment, pickupInstructions?: string) =>
  ({
    sender: {
      name: shipment.get('pickupName'),
      phone: shipment.get('pickupPhone'),
      secondPhone: shipment.get('pickupSecondPhone'),
      email: shipment.get('pickupEmail'),
      address: {
        streetName: shipment.get('pickupAddress'),
        postalCode: shipment.get('pickupZipCode'),
        area: shipment.get('pickupZipArea'),
        country: shipment.get('pickupCountry'),
        lat: shipment.get('pickupLat'),
        lng: shipment.get('pickupLng')
      },
      reference: shipment.get('customerRef')
    },
    pickupInstructions: pickupInstructions ?? shipment.get('pickupInstructions'),
    pickupTime:
      isValidDate(shipment.get('pickupTimeEarliest')) && isValidDate(shipment.get('pickupTimeLatest'))
        ? {
            earliest: toLocaleDatePreserveNull(shipment.get('pickupTimeEarliest')),
            latest: toLocaleDatePreserveNull(shipment.get('pickupTimeLatest'))
          }
        : undefined,
    eta: toLocaleDatePreserveNull(shipment.get('estimatedPickupArrivalTime'))
  }) as Pickup

export const toDelivery = (shipment: Shipment, trackingBaseUrl?: string, deliveryInstructions?: string) =>
  ({
    receiver: {
      name: shipment.get('deliveryName'),
      phone: shipment.get('deliveryPhone'),
      secondPhone: shipment.get('deliverySecondPhone'),
      email: shipment.get('deliveryEmail'),
      address: {
        streetName: shipment.get('deliveryAddress'),
        postalCode: shipment.get('deliveryZipCode'),
        area: shipment.get('deliveryZipArea'),
        country: shipment.get('deliveryCountry'),
        lat: shipment.get('deliveryLat'),
        lng: shipment.get('deliveryLng')
      },
      reference: shipment.get('recipientRef'),
      deliverySms: shipment.get('deliverySms')
    },
    deliveryInstructions: deliveryInstructions ?? shipment.get('deliveryInstructions'),
    deliveryTime:
      isValidDate(shipment.get('deliveryTimeEarliest')) && isValidDate(shipment.get('deliveryTimeLatest'))
        ? {
            earliest: toLocaleDatePreserveNull(shipment.get('deliveryTimeEarliest')),
            latest: toLocaleDatePreserveNull(shipment.get('deliveryTimeLatest'))
          }
        : undefined,
    eta: toLocaleDatePreserveNull(shipment.get('estimatedArrivalTime')),
    showTrackingUrl: trackingBaseUrl && shipment.getIn(['consignments', 0, 'serviceLevel'], 'standard') !== 'none',
    trackingURL: trackingBaseUrl && getTrackingUrl(trackingBaseUrl, shipment)
  }) as Delivery

export const toCustomer = (shipment: Shipment) =>
  ({
    id: shipment.get('customerId'),
    name: shipment.get('custName'),
    number: shipment.get('custAlystraId')
  }) as Customer

const toRoute = (shipment: Shipment, slot?: Slot, unit?: Unit) =>
  ({
    name: slot?.get('name'),
    courierName: unit?.get('name')
  }) as Route

export const toServices = (shipment: Shipment, allTexts: GroupedServicesAndVasesTexts) => {
  const valueAddedServices =
    shipment
      .get('vasDetails')
      ?.map(
        (vas: ValueAddedService) =>
          ({
            text: getVasText(allTexts, vas.get('vasCode'), shipment.get('custAlystraId')),
            quantity: vas.get('quantity')
          }) as VAS
      )
      .sort()
      .toArray() || []

  return {
    service: {
      code: shipment.get('serviceCode'),
      description: getServiceText(allTexts, shipment.get('serviceCode'), shipment.get('custAlystraId'), true)
    },
    valueAddedServices: valueAddedServices
  } as Services
}

export const toGoods = (shipment: Shipment) =>
  ({
    totalWeight: { value: shipment.get('totalWeightValue'), unit: shipment.get('totalWeightUnit') },
    totalVolume: { value: shipment.get('totalVolumeValue'), unit: shipment.get('totalVolumeUnit') },
    totalLoadingMeters: {
      value: shipment.get('totalloadingMetersValue'),
      unit: shipment.get('totalloadingMetersUnit')
    },
    palletFootPrints: {
      value: shipment.get('totalPalletFootPrintsValue'),
      unit: shipment.get('totalPalletFootPrintsUnit')
    },
    goodsDescription: shipment.get('specificationDescription'),
    consignments: shipment
      .get('consignments')
      .map(
        (c) =>
          ({
            packageId: c.get('packageId'),
            weight: c.get('weightValue'),
            length: c.get('lengthValue'),
            width: c.get('widthValue'),
            height: c.get('heightValue')
          }) as Consignment
      )
      .toArray()
  }) as Goods
