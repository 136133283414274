import { eventDataValue } from '@glow/entity-types'
import i18next from 'i18next'
import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { EventRecipient } from './event-recipient'

export function EventImage(props: { data: AnyData }) {
  return (
    <>
      <EventImageTag data={props.data} path={['filename']} />
      <EventRecipient data={props.data} />
    </>
  )
}

export function EventImageTag(props: { data: AnyData; path: string[] }) {
  const filename = eventDataValue(props.data as Record<string, unknown> | null, props.path)

  if (!filename) {
    return null
  }

  return <img src={'/api/image/' + filename} alt={String(filename)} />
}

export function EventImageLink(props: { data: AnyData; path: string[] }) {
  const filename = eventDataValue(props.data as Record<string, unknown> | null, props.path)

  if (!filename) {
    return null
  }

  return (
    <p>
      <a href={'/api/image/' + filename} target="_blank" rel="noreferrer" className="underline">
        {i18next.t('consignmentEvent.message.showPicture')}
      </a>
    </p>
  )
}
