import { ImmutableMap } from '../types/immutableTypes'
import { pureVAS, replaceEveningAndWeight } from '../pages/instant/bookingOrderFunctions'
import { isEmpty } from '../utils/stringUtils'
import { getServiceText, GroupedServicesAndVasesTexts } from '../utils/serviceUtils'

export interface IOptimusService extends ImmutableMap<OptimusServiceProps> {}

export interface OptimusServiceProps {
  id: number
  isoPickupTimeFrom: string
  isoPickupTimeTo: string
  isoDeliveryTimeFrom: string
  isoDeliveryTimeTo: string
  alystraServiceCode: string
  alystraArticleCode: string
  serviceCode: string
  description: string
  vasCode?: string
  xParameter: string
  yParameter: string
  weightAddition: boolean
  instantServiceTime?: number
  availableOnDays: number[]
  useCustomerZones: boolean
  eveningCode?: string
  weekendCode?: string
  weekendEveningCode?: string
  routingOverride?: string
}

export function getServiceDisplayText(service: IOptimusService, serviceTexts?: GroupedServicesAndVasesTexts) {
  const descriptionFromOptimus = service.get('description')
  const code = pureVAS(service) ? service.get('vasCode') : replaceEveningAndWeight(service.get('alystraServiceCode'))

  const serviceTextName = serviceTexts && code && getServiceText(serviceTexts, code, null)
  const description = descriptionFromOptimus || serviceTextName

  if (code === description || isEmpty(code)) {
    return description
  }

  return isEmpty(description) ? code : `${description} (${code})`
}

export function isStoreHomeDeliveryService(service: IOptimusService): boolean {
  // The only services using customer zones for now are SHD (Store Home Delivery) services.
  // We might need to introduce a better way to identify SHD services later, but for now this is good enough
  return service.get('useCustomerZones')
}
