import React from 'react'
import styled from 'styled-components'

export const ShipmentModalCloseButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Wrapper onClick={onClick}>
    <img
      style={{ width: '24px', height: '24px', marginTop: '7px' }}
      src={'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(closeIcon)}
      alt="close"
    />
  </Wrapper>
)

const Wrapper = styled.div`
  cursor: pointer;
  text-align: center;
  width: 38px;
  height: 38px;
  margin-left: 10px;
  margin-top: -7px;
  :hover {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
`

const closeIcon = `
<svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="m14.546 12 7.692-7.692 1.5863-1.5862c0.234-0.234 0.234-0.61425 0-0.84825l-1.6973-1.6972c-0.234-0.234-0.6142-0.234-0.8482 0l-9.2783 9.2782-9.2782-9.279c-0.234-0.234-0.61425-0.234-0.84825 0l-1.698 1.6972c-0.234 0.234-0.234 0.61425 0 0.84825l9.279 9.279-9.279 9.2783c-0.234 0.234-0.234 0.6142 0 0.8482l1.6972 1.6973c0.234 0.234 0.61425 0.234 0.84825 0l9.279-9.2783 9.2783 9.2783c0.234 0.234 0.6142 0.234 0.8482 0l1.6973-1.6973c0.234-0.234 0.234-0.6142 0-0.8482l-9.2783-9.2783z" fill="#fff"/>
</svg>
`
