import React from 'react'
import { formatDateTime, toLocaleDate } from '../../../../utils/dateTime'
import i18next from 'i18next'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { eventDataValue } from '@glow/entity-types'

export const FirstEstimatedTimesSeen = (props: { data: AnyData }): React.ReactElement => {
  const from = eventDataValue(props.data, ['from'])
  const to = eventDataValue(props.data, ['to'])

  return (
    <>
      {from && to && (
        <>
          <p className="font-bold mt-4 mb-0">
            {i18next.t('eventsList.timeWindow')}
            {' -'}
          </p>
          <p className="m-0">
            {' '}
            {i18next.t('eventsList.from')}
            {': '}
            {formatDateTime(toLocaleDate(from as string))}
          </p>
          <p className="m-0">
            {' '}
            {i18next.t('eventsList.to')}
            {': '}
            {formatDateTime(toLocaleDate(to as string))}
          </p>
        </>
      )}
    </>
  )
}
