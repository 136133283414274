import { Collection, fromJS, Map } from 'immutable'
import {
  GET_DELIVERY_SERVICES_OPTIMUS,
  GET_DEPARTMENT_SERVICES_OPTIMUS,
  GET_MULTISTOP_ORDER_PRICE_DATA,
  GET_ORDER_PRICE_DATA,
  GET_ORDER_PRICE_DATA_RESET,
  GET_PICKUP_SERVICES_OPTIMUS,
  GET_SERVICES_OPTIMUS,
  RESET_ORDER_PRICE_INFO,
  ROUTER_LOCATION_CHANGE_ACTION
} from '../actions/actionTypes'
import { AnyData } from '../actions/creators/baseHelpers'
import { ImmutableMap } from '../types/immutableTypes'

const initialState: ServiceStateImmutable = Map({
  pickupServices: Map(),
  deliveryServices: Map(),
  departmentServices: Map(),
  price: Map(),
  errors: [],
  isLoadingPrice: false,
  isLoadingServices: false
} as ServiceState)

export interface ServiceState {
  pickupServices: Collection<unknown, unknown>
  deliveryServices: Collection<unknown, unknown>
  departmentServices: Collection<unknown, unknown>
  price: Collection<unknown, unknown>
  errors: unknown
  isLoadingPrice: boolean
  isLoadingServices: boolean
}

interface ActionProp {
  type: string
  body: AnyData
  payload: { isFirstRendering: boolean }
}

interface ServiceStateImmutable extends ImmutableMap<ServiceState> {}

export default function reducer(state = initialState, action: ActionProp) {
  switch (action.type) {
    case GET_PICKUP_SERVICES_OPTIMUS.SUCCESS:
      return state.withMutations((s) =>
        s
          .set('pickupServices', fromJS(action.body.pickupServices))
          .set('errors', fromJS(action.body.errors))
          .set('isLoadingServices', false)
      )
    case GET_DELIVERY_SERVICES_OPTIMUS.SUCCESS:
      return state.withMutations((s) =>
        s
          .set('deliveryServices', fromJS(action.body.deliveryServices))
          .set('errors', fromJS(action.body.errors))
          .set('isLoadingServices', false)
      )
    case GET_SERVICES_OPTIMUS.SUCCESS:
      return state.withMutations((s) =>
        s
          .set('pickupServices', fromJS(action.body.pickupServices))
          .set('deliveryServices', fromJS(action.body.deliveryServices))
          .set('errors', fromJS(action.body.errors))
          .set('isLoadingServices', false)
      )
    case GET_DEPARTMENT_SERVICES_OPTIMUS.SUCCESS:
      return state.withMutations((s) =>
        s
          .set('departmentServices', fromJS(action.body.departmentServices))
          .set('errors', fromJS(action.body.errors))
          .set('isLoadingServices', false)
      )
    case GET_SERVICES_OPTIMUS.REQUEST:
    case GET_PICKUP_SERVICES_OPTIMUS.REQUEST:
    case GET_DELIVERY_SERVICES_OPTIMUS.REQUEST:
    case GET_DEPARTMENT_SERVICES_OPTIMUS.REQUEST:
      return state.set('isLoadingServices', true)
    case GET_ORDER_PRICE_DATA.SUCCESS:
      return state.set('price', fromJS(action.body)).set('isLoadingPrice', false)
    case GET_ORDER_PRICE_DATA.REQUEST:
      return state.set('isLoadingPrice', true)
    case GET_ORDER_PRICE_DATA_RESET:
      return state.set('price', fromJS({}))
    case GET_MULTISTOP_ORDER_PRICE_DATA.SUCCESS:
      return state.set('price', fromJS(action.body)).set('isLoadingPrice', false)
    case GET_MULTISTOP_ORDER_PRICE_DATA.REQUEST:
      return state.set('isLoadingPrice', true)
    case GET_MULTISTOP_ORDER_PRICE_DATA.FAILURE:
    case GET_ORDER_PRICE_DATA.FAILURE:
    case GET_SERVICES_OPTIMUS.FAILURE:
    case GET_PICKUP_SERVICES_OPTIMUS.FAILURE:
    case GET_DELIVERY_SERVICES_OPTIMUS.FAILURE:
    case GET_DEPARTMENT_SERVICES_OPTIMUS.FAILURE:
      return state.set('isLoadingPrice', false).set('isLoadingServices', false)
    case ROUTER_LOCATION_CHANGE_ACTION:
      return action.payload?.isFirstRendering ? initialState : state
    case RESET_ORDER_PRICE_INFO:
      return state.withMutations((s) => s.set('price', Map()))
    default:
      return state
  }
}
