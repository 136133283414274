import React from 'react'
import { formatQuantity, Goods } from './newShipmentModal/shared/Shipment'
import { DescriptionList, TermDescriptionGroup } from './DescriptionList'
import i18next from 'i18next'

export const GoodsComponent: React.FC<{ goods: Goods }> = ({ goods }) => (
  <>
    <DescriptionList>
      <TermDescriptionGroup
        term={i18next.t('shipment.quantity')}
        description={goods.consignments.length.toString()}
        inline
      />
      <TermDescriptionGroup
        term={i18next.t('shipment.totalWeight')}
        description={formatQuantity(goods.totalWeight)}
        inline
      />
      <TermDescriptionGroup
        term={i18next.t('shipment.totalVolume')}
        description={formatQuantity(goods.totalVolume)}
        inline
      />
      {goods.totalLoadingMeters && (goods.totalLoadingMeters?.value || 0) > 0.0 && (
        <TermDescriptionGroup
          term={i18next.t('shipment.totalLoadingMeters')}
          description={formatQuantity(goods.totalLoadingMeters)}
          inline
        />
      )}
      {goods.totalPalletFootPrints && (goods.totalPalletFootPrints?.value || 0) > 0.0 && (
        <TermDescriptionGroup
          term={i18next.t('shipment.totalPalletFootPrints')}
          description={formatQuantity(goods.totalPalletFootPrints)}
          inline
        />
      )}
      <TermDescriptionGroup term={i18next.t('shipment.goodsInfo')} description={goods.goodsDescription} topMargin />
    </DescriptionList>
  </>
)
