import ImmutableComponent from '../../primitives/ImmutableComponent'
import { DeliveryTimeWindow, DeliveryTimeWindowIdType } from '../../domain/deliveryTimeWindow'
import { Map, Set } from 'immutable'
import { isNotEmpty } from '../../utils/collectionUtils'
import { SmallText } from '../../primitives/Typography'
import i18next from 'i18next'
import * as React from 'react'
import { getDayOfWeekTranslation } from '../../pages/instant/bookingFormComponents/commonHelpers'

interface Props {
  timeWindows: Map<DeliveryTimeWindowIdType, DeliveryTimeWindow>
  deliveryDays: Set<number>
}

export class DeliveryInfo extends ImmutableComponent<Props> {
  render() {
    return (
      <>
        {isNotEmpty(this.props.deliveryDays) && (
          <div
            style={{
              marginBottom: '1rem',
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              columnGap: '1rem'
            }}
          >
            <SmallText inline style={{ marginRight: '1rem', marginTop: '2px', gridColumn: 1 }}>
              {i18next.t('customer.booking.deliveryAvailable')}:
            </SmallText>

            <div
              style={{
                gridColumn: 2,
                flexDirection: 'column',
                display: 'flex',
                flexWrap: 'wrap'
              }}
            >
              {this.props.deliveryDays.map((day: number) => {
                return (
                  <div key={day} style={{ display: 'inline', marginRight: '1rem' }}>
                    <SmallText
                      style={{
                        display: 'inline-block',
                        width: '80px',
                        marginRight: '0.5rem'
                      }}
                    >
                      {getDayOfWeekTranslation(day)}:
                    </SmallText>

                    {this.props.timeWindows
                      .valueSeq()
                      .filter((it) => it.get('dayOfWeek') === day)
                      .map((timeWindow: DeliveryTimeWindow) => {
                        return (
                          <SmallText inline key={timeWindow.get('id')} style={{ marginRight: '0.5rem' }}>
                            {timeWindow.get('timeFrom')} - {timeWindow.get('timeTo')}
                          </SmallText>
                        )
                      })}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </>
    )
  }
}
