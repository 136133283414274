import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import i18next from 'i18next'
import { List } from 'immutable'
import { DateTime } from 'luxon'
import React from 'react'
import styled from 'styled-components'
import { FlexColumn, FlexRow } from '../../../../primitives/Flex'
import variables from '../../../../styles/variables'
import { ShipmentSyncedStatus } from '../../../../types/coreEntitiesTypes'
import { DescriptionList, TermDescriptionGroup } from '../../../DescriptionList'
import { DeliveryTime } from '../../shared/Shipment'
import { AvailableDeliveryTimesGrid } from './AvailableDeliveryTimesGrid'
import { MonthPicker } from './MonthPicker'
import { SmallTertiaryButton } from './bookingTimeCommonStyles'

export const AvailableDeliveryTimesView: React.FC<{
  availableDeliveryTimes: List<DeliveryTime>
  selectedDeliveryTime: DeliveryTime | null
  setSelectedDeliveryTime: (deliveryTime: DeliveryTime | null) => void
  toggleCustomTimeView: () => void
  endDate: DateTime
  setEndDate: (date: DateTime) => void
  prevPage: () => void
  nextPage: () => void
  prevDisabled: boolean
  nextDisabled: boolean
  arrivedAtTerminal: ShipmentSyncedStatus
}> = ({
  availableDeliveryTimes,
  selectedDeliveryTime,
  setSelectedDeliveryTime,
  toggleCustomTimeView,
  endDate,
  setEndDate,
  prevPage,
  nextPage,
  prevDisabled,
  nextDisabled,
  arrivedAtTerminal
}) => {
  return (
    <FlexColumn>
      <FlexRow justifyContent={'space-between'}>
        <MonthPicker endDate={endDate} setEndDate={setEndDate} />
        <SmallTertiaryButton onClick={toggleCustomTimeView}>
          <FontAwesomeIcon
            style={{ marginRight: '0.5em', marginTop: '2px' }}
            icon="plus"
            color={variables.newColors.darkGrey}
          />
          {i18next.t('bookingTimeWindow.customButton')}
        </SmallTertiaryButton>
      </FlexRow>
      <DescriptionList style={{ marginTop: '1rem' }}>
        <TermDescriptionGroup
          term={i18next.t('bookingTimeWindow.arrivedAtTerminal')}
          description={i18next.t(`consignment.shipmentSyncStatus.${arrivedAtTerminal}`)}
        />
      </DescriptionList>
      {availableDeliveryTimes.isEmpty() ? (
        <NoRows>{i18next.t('bookingTimeWindow.noAvailableDeliveryTimes')}</NoRows>
      ) : (
        <AvailableDeliveryTimesGrid
          availableDeliveryTimes={availableDeliveryTimes}
          selectedDeliveryTime={selectedDeliveryTime}
          setSelectedDeliveryTime={setSelectedDeliveryTime}
        />
      )}
      <FlexRow justifyContent={'space-between'}>
        <SmallTertiaryButton onClick={prevPage} disabled={prevDisabled}>
          {i18next.t('bookingTimeWindow.previousButton')}
        </SmallTertiaryButton>
        <SmallTertiaryButton onClick={nextPage} disabled={nextDisabled}>
          {i18next.t('bookingTimeWindow.nextButton')}
        </SmallTertiaryButton>
      </FlexRow>
    </FlexColumn>
  )
}

const NoRows = styled.div`
  margin: 2em 0;
  padding: 0.5em;
  border-radius: 10px;
  text-align: center;
  background: ${variables.newColors.lightRed};
`
