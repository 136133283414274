const sv = {
  language: 'sv',
  welcomeToGlow: 'Välkommen till Glow',
  welcomeToGlowDescription: 'Använd navigationen till vänster för att påbörja ditt arbete!',
  userRoles: {
    admin: 'Admin',
    planner: 'Normal',
    read_only_planner: 'Läsbehörighet',
    plus_planner: 'Plus',
    partner: 'Partner',
    customer: 'Kund',
    customs_agent: 'Tullagent',
    handler: 'Hanläggare'
  },
  application: {
    requiredLocation: 'Platstjänster är obligatoriskt',
    recipient: 'Mottagare',
    save: 'Spara',
    update: 'Uppdatera',
    required: '*Obligatoriskt fält',
    requiredShort: '*Obligatoriskt',
    search: 'Sök',
    logOut: 'Logga ut',
    proceed: 'Fortsätt',
    courier: 'Chaufför',
    couriers: 'Chaufförer',
    consignments: 'Sändningar',
    address: 'Adress',
    estimated: 'Beräknad',
    adjusted: 'Justerad',
    actual: 'Faktisk',
    funeral: 'Begravning',
    tracking: 'Spårning',
    trackingPage: 'Spårningssida för mottagare',
    delivery: 'Leverans',
    phoneNumberShort: 'Telefon',
    yes: 'Ja',
    no: 'Nej',
    httpErrorToString: 'HTTP-fel: kod {{status}} {{statusText}}',
    anErrorOccurred: 'Ett fel inträffade!',
    noAccessTitle: 'Ingen åtkomst',
    noAccessMessage: 'Du har inte rätt att använda denna funktion',
    autoComplete: 'Sök plats ...',
    autoCompleteWithoutDots: 'Sök plats',
    totalWeight: 'Total vikt',
    totalVolume: 'Total volym',
    validDeviationCode: 'Vänligen ange ett giltigt värde',
    backToNewSite: 'Tillbaka till nya sidan',
    title: {
      siteName: 'GLOW',
      root: 'Välkommen',
      planner: 'Trafikplanering',
      plannerLive: 'Trafikplanering - Live',
      plannerLiveSlot: 'Trafikplanering - Live - Rutt',
      plannerCouriers: 'Trafikplanering - Chaufförer',
      plannerImport: 'Trafikplanering - Importer',
      plannerUnresolved: 'Trafikplanering - Obekräftade',
      plannerConsignments: 'Trafikplanering - Sändningar',
      plannerSearch: 'Trafikplanering - Sök',
      plannerPlanning: 'Trafikplanering - Planering',
      deliveryNotes: 'Trafikplanering - Följesedlar',
      plannerInvoicing: 'Trafikplanering - Fakturering',
      plannerVehicle: 'Trafikplanering - Vehicles',
      report: 'Rapporter',
      customerDepartmentLive: 'Live-vy',
      recipient: 'Spårning - Bring',
      instantBooking: 'Bokning',
      h2Booking: 'H2 Bokning',
      orderConfirmation: 'Orderbekräftelse',
      customerPortal: 'Kundportal',
      instantMap: 'Karta',
      recurringOrder: 'Återkommande uppdrag',
      corrections: 'Korrigeringar',
      jobs: 'Jobbövervakning',
      holidays: 'Högtider',
      shipmentLabels: 'Fraktsedlar',
      addresses: 'Adresser',
      airexpressRecurringOrder: 'Air Express Återkommande uppdrag',
      airexpressInvoicing: 'Air Express Fakturering',
      routeReceiptList: 'Kvittenslista',
      loadList: 'Lastlista',
      airexpressAdminTerminals: 'Terminaler'
    },
    language: { en: 'Engelska', nb: 'Norska', sv: 'Svenska', dk: 'Danska', fi: 'Finska' },
    country: {
      AllCountry: 'Alla länder',
      Country: 'Land',
      AT: 'Österrike',
      BE: 'Belgien',
      BA: 'Bosnien och Hercegovina',
      BG: 'Bulgarien',
      HR: 'Kroatien',
      CZ: 'Tjeckien',
      DK: 'Danmark',
      GB: 'Storbritannien',
      EE: 'Estland',
      FO: 'Färöarna',
      FR: 'Frankrike',
      DE: 'Tyskland',
      GR: 'Grekland',
      HU: 'Ungern',
      IS: 'Island',
      IE: 'Irland',
      IT: 'Italien',
      LV: 'Lettland',
      LT: 'Litauen',
      LU: 'Luxemburg',
      MK: 'Nordmakedonien',
      NL: 'Nederländerna',
      NO: 'Norge',
      PL: 'Polen',
      RO: 'Rumänien',
      RU: 'Ryssland',
      RS: 'Serbien',
      SK: 'Slovakien',
      SI: 'Slovenien',
      ES: 'Spanien',
      SE: 'Sverige',
      CH: 'Schweiz',
      UA: 'Ukraina'
    },
    timeDistance: 'Planerad tid och distans',
    driverReturnToAddress: 'Ange ingen returadress',
    plannedTime: 'Planerad tid',
    plannedDistance: 'Planerad distans',
    plannedWeight: 'Planerad vikt',
    distance: 'Distans'
  },
  serviceLevel: { label: 'Nedgradera servicenivå', notSet: 'Inte valt', none: 'Av' },
  simpleSearch: { link: 'Länk' },
  button: {
    edit: 'Ändra',
    back: 'Tillbaka',
    cancel: 'Ångra',
    done: 'Klar',
    clear: 'Rensa',
    clearAll: 'Rensa alla',
    close: 'Stäng',
    save: 'Spara',
    delete: 'Ta bort',
    new: 'Skapa ny',
    search: 'Sök',
    add: 'Lägg till',
    remove: 'Ta bort',
    assign: 'Tilldela',
    fullEdit: 'Full redigering',
    continue: 'Fortsätt'
  },
  consignment: {
    includePreAdvice: 'Preavisering',
    onlyFailedPreAdvice: 'Avisering misslyckats',
    onlyReturnPreAdvice: 'Retur till avsänder',
    consignmentDetails: 'Kolli-information',
    search: 'Sök efter sändningar',
    noShipmentsFound: 'Hittade inga sändningar',
    clickButtonToSearch: 'Klicka på sök-knappen för att utföra ett sök',
    consignmentsForDate: 'Sändningar {{date}}',
    showOnlyEditable: 'Bara ändringsbara',
    showOnlyNotAssigned: 'Ej tilldelad',
    withoutDate: 'Utan datum',
    extendedSearch: 'Utökad sökning',
    showDeleted: 'Visa borttagna',
    showDeviated: 'Visa avvik',
    deletedBy: 'Raderad av',
    deletionReason: 'Orsak',
    numberOfConsignments: 'Hittade {{number}} sändningar',
    showAll: 'Alla',
    delivery: 'Leverans',
    pickup: 'Upphämtning',
    pickups: '{{count}} upphämtning',
    pickups_plural: '{{count}} upphämtningar',
    edit: 'Ändra',
    deleteOrders: 'Radera',
    duplicateOrder: 'Duplicera',
    copyOrder: 'Kopiera',
    editOrder: 'Ändra',
    preplan: 'Förplanera',
    deletedOrderNotCopyable: 'Den borttagna ordern kan inte kopieras eftersom det saknas orderdetaljer i Glow',
    restoreOrder: 'Återställ',
    deletedOrderNotRestorable: 'Raderad order kan inte återställas eftersom det saknas orderdetaljer i Glow',
    deleteConfirmation: 'Är du säker på att du vill radera den här sändningen?',
    deleteMultipleConfirmation: 'Är du säker på att du radera de valda sändningarna?',
    copyConfirmation: 'Är du säker på att du vill kopiera den här sändninge?',
    editConsignment: 'Ändra sändningsinformation',
    searchShipments: 'Sök bland sändningar:',
    searchCustomers: 'Sök bland kunder:',
    filterBy: 'Filtrera på:',
    limitedShipmentsShownMessage: 'Visar ett maximalt antal av {{count}} sändningar, överväg att begränsa din sökning',
    limitedUnresolvedAddressesShownMessage: 'Ett maximum av {{count}} olösta adresser visas',
    bulkActions: 'Ändra sändningar',
    bulkActionsMultiple: 'Ändra sändningar ({{count}})',
    addToRecurringGroup: 'Lägg till återkommande uppdrag',
    moveShipmentsToDifferentDepartment: 'Flytta till en annan avdelning',
    addEvents: 'Lägg till händelser',
    disabledEditDeleteHelperText:
      'Den här ordern kan inte ändras eller tas bort. Kontakta ditt lokala Bring-kontor för att göra ändringar i ordern.',
    editPickupConsignment: 'Ändra upphämtningsinformation',
    editDeliveryConsignment: 'Ändra leveransinformation',
    editPickupAndDeliveryTime: 'Uppdatera upphämtning och leveranstider',
    editConsignmentLocation: ' Plats',
    editConsignmentTime: ' Tid',
    editConsignmentName: ' Namn',
    editConsignmentPhone: ' Telefon',
    editConsignmentInstructions: ' Instruktioner',
    earliestPickupTime: 'Tidigaste upphämtningstid *',
    latestPickupTime: 'Senaste upphämtningstid *',
    earliestDeliveryTime: 'Tidigaste leveranstid',
    latestDeliveryTime: 'Senaste leveranstid',
    enterPickupInstructions: 'Fyll i upphämtningsinstruktioner',
    enterDeliveryInstructions: 'Fyll i leveransinstruktioner',
    enterDeliveryName: 'Fyll i namn till mottagare',
    enterPickupName: 'Fyll i namn till avsändare',
    enterPickupPhone: 'Fyll i telefonnummer till avsändare',
    enterDeliveryPhone: 'Fyll i telefonnummer till mottagare',
    specificationSize: 'Antal',
    enterSpecificationSize: 'Fyll i antal',
    editSpecificationSize: 'Ändra antal',
    enterSpecificationDescription: 'Fyll i beskrivning',
    editSpecificationDescription: 'Ändra beskrivning',
    editPickupInfoDescription: 'För att ändra information så måste du välja någon av alternativen ovan.',
    updateLocationFor: 'Uppdatera information för {{count}} sändning',
    updateLocationFor_plural: 'Uppdatera information för {{count}} sändningar',
    comment: 'Kommentar',
    show: 'Visa',
    filter: 'Filtrera sändningar',
    state: 'Status',
    estimatedDelivery: 'Beräknad leveranstid',
    requestedTimePickup: 'Upphämtningstid',
    requestedTimeDelivery: 'Leveranstid',
    timeNotSet: 'Inte bestämt',
    customerReference: 'Kundreferens',
    shipmentId: 'Sändningsnummer',
    packageId: 'Kollinummer',
    noCourier: 'Ingen chaufför',
    noSlot: 'Ingen rutt',
    showEvents: 'Visa händelser →',
    hideEvents: 'Dölj händelser ←',
    events: 'Händelser',
    send: 'Visa',
    sendMessage: 'Skicka meddelande',
    sendCommentFailed: 'Misslyckades med att spara kommentar',
    recipient: 'Mottagare',
    unresolved: 'Obekräftade sändningar',
    description: 'Beskrivning',
    pickupAddress: 'Upphämtningsadress',
    numberOfPackages: 'Antal paket',
    registeredPickupAddress: 'Registrerad upphämtningsadress',
    pickupZipCode: 'Postnummer',
    pickupZipArea: 'Stad',
    pickupLocation: 'Plats',
    deliveryAddress: 'Leveransadress',
    deliveryZipCode: 'Postnummer',
    deliveryZipArea: 'Stad',
    deliveryLocation: 'Plats',
    registeredDeliveryAddress: 'Registrerad leveransadress',
    updated: 'Sändningen har uppdaterats',
    moved: 'Sändningen har flyttats',
    unresolvedMessage: 'Det finns {{count}} sändning där vi inte kan hitta en adress.',
    unresolvedMessage_plural: 'Det finns {{count}} sändningar där vi inte kan hitta en adress.',
    foundUnresolvedMessage:
      'Vi har hittat de här giltiga adresserna för den här sändningen. Om de är korrekta, tryck på Uppdatera. Om de inte är korrekta, hitta den giltiga adressen med kartan nedan.',
    remove: 'Ta bort',
    alterSlot: 'Ändra rutt (utan optimering)',
    alterSlotAndReoptimize: 'Ändra rutt (optimera ny rutt i samma ordning)',
    alterSlotNotAvailable: 'Inte tillgängligt under optimering',
    optimizingNewSlot: 'Optimera sändningar i ny rutt',
    selectSlot: 'Välj ny rutt',
    slotSelect: 'Välj rutt',
    confirmSlotChange: 'Bekräfta ruttändring',
    pickupInstructions: 'Upphämtningsinstruktioner',
    deliveryInstructions: 'Leveransinstruktioner',
    specificationDescription: 'Beskrivning',
    tracking: 'Spårning',
    recipientPhone: 'Mottagarens telefonnummer',
    customerRef: 'Kundreferens',
    service: 'Tjänst',
    message: {
      CREATED: 'Skapad',
      ASSIGNED: 'Tilldelad rutt',
      UNASSIGNED: 'Ej tilldelad rutt',
      COLLECTED: 'Upphämtad',
      DELIVERED: 'Levererad',
      DEVIATED: 'Avvik',
      COMMENT: 'Kommentar',
      RESOLVED: 'Bekräftad',
      UNRESOLVED: 'Obekräftad',
      OFFERED: 'Erbjuden',
      REJECTED: 'Nekad',
      ACCEPTED: 'Accepterad',
      RETURNED: 'Returnerad',
      DELETED: 'Borttagen'
    },
    manualOverrideTitle: 'Manuell ändring',
    adjustTabTitle: 'Editera',
    mapPanelTitle: 'Karta',
    recipientName: 'Mottagarens namn',
    receivedBy: 'Mottaget av:',
    manuallyChangeLocations: 'Ändra plats manuellt',
    manualOverrideDeviationTimeConfirm: 'Bekräfta manuell avvikelse för vald sändning',
    manualOverrideDeliveryTimeConfirm: 'Bekräfta manuell leverans för vald sändning',
    onManuallyChangeLocationsConfirm: 'Är du säker på att du vill göra sändningen obekräftad?',
    unresolveDeliveryAddress: 'Gör leveransadressen obekräftad',
    unresolvePickupAddress: 'Gör upphämtningsadressen obekräftad',
    reference: 'Referens',
    shipmentIdNumber: 'Nummer',
    shipmentDetails: 'Sändningsdetaljer',
    customer: 'Kund',
    subcustomer: 'Kundnummer',
    dateAndTime: 'Datum och tid',
    filterCustomer: 'Filtrera kund',
    searchShipmentDateError: 'Fyll i ett datumintervall under 120 dagar.',
    searchPhraseLength: 'Sökfraser bör vara minst {{minLength}} tecken för utökad sökning.',
    moveOrder: 'Flytta sändning',
    moveOrderTitle: 'Flytta sändning till en annan avdelning',
    moveOrderDefault: 'Välj avdelning',
    label: 'Fraktsedel',
    createLabel: 'Skapa fraktsedel',
    createLabelShipment: 'Skapa fraktsedel (Sändning)',
    createMultipleLabel: 'Skapa fraktsedel ({{count}})',
    viewLabel: 'Visa fraktsedel',
    viewLabels: 'Visa fraktsedlar',
    generateLabelErrorTitle: 'Något gick fel',
    generateLabelErrorDescription: 'Beklagar, vi kan inte generera fraktsedlar för tillfället',
    generateLabelErrorButton: 'Se felkod',
    generatingLabels: 'Url:er till PDFer dyker upp nedan alldeles strax',
    labelsGeneratedSuccessfully: 'Alla PDFer är genererade',
    vas: 'Tilläggstjänster (VAS)',
    price: 'Pris',
    orderSources: { glow_customerBooking: 'GLOW Kundbokning', glow_recurring: 'Återkommande' },
    shipmentSyncStatus: { no: 'Nej', yes: 'Ja' },
    unresolvedSuccessfully: 'Sändningen är nu obekräftad.',
    wrongCoordinates: 'Fel koordinater?',
    moveShipmentToUnresolved: 'Flytta sändningen till "Obekräftade" för att rätta koordinaterna.',
    mapUnavailable: 'Kartan visas när den här sändningen är bekräftad.',
    selectOtherActions: 'Välj fler alternativ'
  },
  eventsList: { timeWindow: 'Tidsfönster', from: 'Från', to: 'Till' },
  consignmentEvent: {
    message: {
      comment: 'Kommentar',
      created: 'Skapad',
      completed: 'Avslutad',
      assigned: 'Tilldelad chaufför',
      plannedOnRoute: 'Planerad på rutt',
      plannedOnRouteMoreInfo: 'Planerad på rutt {{routeName}} ({{unitName}})',
      delivered: 'Levererad',
      collected: 'Upphämtad',
      returned: 'Returnerad',
      deviated: 'Avvik',
      damaged: 'Skadad',
      notification: 'Meddelande',
      slotChanged: 'Rutten manuellt ändrad',
      unassigned: 'Borttagen från chafför',
      rejected: 'Nekad',
      image: 'Bild',
      error: 'Fel',
      started: 'Startad',
      stopped: 'Avslutad',
      accepted: 'Accepterad',
      offered: 'Erbjuden',
      unitName: 'Enhet',
      driverName: 'Chaufför',
      'delivery-id-check': 'ID-kontroll',
      'delivered-manually-overridden': 'Leverans manuellt ändrad',
      'deviated-manually-overridden': 'Avvik manuellt ändrad',
      'collected-manually-overridden': 'Upphämtning manuellt ändrad',
      'pickup-location-changed': 'Upphämtningsadress ändrad',
      'delivery-time-estimated': 'Uppdaterad leveranstid',
      'slot-manually-changed': 'Rutt manuellt ändrad',
      'pickup-time-changed': 'Upphämtningstid ändrad',
      'manually-change-location': 'Platser manuellt ändrade',
      'pickup-consignment-data-changed': 'Upphämtningsdetaljer ändrade',
      'delivery-consignment-data-changed': 'Leveransdetaljer ändrade',
      'specification-size-data-changed': 'Antal ändrade',
      'specification-description-data-changed': 'Beskrivning ändrad',
      'delivery-time-purchased': 'Anpassat tidsfönster beställt av mottagaren',
      'digital-identity-verified': 'Mottagarens identitet verifierad digitalt',
      'digital-identity-verification-failed': 'Kunde inte verifiera mottagarens identitet digitalt',
      'flex-delivery-ordered': 'Förenklad leverans beställd av mottagare',
      'flex-delivery-ordered-by-sender': 'Förenklad leverans beställd av avsändare',
      'flex-delivery-changed': 'Förenklad leverans ändrad',
      'flex-delivery-confirmation-sms-sent': 'Flex leverans bekräftelse SMS skickat',
      'return-to-pickup-point-delivery-deviated': 'Kommer att överlämnas till utlämningsställe',
      recipientName: 'Mottagarnamn',
      receivedBy: 'Mottaget av',
      'handed-over-to-other-unit': 'Överlämnat till annan enhet',
      deliveredWithoutSignature: 'Levererat utan signatur',
      deliveredWithoutSignatureReason: {
        left_package_outside: 'Placerat enligt anvisning',
        did_not_get_signature: 'Personligen',
        DELIVER_IN_PERSON: 'Personligen'
      },
      'returned-manually-overridden': 'Retur manuellt ändrad',
      'eta-sms-sent': 'SMS om beräknad tid skickat',
      calculatedEta: 'Beräknad ankomsttid: {{eta}}',
      etaSmsLabel: 'Beräknad ankomsttid',
      scanned: 'Ankommit terminal',
      'arrived-at-terminal': 'Ankommit till distributionsterminal',
      'order-sorted': 'Sorterad',
      'not-arrived-at-distributing-terminal': 'Ej ankommit till distributionsterminal',
      'pickup-eta-sms-sent': 'Pickup ETA SMS skickat',
      'handed-in-at-posten-terminal': 'Ankommit till terminal',
      'pickup-sms-sent': 'Spårnings-SMS skickat',
      'pre-pickup-sms-sent': 'Spårnings-SMS förhandsskickat',
      'pickup-address-unresolved': 'Upphämtningsadressen har gjorts ofullständing',
      'delivery-address-unresolved': 'Leveransadressen har gjorts ofullständing',
      'in-transit': 'Under transport',
      'upcoming-pickup-sms-sent': 'Kommande leverans SMS skickat',
      'order-sorted-same-day': 'Datum satt till idag av terminal appen',
      'signature-sms-sent': 'Signatur-SMS skickat',
      'flex-delivery-cancelled': 'Simplified delivery avbeställd',
      'delivery-time-ordered': 'Leveransdatum ändrat av mottagare',
      'delivery-time-ordered-internal': 'Leveransdatum ändrat',
      'delivery-time-updated-from-hf-sync': 'Leveranstid synkroniserad från HF',
      'planned-delivery-date-ordered': 'Planerat leveransdatum bokat',
      'delay-sms-sent': 'SMS om försening har skickats',
      'manual-delay-sms-sent': 'Manuellt försenat-SMS skickat',
      'route-planning-complete': 'Rute ferdig',
      'signature-delivered': 'Signature från mottagare',
      showPicture: 'Visa bild',
      'external-address-wash': 'Leveransdetaljer ändrade',
      'order-note-changed': 'Anteckning ändrad',
      'order-data-changed': 'Order ändrad',
      'order-moved-to-department': 'Flyttad till en annan avdelning',
      'order-moved-to-department-with-data': 'Sändningen flyttad från',
      'package-measurements-changed': 'Paketstorlek ändrad',
      'driver-deviated-from-route': 'Chaufför avvek från rutten',
      'delivered-outside-service-time-window': 'Leverans eller avvik utanför tjänstens tidsfönster',
      'delivered-outside-estimated-time-window': 'Leverans eller avvik utanför beräknat tidsfönster',
      'seen-by-recipient': 'Mottagaren har sett spårningssidan',
      'first-estimated-times-seen': 'Första estimerade tid sedd av mottagare',
      'pre-advice-push-sms-sent': 'Preadvice PUSH-SMS skickat',
      'pre-advice-push-return-sms-sent': 'Preadvice PUSH-RETURN-SMS skickat',
      'pre-advice-pull-sms-sent': 'Preadvice PULL-SMS skickat',
      'pre-advice-pull-reminder-sms-sent': 'Preadvice PULL påminnelse till mottagare skickat',
      'pre-advice-pup-sms-sent': 'Preadvice PUP-SMS skickat',
      'pre-advice-return-sms-sent': 'Retur aviserings SMS skickat',
      'pre-advice-return-email-sent': 'Retur aviserings email skickat',
      'delivery-location-deviation': 'Leverans eller avvik inte på förväntad plats',
      'pre-advice-pull-reminder-to-sender-email-sent': 'Preadvice PULL påminnelse till avsändare email skickat',
      'pre-advice-pup-reminder-to-sender-email-sent': 'Preadvice PUP påminnelse till avsändare email skickat',
      'pre-advice-return-return-to-sender-email-sent': 'Preadvice RETURN påminnelse till avsändare email skickat',
      'pre-advice-pull-return-to-sender-email-sent': 'Preadvice PULL retur till avsändare email skickat',
      'pre-advice-manual-attempt': 'Manuellt preadvice-forsök',
      'pre-advice-manual-request': 'Manuellt preadvice påbörjat',
      'pre-advice-status-changed': 'Aviseringstatus endret',
      'delivery-sms-sent': 'Leverans SMS skickat',
      'parcelbox-allocated': 'Plats reserverad i paketbox',
      'apartment-information-updated': 'Lägenhetsinformation uppdaterad',
      'claim-registered-email-sent': 'E-post om skadade- eller saknade artiklar sänt',
      'invoicing-failed': 'Fakturering misslyckades',
      'invoiced-ok': 'Fakturering OK',
      serviceUpgradePurchasedDescription: 'Inburen leverans beställd av mottagaren',
      'preliminary-loaded': 'Preliminärt lastad',
      'manual-price-override': 'Priset åsidosatts',
      'incomplete-price-approved': 'Ofullständigt pris godkänt',
      'incomplete-price-unapproved': 'Ofullständigt prisgodkännande återkallat',
      'b2r-return-order-created': 'B2R returorder skapad'
    },
    rejectedReason: {
      reason: 'Anledning',
      private: 'Privata orsaker',
      notEnoughTime: 'Hinner inte med stoppet i tid',
      notEnoughSpaceOnVehicle: 'Sändningen får inte plats',
      other: 'Annat'
    },
    idCheck: {
      idCheckFailed: 'ID-kontroll misslyckades, men levererades med {{idCheckFailedResult}}',
      idCheckSummaryOther: 'Annat ({{otherType}}), födelsedatum {{birthDate}}',
      idCheckSummary: '{{idCheckType}}, födelsedatum {{birthDate}}'
    },
    damaged: {
      damageType: 'Skadetyp',
      causeCode: 'Orsakskod',
      courierInput: 'Meddelande från chaufför',
      showPictureLink: 'Visa bild {{idx}}',
      type: { A: 'Paket/emballageskada', D: 'Tryck/klämskada', E: 'Totalskada' }
    },
    invoiceInitiationType: {
      automatic: 'Schemalagd fakturering',
      'manual-department-level': 'Manuell avdelningsfakturering',
      'manual-order-level': 'Manuell orderfakturering'
    },
    changedFrom: 'Ändrat från -'
  },
  numberSeries: { numberSeries: 'Nummerserie' },
  preadvice: {
    preadvice: 'Avisering',
    hdDepartments: 'HD-avdelningar',
    ceDepartments: 'Budavdelningar',
    departmentGroups: 'Avdelningsgrupper',
    customers: 'Kunder',
    customerGroups: 'Kundgrupper',
    customersCount: 'Antal kunder',
    customerGroupsCount: 'Antal kundgrupper',
    departmentsCount: 'Antal avdelningar',
    departmentGroupsCount: 'Antal avdelingsgrupper',
    locked: 'Avisering låst',
    updated: 'Avisering uppdaterad',
    error: {
      PRE_ADVICE_RULE_COLLISIONS: {
        label: 'Regelkonflikt!',
        detail:
          'Regel för {{service}} vill åsidosätta regelsett {{policySetNames}} genom anknytning där till {{relations}}.',
        department: 'avdelning',
        department_group: 'avdelningsgrupp',
        customer: 'kund',
        customer_group: 'kundgrupp'
      }
    },
    noPoliciesFound: 'Inga regelsett',
    policies: 'Regelsett för automatisk avisering',
    policy: {
      deleteConfirm: 'Är du säker på att du vil avlägsna detta regelsettet?',
      name: 'Namn',
      new: 'Nytt aviseringsrelegelsett',
      edit: 'Endre aviseringsrelegelsett',
      description: 'Beskrivning',
      save: 'Spara',
      saveAnyway: 'Spara endå',
      servicePolicies: 'Aviseringsregler',
      targets: 'Kund-/avdelingsanslutningar',
      servicePolicy: {
        edit: 'Endre regelsett',
        new: 'Lägg till regel för tjänst',
        remove: 'Avlägsna vald',
        serviceCode: 'Tjänst',
        serviceCodeLegend: 'Lägg till tjänst t.ex 3123',
        vasCodeLegend: 'Lägg till tilläggstjänst t.ex SWAP',
        sameDayLegend: 'Ange om du sändingar ska levereras med Same Day Service',
        triggerEvent: 'Avisering på',
        triggerEventLegend:
          'Hva ska trigga at preavisering skickas? När Glow mottagar EDI, när sändingen ankommit HUB eller vid DIP? Om det inte ska triggas, väljer du MANUAL.',
        cutoffLegend:
          'Vad er cutoff-tiden när inte längre erbjuder att leverera första tilgjengliga fönster, utan nesta dag.',
        relativeCutoffMinutes: 'Cutoffminuter',
        relativeCutoffMinutesLegend:
          'Minsta antal minuter från aviseringstiden till nästa tillgängliga tidsfönster. Åsidosätter cutoff.',
        calendarDays: 'Kalenderdagar',
        calendarDaysLegend: 'Hur många dagar ski visas i mottagarens kalender',
        noServicePoliciesFound: 'Inga aviseringsregler',
        returnToSenderAfterDays: 'Retur till avsändare',
        returnToSenderAfterDaysLegend:
          'Efter hur många dagar sk sändingar skickas tilbaka til sendaren, om vi inte lykkas komma i kontakt med mottagar.',
        scheme: 'Typ',
        schemeLegend: 'Vilken typ preavisering ska skickas. Pull, Push, Return eller Pick up parcel.',
        searchCustomer: 'Sök kund',
        remindAfterHours: 'Påminnelse till mottagare',
        remindAfterHoursLegend:
          'Används för Pull och PUP preavisering. Efter hur många timmar ska vi skicka påminnelse til mottagaren för at dom ska booka levering',
        preAdviceAttempts: 'Aviseringsforsök',
        reminderToSenderAfterHours: 'Påminnelse till avsänder',
        reminderToSenderAfterHoursLegend:
          'Påminnelse till avsänder om at ci inte har lyckas komme i kontakt med mottagaren. Sändingen vill bli returnerad.',
        deliveryAttempts: 'Leveransforsök',
        deliveryAttemptsLegend: 'Antal leveransfök innan sändingar returneras tilbaka till avsändar.',
        label: { days: 'Dagar', hours: 'Timmar', smss: 'st.' },
        legend: { days: 'Dagar', hours: 'Timmar', smss: 'Maks antal SMS' },
        returnToPup: 'Retur till pickup point'
      }
    }
  },
  holidays: { holidays: 'Högtider', addHoliday: 'Lägg till ny helgdag', country: 'Land', type: 'Typ', date: 'Datum' },
  department: {
    departmentTitle: 'Avdelning',
    department: 'avdelning',
    department_plural: 'avdelningar',
    departmentWithCount: '{{count}} avdelning',
    departmentWithCount_plural: '{{count}} avdelningar',
    departmentGroups: 'Avdelningsgrupper',
    numberOfDepartmentGroups: 'Antal avdelningsgrupper',
    numberOfDepartments: 'Antal avdelningar',
    search: 'Sök efter avdelningar',
    noDepartmentName: 'Namn saknas',
    noDepartmentId: 'Id saknas',
    noCouriers: 'Inga chaufförer',
    courierCount_plural: '{{count}} chaufförer',
    courierCount: '{{count}} chaufför',
    noCourier: 'Inga chaufförer',
    couriers: 'Antal chaufförer',
    plannerCount_plural: '{{count}} trafikplanerare',
    plannerCount: '{{count}} trafikplanerare',
    noPlanner: 'Inga trafikplanerare',
    planners: 'Antal trafikplanerare',
    choose: 'Välj avdelning',
    new: 'Ny avdelning',
    alystraId: 'Alystra-id',
    description: 'Beskrivning',
    name: 'Namn',
    created: 'Avdelning skapad',
    updated: 'Avdelning uppdaterad',
    departmentType: 'Avdelningstyp',
    notSet: 'Inte valt',
    courier: 'Bud',
    hd: 'Hemleveranser',
    other: 'Övrig',
    all: 'Alla',
    deliveryDisplayType: 'Leveranstidsval',
    terminalName: 'Terminalnamn',
    terminalNameTooltip: 'Fyll i detta fält om hämtnings- och avdelningsnamnet är olika',
    selectArea: 'Välj area...',
    address: 'Adress',
    zipCode: 'Postnr',
    zipArea: 'Stad',
    orderBillingType: {
      title: 'Beställningsfaktureringstyp',
      selectBillingType: 'Välj Faktureringstyp',
      customerBillingNumber: 'Kundens faktureringsnummer',
      addMoreOrderBillingInfo: 'Lägg till faktureringstyp för ny beställning'
    },
    departmentId: 'Avdelnings-Id',
    disableSms: 'Inaktivera spårnings-SMS på rutt',
    smsShouldNotBeSent: 'Spårnings-SMS ska inte skickas till mottagare i rutter producerade på denna avdelning.',
    autoRecurrentTime: 'Automatiskt återkommande tid'
  },
  departmentGroup: {
    departmentGroup: 'Avdelningsgrupp',
    name: 'Namn',
    deleteConfirm: 'Är du säker på att du vill radera den här avdelningsgrupp?',
    edit: 'Ändra avdelningsgrupp'
  },
  menu: {
    defaultDisabled: 'Välj ett kundnummer för att visa det här menyalternativet',
    home: 'Hem',
    planner: 'Trafikplanerare',
    report: 'Rapporter',
    customer: 'Kund',
    apiOrder: 'Sök (GLOW Order)',
    priceMotor: 'Prismotor'
  },
  planner: {
    chooseYourDepartment: 'Välj din avdelning',
    unassignedShipments: 'Oanvisade försändelser',
    filterCustomers: 'Filtrera kunder',
    filterServices: 'Filtrera tjänster',
    autoRoute: 'Automatisk rutt',
    saved: 'Sparad',
    allUserRoles: 'Alla användarroller',
    userRolesCount_plural: '{{count}} användarroller',
    createNewUser: 'Skapa ny användare',
    userRolesCount: '{{count}} användarroll',
    role: 'Roll',
    searchForNameOrNumber: 'Sök efter namn eller telefonnummer',
    noPlannersFound: 'Inga planerare hittades',
    typeOfRole: 'Typ av användarroll',
    selectUserRole: 'Välj användarroll',
    users: 'Användare',
    connectDepartment: 'Koppla avdelning till användare',
    banner: {
      tryNewRoutePlanner:
        "1st of May, <0>route planner 2.0</0> will be the only available route planner. If you haven't used the route planner before, we encourage you to start using it from now. <1>Read more on Zendesk</1> and other related articles to get an overview of how it works."
    },
    totalTitle: 'Kollin',
    pickList: 'Plocklista',
    export: 'Exportera för fakturering',
    deleteSlot: 'Radera',
    editSlot: 'Ändra',
    deleteSlotConfirmDialogText: 'Är du säker på att du vill radera rutten?',
    deleteFilteredSlots: 'Radera alla rutter',
    deleteAllSlotsConfirmDialogText: 'Är du säker på att du vill ta bort alla rutter?',
    created: 'Trafikplanerare skapad',
    updated: 'Trafikplanerare uppdaterad',
    removeDepartment: 'Ta bort avdelning {{name}}',
    connectAirlineTerminal: 'Koppla flygterminal(er) till användare',
    connectedAirlineTerminals: 'Anslutna flygterminaler ({{count}})',
    noConnectedTerminals: 'Inga anslutna terminaler ännu',
    removeTerminal: 'Ta bort terminal {{name}}',
    searchTerminals: 'Sök efter flygterminaler',
    youMustSelectTerminal: 'Du måste välja flygterminal',
    youMustProvideName: 'Du måste ange användarens namn',
    youMustProvidePhonenumber: 'Du måste ange telefonnummer',
    routePlanning: 'Ruttplanering',
    routePlanningConsignmentsAssigned: 'tilldelad',
    routePlanningRangeDescription: 'Filtrera sändningar på leveranstid',
    unresolvedConsignments: 'Obekräftad',
    consignments: 'Sändningar',
    couriers: 'Chaufförer',
    units: 'Enheter',
    invoicing: 'Fakturering',
    planner: 'Trafikplanerare',
    planners: 'Trafikplanerare',
    noPlanners: 'Inga trafikplanerare',
    missingId: 'Id saknas',
    missingName: 'Namn saknas',
    search: 'Sök',
    searchForPlanner: 'Sök efter trafikplanerare',
    name: 'Namn',
    phoneNumber: 'Telefonnummer',
    selectDepartments: 'Välj avdelning',
    createSlot: 'Skapa rutt',
    editSlotForm: 'Ändra rutt',
    slotUpdated: 'Ruttdetaljer uppdaterade',
    createSlotFormName: 'Ruttnamn',
    createSlotFormUnit: 'Enhet',
    createSlotFormCourier: 'Chaufför',
    createSlotFormSlotMode: 'Ruttalternativ',
    createSlotFormSlotModePickup: 'Rita upphämtningsområde på karta',
    createSlotFormSlotModeDelivery: 'Ruta leveransområde på karta',
    createSlotFormSlotModeManual: 'Välj sändningar manuellt',
    createSlotFormSlotModeSelected: 'Välj sändningar',
    createSlotFormTimeRange: 'Tidsintervall',
    createSlotFormStartLocation: 'Startplats',
    createSlotFormEndTime: 'Sluttid',
    createSlotFormEndLocation: 'Slutplats',
    createSlotFormSlotColor: 'Ruttfärg',
    createSlotFormTimeRangeTooBig: 'Tidsintervallet är för stort. Maximalt tidsintervall är {{hours}} timmar.',
    createSlotFormFilterCustomers: 'Filtrera kunder',
    createSlotFormFilterServices: 'Filtrera tjänster (ingen markerad = alla)',
    slotFilterInfo:
      'Den här rutten har filtrerats, vilket betyder att bara sändningar som tillhör de valda kunderna och tjänsterna kan kopplas till rutten.',
    slotFilteredCustomer: 'Rutten innehåller BARA sändningar från följande kunder',
    slotFilteredService: 'Rutten innehåller BARA sändningar från följande tjänster',
    onlyShowApplicable: 'Visa bara sändningar som kan kopplas till den här rutten baserat på kund- och tjänstefilter',
    optimizeButtonLabel: 'Optimera och skapa rutter',
    optimizeSlotButtonLabel: 'Omoptimera rutt',
    reversedOptimizeSlotButtonLabel: 'Omvänd leveransordning',
    optimizingSince: 'Optimering pågår, startad {{startTime}}',
    alterSlotIdOnConsignment: 'Är du säker på att du vill ändra rutt för den här sändningen?',
    addToRoute: 'Lägg till rutt...',
    pickup: 'Upphämtning',
    delivery: 'Leverans',
    slot: 'Rutt',
    notAssigned: 'Inte tilldelad',
    readOnly: 'Skrivskyddat (kan inte ändra något)',
    plus: 'Utökade rättigheter',
    readOnlyAccessPlannersWithCount: '{{count}} trafikplanerare med endast läsrättigheter',
    plusPlannersWithCount: '{{count}} trafikplanerare med utökade rättigheter',
    normalPlannersWithCount: '{{count}} trafikplanerare med normala rättigheter',
    saveTemplate: 'Spara som mall',
    loadTemplate: 'Ladda från mall',
    routeListForFilteredSlots: 'Kvittenslista (skriv ut alla)',
    loadListForFilteredSlots: 'Lastlista (skriv ut alla)',
    sendPrePickupSmsesToAll: 'Förhandsskicka spårningssms',
    alertBeforeSendingPrePickupSms: 'Är du säker på att du vill förhandsskicka spårnings-SMS för alla rutter för idag?',
    createTemplateFormName: 'Namn på mall',
    createOrUpdateTemplateDescription:
      'För att spara så måste du antingen ange ett unikt namn eller välja en befintlig mall att skriva över',
    newTemplate: 'Skapa ett ny mall',
    overwriteTemplate: 'Skriv över mall',
    templateSaved: 'Mallen har sparats',
    updateTemplateDescription: 'Välj en befintlig mall att uppdatera',
    updateTemplateConfirm: 'Är du säker på att du vill uppdatera den här mallen?',
    selectOrDeleteTemplateDescription: 'Välj en mall för att applicera eller radera',
    selectTemplate: 'Välj mall',
    deleteTemplate: 'Radera',
    selectTemplateDescription: 'Välj mall:',
    templateSelected: 'Mallen har applicerats',
    templateDeleted: 'Mallen har raderats',
    templateDeleteConfirm: 'Är du säker på att du vill radera mallen?',
    missingDeliveryMarker: 'Inte en giltig leveransplats',
    missingPickupMarker: 'Inte en giltig upphämtningsplats',
    unassignConsignmentFromSlot: 'Ta bort sändning från rutt',
    unassignBulkConsignmentsFromSlotsAlert: 'Ta bort försändelser från respektive rutt?',
    unassignedFromSlot: 'Sändningen har tagits bort från rutten',
    unassignBulkConsignmentsFromSlots: 'Sändningarna är nu borttagna från respektive rutt',
    unassignTitle: 'Ta bort den här sändningen från rutten',
    unassignButton: 'Ta bort från rutt',
    forceUnassignButton: 'Tvingad borttagng från rutt',
    moveOrder: 'Är du säker på att du vill flytta den här sändningen?',
    driverName: 'Chaufför',
    filters: {
      assigned: 'Tilldelade',
      unassigned: 'Inte tilldelade',
      pickups: 'Upphämtningar',
      deliveries: 'Leveranser'
    },
    saveSelected: 'Spara utvalda ({{count}})',
    showSaved: 'Visa sparade ({{count}})',
    showAll: 'Visa alla',
    clearSaved: 'Rensa sparade',
    sendPrePickupSms: 'Förhandsskicka Spårnings-SMS till mottagare',
    sendSms: 'Skicka SMS',
    sendingSmsText: 'Skickar {{count}} spårnings-SMS',
    prePickupSmsSent: '{{sentCount}} av {{totalCount}} SMS förhandsskickade',
    manualDelaySms: {
      smsSent: '{{count}} manuella försenat-SMS skickade',
      heading: 'Manuella försenat-SMS',
      sendingSmsText: 'Skickar {{count}} manuella försenat-SMS',
      selectSmsDescription:
        'SMS kommer börja med "Hej, din leverans från Avsändare är tyvärr försenad". Välj avslut på SMS:et nedan.',
      selectSmsEnding: 'Välj SMS-avslut',
      buttonTitle: 'Skicka',
      endingType: {
        none: 'Ingen',
        soon: '"Vi försöker levererar så snabbt som möjligt."',
        tomorrow: '"Vi försöker levererar nästa dag."'
      },
      disabledTooltip: 'Sändning kan ej vara i status levererad'
    },
    h2: { booking: 'H2 Bokning', vasHelperText: 'Använd VAS 1158 för MBH' },
    loadList: {
      header: 'Ruttöversigt',
      listName: 'Lastlista',
      unknownRouteName: 'Okänt Ruttnamn',
      unknownUnitName: 'Okänt enhetsnamn',
      noDataText: 'Inget att se här'
    },
    locationList: { listName: 'Lokationslista' },
    assignedUnits: 'Tilldelade enheter',
    unassignedUnits: 'Ej tilldelade enheter',
    noUnitSelected: 'Ingen enhet har valts',
    unitAssignmentCount: 'Enhet tilldelad till {{count}} rutter'
  },
  plannerDashboard: {
    vehicleTypes: { car: 'Bil', bicycle: 'Cykel', truck: 'Lastbil', motorcycle: 'Motorcykel', cargobike: 'Cargocykel' }
  },
  updateServiceLevel: {
    menuItem: 'Ändra servicenivå',
    selectOption: 'Välj servicenivå',
    heading: 'Ändra servicenivå',
    confirmation: 'Servivenivå uppdaterad',
    subtext: 'Ändra servicenivå för {{count}} sändning',
    subtext_plural: 'Ändra servicenivå för {{count}} sändningar',
    failed: {
      title: 'Misslyckades med att ändra servicenivån',
      message: 'Misslyckades med att ändra servicenivå för {{count}} sändning',
      message_plural: 'Misslyckades med att ändra servicenivå för {{count}} sändningar'
    }
  },
  manualPreAdvice: {
    menuItem: 'Manuel preadvisering',
    selectOption: 'Välj preadviseringstyp',
    heading: 'Manuel preadvisering',
    confirmation: 'Manuel(la) preadvisering(ar) lagrat',
    subtext: 'Manuel preadvisering för {{count}} sändning',
    subtext_plural: 'Manuel preadvisering för {{count}} sändningar',
    failed: {
      title: 'Lagrande av preadvisering(ar) Misslyckades',
      message: 'Lagrande av preadvisering misslyckades för {{count}} sändning',
      message_plural: 'Lagrande av preadvisering misslyckades för {{count}} sändningar'
    }
  },
  setPickupDeliveryDuration: {
    menuItem: 'Ändra stopptid vid upphämtning och leverans',
    heading: 'Ändra stopptid vid upphämtning och leverans',
    subtext: 'Ändrar stopptid vid upphämtning och leverans för {{count}} sändning',
    subtext_plural: 'Ändrar stopptid vid upphämtning och leverans för {{count}} sändningar',
    pickupDuration: 'Stopptid vid upphämtning',
    deliveryDuration: 'Stopptid vid leverans',
    confirmation: 'Stopptiderna uppdaterade',
    tooltipTitle: 'När du fyller i tiden är max antal minuter och sekunder 59'
  },
  importExportOrders: {
    importSuccess: 'Alla sändningar i Excel-arket har ändrats eller uppdaterats i systemet',
    importSuccessTitle: 'Lyckat',
    importOrdersHeading: 'Importera ordrar',
    importStatus: 'Det finns för närvarande {{imports}} importer i kö med totalt {{orders}} ordrar',
    exportForInvoice: 'Exportera för fakturering',
    fileTemplateLink: 'Ladda hem Excel-mall',
    uploadFile: 'Importera ordrar',
    uploadingMessage: 'Ladda upp filen och importera sändningarna...',
    needToSelectFile: 'Du måste välja en fil',
    exportSuccess: 'Alla sändningar inom valt datumintervall har laddats ned till Excel-arket',
    exportSuccessTitle: 'Lyckat',
    testDataWarning: 'Använd inte persondata i testmiljön',
    testDataInfo: 'Det här är en testmiljö, och du får inte använda persondata från kunder utan deras tillåtelse.',
    fromDate: 'Från datum',
    toDate: 'Till datum',
    fromDateNotAfterToDate: "Kan inte vara senare än 'Till datum'",
    toDateNotBeforeFromDate: "Kan inte vara tidigare än 'Från datum'",
    removeControlColumns: 'Ta bort kontrollkolumner',
    importResults: 'Importresultat',
    fileName: 'Filnamn',
    processedAt: 'Behandlad tidpunkt',
    createdShipmentId: 'Skapad sändningsnummer',
    date: 'Datum',
    exportShipments: 'Exportera sändningar',
    createLabels: 'Skapa fraktsedlar',
    selectSlot: 'Välj rutt',
    all: 'Alla',
    types: {
      SHIPMENT_EXPORT: 'Exportera sändningar',
      AIREXPRESS_SHIPMENTS: 'Exportera sändningar (med AirExpress-data)',
      SOCIAL_CONTROL: 'Social kontrolldata',
      LOCATION_LIST: 'Platslista',
      ORDER_DEVIATION_EXPORT: 'Ordrar (Avvikta)',
      ORDER_EXPORT_ANONYMOUS: 'Ordrar (Anonymiserad data)',
      ORDER_PARTNER_EXPORT: 'Orders (För partners)',
      DEVIATION_EVENTS: 'Avvikelsehändelser'
    },
    exportTitles: {
      SHIPMENT_EXPORT: 'Exportera sändningar',
      AIREXPRESS_SHIPMENTS: 'Exportera sändningar (med AirExpress-data)',
      SOCIAL_CONTROL: 'Exportera sociala kontrolldata',
      LOCATION_LIST: 'Exportera platslista',
      ORDER_EXPORT: 'Exportera Ordrar',
      ORDER_DEVIATION_EXPORT: 'Exportera ordrar',
      ORDER_EXPORT_ANONYMOUS: 'Exportera ordrar',
      ORDER_PARTNER_EXPORT: 'Exportera ordrar',
      DEVIATION_EVENTS: 'Exportera avvikelsehändelser'
    },
    exportFailed: 'Misslyckades med att exportera data',
    selectExportLabel: 'Välj vad du vill exportera',
    allOrders: 'Alla Ordrar',
    deviatedOrders: 'Avvikta Ordrar',
    noSelection: 'Inget val',
    selectFile: 'Välj fil',
    noFileSelected: 'Ingen fil',
    updateSelectedFile: 'Uppdatera fil',
    jobList: {
      title: 'Tidigare exporter',
      content: 'Innehåll',
      created: 'Skapad',
      user: 'Användare',
      dates: 'Datum',
      download: 'Ladda ner',
      exporting: 'Exporterar...',
      inQueue: 'I exportkö',
      exportFailed: 'Exporten misslyckades',
      updated: 'Uppdaterad',
      previousImports: 'Tidigare importer',
      fileName: 'Filnamn',
      numberOfOrders: 'Antal order',
      imported: 'Importerad',
      viewOrders: 'Visa order',
      lastImports: 'Visar de senaste {{count}} importerna'
    },
    exportStartedNotification: 'Export startad',
    exportStartedNotificationMessage: 'Din rapport kommer att vara tillgänglig så snart den har behandlats.',
    exportFailedNotification: 'Exporten misslyckades, försök igen senare',
    refreshData: 'Uppdatera data i befintlig rapport',
    refreshDataDescription:
      'Välj det här alternativet om du redan har skapat en befintlig rapport för de specifika datumen',
    exportDescriptions: {
      AIREXPRESS_SHIPMENTS: 'Inkluderar AirExpress data, utöver vanlig leveransdata',
      ORDER_EXPORT:
        'Om du vill använda datan i en test-miljö rekommenderar vi att använda valet "Ordrar (Anonymiserad data)',
      ORDER_DEVIATION_EXPORT:
        'Om du vill använda datan i en test-miljö rekommenderar vi att använda valet "Ordrar (Anonymiserad data)',
      ORDER_EXPORT_ANONYMOUS: 'Kan användas om datan ska importeras i en test-miljö.',
      ORDER_PARTNER_EXPORT: 'Anpassad excel-fil som kan användas för att importeras i Opter.'
    }
  },
  unit: {
    unitWithCount: '{{count}} enhet',
    unitWithCount_plural: '{{count}} enheter',
    new: 'Ny enhet',
    edit: 'Redigera enhet',
    addCourier: 'Lägg till chaufför',
    hideAddCourier: 'Dölj lägg till chaufför',
    requiresCourierUsers: 'Må ha minst én sjåfør',
    created: 'Enheten har skapats',
    updated: 'Enheten har uppdaterats',
    search: 'Sök efter enheter från andra avdelningar...',
    unitsInCurrentDepartment: 'Enheter i nuvarande avdelning',
    unitsInOtherDepartments: 'Enheter i andra avdelningar',
    saveUnit: 'Spara enhet',
    addCourierToUnit: 'Lägg till chaufför til enheten'
  },
  courier: {
    isActive: 'Är aktiv',
    canLogIn: 'Kan logga in',
    secureLoginEnabled: 'BankID-inloggning',
    recipientName: 'Mottagarens namn',
    created: 'Chauffören har skapats',
    updated: 'Chauffören har uppdaterats',
    courier: 'Chaufför',
    couriers: 'Chaufförer',
    noCouriers: 'Inga chaufförer',
    courierWithCount: '{{count}} chaufför',
    courierWithCount_plural: '{{count}} chaufförer',
    numberOfCouriers: 'Hittade {{number}} chaufförer',
    multipleDrivers: 'Flera chaufförer',
    missingId: 'Saknar id',
    missingName: 'Saknar namn',
    search: 'Sök efter chaufförer',
    new: 'Ny chaufför',
    edit: 'Redigera chaufför',
    name: 'Namn',
    phoneNumber: 'Telefon',
    driverId: 'Chaufför-ID',
    capacity: 'Kapacitet',
    weightCapacity: 'Viktkapacitet (kg)',
    volumeCapacity: 'Volymkapacitet (m³)',
    maxRangeKm: 'Räckvidd (km)',
    color: 'Färg',
    resetColor: 'återställ färg',
    deviateTimeWindow: 'Hann inte leverera innanför tidsfönstret',
    addressNotFound: 'Adress hittades inte',
    deliverySpotUnavailable: 'Överenskommen leveransplats är inte tillgänglig',
    deviation: {
      '7-10': 'Godset kunde inte hämtas upp på grund av ofullständig adress',
      '7-12': 'Avsändaren var inte tillgänglig. Godset har inte hämtats upp',
      '7-82': 'Godset var inte redo för upphämtning',
      'H-10': 'Ett försök har gjorts att leverera paketet, men adressen finns inte',
      'H-11': 'Ett försök har gjorts att leverera paketet, men mottagaren fanns inte på adressen',
      'H-12': 'Ett försök har gjorts att leverera sändningen, men mottagaren var inte på plats',
      'H-13': 'Ett försök har gjorts att leverera paketet, men paketet nekades av mottagaren',
      'H-07-R': 'Misslyckad social kontroll',
      'H-07-Z': 'Misslyckad social kontroll',
      'H-08-Z': 'Misslyckad ålderskontroll',
      'H-09-Z': 'Misslyckad ID kontroll',
      'VI-16-X': 'Hann inte leverera innanför tidsfönstret',
      'V-41-K': 'Sändningen är försenad och kommer att skickas så snart som möjligt',
      'H-11-Z': 'Adress hittades inte',
      'H-BL-A': 'Överenskommen leveransplats är inte tillgänglig',
      'V-100-CA': 'Ingen paketbox tillgänglig. Paketet levereras som hemleverans',
      'V-100-CI': 'Ingen tillgänglig lucka',
      'V-80-CI': 'Paketet överskrider tillåten mått eller vikt',
      'V-99-CI': 'Annan okänd orsak',
      'V-AO-CI': 'Maskinen är ur funktion'
    },
    fuelType: 'Bränsletyp',
    fuelTypes: { gasoline: 'Bensin', natural_gas: 'Naturgas', ethanol: 'Etanol', electric: 'El', bicycle: 'Cykel' },
    fuelCategory: 'Bränslekategori',
    fuelCategories: { fossil_free: 'Fossilfri', zero_emissions: 'Utsläppsfritt' },
    latestLogin: 'Senaste inloggning',
    latestVersion: 'Senaste version',
    failedSocialControl: 'Misslyckad social kontroll',
    failedIdControl: 'Misslyckad ID kontroll',
    failedAgeControl: 'Misslyckad ålderskontroll',
    registrationNumber: 'Registreringsnummer',
    commercialTrafficEligibility: 'Kommersiell trafikk berettigelse',
    vehicleBrand: 'Fordonsmärke',
    vehicleModel: 'Fordonsmodell',
    vehicleControl: 'Fordonskontroll',
    vehicleControlComment: 'Kommentar gällande fordonskontroll',
    euroClass: 'Euro-klass',
    alcoholDeliveryEducation: 'Alkoholleveransutbildning',
    approved: 'Godkänd',
    notApproved: 'Ej godkänd',
    couriersInCurrentDepartment: 'Chaufförer I nuvarande avdelning',
    editCourier: 'Ändra chaufför:',
    courierUserUpdated: 'Chauffören har uppdaterats.',
    courierScanEnabled: 'Skanna',
    breakDuration: 'Pausens längd (min)',
    timeWindow: 'Pausens start (tidigast och senast)',
    breakTimeDescription:
      'I fältet <i>Pausens start (tidigast och senast)</i> så väljer du vilken tid som pausen tidigast och senast kan starta. T.ex. 11.00-17.00 innebär att den senaste tiden som chauffören kan ta en paus är vid kl. 17.00 '
  },
  slot: {
    minutesDelay: 'minuter försenad',
    minutesAhead: 'minuter för tidig',
    minutes: 'minuter',
    time: 'Tid',
    created: 'Skapad',
    assigned: 'Tilldelad',
    lock: 'Lås',
    lockDescription:
      'När rutten är låst så kommer den inte bli en del av ny ruttoptimering och den kan inte bli ändrad.',
    toggleVisibleSlotDescription: 'Dölj rutten.',
    inProgress: 'Pågår',
    onHold: 'Pausad',
    complete: 'Avslutad',
    unknownState: 'Okänd status',
    courierSelect: 'Välj chaufför',
    unitSelect: 'Välj enhet',
    printableSlotList: 'Utskrivsvänlig ruttlista',
    followsRoute: 'Följer',
    doesNotFollowRoute: 'Avviker',
    hasLocation: 'Synlig',
    hasNoLocation: 'Osynlig',
    showActiveSlots: 'Visa pågående',
    showSlotNotStarted: 'Visa ej startade',
    showCompletedSlot: 'Visa avslutade',
    showPerfectSlot: 'Visa perfekta',
    filterSlots: 'Filtrera rutter',
    planningCompleted: 'Färdiga',
    addMapArea: 'Välj område på karta',
    editMapArea: 'Ändra område på karta'
  },
  login: {
    phone: 'Skriv in det telefonnummer du är registrerad med i GLOW med landskod (+46 ...), så skickar vi en SMS-kod',
    code: 'SMS-kod skickad, om du har en användare med det angivna telefonnumret. Koden är giltig i 5 minuter. Ange koden.',
    requestNewCode: 'Begär en ny kod',
    verifyFailed:
      'Koden eller telefonnumret är felaktigt, eller så har för många försök gjorts för att logga in, försök igen eller begär en ny kod.',
    requestFailed: 'Kunde inte skicka kod, försök igen..',
    verifyTokenButton: 'Verifiera kod',
    requestTokenButton: 'Skicka kod',
    passwordWrong: 'Felaktigt lösenord',
    customer: 'Jag är en kund',
    forgotPassword: 'Glömt ditt lösenord?',
    tryApp: 'Prova den nya appen!',
    appDownload: 'Ladda hem den nya chaufförsappen här och få tillgång till ännnu fler funktioner!',
    appInfo:
      'Är du en chaufför med Android-telefon? Ladda hem chaufförsappen <1>här</1> och få tillgång till ännu fler funktioner!',
    webApp: 'Webapp',
    note: 'Notera! Använd bara webbversionen om du av någon anledning inte kan använda apparna för iOS och Android',
    resendToken: 'Skicka kod igen',
    heading: 'Logga in',
    privacyLink: 'Integritetspolicy',
    submitPassword: 'Skicka lösenord',
    loginAsCustomer: 'Logga in som kund',
    loginAsPlanner: 'Logga in som planerare ',
    token: 'Kod',
    password: 'Lösenord',
    tokenRequired: 'Kod är obligatorisk',
    forgotPasswordDescription:
      'För att fortstätta så behöver du ange ett lösenord. Vi kommer att skicka ett mail till dig med en länk för att ställa in detta. Om du har en annan mail än den registrerade i Glow så behöver du kontakta en administratör för att ändra den.',
    sendEmailDescription:
      'För att fortsätta behöver vi din mail. Vi skickar en bekräftelselänk till den angivna adressen. Med den här länken kan du ställa in ditt tvåfaktorslösenord.',
    emailFormatValidation: 'Mailaddressen behöver vara i ett korrekt format',
    sendEmailSuccess: 'Mailet kommer till din inkorg inom kort',
    sendEmailFailed: 'Det gick inte att skicka mail, försök igen. ',
    setYourPassword: 'Ställ in ditt lösenord',
    sendEmail: 'Skicka mail',
    passwordsDontMatch: 'Lösenorden matchar inte',
    passwordNotMeetRequirements: 'Lösenordet uppfyller inte kraven',
    passwordRequired: 'Lösenord är obligatoriskt',
    newPassword: 'Nytt lösenord',
    repeatPassword: 'Upprepa ditt lösenord',
    lowercaseLetter: 'En liten bokstav (gemen)',
    uppercaseLetter: 'En stor bokstav (versal) ',
    numberCharacter: 'En siffra',
    minimumCharacters: 'Minst 15 tecken ',
    passwordDescription: 'Du kan nu ange ditt lösenord. Lösenordet behöver innehålla följande'
  },
  error: {
    '404Message': "We couldn't find the page you're looking for",
    dateRequiredWhenSecureLogin: '*Obligatoriskt fält om BankID är aktiverat',
    phoneNumber: 'Du måste ange ett giltigt telefonnummer, t.ex. +47 12345678',
    notAValidAlphaNumeric: 'Måste vara siffror och/eller bokstäver',
    notAValidDuration: 'Måste vara en giltig tid (mm:ss)',
    somethingWentWrongOnTheServer: 'Något gick fel på servern...',
    somethingWentWrongWithTheRequest: 'Något gick fel med frågan till servern...',
    somethingWentWrongWithTheResponse: 'Något gick fel med svaret från servern...',
    selectAtLeastOne: 'Du måste välja minst ett av alternativen',
    requiredBreakDuration: 'Du måste ange pausen längd',
    notAValidTimeWindow: 'Den tidigaste paustiden behöver vara innan den senaste',
    timeInWrongFormat: 'Tiden är inte giltig'
  },
  loading: 'Vi laddar din information...',
  newAppVersion: 'GLOW blev precis lite bättre, vi laddar nu den senaste versionen.',
  loadingReceiptList: 'Kvittenslista genereras…',
  invoicing: { departmentInvoicingInProgress: 'Fakturering pågår...' },
  months: {
    january: 'Januari',
    february: 'Februari',
    march: 'Mars',
    may: 'Maj',
    june: 'Juni',
    july: 'Juli',
    august: 'Augusti',
    october: 'Oktober'
  },
  weekdays: {
    monday: 'Måndag',
    tuesday: 'Tisdag',
    wednesday: 'Onsdag',
    thursday: 'Torsdag',
    friday: 'Fredag',
    saturday: 'Lördag',
    sunday: 'Söndag'
  },
  weekdaysShort: {
    sunday: 'Sön',
    monday: 'Mån',
    tuesday: 'Tis',
    wednesday: 'Ons',
    thursday: 'Tor',
    friday: 'Fre',
    saturday: 'Lör'
  },
  maps: { addressLookup: 'Adresskoll' },
  notifications: {
    dismiss: 'Stäng',
    dismissAll: 'Stäng alla',
    error: 'Fel',
    reload: 'Ladda om sidan',
    reloadCourier: 'Klicka här för att ladda om sidan',
    navigate: 'Gå till sidan',
    networkError: 'Det verkar vara nätverksproblem (som kan vara tillfälliga), men sidan borde laddas om.',
    networkErrorCourier: 'Du har varit offline, ladda om sidan.'
  },
  modals: {
    preplan: {
      create: 'Förplanera sändning',
      change: 'Ändra förplanerad chaufför',
      shipmentInfo: 'Förplanera sändning {{shipmentId}} ({{pickupName}} till {{deliveryName}})',
      pickDriver: 'Välj en chaufför..',
      unpreplan: 'Ta bort planering',
      preplan: 'Förplanering',
      error: 'Sändningen kunde inte förplaneras',
      notification: {
        preplanned: 'Förplanerad',
        unpreplanned: 'Förplanering borttagen',
        preplannedMessage: 'Sändningen förplanerades till {{courierId}}',
        unpreplannedMessage: 'Förplaneringen togs bort'
      }
    },
    maps: {
      save: 'Spara',
      cancel: 'Ångra',
      delete: 'Radera',
      editMode: 'Ändra',
      mapArea: '{{routeName}} kartområde',
      saved: 'Sparat',
      deleted: 'Raderad'
    }
  },
  grid: {
    columns: {
      department: 'Avdelning',
      failedReason: 'Felmeddelande',
      exceptions: 'Uppehåll',
      name: 'Namn',
      senderName: 'Avsändarens namn',
      delearly: 'LEV',
      dellate: 'LEV',
      zip: 'Postnr',
      type: 'Typ',
      estimated: 'Beräknad tid',
      timeWindow: 'Tidsfönster',
      onCar: 'på enhet',
      frequency: 'Frekvens',
      startDate: 'Startdatum',
      endDate: 'Slutdatum (valfritt)',
      from: 'Från',
      to: 'Till',
      days: 'Dagar',
      holidays: 'Helger',
      startdate: 'Startdatum',
      enddate: 'Slutdatum',
      orders: 'Ordrar',
      id: 'ID',
      shipmentId: 'Sändningsnr',
      packageId: 'Kollinummer',
      errorMessage: 'Felmeddelande',
      customer: 'Kund',
      pickup: 'Upphämtning',
      delivery: 'Leverans',
      service: 'Tjänst',
      address: 'Adress',
      zipcode: 'Postnr',
      area: 'Stad',
      weight: 'Vikt',
      volume: 'Volym{{unit}}',
      qty: 'Ant',
      preplanned: 'Förplanerad',
      finished: 'Färdiga',
      failed: 'Misslyckade',
      pickupContactPerson: 'Upphämtning kontaktperson',
      pickupPhone: 'Upphämtning telefon',
      deliveryContactPerson: 'Leverans kontaktperson',
      deliveryPhone: 'Leverans telefon',
      deliveryAddress: 'Leveransadress',
      deliveryTime: 'Leveranstid',
      contactPerson: 'Kontaktperson',
      phone: 'Telefon',
      deliveryReferencePlaceholder: 'Mottagarreferens',
      specificationDescriptionPlaceholder: 'Godsinformation',
      groupName: 'Gruppnamn',
      customerNumber: 'Kundnummer',
      orderNote: 'Anteckning',
      returned: 'Returnerat',
      price: 'Pris',
      slotName: 'Rutt',
      deliveryEtaSmsSentAt: 'Levererings ETA SMS skickat',
      trip: 'Resa',
      addresses: {
        title: 'Adresser',
        search: 'Adresshantering',
        searchAddresses: 'Sök efter adresser',
        filterAddresses: 'Filtrera adresser',
        deleteConfirmation: 'Är du säker på att du vill radera den här adressen?',
        deleteMultipleConfirmation: 'Är du säker på att du vill radera alla valda adresser?',
        deleteSuccess: 'Adress raderad',
        deleteMultipleSuccess: 'Adresser raderade',
        delete: 'Radera',
        name: 'Namn',
        phone: 'Telefonnummer',
        address: 'Adress',
        zipArea: 'Postort',
        zipCode: 'Postnummer',
        country: 'Land',
        contactPerson: 'Kontaktperson',
        instructions: 'Instruktioner',
        deleteAddress: 'Radera adressen',
        deleteMultipleAddresses: 'Radera adresser ({{count}})',
        loc: 'Plats',
        locTitle: 'Visa på kartan'
      },
      orderGrid: { vehicleType: 'Fordonstyp' },
      dispatch: {
        id: 'ID',
        address: 'Adress',
        adjusted: 'Justerad',
        alreadyAssigned: '<< Allerede tildelt',
        customerName: 'Kund',
        service: 'Tjänst',
        name: 'Namn',
        timeWindow: 'Tidsfönster',
        type: 'Typ'
      },
      orderSource: 'Orderkälla',
      postalCodes: '# postnummer',
      displayEndCustomerPriceShd: 'Visa kundpris',
      courier: 'Chaufför',
      createdBy: 'Skapad av',
      createdAt: 'Skapad',
      plannedDeliveryDate: 'Planerat datum',
      orderedDeliveryDate: 'Beställt datum',
      lastUpdatedAt: 'Senast uppdaterad',
      route: 'Rutt',
      instructions: 'Instruktioner',
      pickupDuration: 'PU stopptid',
      deliveryDuration: 'LEV stopptid',
      originalPickupAddress: 'Ursprunglig avsändaraddress',
      originalPickupZipCode: 'Ursprungligt avsändande postnummer',
      originalPickupZipArea: 'Ursprunglig avsändarort',
      originalPickupCountry: 'Ursprungligt avsändarland',
      originalDeliveryAddress: 'Ursprunglig leveransaddress',
      originalDeliveryZipCode: 'Ursprunglig leveranspostnummer',
      originalDeliveryZipArea: 'Ursprunglig leveransort',
      originalDeliveryCountry: 'Ursprungligt leveransland',
      packagesArrivedAtDip: 'Ankommit',
      packagesCollected: 'Upphämtad',
      packagesDelivered: 'Levererad',
      originalDeliveryEarliest: 'Urspringlig LEV',
      originalDeliveryLatest: 'Urspringlig LEV',
      preAdvices: 'Preavisering',
      preAdviceScheduledDate: 'Preavisering utsendt',
      hasPreAdviceSettings: 'Preaviseringregler',
      arrivedAtHub: 'Ankommit HUB',
      returnToSender: 'Retur till avsänder',
      preAdviceFailed: 'Misslyckades',
      deliveryAttempts: 'Leveransforsök'
    },
    columnSettings: {
      header: 'Lägg till kolumner i tabellen',
      description: 'Välj den data du vill ska synas i tabellen'
    }
  },
  openBooking: {
    pickupReferencePlaceholder: 'Kundreferens - visas på fakturan',
    customerContactPlaceholder: '$t(instant.booking.customerContact) – visas på fakturan',
    invoiceInfoPlaceholder: 'Fakturainformation - visas på fakturan',
    pickupTimePlaceholder: 'Tidigaste upphämtningstid'
  },
  instant: {
    title: 'Trafikledning',
    openMap: 'Visa karta',
    group: 'Gruppera valda',
    editGroup: 'Redigera grupp',
    groupName: 'Gruppnamn',
    groupOrders: 'Gruppera ordrar',
    viewGroup: 'Visa grupp',
    ordersToGroup: 'Ordrar i gruppen',
    fixedOrder: 'Fast ordningsföljd',
    paidTogether: 'Betalas tillsammans',
    saveGroup: 'Spara grupp',
    ungroup: 'Avgruppera',
    removeSelected: 'Ta bort valda',
    noCourierSelected: 'Inga chaufförer valda',
    noOrderSelected: 'Inga sändningar valda',
    workingHours: 'Arbetstider',
    chatButton: 'Chatta med chaufför',
    offeredShipments: 'Erbjudna sändningar',
    rejectedShipments: 'Nekade sändningar',
    rejectedShipmentsSnackbar: '{{count}} nekad sändning av "{{name}}"',
    rejectedShipmentsSnackbar_plural: '{{count}} nekade sändningar av "{{name}}"',
    toBeDone: 'Upphämtning och leveranser som ska utföras',
    finishedRoutePoints: 'Genomförda upphämtningar och leveranser',
    suggestedRouteApplied: 'Den föreslagna rutten har applicerats',
    suggestedRouteCancelled: 'Den föreslagna rutten har tagits bort',
    reorderValidationError: 'Den föreslagna ordningen på rutten är inte giltig',
    planSlot: 'Ruttplanera',
    columnSettings: {
      reset: 'Återställ',
      settings: 'Inställningar',
      hideColumns: 'Visa kolumner',
      saveAndClose: 'Spara och stäng'
    },
    routePoint: { pickup: 'U', delivery: 'L' },
    confirmation: {
      heading: 'Orderbekräftelse',
      from: 'Från',
      to: 'Till',
      service: 'Tjänst',
      pickup: 'Upphämtning',
      delivery: 'Leverans',
      shipmentNumber: 'Sändningsnummer',
      printLabel: 'Skriv ut fraktsedel',
      copyOrder: 'Kopiera sändning',
      editOrder: 'Ändra sändning'
    },
    goods: {
      custom: 'Egen',
      letter: 'Brev',
      smallbox: 'Liten låda',
      mediumbox: 'Mellanlåda',
      largebox: 'Stor låda',
      tube: 'Rör',
      quarterpallet: 'Kvartspall',
      halfpallet: 'Halvpall',
      fullpallet: 'Helpall',
      suitcase: 'Resväska',
      movingbox: 'Flyttkartong',
      trolley: 'Lastbärare',
      parcel: 'Löskolli'
    },
    instantGrid: {
      editColumnTooltip: 'Klicka för att ändra',
      deleteColumnTooltip: 'Klicka för att ta bort sändningen från enheten',
      unassignColumnTooltip: 'Klicka för att ta bort sändningen från enheten',
      dragColumnTooltip: 'Klicka och dra för att tilldela till chaufför',
      filtersActive: 'Aktiva filter',
      clearFilters: 'Ta bort alla filter'
    },
    booking: {
      addPackage: 'Lägg till nytt kolli',
      removePackageRow: 'Ta bort kolli',
      cannotRemoveLastPackageRow: 'Kan inte ta bort sista kollit',
      copyPackageRow: 'Kopiera kolli',
      title: 'Bokning',
      header: 'Bokning',
      headerCreate: 'Ny bokning',
      headerEdit: 'Ändra bokning',
      headerCopy: 'Kopiera bokning',
      headerRestore: 'Återställ bokning',
      customer: 'Kund',
      customerPlaceholder: 'Kundnummer',
      invalidSubcustomer: 'Subcustomer-namnet är ogiltigt',
      shipmentOrPackageId: 'Sändningsnummer eller kollinummer',
      creditBlockedCustomer: 'Kreditspärrad kund',
      sender: 'Avsändare',
      setAsDefaultAddress: 'Sätt som standardavsändare',
      setAsDefaultDisabledText: 'Koordinater saknas. Sätt adressen genom att använda adresssöket.',
      defaultAddressUpdated: 'Standardavsändare uppdaterad',
      recipient: 'Mottagare',
      pickupNamePlaceholder: 'Namn',
      pickupAddressPlaceholder: 'Adress',
      pickupZipCodePlaceholder: 'Postnummer',
      pickupZipAreaPlaceholder: 'Stad',
      pickupCountryPlaceholder: '-- Välj land --',
      pickupPhoneNumberPlaceholder: 'Telefon',
      pickupContactPerson: 'Kontaktperson',
      customerReference: 'Kundreferens',
      pickupReferencePlaceholder: 'Kundreferens - det här fältet visas på fakturan',
      pickupInformationPlaceholder: 'Upphämtningsinformation',
      customerContact: 'Kontaktperson hos kunden',
      customerContactPlaceholder: '$t(instant.booking.customerContact) – det här fältet visas på fakturan',
      customerInfoPlaceholder: 'Intenr kundspecifik information',
      customerInfoHelper:
        'Den här informationen är knuten till kunden. Ändringar kommer att synas internt på alla kundens ordrar.<br><br>För att lägga till orderspecifik information, använd fältet ”Anteckningar”.',
      deliveryNamePlaceholder: 'Namn',
      deliveryAddressPlaceholder: 'Adress',
      deliveryZipCodePlaceholder: 'Postnummer',
      deliveryZipAreaPlaceholder: 'Stad',
      deliveryCountryPlaceholder: '-- Välj land --',
      deliveryPhoneNumberPlaceholder: 'Telefon',
      deliverySecondPhoneNumberPlaceholder: 'Phone 2',
      deliveryReferencePlaceholder: 'Mottagarreferens',
      deliveryInformationPlaceholder: 'Leveransinformation',
      orderNote: 'Anteckning',
      clearData: 'Radera data',
      dimensions: 'Gods',
      weightPlaceholder: 'Vikt (kg)',
      lengthPlaceholder: 'Längd (cm)',
      widthPlaceholder: 'Bredd (cm)',
      heightPlaceholder: 'Höjd (cm)',
      service: 'Tjänst',
      serviceCode: 'Tjänstekod',
      servicePlaceholder: 'Tjänst',
      serviceSelect: 'Välj tjänst',
      serviceSelectDisabled: 'Ändra tjänster på HD-ordrar måste göras i HappyFlow',
      goodsEditDisabled: 'Ändringar på gods på HD-ordrar måste göras i HappyFlow',
      standardServices: '-- STANDARDTJÄNSTER --',
      customizedServices: '-- KUNDUNIKA TJÄNSTER --',
      specificationDescription: 'Gods',
      specificationDescriptionPlaceholder: 'Godsinformation',
      invoiceInfo: 'Fakturainformation',
      invoiceInfoPlaceholder: 'Fakturainformation - det här fältet visas på fakturan',
      manualInvoiceInfoPlaceholder:
        'Manuell fakturahantering (om det här fältet är ifyllt med faktureringsinstruktioner, så kommer sändningen inte bli automatiskt fakturerad)',
      pickup: 'Upphämtning',
      delivery: 'Leverans',
      pickupDatePlaceholder: 'Upphämtningsdatum',
      pickupTimePlaceholder: 'Upphämtningstid',
      deliveryDatePlaceholder: 'Leveransdatum',
      deliveryTimePlaceholder: 'Leveranstid',
      deliveryContactPerson: 'Kontaktperson',
      restoreButton: 'Återställ',
      orderButton: 'Beställ',
      editButton: 'Uppdatera',
      copyButton: 'Beställ',
      created: 'Sändningen har registrerats',
      edited: 'Sändningen har uppdaterats',
      quickSelect: 'Snabbval',
      volume: 'Volym',
      totalVolume: 'Total volym {{volume}}',
      totalWeight: 'Total vikt {{weight}}',
      removeItem: 'Ta bort',
      copyItem: 'Kopiera',
      addItem: 'Lägg till',
      calculatePrice: 'Beräkna pris',
      priceExplanations: {
        header: 'Prisförklaring',
        article: 'Artikel: {{article}}',
        priceListName: 'Prislistans namn: {{pricelist}}',
        explanations: 'Förklaringar:',
        noInfo: 'Ingen prisinformation tillgänglig'
      },
      approveIncompletePrice: 'Jag godkänner alla linjer med nollpris',
      prePlannedToCourier: 'Välj chaufför',
      automaticDepartment: 'Automatisk',
      departmentLabel: 'Avdelning',
      emailNotifications: 'E-mailaviseringar',
      eventRegistered: 'Registrerad',
      eventPickup: 'Upphämtning',
      eventDelivery: 'Leverans',
      emailAddress: 'E-mailadress',
      emailRecipientText: 'Följande e-mailadresser kommer ta emot aviseringar:',
      invalidEmail: 'Ogiltig e-mailadress',
      editIndicator: 'Ändra',
      updateIndicator: 'Uppdatera',
      copyIndicator: 'Kopiera',
      quantity: 'Kvantitet',
      additionalServices: 'Tilläggstjänster',
      paymentInformation: 'Betalningsinformation',
      paidTogetherLabel: 'Samtaxerad?',
      groupingLabel: 'Sändningsgruppering',
      groupIdPlaceholder: 'Unikt grupp-id',
      groupOrderPlaceholder: 'Ordningsföljd',
      addRecipient: 'Lägg till mottagare',
      addPickup: 'Lägg till avsändare',
      vehicleType: 'Fordonstyp',
      vehicleTypeUpdated: 'Fordonstyp uppdaterad',
      selectVehicleType: 'Välj fordonstyp',
      customerPrice: 'Kundepris',
      resourcePrice: 'Unitpris',
      priceList: 'Prislista',
      manualPrice: 'Manuellt pris',
      vehicleTypes: {
        car: 'Bil',
        bicycle: 'Cykel',
        truck: 'Lastbil',
        motorcycle: 'Motorcykel',
        cargobike: 'Cargocykel'
      },
      date: 'Datum',
      unNumber: 'UN-nummer',
      hazardous: 'Klass',
      group: 'Grupp',
      netWeight: 'Nettovikt',
      name: 'Namn',
      restrictions: 'Restriktioner',
      totalPoints: 'Total poäng'
    },
    courierSorting: { title: 'Sortera på', alystraId: 'Chaufförsnummer', empty: 'Tom' },
    courierFilter: {
      title: 'Filtrera chaufförer',
      all: 'Visa alla',
      inactive: 'Dölj inaktiva',
      offline: 'Dölj offline',
      both: 'Dölj inaktiva och offline'
    },
    shipmentFilter: {
      title: 'Filtrera sändningar',
      showAll: 'Visa alla',
      notCollected: 'Ej upphämtade',
      hideAll: 'Dölj alla'
    },
    offer: {
      carEmpty: 'Enheten är tom',
      suggestedDelivery: 'Föreslagen leveransordning',
      newShipments: 'Nya sändningar',
      manualRoute: 'Manuell ruttning',
      confirmRoute: 'Bekräfta rutt',
      assigning: 'Tilldelar',
      shipmentsTo: 'sändningar till',
      shipmentTo: 'sändning till'
    },
    useFlexible: 'Använd flexibel tid',
    forceOptimizing: 'Tvinga leveransordning',
    replanRoute: 'Omplanera rutten',
    recurring: {
      successMessage: '{{number}} har adderats till de valda grupperna',
      title: 'Återkommande',
      recurringOrders: 'Återkommande uppdrag',
      includedOrders: 'Uppdrag inkluderade i gruppen',
      details: 'Detaljer',
      editOriginal: 'Redigera original',
      copyOriginal: 'Kopiera original',
      notStarted: 'Inte startad',
      executing: 'Kör...',
      ended: 'Avslutat',
      pending: 'Resterna',
      failedOrders: 'Misslyckade ordrar',
      exceptionsHeading: 'Uppehåll för återkommande ordrar',
      addExceptionHeading: 'Nytt uppehåll',
      exceptionEnd: 'Slut',
      editExceptions: 'Redigera uppehåll',
      addRecurring: 'Återkommande',
      addToGroup: 'Välj återkommande grupp',
      exceptions: 'Uppehåll',
      recurringOrderAddedConfirmation: 'Order lagt till återkommande grupp',
      exceptionAddedConfirmation: 'Uppehåll tillagt',
      name: 'Namn',
      frequency: 'Frekvens',
      frequencies: { weekly: 'Varje vecka', second_week: 'Varannan vecka', third_week: 'Var tredje vecka' },
      daysOfWeek: 'Veckodagar',
      includeHolidays: 'Inkludera helgdagar',
      startDate: 'Startdatum',
      endDate: 'Slutdatum (valfritt)',
      save: 'Spara',
      fromDate: 'Från datum',
      confirmDelete: 'Är du säker på att du vill ta bort de markerade återkommande uppdragen?',
      from: 'Från',
      createOrders: 'Skapa uppdrag',
      showResult: 'Visa resultat',
      startdate: 'Startdatum',
      enddate: 'Slutdatum',
      orders: 'Ordrar',
      failed: 'Misslyckade',
      by: 'Av',
      on: 'den',
      reset: 'återställa',
      confirmReset: 'Vill du återställa återkommande uppdrag för {{date}}?'
    },
    corrections: {
      title: 'Korrigeringar',
      headline: 'Korrigeringar för {{date}}',
      accepted: 'Korrigering accepterad',
      rejected: 'Korrigering avvisad',
      shipmentId: 'Sändningsnr',
      courier: 'Enhet',
      from: 'Från',
      to: 'Till',
      details: 'Detaljer',
      pickupWaitingTime: 'Väntetid, upphämtning',
      deliveryWaitingTime: 'Väntetid, leverans',
      pickupLoadingTime: 'Lasttid upphämtning',
      deliveryLoadingTime: 'Losstid leverans',
      comment: 'Kommentar',
      weight: 'Vikt',
      volume: 'Volym',
      packages: 'Paket',
      serviceCode: 'Servicekod'
    },
    suggestedUnits: {
      unitName: 'Enhetsnamn',
      driverName: 'Förarens namn',
      slotName: 'Rutt',
      timeAtLocation: 'Tid på plats',
      distanceFromLocation: 'Avstånd från plats',
      shipmentInformation: 'Sändningsinformation',
      suggestedVehicles: 'Föreslagna fordon',
      suggestedVehiclesOnPickup: 'Föreslagna fordon för upphämtning',
      suggestedVehiclesOnDelivery: 'Föreslagna fordon vid leverans',
      suggestedUnits: 'Föreslagna enheter',
      noSuggestionsAvailable: 'Inga förslag tillgängliga',
      pickupTime: 'Upphämtningstid',
      deliveryTime: 'Leveranstid',
      filterBy: 'Sortera efter'
    }
  },
  errors: {
    DEPARTMENT_INVALID_ALYSTRA_ID: 'Avdelningen har ett ogiltigt Alystra-id',
    ENTITY_DOES_NOT_EXIST: 'Enheten existerar inte',
    INVALID_DATE_RANGE: 'Ogiltigt datumintervall',
    INVALID_DELIVERY_LATITUDE: 'Ogiltig leveranslatitud',
    INVALID_DELIVERY_LONGITUDE: 'Ogiltig leveranslongitud',
    INVALID_LATITUDE: 'Ogiltig latitud',
    INVALID_LONGITUDE: 'Ogiltig longitud',
    INVALID_OR_MISSING_TIME: 'Ogiltig eller saknad tid',
    INVALID_PHONE_NUMBER: 'Ogiltigt telefonnummer',
    DUPLICATE_PHONE_NUMBER: 'Telefonnumret finns redan registrerat!',
    INVALID_PICKUP_LATITUDE: 'Ogiltig upphämtningslatitud',
    INVALID_PICKUP_LONGITUDE: 'Ogiltig upphämtningslongitud',
    INVALID_SLOT_TYPE: 'Ogiltig ruttyp',
    MISSING_ADDRESS: 'Saknad adress',
    MISSING_ALYSTRA_ID: 'Saknat Alystra-id',
    MISSING_AREA: 'Saknat område',
    MISSING_CAPACITY: 'Saknar kapacitet',
    MISSING_COLOR: 'Saknar färg',
    MISSING_CONSIGNMENT_INPUTS: 'Saknar sändningsinformation',
    MISSING_CONSIGNMENT: 'Saknar sändning',
    MISSING_CONSIGNMENTS: 'Saknar sändningar',
    MISSING_COURIER_ID: 'Saknar chaufförs-id',
    MISSING_COURIER: 'Du måste välja en chaufför',
    MISSING_CREATED_AT: 'Saknar skapad-datum',
    MISSING_CUSTOMER_ID: 'Saknar kundnummer',
    MISSING_CUSTOMER_REF: 'Saknar kundreferens',
    MISSING_DATE: 'Saknar datum',
    MISSING_DAYS: 'Saknar dagar',
    MISSING_DEPARTMENT: 'Saknar avdelning',
    MISSING_DESCRIPTION: 'Saknad beskrävning',
    MISSING_DEVIATION_TEXT: 'Saknar avvikstext',
    MISSING_END_LOCATION_LATITUDE: 'Saknar slutplatsens latitud',
    MISSING_END_LOCATION_LONGITUDE: 'Saknar slutplatsens longitud',
    MISSING_EVENT_DATA: 'Saknar eventdata',
    MISSING_EVENT_TYPE: 'Saknar eventtyp',
    MISSING_FILE_NAME: 'Saknar filnamn',
    MISSING_FROM_DATE: 'Saknar från-datum',
    MISSING_ID: 'Saknar id',
    MISSING_LOCATION_LATITUDE: 'Saknar platsens latitud',
    MISSING_LOCATION_LONGITUDE: 'Saknar platsens longitud',
    MISSING_LOCATION_POSITION: 'Saknar platsens position',
    MISSING_LOCATION_TIME: 'Saknar platsens tid',
    MISSING_NAME: 'Saknar namn',
    MISSING_GROUP: 'Saknar gruppering',
    MISSING_PACKAGE_ID: 'Saknar kollinummer',
    MISSING_PHONE: 'Saknar telefonnummer',
    MISSING_PLANNER_ID: 'Saknar trafikplanerar-id',
    MISSING_SERVICE_CODE: 'Saknar tjänstekod',
    MISSING_SHIPMENT_ID: 'Saknar sändningsnummer',
    MISSING_SLOT_ID: 'Saknar rutt-id',
    MISSING_SLOT_TYPE: 'Saknar slot-typ',
    MISSING_SMS_TOKEN: 'Saknar sms-kod',
    MISSING_START_LOCATION_LATITUDE: 'Saknar startpositionens latitud',
    MISSING_START_LOCATION_LONGITUDE: 'Saknar startpositionens longitud',
    MISSING_TO_DATE: 'Saknar till-datum',
    MISSING_ZIP_AREA: 'Saknar stad',
    MISSING_ZIP_CODE: 'Saknar postnummer',
    MISSING_COUNTRY_CODE: 'Saknar landskod',
    NO_SUCH_USER: 'Det finns ingen sådan användare',
    PARAM_NOT_OF_EXPECTED_TYPE: 'Parameterna är inte av förväntad typ',
    PARAM_NULL_NOT_ALLOWED: 'Parameter null är inte tillåten',
    QUERY_NAME_MISSING: 'Query-namn saknas',
    QUERY_PARAMS_MISSING: 'Query-parameter saknas',
    USER_NOT_EXIST_OR_INCORRECT_ROLE: 'Användare saknas eller har felaktig roll',
    USER_EXIST_BUT_INCOMPATIBLE_ROLE: 'Användare finns men har en annan roll',
    SLOT_CANNOT_BE_STARTED: 'Rutt kan inte startas',
    SLOT_CANNOT_BE_DELETED: 'Rutt kan inte raderas',
    COURIER_NOT_PART_OF_DEPARTMENT: 'Chauffören tillhör inte avdelningen',
    CONSIGNMENT_IN_INCORRECT_STATE_CANNOT_UPDATE: 'Sändningar i felaktig status och kan inte uppdateras',
    ORDER_DOES_NOT_EXISTS: 'Sändningarna finns inte',
    OPTIMIZE_ALREADY_RUNNING: 'Optimering pågår redan',
    CANNOT_CHANGE_ORDER: 'Kan inte ändra sändning',
    NO_ACCESS: 'Ingen tillgång',
    NO_RELEVANT_CONSIGNMENTS_OR_SLOTS: 'Inga relevanta sändningar för valda rutter',
    NO_RELEVANT_CONSIGNMENTS: 'Inga relevanta sändningar för valda rutter',
    NO_RELEVANT_SLOTS: 'Endast rutter som inte har startats eller är pausade kan användas för denna optimering',
    NOT_EQUAL_PICKUP_ADDRESSES:
      'Alla hämtningsadresser måste vara lika för att begränsa alla försändelser till en förare',
    NO_SUCH_QUERY: 'Ingen sådan query',
    EXCEL_MISSING_SHIPMENT_ID: 'Saknar sändningsnummer i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_CUSTOMER_REF: 'Saknar kundreferens i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_CUSTOMER_ID: 'Saknar kundnummer i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_DEPARTMENT: 'Saknar avdelning i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_PACKAGE_ID: 'Saknar kollinummer i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_NAME: 'Saknar namn i cell {{col}} på rad {{row}}',
    EXCEL_INVALID_PHONE: 'Saknar telefonnummer i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_ADDRESS: 'Saknar adress i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_ZIP_AREA: 'Saknar postnummer i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_ZIP_CODE: 'Saknar stad i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_FROM_DATE: 'Saknar från-datum i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_TO_DATE: 'Saknar till-datum i cell {{col}} på rad {{row}}',
    EXCEL_INVALID_FROM_DATE: 'Från-datum kan inte vara före idag. Hittades i cell {{col}} på rad {{row}}',
    EXCEL_INVALID_TO_DATE: 'Till-datum kan inte vara före idag. Hittades i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_NOTIFICATION_SMS: 'Saknar SMS-information i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_SIZE: 'Saknar antal i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_ORDERS: 'Inga ordrar hittades i Excel-arket.',
    EXCEL_MISSING_COUNTRY: 'Saknar landsnamn eller landskod i cell {{col}} på rad {{row}}',
    EXCEL_NO_ACCESS_DEPARTMENT: 'Ingen tillgång till avdelning "{{dept_ref}}" som hittades på rad {{row}}',
    EXCEL_MISSING_DESCRIPTION: 'Saknar beskrivning i cell "{{cell}}" på rad {{row}}',
    EXCEL_MISSING_PICKUP_INSTRUCTIONS: 'Saknar upphämtningsbeskrivning i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_DELIVERY_INSTRUCTIONS: 'Saknar leveransbeskrivning i cell {{col}} på rad {{row}}',
    MISSING_PICKUP_LOCATION_AND_TIME: 'Saknar upphämtningsplats och tid',
    MISSING_EARLIEST_PICKUP_DATE: 'Saknar tidigaste upphämtningsdatum',
    MISSING_LATEST_PICKUP_DATE: 'Saknar senaste upphämtningsdatum',
    MISSING_EARLIEST_DELIVERY_DATE: 'Saknar tidigaste leveransdatum',
    MISSING_LATEST_DELIVERY_DATE: 'Saknar senaste leveransdatum',
    INVALID_EARLIEST_PICKUP_DATE: 'Ogiltigt tidigaste upphämtningsdatum',
    INVALID_LATEST_PICKUP_DATE: 'Ogiltigt senaste upphämtningsdatum',
    INVALID_EARLIEST_DELIVERY_DATE: 'Ogiltigt tidigaste leveransdatum',
    INVALID_LATEST_DELIVERY_DATE: 'Ogiltigt senaste leveransdatum',
    CANNOT_MODIFY_ORDERS_FROM_DIFFERENT_SOURCE:
      'Kan inte ändra beställningar som kommit in via EDI eller API med Excel-filer',
    PICKUP_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST: 'Upphämtningstid måste vara före leveranstid',
    DELIVERY_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST: 'Tidigaste leverantiden bör vara före senaste leveranstiden',
    CANNOT_DELIVER_ON_A_HOLIDAY: 'Kan inte leverera på en helgdag',
    MISSING_TEMPLATE_ID: 'Saknar mall-id',
    TEMPLATE_DOES_NOT_EXIST: 'Mall-id saknas',
    TEMPLATE_CANNOT_BE_DELETED: 'Mallen kan inte raderas',
    MISSING_TEMPLATE_SLOTS: 'Mallen saknar rutter',
    TEMPLATE_NAME_ALREADY_EXISTS: 'En mall med det här namnet finns redan',
    COURIER_ALREADY_EXISTS: 'Det finns redan en chaufför med det här telefonnumret',
    ORDER_EXIST_FOR_SERVICE: 'Kan inte radera tjänsten eftersom det finns sändningar med den här tjänsten',
    NO_SERVICE_FOUND: 'Lägg till en tjänst innan du ändrar kundmappning',
    CANNOT_DELETE_AS_SERVICE_EXIST: 'Ta bort kundens tjänstekoppling du raderar tjänsten',
    SLOT_IN_INCORRECT_STATE_CANNOT_UPDATE: 'Rutten är inte i ett giltigt tillstånd för den här uppdateringen',
    GRAPHHOPPER_UNASSIGNED:
      'Kan inte tilldela alla sändningar (på grund av tid, kapacitet eller andra faktorer), detaljer: {{details}}',
    GRAPHHOPPER_REQUEST_FAILED: 'Begäran om optimering av rutten misslyckades',
    GRAPHHOPPER_LATEST_ARRIVAL_AT_DELIVERY_AFTER_EARLIEST_DEPARTURE:
      'Senaste ankomst till leverans kan inte vara före tidigaste avresa från upphämtning - är tidsfönstret för upphämtning från tidigare idag?',
    GRAPHHOPPER_EARLIEST_GREATER_THAN_LATEST:
      'Den tidigaste tiden kan inte vara senare än den senaste tiden - är tiderna från tidigare idag eller tidigare dagar?',
    SLOT_CANNOT_BE_EDITED: 'Rutten är inte i ett giltigt tillstånd för den här uppdateringen',
    INVALID_POSTCODE_AND_COUNTRY_CODE_CANNOT_BE_HANDLED: 'Ogiltigt postnummer och landskod',
    INVALID_BASIC_SERVICE_CODE: 'Ogiltig tjänst',
    INVALID_PICKUP_DATE_EARLIEST_FORMAT: 'Ogiltig tidigaste upphämtnings-format',
    INVALID_PICKUP_DATE_LATEST_FORMAT: 'Ogiltig senaste upphämtnings-format',
    INVALID_DELIVERY_DATE_LATEST_FORMAT: 'Ogiltig senaste leverans-format',
    INVALID_DELIVERY_DATE_EARLIEST_FORMAT: 'Ogiltig tidigaste leverans-format',
    INVALID_POSTAL_CODE: 'Ogiltigt postnummer',
    INVALID_MEASUREMENT_UNIT: 'Ogiltig måttenhet',
    MISSING_CONSIGNMENT_SET: 'Saknar sändningsset',
    MISSING_UPDATE_INDICATOR: 'Saknar uppdateringsindikator',
    MISSING_CONSIGNMENT_ID: 'Saknar sändnings-id',
    MISSING_REFERENCE_NO: 'Saknar referens-id',
    MISSING_PACKAGE: 'Saknar kolli',
    MISSING_DELIVERY_DATE_EARLIEST: 'Saknar tidigaste leveransdatum',
    MISSING_DELIVERY_DATE_LATEST: 'Saknar senaste leveransdatum',
    MISSING_CONSIGNEE: 'Saknar mottagare',
    MISSING_CONSIGNOR: 'Saknar avsändare',
    MISSING_POSTAL_CODE: 'Saknar postnummer',
    MISSING_CITY: 'Saknar stad',
    MISSING_PICKUP_DATE_LATEST: 'Saknar senaste upphämtningsdatum',
    MISSING_PICKUP_DATE_EARLIEST: 'Saknar tidigaste upphämtningsdatum',
    MISSING_CONSIGNOR_PARTY_ID: 'Saknar avsändar-id',
    INVALID_CUSTOMER_NO_MAPPING: 'Ogiltig kundnummermappning',
    INVALID_ROUTING: 'Ogiltig ruttning',
    NO_SERVICE_MATCHING_SERVICE_CODE: 'Ingen tjänst matchar tjänstekoden',
    NO_SERVICE_MATCHING_DAY_OF_WEEK: 'Ingen tjänst matchar veckodagen',
    NO_SERVICE_MATCHING_PICKUP_AND_DELIVERY_TIMES: 'Ingen tjänst matchar upphämtnings- och leveranstiden',
    ORDER_WAS_AFTER_CUTOFF_TIME: 'Ordern lades efter deadline',
    OPTIMUS_SERVICE_MUST_HAVE_PICKUP_AND_DELIVERY_TIMES: 'Måste ha pickup- och leveranstider',
    ORDER_CANNOT_BE_MOVED: 'Sändningen kan inte flyttas, den måste tilldelas en rutt',
    MISSING_DESTINATION_DEPARTMENT_ID: 'Saknar avdelnings-id',
    SHIPMENT_NOT_FOUND: 'Sändningen hittades inte',
    DURATION_IS_MORE: 'Du behöver begränsa din sökning till {{maxDuration}} dagar.',
    DUPLICATE_EXCEL_IMPORT:
      'Samma filnamn har redan importerats till den här avdelningen idag. Du kan se resultaten av importen <0>här</0>. Ändra filnamnet om du vill importera igen.',
    SHIPMENT_INVALID_QUERY:
      'Under peak är vårt sök begränsat för att säkra god prestanda. Du kan söka efter alla ordrar under en dag elller lägga till en sökparameter.',
    ADDRESS_CANNOT_BE_DELETED: 'Adressen används som standardadress och kan inte raderas.',
    UNKOWN_ERROR: 'Ändringarna kunde inte sparas',
    INCORRECT_STATES_OR_ASSIGNED_TO_SLOT: 'Felaktig status för tilldelad rutt',
    CANNOT_MOVE_ORDERS: 'Går ej att flytta sändningar',
    DESTINATION_DEPARTMENT_INELIGIBLE: 'Avdelningen är inte tillgänglig',
    DEPARTMENT_MISSING_ADDRESS_DETAILS: 'Avdelningen saknar adressuppgifter',
    MISSING_DELIVERY_PHONE_NUMBER: 'Telefonnummer saknas för',
    INVALID_DELIVERY_PHONE_NUMBER: 'Ogiltigt telefonnummer för',
    ROLE_IN_USE: 'Rollen kan inte tas bort eftersom rollen används.',
    INVALID_NAME: 'Rollnamnet måste vara unikt. En roll med detta namn finns redan.',
    TEXT_CANNOT_BE_DELETED:
      'Texten används i en tjänst och kan inte raderas. Koppla bort texten från tjänsten för att radera den.'
  },
  customer: {
    showAll: 'Visa alla kunder',
    customer: 'Kund',
    groups: {
      title: 'Kundgrupper',
      titleSingular: 'Kundgrupp',
      titleWithCount: '{{count}} kundgrupper',
      name: 'Gruppnamn',
      description: 'Beskrivning',
      numberOfCustomers: 'Antal kunder',
      none: 'INga kundgrupper',
      new: 'Ny kundgruppe',
      edit: 'Redigera kundgruppe',
      searchCustomer: 'Sök kund',
      members: 'Kunder i gruppen',
      save: 'Spara',
      deleteConfirm: 'Er du säker på att du vil radera kundgruppen?',
      deleted: 'Kundgruppen är raderad.',
      cannotDelete: 'Kundgruppen kan inte raderas då den används i en aviseringsregel.'
    },
    customerTitle: 'Kunder',
    customerNo: 'Kund nej',
    name: 'Namn',
    emailToSender: 'E-post till avsändaren',
    deviations: 'Avvikelser',
    add: 'Lägg till',
    noOrders: 'Inga sändningar i Glow',
    noOrdersDescription: 'De kundnummer du har tillgång till har inga sändningar i Glow',
    choose: 'Välj en kund',
    search: 'Sök',
    customerNumber: 'Kundnummer',
    searchHint: 'Sök för sändningar baserat på sändningsnummer, kundreferens, kollinummer, mottagarnamn eller adress',
    senderName: 'Avsändarens namn',
    addSenderName: 'lägg till avsändarens namn',
    senderNameAdded: 'Avsändarens namn tillagt',
    addInvoiceHDOrdersFromDate: 'Lägg till startdatum från vilka fakturering av HD-order ska ske i Glow',
    invoiceHDOrdersFromDate: 'Startdatum för fakturering av HD-order i Glow',
    invoiceHDOrdersFromDateAdded: 'Startdatum för fakturering av HD-order lagrat',
    billingCustomerNumber: 'Betalande kundnummer',
    addBillingCustomerNumber: 'Lägg till betalande kundnummer',
    billingCustomerNumberAdded: 'Betalande kundnummer tillagt',
    searchPage: {
      fromDate: 'Från datum',
      toDate: 'Till datum',
      today: 'Idag',
      tomorrow: 'Imorgon',
      yesterday: 'Igår',
      last30days: 'Senaste 30 dagarna',
      plusMinus15Days: '+/- 15 dagarna',
      plusMinus30Days: '+/- 30 dagarna'
    },
    smsText: {
      title: 'SMS Text',
      addEditSmsText: 'Add/ Edit SMS text',
      smsTypeTexts: {
        pre_advice_push_2nd: 'Push andra aviserings SMS',
        pre_advice_push_2nd_email: 'Push andra aviserings email',
        pre_advice_push_return_to_sender_email: 'Push retur till avsändaren email',
        pre_advice_pull_reminder: 'Pull påminnelse SMS',
        pre_advice_pull_reminder_email: 'Pull påminnelse email',
        pre_advice_pull_reminder_to_sender_email: 'Pull påminnelse till avsändaren email',
        pre_advice_pull_return_to_sender_email: 'Pull retur till avsändaren email',
        pre_advice_return_2nd: 'Return delivery andra aviserings SMS',
        pre_advice_return_return_to_sender_email: 'Return delivery retur till avsändaren email',
        pre_advice_pup_reminder_to_sender_email: 'PUP Påminnelse till avsändaren email',
        pre_advice_pup_return_to_sender_email: 'PUP retur till avsändaren email',
        delivery: 'Leverans SMS'
      }
    },
    showNotCollected: 'Ej upphämtade',
    booking: {
      pickupReady: 'Klar för upphämtning',
      pickup: 'Upphämtning',
      delivery: 'Leverans',
      priceComment: 'Du kommer faktureras enligt ditt avtal med Bring. Avgifter är inte inkluderade i detta pris.',
      serviceUnavailable: 'Den valda tjänsten erbjuds inte till postnummer {{postalCode}}',
      recipientPrice: 'Mottagarpris:',
      customerContactPlaceholder: 'Beställt av – Det här fältet visas på fakturan'
    },
    email: {
      emailAddress: 'E-mail address',
      returnToSender: 'Retur till avsändare',
      reminderToSender: 'Påminnelse till avsändare',
      true: 'yes',
      false: 'no',
      notificationType: { hdPreAdvice: 'HD avisering' }
    },
    customerDeviations: { deviationType: 'Avvikstyp' },
    customerInfo: 'Kundinformation',
    customerInformation: 'Kundinformation',
    customerName: 'Kundnamn',
    invoicingAddress: 'Fakturaadress',
    organisationNumber: 'Organisationsnummer',
    visitingAddress: 'Besöksadress',
    creditBlocked: 'Kredit spärrad',
    creditBlockedFrom: 'Kredit spärrad från datum',
    creditBlockedFromInfo: 'Spärra kredit för denna kund från det valda datumet',
    from: 'Från',
    address: 'Adress',
    zipCode: 'Postnummer',
    zipArea: 'Plats',
    country: 'Land',
    edit: 'Redigera {{name}}',
    selectCountry: 'Välj land',
    addNewCustomer: 'Lägg till ny kund',
    bookingPageModalHeading: 'Bokning i kundportalen',
    bookingPageModalDescription:
      'Avaktivera bokningen om kunden inte ska kunna boka sändningar på detta kundnumret kundportalen i Glow.',
    bookingActive: 'På',
    bookingInactive: 'Av',
    brandedTrackingActive: 'På',
    brandedTrackingInactive: 'Av',
    bookingPageAvailable: 'På',
    bookingPageHidden: 'Av'
  },
  serviceMapping: {
    serviceName: 'Tjänstenamn',
    pickupTime: 'Upphämtningstid',
    deliveryTime: 'Leveranstid',
    internalName: 'Internt namn',
    internalDescription: 'Intern beskrivning',
    externalName: 'Externt namn',
    externalDescription: 'Extern beskrivning',
    alystraId: 'Alystra-id',
    allowFlexDelivery: 'Tillåt förenklad leverans',
    timeWindow: 'Tidsfönster',
    courierApp: 'Budapp',
    allowCourierDescription: 'Tillåt budet att acceptera eller avvisa försändelser med denna tjänstekod',
    allowCourierCheckbox: 'Ja, budet ska tillåtas'
  },
  feedback: { message: 'Ge oss förslag på hur vi kan förbättra den här sidan.', linkText: 'Skicka feedback' },
  texts: {
    title: 'Tjänstetexter',
    update: 'Uppdatera text',
    edit: 'Ändra',
    delete: 'Radera',
    add: 'Lägg till text',
    reset: 'Ångra',
    confirmDelete: 'Är du säker på att du vill radera texten?',
    added: 'Texten har lagts till',
    deleted: 'Texten har raderats',
    updated: 'Texten har uppdaterats',
    nameToolTip: 'Namn visas när text länkas till tjänsten'
  },
  countries: { norway: 'Norge', sweden: 'Sverige', denmark: 'Danmark', germany: 'Tyskland' },
  billingType: { day: 'dag', evening: 'kväll', weekend: 'helg', weekendEvening: 'kväll på helg' },
  shipmentsExport: {
    unit: 'Enhet',
    exportButton: 'Exportera till Excel',
    shipmentId: 'Sändningsnummer',
    returned: 'Returnerat',
    department: 'Avdelning',
    customer: 'Kund',
    customerNumber: 'Kundnummer',
    date: 'Datum',
    pickupEarliest: 'Tidigaste upphämtningstid',
    pickupLatest: 'Senaste upphämtningstid',
    deliveryEarliest: 'Tidigaste leveranstid',
    deliveryLatest: 'Senaste leveranstid',
    serviceCode: 'Tjänst',
    additionalServices: 'Tilläggstjänster',
    pickupName: 'Upphämtning',
    pickupAddress: 'Upphämtningsadress',
    pickupZipCode: 'Upphämtningspostnummer',
    pickupZipArea: 'Upphämtningsstad',
    pickupCoordinates: 'Upphämtningskoordinater',
    pickupInstructions: 'Upphämtningsinstruktioner',
    deliveryName: 'Leverans',
    deliveryAddress: 'Leveransadress',
    deliveryZipCode: 'Leveranspostnummer',
    deliveryZipArea: 'Leveransstad',
    deliveryCoordinates: 'Leveranskoordinater',
    deliveryInstructions: 'Leveransinstruktioner',
    quantity: 'Kvantitet',
    weight: 'Vikt',
    volume: 'Volym',
    customerRef: 'Kundreferens',
    pickupContactPerson: 'Upphämtning kontaktperson',
    pickupPhone: 'Upphämtning telefon',
    deliveryContactPerson: 'Leverans kontaktperson',
    deliveryPhone: 'Leverans telefon',
    deliveryEmail: 'Leverans e-mail',
    recipientRef: 'Mottagarreferens',
    price: 'Pris',
    group: 'Grupp',
    pickupTime: 'Upphämtat/ Försök',
    delivered: 'Levererat/ Försök',
    deliveryEtaSmsSentAt: 'Levererings ETA SMS skickat',
    courierName: 'Chaufför',
    goodsInfo: 'Godsinformation',
    note: 'Anteckning',
    slotName: 'Rutt',
    estimatedArrivalTime: 'Beräknad',
    orderSource: 'Orderkälla'
  },
  importExport: {
    dropText: 'Släpp filen här för att ladda upp',
    export: 'Exportera',
    exportAddresses: 'Exportera kundadresser till Excel',
    importExcel: 'Importera från Excel',
    imported: 'adresser importerade',
    noRecipients: 'Inga giltiga mottagare hittades',
    or: 'eller',
    recipients: 'mottagare',
    selectFile: 'Välj fil',
    uploadingFile: 'Laddar upp...'
  },
  customMeasurements: {
    name: 'Namn',
    weight: 'Vikt',
    height: 'Höjd',
    length: 'Längd',
    width: 'Bredd',
    heading: 'Egna godstyper',
    error: 'Fel när godstyp skulle sparas: ',
    confirmDelete: 'Är du säker på att du vill radera den här godstypen?',
    new: 'Skapa ny',
    customizeGoodsTypes: 'Skapa egna godstyper',
    customGoodsTypes: 'Egna godstyper',
    defaultGoodsTypes: 'Standardgodstyper',
    invalidName: 'Namnet måste innehålla minst en bokstav'
  },
  unresolved: {
    unresolvedAddresses: 'Obekräftad adresser',
    allResolved: 'Alla adresser är bekräftade!',
    address: 'Adress',
    noOfShipments: 'Antal sändningar',
    shipment: 'sändning',
    shipment_plural: 'sändningar',
    shipmentNumber: 'Sändningsnummer',
    customerRef: 'Kundreferens',
    senderName: 'Avsändarens namn',
    senderPhone: 'Avsändarens telefonnummer',
    recipientName: 'Mottagarens namn',
    recipientPhone: 'Mottagarens telefonnummer',
    searchAndSetLocation: 'Sök och sätt adress',
    setALocationToContinue: 'Ange en adress för att fortsätta',
    resolveAddress: 'Bekräfta adress',
    suggestedAddressFound: 'Föreslagen adress hittad',
    thereIsOneUnresolvedAddress: 'Det finns {{count}} obekräftad adress',
    thereIsOneUnresolvedAddress_plural: 'Det finns {{count}} obekräftade adresser'
  },
  optimizing: {
    reqTime: 'Tid för anrop',
    respTime: 'Tid för svar',
    department: 'Avdelning',
    userName: 'Användare',
    request: 'Anrop',
    response: 'Svar',
    error: 'Fel',
    viewReqResp: 'Visa',
    totalTime: 'Tid'
  },
  date: { date: 'Datum', today: 'Idag', tomorrow: 'I morgon', yesterday: 'Igår', dateAndTime: 'Datum och tid' },
  routeReceipt: {
    printRouteReceiptList: 'Skriv ut kvittenslista',
    noRoutesInfoAvailable: 'Ingen ruttinformation tillgänglig ännu.'
  },
  homeDeliveryRouteList: {
    receiptList: 'Kvittenslista',
    shipments: 'Sändningar',
    weight: 'Vikt',
    volume: 'Volym',
    loadingMeters: 'Flakmeter',
    palletFootPrints: 'Pallplatser',
    sender: 'Avsändare',
    package: 'Kolli',
    shipmentNo: 'Sändningsnr',
    recipient: 'Mottagare',
    time: 'Tid',
    mobile: 'Mobil',
    other: 'Övrigt',
    attemptedDelivery: 'Bomkörning',
    receipt: 'Kvittens',
    dateOfBirth: 'Födelsedatum',
    signature: 'Signatur',
    nameClarification: 'Namnförtydligande',
    goodsLine1: 'Kom ihåg att kontrollera godset för synliga skador före signering.',
    goodsLine2: 'Om det finns synliga skador bör föraren registrera dem innan du som mottagare signerar.',
    damage: 'Skada',
    missing: 'Saknat',
    packageNumber: 'Kollinummer',
    comments: 'Kommentar'
  },
  triggerOrderUpdatesFromHF: {
    buttonTitle: 'Begäran uppdatering från HF',
    alertText: 'Är du säker på att du vill begäran uppdateringar för {{count}} sändningar från HF?',
    updatesRequestedNotification: 'Uppdateringar efterfrågade'
  },
  shipment: {
    columnCategories: {
      aboutTheShipment: 'Om sändningen',
      goods: 'Varor',
      timeAndDate: 'Tid och datum',
      planning: 'Planering',
      pickupInformation: 'Upphämtningsinformation',
      customer: 'Kund',
      deliveryInformation: 'Leveransinformation',
      originalSender: 'Ursprunglig avsändare',
      originalDelivery: 'Ursprunglig leverans',
      service: 'Tjänst',
      preAdvice: 'Förhandsråd'
    },
    shipment: 'Sändning',
    customer: 'Kund',
    customerContact: 'Kontaktperson hos kunden',
    customerReference: 'Kundreferens',
    customerNumber: 'Kundnummer',
    route: 'Rutt',
    service: 'Tjänst',
    additionalServices: 'Tilläggstjänster',
    quantity: 'Kvantitet',
    totalWeight: 'Total vikt',
    totalVolume: 'Total volym',
    totalLoadingMeters: 'Total flakmeter',
    totalPalletFootPrints: 'Total pallplatsar',
    goodsInfo: 'Godsinformation',
    packages: 'Paket',
    pickupInfo: 'Upphämtningsinformation',
    pickupDate: 'Upphämtningsdatum',
    pickupTime: 'Upphämtningstid',
    pickupInstructions: 'Upphämtningsinstruktioner',
    deliveryInfo: 'Leveransinformation',
    deliveryDate: 'Leveransdatum',
    deliveryTime: 'Leveranstid',
    receiverReference: 'Mottagarreferens',
    deliveryInstructions: 'Leveransinstruktioner',
    trackingPage: 'Spårningssida för mottagare',
    internalNote: 'Anteckning',
    closeWhileEditing:
      'Är du säker på att du vill stänga sidan under redigering?\nAlla ändringar som inte har sparats kommer gå förlorade.',
    name: 'Namn',
    address: 'Adress',
    zipCode: 'Postnummer',
    zipArea: 'Stad',
    phone: 'Telefon',
    secondPhone: 'Telefon 2',
    email: 'E-postadress'
  },
  bookingTimeWindow: {
    week: 'Vecka',
    bookButton: 'Boka',
    customButton: 'Anpassat tidsfönster',
    removeBookingButton: 'Ta bort bokning',
    bookedInfo: 'Bokat {{date}} mellan {{timeWindow}}',
    notBookedInfo: 'Ej bokad',
    tableHeaderDate: 'Datum',
    tableHeaderSlots: 'Tillgängliga tidsfönster',
    serverError: 'Ett fel inträffade på servern. Vänligen försök igen senare..',
    previousButton: 'Föregående',
    nextButton: 'Nästa',
    noAvailableDeliveryTimes: 'Inga tillgängliga leveranstider denna månad',
    customTimeTitle: 'Välj anpassat tidsfönster för leverans',
    customDateLabel: 'Datum',
    customTimeWindowLabel: 'Leveransfönster',
    backFromCustomTimeButton: 'Tillbaka till tillgängliga tider',
    errorEarliestNotBeforeLatest: ' Leveranstid tidigast kan inte vara före senaste',
    arrivedAtTerminal: 'Ankommit på terminal',
    bookCustomTimeSlot: 'Boka tid',
    shipmentBooked: 'Sändning bokad',
    shipmentBookedWithCustomTimeWindow: 'Sändning bokad med anpassat tidsfönster',
    updateBooking: 'Uppdatera bokning',
    removeTimeSlot: 'Ta bort tid',
    bookingChanged: 'Bokningen ändrad',
    bookingSuccessful: 'Bokning genomförd',
    customTimeWindowAdded: 'Anpassat tidsfönster har lagts till',
    timeFrom: 'Tid från',
    timeTo: 'Tid till',
    timeFieldError: 'Den tidigaste leveranstiden måste vara före den senaste',
    availableTimeSlot: 'Tillgängliga tidsfönster',
    updateButtonTooltip: 'För att uppdatera bokningen måste du först välja ett nytt tidsfönster'
  },
  manualOverride: {
    scannedFormDescription: 'Typ av ankomstscan triggas av vilken avdelning du väljer.',
    eventType: {
      delivered: 'Levererad',
      collected: 'Upphämtad',
      returned: 'Returnerad',
      deviated: 'Avvik',
      scanned: 'Skannat',
      preadvised: 'Preadvice-försök',
      'not-arrived-at-distributing-terminal': 'Ej ankommit'
    },
    selectEventType: 'Välj en typ av event',
    noEventSelected: 'Inget event valt',
    selectManifest: 'Välj flight',
    noManifestSelected: 'Inget flight valt',
    disabledEventReason: {
      returned: ' - Avvikelse krävs',
      collected: ' - Behöver vara tilldelad rutt',
      default: ' - Ej tillåtet'
    },
    fillDeliverOrReturnEventInfo: 'Fyll i namn, datum och tid',
    fillDeviationEventInfo: 'Välj avvik och fyll i datum och tid',
    fillGeneralEventInfo: 'Fyll i datum och tid',
    eventMessage: 'Händelse skapad',
    noActiveDriverError: 'Inga aktiva chaufförer på enheten',
    allPackagesSelected: 'Alla kollin valda',
    selectPackages: 'Välj kolli',
    selectDriver: 'Välj chaufför',
    selectUnit: 'Välj enhet',
    selectDepartment: 'Välj annan avdelning'
  },
  zendesk: {
    email: 'Din e-postadres:',
    confirmEmail: 'Upprepa din e-postadress:',
    send: 'Spara',
    missingEmail:
      'För att kunna använda de nya supportsidorna måste du registrera din Posten- eller Bring-e-postadress. Du behöver bara göra det första gången.'
  },
  instantPlannerUnification: {
    message:
      'Delarna Instant och Trafikplanerare är nu sammanslagna. Gå till Trafikplanerare för att hitta dina tidigare Instantfunktioner.',
    linkTitle: 'Läs mer här.'
  },
  shipmentFormPage: {
    findShipment: 'Hitta sändningar',
    searchPhraseError: 'Du måste ange minst 3 tecken',
    selectStateLabel: 'Välj status',
    selectDateLabel: 'Välj datum',
    submit: 'Visa resultat',
    inputPlaceholder: 'Sök på sändningsnummer, telefonnummer, address, område, postnummer ...',
    clearButtonLabel: 'Knapp för rensa sökterm',
    states: 'Status',
    allStates: 'Alla statusar',
    customDate: 'Anpassat',
    noDate: 'Inget datum',
    selectFromDate: 'Välj från datum',
    selectToDate: 'Välj till datum',
    include: 'Inkludera',
    exclude: 'Exkludera',
    selectAdvancedOperator: 'Välj typ av avancerat filter',
    ofTheseRequirements: 'av dessa krav',
    tableColumn: 'Tabell kolumn',
    condition: 'Villkor',
    searchTerm: 'Sökterm',
    all: 'Alla',
    any: 'Någon',
    tableDropdown: 'Välj tabell',
    conditionDropdown: 'Välj villkor',
    typeSearch: 'Skriv sökterm',
    addNewRow: 'Lägg till rad',
    deleteRow: 'Ta bort rad',
    service: 'Tjänst',
    goodsDescription: 'Godsbeskrivning',
    route: 'Rutt',
    unit: 'Enhet',
    deliveryArea: 'Leveransområde',
    deliveryZipcode: 'Leverans postnummer',
    state: 'Status',
    today: 'Idag',
    tomorrow: 'Imorgon',
    yesterday: 'Igår',
    last30Days: 'Senaste 30 dagarna',
    plusMinus15Days: '+/- 15 dagarna',
    plusMinus30Days: '+/- 30 dagarna',
    shipmentIds: 'Flera sändningar'
  },
  shipmentResultPage: {
    noResultTitle: 'Inget resultat',
    batchLimitReached: 'Vi visar max 2000 sändningar. Begränsa din sökning',
    noResultExplanation: 'Vi hittade inte vad du sökte efter. Testa att söka med andra söktermer.',
    withoutDateLimitReached:
      'När du söker på sändningar utan datum så visar vi maximalt {{limit}} sändningar. Begränsa din sökning.'
  },
  shipmentTable: {
    quantity: 'Antal',
    delEta: 'ETA Lev.',
    delDuration: 'Lev. varaktighet',
    delDurationTitle: 'Varaktighet för leverans (minuter)',
    delDeliveryFrom: 'Lev. från',
    delDeliveryFromTitle: 'Tidigaste leveranstid ',
    delDeliveryTo: 'Lev. till',
    delDeliveryToTitle: 'Senaste leveranstid ',
    timeOfDelivery: 'Leveranstidpunkt',
    puDuration: 'Upph. varaktighet',
    puDurationTitle: 'Varaktighet för upphämtning (minuter)',
    puPickupFrom: 'Upph. från',
    puPickupFromTitle: 'Tidigaste upphämtningstid ',
    puPickupTo: 'Upph. till',
    puPickupToTitle: 'Senaste upphämtningstid ',
    timeOfPickup: 'Upphämtningstidpunkt ',
    delEtaTitle: 'Estimerad leveranstid',
    recipientRef: 'Mottagarereferens',
    loading: 'Laddar …',
    pickupName: 'Upphämtningsnamn',
    deliveryName: 'Leveransnamn',
    selectAllOnPage: 'Markera allt på sidan ({{count}})',
    selectAll: 'Markera allt ({{count}})',
    deselectAll: 'Avmarkera',
    numSelectedShipments: '{{numSelected}} markerade',
    createLabels: 'Skapa fraktsedel',
    editShipments: 'Ändra sändningsinformationen',
    moveShipments: 'Flytta till annan avdelning',
    yourSearchResult: 'Ditt sökresultat',
    numberOfShipmentsFound: '{{count}} sändningar hittades'
  },
  shipmentResultActions: {
    confirmDeleteTitle: 'Ta bort sändning?',
    confirmDuplicateTitle: 'Duplicera sändning?',
    confirmDuplicateButton: 'Duplicera',
    deleteSuccess: 'Sändningen har tagits bort',
    deleteFailure: 'Sändningen kunde inte tas bort'
  },
  tablePagination: {
    goToPage: 'Gå till sida',
    page: 'sida',
    of: 'av'
  },
  moveDepartmentModal: {
    errorMessage: 'Order(s): {{orderIds}}, error: {{error}}',
    ordersMovedText: 'Sändningarna har flyttats till avdelning {{department}}',
    partialSuccessMessage: '{{count}} av {{fullCount}} sändningar har flyttats',
    title: 'Flytta markerade sändningar till en annan avdelning'
  },
  sendManualDelaySmsModal: {
    errorMessage: '{{error}} {{shipmentIds}}',
    partialSuccessMessage: '{{count}} av {{fullCount}} SMS har skickats',
    successMessage: 'Manuellt försenings-SMS har skickats'
  },
  multistopModal: {
    title: 'Gruppinformation',
    description: 'Gruppvy för sändningar som betalas tillsammans.',
    debitLines: 'Orderrader',
    notFound: 'Ingen gruppinformation hittades',
    shipmentId: 'Sändningsnummer',
    deliveryDate: 'Leveransdatum',
    stopAddress: 'Stoppadress',
    postalCode: 'Postnummer',
    noPrice: 'Ingen prisinformation tillgänglig för denna grupp'
  },
  shipmentDetails: {
    eventWasAdded: 'Händelse har lagts till',
    issueSaving: 'Det uppstod ett problem vid sparandet',
    noSelectedPackages: 'Inga paket är markerade',
    pickupAndDelivery: 'Upphämtning och leverans',
    whereIsTheOrder: 'Var är sändningen?',
    communicationToRecipient: 'Kommunikation till mottagaren',
    yesPremium: 'Ja, premium',
    alerts: 'Varningar',
    pickupDeliveryInfo: 'Upphämtning- och leveransinformation',
    quickEdit: 'Snabbredigering',
    quickEditTitle: 'Ändra upphämtning- och leveransinformationen',
    aboutShipment: 'Om sändningen',
    mapLinkText: 'Visa kartkoordinater',
    copyShipment: 'Kopiera sändning',
    editShipment: 'Ändra sändningsinformationen',
    location: 'Plats',
    time: 'Tid',
    selectDate: 'Välj datum',
    manualChangeEvent: 'Ändra händelse manuellt',
    disabledEventReason: {
      returned: 'Avvikelse krävs',
      collected: 'Behöver vara tilldelad rutt',
      default: 'Ej tillåtet'
    },
    selectDeviation: 'Välj avvik',
    required: 'Obligatoriskt',
    allPackages: 'Alla paket',
    packages: 'Paket',
    package: 'paket',
    changeDepartment: 'Ändra avdelning',
    moveDepartment: 'Flytta avdelning',
    shipmentMoved: 'Sändning flyttad',
    shipmentMovedText: 'Sändning {{shipmentId}} har flyttats till avdelning  {{department}}',
    shipmentMovedGoBack: 'Gå tillbaka till söksidan',
    moveShipmentDescription:
      'Om du inte har tillgång till avdelningen så kommer du inte kunna söka upp sändningen efter flytten',
    moveShipmentConfirmText: 'Flytta sändning till en annan avdelning? ',
    addShipmentToSlot: 'Lägg till sändning på {{slot}}?',
    changeSlotConfirm: 'Ändra rutten för sändingen till {{slot}}?',
    changeSlot: 'Ändra rutt',
    selectSlot: 'Välj rutt',
    AddToSlot: 'Lägg till rutt',
    optimisationInProgress: 'Optimering pågår. Du kan inte ändra rutten medan den optimeras.',
    removeFromSlot: 'Ta bort från rutt',
    removeShipmentFromSlot: 'Ta bort sändning från rutt',
    removeShipmentFromSlotHeading: 'Ta bort sändning från denna rutt?',
    successRemoveFromSlot: 'Sändningen har tagits bort från rutten',
    successChangeSlot: 'Sänding ändrade rutten',
    successAddToSlot: 'Sändning har lagts till på rutten',
    changeSlotDescription: 'Ändring av rutt är gjord utan att optimisering',
    addToSlotDescription: 'Den valda rutten kommer att optimeras utan att de existerande sändningarna ändrar ordning',
    pickupOrDeliveryChange: 'Upphämtnings- eller leveransinformation har ändrats',
    pickupOrDeliveryChangeDescription:
      'Upphämtnings- eller leveransaddressen har ändrats. Om du vill ändra tillbaka, klicka på någon av knapparna under.',
    addressWasUnresolved: 'Addressen är obekräftad'
  },
  location: 'Plats',
  scannedAtDelegatedDepartment: 'Skannat från den delegerade avdelningen.',
  pickupOrders: {
    acceptOrders: 'Acceptera ordrar',
    selected: 'markerade',
    handleOrders: 'Hanterade upphämtningsordrar',
    found: 'hittades',
    accept: 'Acceptera',
    reject: 'Neka',
    noResultText: 'Vi kunde inte hitta några resultat. Försök att söka med ett annat datum.',
    last7d: 'Idag + senaste 7 dagarna',
    rejectOptions: {
      notFound: 'Avsändaren fanns ej på upphämtningsadressen. Inga fler försök. Upphämtning avslutad',
      terminatesPickupOrder: 'Avsändaren har avbokat upphämtningsordern. Inga fler försök. Upphämtning avslutad',
      handedOver:
        'Avsändaren har lämnat över upphämtningsordern till en annan transportör. Inga fler försök. Upphämtning avslutad',
      noMoreAttempts: 'Avsändaren har flyttat. Inga fler försök. Upphämtning avslutad',
      pickupCorrected: 'Avsändaren har flyttat. Upphämtning korrigerad och skickad tillbaka till underleverantör.',
      goodsNotReady: 'Godset är inte redo för upphämting. Inga fler försök. Upphämtning avslutad',
      incorrectBooking: 'Felaktig bokning. Upphämtning avslutad.',
      senderRefuses: 'Avsändaren vägrar överlämna gods. Inga fler försök. Upphämtning avslutad.',
      addressCorrected: 'Felaktig upphämtningadress. Addressen korrigerad.',
      incorrectAddressNoMoreAttempts: 'Felaktig upphämtningadress. Inga fler försök. Upphämtning avslutad.',
      senderNotified: 'Felaktig upphämtningadress. Inga fler försök. Upphämtning avslutad.',
      forbiddenGoods: 'Förbjudet gods. Inga fler försök. Upphämtning avslutad.',
      timeFrame: 'Upphämtning kan inte göras inom tidsramen. Upphämtningsorder skickas tillbaka till bokningskontoret.'
    },
    mustSelectReason: 'Du behöver ange en orsak för avslaget',
    selectRejectReason: 'Välj orsak för avslag',
    selectReason: 'Välj orsak',
    commentPlaceholder: 'Valfritt - ge mer information om avslaget',
    acceptedSuccessToast: 'accepterades',
    rejectedSuccessToast: 'avslogs',
    errorToast: 'Något gick fel',
    rejectOrders: 'Neka sändningar',
    shipment: '{{count}} sändning',
    shipment_plural: '{{count}} sändningar'
  },
  editShipments: {
    pickupShouldBeBeforeDelivery: 'Upphämtningstiden måste vara före leveranstiden',
    pickupEarliestShouldBeBeforeLatest: 'Den tidigaste upphämtningstiden behöver vara före den senaste',
    deliveryEarliestShouldBeBeforeLatest: 'Den tidigaste leveranstiden behöver vara före den senaste',
    success: 'Sändningsinformationen har uppdaterats',
    pickupDateTime: 'Upphämtning datum och tid',
    deliveryDateTime: 'Leverans datum och tid',
    earliestPickupTime: 'Tidigaste upphämtningstid',
    latestPickupTime: 'Senaste upphämtningstid',
    earliestDeliveryTime: 'Tidigaste leveranstid',
    latestDeliveryTime: 'Senaste leveranstid',
    pickupName: 'Namn till avsändare',
    pickupPhoneNumber: 'Telefonnummer till avsändare',
    deliveryName: 'Namn till mottagare',
    deliveryPhoneNumber: 'Telefonnummer till mottagare',
    pickupInstructions: 'Upphämtningsinstruktioner',
    deliveryInstructions: 'Leveransinstruktioner',
    mixedShipmentsSelected: 'Du har valt sändningar med och utan datum. Information om upphämtning och leverans krävs.',
    fieldNotEditable: 'Informationen kan ej ändras för sändningar med status tilldelad rutt och upphämtad',
    bulkActionDisabledReason: 'Sändning med status levererad, avvikt, nekad eller retunered kan ej ändras',
    withoutDateDisabledReason:
      'Sändningar utan datum och med antingen status tilldelad rutt eller upphämtad går inte att ändra.'
  },
  shipmentSelectColumn: {
    title: 'Anpassa datatabellen',
    addColumnHeader: 'Lägg till tabellrubriker',
    addColumnBody:
      'Välj de rubriker du vill ska visas i datatabellen. Vårt tips är att välja cirka 10-15 rubriker för att information ska visas på ett så bra sätt som möjligt. Exakt antal beror lite på vilken skärmstorlek du har.',
    orderOfTable: 'Ordning på tabellrubriker',
    orderOfTableDescription:
      'Välj den föredragna ordningen för tabellrubrikerna. Du justerar ordningen genom att dra och släppa.'
  },
  bulkActions: {
    routeReceiptList: {
      disabledTooltip: 'Sändning kan inte ha status bekräftad eller obekräftad'
    },
    unassignShipmentFromSlot: 'Ta bort från rutt ',
    unassignShipmentFromSlotDisabledReason: 'Sändning kan inte ha status tilldelad rutt eller erbjuden',
    forceUnassignShipmentFromSlot: 'Tvinga bortagning från rutt',
    disabledMoveDepartmentTooltip:
      'Sändningar behöver ha status skapad, bekräftad, obekräftad eller avvisad för att kunna flyttas',
    deleteDisableTooltip:
      'Sändningen kan ej tas bort om den har status skapad, obekräftad, bekräftad, erbjuden eller tilldelad',
    duplicateDisableTooltip: 'Sänding behöver tillhöra avdelningstyp Courier samt ha status avvik eller returnerad'
  },
  invoicingStatus: {
    INVOICING_FAILED: 'Fakturering misslyckades',
    CORRECTION_PENDING: 'Inväntar korrigering',
    INVOICING_PAUSED: 'Fakturering pausad',
    EXCLUDED_FROM_INVOICING: 'Exkluderad',
    NOT_READY_FOR_INVOICING: 'Inte redo',
    INVOICED_OK: 'Fakturerad OK',
    ZERO_PRICE: 'Ofullständigt pris'
  },
  invoice: {
    articleCode: 'Artikel',
    allOrdersInvoice: 'Alla order är fakturerade',
    allOrdersInvoiceDescription: 'Vi kunde inte hitta några order som skall faktureras',
    handledBy: 'Hanterad av',
    invoicingInformation: 'Fakturainformation',
    invoicing: 'Fakturering',
    credit: 'Kreditnota',
    invoice: 'Faktura',
    create: 'Skapa',
    invoiceOrderAction: 'Fakturera order',
    customerNumber: 'Kundnummer',
    carrierNumber: 'Enhetsnummer',
    details: 'Detaljer',
    information: 'Fakturainformation',
    reference: 'Fakturareferens',
    items: 'Artiklar',
    quantity: 'Antal',
    description: 'Beskrivning',
    customerAmount: 'Kundbelopp',
    carrierAmount: 'Enhetsbelopp',
    totalCustomerAmount: 'Totalt kundbelopp',
    totalCarrierAmount: 'Totalt enhetsbelopp',
    invoiceDepartment: 'Fakturera avdelning',
    invoiceDepartment_plural: 'Fakturera avdelningar',
    invoiceDepartments: 'Fakturera avdelningar',
    dashboard: 'Dashboard',
    invoicingJobsSummary: 'Summering av faktureringsjobb',
    invoicingStatus: 'Fakturastatus',
    invoicingStatusDescription: 'Visar order från de senaste 3 månaderna',
    notInvoicedOrders: 'Ej fakturerade order',
    ordersFoundCount: '{{count}} order hittades',
    ordersFoundCount_plural: '{{count}} order hittades',
    toBeInvoiced: 'Att faktureras',
    ordersNotInvoiced: 'Order som ej har fakturerats',
    invoicingFailed: 'Fakturering misslyckades',
    invoicingFailedDescription: 'Fakturering av order misslyckades',
    text: 'Fakturatext',
    correctionPending: 'Inväntar korrigering',
    correctionPendingDescription: 'Order som inväntar korrigering',
    invoicingPaused: 'Fakturering pausad',
    ordersPausedFromInvoiced: 'Order som är pausade från fakturering',
    invoicingZeroPrice: 'Ofullständigt pris',
    invoicingZeroPriceDescription: 'Ordres med ej godkända nollrader',
    sendInvoices: 'Skapa fakturor',
    sendInvoicesDescription:
      'Du är på väg att fakturera {{departments}}. Var god välj datum för vilken period som skall faktureras.',
    sendInvoiceDateValidationError: 'Från-datum kan inte vara senare än till-datum',
    sendInvoiceDateValidationErrorOneMonth: 'Perioden kan inte överstiga 1 månad',
    invoicingInProgress: 'Fakturering av {{departmentName}} är påbörjad. {{count}} jobb återstår.',
    invoicingInFinished: 'Fakturering av {{departmentName}} är avslutad.',
    orderSuccessInvoice: 'Fakturering av order lyckades',
    orderSuccessInvoice_plural: 'Fakturering av order lyckades',
    orderSuccessNotInvoiced: 'Fakturastatus ändrades till ej fakturerad',
    orderSuccessNotInvoiced_plural: 'Fakturastatus ändrades till ej fakturerad',
    orderFailedInvoice: 'Fakturering av order misslyckades',
    howManyInvoices: 'Hur många fakturor kommer skickas?',
    fetchAmount: 'Hämta belopp',
    wantToResend: 'Denna order är redan fakturerad. Är du säker på att du vill fakturera igen?',
    invoiceSingleOrder: 'Du är på väg att fakturera denna order. Vill du fortsätta?',
    pauseOrders: 'Pausa fakturering',
    datePeriod: 'Datumperiod',
    ordersWereSuccessfullyPaused: '{{count}} order har pausats från fakturering',
    ordersWereSuccessfullyPaused_plural: '{{count}} order har pausats från fakturering',
    releaseOrders: 'Återuppta order',
    ordersWereSuccessfullyReleased: '{{count}} order har återupptagits för fakturering',
    ordersWereSuccessfullyReleased_plural: '{{count}} order har återupptagits för fakturering',
    ordersWereSuccessfullyAcceptedWithIncompletePrice: '{{count}} order accepterades med ofullständigt pris',
    ordersWereSuccessfullyAcceptedWithIncompletePrice_plural: '{{count}} order accepterades med ofullständigt pris',
    addUnit: 'Lägg till enhet',
    addUnitDescription: 'Lägg till enhet på valda order. Enheten kommer att internfaktureras.',
    addUnitToOrders: 'Lägg till enhet på order',
    addUnitToOrder: '{{unit}} lades till på {{count}} order',
    addUnitToOrder_plural: '{{unit}} lades till på {{count}} order',
    addUnitBulkDisabled: 'Enhet kan endast läggas till på order som saknar enhet',
    approveIncompletePrices: 'Godkänn med ofullständiga priser',
    manualOverrideTitle: 'Överstyr faktureringsstatus',
    manualOverrideModalQuestion:
      'Du är på väg att överstyra faktureringsstatusen på {{count}} order. De kommer att få status Fakturerad OK. Är du säker på att du vill fortsätta?',
    unitId: 'Enhets-ID',
    bannerOldInvoicePage:
      'Den 26 augusti kommer <0>den nya faktureringssidan</0> att öppnas som standard. Vi uppmuntrar dig att börja använda den nya sidan. Mer info om den nya sidan <1>här</1>.',
    acceptIncompletePricesTitle: 'Godkänn ofullständiga priser',
    acceptIncompletePricesModalQuestion:
      'Du är på väg att godkänna {{count}} order med ofullständiga priser. Är du säker på att du vill fortsätta?',
    acceptIncompletePricesModalQuestion_plural:
      'Du är på väg att godkänna {{count}} ordrar med ofullständiga priser. Är du säker på att du vill fortsätta?',
    confirmCancelDialog: {
      title: 'Du har osparade ändringar',
      question: 'Du har osparade ändringar. Vill du fortsätta?',
      abort: 'Stanna på sidan',
      confirm: 'Fortsätt utan att spara'
    }
  },
  live: {
    onlyDeviated: 'Visa endast rutter med avvik',
    bannerOldLivePage:
      '<0>Testa</0> den nya Live-sidan, med förbättrade funktioner och ny design. Vi uppmuntrar dig att använda den nya livesidan för att vänja dig vid den innan vi tar bort sidan helt.',
    shipment: 'Sändning',
    shipmentFrom: 'Från',
    shipmentTo: 'Till',
    confirm: 'Bekräfta',
    unconfirm: 'Ångra',
    confirmedBy: 'Bekräftad av {{name}} kl. {{time}}',
    theCourier: 'Chauffören',
    activeRoutes: 'Aktiva rutter',
    routeDeviation: 'Avvik på rutten',
    routeDeviations: 'Avvik på rutten',
    multipleDeviations: 'Flera avvik',
    recentDeviations: 'Senaste avvik',
    noRoutesFound: 'Inga rutter hittades',
    noDeviationsFound: 'Inga avvik hittades',
    consignmentDamage: 'Skada på sändning ',
    shipmentDeviation: 'Avvik på sändning ',
    outsideServiceTime: 'Utanför servicetiden',
    outsideEstimatedTime: 'Utanför beräknat tidsfönster',
    deliveryLocationDeviation: 'Felaktig leveransplats',
    eventTextDeviationOne: '{{name}} har ett avvik',
    eventTextDeviationMany: '{{name}} har {{count}} avvik',
    eventTextDamageOne: '{{name}} har skada på {{count}} sändningar.',
    eventTextDamageMany: '{{name}} har skada på en sändning.',
    eventTextServiceTimeWindow: '{{name}} har levererat utanför tjänstens tidsfönster.',
    eventTextEstimatedTimeWindow: '{{name}} har levererat utanför det beräknade tidsfönstret.',
    eventTextDeviated: '{{name}} har avvikt från sin rutt.',
    eventTextDeliveryLocationDeviation: '{{name}} levererat/avvikt {{distance}} meter från den förväntade platsen.',
    eventHelpDeviation: '{{name}} har ett avvik på sändningsnummer {{shipmentId}}.',
    eventHelpDamage: '{{name}} har en skada på sändningsnummer {{shipmentId}}.',
    filterOngoing: 'Pågående',
    filterNotStarted: 'Ej startad',
    filterFinished: 'Visa slutförda rutter',
    filterOnlyDeviated: 'Visa enbart rutter med avvik',
    filterShipmentDeviations: 'Avvik på sändning',
    filterShipmentDamage: 'Skadat gods',
    filterEstimatedTime: 'Beräknad tid',
    filterServiceTime: 'Servicetid',
    filterRouteDeviations: 'Avvik på rutt',
    filterDeliveryLocationDeviation: 'Felaktig leveransplats',
    filterConfirmed: 'Bekräftad',
    routeProgress: 'Slutfört {{complete}} av {{total}}',
    routeDuration: 'Från {{from}} till {{to}}',
    routeDelayed: 'Försenad',
    routeAhead: 'Tidig',
    routeOnTime: 'I tid',
    routeUnknown: 'Okänd ',
    routeDeviationsNone: 'Inga avvikelser',
    routeDeviationCount: '{{unhandledDeviations}} av {{totalDeviations}}',
    routeHasHandleDeviations: 'Rutten har {{count}} hanterad avvikelse',
    routeHasHandleDeviations_plural: 'Rutten har {{count}} hanterade avvikelser',
    openRoute: 'Se rutt',
    openShipment: 'Se sändning',
    viewRoute: 'Se rutt',
    deviated: 'Avvikt',
    offRoute: 'Följer ej rutten',
    onRoute: 'Följer rutten',
    allRoutesStates: 'Alla ruttstatusar',
    selectedRouteStates: '{{count}} ruttstatus',
    selectedRouteStates_plural: '{{count}} ruttstatusar',
    noSelectedStates: 'Ingen status vald',
    allDeviationTypes: 'Alla avvikelsetyper',
    selectedDeviationTypes: '{{count}} avvikelsetyp',
    selectedDeviationTypes_plural: '{{count}} avvikelsetyper',
    showHandledDeviations: 'Visa hanterade avik ({{count}})',
    noSelectedTypes: 'Inga valda typer',
    viewShipment: 'Se sändning',
    selectAllRouteStates: 'Välj alla ruttstatusar',
    noRoutesWithDeviations: 'Inga rutter tillgängliga',
    selectAllDeviationTypes: 'Välj alla avvikelsetyper',
    noDeviationOnRoute: 'Inga avvik på rutten',
    noDeviationAvailable: 'Inga avvik tillgängliga',
    noDeviationsOnFilter:
      'Det finns inga tillgängliga avvikelser för det valda filtret.Testa att använda ett annat filter',
    noRoutesOnFilter: 'Det finns inga tillgängliga rutter för det valda filtret.Testa att använda ett annat filter',
    eventHelpServiceTimeWindow:
      '{{name}} har levererat sändningsnummer {{shipmentId}} vid {{actual}}, vilket ligger utanför tjänstens tidsfönster {{tidigast}}-{{senast}}.',
    eventHelpEstimatedTimeWindow:
      '{{name}} har levererat sändningsnummer {{shipmentId}} vid {{actual}}, vilket ligger utanför det beräknade tidsfönstret {{tidigast}}-{{senast}}.',
    eventHelpDriverDeviated: '{{name}} har levererat sändningsnummer {{shipmentId}} i fel ordning.',
    eventHelpDriverDeviatedBefore:
      '{{name}} har levererat sändningsnummer {{shipmentId}}  i fel ordning  #{{actual}}  har levereats före  #{{expected}}.',
    eventHelpDriverDeviatedAfter:
      '{{name}} har levererat sändningsnummer {{shipmentId}} i fel ordning  #{{actual}}  har levereats efter  #{{expected}}.',
    eventHelpDeliveryLocationDeviation: {
      withShipmentId:
        '{{name}} har levererat eller avvikit sändningsnummer {{shipmentId}} <4>här</4>, {{distance}} meter från den <9>förväntade platsen</9>.',
      withoutShipmentId:
        '{{name}} har levererat eller avvikit <4>här</4>, {{distance}} meter från den <9>förväntade platsen</9>.'
    },
    search: 'Sök efter rutt, enhet eller chaufför',
    unhandled: 'Ohanterade',
    deviations: 'avvikelser',
    mostDeviations: 'Flest avvikelser',
    latestInTime: 'Senast i tiden',
    earliestInTime: 'Tidigast i tiden',
    sortOn: 'Sortera på'
  },
  deliveryNote: {
    title: 'Följesedel',
    sender: 'Avsändare',
    vatNumber: 'Momsnummer',
    orderNumber: 'Ordernummer',
    receiver: 'Mottagare',
    phone: 'Telefon',
    shipmentId: 'Sändningsnummer',
    articles: 'Artiklar',
    orderDate: 'Beställningsdatum',
    deliveryDate: 'Leveransdatum',
    deliveryTime: 'Leveranstid',
    printedDate: 'Utskriftsdatum',
    receiversReference: 'Mottagarens referens',
    receiversReferenceNote: 'Använd detta nummer vid all kontakt med oss',
    articleNumber: 'Nummer',
    articleName: 'Namn',
    articlePrice: 'Styckpris',
    articleQuantity: 'Antal',
    numberOfPackages: 'Antal paket',
    totalWeight: 'Total vikt',
    totalVolume: 'Total volym',
    print: 'Skriv ut följesedel'
  },
  userRolesAdmin: {
    userRoles: 'Användarroller',
    successCreateMessage: '{{name}} skapades',
    successUpdateMessage: '{{name}} uppdaterades',
    saveRole: 'Spara roll',
    editRole: 'Redigera {{name}}',
    createNewRole: 'Skapa ny roll',
    nameOfRole: 'Rollens namn',
    nameRequired: 'Namn är obligatoriskt',
    descriptionRequired: 'Beskrivning är obligatoriskt',
    descriptionLabel: 'Beskrivning (max 240 tecken)',
    iconLabel: 'Välj vilken ikon du vill ha för användarrollen',
    selectRoleTitle: 'Välj tillgångsrättighet för roll',
    emptyState: 'Inga roller hittades för sökfrasen',
    deleteConfirm: 'Är du säker på att du vill radera {{name}}?',
    deleteSuccess: 'Rollen {{name}} raderades',
    settings: 'Inställningar',
    accessRights: 'Tillgångsrättigheter',
    settingsTitle: 'Inställningar för roll',
    settingsDescription:
      'Engelska är obligatoriskt. Om inget annat språk väljs kommer engelska att användas som reservspråk.',
    additionalLanguagesTitle: 'Lägg till ytterligare språk',
    customerPortalAccess: 'Tillgång till kundportal',
    defaultForUserRole: 'Använd som standardroll (valfritt)'
  },
  validationError: {
    provideEmail: 'Du måste ange en e-post',
    provideValidEmail: 'Du måste ange en giltig e-post',
    youMustSelectCustomer: 'Du måste välja en kund'
  }
}
export default sv
