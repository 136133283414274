import { LayoutCard } from '@glow/ui-components'
import { Form } from 'formik'
import i18next from 'i18next'
import { List, Map } from 'immutable'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ShipmentPagesRoutesProps } from '../ShipmentPagesRoutes'
import {
  ManualEventsSectionFields,
  ManualEventsSectionForm,
  PartialConsignmentMap
} from '../details-page/ManualOverrideView'
import { BulkActionLayout } from './BulkActionLayout'
import { PartialShipmentData, usePartialShipmentData, useSelectedOrderIdsFromUrl } from './useSelectedOrderIdsFromUrl'

export const mapToPartialConsignmentMap = (partialConsignments: PartialShipmentData[]) => {
  return List(
    partialConsignments.map((consignment) => {
      const map: PartialConsignmentMap = Map({
        id: consignment.consignmentId,
        courierId: consignment.courierId,
        driverUserId: consignment.driverUserId,
        orderId: consignment.orderId,
        packageId: consignment.packageId,
        state: consignment.state,
        type: consignment.orderType,
        vasCodes: List()
      })
      return map
    })
  )
}

const AddEventBulkPage = (props: ShipmentPagesRoutesProps) => {
  const selectedOrderIds = useSelectedOrderIdsFromUrl()
  const navigate = useNavigate()
  const [partialConsignments] = usePartialShipmentData(selectedOrderIds)

  const consignments = useMemo(() => mapToPartialConsignmentMap(partialConsignments), [partialConsignments])
  const singleShipmentSelected = selectedOrderIds.length === 1

  return (
    <ManualEventsSectionForm
      url={props.match.url}
      consignments={consignments}
      bulkActionMode={!singleShipmentSelected}
      orderIds={List(selectedOrderIds)}
      urlDepartmentId={props.match.params.departmentId}
    >
      {(formikRenderProps) => (
        <Form noValidate>
          <BulkActionLayout
            goBack={() => navigate(-1)}
            isSubmitting={formikRenderProps.isSubmitting}
            title={i18next.t('consignment.addEvents')}
            selectedCount={selectedOrderIds.length}
          >
            <LayoutCard fullHeight>
              <ManualEventsSectionFields
                bulkActionMode={!singleShipmentSelected}
                url={props.match.url}
                consignments={consignments}
                orderIds={List(selectedOrderIds)}
                urlDepartmentId={props.match.params.departmentId}
                showSaveButton={false}
                {...formikRenderProps}
              />
            </LayoutCard>
          </BulkActionLayout>
        </Form>
      )}
    </ManualEventsSectionForm>
  )
}

export default AddEventBulkPage
