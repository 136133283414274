import ImmutableComponent from '../../../primitives/ImmutableComponent'
import { PreplannedUnitSelect } from './PreplannedUnitSelect'
import i18next from 'i18next'
import { VehicleTypeSelect } from './VehicleTypeSelect'
import { DepartmentSelect } from './DepartmentSelect'
import EmailNotificationDetails from '../../../components/EmailNotificationDetails'
import { LabeledInput } from '../../../primitives/LabeledInput'
// @ts-expect-error
import { Control } from 'react-redux-form/lib/immutable'
import { TextAreaInputWithLabel } from '../../../primitives/Forms'
import InputGroup from '../InputGroup'
import * as React from 'react'
import { List, Map } from 'immutable'
import { nameIsDashOrEmpty, nameStartsWithAlphabet, nameStartsWithNumber } from '../../../utils/stringUtils'
import { ICustomerEventEmail } from '../bookingOrderTypes'
import { OrderAction } from '../bookingSupport'
import { ROLE_PARTNER } from '../../../utils/roles'

const sortCouriersByName = (a: Map<string, any>, b: Map<string, any>) => {
  const first = (a.get('name') && a.get('name').toLowerCase()) || ''
  const second = (b.get('name') && b.get('name').toLowerCase()) || ''
  return second > first ? -1 : first > second ? 1 : 0
}

export const sortedUnitList = (units: List<any>) => {
  const sortedNamedCouriersList = units.filter(nameStartsWithAlphabet).sort(sortCouriersByName)
  const sortedNumberCouriersList = units.filter(nameStartsWithNumber).sort(sortCouriersByName)
  const sortedCouriersWithEmptyNameOrDash = units.filter(nameIsDashOrEmpty).sort(sortCouriersByName)
  return sortedNumberCouriersList.concat(sortedNamedCouriersList).concat(sortedCouriersWithEmptyNameOrDash)
}

interface Props {
  units: List<any>
  mergeFormValues: (model: string, values: Map<string, any>) => void
  overriddenDepartmentId?: number
  sortedDepartments: List<any>
  selectedCustomerId: number
  customerEventEmailData: List<ICustomerEventEmail>
  orderAction: OrderAction
  role: string
}

export class BookingFormPreplan extends ImmutableComponent<Props> {
  onSelectCourier = (courierId: string) => {
    this.props.mergeFormValues('createOrderForm', Map({ preplannedUnitId: courierId }))
  }

  onSelectVehicleType = (vehicleType: string) => {
    this.props.mergeFormValues('createOrderForm', Map({ vehicleType: vehicleType }))
  }

  onSelectDepartment = (departmentId: string) => {
    this.props.mergeFormValues('createOrderForm', Map({ selectedDepartmentId: departmentId }))
  }

  render() {
    const {
      units,
      overriddenDepartmentId,
      sortedDepartments,
      customerEventEmailData,
      selectedCustomerId,
      orderAction,
      role
    } = this.props
    const sortedUnitsList = sortedUnitList(units)
    const showDepartmentSelector = !(orderAction === OrderAction.Edit && role === ROLE_PARTNER)

    return (
      <InputGroup aria-label="Preplan form">
        <div>
          <PreplannedUnitSelect
            model=".preplannedUnitId"
            id="preplannedUnitId"
            onSelectCourier={this.onSelectCourier}
            units={sortedUnitsList}
            style={{ paddingTop: '0.5rem' }}
          />
        </div>
        <div>
          <div className="text-xs font-bold my-5">{i18next.t('instant.booking.vehicleType')}</div>
          <VehicleTypeSelect
            model=".vehicleType"
            id="vehicleType"
            onSelectVehicleType={this.onSelectVehicleType}
            style={{ paddingTop: '0.5rem' }}
          />
        </div>
        {showDepartmentSelector && (
          <div>
            <div className="text-xs font-bold my-5">{i18next.t('instant.booking.departmentLabel')}</div>
            <DepartmentSelect
              model=".selectedDepartmentId"
              id="selectedDepartmentId"
              style={{ paddingTop: '0.5rem' }}
              onSelectDepartment={this.onSelectDepartment}
              defaultValue={overriddenDepartmentId}
              departments={sortedDepartments}
              groupedDepartments={true}
            />
          </div>
        )}
        <EmailNotificationDetails
          customerEventEmailData={customerEventEmailData}
          selectedCustomerId={selectedCustomerId}
        />
        <div style={{ marginTop: '0.75em' }}>
          <LabeledInput label={i18next.t('instant.booking.orderNote')} htmlFor="orderNote">
            <Control.textarea model=".orderNote" id="orderNote" rows={4} component={TextAreaInputWithLabel} />
          </LabeledInput>
        </div>
      </InputGroup>
    )
  }
}
