import * as React from 'react'
import Select from '../../../primitives/Select'
// @ts-expect-error
import { Control } from 'react-redux-form/lib/immutable'
import i18next from 'i18next'
import { vehicleType } from '../bookingOrderFunctions'

interface VehicleTypeSelectProps {
  model: string
  id: string
  onSelectVehicleType: (courier: any) => void
  style?: React.CSSProperties
}

export const VehicleTypeSelect = ({ model, id, onSelectVehicleType, style }: VehicleTypeSelectProps) => (
  <Select expand>
    <Control.select
      model={model}
      id={id}
      style={style}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        onSelectVehicleType(e.target.value)
      }}
      defaultValue={''}
    >
      <option value={''}>{i18next.t('instant.booking.selectVehicleType')}</option>
      {vehicleType.map((vt: string) => {
        return (
          <option key={`vehicleType-${vt}`} value={vt}>
            {i18next.t(`instant.booking.vehicleTypes.${vt}`)}
          </option>
        )
      })}
    </Control.select>
  </Select>
)
