import i18next from 'i18next'
import { List } from 'immutable'

export const slotStates = {
  CREATED: 'CREATED',
  ASSIGNED: 'ASSIGNED',
  IN_PROGRESS: 'IN_PROGRESS',
  ON_HOLD: 'ON_HOLD',
  COMPLETE: 'COMPLETE'
}

const showSlotCompleted = List([slotStates.COMPLETE])
const activeSlot = List([slotStates.IN_PROGRESS, slotStates.ON_HOLD])
const allowedToEditSlot = List([slotStates.CREATED, slotStates.ASSIGNED])
const startedSlots = List([slotStates.IN_PROGRESS, slotStates.ON_HOLD, slotStates.COMPLETE])

export const isSlotEditable = (state: string) => {
  return allowedToEditSlot.contains(state)
}

export const isSlotComplete = (state: string) => {
  return showSlotCompleted.contains(state)
}

export const isSlotActive = (state?: string) => {
  return state ? activeSlot.contains(state) : false
}

export const isSlotStarted = (state?: string) => {
  return state ? startedSlots.contains(state) : false
}

// Export function that gives you the correct emoji icon for each state.
export function getDisplayNameForSlotState(state: string) {
  switch (state ? state.toUpperCase() : '') {
    case slotStates.CREATED:
      return i18next.t('slot.created')
    case slotStates.ASSIGNED:
      return i18next.t('slot.assigned')
    case slotStates.COMPLETE:
      return i18next.t('slot.complete')
    case slotStates.IN_PROGRESS:
      return i18next.t('slot.inProgress')
    case slotStates.ON_HOLD:
      return i18next.t('slot.onHold')
    default:
      console.log('unknown state:', state)
      return i18next.t('slot.unknownState')
  }
}
