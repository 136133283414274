import React from 'react'
import { List } from 'immutable'
import i18next from 'i18next'
import styled, { css } from 'styled-components'
import variables from '../../../../styles/variables'
import { DeliveryTime } from '../../shared/Shipment'
import { formatTimeWindow, isSameDeliveryDate, isSameDeliveryTime } from './bookingTimeUtils'

export const AvailableDeliveryTimesGrid: React.FC<{
  availableDeliveryTimes: List<DeliveryTime>
  selectedDeliveryTime: DeliveryTime | null
  setSelectedDeliveryTime: (deliveryTime: DeliveryTime | null) => void
}> = ({ availableDeliveryTimes, selectedDeliveryTime, setSelectedDeliveryTime }) => {
  const groupedByDay = availableDeliveryTimes.groupBy((it) => it.earliest.startOf('day'))
  const groupedByWeek = groupedByDay.groupBy((val, key) => key.weekNumber)
  const firstAvailableDeliveryTime = availableDeliveryTimes.get(0)
  const firstWeek = firstAvailableDeliveryTime?.earliest?.weekNumber
  const maxDeliveryTimesPerDay = groupedByDay.map((perDay) => perDay.count()).max() || 1
  const numCols = maxDeliveryTimesPerDay + 1

  return (
    <DeliveryTimesTable>
      <thead>
        <tr>
          <HeaderCell>{i18next.t('bookingTimeWindow.tableHeaderDate')}</HeaderCell>
          <HeaderCell colSpan={numCols}>{i18next.t('bookingTimeWindow.tableHeaderSlots')}</HeaderCell>
        </tr>
      </thead>
      <tbody>
        {groupedByWeek
          .map((daysInWeek, week) => (
            <React.Fragment key={week}>
              {week !== firstWeek && (
                <tr>
                  <WeekNoCell colSpan={numCols}>
                    {i18next.t('bookingTimeWindow.week')} {week}
                  </WeekNoCell>
                </tr>
              )}
              {daysInWeek
                .map((timeWindows, date) => {
                  const isRowSelected = isSameDeliveryDate(timeWindows.first(), selectedDeliveryTime)
                  return (
                    <React.Fragment key={date.toMillis()}>
                      <tr>
                        <DateCell isRowSelected={isRowSelected}>
                          {date.toLocaleString({ weekday: 'long', day: '2-digit', month: 'long' })}
                        </DateCell>
                        <>
                          {timeWindows.map((timeWindow: any) => {
                            const isCellSelected = isSameDeliveryTime(timeWindow, selectedDeliveryTime)
                            return (
                              <TimeWindowCell
                                key={timeWindow.earliest.toMillis()}
                                isCellSelected={isCellSelected}
                                isRowSelected={isRowSelected}
                                onClick={() => setSelectedDeliveryTime(isCellSelected ? null : timeWindow)}
                              >
                                {formatTimeWindow(timeWindow)}
                              </TimeWindowCell>
                            )
                          })}
                        </>
                      </tr>
                    </React.Fragment>
                  )
                })
                .valueSeq()}
            </React.Fragment>
          ))
          .valueSeq()}
      </tbody>
    </DeliveryTimesTable>
  )
}

const DeliveryTimesTable = styled.table`
  margin: 2em 1px 1.5em 1px;

  tbody {
    border-radius: 1px;
    border-collapse: collapse;
    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px ${variables.newColors.spaceGrey};
    tr {
      td {
        border: thin solid ${variables.newColors.spaceGrey};
      }
    }
  }
`

const HeaderCell = styled.th`
  font-size: 0.8rem;
  font-weight: 400;
  text-align: left;
`

const WeekNoCell = styled.td`
  font-size: 0.8rem;
  text-transform: uppercase;
  color: ${variables.newColors.darkGrey};
  background: ${variables.newColors.moonGrey};
  padding: 0.5em 0.5em;
`

interface TimeWindowRowProps {
  isRowSelected?: boolean
}

interface TimeWindowCellProps {
  isCellSelected?: boolean
}

const DateCell = styled.td<TimeWindowRowProps>`
  padding: 0.6em 1em;
  ${({ isRowSelected }) =>
    isRowSelected &&
    css`
      background: ${variables.newColors.pistachioGreen};
    `}
  ${({ isRowSelected }) =>
    !isRowSelected &&
    css`
      color: ${variables.newColors.darkGrey};
    `}
`

const TimeWindowCell = styled.td<TimeWindowCellProps & TimeWindowRowProps>`
  text-align: center;
  padding: 0.6em 1em;
  ${({ isRowSelected }) =>
    isRowSelected &&
    css`
      background: ${variables.newColors.pistachioGreen};
    `}
  ${({ isCellSelected }) =>
    isCellSelected &&
    css`
      color: ${variables.newColors.white};
      font-weight: 600;
      background: ${variables.newColors.primaryGreen};
    `}
`
