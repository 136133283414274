import React from 'react'
import { Unit, Shipment } from '../../types/coreEntitiesTypes'
import { Set } from 'immutable'
import { preplanConsignmentsToCourier } from '../../actions/creators/helpersInstant'
import { closeModal, openModal } from '../../actions/creators/helpers'
import ShipmentModal from '../../components/ShipmentModal'
import variables from '../../styles/variables'
import { FlexRow } from '../../primitives/Flex'
import i18next from 'i18next'
import { formatWeightDimensionsForShipments } from '../../utils/stringUtils'
import { getTotalShipmentWeight } from '../../utils/shipmentsUtils'
import { PrimaryButton, SecondaryButton } from '../../primitives/Button'
import { useAppDispatch } from '../../reducers/redux-hooks'

export const ShipmentSummary: React.FC<{ shipment: Shipment; courier: Unit | undefined }> = ({ shipment, courier }) => {
  const dispatch = useAppDispatch()
  const isMultileg = shipment.get('multilegId')
  const isPreplanned = shipment.get('preplannedToUnit')

  const prePlan = (courierId: any, consignments: Set<number>) => {
    dispatch(preplanConsignmentsToCourier(courierId, consignments))
  }

  const viewShipmentDetails = (shipmentId: number) => {
    dispatch(
      openModal(ShipmentModal, {
        shipmentId,
        includeOverlay: false,
        closeModal: () => {
          dispatch(closeModal(true))
        }
      })
    )
  }

  return (
    <div style={{ borderBottom: `1px solid ${variables.colors.gray4}`, padding: '5px', marginBottom: '1rem' }}>
      <FlexRow justifyContent="space-between">
        <div>
          <strong>{i18next.t('consignment.shipmentId')}:</strong>
          <div style={{ marginBottom: '0.5rem' }}>
            {formatWeightDimensionsForShipments(shipment.get('shipmentId'), getTotalShipmentWeight(shipment))}{' '}
            {isMultileg && '(Multileg)'}
          </div>
        </div>
        <div>
          <SecondaryButton type="button" onClick={() => viewShipmentDetails(shipment.get('id'))}>
            {i18next.t('consignment.show')}
          </SecondaryButton>
          {isPreplanned && (
            <PrimaryButton
              style={{ marginLeft: '5px' }}
              type="button"
              onClick={() => prePlan(null, shipment.get('consignmentIds'))}
            >
              {i18next.t('instant.shipmentSummary.unPrePlan')}
            </PrimaryButton>
          )}
          {!isPreplanned && courier && (
            <PrimaryButton
              type="button"
              style={{ marginLeft: '5px' }}
              onClick={() => prePlan(courier.get('alystraId'), shipment.get('consignmentIds'))}
            >
              {i18next.t('instant.shipmentSummary.prePlanTo')}&nbsp;{courier.get('name')}
            </PrimaryButton>
          )}
        </div>
      </FlexRow>
    </div>
  )
}
