import { ConsignmentEventActionId, ConsignmentEventId, SlotIdType } from './coreEntitiesTypes'
import { ImmutableMap } from './immutableTypes'

export enum ConsignmentEventActionType {
  CONFIRM = 'confirm',
  UNCONFIRM = 'unconfirm'
}

export interface ConsignmentEventAction
  extends ImmutableMap<{
    id: ConsignmentEventActionId
    slotId: SlotIdType
    consignmentEventId: ConsignmentEventId
    type: ConsignmentEventActionType
    createdAt: string
    userName: string
  }> {}
