import React from 'react'
import InputGroup from '../InputGroup'
import i18next from 'i18next'
import { H3 } from '../../../primitives/Headings'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { InputForLabel } from '../../../primitives/Forms'
import { LabeledInput } from '../../../primitives/LabeledInput'
import { SmallValidationError } from '../../../primitives/ErrorMessages'
import { required } from '../../../utils/inputValidation'
import { List } from 'immutable'
import { ClearInstantBookingFormButton } from '../../../components/ClearInstantBookingFormButton'
import { customerComponentNames } from './H2BookingSupport'

interface Props {
  clearComponents: (components: List<string>) => void
}

export const H2BookingCustomer = ({ clearComponents }: Props) => (
  <InputGroup>
    <H3>{i18next.t('instant.booking.customer')}</H3>
    <div>
      <LabeledInput label={i18next.t('instant.booking.customerPlaceholder')} htmlFor="customerId">
        <Control.text
          autoComplete="nope"
          model=".customerId"
          id="customerId"
          defaultValue={''}
          component={InputForLabel}
          validators={{ required }}
        />
      </LabeledInput>
      <Errors
        className="errors"
        model=".customerId"
        show="touched"
        wrapper={SmallValidationError}
        messages={{ required: `${i18next.t('application.required')}` }}
      />
    </div>
    <ClearInstantBookingFormButton components={customerComponentNames} clearComponents={clearComponents} />
  </InputGroup>
)
