import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import vars from '../styles/variables'
import { List } from 'immutable'

const transitionDuration = '0.2s'

interface AccordionProps {
  expansionPanels: List<ExpansionPanelProps>
  style?: CSSProperties
}

export const Accordion = ({ expansionPanels, style }: AccordionProps) => {
  const [expandedPanel, setExpandedPanel] = useState<string | undefined>(
    expansionPanels.find((panel) => panel.expandedOnLoad === true)?.header
  )
  const [height, setHeight] = useState<number>()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      setHeight(ref.current.clientHeight)
    }
  }, [])

  const togglePanel = (header: string) => {
    setExpandedPanel(expandedPanel === header ? undefined : header)
  }

  const toggleDisabled = expansionPanels.size === 1

  return (
    <AccordionContainer ref={ref} style={style}>
      {expansionPanels.map(
        ({ header, content, hidden }, index) =>
          !hidden && (
            <ExpansionPanel
              key={index}
              header={header}
              onToggle={togglePanel}
              expanded={expandedPanel === header}
              content={content}
              toggleDisabled={toggleDisabled}
              accordionHeight={height}
            />
          )
      )}
    </AccordionContainer>
  )
}

interface PanelProps {
  header: string
  content: ReactNode
  expanded: boolean
  onToggle: (header: string) => void
  toggleDisabled: boolean
  accordionHeight?: number
}

const ExpansionPanel = ({ onToggle, header, expanded, content, toggleDisabled, accordionHeight }: PanelProps) => (
  <>
    <HeaderContainer expanded={expanded}>
      <Header expanded={expanded} addHoverEffects={!toggleDisabled} onClick={() => !toggleDisabled && onToggle(header)}>
        {header}
      </Header>
    </HeaderContainer>
    <Content expanded={expanded} maxHeight={accordionHeight ? accordionHeight + 'px' : ''}>
      <div style={{ overflowX: 'scroll' }}>{content}</div>
    </Content>
  </>
)

const HeaderContainer = styled.div<{ expanded: boolean }>`
  background: ${vars.newColors.white};
  border-radius: ${(props) => (props.expanded ? '0.5rem 0.5rem 0 0' : '0.5rem')};
  transition-property: border-radius;
  transition-delay: ${(props) => (props.expanded ? '0s' : transitionDuration)};
  transition-timing-function: ease-in-out;
`

const Header = styled.button<{ expanded: boolean; addHoverEffects: boolean }>`
  font-size: 1.5rem;
  font-weight: 700;
  width: 100%;
  text-align: start;
  background: ${vars.newColors.white};
  border-radius: ${(props) => (props.expanded ? '0.5rem 0.5rem 0 0' : '0.5rem')};
  color: ${vars.newColors.black};
  padding: 1rem 2rem 1rem;
  ${(props) =>
    props.addHoverEffects
      ? `:hover {
    cursor: pointer;
    background-color: ${vars.newColors.moonGrey};
    border-radius: 0.5rem;
  }
  :focus:not(:focus-visible) {
    outline: none;
  }`
      : 'outline: none;'}
`

const Content = styled.div<{ expanded: boolean; maxHeight: string }>`
  background: ${vars.newColors.white};
  border-radius: 0 0 0.5rem 0.5rem;
  color: ${vars.newColors.black};
  padding: ${(props) => (props.expanded ? '0 2rem 1rem' : '0 2rem 0rem')};
  overflow-y: auto;
  overflow-x: hidden;
  max-height: ${(props) => (props.expanded ? props.maxHeight : '0px')};
  transition: all ${transitionDuration} ease-in-out;
  margin-bottom: 1.25rem;
  &:last-child {
    margin-bottom: 0;
  }
`

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
`

export interface ExpansionPanelProps {
  header: string
  expandedOnLoad?: boolean
  hidden?: boolean
  content: ReactNode
}
