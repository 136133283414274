import { AppStateType } from '../utils/appStateReduxStore'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'
import { DeviationOrderPropsType } from '../pages/admin/orderDeviationTypes'
import { List, Map } from 'immutable'
import { BaseConsignmentEvent, ConsignmentEvent, ConsignmentIdType } from '../types/coreEntitiesTypes'
import { EventType } from '../utils/consignmentEvent'

const customerOrderDeviationsSelector = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'orderDeviations']) as Map<number, DeviationOrderPropsType>,
  (orderDeviations) => orderDeviations
)

export const customerOrderDeviationsIdSelector = createImmutableEqualSelector(
  customerOrderDeviationsSelector,
  (state: AppStateType, customerId: number) => customerId,
  (orderDeviations, customerId): Map<number, DeviationOrderPropsType> | null =>
    orderDeviations
      ? orderDeviations.filter(
          (orderDeviations: DeviationOrderPropsType) => orderDeviations.get('customerId') === customerId
        )
      : null
)

export const consignmentContainsDeviationInEventsSelector = createImmutableEqualSelector(
  (eventsMap: Map<ConsignmentIdType, List<ConsignmentEvent>>) => eventsMap,
  (eventsMap) =>
    !!eventsMap.find(
      (events: List<ConsignmentEvent>) =>
        !!events.find((event) =>
          [EventType.DEVIATED, EventType.DEVIATED_MANUALLY_OVERRIDDEN].includes(event.get('type'))
        )
    )
)

export const getEventWithCustomerUniqueDeviationSelector = createImmutableEqualSelector(
  (event: BaseConsignmentEvent, deviations: Map<number, DeviationOrderPropsType>) => {
    const customerUniqueDeviation = deviations?.find(
      (e: DeviationOrderPropsType) => e.get('deviationCode') == event.get('data')?.get('deviation')
    )
    return event.withMutations((mutator) => {
      customerUniqueDeviation && mutator.setIn(['data', 'customerUniqueDeviation'], customerUniqueDeviation)
    })
  },
  (event) => event
)
