import React from 'react'
import styled from 'styled-components'
import variables from '../styles/variables'

export const DescriptionList = styled.dl<{ flex?: boolean }>`
  margin: 0;
  ${(props) => props.flex && 'display: flex;'}
`

export const TermDescriptionGroup: React.FC<{
  term: string
  description?: string | null
  descriptionWithLineBreaks?: string[] | null
  descriptionWithJsx?: JSX.Element | null
  inline?: boolean
  topMargin?: boolean
  noMargin?: boolean
  ellipsis?: boolean
  oneLiner?: boolean
  rightMargin?: boolean
}> = ({
  term,
  description,
  descriptionWithJsx,
  descriptionWithLineBreaks,
  inline,
  topMargin,
  noMargin,
  ellipsis,
  oneLiner,
  rightMargin
}) => (
  <StyledTermDescriptionGroup
    inline={inline}
    topMargin={topMargin}
    noMargin={noMargin}
    ellipsis={ellipsis}
    rightMargin={rightMargin}
  >
    <Term>{term}</Term>
    {descriptionWithJsx && <Description>{descriptionWithJsx}</Description>}
    {descriptionWithLineBreaks && (
      <Description>
        {descriptionWithLineBreaks.map((it) => (
          <div key={it}>{it}</div>
        ))}
      </Description>
    )}
    {description && (
      <Description
        title={(ellipsis && description) || ''}
        missingDescription={!description}
        ellipsis={ellipsis}
        oneLiner={oneLiner}
      >
        {description || '–'}
      </Description>
    )}
  </StyledTermDescriptionGroup>
)

const StyledTermDescriptionGroup = styled.dl<{
  inline?: boolean
  topMargin?: boolean
  noMargin?: boolean
  ellipsis?: boolean
  rightMargin?: boolean
}>`
  margin: ${(props) => (props.noMargin ? '0' : '0 2.5rem 0 0')};
  ${(props) => props.rightMargin && 'margin-right: 2.5rem;'}
  ${(props) => props.topMargin && 'margin-top: 0.625rem;'}
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  ${(props) =>
    props.ellipsis &&
    `
    vertical-align: bottom;
    white-space: nowrap;
    overflow: hidden;
  `}
`

const Term = styled.dt`
  color: ${variables.newColors.darkGrey};
  font-size: 0.75rem;
`

const Description = styled.dd<{ missingDescription?: boolean; ellipsis?: boolean; oneLiner?: boolean }>`
  color: ${(props) => (props.missingDescription ? variables.newColors.stellarGrey : variables.newColors.black)};
  font-size: 1rem;
  margin: 0;
  ${(props) => props.oneLiner && 'white-space: nowrap;'}
  ${(props) =>
    props.ellipsis &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`
