import { Map } from 'immutable'
import {
  WEB_SOCKET_ADD_CONTEXTS,
  WEB_SOCKET_REMOVE_CONTEXTS,
  WEB_SOCKET_SET_CONTEXT,
  WEB_SOCKET_SET_CONTEXT_CUSTOMERS,
  WEB_SOCKET_SET_CONTEXT_DATES,
  WEB_SOCKET_SET_CONTEXT_DEPARTMENTS
} from '../actions/actionTypes'
import { CustomerIdType } from '../domain/customer'
import { DepartmentIdType } from '../types/coreEntitiesTypes'
import { WebSocketContext, WebSocketContextImmutable, WebSocketContextTypes } from '../ws/wsUtils'

interface SetContextAction {
  type: typeof WEB_SOCKET_SET_CONTEXT
  departmentIds: DepartmentIdType[]
  dates: string[]
  customerIds: CustomerIdType[]
  contexts: WebSocketContextTypes[]
}

interface SetContextCustomers {
  type: typeof WEB_SOCKET_SET_CONTEXT_CUSTOMERS
  customerIds: CustomerIdType[]
}

interface SetContextDepartments {
  type: typeof WEB_SOCKET_SET_CONTEXT_DEPARTMENTS
  departmentIds: DepartmentIdType[]
}

interface SetContextDates {
  type: typeof WEB_SOCKET_SET_CONTEXT_DATES
  dates: string[]
}

interface RemoveContexts {
  type: typeof WEB_SOCKET_REMOVE_CONTEXTS
  contexts: WebSocketContextTypes[]
}

interface AddContexts {
  type: typeof WEB_SOCKET_ADD_CONTEXTS
  contexts: WebSocketContextTypes[]
}

type ContextAction =
  | SetContextAction
  | AddContexts
  | SetContextCustomers
  | SetContextDepartments
  | SetContextDates
  | RemoveContexts

const initialState: WebSocketContextImmutable = Map({
  contexts: ['NONE'] as WebSocketContextTypes[],
  customerIds: [],
  dates: [],
  departmentIds: []
} as WebSocketContext)

export default (state = initialState, action: ContextAction) => {
  switch (action.type) {
    case WEB_SOCKET_SET_CONTEXT:
      return state.withMutations((it: WebSocketContextImmutable) =>
        it
          .set('departmentIds', action.departmentIds || state.get('departmentIds'))
          .set('dates', action.dates || state.get('dates'))
          .set('customerIds', action.customerIds || state.get('customerIds'))
          .set('contexts', action.contexts || state.get('contexts'))
      )
    case WEB_SOCKET_ADD_CONTEXTS:
      return state.set('contexts', state.get('contexts').concat(action.contexts))
    case WEB_SOCKET_REMOVE_CONTEXTS:
      const l = new Set(state.get('contexts'))
      action.contexts.forEach((it) => l.delete(it))
      return state.set('contexts', Array.from(l))
    case WEB_SOCKET_SET_CONTEXT_DATES:
      return state.set('dates', action.dates)
    case WEB_SOCKET_SET_CONTEXT_CUSTOMERS:
      return state.set('customerIds', action.customerIds)
    case WEB_SOCKET_SET_CONTEXT_DEPARTMENTS:
      return state.set('departmentIds', action.departmentIds)
    default:
      return state
  }
}
