import React from 'react'
import i18next from 'i18next'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { ImmutableMap } from '../../../../types/immutableTypes'
import { IConsignmentEvent } from '../../../../types/coreEntitiesTypes'
import { Text } from '@glow/ui-components'

export const ParcelLockerDeliverySelected = (props: {
  data: AnyData
  consignmentEvent: ImmutableMap<IConsignmentEvent>
}): React.ReactElement => {
  const pickupPointName = props.consignmentEvent.get('data')?.get('pickupPoint')?.get('name')

  return (
    <div className="flex flex-col gap-2">
      <Text as="span" textType="base" className="text-emphasis-medium">
        {i18next.t('consignmentEvent.deliveryOrderedByReceiver')}
      </Text>
      {pickupPointName && (
        <div className="flex">
          <Text as="span" textType="base" className="text-emphasis-high" bold>
            {i18next.t('consignmentEvent.name')}
            :&nbsp;
          </Text>
          <Text as="span" textType="base" className="text-emphasis-medium">
            {pickupPointName}
          </Text>
        </div>
      )}
    </div>
  )
}
