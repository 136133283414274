import i18next from 'i18next'
import Immutable, { Collection, fromJS, List, Map, Seq, Set } from 'immutable'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import toString from 'lodash/toString'
import * as React from 'react'
import { connect } from 'react-redux'
// @ts-expect-error
import { Form } from 'react-redux-form/lib/immutable'
import { AnyAction, compose } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import {
  AUTOSUGGEST_ADDRESSES,
  AUTOSUGGEST_SUBCUSTOMERS,
  CREATE_OR_UPDATE_ADDRESS,
  CREATE_ORDER_OPTIMUS,
  DELETE_ORDERS,
  GET_HD_SERVICES_CHECKOUT,
  GET_NUMBER_SERIES_VALUES,
  GET_ORDER_PRICE_DATA,
  GET_SERVICES_OPTIMUS
} from '../../actions/actionTypes'
import { AddressOptions, createOrUpdateAddress } from '../../actions/creators/addressHelpers'
import {
  createOrUpdateOrder,
  deleteOrders,
  getAllDepartmentsWithAdminLevelAccess,
  getCustomerEventEmail,
  getDeliveryWindows,
  getDepartmentsForSameCountry,
  getOrderPrice,
  getServicesAndVasesTexts,
  getUnits,
  setPageStateValue,
  subcustomersByAlystraId,
  updateCustomerInformation
} from '../../actions/creators/helpers'
import { Loading } from '../../components/Loading'
import { api, getHdServices, getServicesFromOptimus, validatePostalCode } from '../../http/httpHelper'
import { PrimaryButton, SecondaryButton } from '../../primitives/Button'
import ErrorContainer from '../../primitives/ErrorContainer'
import { H1 } from '../../primitives/Headings'
import ImmutableComponent from '../../primitives/ImmutableComponent'
import SuccessContainer from '../../primitives/SuccessContainer'
import { unitsInDepartmentSelector } from '../../selectors/unitSelectors'
import {
  allDepartmentsInSameCountrySelector,
  allDepartmentsSelector,
  departmentFromIdSelector,
  departmentsSelector
} from '../../selectors/departmentsSelector'
import { errorMessagesFor, errorMessagesOptimusFor, successMessagesFor } from '../../selectors/httpStatusSelectors'
import {
  Consignment,
  DangerousGoods,
  Department,
  DepartmentIdType,
  isBaggageOrder,
  isHdDepartment,
  isHdOrder,
  Order,
  OrderIdType,
  SlotIdType
} from '../../types/coreEntitiesTypes'
import { ImmutableMap, isNotNullOrUndefined } from '../../types/immutableTypes'
import { getSelectedService } from '../../utils/alystraUtils'
import { AppStateType } from '../../utils/appStateReduxStore'
import { concat, isNotEmpty, keyIn } from '../../utils/collectionUtils'
import { uuidv4 } from '../../utils/uuid'
import {
  createAddressFromRecipient,
  createConsignmentSet,
  emptyVAS,
  formValuesToGroupPickup,
  formValuesToGroupRecipient,
  getPhonePrefixFromDepartment,
  isEdit,
  isEditOrRestore,
  measurements,
  prepareClearComponentMap,
  shouldUpdateCustomerAddress,
  sortByName,
  stripEmptyPhonePrefix
} from './bookingOrderFunctions'
import {
  CorrectedArticlePrice,
  IAddress,
  IAlystraPrice,
  ICustomer,
  ICustomerEventEmail,
  IMeasurement,
  IOptimusRouting,
  IOrderForm,
  IOrderFormProps,
  IPricedArticle,
  IPriceExplanation
} from './bookingOrderTypes'
import { acceptableMeasurements, getCustomerEventEmailsData, mergeDangerousGoods, OrderAction } from './bookingSupport'
import BookingFormCustomer from './bookingFormComponents/BookingFormCustomer'
import InputGroup from './InputGroup'
import { GroupRecipientProps } from './MultistopGroupForm'
import BookingFormInvoiceInformation from './bookingFormComponents/BookingFormInvoiceInformation'
import BookingFormPickup, { pickupFields } from './bookingFormComponents/BookingFormPickup'
import BookingFormDelivery, { recipientFields } from './bookingFormComponents/BookingFormDelivery'
import BookingFormGoods from './bookingFormComponents/BookingFormGoods'
import BookingFormServices from './bookingFormComponents/BookingFormServices'
import { BookingFormPreplan } from './bookingFormComponents/BookingFormPreplan'
import { IOptimusService } from '../../domain/optimusService'
import { getButtonTitle } from './bookingFormComponents/commonHelpers'
import {
  customerRoutingAtDeliverySelector,
  customerServicesSelector,
  servicesAndVasesTextsPerCodeSelector,
  standardRoutingAtDeliverySelector,
  standardServicesSelector
} from '../../selectors/servicesSelectors'
import {
  addToFormList,
  changeFormValue,
  mergeFormValues,
  preLoadForm,
  removeFromFormList,
  resetForm,
  setTouched,
  setUntouched,
  setValidity,
  submit
} from '../../actions/creators/formHelpers'
import { addNotification } from '../../actions/creators/helpersNotifications'
import { isAdminUser } from '../../utils/roles'
import { sortDepartmentsAccessToFirst } from '../../components/MoveShipmentsToAnotherDepartmentModal'
import { CustomerIdType } from '../../domain/customer'
import {
  deliveryDaysForCustomerAndPostalCode,
  deliveryTimeWindowsForCustomerAndPostalCode
} from '../../selectors/zoneDeliverySelectors'
import { DeliveryTimeWindow, DeliveryTimeWindowIdType } from '../../domain/deliveryTimeWindow'
import { ContextType } from './InstantGrid'
import { dangerousGoodsSelector } from '../../selectors/dangerousGoodsSelector'
import { GroupedServicesAndVasesTexts, ServiceVasText } from '../../utils/serviceUtils'
import { ThunkDispatch as BaseThunkDispatch } from '../../actions/creators/baseHelpers'
import { RouteComponentProps, withRouter } from '../../hooks/withRouter'

interface LocalStateProps {
  isSaving: boolean
  additionalErrors: List<string> | null
}

class BookingForm extends ImmutableComponent<Props, LocalStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isSaving: false,
      additionalErrors: null
    }
  }

  componentDidMount(): void {
    this.props.getUnits(Set.of(this.props.departmentId))
    this.props.getAllDepartmentsWithAdminLevelAccess()
    this.props.getDepartmentsForSameCountry(this.props.departmentId, 'admin')
    this.props.getServicesAndVasesTexts(ServiceVasText.InternalNameText)
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      this.props.orderAction !== OrderAction.Create &&
      prevProps.isLoading !== this.props.isLoading &&
      this.props.order
    ) {
      this.props.getCustomerEventEmail(this.props.order.get('customerId'))
    }
    this.getServicesOnChange(prevProps, this.props)
    if (isNotEmpty(this.props.errorMessages) && this.props.showErrorAlert) {
      window.scrollTo(0, 0)
      this.props.setPageStateValue('showErrorAlert', false)
    }
  }

  componentWillUnmount() {
    setTimeout(() => this.props.resetForm('createOrderForm'), 200)
  }

  onDeleteShipment(orderId: OrderIdType) {
    if (confirm(i18next.t('consignment.deleteConfirmation'))) {
      this.props.deleteOrders(
        Set([orderId]),
        `/${ContextType.Planner}/${this.props.departmentId}/dispatch`,
        this.props.router.navigate
      )
    }
  }

  onCustomerChanged = (customer: ICustomer) => {
    const isDefaultSenderAddressPresent = isNotNullOrUndefined(customer.get('defaultSenderAddressId'))
    const pickupName = (isDefaultSenderAddressPresent ? customer.get('addressName') : customer.get('name')) || ''
    if (shouldUpdateCustomerAddress(this.props.formValues, this.props.department)) {
      const values = Map({
        customerId: customer.get('alystraId'),
        pickupName,
        pickupAddress: (isDefaultSenderAddressPresent ? customer.get('addressAddress') : customer.get('address')) || '',
        pickupZipCode: (isDefaultSenderAddressPresent ? customer.get('addressZipCode') : customer.get('zipCode')) || '',
        pickupZipArea: (isDefaultSenderAddressPresent ? customer.get('addressZipArea') : customer.get('zipArea')) || '',
        pickupCountryCode:
          (isDefaultSenderAddressPresent
            ? customer.get('addressCountryCode') || customer.get('addressCountry')
            : customer.get('countryCode')) || '',
        pickupPhone: customer.get('addressPhone') || getPhonePrefixFromDepartment(this.props.department),
        pickupContactPerson: customer.get('addressContactPerson') || '',
        pickupInstructions: customer.get('addressInstructions') || '',
        customerInfo: customer.get('information') || '',
        pickupAddressLat: customer.get('addressLat'),
        pickupAddressLng: customer.get('addressLng')
      }).filter((v) => v !== null && v != undefined)
      this.props.mergeFormValues('createOrderForm', values)
    } else {
      const values = Map({
        customerId: customer.get('alystraId'),
        customerInfo: customer.get('information') || ''
      }).filter((v) => v !== null && v != undefined)
      this.props.mergeFormValues('createOrderForm', values)
    }
    this.props.setPageStateValue(AUTOSUGGEST_ADDRESSES + 'createOrderForm.pickupName', pickupName)
    this.props.setPageStateValue('selectedCustomerId', customer.get('id'))
    this.props.setPageStateValue('selectedCustomerCreditBlocked', customer.get('creditBlocked'))
    this.props.setPageStateValue('originalCustomerInformation', customer.get('information'))
    this.props.getCustomerEventEmail(customer.get('id'))
    this.props.subcustomersByAlystraId(customer.get('alystraId'))
    this.props.mergeFormValues('createOrderForm', Map({ additionalServices: List([emptyVAS()]) }))
    this.props.setPageStateValue('showEditCustomerSpecificInformation', false)
    this.props.setPageStateValue('scanToCreatePackages', customer.get('scanToCreatePackages'))
  }

  getServicesOnChange = (prevProps: Readonly<Props>, props: Readonly<Props>) => {
    const previousCustomer = prevProps.formValues.filter(keyIn('customerId', 'subcustomer'))
    const previousPickup = BookingForm.getFirstPickup(prevProps).filter(keyIn('zipCode', 'countryCode'))
    const previousDelivery = BookingForm.getFirstRecipient(prevProps).filter(keyIn('zipCode', 'countryCode'))
    const currentCustomer = props.formValues.filter(keyIn('customerId', 'subcustomer'))
    const currentPickup = BookingForm.getFirstPickup(props).filter(keyIn('zipCode', 'countryCode'))
    const currentDelivery = BookingForm.getFirstRecipient(props).filter(keyIn('zipCode', 'countryCode'))
    if (
      !previousCustomer.equals(currentCustomer) ||
      !previousPickup.equals(currentPickup) ||
      !previousDelivery.equals(currentDelivery)
    ) {
      this.getServices(
        currentCustomer.get('customerId'),
        currentCustomer.get('subcustomer') || null,
        currentPickup.get('countryCode'),
        currentPickup.get('zipCode'),
        currentDelivery.get('countryCode'),
        currentDelivery.get('zipCode'),
        isHdOrder(this.props.order) || isHdDepartment(this.props.department),
        isBaggageOrder(this.props.order)
      )
    }
    if (
      props.selectedCustomerId &&
      props.serviceCode &&
      currentDelivery.get('zipCode') &&
      (props.selectedCustomerId !== prevProps.selectedCustomerId ||
        prevProps.serviceCode !== props.serviceCode ||
        currentDelivery.get('zipCode') !== previousDelivery.get('zipCode'))
    ) {
      this.props.getDeliveryWindows(props.selectedCustomerId, currentDelivery.get('zipCode'), props.serviceCode)
    }
  }

  getServices = debounce(
    (
      customerNumber: string,
      subcustomer: string | null,
      pickupCountryCode: string,
      pickupPostalCode: string,
      deliveryCountryCode: string,
      deliveryPostalCode: string,
      hdOrder: boolean,
      baggageOrder: boolean
    ) => {
      this.props.validatePostalCode(pickupPostalCode, pickupCountryCode).then((isPickupPostalCodeValid: boolean) => {
        if (customerNumber && isPickupPostalCodeValid) {
          this.props
            .validatePostalCode(deliveryPostalCode, deliveryCountryCode)
            .then((isDeliveryPostalCodeValid: boolean) => {
              if (isDeliveryPostalCodeValid) {
                if (hdOrder) {
                  this.props.getHdServices(customerNumber, subcustomer, deliveryCountryCode, deliveryPostalCode)
                } else if (baggageOrder) {
                  this.props.getHdServices(customerNumber, subcustomer, deliveryCountryCode, deliveryPostalCode)
                  this.props.getServicesFromOptimus(
                    customerNumber,
                    subcustomer,
                    pickupCountryCode,
                    pickupPostalCode,
                    deliveryCountryCode,
                    deliveryPostalCode
                  )
                } else {
                  this.props.getServicesFromOptimus(
                    customerNumber,
                    subcustomer,
                    pickupCountryCode,
                    pickupPostalCode,
                    deliveryCountryCode,
                    deliveryPostalCode
                  )
                }
              } else {
                this.props.getServicesFromOptimus(customerNumber, subcustomer, pickupCountryCode, pickupPostalCode)
              }
            })
        }
      })
    },
    500
  )

  servicesLoaded = () => {
    const pickup = BookingForm.getFirstPickup(this.props)
    return !!(this.props.formValues.get('customerId') && pickup.get('zipCode') && pickup.get('countryCode'))
  }

  getShipmentAndPackageIds = (numShipments: number, numPackages: number) => {
    const numShipmentIds = isEditOrRestore(this.props.orderAction) ? 0 : numShipments
    if (numPackages > 0) {
      return this.props.getShipmentAndPackageIds(numShipmentIds, numPackages)
    } else {
      // Dont re fetch if we already have them to prevent using up unnecessary numbers
      return Promise.resolve()
    }
  }

  onSubmit = (values: IOrderForm, order?: Order, department?: Department, pricedArticles?: List<IPricedArticle>) => {
    const packagesPerShipmentCount = this.getNewPackageIdsCount(values.get('measurements'))
    const pickupCount = BookingForm.getAllPickups(this.props).size
    const recipientCount = BookingForm.getAllRecipients(this.props).size

    const correctedArticlePrices = fromJS(values.get('correctedArticlePrices')) || List()
    let priceDiffs = correctedArticlePrices
      .map((cap: any | CorrectedArticlePrice) => {
        const correctedCustomerPrice = cap?.get('customerPrice')
        const correctedResourcePrice = cap?.get('resourcePrice')
        return {
          articleCode: cap?.get('articleCode'),
          customerPrice:
            Number(correctedCustomerPrice) !==
            Number(
              pricedArticles?.find((dl) => dl?.get('articleCode') === cap?.get('articleCode'))?.get('customerPrice')
            )
              ? correctedCustomerPrice
              : undefined,
          resourcePrice:
            Number(correctedResourcePrice) !==
            Number(
              pricedArticles?.find((dl) => dl?.get('articleCode') === cap?.get('articleCode'))?.get('resourcePrice')
            )
              ? correctedResourcePrice
              : undefined
        }
      })
      .filter((cap) => !!cap.customerPrice || !!cap.resourcePrice)

    const updatedFormValues = values.withMutations((v) => {
      v.setIn(['correctedArticlePrices'], priceDiffs)
      v.setIn(
        ['calculatedArticlePrices'],
        pricedArticles?.map((pa) => ({
          articleCode: pa.get('articleCode'),
          customerPrice: pa.get('customerPrice'),
          resourcePrice: pa.get('resourcePrice')
        }))
      )
    })

    return this.getShipmentAndPackageIds(
      pickupCount * recipientCount,
      packagesPerShipmentCount * recipientCount * pickupCount
    ).then(() => this.doSubmit(updatedFormValues, order, department))
  }

  getNewPackageIdsCount = (measurements: List<IMeasurement>) => {
    return isEditOrRestore(this.props.orderAction)
      ? measurements.filterNot((it: IMeasurement) => it.has('packageId')).count()
      : measurements.count()
  }

  static getFirstPickup = (props: Props) =>
    BookingForm.getAllPickups(props).first() as ImmutableMap<GroupRecipientProps>

  static getAllPickups = (props: Props) => {
    const groupPickups = props.formValues.get('groupPickups') || List<ImmutableMap<GroupRecipientProps>>()
    return BookingForm.hasAtLeastOneAdditionalPickup(props.formValues) &&
      BookingForm.pickupFieldsAreEmpty(props.formValues)
      ? groupPickups
      : groupPickups.push(formValuesToGroupPickup(props.formValues, getPhonePrefixFromDepartment(props.department)))
  }

  static getFirstRecipient = (props: Props) =>
    BookingForm.getAllRecipients(props).first() as ImmutableMap<GroupRecipientProps>

  static getAllRecipients = (props: Props) => {
    const groupRecipients = props.formValues.get('groupRecipients') || List<ImmutableMap<GroupRecipientProps>>()
    return BookingForm.hasAtLeastOneAdditionalRecipient(props.formValues) &&
      BookingForm.recipientFieldsAreEmpty(props.formValues)
      ? groupRecipients
      : groupRecipients.push(
          formValuesToGroupRecipient(props.formValues, getPhonePrefixFromDepartment(props.department))
        )
  }

  clearBookingComponents = (components: List<string>) => {
    const clearDataMap = components
      .map((component: string) => prepareClearComponentMap(component, this.props.department))
      .reduce((acc: Map<string, any>, next) => acc.merge(next))
    this.props.mergeFormValues('createOrderForm', clearDataMap)
    components.contains('additionalServices') && this.props.setPageStateValue('showPickupDeliveryTimes', false)
    if (components.contains('customerId')) {
      this.props.setPageStateValue('selectedCustomerId', null)
      this.props.setPageStateValue('originalCustomerInformation', null)
    }
  }

  doSubmit = (values: IOrderForm, order?: Order, department?: Department) => {
    const hdOrder = isHdOrder(order) || isHdDepartment(department)
    const baggageOrder = isBaggageOrder(order)
    const service = getSelectedService(values.get('serviceId'), this.props.services, this.props.customizedServices)

    // TODO: No feedback on fail
    if (!service && !hdOrder) return

    if (
      !(hdOrder || baggageOrder) &&
      (!values.get('pickupDate') ||
        !values.get('pickupTimeEarliest') ||
        !values.get('pickupTimeLatest') ||
        !values.get('deliveryTimeEarliest') ||
        !values.get('deliveryTimeLatest'))
    ) {
      console.error('Booking date or time is undefined', values.toJS())
      return
    }
    this.setState({ isSaving: true })

    const { orderAction, consignments } = this.props
    const shipmentIds: List<string> = isEditOrRestore(orderAction)
      ? List.of(toString(order && order.get('shipmentId')))
      : this.props.shipmentIds.map(toString)
    const packageIdsQueue = this.props.packageIds.toJS() as number[]
    const legSeqNumber = isEdit(orderAction) && order ? order.get('multilegSeqNumber') : null
    const multilegId = (isEdit(orderAction) && order && order.get('multilegId')) || null
    const phonePrefix = getPhonePrefixFromDepartment(this.props.department)
    const pickupPhone = stripEmptyPhonePrefix(values.get('pickupPhone'), phonePrefix)
    const pickupSecondPhoneUnstripped = values.get('pickupSecondPhone')
    const pickupSecondPhone = pickupSecondPhoneUnstripped
      ? stripEmptyPhonePrefix(pickupSecondPhoneUnstripped, phonePrefix)
      : null
    const shouldGroup = !!values.get('groupOrders')
    const groupId = isEdit(orderAction) ? values.get('groupId') || null : shouldGroup ? uuidv4() : null
    const groupName = isEdit(orderAction) || shouldGroup ? values.get('groupName') || null : null

    const recipients = BookingForm.getAllRecipients(this.props)
    const pickups = BookingForm.getAllPickups(this.props)
    const consignmentSets: List<any> = pickups.flatMap((pickup, pickupIndex) =>
      recipients.map((recipient, index) => {
        const groupIndex = pickupIndex * recipients.size + index
        const shipmentId = shipmentIds.get(groupIndex) || ''
        const deliveryPhone = stripEmptyPhonePrefix(recipient.get('phone'), phonePrefix)
        const deliverySecondPhoneUnstripped = values.get('deliverySecondPhone')
        const deliverySecondPhone = deliverySecondPhoneUnstripped
          ? stripEmptyPhonePrefix(deliverySecondPhoneUnstripped, phonePrefix)
          : null
        return createConsignmentSet(
          values,
          shipmentId,
          packageIdsQueue,
          service || null,
          pickupPhone,
          pickupSecondPhone,
          pickup,
          deliveryPhone,
          deliverySecondPhone,
          recipient,
          groupIndex,
          legSeqNumber,
          multilegId,
          groupId,
          groupName,
          orderAction,
          consignments,
          isHdDepartment(department) ? 'HD' : null,
          this.props.scanToCreatePackages,
          order?.get('id'),
          mergeDangerousGoods(values.get('adrGoods'), this.props.dangerousGoods)
        )
      })
    )

    let messageSenderPartyId = 'glow'
    if (hdOrder) {
      messageSenderPartyId = 'HappyFlow'
    } else if (baggageOrder) {
      messageSenderPartyId = 'BaggageSolutions'
    }

    const payload = {
      // TODO: Add new PartyID for HD orders from Glow
      messageSenderPartyId,
      consignmentSets: consignmentSets.toJS()
    }
    pickups.forEach((r) => this.updateAddress(r))
    recipients.forEach((r) => this.updateAddress(r))

    this.props
      .createOrUpdateOrder(
        payload,
        `/${ContextType.Planner}/${this.props.departmentId}/confirmation/${shipmentIds.toJS()}`,
        this.props.router.navigate
      )
      .finally(() => this.setState({ isSaving: false }))
    this.props.setPageStateValue('showErrorAlert', true)
  }

  updateAddress = (party: ImmutableMap<GroupRecipientProps>) => {
    const newAddress = createAddressFromRecipient(party)
    this.props.createOrUpdateAddress(newAddress)
  }

  setFieldsValidity = (validity: boolean, fields: List<keyof IOrderFormProps>) => {
    fields.map((f) => 'createOrderForm.' + f).forEach((f) => this.props.setValidity(f, validity))
  }

  static formFieldIsEmpty = (formValues: IOrderForm, fieldName: keyof IOrderFormProps) => {
    const value = formValues.get(fieldName)
    return isEmpty(value) || toString(value).startsWith('+')
  }

  static recipientFieldsAreEmpty = (formValues: IOrderForm) =>
    recipientFields.every((f) => BookingForm.formFieldIsEmpty(formValues, f))
  static pickupFieldsAreEmpty = (formValues: IOrderForm) =>
    pickupFields.every((f) => BookingForm.formFieldIsEmpty(formValues, f))

  static hasAtLeastOneAdditionalRecipient = (formValues: IOrderForm) =>
    !(formValues.get('groupRecipients') || List()).isEmpty()
  static hasAtLeastOneAdditionalPickup = (formValues: IOrderForm) =>
    !(formValues.get('groupPickups') || List()).isEmpty()

  onClickSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    const formValues = this.props.formValues
    if (BookingForm.recipientFieldsAreEmpty(formValues) && BookingForm.hasAtLeastOneAdditionalRecipient(formValues)) {
      this.setFieldsValidity(true, recipientFields)
    }
    if (BookingForm.pickupFieldsAreEmpty(formValues) && BookingForm.hasAtLeastOneAdditionalPickup(formValues)) {
      this.setFieldsValidity(true, pickupFields)
    }
    if (acceptableMeasurements(formValues.get('measurements'))) {
      this.props.submit('createOrderForm')
    } else {
      window.scrollTo(0, 0)
      this.setState({ additionalErrors: List.of(i18next.t('instant.booking.unacceptableMeasurementsError')) })
    }
  }

  render() {
    const {
      successMessages,
      orderAction,
      order,
      formValues,
      isLoading,
      subTitleKey,
      services,
      customizedServices,
      showPickupDeliveryTimes = true,
      sortedDepartments,
      allDepartments,
      units,
      customerEventEmailData,
      selectedCustomerId,
      selectedCustomerCreditBlocked,
      role,
      serviceTexts
    } = this.props

    if (isLoading) return <Loading />
    const shipmentId = order && order.get('shipmentId')
    const shipmentIdHeader = shipmentId ? ' - ' + shipmentId : ''
    const departmentIdOverridden = order ? order.get('departmentIdOverridden') : false
    const overriddenDepartmentId = departmentIdOverridden ? (order ? order.get('departmentId') : undefined) : undefined
    const creditBlockedCustomer = selectedCustomerCreditBlocked && !!selectedCustomerId
    const customerInfo = formValues.get('customerInfo')
    const errorMessages = concat(this.props.errorMessages, this.state.additionalErrors)
    return (
      <Form
        model="createOrderForm"
        onSubmit={(form: IOrderForm) => this.onSubmit(form, order, this.props.department, this.props.pricedArticles)}
      >
        <H1 size="l" style={{ marginTop: '.5rem' }}>
          {i18next.t(subTitleKey)}
          {shipmentIdHeader}
        </H1>

        {errorMessages && isNotEmpty(errorMessages) && (
          <ErrorContainer center>{errorMessages.join(', ')}</ErrorContainer>
        )}
        {isNotEmpty(successMessages) && <SuccessContainer center>{successMessages.join(', ')}</SuccessContainer>}

        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '0.75rem', marginTop: '0.75rem' }}>
          <BookingFormCustomer
            creditBlockedCustomer={creditBlockedCustomer}
            departmentId={this.props.departmentId}
            mergeFormValues={this.props.mergeFormValues}
            onCustomerChanged={this.onCustomerChanged}
            setPageStateValue={this.props.setPageStateValue}
            selectedCustomerId={selectedCustomerId}
            subCustomersForSelectedAlystraId={this.props.subCustomersForSelectedAlystraId}
            originalCustomerInformation={this.props.originalCustomerInformation}
            customerInfo={customerInfo}
            updateCustomerInformation={this.props.updateCustomerInformation}
            showEditCustomerSpecificInformation={this.props.showEditCustomerSpecificInformation}
            clearComponents={this.clearBookingComponents}
          />

          <BookingFormInvoiceInformation
            clearComponents={this.clearBookingComponents}
            isHdOrder={isHdOrder(order) || isBaggageOrder(order)}
          />

          <BookingFormPickup
            departmentId={this.props.departmentId}
            mergeFormValues={this.props.mergeFormValues}
            department={this.props.department}
            formValues={formValues}
            selectedCustomerId={selectedCustomerId}
            createOrUpdateAddress={this.props.createOrUpdateAddress}
            addNotification={this.props.addNotification}
            orderAction={orderAction}
            setTouched={this.props.setTouched}
            setUntouched={this.props.setUntouched}
            form={this.props.form}
            disableAdditionalPickupButton={BookingForm.hasAtLeastOneAdditionalRecipient(this.props.formValues)}
            formName={'createOrderForm'}
            clearComponents={this.clearBookingComponents}
          />

          <BookingFormDelivery
            formValues={this.props.formValues}
            mergeFormValues={this.props.mergeFormValues}
            setPageStateValue={this.props.setPageStateValue}
            departmentId={this.props.departmentId}
            department={this.props.department}
            orderAction={orderAction}
            setTouched={this.props.setTouched}
            setUntouched={this.props.setUntouched}
            form={this.props.form}
            disableAdditionalRecipientButton={BookingForm.hasAtLeastOneAdditionalPickup(this.props.formValues)}
            formName={'createOrderForm'}
            clearComponents={this.clearBookingComponents}
          />

          <BookingFormGoods
            formValues={this.props.formValues}
            mergeFormValues={this.props.mergeFormValues}
            addToFormList={this.props.addToFormList}
            removeFromFormList={this.props.removeFromFormList}
            availableMeasurements={measurements}
            formName={'createOrderForm'}
            clearComponents={this.clearBookingComponents}
          />

          <BookingFormServices
            formValues={formValues}
            showPickupDeliveryTimes={showPickupDeliveryTimes}
            services={services}
            priceInfo={this.props.priceInfo}
            priceExplanations={this.props.priceExplanations}
            pricedArticles={this.props.pricedArticles}
            routing={this.props.routing}
            customizedServices={customizedServices}
            customizedRouting={this.props.customizedRouting}
            departmentId={this.props.departmentId}
            isLoadingPrice={this.props.isLoadingPrice}
            getOrderPrice={this.props.getOrderPrice}
            setPageStateValue={this.props.setPageStateValue}
            mergeFormValues={this.props.mergeFormValues}
            addToFormList={this.props.addToFormList}
            removeFromFormList={this.props.removeFromFormList}
            servicesLoaded={this.servicesLoaded()}
            allDepartments={allDepartments}
            clearComponents={this.clearBookingComponents}
            hdOrder={isHdOrder(order) || isHdDepartment(this.props.department)}
            baggageOrder={isBaggageOrder(order)}
            deliveryTimeWindows={this.props.deliveryTimeWindows}
            deliveryDays={this.props.deliveryDays}
            serviceTexts={serviceTexts}
            correctedArticlePrices={this.props.correctedArticlePrices}
            orderId={order?.get('id')}
            slotId={this.props.slotId}
            role={role}
            needsZeroPriceApproval={this.props.needsZeroPriceApproval}
            hasZeroPriceApproval={this.props.hasZeroPriceApproval && orderAction === OrderAction.Edit}
          />

          <BookingFormPreplan
            units={units}
            mergeFormValues={this.props.mergeFormValues}
            overriddenDepartmentId={overriddenDepartmentId}
            sortedDepartments={sortedDepartments}
            selectedCustomerId={selectedCustomerId}
            customerEventEmailData={customerEventEmailData}
            orderAction={orderAction}
            role={role}
          />

          <InputGroup aria-label="Submit section">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <PrimaryButton
                type="button"
                style={{ minWidth: '160px' }}
                onClick={this.onClickSubmit}
                disabled={this.state.isSaving}
              >
                {getButtonTitle(orderAction)}
              </PrimaryButton>
              {orderAction === OrderAction.Edit && order && (
                <SecondaryButton
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault()
                    return this.onDeleteShipment(order.get('id'))
                  }}
                  disabled={this.state.isSaving}
                  style={{ minWidth: '160px' }}
                  marginLeft
                >
                  {i18next.t('consignment.deleteOrders')}
                </SecondaryButton>
              )}
            </div>
          </InputGroup>
        </div>
      </Form>
    )
  }
}

interface StateProps {
  departmentId: DepartmentIdType
  department: Department
  order?: Order
  orderId?: OrderIdType
  errorMessages: List<string> | null
  successMessages: List<any>
  formValues: IOrderForm
  services: List<IOptimusService>
  routing: IOptimusRouting
  customizedServices: List<IOptimusService>
  customizedRouting: IOptimusRouting
  units: List<any>
  sortedDepartments: List<Collection<number, Department>>
  allDepartments: List<Department>
  isLoadingPrice: boolean
  selectedCustomerId: number
  selectedCustomerCreditBlocked: boolean
  originalCustomerInformation?: string
  orderAction: OrderAction
  priceInfo?: IAlystraPrice
  priceExplanations?: List<IPriceExplanation>
  pricedArticles?: List<IPricedArticle>
  correctedArticlePrices?: List<IPricedArticle>
  isLoading: boolean
  subTitleKey: string
  shipmentIds: List<number>
  packageIds: List<number>
  showPickupDeliveryTimes: boolean
  showErrorAlert: boolean
  form: { [key: string]: any }
  customerEventEmailData: List<ICustomerEventEmail>
  subCustomersForSelectedAlystraId: Map<string, any>
  showEditCustomerSpecificInformation: boolean
  scanToCreatePackages: boolean
  role: string
  serviceCode: string
  deliveryDays: Set<number>
  deliveryTimeWindows: Map<DeliveryTimeWindowIdType, DeliveryTimeWindow>
  dangerousGoods: List<DangerousGoods>
  serviceTexts: GroupedServicesAndVasesTexts
  slotId?: SlotIdType
  needsZeroPriceApproval?: boolean
  hasZeroPriceApproval?: boolean
}

interface DispatchProps {
  setPageStateValue: (key: string, value: any) => void
  resetForm: (formName: string) => void
  preLoadForm: (formName: string, values: Map<string, any>) => void
  mergeFormValues: (model: string, values: Map<string, any>) => void
  changeFormValue: (formName: string, values: Map<string, any>) => void
  setTouched: (models: string) => void
  setUntouched: (models: string) => void
  addToFormList: (formName: string, values: Map<string, string>) => void
  removeFromFormList: (model: string, index: number) => void
  deleteOrders: (orderIds: Set<OrderIdType>, redirectUrl: string, push: (url: string) => void) => void
  createOrUpdateOrder: (payload: {}, redirectUrl: string, push: (url: string) => void) => any
  getServicesFromOptimus: (
    customerNumber: string,
    subcustomer: string | null,
    pickupCountryCode: string,
    pickupPostalCode: string,
    deliveryCountryCode?: string,
    deliveryPostalCode?: string
  ) => void
  getHdServices: (
    customerNumber: string,
    subcustomer: string | null,
    deliveryCountryCode: string,
    deliveryPostalCode: string
  ) => void
  getOrderPrice: (payload: Map<string, any>) => void
  updateCustomerInformation: (customerId: number, information: string) => void
  getShipmentAndPackageIds: (numShipmentIds: number, numPackageIds: number) => any
  getUnits: (departmentIds: Set<DepartmentIdType>) => void
  createOrUpdateAddress: (address: IAddress, options?: AddressOptions) => any
  addNotification: (props: { title: string; message?: string; timeout: number }) => void
  getAllDepartmentsWithAdminLevelAccess: () => void
  submit: (model: string, promise?: Promise<any>, options?: any) => void
  setValidity: (model: string, validity: boolean, options?: any) => void
  getCustomerEventEmail: (customerId: CustomerIdType) => void
  subcustomersByAlystraId: (alystraId: string) => void
  getDepartmentsForSameCountry: (departmentId: DepartmentIdType, accessLevel: string) => void
  getDeliveryWindows: (customerId: CustomerIdType, postalCode: string, alystraArticleCode: string) => void
  getServicesAndVasesTexts: (serviceVasTextType: ServiceVasText) => void
  validatePostalCode: (postalCode: string, countryCode: string) => (dispatch: BaseThunkDispatch) => Promise<any>
}

interface OwnProps {
  departmentId: number
  department?: Department
  order?: Order
  consignments?: Seq.Indexed<Consignment>
  orderId?: number
  orderAction: OrderAction
  subTitleKey: string
  isLoading: boolean
}

type PathParamsType = {}
type RouterType = RouteComponentProps<PathParamsType>

interface Props
  extends StateProps,
    RouterType,
    Omit<DispatchProps, 'validatePostalCode'>,
    Omit<OwnProps, 'department'> {
  validatePostalCode: (postalCode: string, countryCode: string) => Promise<boolean>
}

export default compose<any>(
  withRouter,
  connect<StateProps, DispatchProps, OwnProps, AppStateType>(
    (state, ownProps) => {
      const departmentId = ownProps.departmentId
      const department = departmentFromIdSelector(state, departmentId)

      const createOrderErrors = errorMessagesOptimusFor(state, CREATE_ORDER_OPTIMUS)
      const deleteOrderErrors = errorMessagesFor(state, DELETE_ORDERS)
      const getPriceErrors = errorMessagesFor(state, GET_ORDER_PRICE_DATA)
      const updateAddressErrors = errorMessagesFor(state, CREATE_OR_UPDATE_ADDRESS)
      const getServiceErrors = errorMessagesOptimusFor(state, GET_SERVICES_OPTIMUS)
      const getServiceCheckoutErrors = errorMessagesOptimusFor(state, GET_HD_SERVICES_CHECKOUT)
      const successMessages = successMessagesFor(state, CREATE_OR_UPDATE_ADDRESS)
      const getNumberSeriesValuesErrors = errorMessagesOptimusFor(state, GET_NUMBER_SERIES_VALUES)

      const formValues = state.getIn(['createOrderForm'])
      const form = state.getIn(['forms', 'createOrderForm'])
      const shipmentIds = state.getIn(['numberSeriesReducer', 'shipmentIds'])
      const packageIds = state.getIn(['numberSeriesReducer', 'packageIds'])

      const subCustomersForSelectedAlystraId = state.getIn(['searchReducer', AUTOSUGGEST_SUBCUSTOMERS]) || Map()
      const isAdmin = isAdminUser(state)

      const allDepartments = allDepartmentsSelector(state)
      const departmentsForUser = departmentsSelector(state)
      const departmentsInSameCountry = allDepartmentsInSameCountrySelector(state)
      const sortedDepartments = isAdmin
        ? List.of(allDepartments.sort(sortByName))
        : sortDepartmentsAccessToFirst(departmentsForUser, departmentsInSameCountry)
      const selectedCustomerId = state.getIn(['pageState', 'selectedCustomerId'])
      const serviceCode = formValues.get('serviceId') ? formValues.get('serviceId').split('___').reverse()[0] : ''
      const deliveryDays = deliveryDaysForCustomerAndPostalCode(
        state,
        selectedCustomerId,
        formValues.get('deliveryZipCode'),
        serviceCode
      )
      const deliveryTimeWindows = deliveryTimeWindowsForCustomerAndPostalCode(
        state,
        selectedCustomerId,
        formValues.get('deliveryZipCode'),
        serviceCode
      )
      const serviceTexts = servicesAndVasesTextsPerCodeSelector(state)

      const slotId = ownProps.consignments && ownProps.consignments?.first()?.get('slotId')

      return {
        units: unitsInDepartmentSelector(state, departmentId),
        sortedDepartments,
        allDepartments,
        departmentId,
        department: department,
        orderId: ownProps.orderId,
        order: ownProps.order,
        orderAction: ownProps.orderAction,
        isLoading: ownProps.isLoading,
        errorMessages: concat(
          createOrderErrors,
          deleteOrderErrors,
          getPriceErrors,
          getServiceErrors,
          getServiceCheckoutErrors,
          updateAddressErrors,
          getNumberSeriesValuesErrors
        ),
        successMessages,
        formValues,
        services: standardServicesSelector(state, isHdOrder(ownProps.order) || isHdDepartment(department)),
        routing: standardRoutingAtDeliverySelector(state),
        customizedServices: customerServicesSelector(
          state,
          isHdOrder(ownProps.order) || isHdDepartment(department),
          isBaggageOrder(ownProps.order)
        ),
        customizedRouting: customerRoutingAtDeliverySelector(state),
        priceInfo: state.getIn(['servicesFromOptimus', 'price', 'price']),
        pricedArticles: state
          .getIn(['servicesFromOptimus', 'price', 'orderImport', 'transportCharges'])
          ?.map((tc: any) =>
            Immutable.fromJS({
              articleCode: tc?.get('article')?.get('id'),
              customerPrice: tc?.get('customerTotalPrice'),
              resourcePrice: tc?.get('carrierTotalPrice'),
              articleLabel: tc?.get('article')?.get('description')
            })
          ),
        correctedArticlePrices: state
          .getIn(['entities', 'articlePriceOverrides'])
          ?.filter((apo: IPricedArticle) => apo?.get('orderId') === ownProps.orderId),
        priceExplanations: state.getIn(['servicesFromOptimus', 'price', 'explanations']),
        isLoadingPrice: state.getIn(['servicesFromOptimus', 'isLoadingPrice']),
        selectedCustomerId,
        selectedCustomerCreditBlocked: state.getIn(['pageState', 'selectedCustomerCreditBlocked']),
        scanToCreatePackages: state.getIn(['pageState', 'scanToCreatePackages']),
        originalCustomerInformation: state.getIn(['pageState', 'originalCustomerInformation']),
        shipmentIds,
        packageIds,
        subTitleKey: ownProps.subTitleKey,
        showPickupDeliveryTimes: state.getIn(['pageState', 'showPickupDeliveryTimes'], true),
        showErrorAlert: state.getIn(['pageState', 'showErrorAlert'], false),
        customerEventEmailData: getCustomerEventEmailsData(state),
        form,
        subCustomersForSelectedAlystraId,
        showEditCustomerSpecificInformation: state.getIn(['pageState', 'showEditCustomerSpecificInformation'], false),
        role: state.getIn(['user', 'role'], ''),
        serviceCode,
        deliveryDays,
        deliveryTimeWindows,
        dangerousGoods: dangerousGoodsSelector(state, ownProps.orderId),
        serviceTexts,
        slotId: slotId === null ? undefined : slotId,
        needsZeroPriceApproval: state.getIn(['servicesFromOptimus', 'price', 'needsZeroPriceApproval']),
        hasZeroPriceApproval: state.getIn(['servicesFromOptimus', 'price', 'hasZeroPriceApproval'])
      }
    },
    {
      resetForm,
      preLoadForm,
      mergeFormValues,
      changeFormValue,
      setTouched,
      setUntouched,
      addToFormList,
      removeFromFormList,
      setPageStateValue,
      createOrUpdateOrder,
      deleteOrders,
      getCustomerEventEmail,
      getServicesFromOptimus,
      getHdServices,
      getOrderPrice,
      updateCustomerInformation,
      getShipmentAndPackageIds,
      getUnits,
      getDepartmentsForSameCountry,
      createOrUpdateAddress,
      addNotification,
      getAllDepartmentsWithAdminLevelAccess,
      submit,
      setValidity,
      subcustomersByAlystraId,
      getDeliveryWindows,
      getServicesAndVasesTexts,
      validatePostalCode
    }
  )
)(BookingForm)

function getShipmentAndPackageIds(numShipments: number = 1, numPackages: number) {
  return (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    return dispatch(
      api.asyncPostJson(GET_NUMBER_SERIES_VALUES, '/number-series', {
        GSIN: numShipments,
        SSCC: numPackages
      })
    )
  }
}
