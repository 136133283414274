import React from 'react'
import { TertiaryButton } from '../primitives/Button'
import i18next from 'i18next'
import { Field, Form, Formik } from 'formik'
import { FormCheckbox } from '@glow/ui-components'
import { ThunkDispatch } from '../actions/creators/baseHelpers'
import { OrderIdType, PreAdviceStatus } from '../types/coreEntitiesTypes'
import { addNotification } from '../actions/creators/helpersNotifications'
import { useAppDispatch } from '../reducers/redux-hooks'
import { lockOrUnlockPreAdvice } from '../actions/creators/helpers'

type LockPreAdviceProps = {
  orderId: OrderIdType
  preAdviceStatus: PreAdviceStatus
}

type FormValues = {
  locked: boolean
}

const LockPreAdvice = ({ orderId, preAdviceStatus }: LockPreAdviceProps) => {
  const dispatch = useAppDispatch()
  const initialValues: FormValues = {
    locked: preAdviceStatus.get('preAdviceStatus') === 'PRE_ADVICE_LOCKED'
  }
  return (
    <div>
      <h3>{i18next.t('preadvice.preadvice')}</h3>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, formikHelpers) => handleSubmit(dispatch, formikHelpers.setSubmitting, orderId, values)}
      >
        {({ isSubmitting }) => (
          <Form>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <Field type="checkbox" name="locked" as={FormCheckbox} />
              <label style={{ marginRight: '1rem' }} htmlFor="locked">
                {i18next.t('preadvice.locked')}
              </label>
              <TertiaryButton type="submit" disabled={isSubmitting}>
                {i18next.t('button.save')}
              </TertiaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default LockPreAdvice

const handleSubmit = (
  dispatch: ThunkDispatch,
  setIsSubmitting: (isSubmitting: boolean) => void,
  orderId: OrderIdType,
  { locked }: FormValues
) =>
  dispatch(lockOrUnlockPreAdvice([orderId], locked, false))
    .then(() => dispatch(addNotification({ title: i18next.t('preadvice.updated'), timeout: 3000 })))
    .finally(() => setIsSubmitting(false))
