import i18next from 'i18next'
import { isCollection, List, Map } from 'immutable'
import React from 'react'
import { api } from '../http/httpHelper'
import { GridConsignmentEvent } from '../primitives/ConsignmentDrawer'
import { SmallText, StyledAnchor } from '../primitives/Typography'
import { ImmutableMap } from '../types/immutableTypes'
import {
  EventType,
  getDeliverWithoutSignatureReason,
  getOrderedDeliveryTime,
  getRecipientName,
  getToAndFromDepartment
} from '../utils/consignmentEvent'
import { format24HourTime, formatDateTime, toLocaleDate, withTimeAndDate, withWeekdayAndTime } from '../utils/dateTime'
import { getMapForDeviatedLocation, getMapForLocationCoordinates } from '../utils/mapCoordinates'
import { isNotEmpty } from '../utils/collectionUtils'
import { ConsignmentEventId, ISODateTimeString } from '../types/coreEntitiesTypes'
import { AnyData } from '../actions/creators/baseHelpers'
import { getDeviationText } from '../utils/deviation'
import { camelCaseToSplit, isEmpty } from '../utils/stringUtils'
import { ConsignmentEventConfirmed } from './ConsignmentEventConfirmed'
import { ConsignmentEventUnitAndDriver } from './ConsignmentEventUnitAndDriver'
import {
  AirExpressConsignmentEventTypes,
  AirExpressConsignmentManualOverrideEventTypes
} from '../types/airexpressConsignmentEventTypes'
import { DeliveryLocationDeviationHelpMessage } from '../pages/shipments/details-page/consignment-event-details/delivery-location-deviation'
import { ELLIPSIS, subStringWithSuffixOverLimit } from '@glow/common'
import { getName } from '../pages/shipments/details-page/EventsView'

export const NOT_APPLICABLE = 'N/A'

interface Props<DP, T> {
  id: ConsignmentEventId
  eventTime: ISODateTimeString
  type: T
  data?: ImmutableMap<DP> | null
  name?: string
  role?: string
  consignmentId: number
}

interface DeviatedProps
  extends Props<
    {
      deviation?: string
      location?: Map<string, any>
      comment?: string
      customerUniqueDeviation: AnyData
      unitName?: string
      userName?: string
    },
    EventType.DEVIATED | EventType.DEVIATED_MANUALLY_OVERRIDDEN
  > {}

export const Deviated = ({
  id,
  eventTime = '',
  data,
  type = EventType.DEVIATED,
  name = '',
  role = ''
}: DeviatedProps): React.ReactElement => {
  const deviationCode = data ? data.get('deviation') : null
  const deviatedLocation = data ? data.get('location') : Map<string, any>()
  const deviatedComment = data ? data.get('comment') : null
  const mapButton = deviatedLocation ? getMapForDeviatedLocation(deviatedLocation, type) : null
  const customerUniqueDeviation = data ? data.get('customerUniqueDeviation') : null
  return (
    <GridConsignmentEvent>
      <div style={{ display: 'inline-flex' }}>
        {withWeekdayAndTime(toLocaleDate(eventTime))} {mapButton}
      </div>
      <SmallText>{getName(role, name)}</SmallText>
      <p>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
        <br />
        <span>{getDeviationText(customerUniqueDeviation, deviationCode)}</span>
      </p>
      {deviatedComment && (
        <p>
          <strong>{i18next.t('consignmentEvent.message.comment')}</strong>
          <br />
          <span>{deviatedComment}</span>
        </p>
      )}
      <ConsignmentEventUnitAndDriver unitName={data?.get('unitName')} driverName={data?.get('userName')} />
      <ConsignmentEventConfirmed consignmentEventId={id} consignmentEventType={type} />
    </GridConsignmentEvent>
  )
}

export interface RejectedEventDataProps {
  reason?: string
  otherReason?: string
}

interface RejectedProps extends Props<RejectedEventDataProps, EventType.REJECTED> {}

export const Rejected = ({ eventTime = '', data, name = '', role = '' }: RejectedProps): React.ReactElement => {
  const rejectedReasonDescription = getRejectedReasonDescription(data?.get('reason'), data?.get('otherReason'))
  const showOtherReasonDescription = isShowOtherReasonDescription(data?.get('reason'), data?.get('otherReason'))
  return (
    <GridConsignmentEvent>
      <div style={{ display: 'inline-flex' }}>{withWeekdayAndTime(toLocaleDate(eventTime))}</div>
      <SmallText>{getName(role, name)}</SmallText>
      <div>
        <strong>{i18next.t('consignmentEvent.message.rejected')}</strong>
        <br />
        {rejectedReasonDescription && (
          <span>
            {i18next.t('consignmentEvent.rejectedReason.reason')}: {rejectedReasonDescription}
          </span>
        )}
        {showOtherReasonDescription && (
          <span>
            <SmallText>{i18next.t('consignmentEvent.message.comment')}: </SmallText>
            {data?.get('otherReason')}
          </span>
        )}
      </div>
    </GridConsignmentEvent>
  )
}

export const getRejectedReasonDescription = (rejectedReason: string | undefined, otherReason: string | undefined) => {
  const isRejectedReasonTypeOther = rejectedReason === 'other'
  const otherReasonDescription = otherReason
  const rejectedReasonDescription = i18next.t(`consignmentEvent.rejectedReason.${rejectedReason}`)
  return isRejectedReasonTypeOther
    ? otherReasonDescription || i18next.t('consignmentEvent.rejectedReason.other')
    : isNotEmpty(rejectedReason) && rejectedReasonDescription
}

export const isShowOtherReasonDescription = (rejectedReason: string | undefined, otherReason: string | undefined) =>
  !!((rejectedReason === 'CJ' || rejectedReason === 'CK' || rejectedReason === 'CL') && isNotEmpty(otherReason))

interface CommentProps extends Props<{ text?: string }, EventType.DEVIATED | EventType.DEVIATED_MANUALLY_OVERRIDDEN> {}

const Comment = ({ eventTime = '', data, name = '', role = '' }: CommentProps): React.ReactElement => {
  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <SmallText>
        {withWeekdayAndTime(toLocaleDate(eventTime))} {getName(role, name)}
      </SmallText>
      <div>{data && data.get('text')}</div>
    </GridConsignmentEvent>
  )
}

interface ShipmentDataChangedProps
  extends Props<
    { comment?: string },
    | EventType.PICKUP_CONSIGNMENT_DATA_CHANGED
    | EventType.SPECIFICATION_SIZE_CHANGED
    | EventType.SPECIFICATION_DESCRIPTION_CHANGED
    | EventType.DELIVERY_CONSIGNMENT_DATA_CHANGED
    | EventType.EXTERNAL_ADDRESS_WASH
    | EventType.ORDER_NOTE_CHANGED
    | EventType.PACKAGE_MEASUREMENTS_CHANGED
    | EventType.ORDER_DATA_CHANGED
    | EventType.PRE_ADVICE_STATUS_CHANGED
    | EventType.DELIVERY_TIME_UPDATED_FROM_HF_SYNC
    | EventType.SERVICE_UPGRADE_PURCHASED
    | AirExpressConsignmentEventTypes.FLIGHT_INFO_CHANGED
  > {}

type UpdatedShipmentEventData = Map<'from' | 'to', any>

const ShipmentDataUpdated = ({
  eventTime = '',
  data,
  type,
  name = '',
  role = ''
}: ShipmentDataChangedProps): React.ReactElement => {
  const fieldsUpdated = data?.filter((_, k) => k !== 'comment')
  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <SmallText>
        {withWeekdayAndTime(toLocaleDate(eventTime))} {getName(role, name)}
      </SmallText>
      <div>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
        <br />
        {data?.get('comment') && (
          <SmallText block>
            {i18next.t('consignment.comment')}: {data.get('comment')}
          </SmallText>
        )}
        {!fieldsUpdated?.isEmpty() && <UpdatedInfo fieldsUpdated={fieldsUpdated} />}
      </div>
    </GridConsignmentEvent>
  )
}

export const UpdatedInfo: React.FC<{ fieldsUpdated?: Map<string, any> }> = ({ fieldsUpdated }) => (
  <>
    <SmallText noMargin strong>
      {i18next.t('consignmentEvent.changedFrom')}
    </SmallText>
    {fieldsUpdated
      ?.map((v, k) => {
        const updatedInfo = v instanceof Map ? fromOldToNewValue(v as UpdatedShipmentEventData) : valueOrBlankText(v)
        return (
          <SmallText block noMargin key={k}>
            {camelCaseToSplit(k) + ': ' + updatedInfo}
          </SmallText>
        )
      })
      .valueSeq()}
  </>
)

export const fromOldToNewValue = (value: UpdatedShipmentEventData): string =>
  subStringWithSuffixOverLimit(valueOrBlankText(value.get('from')), 50, ELLIPSIS) +
  ' -> ' +
  subStringWithSuffixOverLimit(valueOrBlankText(value.get('to')), 50, ELLIPSIS)

const valueOrBlankText = (value: any): any =>
  isEmpty(value) ? i18next.t('consignmentEvent.blankText') : isCollection(value) ? value.toJS() : value

interface NotificationProps extends Props<{ reason?: string }, EventType.NOTIFICATION> {}

const Notification = ({
  eventTime = '',
  data,
  type = EventType.NOTIFICATION,
  name = '',
  role = ''
}: NotificationProps): React.ReactElement => {
  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <SmallText>
        {withWeekdayAndTime(toLocaleDate(eventTime))} {getName(role, name)}
      </SmallText>
      <div>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
        <br />
        {data && data.get('reason')}
      </div>
    </GridConsignmentEvent>
  )
}

interface IdCheckProps
  extends Props<
    {
      idCheckFailedResult?: string
      idCheckType?: string
      birthDate?: string
      idCheckOtherType?: string
    },
    EventType.NOTIFICATION
  > {}

const IdCheck = ({
  eventTime = '',
  data,
  type = EventType.NOTIFICATION,
  name = '',
  role = ''
}: IdCheckProps): React.ReactElement => {
  const idCheckFailedResult = data && data.get('idCheckFailedResult')
  const idCheckType = data && data.get('idCheckType')
  const isIdCheckTypeOther = idCheckType === 'other'
  const birthDate = data && data.get('birthDate')

  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <SmallText>
        {withWeekdayAndTime(toLocaleDate(eventTime))} {getName(role, name)}
      </SmallText>
      <div>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
        <br />
        {idCheckFailedResult &&
          i18next.t('consignmentEvent.idCheck.idCheckFailed', {
            idCheckFailedResult
          })}
        {!idCheckFailedResult && (
          <span>
            {isIdCheckTypeOther &&
              i18next.t('consignmentEvent.idCheck.idCheckSummaryOther', {
                otherType: data && data.get('idCheckOtherType'),
                birthDate
              })}
            {!isIdCheckTypeOther &&
              i18next.t('consignmentEvent.idCheck.idCheckSummary', {
                idCheckType,
                birthDate
              })}
          </span>
        )}
      </div>
    </GridConsignmentEvent>
  )
}

interface ImageProps extends Props<{ filename?: string; recipientNameText?: string }, EventType.IMAGE> {}

const Image = ({
  eventTime = '',
  type = EventType.IMAGE,
  data,
  name = '',
  role = ''
}: ImageProps): React.ReactElement => {
  const actualImage = getImageIfDefined(data)
  const recipientName = getRecipientName(data)
  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <SmallText>
        {withWeekdayAndTime(toLocaleDate(eventTime))} {getName(role, name)}
      </SmallText>
      <div>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
        <br />
      </div>
      {actualImage}
      {recipientName}
    </GridConsignmentEvent>
  )
}

type DamageData = ImmutableMap<{
  filenames: List<string>
  causeCode: string
  damageType: string
  courierInput: string
}>

interface DamagedProps extends Props<{ damage: DamageData }, EventType.DAMAGED> {}

const Damaged = ({
  id,
  eventTime = '',
  type = EventType.DAMAGED,
  data,
  name = '',
  role = ''
}: DamagedProps): React.ReactElement => {
  const damage: DamageData = data?.get('damage') || Map()
  const fileNames = damage.get('filenames') || List()
  const courierInput = damage.get('courierInput') || ''

  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <SmallText>
        {withWeekdayAndTime(toLocaleDate(eventTime))} {getName(role, name)}
      </SmallText>
      <div style={{ marginBottom: '0.5em' }}>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
        <br />
      </div>
      <div style={{ marginBottom: '0.5em' }}>
        {i18next.t('consignmentEvent.damaged.damageType')}: <br />
        {i18next.t(`consignmentEvent.damaged.type.${damage.get('damageType')}`, NOT_APPLICABLE)}
      </div>
      <div style={{ marginBottom: '0.5em' }}>
        {i18next.t('consignmentEvent.damaged.courierInput')}:{' '}
        {/^\s*?$/.test(courierInput) ? NOT_APPLICABLE : courierInput}
      </div>

      {fileNames.map((fileName, idx) => {
        return (
          <div key={`file-${fileName}`} style={{ marginBottom: 2 }}>
            <StyledAnchor href={`/api/upload-multiple/redirect/${fileName}`} target="_blank" rel="noopener noreferrer">
              {i18next.t('consignmentEvent.damaged.showPictureLink', {
                idx: idx + 1
              })}
            </StyledAnchor>
          </div>
        )
      })}
      <ConsignmentEventConfirmed consignmentEventId={id} consignmentEventType={type} />
    </GridConsignmentEvent>
  )
}

interface DeliveryTimeEstimateProps
  extends Props<{ adjustedTimeFrom: string; adjustedTimeTo: string }, EventType.DELIVERY_TIME_ESTIMATED> {}

const DeliveryTimeEstimate = ({
  eventTime = '',
  type = EventType.DELIVERY_TIME_ESTIMATED,
  data,
  name = '',
  role = ''
}: DeliveryTimeEstimateProps): React.ReactElement => {
  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <SmallText>
        {withWeekdayAndTime(toLocaleDate(eventTime))} {getName(role, name)}
      </SmallText>
      <div>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
      </div>
      {data &&
        `${format24HourTime(toLocaleDate(data.get('adjustedTimeFrom') as string))} - ${format24HourTime(
          toLocaleDate(data.get('adjustedTimeTo') as string)
        )}`}
    </GridConsignmentEvent>
  )
}

interface AssignedMessageProps extends Props<{ slotName?: string; unitName?: string; location?: {} }, EventType> {}

const AssignedMessage = ({
  eventTime = '',
  type = EventType.ASSIGNED,
  data = Map(),
  name = '',
  role = ''
}: AssignedMessageProps): React.ReactElement => {
  const mapButton = getMapForLocationCoordinates(data, type)
  const routeName = data?.get('slotName')
  const unitName = data?.get('unitName')

  const message =
    routeName && unitName
      ? i18next.t('consignmentEvent.message.plannedOnRouteMoreInfo', {
          routeName,
          unitName
        })
      : i18next.t('consignmentEvent.message.plannedOnRoute')

  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <div style={{ display: 'inline-flex' }}>
        {withWeekdayAndTime(toLocaleDate(eventTime))} {mapButton}
      </div>
      <SmallText>{getName(role, name)}</SmallText>
      <div>
        <strong>{message}</strong>
      </div>
    </GridConsignmentEvent>
  )
}

interface FirstEstimatedTimesSeenProps extends Props<{ from?: string; to?: string }, EventType> {}

const FirstEstimatedTimesSeenMessage = ({
  eventTime = '',
  type = EventType.FIRST_ESTIMATED_TIMES_SEEN,
  data = Map(),
  name = '',
  role = ''
}: FirstEstimatedTimesSeenProps): React.ReactElement => {
  const from = data?.get('from')
  const to = data?.get('to')
  const timeWindow = from && to && (
    <>
      <SmallText strong style={{ margin: '1rem 0 0 0' }}>
        {i18next.t('eventsList.timeWindow')}
        {' -'}
      </SmallText>
      <SmallText noMargin>
        {' '}
        {i18next.t('eventsList.from')}
        {': '}
        {formatDateTime(toLocaleDate(from))}
      </SmallText>
      <SmallText noMargin>
        {' '}
        {i18next.t('eventsList.to')}
        {': '}
        {formatDateTime(toLocaleDate(to))}
      </SmallText>
    </>
  )

  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <div style={{ display: 'inline-flex' }}>{withWeekdayAndTime(toLocaleDate(eventTime))} </div>
      <SmallText>{getName(role, name)}</SmallText>
      <div>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
      </div>
      <div>{timeWindow}</div>
    </GridConsignmentEvent>
  )
}

interface SignatureSmsSentMessageProps
  extends Props<{ url?: string; location?: {}; phoneNumber?: string }, EventType> {}

const SignatureSmsSentMessage = ({
  eventTime = '',
  type = EventType.SIGNATURE_SMS_SENT,
  data = Map(),
  name = '',
  role = ''
}: SignatureSmsSentMessageProps): React.ReactElement => {
  const mapButton = getMapForLocationCoordinates(data, type)

  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <div style={{ display: 'inline-flex' }}>
        {withWeekdayAndTime(toLocaleDate(eventTime))} {mapButton}
      </div>
      <SmallText>{getName(role, name)}</SmallText>
      <div>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
        {data?.get('phoneNumber') && <div>{data.get('phoneNumber')}</div>}
      </div>
    </GridConsignmentEvent>
  )
}

interface DefaultMessageProps
  extends Props<
    {
      filename?: string
      recipientNameText?: string
      location?: {}
      deliverWithoutSignatureReason?: string
      departmentName?: ImmutableMap<{ to: string; from: string }>
      eventNotes?: string
    },
    EventType
  > {}

const DefaultMessage = ({
  id,
  eventTime = '',
  type = EventType.CREATED,
  data = Map(),
  name = '',
  role = ''
}: DefaultMessageProps): React.ReactElement => {
  const actualImage = getImageIfDefined(data)
  const flexDeliveredImageLink = getImageLinkIfDefined(data, 'flexImageFilename')
  const recipientName = getRecipientName(data)
  const mapButton = getMapForLocationCoordinates(data, type)
  const deliverWithoutSignatureReason = getDeliverWithoutSignatureReason(data)
  const movedDepartment = getToAndFromDepartment(data)

  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <div style={{ display: 'inline-flex' }}>
        {withWeekdayAndTime(toLocaleDate(eventTime))} {mapButton}
      </div>
      <SmallText>{getName(role, name)}</SmallText>
      <div>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
      </div>
      {deliverWithoutSignatureReason}
      {actualImage}
      {recipientName}
      {flexDeliveredImageLink}
      {movedDepartment}
      <ConsignmentEventConfirmed consignmentEventId={id} consignmentEventType={type} />
    </GridConsignmentEvent>
  )
}

const AirExpressMessage = ({
  eventTime = '',
  type,
  name = '',
  role = '',
  data
}: DefaultMessageProps): React.ReactElement => {
  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <div style={{ display: 'inline-flex' }}>{withWeekdayAndTime(toLocaleDate(eventTime))}</div>
      <SmallText>{getName(role, name)}</SmallText>
      <div>
        <strong>{i18next.t(`airexpress.eventType.${type}`)}</strong>
      </div>
      {data?.get('eventNotes') && <div>{data?.get('eventNotes')}</div>}
    </GridConsignmentEvent>
  )
}

const AirExpressChangeMessage = ({
  eventTime = '',
  data,
  type,
  name = '',
  role = ''
}: ShipmentDataChangedProps): React.ReactElement => {
  const fieldsUpdated = data?.filter((_, k) => k !== 'comment')
  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <SmallText>
        {withWeekdayAndTime(toLocaleDate(eventTime))} {getName(role, name)}
      </SmallText>
      <div>
        <strong>{i18next.t(`airexpress.eventType.${type}`)}</strong>
        <br />
        {data?.get('comment') && (
          <SmallText block>
            {i18next.t('consignment.comment')}: {data.get('comment')}
          </SmallText>
        )}
        {!fieldsUpdated?.isEmpty() && <UpdatedInfo fieldsUpdated={fieldsUpdated} />}
      </div>
    </GridConsignmentEvent>
  )
}

interface DeliverManuallyProps
  extends Props<
    { recipientName?: string; unitName?: string; userName?: string },
    EventType.DELIVERED_MANUALLY_OVERRIDDEN
  > {}

const DeliveryManuallyOverriden = ({
  eventTime = '',
  data,
  type = EventType.DELIVERED_MANUALLY_OVERRIDDEN,
  name = '',
  role = ''
}: DeliverManuallyProps): React.ReactElement => {
  const recipientName = data ? data.get('recipientName') : null
  return (
    <GridConsignmentEvent>
      <div style={{ display: 'inline-flex' }}>{withWeekdayAndTime(toLocaleDate(eventTime))} </div>
      <SmallText>{getName(role, name)}</SmallText>
      <div>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
        <br />
        {recipientName && (
          <>
            {' '}
            <strong>{i18next.t('consignment.receivedBy')}</strong>
            <SmallText>{recipientName}</SmallText>{' '}
          </>
        )}
      </div>
      <ConsignmentEventUnitAndDriver unitName={data?.get('unitName')} driverName={data?.get('userName')} />
    </GridConsignmentEvent>
  )
}

interface CollectedManuallyOverriddenProps
  extends Props<
    { recipientName?: string; unitName?: string; userName?: string },
    EventType.COLLECTED_MANUALLY_OVERRIDDEN
  > {}

const CollectedManuallyOverridden = ({
  id,
  eventTime = '',
  type = EventType.COLLECTED_MANUALLY_OVERRIDDEN,
  data = Map(),
  name = '',
  role = ''
}: CollectedManuallyOverriddenProps): React.ReactElement => {
  const recipientName = getRecipientName(data)
  const mapButton = getMapForLocationCoordinates(data, type)

  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <div style={{ display: 'inline-flex' }}>
        {withWeekdayAndTime(toLocaleDate(eventTime))} {mapButton}
      </div>
      <SmallText>{getName(role, name)}</SmallText>
      <div>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
      </div>
      {recipientName}
      <ConsignmentEventUnitAndDriver unitName={data?.get('unitName')} driverName={data?.get('userName')} />
      <ConsignmentEventConfirmed consignmentEventId={id} consignmentEventType={type} />
    </GridConsignmentEvent>
  )
}

interface ReturnManuallyProps
  extends Props<
    { recipientNameText?: string; recipientName?: string; unitName?: string; userName?: string },
    EventType.RETURNED_MANUALLY_OVERRIDDEN
  > {}

const ReturnManuallyOverridden = ({
  eventTime = '',
  type = EventType.RETURNED_MANUALLY_OVERRIDDEN,
  name = '',
  role = '',
  data = Map()
}: ReturnManuallyProps): React.ReactElement => (
  <GridConsignmentEvent>
    <div style={{ display: 'inline-flex' }}>{withWeekdayAndTime(toLocaleDate(eventTime))} </div>
    <SmallText>{getName(role, name)}</SmallText>
    <div>
      <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
      <br />
    </div>
    {getRecipientName(data)}
    <ConsignmentEventUnitAndDriver unitName={data?.get('unitName')} driverName={data?.get('userName')} />
  </GridConsignmentEvent>
)

interface EtaSmsSentProps extends Props<{ calculated_eta: string }, EventType.ETA_SMS_SENT> {}

const EtaSmsSent = ({ eventTime = '', name = '', role = '', data, type = EventType.ETA_SMS_SENT }: EtaSmsSentProps) => {
  const calculatedEta = data && withTimeAndDate(toLocaleDate(data.get('calculated_eta')))
  return (
    <GridConsignmentEvent>
      <div style={{ display: 'inline-flex' }}>{withWeekdayAndTime(toLocaleDate(eventTime))} </div>
      <SmallText>{getName(role, name)}</SmallText>
      <div>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
        <br />
        {i18next.t('consignmentEvent.message.calculatedEta', {
          eta: calculatedEta
        })}
      </div>
    </GridConsignmentEvent>
  )
}

export type DeliveryTimeOrderedData = {
  deliveryDate?: string | null
  extraData?: string | null
  deliveryTimeEarliest?: UpdatedShipmentEventData | null
  deliveryTimeLatest?: UpdatedShipmentEventData | null
}

interface DeliveryTimeOrderedProps extends Props<DeliveryTimeOrderedData, EventType> {}

const DeliveryTimeOrdered = ({
  eventTime = '',
  name = '',
  role = '',
  data = Map(),
  type
}: DeliveryTimeOrderedProps) => (
  <GridConsignmentEvent>
    <div style={{ display: 'inline-flex' }}>{withWeekdayAndTime(toLocaleDate(eventTime))} </div>
    <SmallText>{getName(role, name)}</SmallText>
    <div>
      <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
      <br />
      {data?.get('deliveryTimeEarliest') && (
        <SmallText block noMargin key={'deliveryTimeEarliest'}>
          {i18next.t('consignment.earliestDeliveryTime') +
            ': ' +
            fromOldToNewValue(data?.get('deliveryTimeEarliest') as UpdatedShipmentEventData)}
        </SmallText>
      )}
      {data?.get('deliveryTimeLatest') && (
        <SmallText block noMargin key={'deliveryTimeLatest'}>
          {i18next.t('consignment.latestDeliveryTime') +
            ': ' +
            fromOldToNewValue(data?.get('deliveryTimeLatest') as UpdatedShipmentEventData)}
        </SmallText>
      )}
      {data?.get('deliveryDate') && getOrderedDeliveryTime(data)}
    </div>
  </GridConsignmentEvent>
)

export type ServiceUpgradePurchasedData = {
  deliveryTimeEarliest?: UpdatedShipmentEventData | null
  deliveryTimeLatest?: UpdatedShipmentEventData | null
}

interface ServiceUpgradePurchasedProps extends Props<ServiceUpgradePurchasedData, EventType> {}

const ServiceUpgradePurchased = ({
  eventTime = '',
  name = '',
  role = '',
  data = Map(),
  type
}: ServiceUpgradePurchasedProps) => (
  <GridConsignmentEvent>
    <div style={{ display: 'inline-flex' }}>{withWeekdayAndTime(toLocaleDate(eventTime))} </div>
    <SmallText>{getName(role, name)}</SmallText>
    <div>
      <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
      <br />
      <SmallText className="mb-2">{i18next.t('consignmentEvent.message.serviceUpgradePurchasedDescription')}</SmallText>
      {data?.get('deliveryTimeEarliest') && (
        <SmallText block noMargin key={'deliveryTimeEarliest'}>
          {i18next.t('consignment.earliestDeliveryTime') +
            ': ' +
            fromOldToNewValue(data?.get('deliveryTimeEarliest') as UpdatedShipmentEventData)}
        </SmallText>
      )}
      {data?.get('deliveryTimeLatest') && (
        <SmallText block noMargin key={'deliveryTimeLatest'}>
          {i18next.t('consignment.latestDeliveryTime') +
            ': ' +
            fromOldToNewValue(data?.get('deliveryTimeLatest') as UpdatedShipmentEventData)}
        </SmallText>
      )}
    </div>
  </GridConsignmentEvent>
)

export type InvoicingEventData = {
  initiationType?: 'automatic' | 'manual-department-level' | 'manual-order-level' | null
}

interface InvoicingEventProps extends Props<InvoicingEventData, EventType> {}

const InvoicingEventMessage = ({ eventTime = '', name = '', role = '', data = Map(), type }: InvoicingEventProps) => (
  <GridConsignmentEvent>
    <div style={{ display: 'inline-flex' }}>{withWeekdayAndTime(toLocaleDate(eventTime))} </div>
    <SmallText>{getName(role, name)}</SmallText>
    <div>
      <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
      <br />
      {data?.get('initiationType') && (
        <SmallText className="mb-2">
          {i18next.t(`consignmentEvent.invoiceInitiationType.${data.get('initiationType')}`)}
        </SmallText>
      )}
    </div>
  </GridConsignmentEvent>
)

export type DigitalIdentityVerifiedData = {
  name: string
  birthdate: string
  session: string
}

interface DigitalIdentityVerifiedProps extends Props<DigitalIdentityVerifiedData, EventType> {}

const DigitalIdentityVerifiedMessage = ({
  id,
  eventTime = '',
  type = EventType.DIGITAL_IDENTITY_VERIFIED,
  data = Map(),
  name = '',
  role = ''
}: DigitalIdentityVerifiedProps): React.ReactElement => (
  <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
    <div style={{ display: 'inline-flex' }}>{withWeekdayAndTime(toLocaleDate(eventTime))}</div>
    <SmallText>{getName(role, name)}</SmallText>
    <div>
      <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
      <br />
      {data?.get('name')} - {data?.get('birthdate')}
    </div>
    <ConsignmentEventConfirmed consignmentEventId={id} consignmentEventType={type} />
  </GridConsignmentEvent>
)

interface DeliveryLocationDeviationMessageProps
  extends Props<
    {
      driverName?: string
    },
    EventType
  > {}

const DeliveryLocationDeviationMessage = ({
  id,
  eventTime = '',
  type = EventType.DELIVERY_LOCATION_DEVIATION,
  data = Map(),
  name = '',
  role = ''
}: DeliveryLocationDeviationMessageProps): React.ReactElement => {
  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <div style={{ display: 'inline-flex' }}>{withWeekdayAndTime(toLocaleDate(eventTime))}</div>
      <SmallText>{getName(role, name)}</SmallText>
      <div>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
      </div>
      <DeliveryLocationDeviationHelpMessage data={data} shipmentId={null} courierName={data?.get('driverName')} />
      <ConsignmentEventConfirmed consignmentEventId={id} consignmentEventType={type} />
    </GridConsignmentEvent>
  )
}

type ReportedDamageOrMissingArticleData = ImmutableMap<{
  description: string
  filenames?: List<string>
}>

interface RecipientDamagedOrMissingMessageProps
  extends Props<
    { damage?: ReportedDamageOrMissingArticleData; missingArticle?: ReportedDamageOrMissingArticleData },
    EventType
  > {}
const RecipientDamagedOrMissingMessage = ({
  eventTime = '',
  type = EventType.REPORTED_DAMAGED || EventType.REPORTED_MISSING_ARTICLE,
  data = Map(),
  name = '',
  role = ''
}: RecipientDamagedOrMissingMessageProps): React.ReactElement => {
  const damageOrMissingArticle: ReportedDamageOrMissingArticleData =
    data?.get('damage') || data?.get('missingArticle') || Map()
  const description = damageOrMissingArticle.get('description') || ''
  const fileNames = damageOrMissingArticle.get('filenames') || List()

  return (
    <GridConsignmentEvent className="whitespace-pre-wrap">
      <div className="inline-flex">{withWeekdayAndTime(toLocaleDate(eventTime))}</div>
      <SmallText>{getName(role, name)}</SmallText>
      <div>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
      </div>
      <div className="mb-2">{/^\s*?$/.test(description) ? NOT_APPLICABLE : description}</div>
      {fileNames.map((fileName, idx) => {
        return (
          <div key={`file-${fileName}`} className="mb-0.5">
            <StyledAnchor href={`/api/upload-multiple/redirect/${fileName}`} target="_blank" rel="noopener noreferrer">
              {i18next.t('consignmentEvent.damaged.showPictureLink', {
                idx: idx + 1
              })}
            </StyledAnchor>
          </div>
        )
      })}
    </GridConsignmentEvent>
  )
}

export type ArrivedAtTerminalData = {
  city?: string
  postalCode: string
  depUnitNumber: string
  location?: string
  scannedAtDelegatedDepartment?: boolean
}

interface ArrivedAtTerminalProps extends Props<ArrivedAtTerminalData, EventType> {}

const ArrivedAtTerminalMessage = ({
  id,
  eventTime = '',
  type = EventType.ARRIVED_AT_TERMINAL,
  data = Map(),
  name = '',
  role = ''
}: ArrivedAtTerminalProps): React.ReactElement => {
  const terminalId = data?.get('depUnitNumber')
  const postalCode = data?.get('postalCode')
  const location = data?.get('location')
  const city = data?.get('city')
  const scannedAtDelegatedDepartment = data?.get('scannedAtDelegatedDepartment')
  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <div style={{ display: 'inline-flex' }}>{withWeekdayAndTime(toLocaleDate(eventTime))}</div>
      <SmallText>{getName(role, name)}</SmallText>
      <div>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
        {terminalId && postalCode && (
          <>
            <br />
            {i18next.t('terminal')}
            {': '}
            {[terminalId, postalCode, city].filter((d) => d).join(', ')}
          </>
        )}
        {scannedAtDelegatedDepartment && (
          <>
            <br />
            {i18next.t('scannedAtDelegatedDepartment')}
          </>
        )}
        {location && (
          <>
            <br />
            {i18next.t('location')}
            {': '}
            {location}
          </>
        )}
      </div>
      <ConsignmentEventConfirmed consignmentEventId={id} consignmentEventType={type} />
    </GridConsignmentEvent>
  )
}

export type PreAdviceResultData = {
  policyName?: string
  ruleTrigger?: string
  type?: string
  result: string
  trigger: string
  action?: string
}

interface PreAdviceResultProps extends Props<PreAdviceResultData, EventType> {}

const PreAdviceResultMessage = ({ id, eventTime = '', type, data }: PreAdviceResultProps): React.ReactElement => {
  const policyName = data?.get('policyName')
  const ruleTrigger = data?.get('ruleTrigger')
  const preAdviceType = data?.get('type')
  const result = data?.get('result')
  const trigger = data?.get('trigger')
  const action = data?.get('action')
  return (
    <GridConsignmentEvent style={{ whiteSpace: 'pre-wrap' }}>
      <div style={{ display: 'inline-flex' }}>{withWeekdayAndTime(toLocaleDate(eventTime))}</div>
      <SmallText>
        <strong>{i18next.t(`consignmentEvent.message.${type}`)}</strong>
      </SmallText>
      <div>
        {result && <div className="mt-2 mb-2 font-bold">{i18next.t(`preadvice.result.${result}`)}</div>}
        {policyName && (
          <SmallText>
            {i18next.t('preadvice.rule')}: {policyName}
          </SmallText>
        )}
        {ruleTrigger && (
          <SmallText>
            {i18next.t('preadvice.ruleTrigger')}: {ruleTrigger}
          </SmallText>
        )}
        {preAdviceType && (
          <SmallText>
            {i18next.t('preadvice.type')}: {preAdviceType}
          </SmallText>
        )}
        {trigger && (
          <SmallText>
            {i18next.t('preadvice.trigger')}: {trigger}
          </SmallText>
        )}
        {action && (
          <SmallText>
            {i18next.t('preadvice.action')}: {action}
          </SmallText>
        )}
      </div>
      <ConsignmentEventConfirmed consignmentEventId={id} consignmentEventType={type} />
    </GridConsignmentEvent>
  )
}

export function getMessageContainer(type: string) {
  switch (type) {
    case EventType.ARRIVED_AT_TERMINAL:
      return ArrivedAtTerminalMessage
    case EventType.COMMENT:
      return Comment
    case EventType.IMAGE:
      return Image
    case EventType.ASSIGNED:
      return AssignedMessage
    case EventType.DEVIATED:
    case EventType.DEVIATED_MANUALLY_OVERRIDDEN:
      return Deviated
    case EventType.NOTIFICATION:
      return Notification
    case EventType.DELIVERY_ID_CHECK:
      return IdCheck
    case EventType.PICKUP_CONSIGNMENT_DATA_CHANGED:
    case EventType.SPECIFICATION_SIZE_CHANGED:
    case EventType.SPECIFICATION_DESCRIPTION_CHANGED:
    case EventType.DELIVERY_CONSIGNMENT_DATA_CHANGED:
    case EventType.EXTERNAL_ADDRESS_WASH:
    case EventType.ORDER_NOTE_CHANGED:
    case EventType.ORDER_DATA_CHANGED:
    case EventType.PACKAGE_MEASUREMENTS_CHANGED:
    case EventType.PRE_ADVICE_STATUS_CHANGED:
    case EventType.DELIVERY_TIME_UPDATED_FROM_HF_SYNC:
    case EventType.PACKAGE_DATE_CHANGED:
    case EventType.TIME_WINDOW_CANCELLED_BY_RECIPIENT:
      return ShipmentDataUpdated
    case EventType.DELIVERED_MANUALLY_OVERRIDDEN:
      return DeliveryManuallyOverriden
    case EventType.COLLECTED_MANUALLY_OVERRIDDEN:
      return CollectedManuallyOverridden
    case EventType.REJECTED:
      return Rejected
    case EventType.DAMAGED:
      return Damaged
    case EventType.DELIVERY_TIME_ESTIMATED:
      return DeliveryTimeEstimate
    case EventType.DIGITAL_IDENTITY_VERIFIED:
      return DigitalIdentityVerifiedMessage
    case EventType.RETURNED_MANUALLY_OVERRIDDEN:
      return ReturnManuallyOverridden
    case EventType.ETA_SMS_SENT:
      return EtaSmsSent
    case EventType.SIGNATURE_SMS_SENT:
      return SignatureSmsSentMessage
    case EventType.DELIVERY_TIME_ORDERED_INTERNAL:
    case EventType.DELIVERY_TIME_ORDERED:
      return DeliveryTimeOrdered
    case EventType.FIRST_ESTIMATED_TIMES_SEEN:
      return FirstEstimatedTimesSeenMessage
    case EventType.DELIVERY_LOCATION_DEVIATION:
      return DeliveryLocationDeviationMessage
    case EventType.REPORTED_DAMAGED:
    case EventType.REPORTED_MISSING_ARTICLE:
      return RecipientDamagedOrMissingMessage
    case EventType.SERVICE_UPGRADE_PURCHASED:
      return ServiceUpgradePurchased
    case EventType.INVOICED_OK:
    case EventType.INVOICING_FAILED:
      return InvoicingEventMessage
    case EventType.PRE_ADVICE_RESULT:
      return PreAdviceResultMessage
    case AirExpressConsignmentManualOverrideEventTypes.ACCEPTED_TERMINAL_DPT:
    case AirExpressConsignmentManualOverrideEventTypes.SPX_ETD:
    case AirExpressConsignmentManualOverrideEventTypes.SPX_TRNS:
    case AirExpressConsignmentManualOverrideEventTypes.SPX_XRAY:
    case AirExpressConsignmentManualOverrideEventTypes.SPX_PHS:
    case AirExpressConsignmentManualOverrideEventTypes.READY_FOR_TRANSPORT_TO_FLIGHT:
    case AirExpressConsignmentManualOverrideEventTypes.LOADED_ON_FLIGHT:
    case AirExpressConsignmentManualOverrideEventTypes.ACCEPTED_AIRSIDE:
    case AirExpressConsignmentManualOverrideEventTypes.STORAGE_IN_TERMINAL:
    case AirExpressConsignmentManualOverrideEventTypes.HANDED_OVER_TO_DRIVER:
    case AirExpressConsignmentManualOverrideEventTypes.CUSTOMS_EXPORT:
    case AirExpressConsignmentManualOverrideEventTypes.CUSTOMS_IMPORT:
    case AirExpressConsignmentManualOverrideEventTypes.HELD_IN_CUSTOMS:
    case AirExpressConsignmentManualOverrideEventTypes.DELIVERED:
    case AirExpressConsignmentManualOverrideEventTypes.DEVIATED:
      return AirExpressMessage
    case AirExpressConsignmentEventTypes.FLIGHT_INFO_CHANGED:
      return AirExpressChangeMessage
    default:
      return DefaultMessage
  }
}

const getImageIfDefined = (
  data: ImmutableMap<{
    filename?: string
    flexImageFilename?: string
  }> | null = Map(),
  fileNameKey: string = 'filename'
) => {
  const filename = (data || (Map() as any)).get(fileNameKey)

  if (filename) {
    return (
      <div>
        <img src={api.fullUrl('/image/' + filename)} />
      </div>
    )
  } else {
    return null
  }
}

const getImageLinkIfDefined = (
  data: ImmutableMap<{
    filename?: string
    flexImageFilename?: string
  }> | null = Map(),
  fileNameKey: string = 'filename'
) => {
  const filename = (data || (Map() as any)).get(fileNameKey)

  if (filename) {
    return (
      <div key={`file-${filename}`} style={{ marginBottom: 2 }}>
        <StyledAnchor href={api.fullUrl('/image/' + filename)} target="_blank" rel="noopener noreferrer">
          {i18next.t('consignmentEvent.message.showPicture')}
        </StyledAnchor>
      </div>
    )
  } else {
    return null
  }
}
