import { EditablePickupComponent } from './pickup/EditablePickupComponent'
import { PickupComponent } from './pickup/PickupComponent'
import { EditableDeliveryComponent } from './pickup/EditableDeliveryComponent'
import { DeliveryComponent } from './delivery/DeliveryComponent'
import React from 'react'
import { ShipmentForShipmentModal } from './shared/Shipment'
import { FormikErrors, FormikTouched } from 'formik'
import { QuickEditFormProps } from './shipmentModalUtils'
import { Department, RoutePoint } from '../../types/coreEntitiesTypes'
import { List } from 'immutable'

export const PickupAndDelivery: React.FC<{
  shipment: ShipmentForShipmentModal
  editing: boolean
  errors: FormikErrors<QuickEditFormProps>
  touched: FormikTouched<QuickEditFormProps>
  department?: Department
  routePoints?: List<RoutePoint>
}> = ({ shipment, editing, errors, touched, department, routePoints }) => {
  const findRoutePoint = (type: string): RoutePoint | undefined => {
    return routePoints?.find((routePoint) => routePoint.get('type') === type)
  }
  const pickUpRoutePoint = findRoutePoint('pickup')
  const deliveryRoutePoint = findRoutePoint('delivery')

  return (
    <>
      <section>
        {editing ? (
          <EditablePickupComponent
            errors={errors}
            touched={touched}
            pickupTime={shipment.pickup.pickupTime}
            eta={shipment.pickup.eta}
            reference={shipment.pickup.sender.reference}
            department={department}
            pickupRoutePoint={pickUpRoutePoint}
          />
        ) : (
          <PickupComponent
            pickup={shipment.pickup}
            pickupRoutePoint={pickUpRoutePoint}
            displayMawbAwb={shipment.isMultileg && shipment.isLastLeg}
            awbNo={shipment.awbNo}
            mawbNo={shipment.mawbNo}
          />
        )}
      </section>
      <section>
        {editing ? (
          <EditableDeliveryComponent
            errors={errors}
            touched={touched}
            deliveryTime={shipment.delivery.deliveryTime}
            eta={shipment.delivery.eta}
            reference={shipment.delivery.receiver.reference}
            department={department}
            deliveryRoutePoint={deliveryRoutePoint}
          />
        ) : (
          <DeliveryComponent
            delivery={shipment.delivery}
            deliveryRoutePoint={deliveryRoutePoint}
            serviceLevel={shipment.serviceLevel}
            displayMawbAwb={shipment.isMultileg && !shipment.isLastLeg}
            awbNo={shipment.awbNo}
            mawbNo={shipment.mawbNo}
          />
        )}
      </section>
    </>
  )
}
