import { ConsignmentEventsGrid } from './Events'
import React, { Fragment } from 'react'
import { List, Map } from 'immutable'
import {
  Consignment,
  ConsignmentEvent,
  ConsignmentIdType,
  Unit,
  UnitIdType,
  Shipment,
  Slot
} from '../types/coreEntitiesTypes'
import { ConsignmentDrawer, ConsignmentDrawerClose, GridConsignmentBox } from '../primitives/ConsignmentDrawer'
import { SmallText, StyledAnchor } from '../primitives/Typography'
import i18next from 'i18next'
import { getTimePeriod, getTimePeriodDateTime } from '../utils/dateTime'
import { formatWeightDimensionsForPackages, formatWeightDimensionsForShipments } from '../utils/stringUtils'
import { isEmpty } from '../utils/collectionUtils'
import ImmutableComponent from '../primitives/ImmutableComponent'
import { getDeliveryPageBaseUrl, paramForInternalTrackingURL } from '../utils/trackingUtils'
import { Customer, CustomerIdType } from '../domain/customer'
import { DeviationOrderPropsType } from '../pages/admin/orderDeviationTypes'
import { getServiceText, GroupedServicesAndVasesTexts } from '../utils/serviceUtils'
import { OrderType } from '@glow/entity-types'

interface EventsProps {
  events: List<ConsignmentEvent>
  customerOrderDeviation: Map<number, DeviationOrderPropsType>
  orderType: OrderType | null
}

const Events = ({ events, customerOrderDeviation, orderType }: EventsProps) =>
  !events.isEmpty() ? (
    <ConsignmentEventsGrid
      consignmentEvents={events}
      customerDeviations={customerOrderDeviation}
      orderType={orderType}
    />
  ) : null

interface PickupProps {
  consignment: Consignment
}

const Pickup = ({ consignment }: PickupProps) => (
  <GridConsignmentBox area="pickup">
    <SmallText strong left>
      {i18next.t('consignment.pickup')}
    </SmallText>
    <SmallText>{consignment.get('pickupName')}</SmallText>
    <SmallText>{consignment.get('pickupAddress')}</SmallText>
    <SmallText>
      {consignment.get('pickupZipCode')} {consignment.get('pickupZipArea')} {consignment.get('pickupCountry')}
    </SmallText>
    <SmallText>
      {i18next.t('application.phoneNumberShort')} {consignment.get('pickupPhone')}
    </SmallText>
    <br />
    <SmallText strong>{i18next.t('consignment.requestedTimePickup')}</SmallText>
    <SmallText>{getTimePeriod(consignment.get('pickupTimeEarliest'), consignment.get('pickupTimeLatest'))}</SmallText>
    <br />
    <SmallText strong left>
      {i18next.t('consignment.pickupInstructions')}
    </SmallText>
    <SmallText>{consignment.get('pickupInstructions')}</SmallText>
    <br />
    <SmallText strong left>
      {i18next.t('consignment.customerReference')}
    </SmallText>
    <SmallText>{consignment.get('customerRef')}</SmallText>
    <br />
    <SmallText strong left>
      {i18next.t('customer.customer')}
    </SmallText>
    <SmallText>{consignment.get('custName')}</SmallText>
  </GridConsignmentBox>
)

const Delivery = ({ consignment }: PickupProps) => (
  <GridConsignmentBox area="delivery">
    <SmallText strong left>
      {i18next.t('consignment.delivery')}
    </SmallText>
    <SmallText>{consignment.get('deliveryName')}</SmallText>
    <SmallText>{consignment.get('deliveryAddress')}</SmallText>
    <SmallText>
      {consignment.get('deliveryZipCode')} {consignment.get('deliveryZipArea')} {consignment.get('deliveryCountry')}
    </SmallText>
    <SmallText>
      {i18next.t('application.phoneNumberShort')} {consignment.get('deliveryPhone')} (SMS:{' '}
      {consignment.get('deliverySms') ? i18next.t('application.yes') : i18next.t('application.no')})
    </SmallText>
    <br />
    <SmallText strong>{i18next.t('consignment.requestedTimeDelivery')}</SmallText>
    <SmallText>
      {getTimePeriodDateTime(
        consignment.get('deliveryTimeEarliestCached'),
        consignment.get('deliveryTimeLatestCached')
      )}
    </SmallText>
    <br />
    <SmallText strong left>
      {i18next.t('consignment.deliveryInstructions')}
    </SmallText>
    <SmallText>{consignment.get('deliveryInstructions')}</SmallText>
  </GridConsignmentBox>
)

interface SlotInfoProps {
  courier: Unit
}

const SlotInfo = ({ courier }: SlotInfoProps) => (
  <GridConsignmentBox area="slot">
    <SmallText left strong>
      {i18next.t('application.courier')}
    </SmallText>
    <SmallText>{courier.get('name', '?')}</SmallText>
  </GridConsignmentBox>
)

interface TrackingProps {
  consignment: Consignment
  deliveryPageBaseUrl: string
}

const Tracking = ({ consignment, deliveryPageBaseUrl }: TrackingProps) => (
  <GridConsignmentBox area="tracking">
    <SmallText strong>{i18next.t('consignment.tracking')}</SmallText>
    <StyledAnchor
      href={`${deliveryPageBaseUrl}/track/${consignment.get('externalId')}?${paramForInternalTrackingURL}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {i18next.t('application.tracking')}
    </StyledAnchor>
  </GridConsignmentBox>
)

interface DetailsProps {
  allTexts: GroupedServicesAndVasesTexts
  consignment: Consignment
  shipment: Shipment
  showPrice: boolean
}

const Details = ({ allTexts, consignment, shipment, showPrice }: DetailsProps) => {
  const serviceCode = consignment.get('serviceCode')
  const serviceName = getServiceText(allTexts, serviceCode, consignment.get('custAlystraId'), true)
  const totalShipmentWeight = shipment.map((cons) => cons.get('weightValue')).reduce((a, b) => a + b, 0)

  return (
    <GridConsignmentBox area="info">
      {serviceName && (
        <Fragment>
          <SmallText strong>{i18next.t('consignment.service')}</SmallText>
          <SmallText>{isEmpty(serviceName) ? serviceCode : `${serviceName} (${serviceCode})`}</SmallText>
          <br />
        </Fragment>
      )}
      <SmallText strong>{i18next.t('consignment.shipmentId')}</SmallText>
      <SmallText>{formatWeightDimensionsForShipments(consignment.get('shipmentId'), totalShipmentWeight)}</SmallText>
      <br />
      <SmallText strong>{i18next.t('consignment.packageId')}</SmallText>
      {shipment
        .map((s) => {
          return (
            <SmallText key={`${s.get('packageId')}`}>
              {formatWeightDimensionsForPackages(
                s.get('packageId'),
                s.get('weightValue'),
                s.get('lengthValue'),
                s.get('widthValue'),
                s.get('heightValue')
              )}
            </SmallText>
          )
        })
        .valueSeq()}

      {showPrice && (consignment.get('netCustomerPrice') || 0) > 0 && (
        <>
          <br />
          <SmallText strong>{i18next.t('consignment.price')}</SmallText>
          <SmallText>{consignment.get('netCustomerPrice')}</SmallText>
        </>
      )}
    </GridConsignmentBox>
  )
}

interface ShipmentPanelProps {
  consignmentEvents: List<ConsignmentEvent>
  couriersById: Map<UnitIdType, Unit>
  shipment: Shipment
  slot: any
  allTexts: GroupedServicesAndVasesTexts
  deliveryPageBaseUrl: string
  showPrice: boolean
  viewShipment: (id: number | null) => void
  customerOrderDeviation: Map<number, DeviationOrderPropsType>
}

export const shouldShowTrackingLink = (serviceLevel: string) => serviceLevel !== 'none'

const ShipmentPanel = (props: ShipmentPanelProps) => {
  const {
    consignmentEvents = List(),
    couriersById = Map(),
    shipment,
    slot,
    allTexts,
    deliveryPageBaseUrl,
    showPrice,
    viewShipment,
    customerOrderDeviation
  } = props

  if (!shipment || isEmpty(shipment)) return null

  const consignment = shipment.first()
  const courier = slot ? couriersById.get(slot.get('courierId')) : null
  const shouldShowTracking = shouldShowTrackingLink(consignment.get('serviceLevel', 'standard'))

  return (
    <ConsignmentDrawer>
      <ConsignmentDrawerClose
        onClick={() => {
          viewShipment(null)
        }}
      />
      {/* TODO: DeliveryDisplayType */}
      <Events
        events={consignmentEvents}
        customerOrderDeviation={customerOrderDeviation}
        orderType={shipment.get('type')}
      />
      <Delivery consignment={consignment} />
      <Pickup consignment={consignment} />
      {courier && <SlotInfo courier={courier} />}
      <Details shipment={shipment} consignment={consignment} allTexts={allTexts} showPrice={showPrice} />
      {shouldShowTracking && <Tracking consignment={consignment} deliveryPageBaseUrl={deliveryPageBaseUrl} />}
    </ConsignmentDrawer>
  )
}

interface ShipmentInfoProps {
  allTexts: GroupedServicesAndVasesTexts
  showPrice?: boolean
  shipmentId: number
  shipments: Map<number, Shipment>
  consignmentEventsByConsignmentId: Map<ConsignmentIdType, List<ConsignmentEvent>>
  slot?: Slot
  slots: List<Slot>
  customers: Map<CustomerIdType, Customer>
  couriersById: Map<UnitIdType, Unit>
  viewShipment: (id: number | null) => void
  customerOrderDeviation: Map<number, DeviationOrderPropsType>
}

export class ShipmentInfo extends ImmutableComponent<ShipmentInfoProps> {
  render() {
    const {
      allTexts,
      showPrice = false,
      shipmentId,
      shipments,
      consignmentEventsByConsignmentId,
      slot,
      slots,
      customers,
      couriersById,
      viewShipment,
      customerOrderDeviation
    } = this.props

    const shipment = shipments.get(shipmentId)

    if (!shipment) return null

    const consignmentId = ((shipment && shipment.first()) || Map()).get('id') || ''
    const eventsByConsignmentId = consignmentEventsByConsignmentId.get(consignmentId) || List<ConsignmentEvent>()
    const events = eventsByConsignmentId.map((events) => events.setIn(['packageId'], shipment.first().get('packageId')))
    const customerId = shipment?.get('customerId') || shipment?.first()?.get('customerId')
    const customer = shipment && customers?.get(customerId)
    const deliveryPageBaseUrl = getDeliveryPageBaseUrl(
      customer,
      undefined,
      shipment?.first()?.get('type'),
      shipment?.first()?.get('deliveryCountry')
    )

    return (
      <ShipmentPanel
        consignmentEvents={events}
        shipment={shipment}
        slot={slot || slots.find((slot) => slot.get('id') === ((shipment && shipment.first()) || Map()).get('slotId'))}
        viewShipment={viewShipment}
        couriersById={couriersById}
        allTexts={allTexts}
        deliveryPageBaseUrl={deliveryPageBaseUrl}
        showPrice={showPrice}
        customerOrderDeviation={customerOrderDeviation}
      />
    )
  }
}
