import styled from 'styled-components'
import vars from '../styles/variables'

export const SvgHolder = styled.div<{ large?: boolean; primary?: boolean }>`
  padding: ${(props) => props.large && '1rem'};
  display: ${(props) => (props.large ? 'block' : 'inline-block')};

  svg {
    width: ${(props) => (props.large ? '40px' : '18px')};
    height: ${(props) => (props.large ? '40px' : '18px')};
    left: 0;
    top: 0;

    path {
      fill: ${(props) => (props.color ? props.color : props.primary ? vars.newColors.primary : vars.colors.black)};
    }
  }
`

export const SvgHolder404 = styled.div<{ large?: boolean }>`
  padding: ${(props) => props.large && '1rem'};
  display: ${(props) => (props.large ? 'block' : 'inline-block')};
  width: 100%;
  left: 0;

  @media screen and (orientation: portrait) {
    height: 50vh;
    svg {
      height: 50vh;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
  @media screen and (orientation: landscape) {
    height: 30vh;
    svg {
      height: 30vh;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
`
