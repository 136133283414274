import { List } from 'immutable'

export enum AirExpressConsignmentEventTypes {
  CREATED = 'CREATED',
  ACCEPTED_TERMINAL_DPT = 'ACCEPTED_TERMINAL_DPT',
  SPX_XRAY = 'SPX_XRAY',
  SPX_PHS = 'SPX_PHS',
  READY_FOR_TRANSPORT_TO_FLIGHT = 'READY_FOR_TRANSPORT_TO_FLIGHT',
  LOADED_ON_FLIGHT = 'LOADED_ON_FLIGHT',
  ACCEPTED_AIRSIDE = 'ACCEPTED_AIRSIDE',
  STORAGE_IN_TERMINAL = 'STORAGE_IN_TERMINAL',
  HANDED_OVER_TO_DRIVER = 'HANDED_OVER_TO_DRIVER',
  CUSTOMS_EXPORT = 'CUSTOMS_EXPORT',
  CUSTOMS_IMPORT = 'CUSTOMS_IMPORT',
  HELD_IN_CUSTOMS = 'HELD_IN_CUSTOMS',
  FLIGHT_INFO_CHANGED = 'FLIGHT_INFO_CHANGED'
}

export enum AirExpressConsignmentManualOverrideEventTypes {
  DELIVERED = 'DELIVERED',
  ACCEPTED_TERMINAL_DPT = 'ACCEPTED_TERMINAL_DPT',
  SPX_ETD = 'SPX_ETD',
  SPX_TRNS = 'SPX_TRNS',
  SPX_XRAY = 'SPX_XRAY',
  SPX_PHS = 'SPX_PHS',
  READY_FOR_TRANSPORT_TO_FLIGHT = 'READY_FOR_TRANSPORT_TO_FLIGHT',
  LOADED_ON_FLIGHT = 'LOADED_ON_FLIGHT',
  ACCEPTED_AIRSIDE = 'ACCEPTED_AIRSIDE',
  STORAGE_IN_TERMINAL = 'STORAGE_IN_TERMINAL',
  HANDED_OVER_TO_DRIVER = 'HANDED_OVER_TO_DRIVER',
  CUSTOMS_EXPORT = 'CUSTOMS_EXPORT',
  CUSTOMS_IMPORT = 'CUSTOMS_IMPORT',
  HELD_IN_CUSTOMS = 'HELD_IN_CUSTOMS',
  DEVIATED = 'DEVIATED'
}

export const airExpressCustomsEvents = List([
  AirExpressConsignmentEventTypes.CUSTOMS_EXPORT.valueOf(),
  AirExpressConsignmentEventTypes.CUSTOMS_IMPORT.valueOf(),
  AirExpressConsignmentEventTypes.HELD_IN_CUSTOMS.valueOf()
])

export const airExpressBaseEvents = List(
  Object.keys(AirExpressConsignmentManualOverrideEventTypes).filter(
    (eventType) => !airExpressCustomsEvents.contains(eventType)
  )
)
