import styled from 'styled-components'
import vars from '../styles/variables'

export const ShipmentModalContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  max-height: 1200px;
  width: max-content;
  margin: auto;
  padding: 40px;
  z-index: 99999;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
`

export const MainArea = styled.div`
  background-color: ${vars.colors.white};
  box-shadow: ${vars.boxShadow.medium};
  border-radius: 8px;
  max-width: 955px;
  min-width: 955px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
  padding: 30px;
`
