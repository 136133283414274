import { Collection, List, Map, Set } from 'immutable'
import { DateTime } from 'luxon'
import { courierUsersSelector } from '../components/unit/UnitHelpers'
import {
  CourierLocation,
  CourierUser,
  Department,
  DepartmentIdType,
  Slot,
  SlotIdType,
  Unit,
  UnitIdType,
  UnitWithoutUsers
} from '../types/coreEntitiesTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { zipmapBy } from '../utils/collectionUtils'
import { isUpdatesLast12Hours } from '../utils/googleMapsMarkersHelpers'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'
import { departmentsFromIdsSelector } from './departmentsSelector'
import { slotByIdSelector, slotsByIdsSelector, slotsCourierIdsSetSelector } from './slotSelectors'

export const unitsSelector: (state: AppStateType) => Map<UnitIdType, Unit> = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'units']) || Map(),
  (state: AppStateType) => state.getIn(['entities', 'users']) || Map(),
  (units: Map<UnitIdType, Unit>, users: Map<number, CourierUser>) =>
    units.map((unit) =>
      unit.set(
        'users',
        users.filter((u) => unit.get('userIds')?.contains(u.get('id')))
      )
    ) || Map()
)

export const unitsInDepartmentsSelector: (state: AppStateType, departmentIds: List<DepartmentIdType>) => List<Unit> =
  createImmutableEqualSelector(
    unitsSelector,
    (state: AppStateType, departmentIds: List<DepartmentIdType>) => departmentIds,
    (units: Map<UnitIdType, Unit>, departmentIds: List<DepartmentIdType>) =>
      units
        .valueSeq()
        .filter((it) => !it.get('departments').toSet().intersect(departmentIds).isEmpty())
        .toList()
  )

export const unitsInDepartmentsGroupedByIdSelector: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>
) => Map<UnitIdType, Unit> = createImmutableEqualSelector(
  unitsSelector,
  departmentsFromIdsSelector,
  (units: Map<UnitIdType, Unit>, departments: Map<number, Department>) =>
    units.filter((it) => !it.get('departments').toSet().intersect(departments.keySeq().map(Number)).isEmpty())
)

export const activeUnitsInDepartmentsSelector: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>
) => List<Unit> = createImmutableEqualSelector(
  (state: AppStateType, departmentIds: List<DepartmentIdType>) => unitsInDepartmentsSelector(state, departmentIds),
  (units) => units.filter((it) => it.get('isActive', true)).toList()
)

export const unitsInDepartmentSelector: (state: AppStateType, departmentId: DepartmentIdType) => List<Unit> =
  createImmutableEqualSelector(
    (state: AppStateType, departmentId: DepartmentIdType) => unitsInDepartmentsSelector(state, List([departmentId])),
    (units) => units.toList()
  )

export const unitsWithoutUsersInDepartmentSelectorSorted: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>
) => List<UnitWithoutUsers> = createImmutableEqualSelector(
  (state: AppStateType): Map<UnitIdType, UnitWithoutUsers> => state.getIn(['entities', 'units']) || Map(),
  (state: AppStateType, departmentIds: List<DepartmentIdType>) => departmentIds,
  (units: Map<UnitIdType, UnitWithoutUsers>, departmentIds: List<DepartmentIdType>) =>
    units
      .valueSeq()
      .filter((it) => !it.get('departments').toSet().intersect(departmentIds).isEmpty())
      .sortBy((c) => c.get('name'))
      .toList()
)

export const activeUnitsWhereUsersCanLoginSelectorSorted: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>
) => List<Unit> = createImmutableEqualSelector(
  (state: AppStateType, departmentIds: List<DepartmentIdType>) =>
    activeUnitsInDepartmentsSelector(state, departmentIds),
  (state: AppStateType) => state.getIn(['entities', 'users']),
  (units: List<Unit>, users: Map<number, CourierUser>) =>
    units
      .filter((unit) =>
        unit
          .get('userIds')
          ?.map((userId) => users.get(userId))
          .some((user) => user?.get('canLogIn') ?? false)
      )
      .sortBy((c) => c.get('name'))
      .toList()
)

export const unitsInDepartmentsByCourierIdSelector: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>
) => Map<UnitIdType, Unit> = createImmutableEqualSelector(unitsInDepartmentsSelector, (units) => zipmapBy('id', units))

export const unitsInDepartmentWithSlotsOnDateSelector: (
  state: AppStateType,
  departmentId: DepartmentIdType,
  date: DateTime
) => Collection.Indexed<Unit> = createImmutableEqualSelector(
  unitsSelector,
  slotsCourierIdsSetSelector,
  (units: Map<UnitIdType, Unit>, slotIds) => units.valueSeq().filter((it) => slotIds.has(it.get('id')))
)

export const courierLocationsByUnitIdSelector: (state: AppStateType) => Map<UnitIdType, CourierLocation> =
  createImmutableEqualSelector(
    (state: AppStateType) => state.getIn(['entities', 'courierLocations']),
    (courierLocations) => (courierLocations ? zipmapBy('courierId', courierLocations.valueSeq()) : Map())
  )

export const unitsWithLocationInDepartmentsSelector: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>
) => List<Unit> = createImmutableEqualSelector(
  unitsInDepartmentsSelector,
  courierLocationsByUnitIdSelector,
  (units: Collection.Indexed<Unit>, locations: Map<UnitIdType, CourierLocation>) =>
    units.map((c) => c.setIn(['location'], locations.get(c.get('id')))).toList()
)

export const unitsWithLocationInDepartmentWithSlotsOnDateSelector: (
  state: AppStateType,
  departmentId: DepartmentIdType,
  date: DateTime
) => Collection.Indexed<Unit> = createImmutableEqualSelector(
  unitsInDepartmentWithSlotsOnDateSelector,
  courierLocationsByUnitIdSelector,
  (units: Collection.Indexed<Unit>, locations: Map<UnitIdType, CourierLocation>) =>
    units.map((c) => c.setIn(['location'], locations.get(c.get('id'))))
)

export const unitForSlotSelector: (state: AppStateType, slotId: SlotIdType) => Unit | undefined =
  createImmutableEqualSelector(unitsSelector, slotByIdSelector, (units: Map<UnitIdType, Unit>, slot: Slot) =>
    units.find((c) => c.get('id') === slot.get('courierId'))
  )

export const unitsForSlotsSelector: (state: AppStateType, slotIds: List<SlotIdType>) => Map<UnitIdType, Unit> =
  createImmutableEqualSelector(unitsSelector, slotsByIdsSelector, (units, slots) => {
    const unitIdsForSlots = slots?.map((it) => it.get('courierId'))
    return units.filter((c) => unitIdsForSlots.includes(c.get('id')))
  })

export const filteredUnits: (units: List<Unit>, filter: string) => List<Unit> = createImmutableEqualSelector(
  (units: List<Unit>, filter: string) => filter,
  (units: List<Unit>) => units,
  (filter, units) =>
    filter === 'ALL'
      ? units
      : units.filter((it) =>
          filter === 'IN_PROGRESS' ? it.get('isActive') : filter === 'INACTIVE' ? !it.get('isActive') : true
        )
)

export const filteredCourierLocations: (
  courierLocations: Map<number, CourierLocation>,
  units: List<Unit>
) => Map<number, CourierLocation> = createImmutableEqualSelector(
  (courierLocations: Map<number, CourierLocation>, units: List<Unit>) => units,
  (courierLocations: Map<number, CourierLocation>) => courierLocations,
  (units: List<Unit>, courierLocations: Map<number, CourierLocation>) => {
    const unitIds = units.map((unit) => unit.get('id')).toSet()
    return courierLocations.filter((loc) => unitIds.contains(loc.get('courierId')))
  }
)

export const courierLocationsForDepartmentsSelector: (
  state: AppStateType,
  departmentIds: Set<DepartmentIdType>
) => Map<number, CourierLocation> = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'courierLocations']),
  (state: AppStateType, departmentIds: Set<DepartmentIdType>) => departmentIds,
  (courierLocations: Map<number, CourierLocation>, departmentIds: Set<DepartmentIdType>) =>
    courierLocations.filter((location) => !location.get('departmentIds')?.toSet().intersect(departmentIds).isEmpty())
)

export const courierLocationsSelector: (state: AppStateType) => Map<number, CourierLocation> =
  createImmutableEqualSelector(
    (state: AppStateType) => state.getIn(['entities', 'courierLocations']),
    (courierLocations: Map<number, CourierLocation>) => courierLocations
  )

export const courierLocationsForDepartmentsWithUserInfo: (
  state: AppStateType,
  departmentIds: Set<DepartmentIdType>
) => Map<number, CourierLocation> = createImmutableEqualSelector(
  courierLocationsForDepartmentsSelector,
  courierUsersSelector,
  (courierLocations: Map<number, CourierLocation>, courierUsers: Map<number, CourierUser>) =>
    courierLocations
      .filter((courierLocation) => courierLocation.get('lat') && courierLocation.get('lng'))
      .filter((courierLocation) => isUpdatesLast12Hours(courierLocation.get('updatedAt'))) //This is just to remove from map when idle. Backend only returns 2 hour old updates.
      .map((courierLocation) => {
        const courierUser = courierUsers.get(courierLocation.get('userId'))
        return courierLocation
          .set('userName', courierUser?.get('name'))
          .set('userPhoneNumber', courierUser?.get('phoneNumber'))
      })
)

export const unitWithUsersSelector: (state: AppStateType, unitId: UnitIdType) => Unit = createImmutableEqualSelector(
  (state: AppStateType, unitId: UnitIdType) => state.getIn(['entities', 'units', unitId]),
  (state: AppStateType) => state.getIn(['entities', 'users']),
  (unit, users: Map<number, CourierUser>) =>
    unit?.set(
      'users',
      users.filter((u) => unit.get('userIds')?.contains(u.get('id')))
    )
)
