import { List, Map } from 'immutable'
import { AppStateType } from '../utils/appStateReduxStore'
import { IOptimusService } from '../domain/optimusService'
import { IOptimusRouting } from '../pages/instant/bookingOrderTypes'
import { GroupedServicesAndVasesTexts, ServicesAndVasesTexts } from '../utils/serviceUtils'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'
import { concat } from '../utils/collectionUtils'

export const standardServicesSelector: (state: AppStateType, hdBehaviour: boolean) => List<IOptimusService> = (
  state,
  hdOrder
) => (hdOrder ? List() : standardServicesAtPickupSelector(state))

export const customerServicesSelector: (
  state: AppStateType,
  hdBehavior: boolean,
  baggageBehavior: boolean
) => List<IOptimusService> = (state, hdOrder, baggageOrder) =>
  hdOrder
    ? hdServicesSelector(state)
    : baggageOrder
      ? concat(customerServicesAtPickupSelector(state), hdServicesSelector(state))
      : customerServicesAtPickupSelector(state)

export const standardServicesAtPickupSelector: (state: AppStateType) => List<IOptimusService> = (state: AppStateType) =>
  state.getIn(['servicesFromOptimus', 'pickupServices', 'standardServices', 'services']) || List()

export const standardRoutingAtPickupSelector: (state: AppStateType) => IOptimusRouting = (state: AppStateType) =>
  state.getIn(['servicesFromOptimus', 'pickupServices', 'standardServices', 'routing']) || Map()

export const customerServicesAtPickupSelector: (state: AppStateType) => List<IOptimusService> = (state: AppStateType) =>
  state.getIn(['servicesFromOptimus', 'pickupServices', 'customerServices', 'services']) || List()

export const customerRoutingAtPickupSelector: (state: AppStateType) => IOptimusRouting = (state: AppStateType) =>
  state.getIn(['servicesFromOptimus', 'pickupServices', 'customerServices', 'routing']) || Map()

export const standardServicesAtDeliverySelector: (state: AppStateType) => List<IOptimusService> = (
  state: AppStateType
) => state.getIn(['servicesFromOptimus', 'deliveryServices', 'standardServices', 'services']) || List()

export const standardRoutingAtDeliverySelector: (state: AppStateType) => IOptimusRouting = (state: AppStateType) =>
  state.getIn(['servicesFromOptimus', 'deliveryServices', 'standardServices', 'routing']) || Map()

export const customerServicesAtDeliverySelector: (state: AppStateType) => List<IOptimusService> = (
  state: AppStateType
) => state.getIn(['servicesFromOptimus', 'deliveryServices', 'customerServices', 'services']) || List()

export const customerRoutingAtDeliverySelector: (state: AppStateType) => IOptimusRouting = (state: AppStateType) =>
  state.getIn(['servicesFromOptimus', 'deliveryServices', 'customerServices', 'routing']) || Map()

export const servicesAndVasesTextsSelector: (state: AppStateType) => List<ServicesAndVasesTexts> = (
  state: AppStateType
) => state.getIn(['servicesAndVasesTexts']) || List()

export const standardDepartmentServicesSelector: (state: AppStateType) => List<IOptimusService> = (
  state: AppStateType
) => state.getIn(['servicesFromOptimus', 'departmentServices', 'standardServices', 'services']) || List()

export const servicesAndVasesTextsPerCodeSelector: (state: AppStateType) => GroupedServicesAndVasesTexts =
  createImmutableEqualSelector(servicesAndVasesTextsSelector, (servicesAndVasesTexts: List<ServicesAndVasesTexts>) =>
    servicesAndVasesTexts.groupBy((it) => it.get('code'))
  )

export const hdServicesSelector: (state: AppStateType) => List<IOptimusService> = (state: AppStateType) =>
  state.getIn(['hdServicesReducer', 'hdServices', 'customerServices', 'services']) || List()
