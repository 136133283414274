import React from 'react'
import { EditableContactInfoGrid, HorizontalSpace } from '../shared/Styles'
import { PickupTime } from '../shared/Shipment'
import i18next from 'i18next'
import { H2 } from '../../../primitives/Headings'
import { Field, FormikErrors, FormikTouched } from 'formik'
import { Input, TextAreaInput } from '../../../primitives/Forms'
import variables from '../../../styles/variables'
import { NameWithSearchAddress, PickupTimeComponent, Reference, SearchPlaces } from '../shared/CommonComponents'
import { QuickEditFormProps } from '../shipmentModalUtils'
import { ValidationError } from '../../../primitives/ErrorMessages'
import {
  requiredFormik,
  validEmailFormik,
  validPhonePrefixFormik,
  validTrimmedToNumberFormik
} from '../../../utils/formikInputValidation'
import { Department, RoutePoint } from '../../../types/coreEntitiesTypes'
import { DateTime } from 'luxon'
import { getAta } from '../../routePointHelper'

export const EditablePickupComponent: React.FC<{
  pickupTime?: PickupTime
  errors: FormikErrors<QuickEditFormProps>
  touched: FormikTouched<QuickEditFormProps>
  reference?: string
  department?: Department
  pickupRoutePoint?: RoutePoint
  eta?: DateTime
}> = ({ pickupTime, errors, touched, reference, department, pickupRoutePoint, eta }) => (
  <>
    <H2>{i18next.t('shipment.pickupInfo')}</H2>
    <SenderComponent errors={errors} touched={touched} department={department} />
    <HorizontalSpace />
    <PickupTimeComponent pickupTime={pickupTime} ata={getAta(pickupRoutePoint)} eta={eta} />
    <HorizontalSpace />
    <Reference reference={reference} title={i18next.t('shipment.customerReference')} />
    <HorizontalSpace />
    <PickupInstructions />
  </>
)

const PickupInstructions = () => (
  <div>
    <div
      style={{
        color: variables.newColors.darkGrey,
        fontSize: '0.75rem',
        marginBottom: '2px'
      }}
    >
      {i18next.t('shipment.pickupInstructions')}
    </div>
    <Field as={TextAreaInput} name="pickupInstructions" rows={2} />
  </div>
)

const SenderComponent: React.FC<{
  errors: FormikErrors<QuickEditFormProps>
  touched: FormikTouched<QuickEditFormProps>
  department?: Department
}> = ({ errors, touched, department }) => (
  <EditableContactInfoGrid>
    <NameWithSearchAddress type={'pickup'} errors={errors} touched={touched} department={department} />
    <Address errors={errors} touched={touched} />
    <SearchPlaces type={'pickup'} department={department} />
    <ZipCode errors={errors} touched={touched} />
    <ZipArea errors={errors} touched={touched} />
    <Phone errors={errors} touched={touched} />
    <Email errors={errors} touched={touched} />
  </EditableContactInfoGrid>
)

const Address: React.FC<{ errors: FormikErrors<QuickEditFormProps>; touched: FormikTouched<QuickEditFormProps> }> = ({
  errors,
  touched
}) => (
  <div style={{ gridColumn: '1/3' }}>
    <div style={{ color: variables.newColors.darkGrey, fontSize: '0.75rem', marginBottom: '2px' }}>
      {i18next.t('shipment.address')}
    </div>
    <Field as={Input} style={{ height: '40px' }} name="pickupAddress" validate={requiredFormik} />
    {errors.pickupAddress && touched.pickupAddress && <ValidationError>{errors.pickupAddress}</ValidationError>}
  </div>
)

const ZipCode: React.FC<{ errors: FormikErrors<QuickEditFormProps>; touched: FormikTouched<QuickEditFormProps> }> = ({
  errors,
  touched
}) => (
  <div>
    <div style={{ color: variables.newColors.darkGrey, fontSize: '0.75rem', marginBottom: '2px' }}>
      {i18next.t('shipment.zipCode')}
    </div>
    <Field as={Input} style={{ height: '40px' }} name="pickupZipCode" validate={validTrimmedToNumberFormik} />
    {errors.pickupZipCode && touched.pickupZipCode && <ValidationError>{errors.pickupZipCode}</ValidationError>}
  </div>
)

const ZipArea: React.FC<{ errors: FormikErrors<QuickEditFormProps>; touched: FormikTouched<QuickEditFormProps> }> = ({
  errors,
  touched
}) => (
  <div style={{ gridColumn: '2/4' }}>
    <div style={{ color: variables.newColors.darkGrey, fontSize: '0.75rem', marginBottom: '2px' }}>
      {i18next.t('shipment.zipArea')}
    </div>
    <Field as={Input} style={{ height: '40px' }} name="pickupZipArea" validate={requiredFormik} />
    {errors.pickupZipArea && touched.pickupZipArea && <ValidationError>{errors.pickupZipArea}</ValidationError>}
  </div>
)

const Phone: React.FC<{ errors: FormikErrors<QuickEditFormProps>; touched: FormikTouched<QuickEditFormProps> }> = ({
  errors,
  touched
}) => (
  <div style={{ gridColumn: '1/3' }}>
    <div style={{ color: variables.newColors.darkGrey, fontSize: '0.75rem', marginBottom: '2px' }}>
      {i18next.t('shipment.phone')}
    </div>
    <Field as={Input} style={{ height: '40px' }} name="pickupPhone" validate={validPhonePrefixFormik} />
    {errors.pickupPhone && touched.pickupPhone && <ValidationError>{errors.pickupPhone}</ValidationError>}
  </div>
)

const Email: React.FC<{ errors: FormikErrors<QuickEditFormProps>; touched: FormikTouched<QuickEditFormProps> }> = ({
  errors,
  touched
}) => (
  <div style={{ gridColumn: '1/4' }}>
    <div style={{ color: variables.newColors.darkGrey, fontSize: '0.75rem', marginBottom: '2px' }}>
      {i18next.t('shipment.email')}
    </div>
    <Field
      as={Input}
      inputProp={{ type: 'email' }}
      style={{ height: '40px' }}
      name="pickupEmail"
      validate={validEmailFormik}
    />
    {errors.deliveryEmail && touched.deliveryEmail && <ValidationError>{errors.deliveryEmail}</ValidationError>}
  </div>
)
