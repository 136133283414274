import { AppStateType } from './appStateReduxStore'
import { Department, DepartmentGroup, DepartmentGroupIdType, DepartmentIdType } from '../types/coreEntitiesTypes'
import { List, Map, OrderedMap, Seq } from 'immutable'
import { allDepartmentsExceptAirExpressFromStateSelector } from '../selectors/departmentsSelector'
import { ImmutableMap } from '../types/immutableTypes'

export enum DepartmentType {
  AirExpress = 'airExpress',
  Courier = 'courier',
  Express = 'express',
  HD = 'hd',
  Parcel = 'parcel',
  Posten = 'posten',
  Other = 'other',
  H2 = 'h2'
}

// TODO(olav): Use `${DepartmentType}` when we have TypeScript@^4.1.
export type DepartmentTypes = 'airExpress' | 'courier' | 'express' | 'hd' | 'parcel' | 'posten' | 'other' | 'h2'

interface Props {
  match: {
    params: {
      departmentId?: string
    }
  }
}

interface ReturnValue {
  departmentOrGroupId: string
  isDepartmentGroup: boolean
  allDepartmentsCount: number
  allGroups: Map<number, DepartmentGroup>
  selectedDepartmentIds: List<DepartmentIdType>
  groupsAndDepartments: OrderedMap<string, List<Map<string, string | number>>>
  departmentsAndGroupsCount: number
  departmentIdsInSameGroup: List<DepartmentIdType>
  allDepartments: Map<number, Department>
}

export const isGroupId = (id: DepartmentIdType | string | undefined) =>
  id && typeof id === 'string' && id.match('group')

export function plannerPagesSupportsGroups(url: string) {
  return /\bshipments|invoicing|map|dispatch|export|planning|live2|preadvice-followup|rm\b/.test(url)
}

export function departmentsAndDepartmentGroupsFromState(
  state: AppStateType,
  props?: Props,
  supportsGroups: boolean = false
): ReturnValue {
  const allDepartments = allDepartmentsExceptAirExpressFromStateSelector(state)
  const allDepartmentGroups = (state.getIn(['entities', 'departmentGroups']) as Map<number, DepartmentGroup>) || Map()
  return departmentsAndDepartmentGroups(allDepartments, allDepartmentGroups, props, supportsGroups)
}

export function departmentsAndDepartmentGroups(
  allDepartments: Map<DepartmentIdType, Department>,
  allDepartmentGroups: Map<DepartmentGroupIdType, DepartmentGroup>,
  props?: Props,
  supportsGroups: boolean = false
): ReturnValue {
  const departmentOrGroupId = props?.match.params.departmentId ?? ''
  let selectedDepartmentIds = Seq.Indexed<DepartmentIdType>([])
  let departmentIdsInSameGroup = Seq.Indexed<DepartmentIdType>([])

  const isDepartmentGroup = departmentOrGroupId && isGroupId(departmentOrGroupId)
  if (isDepartmentGroup) {
    const departmentGroupId = parseInt(departmentOrGroupId.split('_')[1], 10)
    selectedDepartmentIds =
      (allDepartmentGroups.getIn([departmentGroupId, 'departments']) as Seq.Indexed<DepartmentIdType>) ||
      Seq.Indexed<DepartmentIdType>()
    departmentIdsInSameGroup = selectedDepartmentIds
  } else if (departmentOrGroupId) {
    const departmentId = parseInt(departmentOrGroupId, 10)
    selectedDepartmentIds = Seq.Indexed([departmentId])
    departmentIdsInSameGroup = Seq.Indexed([departmentId])
  }

  let groupsAndDepartments = OrderedMap<string, List<Map<string, string | number>>>()
  if (supportsGroups && allDepartmentGroups?.count() > 0) {
    groupsAndDepartments = groupsAndDepartments.set(
      'Groups',
      allDepartmentGroups
        .map((group) =>
          Map({
            name: group.get('name'),
            id: `group_${group.get('id')}`
          })
        )
        .toList()
    )
  }
  groupsAndDepartments = groupsAndDepartments.set(
    'Departments',
    allDepartments
      .map((dep) =>
        Map({
          name: dep.get('name'),
          id: dep.get('id')
        })
      )
      .toList()
  )
  const departmentsAndGroupsCount = allDepartmentGroups.count() + allDepartments.count()
  return {
    departmentOrGroupId: departmentOrGroupId,
    isDepartmentGroup: !!isDepartmentGroup,
    allDepartmentsCount: allDepartments?.count() || 0,
    allGroups: allDepartmentGroups,
    selectedDepartmentIds: selectedDepartmentIds.toList(),
    groupsAndDepartments: groupsAndDepartments,
    departmentsAndGroupsCount: departmentsAndGroupsCount,
    departmentIdsInSameGroup: departmentIdsInSameGroup.toList(),
    allDepartments: allDepartments
  }
}
export function getSelectedDepartmentIds(
  allDepartmentGroups: Map<DepartmentGroupIdType, DepartmentGroup>,
  props?: Props
) {
  const departmentOrGroupId = props?.match.params.departmentId ?? ''
  let selectedDepartmentIds = Seq.Indexed<DepartmentIdType>([])

  const isDepartmentGroup = departmentOrGroupId && isGroupId(departmentOrGroupId)
  if (isDepartmentGroup) {
    const departmentGroupId = parseInt(departmentOrGroupId.split('_')[1], 10)
    selectedDepartmentIds =
      (allDepartmentGroups.getIn([departmentGroupId, 'departments']) as Seq.Indexed<DepartmentIdType>) ||
      Seq.Indexed<DepartmentIdType>()
  } else if (departmentOrGroupId) {
    const departmentId = parseInt(departmentOrGroupId, 10)
    selectedDepartmentIds = Seq.Indexed([departmentId])
  }

  return selectedDepartmentIds.toList()
}

export const eligibleForCreatingShipmentLabels = (departmentAlystraId?: string): boolean =>
  departmentAlystraId === 'OSLO DEKK & DELER'

export enum OrderBillingType {
  AirExpress = 'airexpress',
  Apollo = 'apollo',
  H2 = 'h2',
  Pickup = 'pickup'
}

export interface OrderBillingInfoProps {
  type: OrderBillingType | ''
  customerNumber: number | ''
  customerNumberSe: number | ''
  customerNumberNo: number | ''
  customerNumberDk: number | ''
}

export interface OrderBillingInfo extends ImmutableMap<OrderBillingInfoProps> {}
