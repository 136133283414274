import { H3 } from '../../../primitives/Headings'
import i18next from 'i18next'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { InputForLabel } from '../../../primitives/Forms'
import React from 'react'
import { getMeasurementVolume } from '../bookingOrderFunctions'
import { LabeledInput } from '../../../primitives/LabeledInput'
import { required, validNumber } from '../../../utils/inputValidation'
import { SmallValidationError } from '../../../primitives/ErrorMessages'
import { TertiaryButton } from '../../../primitives/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ClearInstantBookingFormButton } from '../../../components/ClearInstantBookingFormButton'
import InputGroup from '../InputGroup'
import { IOrderForm } from '../bookingOrderTypes'
import ImmutableComponent from '../../../primitives/ImmutableComponent'
import { List, Map } from 'immutable'
import { h2measurementComponentNames } from './H2BookingSupport'
import { BookingTotalWeightAndVolume } from '../BookingTotalWeightAndVolume'

interface Props {
  formValues: IOrderForm
  mergeFormValues: (model: string, values: Map<string, any>) => void
  addToFormList: (formName: string, values: Map<string, string>) => void
  removeFromFormList: (model: string, index: number) => void
  showClearButton?: boolean
  formName: string
  clearComponents?: (components: List<string>) => void
}

class H2BookingGoods extends ImmutableComponent<Props> {
  copyMeasurement = (i: number) => (e: React.MouseEvent) => {
    e.preventDefault()
    const measurements = this.props.formValues.get('measurements')
    if (measurements && measurements.count() > i) {
      const measurementToCopy = measurements.get(i)
      if (measurementToCopy) {
        this.props.addToFormList(`${this.props.formName}.measurements`, measurementToCopy.delete('packageId') || Map())
      }
    }
  }

  removeMeasurement = (i: number) => (e: React.MouseEvent) => {
    e.preventDefault()
    if (this.props.formValues.get('measurements').count() > 1) {
      this.props.removeFromFormList(`${this.props.formName}.measurements`, i)
    }
  }

  getMeasurementVolumeByIndex = (index: number) => {
    const measurement = this.props.formValues.get('measurements').get(index)
    if (measurement) {
      return getMeasurementVolume(measurement)
    } else {
      return 0
    }
  }

  render() {
    const { formValues, showClearButton = true, clearComponents } = this.props

    const canRemoveMeasurements = formValues.get('measurements', List()).count() > 1

    return (
      <InputGroup>
        <H3> {i18next.t('instant.booking.dimensions')}</H3>

        <div style={{ marginBottom: '1em' }}>
          <LabeledInput label={i18next.t('consignment.shipmentId')} htmlFor={'shipmentId'}>
            <Control.text
              autoComplete="nope"
              model={'.shipmentId'}
              id={'shipmentId'}
              component={InputForLabel}
              validators={{ required }}
              defaultValue={''}
            />
          </LabeledInput>
          <Errors
            className="errors"
            model={'.shipmentId'}
            show="touched"
            wrapper={SmallValidationError}
            messages={{ required: `${i18next.t('application.required')}` }}
          />
        </div>

        {formValues.get('measurements') &&
          formValues.get('measurements').map((measurement, i) => (
            <div
              key={i}
              style={{
                display: 'grid',
                gridTemplateColumns: '1em 12fr 6fr 6fr 6fr 6fr auto auto 100px',
                gridColumnGap: '0.5em',
                marginBottom: '0.5em'
              }}
            >
              <div style={{ gridColumn: 1, gridRow: 1, marginRight: '1em', padding: '0.5em' }}>
                <strong>{i + 1}</strong>
              </div>
              <div style={{ gridColumn: 2, gridRow: 1 }}>
                <LabeledInput label={i18next.t('consignment.packageId')} htmlFor={`packageId[${i}]`}>
                  <Control.text
                    autoComplete="nope"
                    model={`.measurements[${i}].packageId`}
                    id={`packageId[${i}]`}
                    component={InputForLabel}
                    validators={{ required }}
                  />
                </LabeledInput>
                <Errors
                  className="errors"
                  model={`.measurements[${i}].packageId`}
                  show="touched"
                  wrapper={SmallValidationError}
                  messages={{ required: `${i18next.t('application.required')}` }}
                />
              </div>

              <div style={{ gridColumn: 3, gridRow: 1 }}>
                <LabeledInput label={i18next.t('instant.booking.weightPlaceholder')} htmlFor={`weight-${i}`}>
                  <Control.text
                    autoComplete="nope"
                    model={`.measurements[${i}].weight`}
                    id={`weight-${i}`}
                    component={InputForLabel}
                    validators={{ validNumber }}
                  />
                </LabeledInput>
                <Errors
                  className="errors"
                  model={`.measurements[${i}].weight`}
                  show="touched"
                  wrapper={SmallValidationError}
                  messages={{ validNumber: `${i18next.t('error.notAValidNumber')}` }}
                />
              </div>
              <div style={{ gridColumn: 4, gridRow: 1 }}>
                <LabeledInput label={i18next.t('instant.booking.lengthPlaceholder')} htmlFor={`length-${i}`}>
                  <Control.text
                    autoComplete="nope"
                    model={`.measurements[${i}].length`}
                    id={`length-${i}`}
                    component={InputForLabel}
                    validators={{ validNumber }}
                  />
                </LabeledInput>
                <Errors
                  className="errors"
                  model={`.measurements[${i}].length`}
                  show="touched"
                  wrapper={SmallValidationError}
                  messages={{ validNumber: `${i18next.t('error.notAValidNumber')}` }}
                />
              </div>

              <div style={{ gridColumn: 5, gridRow: 1 }}>
                <LabeledInput label={i18next.t('instant.booking.widthPlaceholder')} htmlFor={`width-${i}`}>
                  <Control.text
                    autoComplete="nope"
                    model={`.measurements[${i}].width`}
                    id={`width-${i}`}
                    component={InputForLabel}
                    validators={{ validNumber }}
                  />
                </LabeledInput>
                <Errors
                  className="errors"
                  model={`.measurements[${i}].width`}
                  show="touched"
                  wrapper={SmallValidationError}
                  messages={{ validNumber: `${i18next.t('error.notAValidNumber')}` }}
                />
              </div>

              <div style={{ gridColumn: 6, gridRow: 1 }}>
                <LabeledInput label={i18next.t('instant.booking.heightPlaceholder')} htmlFor={`height-${i}`}>
                  <Control.text
                    autoComplete="nope"
                    model={`.measurements[${i}].height`}
                    id={`height-${i}`}
                    component={InputForLabel}
                    validators={{ validNumber }}
                  />
                </LabeledInput>

                <Errors
                  className="errors"
                  model={`.measurements[${i}].height`}
                  show="touched"
                  wrapper={SmallValidationError}
                  messages={{ validNumber: `${i18next.t('error.notAValidNumber')}` }}
                />
              </div>

              <div style={{ gridColumn: 7, gridRow: 1 }}>
                <TertiaryButton type="button" onClick={this.copyMeasurement(i)}>
                  <FontAwesomeIcon icon="plus" />
                  <span style={{ paddingLeft: '0.5em' }}>{i18next.t('instant.booking.addItem')}</span>
                </TertiaryButton>
              </div>
              <div style={{ gridColumn: 8, gridRow: 1 }}>
                <TertiaryButton onClick={this.removeMeasurement(i)} disabled={!canRemoveMeasurements}>
                  <FontAwesomeIcon icon="times" />
                  <span style={{ paddingLeft: '0.5em' }}>{i18next.t('instant.booking.removeItem')}</span>
                </TertiaryButton>
              </div>

              <div style={{ gridColumn: 9, gridRow: 1, padding: '0.5em', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                <div>{this.getMeasurementVolumeByIndex(i).toFixed(1)} dm³</div>
              </div>
            </div>
          ))}

        {formValues.get('measurements') && (
          <BookingTotalWeightAndVolume measurements={formValues.get('measurements')} />
        )}

        {showClearButton && clearComponents && (
          <ClearInstantBookingFormButton clearComponents={clearComponents} components={h2measurementComponentNames} />
        )}
      </InputGroup>
    )
  }
}

export default H2BookingGoods
