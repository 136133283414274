import React from 'react'
import { DescriptionList, TermDescriptionGroup } from '../../DescriptionList'
import styled from 'styled-components'
import { Customer, Goods, Route, Services } from '../shared/Shipment'
import { ConsignmentState } from '../../../utils/consignmentState'
import { FlexRow } from '../../../primitives/Flex'
import { getIconClassForConsignmentState } from '../../../utils/getIconForConsignmentState'
import { Small } from '../../../primitives/Small'
import i18next from 'i18next'
import { H1 } from '../../../primitives/Headings'
import { PrimaryButton, SecondaryButton, TertiaryButton } from '../../../primitives/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import variables from '../../../styles/variables'
import { DepartmentIdType, OrderIdType } from '../../../types/coreEntitiesTypes'
import { ContextType } from '../../../pages/instant/InstantGrid'
import { bookingEditUrl } from '../../../actions/creators/urlHelpers'
import { CustomerIdType } from '../../../domain/customer'
import { GoodsComponent } from '../../GoodsDetails'
import { OrderType } from '@glow/entity-types'

export const ShipmentInfo: React.FC<{
  shipmentNumber: string
  state: ConsignmentState
  isMultileg: boolean
  customer: Customer
  customerContact?: string
  goods: Goods
  services: Services
  route: Route
  orderId: OrderIdType
  departmentId: DepartmentIdType
  isEditable: boolean
  isDeleted: boolean
  editing: boolean
  setEditing: (status: boolean) => void
  enableQuickEdit?: boolean
  resetForm: () => void
  orderType: OrderType | null
}> = (props) => (
  <>
    <FlexRow alignItems={'baseline'} gap={20} inline>
      <H1 size="l">
        {i18next.t('shipment.shipment')} {props.shipmentNumber}{' '}
        {props.isMultileg && <>({i18next.t('shipment.multileg')})</>}
      </H1>
      <State state={props.state} />
    </FlexRow>
    <ActionButtons
      editing={props.editing}
      isDeleted={props.isDeleted}
      orderId={props.orderId}
      departmentId={props.departmentId}
      isEditable={props.isEditable}
      customerId={props.customer.id}
      setEditing={props.setEditing}
      enableQuickEdit={props.enableQuickEdit}
      resetForm={props.resetForm}
      orderType={props.orderType}
    />
    <Grid>
      <CustomerComponent customer={props.customer} />
      <GoodsComponent goods={props.goods} />
      <ServicesWrapper>
        <ServicesComponent services={props.services} />
      </ServicesWrapper>
      <RouteComponent route={props.route} />
      <CustomerContactComponent customerContact={props.customerContact} />
    </Grid>
  </>
)

const ActionButtons: React.FC<{
  editing: boolean
  isDeleted: boolean
  orderId: OrderIdType
  departmentId: DepartmentIdType
  isEditable: boolean
  customerId: CustomerIdType
  setEditing: (status: boolean) => void
  enableQuickEdit?: boolean
  resetForm: () => void
  orderType: OrderType | null
}> = ({
  editing,
  isDeleted,
  isEditable,
  orderId,
  departmentId,
  setEditing,
  customerId,
  enableQuickEdit,
  resetForm,
  orderType
}) => (
  <div style={{ float: 'right', display: isDeleted ? 'none' : '' }}>
    {editing ? (
      <>
        <SecondaryButton
          style={{ height: '32px', marginRight: '10px', minWidth: '84px' }}
          type="button"
          onClick={() => {
            setEditing(false)
            resetForm()
          }}
        >
          {i18next.t('button.cancel')}
        </SecondaryButton>
        <PrimaryButton
          style={{ height: '32px', background: variables.newColors.primaryGreen, minWidth: '84px' }}
          type="submit"
        >
          {i18next.t('button.save')}
        </PrimaryButton>
      </>
    ) : (
      <>
        <FullEditButton
          orderId={orderId}
          departmentId={departmentId}
          customerId={customerId}
          isEditable={isEditable}
          orderType={orderType}
        />
        <QuickEditButton onClick={() => setEditing(true)} isEditable={isEditable} enableQuickEdit={enableQuickEdit} />
      </>
    )}
  </div>
)

const QuickEditButton: React.FC<{
  onClick: () => void
  isEditable: boolean
  enableQuickEdit?: boolean
}> = ({ onClick, isEditable, enableQuickEdit }) => (
  <span style={{ display: !enableQuickEdit ? 'none' : '' }}>
    <TertiaryButton
      style={{ height: '32px', padding: '5px 12px', minWidth: '85px' }}
      onClick={onClick}
      disabled={!isEditable}
      type="button"
    >
      <FontAwesomeIcon style={{ marginRight: '0.5rem' }} icon={['far', 'pen']} color={variables.newColors.darkGrey} />
      <span style={{ color: variables.newColors.darkGrey }}>{i18next.t('button.edit')}</span>
    </TertiaryButton>
  </span>
)

export const FullEditButton: React.FC<{
  orderId: OrderIdType
  departmentId: DepartmentIdType
  customerId: CustomerIdType
  isEditable: boolean
  orderType: OrderType | null
}> = ({ orderId, departmentId, isEditable, orderType }) => {
  const onClick = () => {
    const win = window.open(bookingEditUrl(ContextType.Planner, departmentId, orderId, orderType), '_blank')
    if (win) win.focus()
  }

  return (
    <>
      <TertiaryButton
        style={{ height: '32px', padding: '5px 12px', minWidth: '85px', marginRight: '10px' }}
        onClick={onClick}
        disabled={!isEditable}
        type="button"
      >
        <FontAwesomeIcon style={{ marginRight: '0.5rem' }} icon="external-link" color={variables.newColors.darkGrey} />
        <span style={{ color: variables.newColors.darkGrey }}>{i18next.t('button.fullEdit')}</span>
      </TertiaryButton>
    </>
  )
}

export const CustomerComponent: React.FC<{ customer: Customer }> = ({ customer }) => (
  <DescriptionList>
    <TermDescriptionGroup term={i18next.t('shipment.customer')} description={customer.name} />
    <TermDescriptionGroup term={i18next.t('shipment.customerNumber')} description={customer.number} topMargin />
  </DescriptionList>
)

const CustomerContactComponent: React.FC<{ customerContact?: string }> = ({ customerContact }) => (
  <DescriptionList>
    <TermDescriptionGroup term={i18next.t('shipment.customerContact')} description={customerContact} />
  </DescriptionList>
)

const RouteComponent: React.FC<{ route: Route }> = ({ route }) => (
  <DescriptionList>
    <TermDescriptionGroup
      term={i18next.t('shipment.route')}
      description={route.name && route.courierName ? `${route.name} (${route.courierName})` : route.name}
    />
  </DescriptionList>
)

export const ServicesComponent: React.FC<{ services: Services }> = ({ services }) => (
  <DescriptionList>
    <TermDescriptionGroup term={i18next.t('shipment.service')} description={services.service.description} />
    <TermDescriptionGroup
      term={i18next.t('shipment.additionalServices')}
      description={services.valueAddedServices.map((vas) => vas.text).join(', ')}
      topMargin
    />
  </DescriptionList>
)

export const State: React.FC<{ state: ConsignmentState }> = ({ state = ConsignmentState.CREATED }) => (
  <FlexRow alignItems={'center'}>
    {getIconClassForConsignmentState(state)}
    <br />
    <Small>{i18next.t(`consignment.message.${state}`)}</Small>
  </FlexRow>
)

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 1.25rem;
  justify-items: start;
  align-items: start;
`

const ServicesWrapper = styled.div`
  grid-row-start: 2;
  grid-row-end: span 2;
`
