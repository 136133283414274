import {
  FormikDate,
  FormikInput,
  FormikRadioSelect,
  FormikTime,
  formikValidationRequired
} from '@glow/formik-components'
import { IconFa, LayoutFormSection, Option, Text } from '@glow/ui-components'
import i18next from 'i18next'
import { List } from 'immutable'
import React, { ReactNode, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { departmentsSelector, selectedDepartmentIdsSelector } from '../../../selectors/departmentsSelector'
import { DepartmentIdType, OrderIdType } from '../../../types/coreEntitiesTypes'
import { AppStateType } from '../../../utils/appStateReduxStore'
import { userLanguage } from '../../../utils/dateTime'
import { selectDeviationOptions } from '../../../utils/deviation'
import { ManualOverrideFormProps, PartialConsignmentMap } from '../details-page/ManualOverrideView'
import { UnitDriverForm } from '../details-page/deviations/UnitDriverForm'
import { EventType } from '../../../utils/consignmentEvent'

interface FormSelectorProps {
  values: ManualOverrideFormProps
  orderIds: List<OrderIdType>
  urlDepartmentId: string
  consignments: List<PartialConsignmentMap>
}

export const FormFieldsByEvent = ({ values, orderIds, urlDepartmentId, consignments }: FormSelectorProps) => {
  const selectedDepartmentIds = useSelector((state: AppStateType) => {
    return selectedDepartmentIdsSelector(state, urlDepartmentId)
  })

  switch (values.event) {
    case EventType.SCANNED:
      return <ScannedForm values={values} />
    case EventType.ORDER_SORTED:
    case EventType.NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL:
      return <DateAndTime values={values} />
    case EventType.DEVIATED:
      return (
        <DeviatedForm
          departmentIds={selectedDepartmentIds}
          orderIds={orderIds}
          values={values}
          consignments={consignments}
        />
      )
    case EventType.COLLECTED:
      return <CollectedForm departmentIds={selectedDepartmentIds} orderIds={orderIds} values={values} />
    default:
      return <ReturnedAndDeliveredForm departmentIds={selectedDepartmentIds} orderIds={orderIds} values={values} />
  }
}

interface FormProps {
  values: ManualOverrideFormProps
  orderIds: List<OrderIdType>
  departmentIds: List<DepartmentIdType>
}

interface DeviatedFormProps extends FormProps {
  consignments: List<PartialConsignmentMap>
}

const UnitDriverDateBaseForm = ({
  values,
  orderIds,
  departmentIds
}: Omit<FormProps, 'setFieldValue'> & { children?: ReactNode }) => (
  <LayoutFormSection className="mt-4">
    <UnitDriverForm orderIds={orderIds} departmentIds={departmentIds} />
    <DateAndTime values={values} />
  </LayoutFormSection>
)

const CollectedForm = ({ values, orderIds, departmentIds }: FormProps) => (
  <UnitDriverDateBaseForm values={values} orderIds={orderIds} departmentIds={departmentIds} />
)
const ReturnedAndDeliveredForm = ({ values, orderIds, departmentIds }: FormProps) => (
  <>
    <FormikInput
      name="name"
      className="mt-2"
      validate={(value: string) => formikValidationRequired(value, i18next.t('shipmentDetails.required'))}
      label={i18next.t('consignment.recipientName')}
      required
    />
    <UnitDriverDateBaseForm values={values} orderIds={orderIds} departmentIds={departmentIds} />
  </>
)

const DeviatedForm = ({ values, orderIds, departmentIds, consignments }: DeviatedFormProps) => {
  const options = selectDeviationOptions(consignments)

  const selectedDeviationLabel = useMemo(() => {
    return options.find((courier) => courier.type !== 'header' && courier.code === values.deviationCode)?.label
  }, [values.deviationCode])
  return (
    <>
      <FormikRadioSelect
        name="deviationCode"
        containerClassName="mt-2"
        options={options}
        label={i18next.t('shipmentDetails.selectDeviation')}
        aria-label={i18next.t('shipmentDetails.selectDeviation')}
        placeholder={i18next.t('shipmentDetails.selectDeviation')}
        selectedLabel={selectedDeviationLabel}
      />
      <UnitDriverDateBaseForm values={values} orderIds={orderIds} departmentIds={departmentIds} />
    </>
  )
}

const ScannedForm = ({ values }: { values: ManualOverrideFormProps }) => {
  const departments = useSelector((state: AppStateType) => departmentsSelector(state))
  const departmentsForUser: Option[] = useMemo(
    () =>
      departments.map((department) => ({ code: department.get('id') + '', label: department.get('name') })).toArray(),
    [departments]
  )

  const selectedDepartmentLabel = useMemo(() => {
    return departmentsForUser.find((department) => department.code == values.departmentId)?.label
  }, [departmentsForUser, values.departmentId])

  return (
    <>
      <Text as="p" textType="base" className="text-emphasis-medium mt-2">
        {i18next.t('manualOverride.scannedFormDescription')}
      </Text>
      <FormikRadioSelect
        name="departmentId"
        containerClassName="mt-4"
        options={departmentsForUser}
        label={i18next.t('manualOverride.selectDepartment')}
        aria-label={i18next.t('manualOverride.selectDepartment')}
        placeholder={i18next.t('manualOverride.selectDepartment')}
        selectedLabel={selectedDepartmentLabel}
        validate={(value: string) => formikValidationRequired(value, i18next.t('application.requiredShort'))}
        required
      />
      <DateAndTime values={values} />
    </>
  )
}

const DateAndTime = ({ values }: { values: ManualOverrideFormProps }) => (
  <div className="mt-4 w-1/2 flex gap-4">
    <FormikDate
      value={values.date}
      icon={<IconFa icon={['far', 'calendar-alt']} />}
      name="date"
      lang={userLanguage}
      label={i18next.t('shipmentDetails.selectDate')}
    />
    <FormikTime name="time" label={i18next.t('shipmentDetails.time')} type="time" />
  </div>
)
