import { List } from 'immutable'
import React from 'react'
import { Consignment } from '../../../../types/coreEntitiesTypes'
import { ConsignmentBox } from './ConsignmentBox'

interface ConsignmentsProps {
  consignments: List<Consignment>
  consignmentsCount: number
}

export const Consignments = ({ consignments, consignmentsCount }: ConsignmentsProps) => (
  <>
    {consignments.map((consignment, index) => (
      <ConsignmentBox
        key={index}
        consignment={consignment}
        consignmentNumber={++index}
        consignmentsCount={consignmentsCount}
      />
    ))}
  </>
)
