import { Collection, List, Map } from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
import { MOVE_ORDERS } from '../actions/actionTypes'
import { mergeFormValues } from '../actions/creators/formHelpers'
import { getAllDepartmentsWithAdminLevelAccess, moveOrders } from '../actions/creators/helpers'
import { sortByName } from '../pages/instant/bookingOrderFunctions'
import ErrorContainer from '../primitives/ErrorContainer'
import ImmutableComponent from '../primitives/ImmutableComponent'
import { consignmentsByOrderIdsSelector } from '../selectors/consignmentSelectors'
import { allDepartmentsSelector, departmentsSelector } from '../selectors/departmentsSelector'
import { errorMessagesFor } from '../selectors/httpStatusSelectors'
import { ConsignmentIdType, Department, DepartmentIdType, OrderIdType } from '../types/coreEntitiesTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { isAdminUser } from '../utils/roles'
import { MoveShipmentToAnotherDepartment } from './ShipmentEditModal'

class MoveShipmentsToAnotherDepartmentModal extends ImmutableComponent<Props> {
  componentDidMount(): void {
    this.props.getAllDepartmentsWithAdminLevelAccess()
  }

  handleMoveOrders = (departmentId: DepartmentIdType) => {
    this.props.moveOrders(this.props.selectedOrderIds || List(), departmentId, this.props.consignmentIds ?? List())
  }

  render() {
    const { errorMessages, selectedOrderIds } = this.props
    return (
      <div>
        {errorMessages && !errorMessages.isEmpty() && (
          <ErrorContainer center style={{ marginBottom: '1em' }}>
            {errorMessages.map((msg: string) => (
              <>
                {msg}
                <br />
              </>
            ))}
          </ErrorContainer>
        )}
        <MoveShipmentToAnotherDepartment
          moveOrders={this.handleMoveOrders}
          moveOrderDepartments={this.props.moveOrderDepartments}
          moveOrderGroupedDepartments={this.props.moveOrderGroupedDepartments}
          orderIds={selectedOrderIds || List()}
          showDialogBox={false}
          mergeFormValues={this.props.mergeFormValues}
        />
      </div>
    )
  }
}

interface StateProps {
  errorMessages: List<string> | null
  moveOrderDepartments: List<Department>
  moveOrderGroupedDepartments: List<Collection<number, Department>>
  selectedOrderIds?: List<OrderIdType>
  consignmentIds?: List<ConsignmentIdType>
}

interface DispatchProps {
  moveOrders: (
    orderIds: List<OrderIdType>,
    departmentId: DepartmentIdType,
    consignmentIds: List<ConsignmentIdType>
  ) => void
  getAllDepartmentsWithAdminLevelAccess: () => void
  mergeFormValues: (formName: string, values: Map<string, any>) => void
}

interface OwnProps {
  department?: Department
  departmentId?: number
  selectedOrderIds?: List<OrderIdType>
}

type Props = StateProps & DispatchProps & OwnProps

export default connect<StateProps, DispatchProps, OwnProps, AppStateType>(
  (state, ownProps): StateProps => {
    const allDepartments = allDepartmentsSelector(state)
    const departmentsForUser = departmentsSelector(state)
    const consignmentIds = consignmentsByOrderIdsSelector(state, ownProps.selectedOrderIds ?? List())
      .map((c) => c.get('id'))
      .toList()
    const isAdmin = isAdminUser(state)
    const departments = isAdmin ? departmentsForUser.sort(sortByName) : List<Department>()
    const groupedDepartments = isAdmin
      ? List<Department>()
      : sortDepartmentsAccessToFirst(departmentsForUser, allDepartments)
    const errorMessages = errorMessagesFor(state, MOVE_ORDERS)
    return {
      errorMessages,
      moveOrderDepartments: departments,
      consignmentIds,
      moveOrderGroupedDepartments: groupedDepartments,
      selectedOrderIds: ownProps.selectedOrderIds && List(ownProps.selectedOrderIds)
    }
  },
  {
    moveOrders,
    getAllDepartmentsWithAdminLevelAccess,
    mergeFormValues
  }
)(MoveShipmentsToAnotherDepartmentModal)

export function sortDepartmentsAccessToFirst(
  departments: List<Department>,
  allDepartmentsSameCountry: List<Department>
): List<List<Department>> {
  const sortedAccessDepartments = departments.sort(sortByName)
  return List([sortedAccessDepartments, allDepartmentsSameCountry.sort(sortByName)])
}
