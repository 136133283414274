import i18next from 'i18next'
import { eventDataValue, EventDataValue } from '@glow/entity-types'
import React from 'react'
import { IconFa } from '@glow/ui-components'
import { AnyData } from '../../../../actions/creators/baseHelpers'

export function EventMap(props: { data: AnyData }) {
  const lat = forceFloat(eventDataValue(props.data as Record<string, unknown> | null, ['location', 'latitude']))
  const lng = forceFloat(eventDataValue(props.data as Record<string, unknown> | null, ['location', 'longitude']))

  if (!lat || !lng) {
    return null
  }

  return (
    <p>
      <a
        href={`https://maps.google.com/?q=${lat},${lng}`}
        target="_blank"
        rel="noreferrer"
        className="text-emphasis-extra flex gap-2 items-center underline"
      >
        <IconFa icon={['far', 'external-link']} />
        {i18next.t('shipmentDetails.mapLinkText')}
      </a>
    </p>
  )
}

function forceFloat(value: EventDataValue): number {
  if (typeof value !== 'number') {
    return 0
  }

  return value
}
