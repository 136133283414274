import * as React from 'react'
import i18next from 'i18next'
import styled from 'styled-components'
import { LabeledInput } from '../../../primitives/LabeledInput'
// @ts-expect-error
import { Control } from 'react-redux-form/lib/immutable'
import { InputForLabel } from '../../../primitives/Forms'
import { validNumber } from '../../../utils/inputValidation'
import vars from '../../../styles/variables'
import { IOrderForm } from '../bookingOrderTypes'

const ADRGoodsGridRow = styled.div`
  display: grid;
  grid-template-columns: 80px 40px 50px 70px auto 110px 70px;
  grid-gap: 4px;

  h5 {
    margin-top: 0;
  }
`

const FlexRightContainer = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 14px;
  justify-content: flex-end;
  gap: 80px;
  padding: 12px;
  margin-top: 16px;
  border-radius: 4px;
`

const CenterText = styled.div`
  text-align: center;
`

const TextEllipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 4px;
`

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`
interface Props {
  formValues: IOrderForm
}

export const BookingFormADRGoods = ({ formValues }: Props) => {
  const adrGoods = formValues.get('adrGoods')
  if (!adrGoods || adrGoods.count() === 0) return null

  const totalPoints = adrGoods.reduce((a, b) => a + b.get('hazardPoints'), 0)
  return (
    <div>
      <h4>{i18next.t('Hazardous goods')}</h4>
      <div>
        <ADRGoodsGridRow>
          <h5>{i18next.t('instant.booking.unNumber')}</h5>
          <h5>{i18next.t('instant.booking.class')}</h5>
          <h5>{i18next.t('instant.booking.group')}</h5>
          <h5>{i18next.t('instant.booking.netWeight')}</h5>
          <h5>{i18next.t('instant.booking.name')}</h5>
          <h5>{i18next.t('instant.booking.restrictions')}</h5>
          <h5>{i18next.t('instant.booking.points')}</h5>
        </ADRGoodsGridRow>
        {adrGoods.map((adrGood, index) => (
          <ADRGoodsGridRow key={`adrGood.name-${index}`}>
            <CenterText>{adrGood.get('hazardIdentifier')}</CenterText>
            <CenterText>{adrGood.get('hazardClass')}</CenterText>
            <CenterText>{adrGood.get('packingGroup')}</CenterText>
            <CenterText>{adrGood.get('netWeightKg')} kg</CenterText>
            <TextEllipsis style={{ minWidth: 100 }} title={adrGood.get('technicalName')}>
              {adrGood.get('technicalName')}
            </TextEllipsis>
            <List>
              {adrGood.get('restrictions').map((restriction) => (
                <li key={restriction.get('type')}>
                  <TextEllipsis title={`${restriction.get('value')} - ${restriction.get('type')}`}>{`${restriction.get(
                    'value'
                  )} - ${restriction.get('type')}`}</TextEllipsis>
                </li>
              ))}
            </List>
            <LabeledInput
              label={i18next.t('instant.booking.points')}
              htmlFor={`.adrGoods[${index}].hazardPoints`}
              style={{ marginBottom: 8 }}
            >
              <Control.text
                autoComplete="nope"
                model={`.adrGoods[${index}].hazardPoints`}
                id={`.adrGoods[${index}].hazardPoints`}
                component={InputForLabel}
                parser={Number}
                validators={{ validNumber }}
              />
            </LabeledInput>
          </ADRGoodsGridRow>
        ))}
      </div>
      <FlexRightContainer style={{ backgroundColor: vars.colors.gray }}>
        <div>{i18next.t('instant.booking.totalPoints').toLocaleUpperCase()}:</div>
        <div>{totalPoints}</div>
      </FlexRightContainer>
    </div>
  )
}
