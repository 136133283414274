import React from 'react'
import isFunction from 'lodash/isFunction'
import ImmutableComponent from '../primitives/ImmutableComponent'
import Autosuggest from 'react-autosuggest'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import { clearSearchSuggestions, searchCustomersWithoutSubcustomers } from '../actions/creators/helpers'
import { changeFormValue } from '../actions/creators/formHelpers'
import { AUTOSUGGEST_CUSTOMERS } from '../actions/actionTypes'
import debounce from 'lodash/debounce'
import { InputForLabel } from '../primitives/Forms'
import { SuggestionsContainer } from '../primitives/AutoSuggest'
import { isNotEmpty } from '../utils/collectionUtils'
import { LabeledInput } from '../primitives/LabeledInput'
import { searchAddresses } from '../actions/creators/addressHelpers'
import { sortByName } from '../pages/instant/bookingOrderFunctions'
import { autoSuggestTheme, renderSuggestion } from './AutoSuggestWrapper'
import { AppStateType } from '../utils/appStateReduxStore'

const isSelecting = (method: string) => method === 'click' || method === 'enter'
const isTyping = (method: string) => method === 'type'

class AutoSuggestWrapperForInternalBooking extends ImmutableComponent<Props> {
  private searchDebounced: (value: string) => void
  constructor(props: Props) {
    super(props)
    this.searchDebounced = debounce(this.search.bind(this), 300)
  }

  onSuggestionSelected = (event: { preventDefault: () => void }) => {
    event.preventDefault()
  }

  renderInputComponent = (inputProps: any) => (
    <LabeledInput label={this.props.placeholder ?? ''} htmlFor={inputProps.id}>
      <InputForLabel {...inputProps} />
    </LabeledInput>
  )

  renderSuggestionsContainer = ({ containerProps, children }: any) => (
    <SuggestionsContainer show={!!children} {...containerProps}>
      {children}
    </SuggestionsContainer>
  )

  search = (value: string) => {
    const departmentIds = this.props.departmentIds
    this.props.searchCustomersWithoutSubcustomers(departmentIds, value)
  }

  suggestionLabel = (suggestion: { name: any }) => suggestion.name

  renderSuggestion = (suggestion: { name: any }, { query, isHighlighted }: any) => {
    const label = this.props.suggestionLabel ? this.props.suggestionLabel(suggestion) : this.suggestionLabel(suggestion)
    return renderSuggestion(label, query, isHighlighted)
  }

  onInputChanged = (event: { preventDefault: () => void }, value: any, method: string) => {
    if (isTyping(method)) {
      this.props.onChange?.(event)
    } else if (isSelecting(method)) {
      event.preventDefault()
      const label = isFunction(this.props.getLabel) ? this.props.getLabel(value) : value.name || value
      this.props.onChange?.(label)
      this.props.onSelect?.(Map(value))
    }
  }

  render() {
    const {
      id,
      placeholder,
      labeled,
      value,
      searchResults,
      searchType = AUTOSUGGEST_CUSTOMERS,
      getSuggestionValue = (v) => v,
      highlightFirstSuggestion = true,
      focusInputOnSuggestionClick = false,
      shouldRenderSuggestions = (v) => Boolean(v && v.trim().length > 2)
    } = this.props

    return (
      <Autosuggest
        suggestions={searchResults}
        onSuggestionsFetchRequested={({ value }) => this.searchDebounced(value)}
        onSuggestionsClearRequested={() => this.props.clearSearchSuggestions(searchType)}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderInputComponent={this.renderInputComponent}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        onSuggestionSelected={this.onSuggestionSelected}
        highlightFirstSuggestion={highlightFirstSuggestion}
        focusInputOnSuggestionClick={focusInputOnSuggestionClick}
        shouldRenderSuggestions={shouldRenderSuggestions}
        inputProps={
          {
            id: id,
            autoComplete: 'nope',
            placeholder: labeled ? '' : placeholder,
            value: value,
            labeled: labeled,
            onChange: (event: { preventDefault: () => void }, { newValue, method }: any) =>
              this.onInputChanged(event, newValue, method)
          } as any
        }
        theme={autoSuggestTheme}
      />
    )
  }
}
interface Props {
  departmentIds: List<number>
  departmentId: string
  id: string
  labeled: string
  value: string
  searchResults: any
  searchType: string
  getSuggestionValue: (v: any) => any
  highlightFirstSuggestion: boolean
  focusInputOnSuggestionClick: boolean
  onChange?: (event: any) => void
  onSelect?: (event: any) => void
  suggestionLabel?: (event: any) => void
  getLabel?: (event: any) => void
  shouldRenderSuggestions: (value: string) => boolean
  placeholder?: string
  clearSearchSuggestions: (type: string) => any
  searchCustomersWithoutSubcustomers: (departmentIds: List<number>, searchPhrase: string) => any
  searchAddresses: (departmentIds: number | List<number>, searchPhrase: string, customerId: number) => any
  changeFormValue: (model: string, value: any) => any
}
export default connect(
  (state: AppStateType, ownProps: Props) => {
    const searchType = AUTOSUGGEST_CUSTOMERS
    const searchResults = state.getIn(['searchReducer', searchType])
    return {
      searchResults: isNotEmpty(searchResults) ? searchResults.valueSeq().sort(sortByName).toJS() : [],
      searchType: searchType,
      id: searchType + ownProps.id,
      modelAndId: ownProps.id,
      departmentIds: ownProps.departmentIds || List([ownProps.departmentId])
    }
  },
  {
    clearSearchSuggestions,
    searchCustomersWithoutSubcustomers,
    searchAddresses,
    changeFormValue
  }
)(AutoSuggestWrapperForInternalBooking)
