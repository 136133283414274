import i18next from 'i18next'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import React from 'react'
import { IconFa } from '@glow/ui-components'

export function EventFromToDepartment(props: { data: AnyData }) {
  const moveOrderToAnotherDepartmentData = props.data
    ? (props.data.departmentName as { from: string; to: string })
    : null
  if (moveOrderToAnotherDepartmentData) {
    return (
      <div className="flex gap-1 items-center">
        <span>{i18next.t('consignmentEvent.message.order-moved-to-department-with-data')}</span>
        <span>{moveOrderToAnotherDepartmentData.from}</span>
        <IconFa icon={['far', 'arrow-right']} />
        <span>{moveOrderToAnotherDepartmentData.to}</span>
      </div>
    )
  } else {
    return null
  }
}
