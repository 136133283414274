import isNil from 'lodash/isNil'
import { webSocketUrl } from '@glow/common'
import { formatSocketQueryParams, WebSocketContextImmutable } from './wsUtils'
import { isImmutable } from 'immutable'
import { Store } from 'redux'
import { siteMetricsCaptureErrorMessage } from '@glow/instrumentation'

let source: WebSocket | null
let wsParams = ''
let keepOpen = true
let reconnectNow = false
let lastPing: Date
let retrying = false
let checking = false
let retries = 0
const retriesUntilNotification = 3
const seenSentryMessages: { [k: string]: boolean } = {}

type Handlers = {
  errorHandler: (error: string) => void
  messageHandler: (message: string) => void
  clearErrorHandler: () => void
}

const retryOpening = (handlers: Handlers) => {
  if (!keepOpen || retrying) {
    return
  }
  retrying = true
  retries++
  setTimeout(() => {
    console.log(`ws closed, reopening ${keepOpen}`)
    if (keepOpen) {
      retrying = false
      startWS(null, handlers)
    }
  }, 2000)
}

const doReconnect = (localSource: WebSocket, code: number, reason: string, handlers: Handlers) => {
  console.log('reconnecting now', reason)
  localSource && localSource.close(code, reason)
  source = null
  reconnectNow = false
  setTimeout(() => {
    startWS(null, handlers)
  }, 500)
}

function logAndSendToSentry(localSource: WebSocket, code: number, reason: string, event: any = null) {
  const url = localSource?.url || ''
  const message = `doRetryFromFailure [${code}][${reason}][${url}]`
  const eventCode = event?.code
  const eventReason = event?.reason
  const scope = { url, code, reason, eventCode, eventReason }

  console.log(message, scope, event)
  sendToSentryIfUnique(message, scope)
}

// Only send unique messages to avoid flooding Sentry with reconnect errors.
function sendToSentryIfUnique(message: string, scope: {}) {
  const key = message + JSON.stringify(scope)
  if (!seenSentryMessages[key]) {
    seenSentryMessages[key] = true

    try {
      console.log('sendToSentryIfUnique', 'send', message, scope)
      siteMetricsCaptureErrorMessage(message, scope)
    } catch (err) {
      console.error(err)
    }
  }
}

const doRetryFromFailure = (localSource: WebSocket, code: number, reason: string, handlers: Handlers) => {
  localSource && localSource.close(code, reason)
  source = null
  retryOpening(handlers)
}

const setupWsChecker = (localSource: WebSocket, handlers: Handlers) => {
  if (checking) return
  else checking = true

  setTimeout(() => {
    const timeout = new Date()
    timeout.setSeconds(timeout.getSeconds() - 30)
    if (lastPing && keepOpen && timeout > lastPing) {
      const showNotification = retries > retriesUntilNotification
      const code = 3004
      const reason = 'no ping in 30 seconds'
      doRetryFromFailure(localSource, code, reason, handlers)
      if (showNotification) {
        handlers.errorHandler('notifications.networkError')
        logAndSendToSentry(localSource, code, reason)
      }
      setupWsChecker(localSource, handlers)
    } else if (keepOpen) {
      console.log('ping pong ok!')
      checking = false
      setupWsChecker(localSource, handlers)
    } else {
      checking = false
    }
  }, 10000)
}
export const startWS = (store: Store | null, handlers: Handlers) => {
  keepOpen = true
  if (isNil(source)) {
    const context = store?.getState()?.get('context')
    wsParams = context ? formatSocketQueryParams(context) : wsParams
    console.debug('starting WS with url ' + webSocketUrl(), source, wsParams)
    const localSource = new WebSocket(webSocketUrl() + wsParams)
    source = localSource

    setupWsChecker(localSource, handlers)

    localSource.onmessage = (e) => {
      const data = e.data
      if (data === 'login') {
        console.error('WS told me to log in')
        localSource.close(3001, 'login')
        window.location.href = '/'
      } else if (data === 'ping') {
        lastPing = new Date()
        localSource.send('pong')
      } else if (data === 'ok') {
        lastPing = new Date()
      } else {
        handlers.messageHandler(JSON.parse(e.data))
      }
    }
    localSource.onerror = (e) => {
      console.error('WS got error', retries, e)
      const showNotification = retries > retriesUntilNotification
      if (!reconnectNow) {
        const code = 3002
        const reason = 'error'
        if (showNotification) {
          handlers.errorHandler('notifications.networkError')
          logAndSendToSentry(localSource, code, reason, e)
        }
        doRetryFromFailure(localSource, code, reason, handlers)
      }
    }
    localSource.onopen = (e) => {
      console.log('WS opened', e)
      try {
        const localContext = store?.getState()?.get('context')
        if (isImmutable(localContext)) source?.send(JSON.stringify(localContext.toJS()))
      } catch (e) {
        console.error('could not send WS message...', e)
      }
      retries = 0
    }
    localSource.onclose = (e) => {
      console.log('I got a close', e)
      if (keepOpen) {
        //handlers.errorHandler('notifications.networkError')
      }
      if (reconnectNow) {
        doReconnect(localSource, 1000, 'context change', handlers)
      } else {
        const showNotification = retries > retriesUntilNotification
        const code = 3003
        const reason = 'close'
        if (showNotification) {
          handlers.errorHandler('notifications.networkError')
          logAndSendToSentry(localSource, code, reason, e)
        }
        doRetryFromFailure(localSource, code, reason, handlers)
      }
    }
  }
}

export const changeWSContext = (newContext: WebSocketContextImmutable) => {
  const oldContext = wsParams
  wsParams = formatSocketQueryParams(newContext)
  if (oldContext !== wsParams) {
    if (!isNil(source) && !reconnectNow && newContext && source.readyState === 1) {
      try {
        console.log('changing ws context to:', newContext?.toJS())
        source.send(JSON.stringify(newContext.toJS()))
      } catch (e) {
        console.error('failed to send message on websocket', e)
      }
    } else console.log('will not try to change')
  }
}

export const stopWS = () => {
  console.debug('stopping ws')
  checking = false
  keepOpen = false
  source && source.close()
  source = null
}
