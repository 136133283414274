import i18next from 'i18next'
import { List, Set, fromJS } from 'immutable'
import { DateTime } from 'luxon'
import { HandlerUserFormValues } from '../../components/airexpress/HandlerUserForms'
import { RecurringAirExpressSchedule } from '../../domain/airexpress/airExpressRecurringOrderTypes'
import { AirlineIATA } from '../../domain/airexpress/airline'
import { AirlineTerminal } from '../../domain/airexpress/airlineTerminal'
import { AirportIATA } from '../../domain/airexpress/airport'
import { ConsumedResponse, Http, QueryData } from '../../http/httpHelper'
import { httpDone } from '../../reducers/httpStatus'
import {
  AirlineEntity,
  AirportEntity,
  FlightScheduleEntity,
  toAirline,
  toAirport
} from '../../types/airexpressEntitiesTypes'
import {
  ConsignmentIdType,
  CustomerAlystraId,
  DepartmentIdType,
  ISODateString,
  OrderIdType
} from '../../types/coreEntitiesTypes'
import { AppStateType } from '../../utils/appStateReduxStore'
import { TIMESTAMP_DATE_FORMAT } from '../../utils/inputValidation'
import {
  ADD_LOCAL_FLIGHT_SCHEDULE,
  ActionType,
  CREATE_AIRLINE,
  CREATE_AIRPORT,
  CREATE_AIR_EXPRESS_CONSIGNMENT_EVENT,
  CREATE_AIR_EXPRESS_CONSIGNMENT_EVENT_FOR_ORDER,
  CREATE_AIR_EXPRESS_RECURRING_SCHEDULE,
  CREATE_AIR_EXPRESS_RECURRING_TEMPLATE,
  CREATE_AWB_LABEL,
  CREATE_HANDLER_USER,
  CREATE_OR_UPDATE_AIRLINE_TERMINAL,
  DELETE_AIREXPRESS_ORDERS,
  DELETE_AIRLINE,
  DELETE_AIRPORT,
  DELETE_LOCAL_FLIGHT_SCHEDULE,
  DELETE_RECURRING_SCHEDULE,
  DELETE_RECURRING_TEMPLATE_FROM_SCHEDULE,
  EDIT_RECURRING_SCHEDULE,
  FIND_MASTER_AWBS,
  GET_AIREXPRESS_RECURRING_TEMPLATES,
  GET_AIRLINES,
  GET_AIRLINE_TERMINALS,
  GET_AIRPORTS,
  GET_AIRWAYBILL_STOCK_REMAINDERS,
  GET_AIR_EXPRESS_ORDER,
  GET_AIR_EXPRESS_ORDER_WITH_ALL_LEGS,
  GET_AIR_EXPRESS_RECURRING_SCHEDULES,
  GET_AIR_EXPRESS_RECURRING_TEMPLATES_FOR_DATE,
  GET_AIR_EXPRESS_RECURRING_TEMPLATES_FOR_SCHEDULE,
  GET_CUSTOMS_AGENTS,
  GET_FLIGHT_SCHEDULE_UPLOADS,
  GET_HANDLERS,
  GET_LOCALLY_ADDED_FLIGHT_SCHEDULES,
  GET_MASTER_AIR_WAYBILL_BY_MANIFEST,
  GET_ORDERS_BY_SHIPMENT_IDS,
  GET_ORDER_PRICE_DATA,
  SEARCH_AIR_EXPRESS_DUTIABLE_ORDERS,
  SEARCH_AIR_EXPRESS_ORDERS,
  SEARCH_LIVE_FLIGHTS,
  SEARCH_MANIFESTS,
  SEARCH_SCHEDULED_FLIGHTS,
  SEND_MANIFEST_EMAILS,
  UPDATE_AIRLINE,
  UPDATE_AIRPORT,
  UPDATE_HANDLER_USER,
  UPDATE_LOCAL_FLIGHT_SCHEDULE,
  UPLOAD_FLIGHT_SCHEDULES
} from '../actionTypes'
import { AnyData, ThunkDispatch, ThunkResult } from './baseHelpers'
import { closeModalAndNotify, isReadOnlyUser, setPageStateValue } from './helpers'
import { addNoAccessNotification, addNotification } from './helpersNotifications'

export const airExpressApi = Http('/airexpress/api')

export const asyncAirExpressCommand =
  (actionType: ActionType, data: AnyData) => (dispatch: ThunkDispatch, getState: () => AppStateType) => {
    if (isReadOnlyUser(getState().get('user'))) {
      const msg = 'no access for user with role ' + getState().getIn(['user', 'role'])
      console.warn(msg)
      dispatch(addNoAccessNotification())
      throw msg
    } else return dispatch(asyncAirExpressPost(actionType, `/command/${actionType.name}`, data))
  }

const fetchAirExpressIfNotPresent: (
  actionType: ActionType,
  queryBody: QueryData
) => ThunkResult<Promise<ConsumedResponse>> =
  (actionType: ActionType, queryBody: QueryData) => (dispatch, getState) => {
    if (httpDone(getState, actionType, queryBody)) {
      return Promise.resolve(new Response(null, { status: 200 })) as Promise<ConsumedResponse>
    } else {
      return dispatch(airExpressApi.asyncQuery(actionType, queryBody))
    }
  }

const asyncAirExpressPost = (actionType: ActionType, uri: string, data: AnyData) =>
  airExpressApi.asyncPostJson(actionType, uri, data)

const fetchEvenIfPresent: (actionType: ActionType, queryBody: QueryData) => ThunkResult<Promise<ConsumedResponse>> =
  (actionType: ActionType, queryBody: QueryData) => (dispatch) =>
    dispatch(airExpressApi.asyncQuery(actionType, queryBody))

export const uploadSSIMFile = (file: File) => (dispatch: ThunkDispatch) => {
  const formData = new FormData()
  formData.append('file', file)

  return dispatch(airExpressApi.asyncPostFormData(UPLOAD_FLIGHT_SCHEDULES, '/upload-flight-schedules', formData))
}

const createAirportPayload = (values: AirportEntity) => {
  const airport = toAirport(values)
  return {
    airport: {
      ...airport,
      agreements: airport.agreements.filter((agreement) => agreement.unitId && agreement.unitId > 0)
    }
  }
}

// Filtering out awbPrefix with value = 0 because those are considered dummy items. Not able to remove last row in Formic - FieldArray
const createAirlinePayload = (values: AirlineEntity) => {
  const airline = toAirline(values)
  return {
    ...airline,
    awbPrefix: airline.awbPrefix.filter((awb) => awb.value > 0),
    awbStock: airline.awbStock,
    agreements: airline.agreements.filter((agreement) => agreement.unitId && agreement.unitId > 0)
  }
}

export const getAirlines = () => fetchAirExpressIfNotPresent(GET_AIRLINES, { query: 'airlines', params: {} })

export const getAirwaybillStockRemainders = (shouldFetchIfPresent: boolean = false) => {
  if (shouldFetchIfPresent) {
    return fetchEvenIfPresent(GET_AIRWAYBILL_STOCK_REMAINDERS, { query: 'airwaybillStockRemainders', params: {} })
  } else {
    return fetchAirExpressIfNotPresent(GET_AIRWAYBILL_STOCK_REMAINDERS, {
      query: 'airwaybillStockRemainders',
      params: {}
    })
  }
}

export const getAllAirlineTerminals = () =>
  fetchEvenIfPresent(GET_AIRLINE_TERMINALS, { query: 'airlineTerminals', params: {} })

export const getAirports = () => fetchAirExpressIfNotPresent(GET_AIRPORTS, { query: 'airports', params: {} })

export const getRecurringTemplates = () =>
  fetchEvenIfPresent(GET_AIREXPRESS_RECURRING_TEMPLATES, { query: 'recurringTemplates', params: {} })

type SearchManifestParameters = {
  searchPhrase: string | null
  flightId: string | null
  fromDate: string
  toDate: string
}

export const searchManifests = (params: SearchManifestParameters) => (dispatch: ThunkDispatch) =>
  dispatch(
    airExpressApi.asyncQuery(SEARCH_MANIFESTS, {
      query: 'searchManifests',
      params
    })
  )

export const getMasterAirWaybillByManifestId = (params: { manifestId: number }) => (dispatch: ThunkDispatch) =>
  dispatch(
    airExpressApi.asyncQuery(GET_MASTER_AIR_WAYBILL_BY_MANIFEST, {
      query: 'masterAirWaybill',
      params
    })
  )

interface SendManifestEmailsPayload {
  manifestIds: Set<number>
}

export const sendManifestEmails = (payload: SendManifestEmailsPayload) => (dispatch: ThunkDispatch) =>
  dispatch(
    asyncAirExpressCommand(SEND_MANIFEST_EMAILS, {
      type: 'SEND_MANIFEST_EMAILS',
      payload
    })
  )

interface PayloadCreateAirExpressConsignmentEventForOrder {
  orderId: number
  consignmentEventType: string
  departmentId: DepartmentIdType
  manifestId: number
  eventNotes?: string
}

export const createAirExpressConsignmentEventForOrder =
  (payload: PayloadCreateAirExpressConsignmentEventForOrder) => (dispatch: ThunkDispatch) => {
    dispatch(
      asyncAirExpressCommand(CREATE_AIR_EXPRESS_CONSIGNMENT_EVENT_FOR_ORDER, {
        type: 'CREATE_AIR_EXPRESS_CONSIGNMENT_EVENT_FOR_ORDER',
        payload
      })
    )
  }

export interface CreateAirExpressConsignmentEvent {
  consignments: Set<ConsignmentIdType>
  consignmentEventType: string
  departmentId: DepartmentIdType
  manifestId: number
  eventNotes?: string
}

export const createAirExpressConsignmentEvent =
  (payload: CreateAirExpressConsignmentEvent) => (dispatch: ThunkDispatch) =>
    dispatch(
      asyncAirExpressCommand(CREATE_AIR_EXPRESS_CONSIGNMENT_EVENT, {
        type: 'CREATE_AIR_EXPRESS_CONSIGNMENT_EVENT',
        payload
      })
    )

export interface ShipmentSearchOptions {
  departmentId: DepartmentIdType
  fromDate: ISODateString
  toDate: ISODateString
  searchPhrase?: string
  customerAlystraId?: CustomerAlystraId
  userId?: number
  invoicingSearchFilter?: InvoicingSearchFilter
}

export enum InvoicingSearchFilter {
  'ALL' = 'ALL',
  'INVOICED_OK' = 'INVOICED_OK',
  'TO_BE_INVOICED' = 'TO_BE_INVOICED',
  'ORDER_CORRECTION' = 'ORDER_CORRECTION',
  'INVOICED_FAILED' = 'INVOICED_FAILED'
}

export interface ManifestSearchOptions {
  departmentId: DepartmentIdType
  fromDate: ISODateString
  toDate: ISODateString
  flightId?: string | null
  searchPhrase?: string
}

export const searchAirExpressOrders = (params: ShipmentSearchOptions) =>
  fetchEvenIfPresent(SEARCH_AIR_EXPRESS_ORDERS, {
    query: 'searchAirExpressOrders',
    params: { ...params, customerAlystraId: params.customerAlystraId || undefined }
  })

export const searchAirExpressDutiableOrders = (params: ShipmentSearchOptions) =>
  fetchEvenIfPresent(SEARCH_AIR_EXPRESS_DUTIABLE_ORDERS, { query: 'searchAirExpressDutiableOrders', params })

export const getAirExpressOrderById = (id: OrderIdType) =>
  fetchAirExpressIfNotPresent(GET_AIR_EXPRESS_ORDER, {
    query: 'airExpressOrderById',
    params: { id: id, includeRoutePoints: true }
  })

export const getAirExpressOrderByIdWithAllLegs = (id: OrderIdType) =>
  fetchAirExpressIfNotPresent(GET_AIR_EXPRESS_ORDER_WITH_ALL_LEGS, {
    query: 'airExpressOrderByIdWithAllLegs',
    params: { id: id, includeRoutePoints: true }
  })

export const addAirport = (values: AirportEntity) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(
      asyncAirExpressCommand(CREATE_AIRPORT, {
        type: 'CREATE_AIRPORT',
        payload: createAirportPayload(values)
      })
    ).then(
      (res) => (res.ok ? dispatch(closeModalAndNotify(i18next.t('airexpress.airports.created'))) : res),
      (err) => console.log('addAirport', err)
    )
  }
}

export const updateAirport = (values: AirportEntity) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(
      asyncAirExpressCommand(UPDATE_AIRPORT, {
        type: 'UPDATE_AIRPORT',
        payload: createAirportPayload(values)
      })
    ).then(
      (res) => (res.ok ? dispatch(closeModalAndNotify(i18next.t('airexpress.airports.updated'))) : res),
      (err) => console.log('updateAirport', err)
    )
  }
}

export const deleteAirport = (id: number) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(
      asyncAirExpressCommand(DELETE_AIRPORT, {
        type: 'DELETE_AIRPORT',
        payload: { id }
      })
    ).then(
      (res) =>
        res.ok
          ? dispatch(
              addNotification({
                title: i18next.t('airexpress.airports.deleted'),
                message: '',
                timeout: 5000
              })
            )
          : res,
      (err) => console.log('deleteAirport', err)
    )
  }
}
export const addAirline = (values: AirlineEntity) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(
      asyncAirExpressCommand(CREATE_AIRLINE, {
        type: 'CREATE_AIRLINE',
        payload: createAirlinePayload(values)
      })
    ).then(
      (res) => (res.ok ? dispatch(closeModalAndNotify(i18next.t('airexpress.airlines.created'))) : res),
      (err) => console.log('addAirline', err)
    )
  }
}

export const updateAirline = (values: AirlineEntity) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(
      asyncAirExpressCommand(UPDATE_AIRLINE, {
        type: 'UPDATE_AIRLINE',
        payload: createAirlinePayload(values)
      })
    ).then(
      (res) => (res.ok ? dispatch(closeModalAndNotify(i18next.t('airexpress.airlines.updated'))) : res),
      (err) => console.log('updateAirline', err)
    )
  }
}

export const deleteAirline = (id: number) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(
      asyncAirExpressCommand(DELETE_AIRLINE, {
        type: 'DELETE_AIRLINE',
        payload: { id }
      })
    ).then(
      (res) =>
        res.ok
          ? dispatch(
              addNotification({
                title: i18next.t('airexpress.airlines.deleted'),
                message: '',
                timeout: 5000
              })
            )
          : res,
      (err) => console.log('deleteAirline', err)
    )
  }
}
export const getLocallyAddedFlightSchedules = () =>
  fetchAirExpressIfNotPresent(GET_LOCALLY_ADDED_FLIGHT_SCHEDULES, { query: 'locallyAddedFlightSchedules', params: {} })

const createFlightSchedulePayload = (values: FlightScheduleEntity) => ({
  flightSchedule: {
    id: values.get('id'),
    validFrom: values.get('validFrom'),
    validTo: values.get('validTo'),
    airlineIata: values.get('airlineIata'),
    flightNo: values.get('flightNo'),
    itinerary: values.get('itinerary'),
    legNo: values.get('legNo'),
    weekdays: values.get('weekdays'),
    departureAirportIata: values.get('departureIata'),
    departureTime: values.get('departureTime'),
    arrivalAirportIata: values.get('arrivalIata'),
    arrivalTime: values.get('arrivalTime')
  }
})

export const addFlightSchedule = (values: FlightScheduleEntity) => {
  return function (dispatch: ThunkDispatch) {
    return dispatch(
      asyncAirExpressCommand(ADD_LOCAL_FLIGHT_SCHEDULE, {
        type: 'ADD_LOCAL_FLIGHT_SCHEDULE',
        payload: createFlightSchedulePayload(values)
      })
    ).then(
      (res) =>
        res.ok ? dispatch(closeModalAndNotify(i18next.t('airexpress.locallyAddedFlightSchedules.created'))) : res,
      (err) => console.log('addFlightSchedule', err)
    )
  }
}

export const updateFlightSchedule = (values: FlightScheduleEntity) => {
  return function (dispatch: ThunkDispatch) {
    return dispatch(
      asyncAirExpressCommand(UPDATE_LOCAL_FLIGHT_SCHEDULE, {
        type: 'UPDATE_LOCAL_FLIGHT_SCHEDULE',
        payload: createFlightSchedulePayload(values)
      })
    ).then(
      (res) =>
        res.ok ? dispatch(closeModalAndNotify(i18next.t('airexpress.locallyAddedFlightSchedules.updated'))) : res,
      (err) => console.log('updateFlightSchedule', err)
    )
  }
}

export const deleteFlightSchedule = (id: number) => {
  return function (dispatch: ThunkDispatch) {
    return dispatch(
      asyncAirExpressCommand(DELETE_LOCAL_FLIGHT_SCHEDULE, {
        type: 'DELETE_LOCAL_FLIGHT_SCHEDULE',
        payload: { id }
      })
    ).then(
      (res) =>
        res.ok
          ? dispatch(
              addNotification({
                title: i18next.t('airexpress.locallyAddedFlightSchedules.deleted'),
                message: '',
                timeout: 5000
              })
            )
          : res,
      (err) => console.log('deleteFlightSchedule', err)
    )
  }
}

export const searchScheduledFlights =
  (departureIata?: string, arrivalIata?: string, fromDateTime?: string) => (dispatch: ThunkDispatch) => {
    return dispatch(
      airExpressApi.asyncGet(
        `/internal/flight-schedules/${departureIata}/${arrivalIata}/${fromDateTime}`,
        SEARCH_SCHEDULED_FLIGHTS
      )
    )
  }

export const searchLiveFlights = (departureIata?: string, fromDateTime?: string) => (dispatch: ThunkDispatch) => {
  return dispatch(
    airExpressApi.asyncGet(`/internal/flight-departures/${departureIata}/${fromDateTime}`, SEARCH_LIVE_FLIGHTS)
  )
}

export const findMasterAWBs = (uniqueFlightRouteIds: string[]) => (dispatch: ThunkDispatch) =>
  dispatch(airExpressApi.asyncQuery(FIND_MASTER_AWBS, { query: 'findMasterAWBs', params: { uniqueFlightRouteIds } }))

export const getAirlineTerminalsIata = (
  airlineIata: AirlineIATA,
  departureAirportIata: AirportIATA,
  arrivalAirportIata?: AirportIATA
) =>
  fetchEvenIfPresent(GET_AIRLINE_TERMINALS, {
    query: 'airlineTerminals',
    params: {
      airlineIata,
      airportIatas: arrivalAirportIata ? [departureAirportIata, arrivalAirportIata] : [departureAirportIata]
    }
  })

export const createOrUpdateAirlineTerminal = (airlineTerminal: AirlineTerminal) => (dispatch: ThunkDispatch) =>
  dispatch(
    asyncAirExpressCommand(CREATE_OR_UPDATE_AIRLINE_TERMINAL, {
      type: 'CREATE_OR_UPDATE_AIRLINE_TERMINAL',
      payload: { airlineTerminal }
    })
  ).then(
    (res) => {
      if (res.ok) {
        return res
      } else {
        console.log('Preadvice save failed, reason=', res)
        throw res
      }
    },
    (err) => {
      console.log('Network error in Preadvice save:', err)
      throw err
    }
  )

export const getFlightScheduleUploads = (dispatch: ThunkDispatch) =>
  dispatch(
    airExpressApi.asyncQuery(GET_FLIGHT_SCHEDULE_UPLOADS, {
      query: 'flightScheduleUploads',
      params: {}
    })
  ).then((response) => dispatch(setPageStateValue('flightScheduleUploads', fromJS(response.readBody))))

export const getAirExpressOrdersByShipmentIds = (shipmentIds: List<string>) =>
  fetchEvenIfPresent(GET_ORDERS_BY_SHIPMENT_IDS, { query: 'orders', params: { shipmentIds } })

export const createAirExpressHandlerUser = (values: Omit<HandlerUserFormValues, 'id'>) => {
  return function (dispatch: ThunkDispatch) {
    createAirExpressHandlerUserJS(values)(dispatch).then(
      (res) => (res.ok ? dispatch(closeModalAndNotify(i18next.t('airexpress.handlerUser.created'))) : res),
      (err) => console.log('createHandlerUser', err)
    )
  }
}

export const updateAirExpressHandlerUser = (values: HandlerUserFormValues) => {
  return function (dispatch: ThunkDispatch) {
    return updateAirExpressHandlerUserJS(values)(dispatch).then(
      (res) => (res.ok ? dispatch(closeModalAndNotify(i18next.t('airexpress.handlerUser.updated'))) : res),
      (err) => console.log('updateHandleUser', err)
    )
  }
}
export const createAirExpressHandlerUserJS = (values: Omit<HandlerUserFormValues, 'id'>) => {
  return function (dispatch: ThunkDispatch) {
    return dispatch(
      asyncAirExpressCommand(CREATE_HANDLER_USER, {
        type: 'CREATE_HANDLER_USER',
        payload: {
          name: values.name,
          phoneNumber: values.phoneNumber,
          airlineTerminalIds: values.airlineTerminals,
          departments: values.departments,
          canLogIn: values.canLogIn,
          email: values.email,
          roleType: 'handler'
        }
      })
    )
  }
}

export const updateAirExpressHandlerUserJS = (values: HandlerUserFormValues) => {
  return function (dispatch: ThunkDispatch) {
    return dispatch(
      asyncAirExpressCommand(UPDATE_HANDLER_USER, {
        type: 'UPDATE_HANDLER_USER',
        payload: {
          id: values.id,
          planner: values.id,
          name: values.name,
          phoneNumber: values.phoneNumber,
          airlineTerminalIds: values.airlineTerminals,
          departments: values.departments,
          canLogIn: values.canLogIn,
          email: values.email,
          roleType: 'handler'
        }
      })
    )
  }
}

export const createAWBLabel = (
  awbNo: number,
  onSuccess: (res: ConsumedResponse) => void,
  onError: (res: string) => void = () => {}
) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(
      airExpressApi.asyncQuery(CREATE_AWB_LABEL, {
        query: 'createAWBLabel',
        params: {
          awbNo: awbNo
        }
      })
    ).then(
      (res) => onSuccess(res),
      (err) => onError(err)
    )
  }
}

export const getUsersAirExpressWithRoleHandler = () =>
  fetchEvenIfPresent(GET_HANDLERS, { query: 'users', params: { role: 'handler' } })

export const getUsersAirExpressWithRoleCustomsAgent = () => {
  return fetchEvenIfPresent(GET_CUSTOMS_AGENTS, { query: 'users', params: { role: 'customs_agent' } })
}

export const deleteAirExpressOrders =
  (orderIds: Set<number>, onSuccessRedirectUrl: string, push: (url: string) => void) => (dispatch: ThunkDispatch) =>
    dispatch(
      asyncAirExpressCommand(DELETE_AIREXPRESS_ORDERS, {
        type: 'DELETE_ORDERS',
        payload: { orderIds: orderIds }
      })
    ).then(
      () => push(onSuccessRedirectUrl),
      (err) => console.log(err)
    )

export const getOrderPriceFromPriceMotor = (orderData: AnyData) => (dispatch: ThunkDispatch) =>
  dispatch(
    airExpressApi.asyncQuery(GET_ORDER_PRICE_DATA, {
      query: 'orderPrice',
      params: { orderData }
    })
  )

export const getAirExpressRecurringSchedules = (departmentId: number) =>
  fetchEvenIfPresent(GET_AIR_EXPRESS_RECURRING_SCHEDULES, { query: 'recurringSchedules', params: { departmentId } })

export const getAirExpressRecurringTemplatesForSchedule = (recurringScheduleId: number, departmentId: number) =>
  fetchEvenIfPresent(GET_AIR_EXPRESS_RECURRING_TEMPLATES_FOR_SCHEDULE, {
    query: 'recurringTemplatesForSchedule',
    params: { departmentId, recurringScheduleId }
  })

export const getAirExpressRecurringTemplatesForDate = (departmentId: DepartmentIdType) =>
  fetchEvenIfPresent(GET_AIR_EXPRESS_RECURRING_TEMPLATES_FOR_DATE, {
    query: 'recurringTemplatesForDate',
    params: { validDate: DateTime.now().toFormat(TIMESTAMP_DATE_FORMAT), departmentId }
  })

export const createAirExpressRecurringSchedule = (values: RecurringAirExpressSchedule) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(
      asyncAirExpressCommand(CREATE_AIR_EXPRESS_RECURRING_SCHEDULE, {
        type: 'CREATE_AIR_EXPRESS_RECURRING_SCHEDULE',
        payload: values
      })
    ).then(
      (res) =>
        res.ok
          ? dispatch(
              addNotification({
                title: 'Recurring schedule saved',
                message: '',
                timeout: 3000
              })
            )
          : res,
      (err) => console.log('createAirExpressRecurringSchedule', err)
    )
  }
}

export const editAirExpressRecurringSchedule = (values: RecurringAirExpressSchedule) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(
      asyncAirExpressCommand(EDIT_RECURRING_SCHEDULE, {
        type: 'EDIT_RECURRING_SCHEDULE',
        payload: values
      })
    ).then(
      (res) =>
        res.ok
          ? dispatch(
              addNotification({
                title: 'Recurring schedule updated',
                message: '',
                timeout: 3000
              })
            )
          : res,
      (err) => console.log('editirExpressRecurringSchedule', err)
    )
  }
}

export const createAirExpressRecurringTemplate = (orderIds: List<OrderIdType>, recurringScheduleId: number) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(
      asyncAirExpressCommand(CREATE_AIR_EXPRESS_RECURRING_TEMPLATE, {
        type: 'CREATE_AIR_EXPRESS_RECURRING_TEMPLATE',
        payload: {
          orderIds: orderIds.toJS(),
          recurringScheduleId: recurringScheduleId
        }
      })
    )
  }
}

export const deleteAirExpressRecurringSchedule = (id: number) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(
      asyncAirExpressCommand(DELETE_RECURRING_SCHEDULE, {
        type: 'DELETE_RECURRING_SCHEDULE',
        payload: { id }
      })
    ).then(
      (res) =>
        res.ok
          ? dispatch(
              addNotification({
                title: 'Recurring schedule deleted',
                message: '',
                timeout: 3000
              })
            )
          : res,
      (err) => console.log('deleteAirExpressRecurringSchedule', err)
    )
  }
}

export const deleteAirExpressRecurringOrderFromSchedule = (id: number) => {
  return function (dispatch: ThunkDispatch) {
    dispatch(
      asyncAirExpressCommand(DELETE_RECURRING_TEMPLATE_FROM_SCHEDULE, {
        type: 'DELETE_RECURRING_TEMPLATE_FROM_SCHEDULE',
        payload: { id }
      })
    ).then(
      (res) =>
        res.ok
          ? dispatch(
              addNotification({
                title: 'Recurring template deleted from schedule',
                message: '',
                timeout: 3000
              })
            )
          : res,
      (err) => console.log('deleteAirExpressRecurringOrderFromSchedule', err)
    )
  }
}
