import { Map } from 'immutable'
import { OrderIdType, PreAdviceStatus } from '../types/coreEntitiesTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'

export const preAdviceStatusForOrderSelector: (state: AppStateType, orderId: OrderIdType) => PreAdviceStatus | null =
  createImmutableEqualSelector(
    (state: AppStateType) =>
      (state.getIn(['entities', 'consignmentPreAdvices']) as Map<number, PreAdviceStatus>) || Map(),
    (state: AppStateType, orderId: OrderIdType) => orderId,
    (preAdvices: Map<number, PreAdviceStatus>, orderId: OrderIdType) =>
      preAdvices.find((pas) => pas.get('orderId') === orderId) || null
  )
