import { Map } from 'immutable'
import { ImmutableMap } from './immutableTypes'
import { FlightSchedule } from '../domain/airexpress/flightSchedule'
import { Airport, AirportIdType } from '../domain/airexpress/airport'
import { Airline, AirlineIdType } from '../domain/airexpress/airline'
import { AirlineTerminal, AirlineTerminalExtraInfo } from '../domain/airexpress/airlineTerminal'
import { IdType } from '../domain/airexpress/common'
import { IBaseConsignmentEvent } from './coreEntitiesTypes'
import { AirExpressConsignmentManualOverrideEventTypes } from './airexpressConsignmentEventTypes'

export interface FlightScheduleEntity extends ImmutableMap<FlightSchedule> {}

export interface AirlineEntity extends ImmutableMap<Airline> {}

export interface AirportEntity extends ImmutableMap<Airport> {}

export interface AirlineTerminalEntity extends ImmutableMap<AirlineTerminal> {}

export interface AirlineTerminalWithExtraInfo extends ImmutableMap<AirlineTerminal & AirlineTerminalExtraInfo> {}

export type AirlineEntities = Map<AirlineIdType, AirlineEntity>
export type AirportEntities = Map<AirportIdType, AirportEntity>
export type AirlineTerminalEntities = Map<IdType, AirlineTerminalEntity>
export type AirlineTerminalWithAirlineAndAirportEntities = Map<IdType, AirlineTerminalWithExtraInfo>

export const toAirport = (entity: AirportEntity): Airport => entity.toJS() as Airport
export const toAirline = (entity: AirlineEntity): Airline => entity.toJS() as Airline

export interface IAirExpressConsignmentEvent extends IBaseConsignmentEvent {
  type: AirExpressConsignmentManualOverrideEventTypes
  manifestId: number
}

export interface AirExpressConsignmentEvent extends ImmutableMap<IAirExpressConsignmentEvent> {}
