import i18next from 'i18next'
import { List, Map, Set } from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
import { closeModal, getOrderDeviationsByOrderId, openModal } from '../../actions/creators/helpers'
import { getMessageContainer } from '../../components/Message'
import ShipmentModal from '../../components/ShipmentModal'
import { GridConsignmentEvent } from '../../primitives/ConsignmentDrawer'
import { H2 } from '../../primitives/Headings'
import ImmutableComponent from '../../primitives/ImmutableComponent'
import { consignmentEventsToMapOfConsignmentIdsToEventMap } from '../../selectors/consignmentSelectors'
import { createImmutableEqualSelector } from '../../selectors/createImmutableEqualSelector'
import { shipmentByIdSelector } from '../../selectors/shipmentSelectors'
import variables from '../../styles/variables'
import {
  Consignment,
  ConsignmentEvent,
  ConsignmentIdType,
  Unit,
  UnitIdType,
  OrderIdType,
  Shipment
} from '../../types/coreEntitiesTypes'
import { AppStateType } from '../../utils/appStateReduxStore'
import { isEmpty } from '../../utils/collectionUtils'
import { hourMinute } from '../../utils/dateTime'
import { formatWeightDimensionsForPackages } from '../../utils/stringUtils'
import { customerNumberAndSubcustomer } from './bookingOrderFunctions'
import { preplanConsignmentsToCourier } from '../../actions/creators/helpersInstant'

import {
  consignmentContainsDeviationInEventsSelector,
  customerOrderDeviationsIdSelector,
  getEventWithCustomerUniqueDeviationSelector
} from '../../selectors/customerOrderDeviationsSelector'
import { DeviationOrderPropsType } from '../admin/orderDeviationTypes'
import { getOrderById } from '../../actions/creators/queryHelpers'
import { ShipmentSummary } from './ShipmentSummary'
import { filterConsignmentEvents } from '../../utils/shipmentsUtils'

class InstantShipmentDetails extends ImmutableComponent<Props> {
  componentDidMount() {
    const orderId = this.props.shipment.get('orderId')
    orderId && this.props.getOrderById(orderId)
    this.props.containsDeviationInEvents && orderId && this.props.getOrderDeviationsByOrderId(orderId)
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const orderId = this.props.shipment.get('orderId')
    const prevOrderId = prevProps.shipment.get('orderId')
    if (!!orderId && prevOrderId !== orderId) this.props.getOrderById(orderId)
  }

  viewShipmentDetails(shipmentId: number) {
    // @ts-ignore
    this.props.openModal(ShipmentModal, {
      shipmentId,
      includeOverlay: false,
      closeModal: () => {
        this.props.closeModal(true)
      }
    })
  }

  preplan = (courierId: any, consignments: Set<number>) => {
    this.props.preplanConsignmentsToCourier(courierId, consignments)
  }

  render() {
    const { shipment, events, courier, customerOrderDeviations } = this.props
    if (isEmpty(shipment)) {
      return <H2 textAlign>{i18next.t('instant.noOrderSelected')}</H2>
    }

    return (
      <div style={{ padding: '15px' }}>
        <ShipmentSummary shipment={shipment} courier={courier} />
        <div style={{ borderBottom: `1px solid ${variables.colors.gray4}`, padding: '5px' }}>
          <strong>{i18next.t('consignment.customer')}:</strong>
          <div style={{ marginBottom: '0.5rem' }}>
            {customerNumberAndSubcustomer(shipment.get('custAlystraId'), shipment.get('custAlystraSubcustomer'))}{' '}
            {shipment.get('custName', '')}{' '}
          </div>
        </div>
        <div style={{ borderBottom: `1px solid ${variables.colors.gray4}`, padding: '5px' }}>
          <strong>{i18next.t('consignment.pickup')}:</strong>
          <div style={{ marginBottom: '0.5rem' }}>
            {hourMinute(shipment.get('pickupTimeEarliest'))}
            {' - '}
            {hourMinute(shipment.get('pickupTimeLatest'))} {shipment.get('pickupName')} {shipment.get('pickupAddress')}{' '}
            {shipment.get('pickupZipCode')} {shipment.get('pickupZipArea')}
          </div>
        </div>
        <div style={{ borderBottom: `1px solid ${variables.colors.gray4}`, padding: '5px' }}>
          <strong>{i18next.t('consignment.delivery')}:</strong>
          <div>
            {hourMinute(shipment.get('deliveryTimeEarliest'))}
            {' - '}
            {hourMinute(shipment.get('deliveryTimeLatest'))} {shipment.get('deliveryName')}{' '}
            {shipment.get('deliveryAddress')} {shipment.get('deliveryZipCode')} {shipment.get('deliveryZipArea')}
          </div>
        </div>
        <div style={{ borderBottom: `1px solid ${variables.colors.gray4}`, padding: '5px' }}>
          <strong>{i18next.t('consignment.pickupInstructions')}:</strong>
          <div>{shipment.get('pickupInstructions') || '-'}</div>
        </div>
        <div style={{ borderBottom: `1px solid ${variables.colors.gray4}`, padding: '5px' }}>
          <strong>{i18next.t('consignment.deliveryInstructions')}:</strong>
          <div>{shipment.get('deliveryInstructions') || '-'}</div>
        </div>
        <div style={{ borderBottom: `1px solid ${variables.colors.gray4}`, padding: '5px' }}>
          <strong>{i18next.t('consignment.specificationDescription')}:</strong>
          <div>{shipment.get('specificationDescription') || '-'}</div>
        </div>
        <div style={{ borderBottom: `1px solid ${variables.colors.gray4}`, padding: '5px' }}>
          <strong>{i18next.t('consignment.vas')}:</strong>
          <div>
            {(shipment.get('vasDetails') || List())
              .filter((vas) => vas.get('pureVas'))
              .map(
                (vas) => vas.get('vasCode') + (parseInt(vas.get('quantity'), 10) > 1 ? ` (${vas.get('quantity')})` : '')
              )
              .join(', ') || '-'}
          </div>
        </div>
        <div style={{ padding: '5px' }}>
          {shipment.get('consignments').map((consignment: Consignment, index: number) => {
            const consignmentEvents = events.get(consignment.get('id'))
            if (!consignmentEvents) return null
            const filteredConsignmentEvents = filterConsignmentEvents(consignmentEvents, shipment.get('type'))
            return (
              <div key={`ce_${index}`} style={{ display: 'grid', gridGap: '5px' }}>
                <strong>
                  {i18next.t('consignment.packageId')}:{' '}
                  {formatWeightDimensionsForPackages(
                    consignment.get('packageId'),
                    consignment.get('weightValue'),
                    consignment.get('lengthValue'),
                    consignment.get('widthValue'),
                    consignment.get('heightValue')
                  )}
                </strong>
                {filteredConsignmentEvents &&
                  filteredConsignmentEvents
                    .sortBy((c: ConsignmentEvent) => c.get('createdAt'))
                    .reverse()
                    .map((event, idx: number) => {
                      const MessageContainer: React.FC<any> = getMessageContainer(event.get('type'))
                      const eventWithCustomerDeviation = getEventWithCustomerUniqueDeviationSelector(
                        event,
                        customerOrderDeviations
                      )
                      return (
                        <GridConsignmentEvent key={idx}>
                          <MessageContainer {...eventWithCustomerDeviation.toObject()} data={event.get('data')} />
                        </GridConsignmentEvent>
                      )
                    })}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export const consignmentEventsForShipmentIdByShipmentId: (
  state: AppStateType,
  shipmentId: number
) => Map<ConsignmentIdType, List<ConsignmentEvent>> = createImmutableEqualSelector(
  consignmentEventsToMapOfConsignmentIdsToEventMap,
  shipmentByIdSelector,
  (events, shipment) => {
    if (!shipment) {
      return Map<ConsignmentIdType, List<ConsignmentEvent>>()
    }
    return shipment.get('consignmentIds', Set()).reduce((res, curr: number) => {
      const e = events.get(curr)
      if (e !== undefined) return res.set(curr, e.valueSeq().toList())
      return res
    }, Map<ConsignmentIdType, List<ConsignmentEvent>>())
  }
)

interface StateProps {
  shipment: Shipment
  events: Map<number, List<ConsignmentEvent>>
  containsDeviationInEvents: boolean
  customerOrderDeviations: Map<number, DeviationOrderPropsType>
}

interface DispatchProps {
  openModal: (modalClass: typeof ImmutableComponent, props: any) => void
  closeModal: (keepState: boolean) => void
  preplanConsignmentsToCourier: (courierId: UnitIdType, consignments: Set<number>) => void
  getOrderDeviationsByOrderId: (orderId: OrderIdType) => void
  getOrderById: (id: OrderIdType) => void
}

interface OwnProps {
  courier: Unit | undefined
  shipmentToShow: number
}

type Props = StateProps & DispatchProps & OwnProps

export default connect<StateProps, DispatchProps, OwnProps, AppStateType>(
  (state): StateProps => {
    const shipmentId = state.getIn(['pageState', 'shipmentToShow'])
    const shipment = shipmentByIdSelector(state, shipmentId)
    const events = consignmentEventsForShipmentIdByShipmentId(state, shipmentId)
    const containsDeviationInEvents = consignmentContainsDeviationInEventsSelector(events)
    const customerOrderDeviations =
      (containsDeviationInEvents && customerOrderDeviationsIdSelector(state, shipment.get('customerId'))) ||
      Map<number, DeviationOrderPropsType>()
    return {
      shipment,
      events,
      containsDeviationInEvents,
      customerOrderDeviations
    }
  },
  {
    openModal,
    closeModal,
    preplanConsignmentsToCourier,
    getOrderDeviationsByOrderId,
    getOrderById
  }
)(InstantShipmentDetails)
