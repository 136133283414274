import { AnyData } from '../../../../actions/creators/baseHelpers'
import { EventMap } from './event-map'
import React from 'react'
import { eventDataValue } from '@glow/entity-types'
import i18next from 'i18next'

export const SignatureSmsSent = (props: { data: AnyData }): React.ReactElement => {
  const phoneNumber = eventDataValue(props.data, ['phoneNumber'])

  return (
    <>
      {phoneNumber && (
        <div>
          <p className="text-sm text-emphasis-medium mb-1">
            {i18next.t('consignmentEvent.message.signature-sms-sent')}
          </p>
          <p className="text-emphasis-high">{phoneNumber}</p>
        </div>
      )}
      <EventMap data={props.data} />
    </>
  )
}
