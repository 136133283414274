import { isBringOrPosten } from '@glow/common'

export const slotStates = {
  CREATED: 0,
  INITIALIZED: 1,
  ASSIGNED: 2,
  READY: 3,
  LOCKED: 4,
  IN_PROGRESS: 5,
  ON_HOLD: 6,
  COMPLETE: 7
}

export const allowManualRouting = () => isBringOrPosten()
