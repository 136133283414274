import React from 'react'
import ImmutableComponent from '../primitives/ImmutableComponent'
import Autosuggest from 'react-autosuggest'
import { connect } from 'react-redux'
import { AUTOSUGGEST_SUBCUSTOMERS } from '../actions/actionTypes'
import { InputForLabel } from '../primitives/Forms'
import { SuggestionsContainer } from '../primitives/AutoSuggest'
import { LabeledInput } from '../primitives/LabeledInput'
import { autoSuggestTheme, renderSuggestion } from './AutoSuggestWrapper'
import { Map } from 'immutable'
import { filterSubcustomers } from '../pages/instant/bookingOrderFunctions'
import { AppStateType } from '../utils/appStateReduxStore'

const isSelecting = (method: string) => method === 'click' || method === 'enter'

class AutoSuggestWrapperForSubcustomer extends ImmutableComponent<Props> {
  renderInputComponent = (inputProps: any) => {
    return (
      <LabeledInput label={this.props.placeholder ?? ''} htmlFor={inputProps.id}>
        <InputForLabel {...inputProps} />
      </LabeledInput>
    )
  }

  renderSuggestionsContainer = ({ containerProps, children }: any) => (
    <SuggestionsContainer show={!!children} {...containerProps}>
      {children}
    </SuggestionsContainer>
  )

  renderSuggestion = (suggestion: { alystraSubcustomer: any }, { query, isHighlighted }: any) => {
    const label = suggestion.alystraSubcustomer
    return renderSuggestion(label, query, isHighlighted)
  }

  search = (value: string | null) => {
    this.props.setPageStateValue('subCustomerSearchPhrase', value)
  }

  onInputChanged = (event: React.FormEvent<HTMLElement>, value: any, method: string) => {
    if (isSelecting(method)) {
      event.preventDefault()
      this.props.onChange?.(value.alystraSubcustomer)
      this.props.onSelect?.(Map(value))
    } else {
      this.props.onChange?.(event)
    }
  }

  render() {
    const { id, value, subcustomers, getSuggestionValue = (v: any) => v } = this.props

    return (
      <Autosuggest
        suggestions={subcustomers}
        onSuggestionsFetchRequested={({ value }) => this.search(value)}
        onSuggestionsClearRequested={() => this.search(null)}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderInputComponent={this.renderInputComponent}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        highlightFirstSuggestion={true}
        focusInputOnSuggestionClick={false}
        shouldRenderSuggestions={() => true} // Always show suggestions
        inputProps={{
          id: id,
          autoComplete: 'nope',
          value: value,
          onChange: (event, { newValue, method }) => this.onInputChanged(event, newValue, method)
        }}
        theme={autoSuggestTheme}
      />
    )
  }
}
interface Props {
  subCustomersForSelectedAlystraId: Map<string, any>
  id: string
  placeholder?: string
  setPageStateValue: (key: string, value: string | null) => void
  onChange?: (event: any) => void
  onSelect?: (event: any) => void
  value: string
  subcustomers: any
  getSuggestionValue: any
}
export default connect((state: AppStateType, ownProps: Props) => {
  const phrase = state.getIn(['pageState', 'subCustomerSearchPhrase'])
  return {
    subcustomers: filterSubcustomers(ownProps.subCustomersForSelectedAlystraId, phrase),
    id: AUTOSUGGEST_SUBCUSTOMERS + ownProps.id,
    setPageStateValue: ownProps.setPageStateValue
  }
}, {})(AutoSuggestWrapperForSubcustomer)
