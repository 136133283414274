import { Set } from 'immutable'
import { Shipment } from '../types/coreEntitiesTypes'

export const returnServiceCodes = Set(['2778', '3577', '3578', '4205'])
export const curbSideServiceCodes = Set(['3123', '3237', '3238', '3457'])

export const oversizedVasCodes = Set(['1138', '1140'])
export const installationVasCodes = Set(['1124', '1125', '1127', '1128', '1141'])

export const isReturnService = (serviceCode: string): boolean => {
  return returnServiceCodes.contains(serviceCode)
}

export const isShipmentReturn = (shipment: Shipment): boolean =>
  shipment ? isReturnService(shipment.get('serviceCode')) : false
