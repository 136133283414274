import { ButtonBase, FormCheckbox, IconFa, Text } from '@glow/ui-components'
import i18next from 'i18next'
import { List, Map } from 'immutable'
import React, { useMemo, useState } from 'react'
import { Control } from 'react-redux-form'
import { IOptimusService } from '../../../domain/optimusService'
import { Input } from '../../../primitives/Forms'
import variables from '../../../styles/variables'
import { ImmutableMap } from '../../../types/immutableTypes'
import { CorrectedArticlePrice, IPricedArticle } from '../bookingOrderTypes'
import { isProduction } from '@glow/common'

type PriceRole = 'customerPrice' | 'resourcePrice'
const PRICE_ROLES: PriceRole[] = ['customerPrice', 'resourcePrice']

interface IPriceFormValuesProps {
  correctedArticlePrices?: List<CorrectedArticlePrice>
}
interface IPriceFormValues extends ImmutableMap<IPriceFormValuesProps> {}

interface IPriceFormProps {
  corrections: List<CorrectedArticlePrice>
  editablePrices: Map<string, boolean>
  formValues: IPriceFormValues
  pricedArticles: List<IPricedArticle> | undefined
  setEditablePrices: (value: React.SetStateAction<Map<string, boolean>>) => void
  showManualColumn: boolean
  mergeForm: (values: Map<string, any>) => void
  enableEdit: boolean
}

export interface IPriceFormServicesProps {
  customizedServices: List<IOptimusService>
  services: List<IOptimusService>
  valueAddedServices?: List<IOptimusService>
  needsZeroPriceApproval?: boolean
  hasZeroPriceApproval?: boolean
}

interface OrderPricesProps extends IPriceFormProps, IPriceFormServicesProps {
  togglePriceExplanations: (force?: unknown) => void
}

interface PricesProps extends IPriceFormProps, IPriceFormServicesProps {
  priceRole: PriceRole
}

interface PriceRowProps extends IPriceFormProps {
  cap: CorrectedArticlePrice
  index: number
  priceRole: PriceRole
}

export const OrderPrices = (props: OrderPricesProps) => {
  const [approveIncompletePrice, setApproveIncompletePrice] = useState(props.hasZeroPriceApproval)
  return (
    <div className="bg-gray-100 p-6">
      <Text as="h5" textType="lg">
        {i18next.t('instant.booking.priceDetails')}
        <div className="inline-block" onClick={props.togglePriceExplanations}>
          {<IconFa className="ml-2" icon={['far', 'circle-info']} color={variables.emphasis.medium} cursor="help" />}
        </div>
      </Text>

      {PRICE_ROLES.map((priceRole) => (
        <Prices
          key={priceRole}
          priceRole={priceRole}
          showManualColumn={props.showManualColumn}
          pricedArticles={props.pricedArticles}
          services={props.services}
          customizedServices={props.customizedServices}
          valueAddedServices={props.valueAddedServices}
          corrections={props.corrections}
          editablePrices={props.editablePrices}
          formValues={props.formValues}
          setEditablePrices={props.setEditablePrices}
          mergeForm={props.mergeForm}
          enableEdit={props.enableEdit}
        />
      ))}
      {!isProduction() && (
        <>
          {(props.needsZeroPriceApproval || props.hasZeroPriceApproval) && (
            <>
              <FormCheckbox
                label={i18next.t('instant.booking.approveIncompletePrice')}
                onChange={(event) => {
                  setApproveIncompletePrice(() => event.currentTarget.checked)
                  props.mergeForm(
                    Map({
                      allowIncompletePrice: event.currentTarget.checked
                    })
                  )
                  return true
                }}
                defaultChecked={approveIncompletePrice}
                disabled={!props.enableEdit}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

const Prices = (props: PricesProps) => {
  const sortedArticles = useMemo(
    () =>
      props.pricedArticles?.sortBy((a) =>
        canonicalSortOrder(
          props.services,
          props.customizedServices,
          props.valueAddedServices ?? List(),
          a.get('articleCode')
        )
      ),
    [props.pricedArticles, props.services, props.customizedServices, props.valueAddedServices]
  )

  const total = useMemo(
    () =>
      Number(
        props.pricedArticles
          ?.map((pa) => {
            return (
              props.formValues
                .get('correctedArticlePrices')
                ?.find((cap) => pa.get('articleCode') === cap.get('articleCode'))
                ?.get(props.priceRole) ?? pa.get(props.priceRole)
            )
          })
          ?.map((p) => Number(p))
          ?.reduce((a, b) => a + b, 0) || 0.0
      ),
    [props.pricedArticles, props.priceRole, props.formValues]
  )

  return (
    <>
      <div className="flex">
        <div className="basis-7/12 grow">
          <Text as="h5" textType="base">
            {i18next.t(`instant.booking.${props.priceRole}`)}
          </Text>
        </div>
        <div className="basis-2/12 grow text-right">
          <Text as="h5" textType="xs">
            {i18next.t('instant.booking.priceList')}
          </Text>
        </div>
        {props.showManualColumn && (
          <div className="basis-2/12 grow text-right">
            <Text as="h5" textType="xs">
              {i18next.t('instant.booking.manualPrice')}
            </Text>
          </div>
        )}
        {props.enableEdit && <div className="basis-1/12"></div>}
      </div>

      {sortedArticles?.map((cap, index) => (
        <PriceRow
          key={index}
          cap={cap}
          index={index}
          corrections={props.corrections}
          priceRole={props.priceRole}
          editablePrices={props.editablePrices}
          pricedArticles={props.pricedArticles}
          showManualColumn={props.showManualColumn}
          formValues={props.formValues}
          setEditablePrices={props.setEditablePrices}
          mergeForm={props.mergeForm}
          enableEdit={props.enableEdit}
        />
      ))}
      <div className="flex mb-4">
        <div className="basis-11/12 grow text-right">
          <Text as="span" textType="lg">
            {i18next.t('instant.booking.total')}: {priceFormatter(total)}
          </Text>
        </div>
        {props.enableEdit && <div className="basis-1/12"></div>}
      </div>
    </>
  )
}

const PriceRow = (props: PriceRowProps) => {
  const editable = useMemo(
    () => props.editablePrices.get(`${props.priceRole}_${props.cap.get('articleCode')}`),
    [props.editablePrices]
  )

  const articleLabel = useMemo(
    () =>
      props.pricedArticles?.find((pa) => pa.get('articleCode') === props.cap?.get('articleCode'))?.get('articleLabel'),
    [props.pricedArticles, props.cap]
  )

  const priceListPrice = useMemo(
    () =>
      props.pricedArticles?.find((pa) => pa.get('articleCode') === props.cap?.get('articleCode'))?.get(props.priceRole),
    [props.pricedArticles, props.cap]
  )

  const manualPrice = useMemo(
    () => props.corrections.get(props.index)?.get(props.priceRole),
    [props.corrections, props.index, props.priceRole]
  )
  const corrected = useMemo(
    () => props.formValues?.get('correctedArticlePrices')?.get(props.index),
    [props.formValues, props.index]
  )
  const overridden = useMemo(() => isOverridden(priceListPrice, manualPrice), [manualPrice, priceListPrice])

  const finishManualPrice = () =>
    props.setEditablePrices((prev) => prev.set(`${props.priceRole}_${props.cap.get('articleCode')}`, false))

  return (
    <div className="flex items-center" key={props.index}>
      <div className="basis-7/12 grow">
        <Text className="text-black" textType="base" as="span">
          {articleLabel} ({props.cap.get('articleCode')})
        </Text>
      </div>
      <div className="basis-2/12 grow text-right">
        <Text as="span" textType="base" className={`${overridden ? 'line-through' : 'text-black'}`}>
          {priceFormatter(priceListPrice)}
        </Text>
      </div>

      {props.showManualColumn && (
        <div className="basis-2/12 grow text-right">
          {editable ? (
            <Control.text
              className="!p-2 text-right max-w-14 !float-right"
              autoComplete="nope"
              model={`.correctedArticlePrices[${props.index}].${props.priceRole}`}
              id={`corrC${props.index}`}
              component={Input}
              controlProps={{
                preventSubmitOnEnter: true,
                onKeyDown: (e: KeyboardEvent) => {
                  if (e.key === 'Enter' || e.key === 'Escape') {
                    finishManualPrice()
                  }
                }
              }}
              parser={fixFormat}
            />
          ) : (
            <Text as="div" textType="base" className="text-black">
              {corrected?.get(props.priceRole) && priceFormatter(manualPrice)}
            </Text>
          )}
        </div>
      )}
      {editable ? (
        <div className="basis-1/12">
          <ButtonBase variant="tertiary" type="button" onClick={finishManualPrice}>
            <IconFa icon="check" />
          </ButtonBase>
        </div>
      ) : props.enableEdit ? (
        <div className="basis-1/12 text-right">
          <div className="flex flex-nowrap max-w-8">
            <ButtonBase
              className="w-1/2  m-0"
              variant="tertiary"
              type="button"
              onClick={() =>
                props.setEditablePrices((prev) => prev.set(`${props.priceRole}_${props.cap.get('articleCode')}`, true))
              }
            >
              <IconFa icon="pencil" color={variables.emphasis.medium} />
            </ButtonBase>

            {overridden && !editable && props.showManualColumn && props.enableEdit && (
              <ButtonBase
                className="w-1/2 pl-1"
                variant="tertiary"
                type="button"
                onClick={() => {
                  !isNaN(Number(priceListPrice)) &&
                    corrected &&
                    props.mergeForm(
                      Map({
                        correctedArticlePrices: props.formValues.get('correctedArticlePrices')?.map((cap, capIndex) => {
                          return capIndex === props.index ? cap.remove(props.priceRole) : cap
                        })
                      })
                    )
                }}
              >
                <IconFa icon={['far', 'arrow-rotate-backward']} color={variables.emphasis.medium} />
              </ButtonBase>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export const priceFormatter = (value: any) => {
  return Number(value ? Number(value.toString().replace(',', '.')).toFixed(2) : 0.0)
    .toFixed(2)
    .toLocaleString()
}

export const fixFormat = (value: any) => {
  if (typeof value === 'string') {
    return value.replace(',', '.').replace(/[^0-9\\.]/, '')
  } else return value
}

export const canonicalSortOrder = (
  services: List<IOptimusService>,
  customizedServices: List<IOptimusService>,
  valueAddedServices: List<IOptimusService>,
  articleCode: string
) => {
  if (
    services.find((s) => s.get('serviceCode') === articleCode) ||
    customizedServices.find((s) => s.get('serviceCode') === articleCode)
  )
    return `A${articleCode}`
  if (valueAddedServices.find((s) => s.get('serviceCode') === articleCode)) {
    return `B${articleCode}`
  } else return `C${articleCode}`
}

const isOverridden = (priceListPrice?: number, manualPrice?: number) =>
  !isNaN(Number(manualPrice)) && !isNaN(Number(priceListPrice)) && Number(manualPrice) !== Number(priceListPrice)
