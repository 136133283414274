import { isProduction } from '@glow/common'
import { IconFa, IconTruckNotCollected, Option, Text, TextType, Tooltip } from '@glow/ui-components'
import i18next from 'i18next'
import React from 'react'
import { EventType } from '../../../utils/consignmentEvent'

export const eventSelectOptions = (
  canCollectShipment: boolean,
  canReturnShipment: boolean,
  canSortShipment: boolean,
  canMarkAsNotArrivedAtDistributingTerminal: boolean
): Option[] =>
  (
    [
      { code: -1, label: i18next.t('manualOverride.shipmentEvent'), type: 'header' },
      {
        code: EventType.COLLECTED,
        label: (
          <div className="flex gap-2">
            <EventHeading eventType={EventType.COLLECTED} textType="sm" />
            {!canCollectShipment && (
              <Tooltip
                referenceId={`tooltipId_${EventType.COLLECTED}`}
                label={i18next.t('shipmentDetails.disabledEventReason.collected')}
              >
                <IconFa icon={['far', 'info-circle']} id={`tooltipId_${EventType.COLLECTED}`} />
              </Tooltip>
            )}
          </div>
        ),
        disabled: !canCollectShipment
      },
      { code: EventType.DELIVERED, label: <EventHeading eventType={EventType.DELIVERED} textType="sm" /> },
      { code: EventType.DEVIATED, label: <EventHeading eventType={EventType.DEVIATED} textType="sm" /> },
      {
        code: EventType.RETURNED,
        label: (
          <div className="flex gap-2">
            <EventHeading eventType={EventType.RETURNED} textType="sm" />
            {!canReturnShipment && (
              <Tooltip
                referenceId={`tooltipId_${EventType.RETURNED}`}
                label={i18next.t('shipmentDetails.disabledEventReason.returned')}
              >
                <IconFa icon={['far', 'info-circle']} id={`tooltipId_${EventType.RETURNED}`} />
              </Tooltip>
            )}
          </div>
        ),
        disabled: !canReturnShipment
      },
      { code: -2, label: i18next.t('manualOverride.terminalEvent'), type: 'header' },
      { code: EventType.SCANNED, label: <EventHeading eventType={EventType.SCANNED} textType="sm" /> }
    ] as Option[]
  )
    .concat(
      //THIS SHOULD NEVER BE TURNED ON IN PROD! Talk to business before removing the feature flag, this is not meant for prod!
      !isProduction() && canSortShipment
        ? [
            {
              code: EventType.ORDER_SORTED,
              label: <EventHeading eventType={EventType.ORDER_SORTED} textType="sm" />
            }
          ]
        : []
    )
    .concat(
      canMarkAsNotArrivedAtDistributingTerminal
        ? [
            {
              code: EventType.NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL,
              label: <EventHeading eventType={EventType.NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL} textType="sm" />
            }
          ]
        : []
    )

export const EventHeading = ({
  eventType,
  bold,
  textType
}: {
  eventType: EventType
  bold?: boolean
  textType: TextType
}) => (
  <div className="flex gap-2 items-center">
    <EventIcon eventType={eventType} />
    <Text as="p" textType={textType} className="text-black" bold={bold}>
      {i18next.t(`manualOverride.eventType.${eventType}`)}
    </Text>
  </div>
)

const EventIcon = ({ eventType }: { eventType: EventType }) => {
  switch (eventType) {
    case EventType.SCANNED:
      return <IconFa icon={['far', 'barcode-read']} />
    case EventType.ORDER_SORTED:
      return <IconFa icon={['fas', 'conveyor-belt-boxes']} />
    case EventType.NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL:
      return <IconTruckNotCollected />
    case EventType.DEVIATED:
      return <IconFa className="text-danger-dark" icon={['fas', 'exclamation-triangle']} />
    case EventType.COLLECTED:
      return <IconFa icon={['fas', 'circle-arrow-up']} />
    case EventType.RETURNED:
      return <IconFa className="text-danger-dark" icon={['fas', 'reply']} />
    case EventType.DELIVERED:
      return <IconFa className="text-success-dark" icon={['fas', 'check-circle']} />
    default:
      return null
  }
}
