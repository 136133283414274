import React from 'react'
import i18next from 'i18next'
import {
  getRejectedReasonDescription,
  isShowOtherReasonDescription,
  RejectedEventDataProps
} from '../../../../components/Message'

export const EventRejected = (props: { data: RejectedEventDataProps | null }): React.ReactElement => {
  const otherReason = props?.data?.otherReason
  const rejectedReasonDescription = getRejectedReasonDescription(props?.data?.reason, otherReason)
  const showOtherReasonDescription = isShowOtherReasonDescription(props?.data?.reason, otherReason)

  return (
    <div>
      {rejectedReasonDescription && (
        <>
          <p className="text-sm text-emphasis-medium mb-1">{i18next.t('consignmentEvent.rejectedReason.reason')}</p>
          <p className="text-emphasis-high">{rejectedReasonDescription}</p>
        </>
      )}
      {showOtherReasonDescription && (
        <>
          <p className="text-sm text-emphasis-medium mb-1">{i18next.t('consignmentEvent.message.comment')}</p>
          <p className="text-emphasis-high">{otherReason}</p>
        </>
      )}
    </div>
  )
}
