const BRING_PROD_GOOGLE_API_KEY = 'AIzaSyCn5KvyldtSam1GwxbXupUXc1amHYfc3Ek'
const BRING_PROD_HOSTNAME = 'glow.bring.com'
const BRING_SHORT_TRACKING_PROD_HOSTNAME = 't.bring.com'
const POSTEN_PROD_HOSTNAME = 'glow.posten.no'
const POSTEN_SHORT_TRACKING_PROD_HOSTNAME = 't.posten.no'
const POSTEN_QA_HOSTNAME = 'qa.glow-posten.bring.services'
const BRING_PROD_REPORT_URL = 'https://insight.glow.bring.com/opensearch/'
const AMOI_PROD_REPORT_URL = 'https://insight.amoi.glowtechnology.com/opensearch/'
const BRING_QA_GOOGLE_API_KEY = 'AIzaSyD6IoJ7EPub9WzBJMUy30aNXsUdaK9sq1g'
const BRING_QA_HOSTNAME = 'qa.glow.bring.services'
const BRING_QA_FEATURE_HOSTNAME = 'qa.glow-feature.bring.services'
const BRING_QA_REPORT_URL = 'https://insight.qa.glow.bring.services/opensearch/'
const BRING_SHORT_TRACKING_QA_HOSTNAME = 't.qa.bring.services'

const GLOW_TECHNOLOGY_GOOGLE_API_KEY = 'AIzaSyAyt1fBNDhax-NUTN1WbTAHh-HWyTwsP84'

const AMOI_PROD_HOST_NAME = 'amoi.glowtechnology.com'
const DEMO_QA_HOST_NAME = 'demo.glowtechnology.com'

const MIIST_PROD_HOST_NAME = 'miist.glowtechnology.com'
const MIIST_QA_HOSTNAME = 'miist.qa.glowtechnology.com'

const PILOT_HOSTNAME = 'pilot.glowtechnology.com'
const ICA_PROD_HOST_NAME = 'ica.glowtechnology.com'

const NORDISKA_GALLERIELT_PROD_HOST_NAME = 'nordiskagalleriet.glowtechnology.com'

const LOCAL_GOOGLE_API_KEY = 'AIzaSyAO7d495X98nmkdomyAza5vwnSeXIACwms'
const LOCAL_BASE_URL = 'http://localhost:10001'

/* ------------------------------------
Exported functions
------------------------------------ */

// Add new production URL's here, so that they're discovered by tests
export const ALL_PRODUCTION_URLS = [
  AMOI_PROD_HOST_NAME,
  BRING_PROD_HOSTNAME,
  BRING_SHORT_TRACKING_PROD_HOSTNAME,
  ICA_PROD_HOST_NAME,
  MIIST_PROD_HOST_NAME,
  NORDISKA_GALLERIELT_PROD_HOST_NAME,
  POSTEN_PROD_HOSTNAME,
  POSTEN_SHORT_TRACKING_PROD_HOSTNAME
]

// Add new QA URL's here, so that they're discovered by tests
export const ALL_QA_URLS = [
  BRING_QA_HOSTNAME,
  BRING_QA_FEATURE_HOSTNAME,
  BRING_SHORT_TRACKING_QA_HOSTNAME,
  POSTEN_QA_HOSTNAME,
  DEMO_QA_HOST_NAME,
  MIIST_QA_HOSTNAME
]

export function webSocketUrl() {
  if (isLocalhost() || isE2ETest()) {
    return `ws://${window.location.host}/api/subscribe`
  } else {
    return `wss://${hostName()}/api/subscribe`
  }
}

export function baseTrackUrl() {
  if (isDemo()) return `https://${DEMO_QA_HOST_NAME}`
  else if (isBringQa()) return `https://${BRING_QA_HOSTNAME}`
  else if (isPilot()) return `https://${PILOT_HOSTNAME}`
  else if (isIcaProd()) return `https://${ICA_PROD_HOST_NAME}`
  else if (isMiistProd()) return `https://${MIIST_PROD_HOST_NAME}`
  else if (isMiistQa()) return `https://${MIIST_QA_HOSTNAME}`
  else if (isAmoi()) return `https://${AMOI_PROD_HOST_NAME}`
  else if (isNordiskaGalleriet()) return `https://${NORDISKA_GALLERIELT_PROD_HOST_NAME}`
  else if (isLocalhost()) return `http://${window.location.host}`
  else return `https://${BRING_PROD_HOSTNAME}`
}

export function basePostenTrackUrl() {
  if (isLocalhost()) {
    return LOCAL_BASE_URL
  } else if (isBring()) {
    if (isBringQa()) return 'https://qa.glow-posten.bring.services'
    if (isBringProd()) return 'https://glow.posten.no'
    else return LOCAL_BASE_URL
  } else return baseTrackUrl()
}

export function isProduction() {
  return isBringProd() || isPostenProd() || isGlowTechnologyProd()
}

export function allowMockLogin(): boolean {
  return isLocalhost()
}

export function customerLoginUrl(): string {
  if (isGlowTechnology()) return 'login'
  else return 'api/auth/customer-login-initiate'
}

export function reportUrl(): string | undefined {
  if (isBring()) {
    if (isBringProd()) return BRING_PROD_REPORT_URL
    if (isBringQa()) return BRING_QA_REPORT_URL
  } else if (isAmoi()) {
    return AMOI_PROD_REPORT_URL
  } else if (isLocalhost()) return BRING_PROD_REPORT_URL
}

export function sentryEnvironment() {
  if (isBring()) return environmentName() + '_' + 'bring'
  else if (isPosten()) return environmentName() + '_' + 'posten'
  else if (isDemo()) return environmentName() + '_' + 'demo'
  else if (isAmoi()) return environmentName() + '_' + 'amoi'
  else if (isPilot()) return environmentName() + '_' + 'pilot'
  else if (isIca()) return environmentName() + '_' + 'ica'
  else if (isMiist()) return environmentName() + '_' + 'miist'
  else if (isNordiskaGalleriet()) return environmentName() + '_' + 'nordiskagalleriet'
  else return environmentName()
}

export function internalFrontendSentryAPIKey(): string | undefined {
  if (!isLocalhost()) {
    return 'https://a70fefda14584e3c80ba7888a6de217b@o543362.ingest.sentry.io/5663487'
  }
}

export function customerPortalSentryAPIKey(): string | undefined {
  if (!isLocalhost()) {
    return 'https://88549daede8e4c06bdaf047708551b43@o543362.ingest.sentry.io/6209041'
  }
}

export function envMarker(): string | undefined {
  if (isProduction()) return
  else if (isMiistQa()) return 'QA MIIST'
  else if (isBringQaFeature()) return 'QA FEATURE'
  else if (isBringQa()) return 'QA'
  else if (isDemo()) return 'DEMO'
  else if (isLocalhost()) return 'LOCAL'
  else if (isE2ETest()) return 'E2E'
}

export function googleAPIKey(): string | undefined {
  if (isBringProd()) return BRING_PROD_GOOGLE_API_KEY
  else if (isBringQa()) return BRING_QA_GOOGLE_API_KEY
  else if (isGlowTechnology()) return GLOW_TECHNOLOGY_GOOGLE_API_KEY
  else if (isLocalhost()) return LOCAL_GOOGLE_API_KEY
  else if (isE2ETest()) return LOCAL_GOOGLE_API_KEY
}

export function supportUrl(): string {
  if (isGlowTechnology()) {
    return 'https://glowhelpcenter.zendesk.com/hc/en-us'
  } else {
    return 'https://glowtechnology.zendesk.com'
  }
}

export function isRunningTests(): boolean {
  return window.process?.env.JEST_WORKER_ID !== undefined
}
export function isE2ETest(): boolean {
  return hostName() === 'glow-proxy' && window.location.port === '22222'
}
export function isLocalhost(): boolean {
  return hostName() === 'localhost'
}
export function trackingSentryAPIKey(): string | undefined {
  if (!isLocalhost() && !isE2ETest()) {
    return 'https://e10766deb8d14a089b23ac3349394626@o543362.ingest.sentry.io/5668554'
  }
}

export function isBringOrPosten(): boolean {
  return isBring() || isPosten()
}

export function isPosten(): boolean {
  return window.location.hostname.includes('posten')
}

export function isPTVEnabled() {
  return isBring() || isPilot()
}

export function isPTVCloudEnabled() {
  return isBringQa() || isLocalhost()
}

export function isBring() {
  return isBringQa() || isBringProd() || isLocalhost() || isE2ETest()
}
export function isGlowTechnology() {
  return hostName().includes('.glowtechnology.com')
}

export function isDemo() {
  return hostName() == DEMO_QA_HOST_NAME
}
export function isAmoi() {
  return isAmoiProd()
}

export function isBringQa() {
  return (
    hostName() === BRING_QA_HOSTNAME ||
    hostName() === BRING_QA_FEATURE_HOSTNAME ||
    hostName() === BRING_SHORT_TRACKING_QA_HOSTNAME
  )
}

export function isBringQaFeature() {
  return hostName() === BRING_QA_FEATURE_HOSTNAME
}

export function isPilot() {
  return hostName() == PILOT_HOSTNAME
}

export function isIca() {
  return isIcaProd()
}

export function isMiist() {
  return isMiistProd() || isMiistQa()
}

export function isNordiskaGalleriet() {
  return isNordiskaGallerietProd()
}

export type Client = 'bring' | 'glow'

export function getClient(): Client {
  return isBring() ? 'bring' : 'glow'
}

export function glowFrontendDomain() {
  return window.location.hostname === 'localhost' && window.location.port === '10002'
    ? window.location.protocol + '//localhost:10000'
    : window.location.protocol + '//' + window.location.host
}

export function enablePricingEngine() {
  return isBring() || isMiist() || isDemo()
}

export function isHdEnabled() {
  return isBring() || isLocalhost() || isNordiskaGalleriet() || isDemo()
}

export function enableMandatoryVehicle() {
  return isGlowTechnology()
}

export function isUnitOptional() {
  return isGlowTechnology()
}

/* ------------------------------------
private helpers
------------------------------------ */
function environmentName() {
  if (isBringProd() || isGlowTechnologyProd()) return 'production'
  else if (isBringQa() || isGlowTechnologyQa()) return 'qa'
  else return 'development'
}

function hostName() {
  return window.location.hostname
}

function isGlowTechnologyQa() {
  return hostName().includes('.qa.glowtechnology.com') || isDemo()
}

function isGlowTechnologyProd() {
  return isGlowTechnology() && !isGlowTechnologyQa()
}

function isAmoiProd() {
  return hostName() == AMOI_PROD_HOST_NAME
}

export function isBringProd() {
  return hostName() === BRING_PROD_HOSTNAME || hostName() == BRING_SHORT_TRACKING_PROD_HOSTNAME
}

function isPostenProd() {
  return hostName() === POSTEN_PROD_HOSTNAME || hostName() == POSTEN_SHORT_TRACKING_PROD_HOSTNAME
}

function isIcaProd() {
  return hostName() === ICA_PROD_HOST_NAME
}

function isMiistProd() {
  return hostName() === MIIST_PROD_HOST_NAME
}

function isMiistQa() {
  return hostName() === MIIST_QA_HOSTNAME
}

function isNordiskaGallerietProd() {
  return hostName() === NORDISKA_GALLERIELT_PROD_HOST_NAME
}
