import { H3 } from '../../../primitives/Headings'
import i18next from 'i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import variables from '../../../styles/variables'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { email, phone, required, validPhonePrefix, validTrimmed } from '../../../utils/inputValidation'
import { AUTOSUGGEST_ADDRESSES } from '../../../actions/actionTypes'
import { SmallValidationError } from '../../../primitives/ErrorMessages'
import { fromJS, List, Map } from 'immutable'
import { LabeledInput } from '../../../primitives/LabeledInput'
import { InputForLabel, TextAreaInputWithLabel } from '../../../primitives/Forms'
import Select from '../../../primitives/Select'
import { getSupportedCountries } from '../../../utils/country'
import { BookingType, deliveryComponentNames, OrderAction } from '../bookingSupport'
import { TertiaryButton } from '../../../primitives/Button'
import { GroupRecipientProps, MultistopGroupForm } from '../MultistopGroupForm'
import { ClearInstantBookingFormButton } from '../../../components/ClearInstantBookingFormButton'
import InputGroup from '../InputGroup'
import * as React from 'react'
import { IAddress, IOrderForm, IOrderFormProps } from '../bookingOrderTypes'
import { Department } from '../../../types/coreEntitiesTypes'
import { ImmutableMap, isNotNullOrUndefined } from '../../../types/immutableTypes'
import { isNotEmpty } from '../../../utils/collectionUtils'
import { formValuesToGroupRecipient, getPhonePrefixFromDepartment } from '../bookingOrderFunctions'
import ImmutableComponent from '../../../primitives/ImmutableComponent'
import { GooglePlacesAutocompleteWrapper } from '../../../components/GooglePlacesAutompleteWrapper'
import { DeliveryName } from './DeliveryName'
import toUpper from 'lodash/toUpper'

interface Props {
  formValues: IOrderForm
  mergeFormValues: (model: string, values: Map<string, any>) => void
  setPageStateValue: (key: string, value: any) => void
  departmentId: number
  department: Department
  orderAction: OrderAction
  setTouched: (models: string) => void
  setUntouched: (models: string) => void
  form: { [key: string]: any }
  disableAdditionalRecipientButton?: boolean
  hideAdditionalRecipientButton?: boolean
  bookingType?: BookingType
  formName: string
  clearComponents: (components: List<string>) => void
}

export const recipientFields = List.of<keyof IOrderFormProps>(
  'deliveryName',
  'deliveryAddress',
  'deliveryZipArea',
  'deliveryZipCode',
  'deliveryCountryCode',
  'deliveryPhone',
  'deliveryContactPerson',
  'deliveryInstructions',
  'recipientRef',
  'deliveryEmail'
)

class BookingFormDelivery extends ImmutableComponent<Props> {
  onSwitchDeliveryAndRecipientData(values: IOrderForm) {
    this.props.mergeFormValues(
      this.props.formName,
      Map({
        deliveryName: values.get('pickupName'),
        deliveryAddress: values.get('pickupAddress'),
        deliveryZipArea: values.get('pickupZipArea'),
        deliveryZipCode: values.get('pickupZipCode'),
        deliveryCountryCode: values.get('pickupCountryCode'),
        deliveryPhone: values.get('pickupPhone'),
        deliveryEmail: values.get('pickupEmail'),
        deliveryInstructions: values.get('pickupInstructions'),
        deliveryContactPerson: values.get('pickupContactPerson'),
        pickupInstructions: values.get('deliveryInstructions'),
        pickupName: values.get('deliveryName'),
        pickupAddress: values.get('deliveryAddress'),
        pickupZipArea: values.get('deliveryZipArea'),
        pickupZipCode: values.get('deliveryZipCode'),
        pickupCountryCode: values.get('deliveryCountryCode'),
        pickupPhone: values.get('deliveryPhone'),
        pickupEmail: values.get('deliveryEmail'),
        pickupContactPerson: values.get('deliveryContactPerson')
      })
    )

    this.props.setPageStateValue(
      AUTOSUGGEST_ADDRESSES + `${this.props.formName}.pickupName`,
      values.get('deliveryName')
    )
    this.props.setPageStateValue(
      AUTOSUGGEST_ADDRESSES + `${this.props.formName}.deliveryName`,
      values.get('pickupName')
    )
  }

  onDeliveryAddressChanged = (address: IAddress) => {
    const formValues = {
      deliveryAddress: address.get('streetname') || address.get('address') || '',
      deliveryZipCode: address.get('zipCode') || '',
      deliveryZipArea: address.get('zipArea') || '',
      deliveryCountryCode: toUpper(address.get('countryCode') || address.get('country') || ''),
      deliveryAddressLat: address.get('latLng')?.get('lat') || NaN,
      deliveryAddressLng: address.get('latLng')?.get('lng') || NaN
    }
    this.props.mergeFormValues(this.props.formName, Map(formValues).filter(isNotNullOrUndefined))
  }

  onDeliveryPlaceChanged = (address: IAddress) => {
    const deliveryInstructionValues = isNotEmpty(address.get('instructions')) ? address.get('instructions') : null
    this.onDeliveryAddressChanged(address)
    const formValues = {
      deliveryPhone: address.get('phone') || getPhonePrefixFromDepartment(this.props.department),
      deliveryContactPerson: address.get('contactPerson') || '',
      deliveryInstructions: deliveryInstructionValues,
      deliveryEmail: ''
    }
    this.props.mergeFormValues(this.props.formName, Map(formValues).filter(isNotNullOrUndefined))
  }

  setFieldsTouched = (fields: List<string>, touched: boolean = true) => {
    if (touched) {
      fields.forEach(this.props.setTouched)
    } else {
      fields.forEach(this.props.setUntouched)
    }
  }

  setRecipientFieldsTouched = (touched: boolean = true) => {
    this.setFieldsTouched(
      recipientFields.map((f) => `${this.props.formName}.` + f),
      touched
    )
  }

  static isFormfieldValid(formField: any) {
    return formField && formField.valid
  }

  isDeliveryValid = (form: { [p: string]: any }) => {
    return (
      form &&
      [
        form.deliveryName,
        form.deliveryAddress,
        form.deliveryZipArea,
        form.deliveryZipCode,
        form.deliveryCountryCode
      ].every(BookingFormDelivery.isFormfieldValid)
    )
  }

  submitRecipient = () => {
    const values = this.props.formValues
    const form = this.props.form
    this.setRecipientFieldsTouched(true)
    if (this.isDeliveryValid(form)) {
      const groupRecipients = values.get('groupRecipients') || List()
      const phonePrefix = getPhonePrefixFromDepartment(this.props.department)
      this.props.mergeFormValues(
        this.props.formName,
        Map({
          groupRecipients: groupRecipients.push(
            formValuesToGroupRecipient(values, getPhonePrefixFromDepartment(this.props.department))
          )
        })
      )
      this.props.mergeFormValues(
        this.props.formName,
        Map({
          deliveryName: '',
          deliveryAddress: '',
          deliveryZipArea: '',
          deliveryZipCode: '',
          deliveryCountryCode: '',
          deliveryPhone: phonePrefix || '',
          deliveryContactPerson: '',
          deliveryInstructions: '',
          deliveryEmail: ''
        })
      )
      this.setRecipientFieldsTouched(false)
    }
  }

  render() {
    const {
      formValues,
      mergeFormValues,
      department,
      orderAction,
      disableAdditionalRecipientButton,
      hideAdditionalRecipientButton,
      bookingType,
      clearComponents
    } = this.props
    const isH2Booking = bookingType === BookingType.H2

    return (
      <InputGroup aria-label="Delivery form">
        <H3>
          {i18next.t('instant.booking.delivery')}
          <span
            style={{
              cursor: 'pointer',
              textAlign: 'right',
              marginLeft: '1em'
            }}
            onClick={() => this.onSwitchDeliveryAndRecipientData(formValues)}
          >
            <FontAwesomeIcon icon="exchange-alt" color={variables.colors.gray9} size="sm" />
          </span>
        </H3>
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: 5, paddingRight: '0.75em' }}>
            <DeliveryName
              department={department}
              onDeliveryPlaceChanged={this.onDeliveryPlaceChanged}
              isH2Booking={isH2Booking}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Control
              model=".deliveryLocation"
              id="deliveryLocation"
              placeholder={i18next.t('instant.booking.deliveryAddressPlaceholder')}
              component={GooglePlacesAutocompleteWrapper}
              searchAroundArea={{
                lat: department.get('lat'),
                lng: department.get('lng')
              }}
              rounded={true}
              onComplete={(address: IAddress) => this.onDeliveryAddressChanged(fromJS(address) as IAddress)}
            />
          </div>
        </div>

        <div style={{ display: 'flex', marginTop: '0.75em' }}>
          <div style={{ paddingRight: '0.75em', flexGrow: 3 }}>
            <LabeledInput label={i18next.t('instant.booking.deliveryAddressPlaceholder')} htmlFor="deliveryAddress">
              <Control.text
                autoComplete="nope"
                model=".deliveryAddress"
                id="deliveryAddress"
                component={InputForLabel}
                validators={{ required }}
                defaultValue={''}
              />
            </LabeledInput>
            <Errors
              className="errors"
              model=".deliveryAddress"
              show="touched"
              wrapper={SmallValidationError}
              messages={{ required: `${i18next.t('application.required')}` }}
            />
          </div>

          <div style={{ paddingRight: '0.75em', width: '10em' }}>
            <LabeledInput label={i18next.t('instant.booking.deliveryZipCodePlaceholder')} htmlFor="deliveryZipCode">
              <Control.text
                autoComplete="nope"
                model=".deliveryZipCode"
                id="deliveryZipCode"
                component={InputForLabel}
                validators={{ required, validTrimmed }}
                defaultValue={''}
              />
            </LabeledInput>
            <Errors
              className="errors"
              model=".deliveryZipCode"
              show="touched"
              wrapper={SmallValidationError}
              messages={{
                required: `${i18next.t('application.required')}`,
                validTrimmedToNumber: `${i18next.t('error.notAValidNumber')}`
              }}
            />
          </div>
          <div style={{ paddingRight: '0.75em', flexGrow: 1 }}>
            <LabeledInput label={i18next.t('instant.booking.deliveryZipAreaPlaceholder')} htmlFor="deliveryZipArea">
              <Control.text
                autoComplete="nope"
                model=".deliveryZipArea"
                id="deliveryZipArea"
                component={InputForLabel}
                validators={{ required }}
                defaultValue={''}
              />
            </LabeledInput>

            <Errors
              className="errors"
              model=".deliveryZipArea"
              show="touched"
              wrapper={SmallValidationError}
              messages={{ required: `${i18next.t('application.required')}` }}
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <Select expand>
              <Control.select
                model=".deliveryCountryCode"
                id="deliveryCountryCode"
                validators={{ required }}
                defaultValue={''}
              >
                <option value={''}>{i18next.t('instant.booking.deliveryCountryPlaceholder')}</option>
                {getSupportedCountries().map((country: Map<string, any>) => {
                  const id = country.get('code')
                  return (
                    <option key={`deliveryCountryCode-${id}`} value={id}>
                      {country.get('name')}
                    </option>
                  )
                })}
              </Control.select>
            </Select>
            <Errors
              className="errors"
              model=".deliveryCountryCode"
              show="touched"
              wrapper={SmallValidationError}
              messages={{ required: `${i18next.t('application.required')}` }}
            />
          </div>
        </div>

        <div style={{ display: 'flex', marginTop: '0.75em', gap: '0.75rem' }}>
          <div style={{ flexGrow: 1 }}>
            <LabeledInput label={i18next.t('instant.booking.deliveryContactPerson')} htmlFor="deliveryContactPerson">
              <Control.text
                autoComplete="nope"
                model=".deliveryContactPerson"
                id="deliveryContactPerson"
                component={InputForLabel}
              />
            </LabeledInput>
          </div>
          <div style={{ flexGrow: 1 }}>
            <LabeledInput label={i18next.t('instant.booking.deliveryPhoneNumberPlaceholder')} htmlFor="deliveryPhone">
              <Control.text
                autoComplete="nope"
                model=".deliveryPhone"
                id="deliveryPhone"
                component={InputForLabel}
                validators={{ phone, validPhonePrefix }}
              />
            </LabeledInput>
            <Errors
              className="errors"
              model=".deliveryPhone"
              show="touched"
              wrapper={SmallValidationError}
              messages={{
                required: `${i18next.t('application.required')}`,
                phone: `${i18next.t('error.phoneNumber')}`,
                validPhonePrefix: `${i18next.t('error.phoneNumberPrefix')}`
              }}
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <LabeledInput label={i18next.t('shipment.email')} htmlFor="deliveryEmail">
              <Control.text
                autoComplete="nope"
                model=".deliveryEmail"
                id="deliveryEmail"
                component={InputForLabel}
                validators={{ email }}
              />
            </LabeledInput>
            <Errors
              className="errors"
              model=".deliveryEmail"
              show="touched"
              wrapper={SmallValidationError}
              messages={{ email: `${i18next.t('instant.booking.invalidEmail')}` }}
            />
          </div>
        </div>

        <div style={{ marginTop: '0.75em' }}>
          <LabeledInput
            label={i18next.t('instant.booking.deliveryInformationPlaceholder')}
            htmlFor="deliveryInstructions"
          >
            <Control.textarea
              model=".deliveryInstructions"
              id="deliveryInstructions"
              rows={4}
              component={TextAreaInputWithLabel}
            />
          </LabeledInput>
        </div>
        {!isH2Booking && (
          <>
            {orderAction !== OrderAction.Edit && !hideAdditionalRecipientButton && (
              <div
                style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '0.75em', alignItems: 'baseline' }}
              >
                <TertiaryButton
                  type="button"
                  onClick={this.submitRecipient}
                  disabled={disableAdditionalRecipientButton}
                >
                  <span style={{ margin: '5px' }}>{i18next.t('instant.booking.addRecipient')}</span>
                </TertiaryButton>
              </div>
            )}

            <MultistopGroupForm
              recipients={formValues.get('groupRecipients') || List<ImmutableMap<GroupRecipientProps>>()}
              formValues={formValues}
              onDelete={(id: number) => {
                const groupRecipients = this.props.formValues.get('groupRecipients') || List()
                mergeFormValues(this.props.formName, Map({ groupRecipients: groupRecipients.remove(id) }))
              }}
            />
          </>
        )}
        <ClearInstantBookingFormButton clearComponents={clearComponents} components={deliveryComponentNames} />
      </InputGroup>
    )
  }
}

export default BookingFormDelivery
