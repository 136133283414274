import i18next from 'i18next'
import { List, Map } from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
// @ts-expect-error
import { Form } from 'react-redux-form/lib/immutable'
import { CREATE_ORDER_OPTIMUS, SEARCH_H2_ORDER } from '../../../actions/actionTypes'
import { createOrUpdateOrder, setPageStateValue } from '../../../actions/creators/helpers'
import { Loading } from '../../../components/Loading'
import { PrimaryButton } from '../../../primitives/Button'
import { H1 } from '../../../primitives/Headings'
import ImmutableComponent from '../../../primitives/ImmutableComponent'
import { errorMessagesFor, errorMessagesOptimusFor } from '../../../selectors/httpStatusSelectors'
import { Department, DepartmentIdType, Order } from '../../../types/coreEntitiesTypes'
import { AppStateType } from '../../../utils/appStateReduxStore'
import { concat } from '../../../utils/collectionUtils'
import {
  formValuesToGroupPickup,
  formValuesToGroupRecipient,
  getPhonePrefixFromDepartment,
  prepareClearComponentMap,
  stripEmptyPhonePrefix
} from '../bookingOrderFunctions'
import InputGroup from '../InputGroup'
import BookingFormPickup from '../bookingFormComponents/BookingFormPickup'
import BookingFormDelivery from '../bookingFormComponents/BookingFormDelivery'
import { getButtonTitle } from '../bookingFormComponents/commonHelpers'
import {
  addToFormList,
  mergeFormValues,
  removeFromFormList,
  resetForm,
  setTouched,
  setUntouched,
  submit
} from '../../../actions/creators/formHelpers'
import { addNotification } from '../../../actions/creators/helpersNotifications'
import { BookingType, OrderAction } from '../bookingSupport'
import { ErrorMessages } from '../../../primitives/ErrorMessages'
import { H2BookingService } from './H2BookingService'
import { H2BookingCustomer } from './H2BookingCustomer'
import { H2BookingSearchOrder } from './H2BookingSearchOrder'
import { H2OrderForm } from './H2BookingTypes'
import { createConsignmentSet, h2BookingForm } from './H2BookingSupport'
import H2BookingGoods from './H2BookingGoods'
import { ContextType } from '../InstantGrid'
import { IOrderForm } from '../bookingOrderTypes'
import { withRouter, RouteComponentProps } from '../../../hooks/withRouter'
import { compose } from 'redux'

class H2BookingForm extends ImmutableComponent<Props> {
  componentWillUnmount() {
    setTimeout(() => this.props.resetForm(h2BookingForm), 200)
  }

  onSubmit = (formValues: H2OrderForm) => {
    const orderAction = this.props.orderAction
    const shipmentId = formValues.get('shipmentId')
    const phonePrefix = getPhonePrefixFromDepartment(this.props.department)

    const pickup = formValuesToGroupPickup(
      // TODO: It seems like H2OrderForm probably should be a subset of IOrderForm
      this.props.formValues as unknown as IOrderForm,
      getPhonePrefixFromDepartment(this.props.department)
    )
    const recipient = formValuesToGroupRecipient(
      this.props.formValues as unknown as IOrderForm,
      getPhonePrefixFromDepartment(this.props.department)
    )

    const pickupPhone = stripEmptyPhonePrefix(formValues.get('pickupPhone') || '', phonePrefix)
    const deliveryPhone = stripEmptyPhonePrefix(formValues.get('deliveryPhone') || '', phonePrefix)

    const consignmentSet = createConsignmentSet(
      formValues,
      pickupPhone,
      pickup,
      deliveryPhone,
      recipient,
      orderAction,
      this.props.department
    )

    const payload = {
      messageSenderPartyId: 'glow',
      consignmentSets: List.of(consignmentSet).toJS()
    }

    this.props.createOrUpdateOrder(
      payload,
      `/${ContextType.Planner}/${this.props.departmentId}/confirmation/${shipmentId}`,
      this.props.router.navigate
    )
  }

  onClickSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    this.props.submit(h2BookingForm)
  }

  clearComponents = (components: List<string>) => {
    const clearDataMap = components.flatMap((component: string) =>
      prepareClearComponentMap(component, this.props.department)
    )
    this.props.mergeFormValues(h2BookingForm, clearDataMap)

    components.map((component) => this.props.setUntouched(`${h2BookingForm}.${component}`))
  }

  render() {
    const {
      errorMessages,
      orderAction,
      formValues,
      isLoading,
      subTitleKey,
      department,
      departmentId,
      mergeFormValues
    } = this.props

    if (isLoading) return <Loading />

    return (
      <Form model={h2BookingForm} onSubmit={(form: H2OrderForm) => this.onSubmit(form)}>
        <H1 size="l" style={{ marginTop: '.5rem' }}>
          {i18next.t(subTitleKey)}
        </H1>

        {errorMessages && <ErrorMessages errorMessages={errorMessages} />}

        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '0.75rem', marginTop: '0.75rem' }}>
          <H2BookingSearchOrder
            clearComponents={this.clearComponents}
            formValues={formValues}
            setTouched={this.props.setTouched}
            mergeFormValues={mergeFormValues}
          />

          <H2BookingCustomer clearComponents={this.clearComponents} />

          <BookingFormPickup
            departmentId={departmentId}
            mergeFormValues={mergeFormValues}
            department={department}
            formValues={formValues as unknown as IOrderForm}
            addNotification={this.props.addNotification}
            orderAction={orderAction}
            setTouched={this.props.setTouched}
            setUntouched={this.props.setUntouched}
            form={this.props.form}
            bookingType={BookingType.H2}
            formName={h2BookingForm}
            clearComponents={this.clearComponents}
          />

          <BookingFormDelivery
            formValues={this.props.formValues as unknown as IOrderForm}
            mergeFormValues={mergeFormValues}
            setPageStateValue={this.props.setPageStateValue}
            departmentId={departmentId}
            department={department}
            orderAction={orderAction}
            setTouched={this.props.setTouched}
            setUntouched={this.props.setUntouched}
            form={this.props.form}
            bookingType={BookingType.H2}
            formName={h2BookingForm}
            clearComponents={this.clearComponents}
          />

          <H2BookingGoods
            formValues={this.props.formValues as unknown as IOrderForm}
            mergeFormValues={mergeFormValues}
            addToFormList={this.props.addToFormList}
            removeFromFormList={this.props.removeFromFormList}
            formName={h2BookingForm}
            clearComponents={this.clearComponents}
          />

          <H2BookingService
            formValues={this.props.formValues}
            clearComponents={this.clearComponents}
            addToFormList={this.props.addToFormList}
            removeFromFormList={this.props.removeFromFormList}
          />

          <InputGroup>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <PrimaryButton type="button" onClick={this.onClickSubmit}>
                {getButtonTitle(orderAction)}
              </PrimaryButton>
            </div>
          </InputGroup>
        </div>
      </Form>
    )
  }
}

type PathParamsType = {}
type RouterType = RouteComponentProps<PathParamsType>
interface StateProps {
  departmentId: DepartmentIdType
  department: Department
  errorMessages: List<string> | null
  formValues: H2OrderForm
  isLoading: boolean
  subTitleKey: string
  form: { [key: string]: any }
  orderAction: OrderAction
}

interface DispatchProps {
  setPageStateValue: (key: string, value: any) => void
  resetForm: (formName: string) => void
  mergeFormValues: (...x: any[]) => void
  setTouched: (models: string) => void
  setUntouched: (models: string) => void
  addToFormList: (formName: string, values: Map<string, string>) => void
  removeFromFormList: (model: string, index: number) => void
  createOrUpdateOrder: (payload: {}, redirectUrl: string, push: (url: string) => void) => void
  addNotification: (props: { title: string; message?: string; timeout: number }) => void
  submit: (model: string, promise?: Promise<any>, options?: any) => void
}

interface OwnProps {
  department: Department
  order?: Order
  subTitleKey: string
  isLoading: boolean
  orderAction: OrderAction
}

type Props = StateProps & DispatchProps & RouterType

export default compose<any>(
  withRouter,
  connect<StateProps, DispatchProps, OwnProps, AppStateType>(
    (state, ownProps) => {
      const errorMessages = concat(
        errorMessagesOptimusFor(state, CREATE_ORDER_OPTIMUS),
        errorMessagesFor(state, SEARCH_H2_ORDER)
      )

      const formValues = state.getIn([h2BookingForm])
      const form = state.getIn(['forms', h2BookingForm])
      const department = ownProps.department
      return {
        orderAction: ownProps.orderAction,
        departmentId: department.get('id'),
        department,
        isLoading: ownProps.isLoading,
        errorMessages,
        formValues,
        subTitleKey: ownProps.subTitleKey,
        form
      }
    },
    {
      resetForm,
      mergeFormValues,
      setTouched,
      setUntouched,
      addToFormList,
      removeFromFormList,
      setPageStateValue,
      createOrUpdateOrder,
      addNotification,
      submit
    }
  )
)(H2BookingForm)
