import React from 'react'
import { EditableContactInfoGrid, HorizontalSpace } from '../shared/Styles'
import { DeliveryTime } from '../shared/Shipment'
import i18next from 'i18next'
import { H2 } from '../../../primitives/Headings'
import { Field, FormikErrors, FormikTouched } from 'formik'
import { Input, TextAreaInput } from '../../../primitives/Forms'
import variables from '../../../styles/variables'
import { DeliveryTimeComponent, NameWithSearchAddress, Reference, SearchPlaces } from '../shared/CommonComponents'
import { DateTime } from 'luxon'
import { QuickEditFormProps } from '../shipmentModalUtils'
import {
  requiredFormik,
  validEmailFormik,
  validPhonePrefixFormik,
  validTrimmedToNumberFormik
} from '../../../utils/formikInputValidation'
import { ValidationError } from '../../../primitives/ErrorMessages'
import { Department, RoutePoint } from '../../../types/coreEntitiesTypes'
import { getAta } from '../../routePointHelper'

export const EditableDeliveryComponent: React.FC<{
  deliveryTime?: DeliveryTime
  eta?: DateTime
  errors: FormikErrors<QuickEditFormProps>
  touched: FormikTouched<QuickEditFormProps>
  reference?: string
  department?: Department
  deliveryRoutePoint?: RoutePoint
}> = ({ deliveryTime, eta, errors, touched, reference, department, deliveryRoutePoint }) => (
  <>
    <H2>{i18next.t('shipment.deliveryInfo')}</H2>
    <ReceiverComponent errors={errors} touched={touched} department={department} />
    <HorizontalSpace />
    <DeliveryTimeComponent eta={eta} deliveryTime={deliveryTime} ata={getAta(deliveryRoutePoint)} />
    <HorizontalSpace />
    <Reference reference={reference} title={i18next.t('shipment.receiverReference')} />
    <HorizontalSpace />
    <DeliveryInstructions />
  </>
)

const DeliveryInstructions = () => (
  <div>
    <div
      style={{
        color: variables.newColors.darkGrey,
        fontSize: '0.75rem',
        marginBottom: '2px'
      }}
    >
      {i18next.t('shipment.deliveryInstructions')}
    </div>
    <Field as={TextAreaInput} name="deliveryInstructions" rows={2} />
  </div>
)

const ReceiverComponent: React.FC<{
  errors: FormikErrors<QuickEditFormProps>
  touched: FormikTouched<QuickEditFormProps>
  department?: Department
}> = ({ errors, touched, department }) => (
  <EditableContactInfoGrid>
    <NameWithSearchAddress type={'delivery'} errors={errors} touched={touched} department={department} />
    <Address errors={errors} touched={touched} />
    <SearchPlaces type={'delivery'} department={department} />
    <ZipCode errors={errors} touched={touched} />
    <ZipArea errors={errors} touched={touched} />
    <Phone errors={errors} touched={touched} />
    <SecondPhone errors={errors} touched={touched} />
    <Email errors={errors} touched={touched} />
  </EditableContactInfoGrid>
)

const Address: React.FC<{ errors: FormikErrors<QuickEditFormProps>; touched: FormikTouched<QuickEditFormProps> }> = ({
  errors,
  touched
}) => (
  <div style={{ gridColumn: '1/3' }}>
    <div style={{ color: variables.newColors.darkGrey, fontSize: '0.75rem', marginBottom: '2px' }}>
      {i18next.t('shipment.address')}
    </div>
    <Field as={Input} style={{ height: '40px' }} name="deliveryAddress" validate={requiredFormik} />
    {errors.deliveryAddress && touched.deliveryAddress && <ValidationError>{errors.deliveryAddress}</ValidationError>}
  </div>
)

const ZipCode: React.FC<{ errors: FormikErrors<QuickEditFormProps>; touched: FormikTouched<QuickEditFormProps> }> = ({
  errors,
  touched
}) => (
  <div>
    <div style={{ color: variables.newColors.darkGrey, fontSize: '0.75rem', marginBottom: '2px' }}>
      {i18next.t('shipment.zipCode')}
    </div>
    <Field as={Input} style={{ height: '40px' }} name="deliveryZipCode" validate={validTrimmedToNumberFormik} />
    {errors.deliveryZipCode && touched.deliveryZipCode && <ValidationError>{errors.deliveryZipCode}</ValidationError>}
  </div>
)

const ZipArea: React.FC<{ errors: FormikErrors<QuickEditFormProps>; touched: FormikTouched<QuickEditFormProps> }> = ({
  errors,
  touched
}) => (
  <div style={{ gridColumn: '2/4' }}>
    <div style={{ color: variables.newColors.darkGrey, fontSize: '0.75rem', marginBottom: '2px' }}>
      {i18next.t('shipment.zipArea')}
    </div>
    <Field as={Input} style={{ height: '40px' }} name="deliveryZipArea" validate={requiredFormik} />
    {errors.deliveryZipArea && touched.deliveryZipArea && <ValidationError>{errors.deliveryZipArea}</ValidationError>}
  </div>
)

const Phone: React.FC<{ errors: FormikErrors<QuickEditFormProps>; touched: FormikTouched<QuickEditFormProps> }> = ({
  errors,
  touched
}) => (
  <div style={{ gridColumn: '1/3' }}>
    <div style={{ color: variables.newColors.darkGrey, fontSize: '0.75rem', marginBottom: '2px' }}>
      {i18next.t('shipment.phone')}
    </div>
    <Field as={Input} style={{ height: '40px' }} name="deliveryPhone" validate={validPhonePrefixFormik} />
    {errors.deliveryPhone && touched.deliveryPhone && <ValidationError>{errors.deliveryPhone}</ValidationError>}
  </div>
)

const SecondPhone: React.FC<{
  errors: FormikErrors<QuickEditFormProps>
  touched: FormikTouched<QuickEditFormProps>
}> = ({ errors, touched }) => (
  <div style={{ gridColumn: '3/4' }}>
    <div style={{ color: variables.newColors.darkGrey, fontSize: '0.75rem', marginBottom: '2px' }}>
      {i18next.t('shipment.secondPhone')}
    </div>
    <Field as={Input} style={{ height: '40px' }} name="deliverySecondPhone" validate={validPhonePrefixFormik} />
    {errors.deliverySecondPhone && touched.deliverySecondPhone && (
      <ValidationError>{errors.deliverySecondPhone}</ValidationError>
    )}
  </div>
)

const Email: React.FC<{ errors: FormikErrors<QuickEditFormProps>; touched: FormikTouched<QuickEditFormProps> }> = ({
  errors,
  touched
}) => (
  <div style={{ gridColumn: '1/4' }}>
    <div style={{ color: variables.newColors.darkGrey, fontSize: '0.75rem', marginBottom: '2px' }}>
      {i18next.t('shipment.email')}
    </div>
    <Field
      as={Input}
      inputProp={{ type: 'email' }}
      style={{ height: '40px' }}
      name="deliveryEmail"
      validate={validEmailFormik}
    />
    {errors.deliveryEmail && touched.deliveryEmail && <ValidationError>{errors.deliveryEmail}</ValidationError>}
  </div>
)
