import styled, { css } from 'styled-components'
import * as CSS from 'csstype'

interface GridDivProps {
  row: number
  column: number
  justifySelf?: CSS.Property.JustifySelf
}

export const GridDiv = styled.div<GridDivProps>`
  grid-row: ${(props) => props.row};
  grid-column: ${(props) => props.column};
  ${({ justifySelf }) =>
    justifySelf &&
    css`
      justify-self: ${justifySelf};
    `}
`
