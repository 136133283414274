import { Consignment, ConsignmentIdType } from '../types/coreEntitiesTypes'
import { ConsignmentState, getHighestStateFromShipment, notCollectedStates } from './consignmentState'
import { ConsignmentsByOrderIdType } from '../selectors/shipmentSelectors'
import { ConsignmentEventsMapType } from './shipmentsUtils'
import { isConsignmentCredited, isConsignmentReturned } from './consignmentEvent'
import { Collection, Map, Set } from 'immutable'
import { ImmutableMap, isNotNullOrUndefined } from '../types/immutableTypes'

const noFilter = Set([])

export interface StateFiltersForCustomers extends ImmutableMap<StateFiltersForCustomersProps> {}

interface StateFiltersForCustomersProps {
  showAll: Set<ConsignmentState>
  showCollected: Set<ConsignmentState>
  showDelivered: Set<ConsignmentState>
  showDeviated: Set<ConsignmentState>
  showNotCollected: Set<ConsignmentState>
}

export const StateFiltersForCustomer: StateFiltersForCustomers = Map({
  showAll: noFilter,
  showCollected: Set([ConsignmentState.COLLECTED]),
  showDelivered: Set([ConsignmentState.DELIVERED]),
  showDeviated: Set([ConsignmentState.DEVIATED, ConsignmentState.RETURNED]),
  showNotCollected: notCollectedStates
})

export interface ReturnType {
  showAllCount: number
  showCollectedCount: number
  showDeliveredCount: number
  showDeviatedCount: number
  showNotCollectedCount: number
  filteredConsignmentsByOrderId: ConsignmentsByOrderIdType
}

export const getFilteredConsignmentsData = (
  consignmentsByOrderId: ConsignmentsByOrderIdType,
  filterOnStates: Set<ConsignmentState>,
  consignmentEventsMap: ConsignmentEventsMapType
): ReturnType => {
  let showCollectedCount = 0
  let showDeliveredCount = 0
  let showDeviatedCount = 0
  let showNotCollectedCount = 0

  const filteredConsignmentsByOrderId = consignmentsByOrderId.filter((consignments) => {
    const highestShipmentState = getHighestStateFromShipment(consignments.toSet())
    const allPackagesInOrderReturned = areAllPackagesInOrderReturned(consignments, consignmentEventsMap)

    if (allPackagesInOrderReturned || StateFiltersForCustomer.get('showDeviated').includes(highestShipmentState)) {
      showDeviatedCount++
    } else {
      if (StateFiltersForCustomer.get('showCollected').includes(highestShipmentState)) {
        showCollectedCount++
      } else if (StateFiltersForCustomer.get('showDelivered').includes(highestShipmentState)) {
        showDeliveredCount++
      } else if (StateFiltersForCustomer.get('showNotCollected').includes(highestShipmentState)) {
        showNotCollectedCount++
      }
    }
    return (
      filterOnStates.isEmpty() ||
      (allPackagesInOrderReturned && filterOnStates.includes(ConsignmentState.RETURNED)) ||
      (!allPackagesInOrderReturned && filterOnStates.includes(getHighestStateFromShipment(consignments.toSet())))
    )
  })
  return {
    showAllCount: consignmentsByOrderId.count(),
    showCollectedCount: showCollectedCount,
    showDeliveredCount: showDeliveredCount,
    showDeviatedCount: showDeviatedCount,
    showNotCollectedCount: showNotCollectedCount,
    filteredConsignmentsByOrderId: filteredConsignmentsByOrderId
  }
}

export const areAllPackagesInOrderReturned = (
  consignments: Collection<ConsignmentIdType, Consignment>,
  consignmentEventsMap: ConsignmentEventsMapType
) =>
  consignments.every((consignment) => {
    const consignmentEvents = consignmentEventsMap.get(consignment.get('id'))
    return isNotNullOrUndefined(consignmentEvents) && isConsignmentReturned(consignmentEvents.toList())
  })

export const isOrderCredited = (
  consignments: Collection<ConsignmentIdType, Consignment>,
  consignmentEventsMap: ConsignmentEventsMapType
) =>
  !!consignments.find((consignment) => {
    const consignmentEvents = consignmentEventsMap.get(consignment.get('id'))
    return isNotNullOrUndefined(consignmentEvents) && isConsignmentCredited(consignmentEvents.toList())
  })
