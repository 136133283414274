import { eventDataValue } from '@glow/entity-types'
import i18next from 'i18next'
import { Map } from 'immutable'
import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { ConsignmentEventConfirmed } from '../../../../components/ConsignmentEventConfirmed'
import { getEventWithCustomerUniqueDeviationSelector } from '../../../../selectors/customerOrderDeviationsSelector'
import { IConsignmentEvent } from '../../../../types/coreEntitiesTypes'
import { ImmutableMap } from '../../../../types/immutableTypes'
import { DeviationOrderPropsType } from '../../../admin/orderDeviationTypes'
import { EventMap } from './event-map'
import { EventRecipient } from './event-recipient'
import { EventSelfBilling } from './event-selfbilling'

interface EventDeviatedProps {
  consignmentEvent: ImmutableMap<IConsignmentEvent>
  deviations: Map<number, DeviationOrderPropsType>
  data: AnyData
}

export function EventDeviated(props: EventDeviatedProps) {
  return (
    <>
      <EventDeviatedText {...props} />
      <EventComment data={props.data} />
      <EventMap data={props.data} />
      <EventRecipientUnitAndDriver data={props.data} />
      <ConsignmentEventConfirmed
        consignmentEventId={props.consignmentEvent.get('id')}
        consignmentEventType={props.consignmentEvent.get('type')}
      />
      <EventSelfBilling data={props.data} />
    </>
  )
}

function EventDeviatedText(props: EventDeviatedProps) {
  const deviatedText = useEventDeviatedText(props.deviations, props.consignmentEvent)
  const deviationCode = eventDataValue(props.data, ['deviation'])

  if (deviationCode) {
    return (
      <p className="mb-0">
        <strong>{deviationCode}: </strong>
        {deviatedText}
      </p>
    )
  }

  return null
}

export const useEventDeviatedText = (
  deviations: Map<number, DeviationOrderPropsType>,
  event: ImmutableMap<IConsignmentEvent>
) => {
  const eventWithDeviations = getEventWithCustomerUniqueDeviationSelector(event, deviations)

  const data = eventWithDeviations?.get('data')
  const deviationCode = data ? data.get('deviation') : null

  const customerUniqueDeviation = data ? data.get('customerUniqueDeviation') : null

  return getDeviationText(customerUniqueDeviation, deviationCode)
}

const EventComment = (props: { data: AnyData }) => {
  const deviatedComment = eventDataValue(props.data, ['comment'])

  return (
    <>
      {deviatedComment && (
        <div>
          <p className="text-sm text-emphasis-medium mb-1">{i18next.t('consignmentEvent.message.comment')}</p>
          <p className="text-sm text-emphasis-high">{deviatedComment}</p>
        </div>
      )}
    </>
  )
}

export const EventRecipientUnitAndDriver = (props: { data: AnyData; labelForRecipient?: string }) => {
  const unit = eventDataValue(props.data, ['unitName'])
  const driver = eventDataValue(props.data, ['userName'])

  return (
    <>
      {unit && (
        <div className="grid grid-flow-col gap-x-6 auto-cols-max">
          <EventRecipient data={props.data} label={props.labelForRecipient} />
          <div>
            <p className="text-sm text-emphasis-medium mb-1">{i18next.t('consignmentEvent.message.unitName')}</p>
            <p className="text-emphasis-high">{unit}</p>
          </div>
          <div>
            <p className="text-sm text-emphasis-medium mb-1">{i18next.t('consignmentEvent.message.driverName')}</p>
            <p className="text-emphasis-high">{driver}</p>
          </div>
        </div>
      )}
    </>
  )
}

const getDeviationText = (
  customerUniqueDeviation: AnyData | null,
  deviationCode: string | null | undefined
): string => {
  const currentLng = i18next.language.charAt(0).toUpperCase() + i18next.language.charAt(1)
  const courierDeviationKey = 'courier.deviation.' + deviationCode
  const description = i18next.t(courierDeviationKey)
  return customerUniqueDeviation
    ? customerUniqueDeviation.get('description' + currentLng)
    : deviationCode && i18next.exists(courierDeviationKey)
      ? description
      : deviationCode
        ? deviationCode
        : ''
}
