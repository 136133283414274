import React from 'react'
import styled from 'styled-components'
import variables from '../../../../styles/variables'
import { FlexRow } from '../../../../primitives/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const ErrorNotification: React.FC<{ message: string }> = ({ message }) => (
  <ErrorBox>
    <FlexRow justifyContent="center">
      <FontAwesomeIcon
        style={{ marginLeft: '5px', marginRight: '10px', marginTop: '2px' }}
        icon="exclamation-triangle"
        color={variables.newColors.black}
      />
      {message}
    </FlexRow>
  </ErrorBox>
)

const ErrorBox = styled.div`
  border-radius: 10px;
  padding: 0.7em;
  margin-bottom: 2em;
  background: ${variables.newColors.lightRed};
`
