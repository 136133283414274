import { GoogleMap, Marker, MarkerClusterer, TrafficLayer, useLoadScript } from '@react-google-maps/api'
import React, { CSSProperties, ReactNode } from 'react'
import { isNotEmpty } from '../utils/collectionUtils'
import ImmutableComponent from '../primitives/ImmutableComponent'
import throttle from 'lodash/throttle'
import {
  generateMarkersFromCourierLocations,
  generateMarkersFromCouriers,
  generateMarkersFromShipments,
  generateMarkersFromWaypoints
} from '../utils/googleMapsMarkers'
import { SlotPolygon } from './SlotPolygon'
import { MarkerWithInfoWindow } from './MarkerWithInfoWindow'
import { Collection, List, Map, Set } from 'immutable'
import RouteRenderer from './RouteRenderer'
import { ROLE_PLANNER } from '../utils/roles'
import { courierIcon, GOOGLE_MAP_PROPS } from '../utils/mapUtils'
import { CustomerMarkerWithInfoWindow } from './CustomerMarkerWithInfoWindow'
import { generateCustomerMarkersFromShipments } from '../utils/googleMapsCustomerMarkers'
import { Loading } from './Loading'
import { Consignment, CourierLocation, Slot } from '../types/coreEntitiesTypes'
import { WayPoint } from '../query/liveViewQuery'
import { MapAddress, MapLocation } from '../types/mapTypes'

const options = (interactive: boolean, zoom: number | null, maxZoom: number, allowFullscreen: boolean) => {
  return {
    zoom: zoom,
    maxZoom: maxZoom,
    clickableIcons: false,
    fullscreenControl: allowFullscreen,
    mapTypeControl: false,
    streetViewControl: false,
    gestureHandling: interactive ? 'auto' : 'none',
    disableDefaultUI: !interactive,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      }
    ]
  }
}

function createNewMarkerElements(markers: any[], interactive: boolean): any {
  return markers.map((marker) => (clusterer: any) => (
    <CustomerMarkerWithInfoWindow
      showCount={true}
      key={marker.id}
      lat={marker.lat}
      lng={marker.lng}
      count={(marker.states || []).length}
      heading={marker.heading}
      zIndex={marker.zIndex}
      interactive={interactive}
      states={marker.states}
      information={marker.information || []}
      clusterer={clusterer}
    />
  ))
}

function createMarkerElements(markers: any[], interactive: boolean): any {
  return markers.map((marker) => (clusterer: any) => (
    <MarkerWithInfoWindow
      id={marker.id}
      ids={marker.ids || []}
      key={marker.id}
      lat={marker.lat}
      lng={marker.lng}
      icon={marker.icon}
      colors={marker.colors}
      display={marker.display}
      heading={marker.heading}
      zIndex={marker.zIndex}
      interactive={interactive}
      information={marker.information}
      title={marker.title}
      clusterer={clusterer}
    />
  ))
}

interface Props {
  interactive?: boolean
  showTraffic?: boolean
  showWaypoints?: boolean
  location: MapLocation | MapAddress
  style: CSSProperties
  setMapPosition?: any
  shipments?: List<Collection<number, Consignment>>
  slots?: any
  courierLocations?: Map<number, CourierLocation>
  showSlots?: boolean
  showUnassigned?: boolean
  useMarkerClusterer?: boolean
  highlightShipments?: any
  updateLocation?: boolean
  showMarkerOnLocation?: boolean
  selectedSlot?: any
  updateAreaInSlot?: (_slot: number, area: any) => void
  minNumberOfMarkersToCluster?: number
  allowFullscreen?: boolean
  showRoutes?: boolean
  allowEdit?: boolean
  push?: (url: string) => void
  onClickSlot?: (slot: Slot) => void
  waypoints?: List<WayPoint>
  useNewMarkers?: boolean
  assignToSlotSelector?: (shipmentId: number) => JSX.Element
  onClickEmptySpace?: (location: MapLocation) => void
  searchAroundArea?: MapLocation
  children?: ReactNode
}

interface State {
  centerSet: boolean
}
export default class GoogleMaps extends ImmutableComponent<Props> {
  private _mapRef: any
  constructor(props: Props) {
    super(props)
    this.state = {
      centerSet: false
    }
    this.handleBoundsChanged = throttle(this.handleBoundsChanged.bind(this), 1000)
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.location && !state.centerSet) {
      return {
        centerSet: true
      }
    }
    return {}
  }

  handleMapMounted(
    c: any,
    markers: ((clusterer?: any) => React.JSX.Element)[],
    polygons: Map<unknown, unknown> | List<React.JSX.Element>,
    location: MapLocation | MapAddress,
    fitMapToMarkersAndSlots: boolean
  ) {
    if (!c || this._mapRef) return
    this._mapRef = c
    this.fitBoundsToContent(c, markers, polygons, location, fitMapToMarkersAndSlots)
  }

  fitBoundsToContent(
    c: { fitBounds: (arg0: google.maps.LatLngBounds) => void },
    markers: any[],
    polygons: any[] | Map<unknown, unknown> | List<React.JSX.Element>,
    location: any,
    fitMapToMarkersAndSlots: boolean
  ) {
    if (!fitMapToMarkersAndSlots) {
      return
    }

    const newBounds = new window.google.maps.LatLngBounds()
    newBounds.extend({ lat: location.lat, lng: location.lng })
    markers.forEach((marker) => {
      const m = marker()
      newBounds.extend({ lat: m.props.lat, lng: m.props.lng })
    })
    polygons.forEach((polygon) => {
      const area = polygon.props.slot.get('area')
      if (area) {
        area.forEach((position: any) => {
          newBounds.extend({ lat: position.get('lat'), lng: position.get('lng') })
        })
      }
    })
    c.fitBounds(newBounds)
  }

  handleBoundsChanged() {
    if (!this._mapRef || !this._mapRef.getBounds()) return

    if (this.props.setMapPosition) {
      const bounds = this._mapRef.getBounds()
      if (!bounds) return
      const northEast = bounds.getNorthEast()
      const southWest = bounds.getSouthWest()
      this.props.setMapPosition({
        lat: this._mapRef.getCenter().lat(),
        lng: this._mapRef.getCenter().lng(),
        zoom: this._mapRef.getZoom(),
        bounds: {
          northEast: {
            lat: northEast.lat(),
            lng: northEast.lng()
          },
          southWest: {
            lat: southWest.lat(),
            lng: southWest.lng()
          }
        }
      })
    }
  }

  handleOnClickEmptySpace(e: any) {
    this.props.onClickEmptySpace &&
      this.props.onClickEmptySpace({
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      })
  }

  render() {
    let {
      location,
      shipments = List(),
      waypoints = List(),
      slots = List(),
      highlightShipments = Set(),
      showUnassigned = true,
      showTraffic = false,
      showSlots = true,
      onClickSlot = () => {},
      interactive = false,
      updateLocation = false,
      showMarkerOnLocation = false,
      showWaypoints = true,
      selectedSlot,
      showRoutes,
      allowEdit = true,
      useMarkerClusterer = false,
      minNumberOfMarkersToCluster = 100,
      useNewMarkers = false,
      assignToSlotSelector,
      allowFullscreen = false,
      courierLocations = Map()
    } = this.props

    const hiddenSlot = Set(),
      couriers = List(),
      showPickups = true,
      showDeliveries = true,
      showAssigned = true,
      maxZoom = 16,
      fitMapToMarkersAndSlots = true,
      userRole = ROLE_PLANNER,
      useSlotColors = true

    if (!location || !(location as MapLocation).lat || !(location as MapLocation).lng) {
      console.error('Missing default location, will not show any map')
      return null
    }

    const polygons = showSlots
      ? slots
          .filter((slot: any) => slot.get('area'))
          .filter((c: any) => !hiddenSlot.has(c.get('id')))
          .map((slot: any) => (
            <SlotPolygon
              key={slot.get('id')}
              slot={slot}
              selectedSlot={selectedSlot ?? Map()}
              onClick={() => onClickSlot(slot)}
              onChange={(area) => this.props.updateAreaInSlot?.(slot.get('id'), area)}
            />
          ))
      : Map()

    const waypointMarkers = showWaypoints ? generateMarkersFromWaypoints(waypoints, slots, useSlotColors) : []

    const markableShipments =
      highlightShipments.count() > 0
        ? shipments.filter((c) => highlightShipments.contains(c.first()?.get('orderId') ?? 0))
        : shipments

    const m = useNewMarkers
      ? generateCustomerMarkersFromShipments(markableShipments, showDeliveries, showPickups, true, slots, allowEdit)
      : generateMarkersFromShipments(
          markableShipments,
          slots,
          showPickups,
          showDeliveries,
          showAssigned,
          showUnassigned,
          allowEdit,
          useSlotColors,
          assignToSlotSelector
        )
    const markers = useNewMarkers
      ? createNewMarkerElements(m.concat(waypointMarkers), interactive)
      : createMarkerElements(m.concat(waypointMarkers), interactive)

    const courierMarkers = courierLocations.isEmpty()
      ? createMarkerElements(generateMarkersFromCouriers(couriers, userRole, courierIcon), interactive)
      : createMarkerElements(generateMarkersFromCourierLocations(courierLocations, userRole, courierIcon), interactive)

    const allMarkers = markers.concat(courierMarkers)

    if (updateLocation && this._mapRef) {
      setTimeout(
        () => this.fitBoundsToContent(this._mapRef, allMarkers, polygons, location, fitMapToMarkersAndSlots),
        0
      )
    }

    const shouldUseClusterMarkerer =
      useMarkerClusterer &&
      isNotEmpty(markers) &&
      // @ts-ignore
      (markers.length > minNumberOfMarkersToCluster || markers.size > minNumberOfMarkersToCluster)

    const centerProp = updateLocation || !this.state.centerSet || !this._mapRef ? { center: location } : {}
    const zoom = !this.state.centerSet || !this._mapRef ? 12 : null

    return (
      <GoogleMapsWrapper
        mapContainerStyle={this.props.style || { height: '100%', width: '100%' }}
        options={options(interactive, zoom, maxZoom, allowFullscreen)}
        onClick={(e: any) => this.handleOnClickEmptySpace(e)}
        onMapMounted={(c: any) => this.handleMapMounted(c, allMarkers, polygons, location, fitMapToMarkersAndSlots)}
        onBoundsChanged={() => this.handleBoundsChanged()}
        {...centerProp}
      >
        {shouldUseClusterMarkerer && (
          <MarkerClusterer
            averageCenter={false}
            enableRetinaIcons={true}
            gridSize={60}
            minimumClusterSize={2}
            maxZoom={12}
            styles={[
              {
                url: '/maps/m1.svg',
                height: 53,
                width: 53
              }
            ]}
          >
            {(clusterer) => markers.map((marker: any) => marker(clusterer))}
          </MarkerClusterer>
        )}

        {!shouldUseClusterMarkerer && markers.map((marker: any) => marker())}

        {isNotEmpty(polygons) && polygons}

        {showMarkerOnLocation && <Marker key="locationMarker" position={location as any} />}

        {showTraffic && <TrafficLayer />}

        {isNotEmpty(courierMarkers) && courierMarkers.map((marker: any) => marker())}

        {showRoutes && (
          <RouteRenderer
            key={`routeRenderer_${selectedSlot?.get('id')}`}
            waypoints={waypoints as any}
            selectedSlot={selectedSlot}
          />
        )}

        {this.props.children}
      </GoogleMapsWrapper>
    )
  }
}

export const GoogleMapsWrapper = (props: any) => {
  const { onMapMounted, ...otherProps } = props
  // @ts-ignore
  const { isLoaded } = useLoadScript({ ...GOOGLE_MAP_PROPS })
  return isLoaded ? (
    <GoogleMap {...otherProps} onLoad={(map) => onMapMounted(map)}>
      {props.children}
    </GoogleMap>
  ) : (
    <Loading />
  )
}
