import { ContextType } from '../../pages/instant/InstantGrid'
import { DepartmentIdType, ISODateTimeString, OrderIdType } from '../../types/coreEntitiesTypes'
import { CustomerIdType } from '../../domain/customer'
import { useLocation } from 'react-router-dom'
import React from 'react'
import { format } from 'date-fns'
import { OrderType } from '@glow/entity-types'

export const bookingEditUrl = (
  contextType: ContextType,
  departmentOrCustomerId: DepartmentIdType | CustomerIdType | string,
  orderId: OrderIdType,
  orderType: OrderType | null
) =>
  orderType === 'Invoice' || orderType === 'Credit'
    ? `/${contextType}/${departmentOrCustomerId}/billing-order/edit/${orderId}`
    : `/${contextType}/${departmentOrCustomerId}/booking/edit/${orderId}`

export const bookingCopyUrl = (
  contextType: ContextType,
  departmentOrCustomerId: number | string,
  orderId: OrderIdType,
  recurringProcessedDate?: string,
  recurringTemplateId?: number,
  orderType?: OrderType | null | undefined
) => {
  if (orderType === 'Invoice') {
    return `/planner/${departmentOrCustomerId}/billing-order/copy/${orderId}`
  } else
    return `/${contextType}/${departmentOrCustomerId}/booking/copy/${orderId}${
      recurringTemplateId ? '/' + recurringTemplateId : ''
    }${recurringProcessedDate ? '/' + recurringProcessedDate : ''}`
}

export const bookingViewUrl = (
  departmentOrCustomerId: DepartmentIdType | CustomerIdType,
  orderId: OrderIdType,
  createdAt: ISODateTimeString
) => {
  const date = new Date(createdAt)
  const formattedDate = format(date, 'yyyy-MM-dd')

  return `/${ContextType.Planner}/${departmentOrCustomerId}/shipments/-/${formattedDate}/${formattedDate}/-/-/${orderId}`
}

export const bookingQuickEditUrl = (
  departmentOrCustomerId: DepartmentIdType | CustomerIdType | string,
  orderId: OrderIdType
) => `/${ContextType.Planner}/${departmentOrCustomerId}/shipments-search/${orderId}/details/quick-edit`

export const useQuery = () => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}
