import styled from 'styled-components'

export const ConsignmentEventsList = styled.ul`
  display: grid;
  gap: 0.5rem;
`
export const ConsignmentEventItem = styled.li`
  display: grid;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.25rem;
  background-color: var(--color-grey-100);
`
export const ConsignmentEventItemContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
`

export const ConsignmentEventItemTitle = styled.h2`
  @media (min-width: 768px) {
    margin-bottom: 0;
  }

  display: flex;
  margin-bottom: 1rem;
`

export const ConsignmentEventItemTime = styled.time`
  color: var(--text-emphasis-medium);
  text-align: right;
`

export const H2 = styled.h2`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  color: var(--color-emphasis-high);
`

export const ListContainer = styled.ul`
  gap: 1rem;
  display: grid;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 1rem;
`

export const DisclosureContainer = styled.div`
  display: grid;
  gap: 1rem;
  padding: 2rem;
  padding-top: 1rem;
  border-radius: 0.25rem;
  position: relative;
`

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  align-items: center;
`

export const ButtonTitle = styled.h2`
  display: grid;
  grid-template-columns: 2rem 1fr;
  color: var(--color-emphasis-high);
  align-items: center;
`

export const Container = styled.div`
  padding: 1.5rem 2.5rem;
`

export const ButtonText = styled.span`
  color: var(--text-emphasis-extra);
  padding: 1rem;
  /* -m-4 */
`

export const ConsignmentDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
`
