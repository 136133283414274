import React from 'react'
import { Polygon } from '@react-google-maps/api'
import { slotStates } from '../domain/slot'
import ImmutableComponent from '../primitives/ImmutableComponent'
import { Slot } from '../types/coreEntitiesTypes'

type Props = {
  slot: Slot
  selectedSlot: Slot
  onClick: (e: google.maps.MapMouseEvent) => void
  onChange: (area: { lat: number; lng: number }[] | undefined) => void
}
export class SlotPolygon extends ImmutableComponent<Props> {
  private ref: google.maps.Polygon | undefined

  render() {
    const { slot, selectedSlot, onClick } = this.props

    const anySlotIsSelected = selectedSlot !== undefined
    const thisSlotIsSelected = anySlotIsSelected && selectedSlot.get('id') === slot.get('id')
    const editable =
      thisSlotIsSelected &&
      slotStates[slot.get('state') as keyof typeof slotStates] < slotStates.IN_PROGRESS &&
      !slot.get('locked')

    return (
      <Polygon
        onLoad={(ref) => {
          this.ref = ref
        }}
        onClick={onClick}
        path={(slot.get('area')?.toJS() || []) as { lat: number; lng: number }[]}
        options={{
          strokeColor: (anySlotIsSelected && thisSlotIsSelected) || !anySlotIsSelected ? slot.get('color') : 'gray',
          strokeOpacity: (anySlotIsSelected && thisSlotIsSelected) || !anySlotIsSelected ? 0.8 : 0.2,
          fillColor: (anySlotIsSelected && thisSlotIsSelected) || !anySlotIsSelected ? slot.get('color') : 'gray',
          fillOpacity: (anySlotIsSelected && thisSlotIsSelected) || !anySlotIsSelected ? 0.3 : 0.15,
          zIndex: thisSlotIsSelected ? 1000 : 1,
          editable: editable,
          draggable: editable
        }}
        onMouseUp={() => {
          if (thisSlotIsSelected) {
            setTimeout(() => {
              //Let the polygon update before fetching area
              const area = this.ref
                ?.getPath()
                .getArray()
                .map((latLng) => {
                  return {
                    lat: latLng.lat(),
                    lng: latLng.lng()
                  }
                })
              this.props.onChange(area)
            }, 100)
          }
        }}
      />
    )
  }
}
