import { Map } from 'immutable'
import { ROUTER_LOCATION_CHANGE_ACTION, SET_REACT_RENDER_ERROR } from '../actions/actionTypes'
import { ErrorInfo } from 'react'

const initialState = Map()

interface ActionProps {
  type: typeof SET_REACT_RENDER_ERROR | typeof ROUTER_LOCATION_CHANGE_ACTION
  error: Error
  info: ErrorInfo
}

export const reactRenderError = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case SET_REACT_RENDER_ERROR:
      return state.merge(Map({ error: action.error, info: action.info }))
    case ROUTER_LOCATION_CHANGE_ACTION:
      return state.delete('error').delete('info')
    default:
      return state
  }
}
