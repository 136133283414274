import React, { CSSProperties } from 'react'
import { ActionMeta, components, default as ReactSelect } from 'react-select'
import { Seq } from 'immutable'
import { isNotNullOrUndefined } from '../types/immutableTypes'
import i18next from 'i18next'
import vars from '../styles/variables'
import { StyledCheckbox } from './Forms'

interface Props {
  options: Seq.Keyed<string | number, string>
  onSelection: (selectedKeys: Seq.Indexed<any>) => void
  getPlaceholderText?: (selectedLabels: Seq.Indexed<string>) => string
  placeholder?: string
  style?: CSSProperties
  allowSelectAll?: boolean
  shouldClearSelection?: boolean
  allSelectedByDefault?: boolean
  useStandardClearButtonStyles?: boolean
  useStandardGreenForCheckBoxes?: boolean
}

interface StateProps {
  selection: Seq.Indexed<ReactSelectOption>
}

export class MultiSelectKeyed extends React.Component<Props, StateProps> {
  constructor(props: Props) {
    super(props)
    this.state = { selection: Seq.Indexed() }
  }

  componentDidMount() {
    if (this.props.allSelectedByDefault) {
      this.setState({ selection: this.options().concat(allOption) })
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!prevProps.shouldClearSelection && this.props.shouldClearSelection) {
      this.setState({ selection: Seq.Indexed() })
    }
  }

  private onChange(selected: ReactSelectOption[], action: ActionMeta<ReactSelectOption>): void {
    if (!this.props.allowSelectAll) {
      return this.setSelection(Seq.Indexed(selected))
    }

    if (isNotNullOrUndefined(selected) && selected.length > 0) {
      if (selected[0] === allOption && action.action === 'select-option') {
        return this.setSelection(this.options().concat(allOption))
      }

      if (selected.length === this.options().count()) {
        if (selected.includes(allOption)) {
          return this.setSelection(
            Seq.Indexed(selected).filter((option: ReactSelectOption) => option.value !== allOption.value)
          )
        } else if (action.action === 'select-option') {
          return this.setSelection(this.options().concat(allOption))
        }

        return this.setSelection(Seq.Indexed())
      }
    }

    return this.setSelection(Seq.Indexed(selected))
  }

  private setSelection(selected: Seq.Indexed<ReactSelectOption>): void {
    const selectedKeys = selected.map((s) => s.key)
    this.setState({ selection: selected })
    this.props.onSelection(selectedKeys)
  }

  private options(): Seq.Indexed<ReactSelectOption> {
    return this.props.options.map(toReactSelectOption).valueSeq()
  }

  render() {
    const { selection } = this.state
    return (
      <div style={this.props.style}>
        <ReactSelect
          options={
            this.props.allowSelectAll && this.options().count() > 0
              ? [allOption, ...this.options().toArray()]
              : this.options().toArray()
          }
          noOptionsMessage={() => i18next.t('application.noOptions')}
          placeholder={
            (this.props.getPlaceholderText && this.props.getPlaceholderText(selection.map((it) => it.label))) ||
            this.props.placeholder
          }
          styles={customStyles(this.props.useStandardClearButtonStyles)}
          theme={customTheme}
          value={selection?.toArray()}
          components={{
            Option: (props) =>
              CustomOption({ ...props, useStandardGreenForCheckBoxes: this.props.useStandardGreenForCheckBoxes }),
            ClearIndicator: CustomClearIndicator
          }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          onChange={(selected, action) => this.onChange(selected as ReactSelectOption[], action)}
          isMulti
          tabSelectsValue={false}
          backspaceRemovesValue={false}
          closeMenuOnSelect={false}
          defaultValue={this.props.allSelectedByDefault ? selection?.toArray() : null}
          menuPortalTarget={document.body}
        />
      </div>
    )
  }
}

interface ReactSelectOption {
  key: string
  value: string
  label: string
}

export const allOption = { value: '*', label: i18next.t('application.selectAll') } as ReactSelectOption
const toReactSelectOption = (option: string, key: string | number) =>
  ({ key: key, value: option, label: option }) as ReactSelectOption

const customStyles = (useStandardClearIndicatorStyles?: boolean) => {
  return {
    option: (provided: any) => ({
      ...provided,
      background: `${vars.colors.white}`,
      color: `${vars.colors.gray9}`,
      '&:hover': {
        background: `${vars.newColors.moonGrey}`
      },
      cursor: 'pointer'
    }),
    dropdownIndicator: (provided: any) => ({ ...provided, cursor: 'pointer' }),
    multiValueRemove: (provided: any) => ({ ...provided, cursor: 'pointer' }),
    valueContainer: (provided: any) => ({ ...provided, cursor: 'text' }),
    menu: (provided: any) => ({ ...provided, minWidth: provided.width, width: 'max-content', maxWidth: '480px' }),
    clearIndicator: (provided: any) => ({
      ...provided,
      fontSize: '12px',
      cursor: 'pointer',
      color: `${useStandardClearIndicatorStyles ? vars.newColors.darkGrey : vars.colors.gray6}`,
      backgroundColor: `${useStandardClearIndicatorStyles ? vars.newColors.orbitGrey : vars.colors.warningHoverRow}`,
      padding: '0 5px',
      marginRight: '12px',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center'
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: Number.MAX_SAFE_INTEGER })
  }
}

const customTheme = (theme: any) => ({
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    primary25: vars.newColors.lightGreen,
    primary: vars.colors.gray13,
    dangerLight: vars.newColors.lightRed,
    danger: vars.newColors.intenseRed
  }
})

const CustomOption = (props: any) => (
  <components.Option {...props} style={{ display: 'flex' }}>
    <StyledCheckbox
      checked={props.isSelected}
      onChange={() => null}
      useStandardGreen={props.useStandardGreenForCheckBoxes}
    />
    <span />
    <label style={{ cursor: 'pointer' }}>{props.label}</label>
  </components.Option>
)

const CustomClearIndicator = (props: any) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps }
  } = props
  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      <span>Clear all</span>
      <span style={{ fontSize: '14px', fontWeight: 'bold', paddingBottom: '2px', marginLeft: '4px' }}>x</span>
    </div>
  )
}
