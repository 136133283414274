import { AnyData } from '../../actions/creators/baseHelpers'
import { DepartmentIdType } from '../../types/coreEntitiesTypes'
import { ShipmentForShipmentModal } from './shared/Shipment'
import { isNotEmpty } from '../../utils/stringUtils'
import { isNotNullOrUndefined } from '../../types/immutableTypes'

export interface QuickEditFormProps {
  pickupName: string | null
  pickupAddress: string | null
  pickupZipArea: string | null
  pickupZipCode: string | null
  pickupCountry: string | null
  pickupLat: number | null
  pickupLng: number | null
  pickupPhone: string | null
  pickupEmail: string | null
  pickupInstructions: string | null
  deliveryName: string | null
  deliveryAddress: string | null
  deliveryZipArea: string | null
  deliveryZipCode: string | null
  deliveryCountry: string | null
  deliveryLat: number | null
  deliveryLng: number | null
  deliveryPhone: string | null
  deliverySecondPhone: string | null
  deliveryEmail: string | null
  deliveryInstructions: string | null
  orderNote: string | null
}

export const initialValuesForEditShipment = (shipment: ShipmentForShipmentModal): QuickEditFormProps => ({
  pickupName: shipment.pickup.sender.name || '',
  pickupAddress: shipment.pickup.sender.address.streetName || '',
  pickupZipArea: shipment.pickup.sender.address.area || '',
  pickupZipCode: shipment.pickup.sender.address.postalCode || '',
  pickupCountry: shipment.pickup.sender.address.country || '',
  pickupLat: shipment.pickup.sender.address.lat || null,
  pickupLng: shipment.pickup.sender.address.lng || null,
  pickupPhone: shipment.pickup.sender.phone || '',
  pickupEmail: shipment.pickup.sender.email || '',
  pickupInstructions: shipment.pickup.pickupInstructions || '',
  deliveryName: shipment.delivery.receiver.name || '',
  deliveryAddress: shipment.delivery.receiver.address.streetName || '',
  deliveryZipArea: shipment.delivery.receiver.address.area || '',
  deliveryZipCode: shipment.delivery.receiver.address.postalCode || '',
  deliveryCountry: shipment.delivery.receiver.address.country || '',
  deliveryLat: shipment.delivery.receiver.address.lat || null,
  deliveryLng: shipment.delivery.receiver.address.lng || null,
  deliveryPhone: shipment.delivery.receiver.phone || '',
  deliverySecondPhone: shipment.delivery.receiver.secondPhone || '',
  deliveryEmail: shipment.delivery.receiver.email || '',
  deliveryInstructions: shipment.delivery.deliveryInstructions || '',
  orderNote: shipment.internalNote || ''
})

export const getPayloadForEditShipment = (
  formValues: QuickEditFormProps,
  shipment: ShipmentForShipmentModal,
  departmentId: DepartmentIdType
) => {
  let payload: AnyData = {
    departmentId,
    orderId: shipment.orderId
  }

  if (isShipmentFieldUpdated(formValues.pickupInstructions, shipment.pickup.pickupInstructions)) {
    payload = { ...payload, pickupInstructions: formValues.pickupInstructions }
  }
  if (isShipmentFieldUpdated(formValues.deliveryInstructions, shipment.delivery.deliveryInstructions)) {
    payload = { ...payload, deliveryInstructions: formValues.deliveryInstructions }
  }
  if (isShipmentFieldUpdated(formValues.pickupName, shipment.pickup.sender.name)) {
    payload = { ...payload, pickupName: formValues.pickupName }
  }
  if (isShipmentFieldUpdated(formValues.deliveryName, shipment.delivery.receiver.name)) {
    payload = { ...payload, deliveryName: formValues.deliveryName }
  }
  if (isShipmentFieldUpdated(formValues.pickupPhone, shipment.pickup.sender.phone)) {
    payload = { ...payload, pickupPhone: formValues.pickupPhone }
  }
  if (isShipmentFieldUpdated(formValues.pickupEmail, shipment.pickup.sender.email)) {
    payload = { ...payload, pickupEmail: formValues.pickupEmail }
  }
  if (isShipmentFieldUpdated(formValues.deliveryPhone, shipment.delivery.receiver.phone)) {
    payload = { ...payload, deliveryPhone: formValues.deliveryPhone }
  }
  if (isShipmentFieldUpdated(formValues.deliverySecondPhone, shipment.delivery.receiver.secondPhone)) {
    payload = { ...payload, deliverySecondPhone: formValues.deliverySecondPhone }
  }
  if (isShipmentFieldUpdated(formValues.deliveryEmail, shipment.delivery.receiver.email)) {
    payload = { ...payload, deliveryEmail: formValues.deliveryEmail }
  }
  if (isShipmentFieldUpdated(formValues.pickupAddress, shipment.pickup.sender.address.streetName)) {
    payload = { ...payload, pickupAddress: formValues.pickupAddress }
  }
  if (isShipmentFieldUpdated(formValues.pickupZipArea, shipment.pickup.sender.address.area)) {
    payload = { ...payload, pickupZipArea: formValues.pickupZipArea }
  }
  if (isShipmentFieldUpdated(formValues.pickupZipCode, shipment.pickup.sender.address.postalCode)) {
    payload = { ...payload, pickupZipCode: formValues.pickupZipCode }
  }
  if (isShipmentFieldUpdated(formValues.pickupCountry, shipment.pickup.sender.address.country)) {
    payload = { ...payload, pickupCountry: formValues.pickupCountry }
  }
  if (isShipmentFieldUpdated(formValues.pickupLat, shipment.pickup.sender.address.lat)) {
    payload = { ...payload, pickupLat: formValues.pickupLat }
  }
  if (isShipmentFieldUpdated(formValues.pickupLng, shipment.pickup.sender.address.lng)) {
    payload = { ...payload, pickupLng: formValues.pickupLng }
  }
  if (isShipmentFieldUpdated(formValues.deliveryAddress, shipment.delivery.receiver.address.streetName)) {
    payload = { ...payload, deliveryAddress: formValues.deliveryAddress }
  }
  if (isShipmentFieldUpdated(formValues.deliveryZipArea, shipment.delivery.receiver.address.area)) {
    payload = { ...payload, deliveryZipArea: formValues.deliveryZipArea }
  }
  if (isShipmentFieldUpdated(formValues.deliveryZipCode, shipment.delivery.receiver.address.postalCode)) {
    payload = { ...payload, deliveryZipCode: formValues.deliveryZipCode }
  }
  if (isShipmentFieldUpdated(formValues.deliveryCountry, shipment.delivery.receiver.address.country)) {
    payload = { ...payload, deliveryCountry: formValues.deliveryCountry }
  }
  if (isShipmentFieldUpdated(formValues.deliveryLat, shipment.delivery.receiver.address.lat)) {
    payload = { ...payload, deliveryLat: formValues.deliveryLat }
  }
  if (isShipmentFieldUpdated(formValues.deliveryLng, shipment.delivery.receiver.address.lng)) {
    payload = { ...payload, deliveryLng: formValues.deliveryLng }
  }
  if (isShipmentFieldUpdated(formValues.orderNote, shipment.internalNote)) {
    payload = { ...payload, orderNote: formValues.orderNote }
  }

  return payload
}

export const isShipmentFieldUpdated = (
  formValue: string | number | null | undefined,
  currentValue: string | number | null | undefined
) =>
  formValue !== currentValue &&
  ((typeof formValue === 'string' && isNotEmpty(formValue)) || isNotNullOrUndefined(currentValue))
