import { Link } from 'react-router-dom'
import styled from 'styled-components'
import vars from '../styles/variables'

const linkStyling = `
  text-decoration: none;
  color: ${vars.colors.black};
  border-bottom: 2px solid var(--color-primary-300);
  cursor:pointer;

  &:hover, &:focus {
    border-bottom: 2px solid ${vars.colors.black};
  }
`

export const ExternalLink = styled.a`
  font-size: 1.125rem;
  text-decoration: none;
  color: ${vars.colors.link};
  border-bottom: 2px solid ${vars.colors.link};
  cursor: pointer;

  &:hover,
  &:focus {
    border-bottom: 2px solid ${vars.colors.black};
  }
`

export const StyledLink = styled(Link)`
  ${linkStyling}
`

export const StyledAnchor = styled.a`
  ${linkStyling}
`

export const HorizontalRule = styled.hr<{ white?: boolean }>`
  border: 1px solid ${(props) => (props.white ? vars.colors.white : vars.colors.grayDark)};
`

export const SmallText = styled.p<ISmallText>`
  font-size: 0.8rem;
  font-weight: ${(props) => (props.strong ? 700 : 0)};
  display: ${(props) => (props.block ? 'block' : props.inline && 'inline')};
  color: ${(props) => (props.red ? vars.newColors.deepRed : props.white ? vars.colors.white : vars.colors.gray9)};
  text-align: ${(props) => (props.left && 'left') || (props.right && 'right')};
  ${(props) => props.noMargin && 'margin: 0px;'}
`

interface ISmallText {
  strong?: boolean
  inline?: boolean
  red?: boolean
  white?: boolean
  left?: boolean
  right?: boolean
  block?: boolean
  noMargin?: boolean
}

export const VerticalLine = styled.div`
  border-left: 1px solid #e1e1e1;
  height: 60%;
  margin-left: -3px;
  top: 0;
`

export const Dot = styled.span<{ color?: string; small?: boolean }>`
  height: ${(props) => (props.small ? '7px' : '10px')};
  width: ${(props) => (props.small ? '7px' : '10px')};
  background-color: ${(props) => props.color || '#bbb'};
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`
