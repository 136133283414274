import React from 'react'
import { FlexColumn, FlexRow } from '../../../../primitives/Flex'
import { ISODateString, ISOTimeString } from '../../../../types/coreEntitiesTypes'
import i18next from 'i18next'
import { SmallTertiaryButton } from './bookingTimeCommonStyles'
import { DeliveryTime } from '../../shared/Shipment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import variables from '../../../../styles/variables'
import { H2 } from '../../../../primitives/Headings'
import styled from 'styled-components'
import TimePicker from '../../../../pages/instant/TimePicker'
import { DateTime } from 'luxon'
import { parseDateTime } from '../../../../utils/dateTime'
import DatePicker from '../../../../pages/instant/DatePicker'

export const CustomTimeWindowView: React.FC<{
  selectedDeliveryTime: DeliveryTime | null
  setSelectedDeliveryTime: (deliveryTime: DeliveryTime | null) => void
  toggleCustomTimeView: () => void
}> = ({ selectedDeliveryTime, setSelectedDeliveryTime, toggleCustomTimeView }) => {
  return (
    <FlexColumn>
      <FlexRow>
        <SmallTertiaryButton onClick={toggleCustomTimeView}>
          <FontAwesomeIcon
            style={{ marginRight: '0.5em', marginTop: '2px' }}
            icon="arrow-left"
            color={variables.newColors.darkGrey}
          />
          {i18next.t('bookingTimeWindow.backFromCustomTimeButton')}
        </SmallTertiaryButton>
      </FlexRow>
      <CustomTimeContent>
        <H2 size="s">{i18next.t('bookingTimeWindow.customTimeTitle')}</H2>
        <EditDateTime selectedDeliveryTime={selectedDeliveryTime} setSelectedDeliveryTime={setSelectedDeliveryTime} />
      </CustomTimeContent>
    </FlexColumn>
  )
}

const EditDateTime: React.FC<{
  selectedDeliveryTime: DeliveryTime | null
  setSelectedDeliveryTime: (deliveryTime: DeliveryTime | null) => void
}> = ({ selectedDeliveryTime, setSelectedDeliveryTime }) => {
  const setCustomDate = (isoDate: ISODateString) => {
    setSelectedDeliveryTime({
      earliest: selectedDeliveryTime
        ? parseDateTime(isoDate, selectedDeliveryTime.earliest.toISOTime())
        : DateTime.fromISO(isoDate),
      latest: selectedDeliveryTime
        ? parseDateTime(isoDate, selectedDeliveryTime.latest.toISOTime())
        : DateTime.fromISO(isoDate)
    })
  }

  const setCustomTimeEarliest = (isoTime: ISOTimeString) => {
    const newTime = parseDateTime((selectedDeliveryTime?.earliest || DateTime.now()).toISODate(), isoTime)
    setSelectedDeliveryTime({
      earliest: newTime,
      latest: selectedDeliveryTime?.latest || newTime
    })
  }

  const setCustomTimeLatest = (isoTime: ISOTimeString) => {
    const newTime = parseDateTime((selectedDeliveryTime?.latest || DateTime.now()).toISODate(), isoTime)
    setSelectedDeliveryTime({
      earliest: selectedDeliveryTime?.earliest || newTime,
      latest: newTime
    })
  }

  return (
    <FlexRow>
      <FlexColumn>
        <Label>{i18next.t('bookingTimeWindow.customDateLabel')}</Label>
        <DatePicker
          onChange={setCustomDate}
          value={selectedDeliveryTime?.earliest.toISODate()}
          style={customDatePickerStyle}
          dayPickerInputProps={{ disabledDays: [{ before: new Date() }] }}
        />
      </FlexColumn>
      <FlexColumn>
        <Label>{i18next.t('bookingTimeWindow.customTimeWindowLabel')}</Label>
        <FlexRow>
          <TimePicker
            onChange={setCustomTimeEarliest}
            value={selectedDeliveryTime?.earliest.toISOTime()}
            style={customTimePickerEarliestStyle}
          />
          <Dash>{'-'}</Dash>
          <TimePicker
            onChange={setCustomTimeLatest}
            value={selectedDeliveryTime?.latest.toISOTime()}
            style={customTimePickerLatestStyle}
          />
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  )
}

const CustomTimeContent = styled.div`
  margin: 2em 1px 19em 1px;
`

const Label = styled.div`
  font-size: 0.8rem;
  font-weight: 400;
  text-align: left;
  margin-bottom: 5px;
  color: ${variables.newColors.darkGrey};
`

const Dash = styled.div`
  padding-top: 5px;
`

const customDatePickerStyle = {
  padding: '0.0rem',
  width: '5.5rem',
  height: '2.0rem',
  marginRight: '1rem'
}

const customTimePickerEarliestStyle = {
  padding: '0.45rem',
  width: '3.5rem',
  marginRight: '0.5rem'
}

const customTimePickerLatestStyle = {
  ...customTimePickerEarliestStyle,
  marginLeft: '0.5rem'
}
