import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconFa, PlainButton } from '@glow/ui-components'
import i18next from 'i18next'
import { Collection, List, Map } from 'immutable'
import toString from 'lodash/toString'
import { Duration } from 'luxon'
import React from 'react'
import adjustedRightSvgIcon from '../../assets/icons/adjustedRight.svg'
import multilegSvgIcon from '../../assets/icons/multileg.svg'
import bicycleIconBlack from '../../assets/icons/vehicles/bicycleIconBlack.svg'
import bicycleIconGray from '../../assets/icons/vehicles/bicycleIconGray.svg'
import carIconBlack from '../../assets/icons/vehicles/carIconBlack.svg'
import carIconGray from '../../assets/icons/vehicles/carIconGray.svg'
import cargoBikeIconBlack from '../../assets/icons/vehicles/cargoBikeIconBlack.svg'
import cargoBikeIconGray from '../../assets/icons/vehicles/cargoBikeIconGray.svg'
import motorbikeIconBlack from '../../assets/icons/vehicles/motorbikeIconBlack.svg'
import motorbikeIconGray from '../../assets/icons/vehicles/motorbikeIconGray.svg'
import truckIconBlack from '../../assets/icons/vehicles/truckIconBlack.svg'
import truckIconGray from '../../assets/icons/vehicles/truckIconGray.svg'
import vanIconBlack from '../../assets/icons/vehicles/vanIconBlack.svg'
import vanIconGray from '../../assets/icons/vehicles/vanIconGray.svg'
import { customerDisplayTextLong } from '../../domain/customer'
import { MeasurementUnit, convert, formatAmountWithUnit, sum } from '../../domain/measurementUnits'
import { stopPropagating } from '../../pages/instant/InstantGridRow'
import {
  customerNumberAndSubcustomer,
  formatPrice,
  shouldShowPalletIcon,
  shouldShowPalletIconOnWeightCheck
} from '../../pages/instant/bookingOrderFunctions'
import { ValueAddedService } from '../../pages/instant/bookingOrderTypes'
import { TertiaryButton } from '../../primitives/Button'
import { Checkbox } from '../../primitives/Forms'
import { DEFAULT_ROW_HEIGHT_PX, Icon } from '../../primitives/InstantGridStyles'
import { Dot } from '../../primitives/Typography'
import variables from '../../styles/variables'
import { ConsignmentEvent, OrderIdType, Unit } from '../../types/coreEntitiesTypes'
import { isNullOrUndefined } from '../../types/immutableTypes'
import { notCollectedStates } from '../../utils/consignmentState'
import {
  diffInMinutesBetweenTwoDates,
  hourMinute,
  hourMinuteWithDateTime,
  isInThePast,
  toLocaleDatePreserveNull
} from '../../utils/dateTime'
import { deliveryDeviationCodes, isDeviationEvent, pickupDeviationCodes } from '../../utils/deviation'
import { ConsignmentStateIcon, CustomerConsignmentStateIcon } from '../../utils/getIconForConsignmentState'
import { isShipmentReturn } from '../../utils/hdUtils'
import { getOrderSourceText } from '../../utils/shipmentsUtils'
import { getDisplayNameForSlotState } from '../../utils/slotStates'

export interface GridTitleRenderParams {
  title: string
  callbackFn?: () => void
}

export interface GridRendererParams {
  column: string
  value: any
  row: Map<any, any>
  callbackFn?: (row: any, columnName?: string) => (event?: React.MouseEvent) => void
  isColumnSelected?: boolean
}

export interface GridGroupRendererParams {
  column: string
  rows: Collection<number, Map<string, any>>
}

export const timeDiffRenderer = ({ row }: GridRendererParams) => {
  const diff = diffInMinutesBetweenTwoDates(row.get('arrival'), row.get('previousArrival'))
  return diff == 0 ? (
    <span />
  ) : (
    <span>
      <Icon src={adjustedRightSvgIcon} alt={'Adjusted time'} style={{ paddingTop: '0.3rem' }} />
      {diff >= 0 ? ' +' : ' '}
      {diff} min
    </span>
  )
}

export const timeRenderIfNotManual = ({ value, row }: GridRendererParams) => {
  if (row.get('isManualPlanning', false)) return <span />
  return <span>{hourMinute(value)}</span>
}

export const groupNameRenderer =
  (width: string) =>
  ({ value }: GridRendererParams) => (
    <span
      title={value}
      style={{ display: 'block', width: width, maxWidth: width, textOverflow: 'ellipsis', overflow: 'hidden' }}
    >
      {value}
    </span>
  )

export const timeRenderer = ({ value }: GridRendererParams) => <>{hourMinute(value)}</>

export const timeValueRenderer = ({ value }: GridRendererParams) => hourMinute(value)?.replace(':', '')

export const sourceRenderer = ({ value }: GridRendererParams) => <>{getOrderSourceText(value)}</>

export const alreadyAssignedRenderer = ({ value }: GridRendererParams) => (
  <span>{value ? i18next.t('grid.columns.dispatch.alreadyAssigned') : ''}</span>
)

export const booleanCheckRenderer = ({ value }: GridRendererParams) => (
  <span>{value ? <FontAwesomeIcon icon="check" /> : ''}</span>
)

export const timeWindowRenderer = ({ row }: GridRendererParams) => (
  <span>{`${hourMinute(row.get('start'))}-${hourMinute(row.get('stop'))}`}</span>
)

export const expiredRenderer = ({ value }: GridRendererParams) => (
  <span>{isInThePast(value) ? <FontAwesomeIcon icon="exclamation-triangle" /> : ''}</span>
)

export const pickupRenderer = ({ row }: GridRendererParams) => (
  <span>{`${hourMinuteWithDateTime(row.get('pickupTimeEarliestCached'))}-${hourMinuteWithDateTime(
    row.get('pickupTimeLatestCached')
  )}`}</span>
)

export const deliveryRenderer = ({ row }: GridRendererParams) => (
  <span>{`${hourMinuteWithDateTime(row.get('deliveryTimeEarliestCached'))}-${hourMinuteWithDateTime(
    row.get('deliveryTimeLatestCached')
  )}`}</span>
)

export const driverValueRenderer =
  (units: Map<number, Unit>) =>
  ({ value }: GridRendererParams) => {
    return (units.getIn([value, 'name']) as string) || ''
  }

export const driverRenderer = (units: Map<number, Unit>) => (params: GridRendererParams) => {
  const view = driverValueRenderer(units)(params)
  return <span title={view}>{view}</span>
}

export const daysValueRenderer = ({ row }: GridRendererParams) =>
  List([
    row.get('monday') && 'monday',
    row.get('tuesday') && 'tuesday',
    row.get('wednesday') && 'wednesday',
    row.get('thursday') && 'thursday',
    row.get('friday') && 'friday',
    row.get('saturday') && 'saturday',
    row.get('sunday') && 'sunday'
  ])
    .filter((v) => v)
    .map((d) => i18next.t(`weekdaysShort.${d}`))
    .join(', ')

export const daysRenderer = (params: GridRendererParams) => <span>{daysValueRenderer(params)}</span>

export const holidaysRenderer = ({ value }: GridRendererParams) => (
  <span>{value ? i18next.t('application.yes') : i18next.t('application.no')}</span>
)

export const frequencyRenderer = ({ value }: GridRendererParams) => (
  <span>{i18next.t(`instant.recurring.frequencies.${value.toLowerCase()}`)}</span>
)

export const airexpressFrequencyRenderer = ({ value }: GridRendererParams) => (
  <span>{i18next.t(`airexpress.recurring.frequencies.${value.toLowerCase()}`)}</span>
)

export const selectAllTitleRenderer = ({ title, callbackFn }: GridTitleRenderParams) => (
  <a
    style={{
      cursor: 'pointer',
      textDecoration: 'none',
      color: variables.colors.black
    }}
    href="#"
    onClick={(e) => {
      e.preventDefault()
      callbackFn && callbackFn()
    }}
  >
    {title}
  </a>
)

export const checkboxRenderer = ({ column, value, row, isColumnSelected, callbackFn }: GridRendererParams) => (
  <Checkbox
    id={row.get('id')}
    type="checkbox"
    value={`${value}`}
    checked={isColumnSelected === undefined ? value : isColumnSelected}
    onChange={callbackFn && callbackFn(row, column)}
    disabled={row.get('selectionDisabled', false) || false}
    onClick={stopPropagating()}
  />
)

export const groupNumberOfPickupsRenderer = ({ rows }: GridGroupRendererParams) => {
  const textKey = rows?.first(null)?.get('type') == 'pickup' ? 'courier.slotNumPickups' : 'courier.slotNumDeliveries'
  return <span>{i18next.t(textKey, { count: rows.count() })}</span>
}

export const groupQtyRenderer = ({ rows }: GridGroupRendererParams) => (
  <span>{sum(rows.map((it) => it.get('noOfPackages')))}</span>
)

export const routePointSummaryNameRenderer = ({ rows }: GridGroupRendererParams) => {
  const textKey = rows?.first(null)?.get('type') == 'pickup' ? 'consignment.pickup' : 'consignment.delivery'
  return <span>{i18next.t(textKey)}</span>
}

export const summaryLastRowPicker = ({ rows }: GridGroupRendererParams) => {
  return rows?.last(null)
}

export const routePointTypeRenderer = ({ value }: GridRendererParams) => (
  <span>{i18next.t(`instant.routePoint.${value}`)}</span>
)

export const endDateRenderer = ({ value }: GridRendererParams) => <span>{value || '∞'}</span>

export const subCustomerValueRenderer = ({ row }: GridRendererParams) => {
  return `${customerNumberAndSubcustomer(row.get('custAlystraId'), row.get('custAlystraSubcustomer')) || ''}`.trim()
}

export const subCustomerRenderer = (width: string) => (params: GridRendererParams) => {
  const view = subCustomerValueRenderer(params)
  return (
    <span
      title={view}
      style={{ display: 'block', width: width, maxWidth: width, textOverflow: 'ellipsis', overflow: 'hidden' }}
    >
      {view}
    </span>
  )
}

export const dateTimeValueRenderer = ({ value }: GridRendererParams) =>
  toLocaleDatePreserveNull(value)?.toFormat('yyyy-MM-dd HH:mm') || ''

export const dateTimeRenderer = (params: GridRendererParams) => <>{dateTimeValueRenderer(params)}</>

export const dateRenderer = ({ value }: GridRendererParams) => (
  <span>{value ? toLocaleDatePreserveNull(value)?.toFormat('yy-MM-dd') : ''}</span>
)

export const numberOfOrders = (params: GridRendererParams) => <span>{numberOfOrdersValue(params)}</span>

export const numberOfOrdersValue = ({ value }: GridRendererParams) => toString(value && value.size)

export const yesNoRenderer = (params: GridRendererParams) => <>{yesNoValueRenderer(params)}</>

export const yesNoValueRenderer = ({ value }: GridRendererParams) =>
  i18next.t(value ? 'application.yes' : 'application.no')

export const priceRenderer = (params: GridRendererParams) => <>{priceValueRenderer(params)}</>

export const priceValueRenderer = ({ value }: GridRendererParams) => formatPrice(value)

export const customerRenderer = ({ row }: GridRendererParams) => {
  const content = row && customerDisplayTextLong(row)
  return <span title={content}>{content}</span>
}

export const mapLinkRenderer = ({ row }: GridRendererParams) => {
  return (
    row.get('latLng')?.get('lat') &&
    row.get('latLng')?.get('lng') && (
      <a
        href={`https://www.google.com/maps/place/${row.get('latLng')?.get('lat')},${row.get('latLng')?.get('lng')}`}
        target="blank"
      >
        <FontAwesomeIcon icon="map-marker-alt" />
      </a>
    )
  )
}

export const volumeInMQRenderer = ({ value }: GridRendererParams) => (
  <>
    {formatAmountWithUnit(convert(MeasurementUnit.DMQ, MeasurementUnit.MTQ, Number(value)) || 0, MeasurementUnit.MTQ)}
  </>
)

export const amountRenderer =
  (unitColumn: string) =>
  ({ value, row }: GridRendererParams) => <>{formatAmountWithUnit(value, row.get(unitColumn))}</>

export const unitRenderer =
  (unit: MeasurementUnit) =>
  ({ value }: GridRendererParams) => <>{formatAmountWithUnit(value, unit)}</>

const vehicleTypeToIcon = (vt: string, gray: boolean, extraStyle: any = {}) => {
  switch (vt) {
    case 'car':
      return <Icon src={gray ? carIconGray : carIconBlack} alt="Car" style={extraStyle} />
    case 'van':
      return <Icon src={gray ? vanIconGray : vanIconBlack} alt="Van" style={extraStyle} />
    case 'truck':
      return <Icon src={gray ? truckIconGray : truckIconBlack} alt="Truck" style={extraStyle} />
    case 'bicycle':
      return <Icon src={gray ? bicycleIconGray : bicycleIconBlack} alt="Bicycle" style={extraStyle} />
    case 'motorcycle':
      return <Icon src={gray ? motorbikeIconGray : motorbikeIconBlack} alt="Motorbike" style={extraStyle} />
    case 'cargobike':
      return <Icon src={gray ? cargoBikeIconGray : cargoBikeIconBlack} alt="Cargo bike" style={extraStyle} />
    default:
      return null
  }
}

export const vehicleTypeToBlackIcon = (vt: string, extraStyle: any = {}) => {
  return vehicleTypeToIcon(vt, false, extraStyle)
}

export const vehicleTypeToGrayIcon = (vt: string, extraStyle: any = {}) => {
  return vehicleTypeToIcon(vt, true, extraStyle)
}

export const vehicleTypeRenderer = ({ value }: GridRendererParams) => {
  return <span>{vehicleTypeToBlackIcon(value)}</span>
}

export const vehicleTypeIconWithTextRenderer = ({ value }: GridRendererParams) => {
  return (
    <span>
      {vehicleTypeToBlackIcon(value)} {i18next.t(`instant.booking.vehicleTypes.${value}`)}
    </span>
  )
}

const getVases = (value: List<ValueAddedService> | undefined): List<string> =>
  value
    ? value
        .map((it: ValueAddedService) => it.get('vasCode'))
        .toSet()
        .toList()
        .sort()
    : List()

function getPureVases(value: List<ValueAddedService> | undefined) {
  const pureVases: List<string> = value
    ? value
        .filter((it: ValueAddedService) => it.get('pureVas'))
        .map((it: ValueAddedService) => it.get('vasCode'))
        .toSet()
        .toList()
        .sort()
    : List()
  return pureVases
}

export const vasHDDetailsRenderer = ({ row, value }: GridRendererParams) => {
  const pureVases = getPureVases(value)
  const isReturn = isShipmentReturn(row)

  return (
    <span>
      {pureVases.map(vasToDot)}
      {isReturn && <Dot small color={variables.colors.shipmentReturn} title={'Return'} />}
    </span>
  )
}

const vasToDot = (vas: string) => {
  switch (vas) {
    case '1138':
    case '1140':
      return <Dot key={vas} small color={variables.colors.shipmentOversized} title={'Oversized'} />
    case '1124':
    case '1125':
    case '1127':
    case '1128':
    case '1141':
      return <Dot key={vas} small color={variables.colors.shipmentInstallation} title={'Installation'} />
    default:
      return null
  }
}

export const vasDetailsRenderer = (params: GridRendererParams) => <>{vasDetailsValueRenderer(params)}</>

export const vasDetailsValueRenderer = ({ value }: GridRendererParams) => getVases(value).join(', ')

export const multilegRenderer = ({ value }: GridRendererParams) => <span>{!!value && multilegIcon()}</span>

export const errorRenderer =
  (color: string) =>
  ({ value }: GridRendererParams) =>
    value > 0 ? <span style={{ color }}>{value}</span> : <span>{value}</span>

const vasIcon = (vas: string, iconName: IconProp) => (
  <FontAwesomeIcon key={vas} style={{ marginRight: '4px' }} title={vas} icon={iconName} size={'lg'} />
)

export const multilegIcon = () => <img className="mr-2 inline" src={multilegSvgIcon} alt="Multileg" />

export const extraInfoRenderer = ({ row }: GridRendererParams) => (
  <>
    {row && (shouldShowPalletIconOnWeightCheck(row) || shouldShowPalletIcon(row)) && (
      <span>{vasIcon('PALLET', 'pallet')}</span>
    )}
    {row && row.get('serviceCode') === 'MANPOWER' && <span>{vasIcon('MANPOWER', 'male')}</span>}
    <span>{row ? <>{row.get('multilegId') && multilegIcon()}</> : ''}</span>
    <span>
      {row ? (
        <>
          {row.get('groupName')}
          {!isNullOrUndefined(row.get('groupOrder')) ? `:${row.get('groupOrder')}` : ''}
        </>
      ) : (
        ''
      )}
    </span>
  </>
)

export const groupExtraInfoRenderer = ({ rows }: GridGroupRendererParams) => (
  <>
    <span>{rows && !rows.isEmpty() ? <>{rows.first(Map()).get('multilegId') && multilegIcon()}</> : ''}</span>
    <span>
      {rows && !rows.isEmpty() ? (
        <>
          {rows.first(Map()).get('groupName')} ({rows.count()})
        </>
      ) : (
        ''
      )}
    </span>
  </>
)

export const groupRenderer = ({ row }: GridRendererParams) => (
  <span>
    {row.get('groupName')}
    {!isNullOrUndefined(row.get('groupOrder')) ? `:${row.get('groupOrder')}` : ''}
  </span>
)

export const hide = () => <></>

export const text = (text: string) => () => <>{text}</>

export const exceptionsRenderer = (params: GridRendererParams) => (
  <>
    <a style={{ color: variables.colors.black }} href="#" onClick={params.value(params.row.get('orderId'))}>
      {params.row.get('exceptions').count() > 0 ? <FontAwesomeIcon icon="calendar-alt" /> : null}
    </a>
  </>
)

export const slotStatusRenderer = ({ value }: GridRendererParams) => (
  <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>{getDisplayNameForSlotState(value)}</div>
)

export const shipmentSyncStatusRenderer = ({ value }: GridRendererParams) => (
  <span>{!!value && i18next.t(`consignment.shipmentSyncStatus.${value}`)}</span>
)

export const statusValueRenderer = ({ value }: GridRendererParams) => i18next.t(`consignment.message.${value}`)

export const statusRenderer = ({ value }: GridRendererParams) => (
  <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
    <ConsignmentStateIcon
      state={value}
      style={{
        display: 'flex',
        alignItems: 'center',
        height: `${DEFAULT_ROW_HEIGHT_PX}px`,
        padding: '0 5px',
        marginRight: '5px'
      }}
    />
    {i18next.t(`consignment.message.${value}`)}
  </div>
)

export const customerStatusRenderer = (params: GridRendererParams) => (
  <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
    <CustomerConsignmentStateIcon
      state={params.value}
      style={{
        display: 'flex',
        alignItems: 'center',
        height: `${DEFAULT_ROW_HEIGHT_PX}px`,
        padding: '0 5px',
        marginRight: '5px'
      }}
    />
    {customerStatusValueRenderer(params)}
  </div>
)

export const customerStatusValueRenderer = ({ value }: GridRendererParams) =>
  notCollectedStates.includes(value)
    ? i18next.t('customer.showNotCollected')
    : i18next.t(`consignment.message.${value}`)

export const adminCustomerEmailRenderer = ({ value }: GridRendererParams) => (
  <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
    {i18next.t(`customer.email.${value ?? 'false'}`)}
  </div>
)

export const fuelTypeRenderer = ({ value }: GridRendererParams) => (
  <span>{!!value && i18next.t(`courier.fuelTypes.${value}`)}</span>
)

export const fuelCategoryRenderer = ({ value }: GridRendererParams) => (
  <span>{!!value && i18next.t(`courier.fuelCategories.${value}`)}</span>
)

export const countValueRenderer = ({ value }: GridRendererParams) => value?.count() || 0

export const countRenderer = (params: GridRendererParams) => <span>{countValueRenderer(params)}</span>

export const buttonRenderer = ({ row, value }: GridRendererParams) => {
  if (value.icon) {
    return (
      <PlainButton onClick={() => value.onClick(row)} type="button" aria-label={value.text}>
        <IconFa icon={['far', value.icon]} />
      </PlainButton>
    )
  }
  return (
    <TertiaryButton type="button" onClick={() => value.onClick(row)}>
      {value.text}
    </TertiaryButton>
  )
}

export const simpleDeviationTypeRenderer = ({ value }: GridRendererParams) => (
  <>{value ? i18next.t(value == 'PICKUP' ? 'consignment.pickup' : 'consignment.delivery') : ''}</>
)

export const deviationTypeRenderer =
  (events: Map<OrderIdType, List<ConsignmentEvent>>) =>
  ({ row }: GridRendererParams) => {
    const shipmentEvents = events.get(row.get('orderId')) || List<ConsignmentEvent>()
    const deviationCode = getLatestDeviation(shipmentEvents)
    const isPickupDeviation = pickupDeviationCodes.map((devCode) => devCode.get('code')).contains(deviationCode)
    const isDeliveryDeviation = isPickupDeviation
      ? false
      : deliveryDeviationCodes.map((devCode) => devCode.get('code')).contains(deviationCode)
    return (
      <>
        {isPickupDeviation || isDeliveryDeviation
          ? i18next.t(isPickupDeviation ? 'consignment.pickup' : 'consignment.delivery')
          : ''}
      </>
    )
  }

export const deviationCodeRenderer = (params: GridRendererParams) => <>{deviationCodeValueRenderer(params)}</>

export const deviationCodeValueRenderer = ({ value }: GridRendererParams): string => {
  const deviationCodeLabel =
    pickupDeviationCodes.find((devCodeLabel) => devCodeLabel.get('code') === value) ||
    deliveryDeviationCodes.find((devCodeLabel) => devCodeLabel.get('code') === value)
  const deviationLabel = deviationCodeLabel?.get('label')
  return i18next.t(deviationLabel ? deviationLabel : '')
}

export const deviationTextRenderer =
  (events: Map<OrderIdType, List<ConsignmentEvent>>) =>
  ({ row }: GridRendererParams) => {
    const shipmentEvents = events.get(row.get('orderId')) || List<ConsignmentEvent>()
    const deviationCode = getLatestDeviation(shipmentEvents)
    const deviationCodeLabel =
      pickupDeviationCodes.find((devCodeLabel) => devCodeLabel.get('code') === deviationCode) ||
      deliveryDeviationCodes.find((devCodeLabel) => devCodeLabel.get('code') === deviationCode)
    const deviationLabel = deviationCodeLabel?.get('label')
    return <>{i18next.t(deviationLabel ? deviationLabel : '')}</>
  }

export const getLatestDeviation = (shipmentEvents: List<ConsignmentEvent>): string => {
  const latestDeviatedEvent = shipmentEvents
    .filter((event) => isDeviationEvent(event))
    .maxBy((event) => event.get('eventTime'))
  return latestDeviatedEvent?.getIn(['data', 'deviation']) || ''
}

export const inlineEditRenderer = (params: GridRendererParams) => {
  if (params.row.get('edit_' + params.column)) {
    return params.row.get('edit_' + params.column)
  } else {
    return <>{params.value}</>
  }
}

export const euroClassRenderer = ({ value }: GridRendererParams) => (
  <span>{!!value && i18next.t(`courier.euroClassTypes.${value}`)}</span>
)

export const vehicleControlRenderer = ({ value }: GridRendererParams) => (
  <span>{!!value && i18next.t(`courier.${value}`)}</span>
)

export const durationValueRenderer = ({ value }: GridRendererParams) =>
  value && Duration.fromObject({ seconds: value }).toFormat('mm:ss')

export const durationRenderer = (params: GridRendererParams) => <span>{durationValueRenderer(params)}</span>

export const serviceLevelRenderer = ({ value }: GridRendererParams) => (
  <>{!!value && i18next.t(`serviceLevel.${value}`)}</>
)

export const airexpressStatusRenderer = ({ value }: GridRendererParams) => (
  <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
    <ConsignmentStateIcon
      state={value}
      style={{
        display: 'flex',
        alignItems: 'center',
        height: `${DEFAULT_ROW_HEIGHT_PX}px`,
        padding: '0 5px',
        marginRight: '5px'
      }}
    />
    <span style={value == 'TEMPLATE' ? { background: 'yellow' } : undefined}>
      {value == 'TEMPLATE'
        ? 'Template'
        : i18next.exists(`consignment.message.${value}`)
          ? i18next.t(`consignment.message.${value}`)
          : i18next.t(`airexpress.eventType.${value}`)}
    </span>
  </div>
)

export const suggestedDriverWithPackagesRenderer = ({ row }: GridRendererParams) => (
  <span>
    {row.get('driverName')}
    {row.get('itemsOnUnit')
      ? ` (${row.get('itemsOnUnit')})`
      : row.get('itemsOnVehicle')
        ? ` (${row.get('itemsOnVehicle')})`
        : ''}
  </span>
)
