import { DateTime } from 'luxon'
import { DeliveryTime } from '../../shared/Shipment'
import { Shipment } from '../../../../types/coreEntitiesTypes'

export const formatTime = (time: DateTime): string => (time.minute === 0 ? time.toFormat('HH') : time.toFormat('HH:mm'))

export const formatTimeWindow = (timeWindow: DeliveryTime | null): string =>
  timeWindow ? `${formatTime(timeWindow.earliest)}-${formatTime(timeWindow.latest)}` : '-'

export const isSameDeliveryTime = (dt1: DeliveryTime, dt2: DeliveryTime | null): boolean =>
  dt1.earliest.toMillis() === dt2?.earliest.toMillis() && dt1.latest.toMillis() === dt2?.latest.toMillis()

export const isSameDeliveryDate = (dt1: DeliveryTime, dt2: DeliveryTime | null): boolean =>
  isSameDeliveryTime(
    {
      earliest: dt1.earliest.startOf('day'),
      latest: dt1.earliest.startOf('day')
    },
    (dt2 && {
      earliest: dt2.earliest.startOf('day'),
      latest: dt2.earliest.startOf('day')
    }) ||
      null
  )

const getValidDeliveryTime = (earliest?: DateTime, latest?: DateTime): DeliveryTime | null =>
  earliest?.isValid && latest?.isValid ? { earliest, latest } : null

export const isValidDeliveryTime = (deliveryTime: DeliveryTime | null): boolean =>
  getValidDeliveryTime(deliveryTime?.earliest, deliveryTime?.latest) !== null

export const getDeliveryTime = (shipment?: Shipment): DeliveryTime | null =>
  getValidDeliveryTime(
    shipment && DateTime.fromISO(shipment.get('deliveryTimeEarliest')),
    shipment && DateTime.fromISO(shipment.get('deliveryTimeLatest'))
  )
