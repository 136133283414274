import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import React from 'react'
import { DateTime } from 'luxon'
import { DayPickerInputProps, DayPickerProps } from 'react-day-picker'
import { isDate, localizedDayPickerProps, withWeekDayWithoutYear } from '../utils/dateTime'

interface Props {
  component?: React.ReactNode
  onDayChange: (date: Date) => void
  formatDate?: (date: Date) => string
  parseDate?: (str: string) => Date
  dayPickerProps?: DayPickerProps
  dayPickerInputProps?: DayPickerInputProps
  inputProps?: object
  placeholder?: string
  value: string
}

const DatePicker = (props: Props) => {
  const { dayPickerInputProps, ...rest } = props

  const dayPickerProps = {
    ...localizedDayPickerProps,
    dayPickerInputProps
  }

  return (
    <>
      <DayPickerInput
        dayPickerProps={dayPickerProps}
        formatDate={datePickerFormatDate}
        parseDate={datePickerParseDate}
        inputProps={{
          style: { borderStyle: 'groove' },
          readOnly: true
        }}
        {...rest}
      />
    </>
  )
}
export default DatePicker

export const datePickerParseDate = (str: any) => {
  const parsed = DateTime.fromJSDate(str).toJSDate()
  if (isDate(parsed)) {
    return parsed
  }
  return undefined
}

export const datePickerFormatDate = (date: Date | DateTime) => {
  if (!DateTime.isDateTime(date)) {
    date = DateTime.fromJSDate(date)
  }
  return date.toFormat('yyyy-MM-dd')
}

export const datePickerFormatDateLive = (date: Date | DateTime) => {
  return withWeekDayWithoutYear(DateTime.fromISO(datePickerFormatDate(date)))
}
