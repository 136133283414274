import React from 'react'
import { SmallText } from '../../../../primitives/Typography'
import { IConsignmentEvent } from '../../../../types/coreEntitiesTypes'
import { ImmutableMap } from '../../../../types/immutableTypes'
import { toLocaleDate, withTimeAndDate } from '../../../../utils/dateTime'

interface DigitalIdentityVerifiedProps {
  consignmentEvent: ImmutableMap<IConsignmentEvent>
}

export const DigitalIdentityVerifiedMessage = ({
  consignmentEvent
}: DigitalIdentityVerifiedProps): React.ReactElement => {
  const data = consignmentEvent.get('data')
  return (
    <div style={{ whiteSpace: 'pre-wrap' }}>
      <div style={{ display: 'inline-flex' }}>
        {data?.get('name')} - {data?.get('birthdate')}
      </div>
      <SmallText right>{withTimeAndDate(toLocaleDate(data?.get('eventTime')))}</SmallText>
    </div>
  )
}
