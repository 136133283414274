import React from 'react'
import { NavigateFunction, Location, useLocation, useNavigate, useParams } from 'react-router-dom'

export interface RouteComponentProps<Params extends { [K in keyof Params]?: string } = {}> {
  router: {
    location: Location
    navigate: NavigateFunction
    params: Readonly<Params>
  }
}

export function withRouter<ComponentProps>(Component: any) {
  function ComponentWithRouterProp(props: ComponentProps) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    return <Component {...props} router={{ location, navigate, params }} />
  }

  return ComponentWithRouterProp
}
