import i18next from 'i18next'
import { Collection, List, Map, Set } from 'immutable'
import React, { Fragment } from 'react'
// @ts-expect-error
import { Control, Errors, Form } from 'react-redux-form/lib/immutable'
import { TertiaryButton } from '../primitives/Button'
import { ValidationError } from '../primitives/ErrorMessages'
import { Label } from '../primitives/Forms'
import { H3 } from '../primitives/Headings'
import ImmutableComponent from '../primitives/ImmutableComponent'
import { IOptimizeJobResult } from '../selectors/optimizeJobResultsSelector'
import { Shipment, Slot } from '../types/coreEntitiesTypes'
import { ImmutableMap } from '../types/immutableTypes'
import { isNotEmpty } from '../utils/collectionUtils'
import { required } from '../utils/inputValidation'
import { isSlotEditable, isSlotStarted } from '../utils/slotStates'

interface Props {
  slot: Slot
  availableSlots: List<Slot>
  shipment: Shipment
  changeSlotForConsignments: (value: Map<string, any>, fromSlotId: number, consignmentIds: Set<number>) => void
  addConsignmentsToSlotAndReoptimize: (value: Map<string, any>, consignmentIds: Set<number>) => void
  optimizeJobResults: Collection<number, IOptimizeJobResult>
  unassignConsignmentsFromSlot: (slotId: number, consignmentIds: Set<number>) => void
}

interface IFormProps {
  slot: Slot
}

interface IForm extends ImmutableMap<IFormProps> {}

export class ShipmentChangeSlot extends ImmutableComponent<Props> {
  render() {
    const {
      slot,
      availableSlots,
      shipment,
      changeSlotForConsignments,
      addConsignmentsToSlotAndReoptimize,
      optimizeJobResults,
      unassignConsignmentsFromSlot
    } = this.props
    const consignmentIds = shipment.get('consignmentIds')

    if (isNotEmpty(slot)) {
      const alterSlotText = i18next.t('consignment.alterSlot')
      const slotState = slot.get('state')
      const targetSlots = availableSlots.filter((s) => s.get('id') !== slot.get('id')).toList()

      return (
        <div>
          {isSlotEditable(slotState) && (
            <Fragment>
              <H3>{i18next.t('planner.unassignTitle')}</H3>
              <Label>
                <TertiaryButton onClick={() => unassignConsignmentsFromSlot(slot.get('id'), consignmentIds)}>
                  {i18next.t('planner.unassignButton')}
                </TertiaryButton>
              </Label>
            </Fragment>
          )}
          {targetSlots.size > 0 &&
            isSlotStarted(slotState) &&
            ShipmentChangeSlot.changeSlotForm(alterSlotText, targetSlots, (v) => {
              changeSlotForConsignments(v, slot.get('id'), consignmentIds)
            })}
        </div>
      )
    } else {
      const alterSlotText = i18next.t('consignment.alterSlotAndReoptimize')
      if (optimizeJobResults.last() && !optimizeJobResults.last(Map() as IOptimizeJobResult).get('finished')) {
        return (
          <div>
            <H3>{alterSlotText}</H3>
            <p>{i18next.t('consignment.alterSlotNotAvailable')}</p>
          </div>
        )
      }
      const targetSlots = availableSlots.filter((s) => isSlotEditable(s.get('state'))).toList()
      return (
        <div>
          {targetSlots.size > 0 &&
            ShipmentChangeSlot.changeSlotForm(alterSlotText, targetSlots, (v) => {
              addConsignmentsToSlotAndReoptimize(v, consignmentIds)
            })}
        </div>
      )
    }
  }

  static changeSlotForm(text: string, changeableSlots: List<Slot>, changeSlotFn: (v: IForm) => void) {
    return (
      <Fragment>
        <H3>{text}</H3>
        <Form model="alterSlotForConsignment" onSubmit={changeSlotFn}>
          <Label>
            {i18next.t('consignment.selectSlot')}:
            <Control.select model=".slot" id="selectedSlot" validators={{ required }} defaultValue="0">
              <option key="0" value="0">
                {i18next.t('consignment.slotSelect')}
              </option>
              {changeableSlots
                .map((s) => {
                  const slotId = s.get('id')
                  return (
                    <option key={`slot_${slotId}`} value={slotId}>
                      {s.get('name')}
                    </option>
                  )
                })
                .toList()}
            </Control.select>
            <TertiaryButton marginLeft type="submit">
              {i18next.t('consignment.confirmSlotChange')}
            </TertiaryButton>
            <Errors
              className="errors"
              model=".slot"
              show="touched"
              wrapper={ValidationError}
              messages={{ required: `${i18next.t('application.required')}` }}
            />
          </Label>
        </Form>
      </Fragment>
    )
  }
}
