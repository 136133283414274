import { H3 } from '../../../primitives/Headings'
import i18next from 'i18next'
import { LabeledInput } from '../../../primitives/LabeledInput'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { InputForLabel, TextAreaInputWithLabel } from '../../../primitives/Forms'
import { ClearInstantBookingFormButton } from '../../../components/ClearInstantBookingFormButton'
import { invoiceInformationComponentNames } from '../bookingSupport'
import InputGroup from '../InputGroup'
import React from 'react'
import { List } from 'immutable'
import { SmallValidationError } from '../../../primitives/ErrorMessages'
import { required } from '../../../utils/inputValidation'
import { isBring } from '@glow/common'

interface Props {
  clearComponents: (components: List<string>) => void
  isHdOrder?: boolean
}

const BookingFormInvoiceInformation = ({ clearComponents, isHdOrder }: Props) => {
  return (
    <InputGroup aria-label="Invoice form">
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <H3 style={{ paddingRight: '0.3em' }}>{i18next.t('instant.booking.invoiceInfo')}</H3>
      </div>

      <div>
        <LabeledInput
          label={i18next.t(`instant.booking.${isBring() ? 'customerContactPlaceholder' : 'customerContact'}`)}
          htmlFor="customerContact"
        >
          <Control.text
            autoComplete="nope"
            model=".customerContact"
            id="customerContact"
            component={InputForLabel}
            validators={isHdOrder ? undefined : { required }}
            defaultValue={''}
          />
        </LabeledInput>
        <Errors
          className="errors"
          model=".customerContact"
          show="touched"
          wrapper={SmallValidationError}
          messages={{ required: `${i18next.t('application.required')}` }}
        />
      </div>

      <div style={{ marginTop: '0.75em' }}>
        <LabeledInput
          label={i18next.t(`instant.booking.${isBring() ? 'pickupReferencePlaceholder' : 'customerReference'}`)}
          htmlFor="customerRef"
        >
          <Control.text autoComplete="nope" model=".customerRef" id="customerRef" component={InputForLabel} />
        </LabeledInput>
      </div>

      <div style={{ marginTop: '0.75em' }}>
        <LabeledInput label={i18next.t('instant.booking.deliveryReferencePlaceholder')} htmlFor="recipientRef">
          <Control.text autoComplete="nope" model=".recipientRef" id="recipientRef" component={InputForLabel} />
        </LabeledInput>
      </div>

      <div style={{ paddingTop: '0.5em' }}>
        <LabeledInput
          label={i18next.t(`instant.booking.${isBring() ? 'invoiceInfoPlaceholder' : 'invoiceInfo'}`)}
          htmlFor="invoiceInfo"
        >
          <Control.textarea model=".invoiceInfo" id="invoiceInfo" component={TextAreaInputWithLabel} rows={1} />
        </LabeledInput>
      </div>

      <div style={{ paddingTop: '0.75em' }}>
        <LabeledInput label={i18next.t('instant.booking.manualInvoiceInfoPlaceholder')} htmlFor="manualInvoiceInfo">
          <Control.textarea
            model=".manualInvoiceInfo"
            id="manualInvoiceInfo"
            component={TextAreaInputWithLabel}
            rows={2}
          />
        </LabeledInput>
      </div>
      <ClearInstantBookingFormButton clearComponents={clearComponents} components={invoiceInformationComponentNames} />
    </InputGroup>
  )
}

export default BookingFormInvoiceInformation
