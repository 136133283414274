import React from 'react'
import styled from 'styled-components'
import vars from '../styles/variables'
import { Label } from './Forms'
import ImmutableComponent from './ImmutableComponent'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  whiteBackground?: boolean
  htmlFor?: string
  label: string
}

export class LabeledInput extends ImmutableComponent<Props> {
  render() {
    const { whiteBackground, htmlFor, label, children, ...props } = this.props
    return (
      <InputWithLabel whiteBackground={whiteBackground} {...props}>
        <Label htmlFor={htmlFor} style={{ margin: 0, fontSize: '.7rem', marginLeft: '4px' }}>
          {label}
        </Label>
        {children}
      </InputWithLabel>
    )
  }
}

export const InputWithLabel = styled.div<{ inline?: any; whiteBackground?: boolean }>`
  background: ${(props) => (props.whiteBackground ? vars.colors.white : '')};
  border-radius: 4px;
  box-shadow: 0 0 1px ${vars.colors.black};
  padding: 0;
  margin-top: 0;
  padding-bottom: 4px;
  font-style: oblique;
`
