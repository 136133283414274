import { AnyAction, Reducer } from 'redux'
import { combineReducers } from 'redux-immutable'
import * as types from '../actions/actionTypes'
import httpStatus from './httpStatus'
import { List, Map } from 'immutable'
import user from './user'
import context from './context'
import { modal } from './modal'
// @ts-expect-error
import { createForms } from 'react-redux-form/lib/immutable'
import { pageState } from './pageState'
import { entities } from './entities'
import map from './map'
import { feedbackNotifications } from './feedbackNotifications'
import { reactRenderError } from './reactRenderError'
import aggregates from './aggregates'
import searchConsignments from './searchConsignments'
import searchAddresses from './searchAddresses'
import optimizingReducer from './optimizingReducer'
import servicesReducer from './servicesReducer'
import { drawer } from './drawer'
import { websocket } from './websockets'
import searchReducer from './searchReducer'
import invoicingDataReducer from './invoicingDataReducer'
import invoicingPageReducer from './invoicingPageReducer'
import manageReturnsPageReducer from './manageReturnsPageReducer'
import preAdviceFollowupPageReducer from './preAdviceFollowupPageReducer'
import { getDefaultPresetMeasurement } from '../pages/instant/Booking'
import numberSeriesReducer from './numberSeriesReducer'
import snackbar from './snackbarReducers'
import grid from './gridReducer'
import filter from './filterReducer'
import RecurringOrderForm from '../pages/instant/RecurringOrderForm'
import recurringExecutedDatesReducer from './recurringExecutedDatesReducer'
import importRecipients from './importRecipients'
import notifications from './notifications'
import { initialUnitFormState } from '../components/unit/UnitForm'
import settingsReducer from './settingsReducer'
import unresolvedAddresses from './unresolvedAddressReducer'
import servicesAndVasesTexts from './servicesAndVasesTextsReducer'
import searchUnitCouriers from './searchCourierUsers'
import searchUnits from './searchUnits'
import {
  suggestedUnitsForLocationReducer,
  suggestedUnitsOnDeliveryReducer,
  suggestedUnitsOnPickupReducer
} from './suggestedUnitsReducer'
import gridSavedIds from './gridSavedIdsReducer'
import { getDefaultPresetMeasurementForH2 } from '../pages/instant/h2/H2Booking'
import { emptyVAS } from '../pages/instant/bookingOrderFunctions'
import consignmentEvents from './consignmentEventsReducer'
import { setPickupDeliveryDurationFormInitialState } from '../pages/planner/SetPickupDeliveryDurationModal'
import airExpressReducer from './airExpressReducer'
import availableDeliveryTimesReducer from './availableDeliveryTimesReducer'
import hdServicesReducer from './hdServicesReducer'
import shdMatrix from './shdMatrixReducer'
import searchPhraseReducer from './searchPhraseReducer'
import { AppStateType } from '../utils/appStateReduxStore'
import articlesReducer from './articlesReducer'

const combinedReducers = (): Reducer<AppStateType> =>
  combineReducers({
    articlesReducer,
    user,
    httpStatus,
    pageState,
    availableDeliveryTimesReducer,
    modal,
    entities,
    notifications,
    feedbackNotifications,
    reactRenderError,
    aggregates,
    searchReducer,
    searchConsignments,
    searchAddresses,
    map,
    optimizingReducer,
    websocket,
    drawer,
    servicesFromOptimus: servicesReducer,
    hdServicesReducer,
    numberSeriesReducer,
    invoicingDataReducer,
    invoicingPageReducer,
    manageReturnsPageReducer,
    preAdviceFollowupPageReducer,
    snackbar,
    grid,
    filter,
    recurringExecutedDatesReducer,
    importRecipients,
    unresolvedAddresses,
    servicesAndVasesTexts,
    context,
    settingsReducer,
    searchUnitCouriers,
    searchUnits,
    searchPhraseReducer,
    suggestedUnitsForLocationReducer,
    suggestedUnitsOnDeliveryReducer,
    suggestedUnitsOnPickupReducer,
    gridSavedIds,
    consignmentEvents,
    shdMatrix,
    airExpress: airExpressReducer,
    ...createForms({
      createPlanner: Map({ name: '', phoneNumber: '' }),
      createDepartmentGroup: Map({ name: '' }),
      editDepartmentGroup: Map(),
      createUnit: initialUnitFormState,
      createSlot: Map({ name: '', slotMode: null, courierId: 0, shouldDriverNotReturn: true }),
      editUnit: initialUnitFormState,
      editPlanner: Map(),
      areaModel: Map(),
      unresolvedConsignmentForm: Map(),
      unresolvedAddressForm: Map(),
      numberSeriesForm: Map(),
      holidayForm: Map(),
      adminCustomerForm: Map(),
      login: Map({ phoneNumber: '', token: '' }),
      addConsignmentComment: Map({ comment: '', consignmentId: '' }),
      consignment: Map({ checked: List(), selectAll: false }),
      setShipmentsInfo: Map({ comment: '', editPickupTime: false, editPickupLocation: false, editDeliveryTime: false }),
      alterSlotForConsignment: Map(),
      manualOverrideControlsForConsignment: Map(),
      searchPlannerShipment: Map(),
      searchCustomerShipment: Map(),
      saveTemplate: Map(),
      selectTemplate: Map(),
      adminServiceMappingForm: Map({ important: false, allowFlexDelivery: true }),
      createOrderForm: Map({
        measurements: List([getDefaultPresetMeasurement()]),
        additionalServices: List(),
        adrGoods: List(),
        searchFlight: Map({})
      }),
      h2BookingForm: Map({
        measurements: List([getDefaultPresetMeasurementForH2()]),
        additionalServices: List.of(emptyVAS())
      }),
      recurringOrderForm: RecurringOrderForm.initialState,
      moveOrderToAnotherDepartmentForm: Map(),
      orderCorrectionForm: Map(),
      newsForm: Map(),
      groupOrdersForm: Map(),
      splitOrdersForm: Map(),
      customerEmailForm: Map(),
      customerSmsForm: Map(),
      customerSenderNameForm: Map(),
      invoiceHDOrdersFromDateForm: Map(),
      orderDeviationForm: Map(),
      customerSmsTextForm: Map(),
      addExceptionsForm: Map(),
      addressesForm: Map(),
      exportCustomerAddresses: Map(),
      exportOrder: Map(),
      exportShipments: Map(),
      exportInvoice: Map(),
      customMeasurementForm: Map(),
      unitCourierForm: Map({ name: '', phoneNumber: '', canLogIn: true, secureLoginEnabled: false }),
      courierUserForm: Map(),
      airportModel: Map(),
      flightScheduleModel: Map(),
      sendManualDelaySmsForm: Map(),
      searchEventsWithLmStatus: Map(),
      setPickupDeliveryDurationForm: setPickupDeliveryDurationFormInitialState,
      exportLocationList: Map()
    })
  })

export default () => (state: AppStateType | undefined, action: AnyAction) => {
  if (action.type === types.LOGOUT.SUCCESS) {
    state = Map()
  }
  return combinedReducers()(state, action)
}
