import React from 'react'
import i18next from 'i18next'
import { AnyData } from '../../../../actions/creators/baseHelpers'
import { ImmutableMap } from '../../../../types/immutableTypes'
import { IConsignmentEvent } from '../../../../types/coreEntitiesTypes'

export const EventInvoice = (props: {
  data: AnyData
  consignmentEvent: ImmutableMap<IConsignmentEvent>
}): React.ReactElement => {
  const initiationType = props.consignmentEvent.get('data')?.get('initiationType')

  return (
    <>
      {initiationType && (
        <span className="text-emphasis-high">
          {i18next.t(`consignmentEvent.invoiceInitiationType.${initiationType}`)}
        </span>
      )}
    </>
  )
}
