import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import i18next from 'i18next'
import { Collection, List, Map, Set } from 'immutable'
import isFunction from 'lodash/isFunction'
import toString from 'lodash/toString'
import React, { ChangeEvent, CSSProperties, RefObject } from 'react'
import { connect } from 'react-redux'
import {
  clearAllFilters,
  clearFilter,
  gridInit,
  gridInitSort,
  gridResetSort,
  gridSelect,
  gridSelectNone,
  gridSortDescending,
  hidePopover,
  setFilter,
  showPopover
} from '../../actions/creators/helpersInstant'
import { ColumnDefinition } from '../../components/grid/GridColumns'
import ImmutableComponent from '../../primitives/ImmutableComponent'
import { Grid, Icon, Table, TH } from '../../primitives/InstantGridStyles'
import { FilterState } from '../../reducers/filterReducer'
import { filterColumnsSelector, gridByIdSelector } from '../../selectors/gridSelectors'
import vars from '../../styles/variables'
import { Entity } from '../../types/coreEntitiesTypes'
import { AppStateType } from '../../utils/appStateReduxStore'
import { count, isEmpty, isNotEmpty } from '../../utils/collectionUtils'
import { matchesOneOf } from './filterFunctions'
import { FilterPopover } from './FilterPopover'
import { groupingSelector } from './groupingSelectors'
import { reOrderData } from './InstantDragAndDropUtils'
import InstantGridDraggableGroupRow from './InstantGridDraggableGroupRow'
import InstantGridDraggableRow from './InstantGridDraggableRow'
import InstantGridDroppableRow from './InstantGridDroppableRow'
import InstantGridGroupRow from './InstantGridGroupRow'
import InstantGridRow, { InstantGridMultiSelectCheckbox } from './InstantGridRow'
import { Comparator, defaultSortedSelector, lookupCompare, sortByColumn, sortedSelector } from './sortingSelectors'
import Modal from '../../components/Modals'
import InstantColumnSettingsModal, { getHiddenColumns } from '../../components/InstantColumnSettingsModal'
import settingsGray from '../../assets/icons/gridColumns.svg'
import { openModal } from '../../actions/creators/helpers'
import { Loading } from '../../components/Loading'
import InstantGridInfiniteScroll from './InstantGridInfiniteScroll'
import { ResizableContainer } from '../../primitives/InstantStyles'
import { FormikValues } from 'formik'
import { InstantBreakRow } from './break/InstantBreakRow'
import { IRoutePointWithExtraDataProps } from '../../query/instantCourierQuery'
import { Break } from '../../components/unit/UnitForm'

interface OpenSettingsButtonProps {
  openSettings: () => void
}

const OpenSettingsButton = ({ openSettings }: OpenSettingsButtonProps) => {
  return (
    <button
      type="button"
      title={i18next.t('instant.columnSettings.settings')}
      style={{
        minWidth: '32px',
        flex: '30 0 auto',
        width: '32px',
        height: '32px',
        padding: '0',
        cursor: 'pointer',
        outline: 'none'
      }}
      onClick={openSettings}
    >
      <Icon
        src={settingsGray}
        alt="settings"
        style={{
          width: '32px',
          bottom: '0'
        }}
      />
    </button>
  )
}

interface OwnProps<T> {
  data: Collection<number, Entity<T>>
  columns: List<ColumnDefinition<T>>
  onClickRow?: (row: Entity<T>) => void
  onDoubleClickRow?: (row: Entity<T>) => void
  onEditRow?: (rowId: number, row: Entity<T>) => void
  overflow?: boolean
  height?: string
  minHeight?: string
  maxHeight?: string
  defaultColumnWidth?: number
  isAssignable?: boolean
  isMultiSelectEnabled?: boolean
  isDropEnabled?: boolean
  isEditable?: boolean
  isFullscreen?: boolean
  isDragNDropEnabled?: boolean
  isDeletable?: boolean
  isResizeable?: boolean
  style?: CSSProperties
  onDragEnd?: (
    dropTarget: any,
    dragRow: Entity<T>,
    dragId: any,
    dragIndex: number,
    dropIndex: number,
    dataOrder: Map<number, number>
  ) => void
  dragPreview?: string
  isOverCurrent?: boolean
  onSelectOrderRow?: (row: Entity<T>, columnName?: string) => (event?: React.MouseEvent) => void
  onDeleteRow?: (row: Entity<T>) => void
  sorting?: boolean
  onAssignRow?: (row: Entity<T>) => void
  onUnassignRow?: (row: Entity<T>) => void
  isUnassignable?: boolean | ((row: Entity<T>) => boolean)
  stateKey: string
  shipmentPriorities?: Map<number, string>
  defaultSortColumnKey?: string
  defaultSortDescending?: boolean
  groupFunction?: (value: Entity<T>, key: number) => string
  groupSortFunction?: (a: Entity<T>, b: Entity<T>) => number
  showHeader?: boolean
  actions?: List<InstantGridAction<T>>
  rowBackgroundColorFunction?: (row: Entity<T>) => string | undefined
  rowBackgroundHoverColorFunction?: (row: Entity<T>) => string | undefined
  enableColumnFilters?: boolean
  key?: string
  isLoading?: boolean
  loadingText?: string
  noDataText?: string
  showCount?: boolean
  countI18nKey?: string
  showSettingsButton?: boolean
  showEmptyGrid?: boolean
  infiniteScrollEnabled?: boolean
  isGroupedElementClickDisabled?: boolean
  courierBreak?: Map<string, string>
  editBreak?: (values: FormikValues) => void
  updatedBreak?: Break
  newUnoptimizedBreak?: boolean
  deleteBreakFromRoute?: (row: Entity<IRoutePointWithExtraDataProps>) => void
  canEditBreak?: boolean
}

interface StateProps<T> {
  sortFunction?: Comparator<Entity<T>>
  sortedColumnKey?: string
  sortDescending: boolean
  selectedRows: Map<string, Set<number>>
  allSelectedPerColumn: Map<string, boolean>
  filterState: FilterState
  hiddenColumns: List<keyof T>
  disabledRows: Set<number>
}

interface DispatchProps<T> {
  gridInit: (key: string) => void
  gridSelectNone: (key: string, subKey?: string) => void
  gridSelect: (key: string, ids: Set<number>, allSelected: boolean, subKey?: string) => void
  gridSortDescending: (key: string, sortDescending: boolean) => void
  gridResetSort: (key: string) => void
  gridInitSort: (
    key: string,
    sortColumnKey: keyof T,
    sortDescending: boolean,
    sortFunction: Comparator<Entity<T>>
  ) => void
  showPopover: (key: string, column: string) => void
  hidePopover: (key: string, column: string) => void
  setFilter: (key: string, column: string, filter: string) => void
  clearFilter: (key: string, column: string) => void
  clearAllFilters: (key: string) => void
  openModal: (modalClass: any, propsForModal: any) => void
}

export enum ContextType {
  Planner = 'planner',
  Airexpress = 'airexpress'
}

export interface InstantGridAction<T> {
  icon: string | React.ReactElement
  text: string
  action?: (row: Entity<T>) => void
  enabled?: (row: Entity<T>) => boolean
  title?: string
}

type Props<T> = OwnProps<T> & StateProps<T> & DispatchProps<T>

export const DEFAULT_SELECT_COLUMN = 'multiSelect'

const noSorting = () => 0

const idsBetween = (ids: List<number>, startId: number, endId: number) => {
  const idx1 = ids.indexOf(startId) || 0
  const idx2 = ids.indexOf(endId)

  if (idx1 < idx2) {
    return ids.slice(idx1, idx2 + 1)
  } else {
    return ids.slice(idx2, idx1 + 1)
  }
}

class InstantGrid<T> extends ImmutableComponent<Props<T>> {
  gridContainer: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()

  constructor(props: Props<T>) {
    super(props)
    this.props.gridInit(this.props.stateKey)
    this.state = { dataOrder: Map() }
  }

  componentDidMount(): void {
    if (this.props.defaultSortColumnKey)
      this.props.gridInitSort(
        this.props.stateKey,
        this.props.defaultSortColumnKey as keyof T,
        !!this.props.defaultSortDescending,
        sortByColumn(this.props.defaultSortColumnKey as keyof T)
      )
  }

  componentDidUpdate(prevProps: Props<T>) {
    if (this.props.data && !this.props.data.equals(prevProps.data)) {
      this.props.selectedRows.map((value, key) => {
        const allSelected = this.props.allSelectedPerColumn.get(key, false)
        const selected: Set<number> = Set.intersect([value, this.props.data.map((d: Entity<T>) => d.get('id')).toSet()])
        if (!selected.equals(this.props.selectedRows)) {
          this.props.gridSelect(this.props.stateKey, selected, isEmpty(selected) ? false : allSelected, key)
        }
      })
    }
  }

  onSortColumnClick = (sortFunction: Comparator<Entity<T>>, dataIndex: keyof T) => (event: React.MouseEvent) => {
    event.preventDefault()
    if (dataIndex === this.props.sortedColumnKey) {
      if (this.props.sortDescending) {
        // reset sorting when cycled through asc and desc
        this.props.gridResetSort(this.props.stateKey)
      } else {
        // toggle from asc to desc
        this.props.gridSortDescending(this.props.stateKey, !this.props.sortDescending)
      }
    } else {
      // initial select sort
      this.props.gridInitSort(this.props.stateKey, dataIndex, false, sortFunction)
    }
  }

  getSortIcon = ({ dataIndex }: any) => {
    if (dataIndex === this.props.sortedColumnKey) {
      if (this.props.sortDescending) {
        return 'sort-down'
      } else {
        return 'sort-up'
      }
    } else {
      return 'sort'
    }
  }

  onToggleAllSelected = (
    visibleRows: Collection<number, Entity<T>>,
    allVisible: boolean,
    columnName: string = DEFAULT_SELECT_COLUMN
  ) => {
    const stateKey = this.props.stateKey
    if (!this.props.allSelectedPerColumn.get(columnName, false) && !this.allVisibleSelected(visibleRows, columnName)) {
      const selectedRows = visibleRows.map((r) => r.get('id')).toSet()
      this.props.gridSelect(stateKey, selectedRows, allVisible, columnName)
    } else {
      this.props.gridSelectNone(stateKey, columnName)
    }
  }

  onToggleMultipleSelected =
    (rows: Collection<number, Entity<T>>, columnName: string = DEFAULT_SELECT_COLUMN) =>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (event?: React.MouseEvent) => {
      const rowIds = rows.map((r) => r.get('id')).toSet()
      const selectedRows = this.props.selectedRows.get(columnName, Set<number>()) || Set<number>()

      if (rowIds.every((rowId) => selectedRows.has(rowId))) {
        this.props.gridSelect(this.props.stateKey, selectedRows.subtract(rowIds), false, columnName)
      } else {
        this.props.gridSelect(
          this.props.stateKey,
          selectedRows.union(rowIds),
          this.props.allSelectedPerColumn.get(columnName, false),
          columnName
        )
      }
    }

  onToggleOneSelected =
    (row: Entity<T>, columnName: string = DEFAULT_SELECT_COLUMN) =>
    (event?: React.MouseEvent) => {
      const rowId = row.get('id')
      const selectedRows = this.props.selectedRows.get(columnName, Set<number>()) || Set<number>()

      // since we are iterating the array and set several times, mutations should be more efficient
      let newSelectedRows = selectedRows
      let allSelected = this.props.allSelectedPerColumn.get(columnName, false)
      if (!(event?.nativeEvent?.shiftKey && !!this.state.lastSelectedId)) {
        if (selectedRows.has(rowId)) {
          newSelectedRows = newSelectedRows.delete(rowId)
          allSelected = false
          this.setState({ lastSelectedId: undefined })
        } else {
          newSelectedRows = newSelectedRows.add(rowId)
          this.setState({ lastSelectedId: rowId })
        }
      } else {
        const sortedData = isFunction(this.props.sortFunction)
          ? sortedSelector(this.props.data, this.props.sortFunction, this.props.sortDescending)
          : defaultSortedSelector(this.props.data, this.state.dataOrder)
        const columns = filterColumnsSelector(this.props.columns, this.props.hiddenColumns)

        const groupFunction = this.props.groupFunction || ((v: Entity<T>) => toString(v.get('id')))
        const groupSortFunction = this.props.groupSortFunction || noSorting
        const filteredData = this.getFilteredData(
          this.props.filterState,
          groupingSelector(sortedData, groupFunction, groupSortFunction),
          columns
        )
        const flattenedData = filteredData.valueSeq().flatten(true) as Collection<unknown, Entity<T>>
        const gridRowIds = flattenedData.map((row) => row.get('id')).toList()

        // we want these in order, otherwise set operations would be favorable
        const avail: List<number> = idsBetween(gridRowIds, this.state.lastSelectedId, rowId)

        if (avail.every((n) => newSelectedRows.contains(n))) {
          avail.forEach((_) => (newSelectedRows = newSelectedRows.delete(_))) // intersect
          this.setState({ lastSelectedId: undefined })
        } else {
          avail.forEach((_) => (newSelectedRows = newSelectedRows.add(_))) // union
          this.setState({ lastSelectedId: rowId })
        }
      }

      this.props.gridSelect(this.props.stateKey, newSelectedRows, allSelected, columnName)
    }

  onDragEnd = (dropId: number, row: Entity<T>, id: number, index: number, dropIndex: number) => {
    const sortedData = this.props.data.sort(lookupCompare(this.state.dataOrder))
    const dataOrder = reOrderData(sortedData, index, dropIndex)
    this.setState({
      dataOrder: dataOrder
    })
    if (isFunction(this.props.onDragEnd)) this.props.onDragEnd(dropId, row, id, index, dropIndex, dataOrder)
    else console.log('nothing to call on drag end')
  }

  hasActiveFilters = () => {
    return (this.props.filterState.get('filters') || Map())
      .filter((_, key) => this.props.columns.find((it) => it.name === key)?.disableFilter !== true)
      .some((value) => !!value.input)
  }

  allVisibleSelected = (visible: Collection<number, Entity<T>>, columnName: string = DEFAULT_SELECT_COLUMN) => {
    return (
      !visible.isEmpty() &&
      visible.every((s) => this.props.selectedRows.get(columnName, Set()).some((id) => id === s.get('id')))
    )
  }

  openSettings = () => {
    this.props.openModal(InstantColumnSettingsModal, {
      keepStateOnClose: true,
      columns: this.props.columns,
      viewKey: this.props.key || this.props.stateKey
    })
  }

  render() {
    const {
      data,
      columns: allColumns,
      stateKey = 'emptyStateKey',
      onClickRow,
      onDoubleClickRow,
      onEditRow = noop as (rowId: number, row: Entity<T>) => void,
      overflow = true,
      height = '100%',
      minHeight = 'auto',
      maxHeight,
      defaultColumnWidth = 100,
      isAssignable = false,
      onAssignRow = noop as (row: Entity<T>) => void,
      isMultiSelectEnabled = true,
      isDropEnabled = false,
      isEditable = true,
      isFullscreen = true,
      isDragNDropEnabled = true,
      isDeletable = false,
      isResizeable = true,
      style,
      isOverCurrent = false,
      onDeleteRow = noop as (row: Entity<T>) => void,
      dragPreview,
      sorting = true,
      onUnassignRow = noop as (row: Entity<T>) => void,
      isUnassignable = false,
      shipmentPriorities,
      groupFunction = (v: Entity<T>) => toString(v.get('id')),
      groupSortFunction = noSorting,
      filterState,
      showHeader = true,
      actions,
      rowBackgroundColorFunction,
      rowBackgroundHoverColorFunction,
      enableColumnFilters = false,
      hiddenColumns,
      isLoading = false,
      loadingText,
      showCount = false,
      countI18nKey = 'consignment.numberOfConsignments',
      showSettingsButton = false,
      noDataText = i18next.t('consignment.noShipmentsFound'),
      showEmptyGrid = false,
      infiniteScrollEnabled = true,
      isGroupedElementClickDisabled,
      courierBreak = Map()
    } = this.props

    if (isLoading)
      return (
        <div style={{ minHeight: minHeight, height: height, background: 'white', ...style }}>
          <Loading loadingText={loadingText} />
        </div>
      )
    else if (!isLoading && !showEmptyGrid && (!data || isEmpty(data)))
      return (
        <ResizableContainer
          direction={'vertical'}
          style={{ textAlign: 'center', padding: 10, minHeight: minHeight, height: height }}
        >
          {noDataText}
        </ResizableContainer>
      )

    const sortedData = isFunction(this.props.sortFunction)
      ? sortedSelector(data, this.props.sortFunction, this.props.sortDescending)
      : defaultSortedSelector(data, this.state.dataOrder)
    const columns = filterColumnsSelector(allColumns, hiddenColumns)

    const filteredData = this.getFilteredData(
      filterState,
      groupingSelector(sortedData, groupFunction, groupSortFunction),
      columns
    )
    const flattenedFilteredData = filteredData.toList().flatten(true) as List<Entity<T>>

    const GridRow =
      isDragNDropEnabled && isDropEnabled
        ? InstantGridDroppableRow
        : isDragNDropEnabled
          ? InstantGridDraggableRow
          : InstantGridRow
    const GridGroupRow = isDragNDropEnabled ? InstantGridDraggableGroupRow : InstantGridGroupRow

    let index = 0
    const allSelectedExceptDefaultRows = this.props.selectedRows.delete(DEFAULT_SELECT_COLUMN).valueSeq().flatten()

    const rows = filteredData.map((group: Collection<number, Entity<T>>, groupKey: string) => {
      const idx = index++
      const commonProps = {
        defaultColumnWidth,
        onDragEnd: this.onDragEnd,
        onEditRow,
        index: idx,
        isOverCurrent,
        stateKey,
        columns,
        isAssignable,
        onAssignRow,
        isMultiSelectEnabled,
        isEditable,
        isDragNDropEnabled,
        isDropEnabled,
        isDeletable,
        onUnassignRow,
        isUnassignable,
        onDeleteRow,
        dragPreview,
        actions,
        onClickRow,
        onDoubleClickRow,
        isGroupedElementClickDisabled
      }
      if (group.count() > 1) {
        const id = groupKey + idx
        return (
          <GridGroupRow
            id={id}
            key={idx}
            group={group}
            groupKey={groupKey}
            allSelected={this.props.allSelectedPerColumn.get(DEFAULT_SELECT_COLUMN, false)}
            onSelectOrderRow={this.onToggleMultipleSelected}
            shipmentPriorities={shipmentPriorities}
            selectedRows={this.props.selectedRows}
            disabledRows={this.props.disabledRows}
            {...commonProps}
          />
        )
      } else if (group.count() == 1) {
        const d = group.first<Entity<T>>()
        const isInstantBreakRow = d.has('type')
          ? (d as Entity<IRoutePointWithExtraDataProps>).get('type') === 'rest_break'
          : false
        const id = d.get('id')
        const selectedRows =
          allSelectedExceptDefaultRows && allSelectedExceptDefaultRows.has(id)
            ? this.props.selectedRows
            : Map<string, Set<number>>()
        const breakRowColSpan =
          columns.size +
          (isAssignable ? 1 : 0) +
          (isDeletable ? 1 : 0) +
          (isUnassignable ? 1 : 0) +
          (isEditable ? 1 : 0)

        return isInstantBreakRow ? (
          <InstantBreakRow
            colSpan={breakRowColSpan}
            courierBreak={courierBreak}
            editBreak={this.props.editBreak ?? noop}
            row={d}
            updatedBreak={this.props.updatedBreak}
            key={idx}
            newUnoptimizedBreak={this.props.newUnoptimizedBreak}
            deleteBreakFromRoute={this.props.deleteBreakFromRoute ?? noop}
            canEditBreak={this.props.canEditBreak ?? false}
          />
        ) : (
          <GridRow
            id={id}
            key={idx}
            row={d}
            isSelected={
              this.props.allSelectedPerColumn.get(DEFAULT_SELECT_COLUMN, false) ||
              this.props.selectedRows.get(DEFAULT_SELECT_COLUMN, Set()).has(id)
            }
            onSelectOrderRow={this.onToggleOneSelected}
            priority={shipmentPriorities ? shipmentPriorities.get(id, 'normal') : undefined}
            rowBackgroundColor={(rowBackgroundColorFunction && rowBackgroundColorFunction(d)) || undefined}
            rowBackgroundHoverColor={
              (rowBackgroundHoverColorFunction && rowBackgroundHoverColorFunction(d)) || undefined
            }
            selectedRows={selectedRows}
            disabledRows={this.props.disabledRows}
            {...commonProps}
          />
        )
      }
    })

    const showSettings = showSettingsButton || isEditable || actions

    const allSelected =
      this.props.allSelectedPerColumn.get(DEFAULT_SELECT_COLUMN, false) ||
      this.allVisibleSelected(flattenedFilteredData)

    return (
      <div style={{ background: 'white', ...style }}>
        {this.hasActiveFilters() && (
          <div
            style={{
              padding: '2px',
              textAlign: 'center',
              backgroundColor: vars.colors.warningHoverRow,
              color: vars.colors.black
            }}
          >
            {i18next.t('instant.instantGrid.filtersActive')}.&nbsp;
            <a
              onClick={(e) => {
                e.preventDefault()
                this.props.clearAllFilters(stateKey)
              }}
              style={{ color: vars.colors.black }}
              href="#"
            >
              {i18next.t('instant.instantGrid.clearFilters')}
            </a>
          </div>
        )}
        <Grid
          height={height}
          minHeight={minHeight}
          overflowY={overflow}
          ref={this.gridContainer}
          isResizeable={isResizeable}
          style={{ maxHeight }}
        >
          {showCount && <div style={{ marginBottom: '5px' }}>{i18next.t(countI18nKey, { number: rows.count() })}</div>}
          <Table isFullscreen={isFullscreen}>
            {showHeader && (
              <thead>
                <tr>
                  {shipmentPriorities && <TH style={{ width: '20px' }}>&nbsp;</TH>}
                  {isDragNDropEnabled && <TH style={{ width: '20px' }}>&nbsp;</TH>}
                  {isMultiSelectEnabled && (
                    <TH style={{ width: '20px' }}>
                      <InstantGridMultiSelectCheckbox
                        selected={false}
                        value={`selectAll_${allSelected}`}
                        checked={allSelected}
                        onChange={() =>
                          this.onToggleAllSelected(
                            flattenedFilteredData,
                            flattenedFilteredData.count() === data.count()
                          )
                        }
                      />
                    </TH>
                  )}
                  {columns.map((column) => {
                    const columnIndex = toString(column.dataIndex)
                    const disableFilter = column.disableFilter || false
                    const hasFilter = !!filterState.getIn(['filters', columnIndex, 'input'])
                    if (column.hidden) {
                      return null
                    }
                    const sortFunction = sorting ? sortByColumn(column.dataIndex, column.valueRenderer) : null

                    const hasRightAlignedSortingArrow = column.hasOwnProperty('rightAlignSortingArrow')
                      ? column.rightAlignSortingArrow
                      : true
                    const extraStyling: CSSProperties = hasRightAlignedSortingArrow
                      ? {
                          background: `linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, ${vars.colors.white} 35%, ${vars.colors.white} 100%)`,
                          paddingLeft: 2,
                          position: 'absolute',
                          right: 0
                        }
                      : {}

                    return (
                      <TH
                        key={`IG-header-${columnIndex}_${column.name}`}
                        style={{
                          width: column.width || '',
                          maxWidth: column.maxWidth || column.width || '150px',
                          minWidth: column.minWidth || column.width || '',
                          overflow: 'hidden',
                          paddingRight: hasRightAlignedSortingArrow ? 10 : 0
                        }}
                      >
                        {isFunction(column.titleRenderer) ? (
                          column.titleRenderer({
                            title: column.legend || column.name,
                            callbackFn: () =>
                              this.onToggleAllSelected(
                                flattenedFilteredData,
                                flattenedFilteredData.count() === data.count(),
                                columnIndex
                              )
                          })
                        ) : (
                          <>
                            <FilterPopover
                              key={`column_${columnIndex}`}
                              isOpen={filterState.getIn(['popovers', columnIndex]) || false}
                              close={() => this.props.hidePopover(this.props.stateKey, columnIndex)}
                              clear={() => this.props.clearFilter(this.props.stateKey, columnIndex)}
                              filterValue={
                                (!disableFilter && filterState.getIn(['filters', columnIndex, 'input'])) || ''
                              }
                              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                this.props.setFilter(stateKey, columnIndex, event.target.value)
                              }
                            >
                              <span
                                style={{
                                  cursor: `${!disableFilter ? 'pointer' : 'inherit'}`,
                                  textDecoration: 'none',
                                  color: !disableFilter && hasFilter ? vars.colors.brown : vars.colors.black
                                }}
                                title={column.legend || column.name}
                                onClick={() => {
                                  !disableFilter && this.props.showPopover(this.props.stateKey, columnIndex)
                                }}
                              >
                                {column.name}
                              </span>
                            </FilterPopover>

                            {isFunction(sortFunction) ? (
                              <span
                                style={{
                                  cursor: 'pointer',
                                  textDecoration: 'none',
                                  color: vars.colors.black,
                                  ...extraStyling
                                }}
                                onClick={this.onSortColumnClick(sortFunction, column.dataIndex)}
                              >
                                &nbsp;
                                <FontAwesomeIcon icon={this.getSortIcon(column)} />
                              </span>
                            ) : (
                              ''
                            )}
                          </>
                        )}
                      </TH>
                    )
                  })}
                  {isAssignable && <TH style={{ width: '10px' }} />}
                  {isDeletable && <TH style={{ width: '10px' }} />}
                  {isUnassignable && <TH style={{ width: '10px' }} />}
                  {showSettings && (
                    <TH style={{ width: '30px' }}>
                      {enableColumnFilters && <OpenSettingsButton openSettings={this.openSettings} />}
                    </TH>
                  )}
                </tr>
              </thead>
            )}
            {(infiniteScrollEnabled && (
              <InstantGridInfiniteScroll
                items={rows}
                totalItemsCount={count(rows)}
                scrollRef={this.gridContainer}
                height={height}
              />
            )) || <tbody>{rows.valueSeq()}</tbody>}
          </Table>
        </Grid>
        <Modal keepStateOnClose={true} large={true}>
          {/* The way our modals works this element will be cloned by Modal and have its props injected.
               But typescript wants props here as well, so use dummy values =/
          */}
          <InstantColumnSettingsModal viewKey={'todo'} columns={List()} />
        </Modal>
      </div>
    )
  }

  private getFilteredData(
    filterState: FilterState,
    groupedData: Map<string, Collection<number, Entity<T>>>,
    columns: List<ColumnDefinition<T>>
  ) {
    return isNotEmpty(filterState.get('filters', Map()))
      ? groupedData.filter((group) =>
          group.some((row) =>
            columns.every((column) => {
              const value = row.get(column.dataIndex)
              const filter = filterState.getIn(['filters', toString(column.dataIndex)])
              if (filter) {
                const rendered = isFunction(column.valueRenderer)
                  ? column.valueRenderer({ column: toString(column.dataIndex), row, value })
                  : toString(value)
                return matchesOneOf(filter.filters, rendered)
              }
              return true
            })
          )
        )
      : groupedData
  }
}

export const noop = () => {}
export const noop2 = () => () => {}

export function createInstantGrid<T>() {
  return connect<StateProps<T> & OwnProps<T>, DispatchProps<T>, OwnProps<T>, AppStateType>(
    (state, ownProps) => {
      const hiddenColumns = getHiddenColumns(state, ownProps.key || ownProps.stateKey) as List<keyof T>
      const stateKey = ownProps.stateKey
      const grid = gridByIdSelector(state, stateKey)
      const filterState: FilterState = state.getIn(['filter', stateKey]) || Map()

      return {
        sortFunction: grid.get('sortFunction') || undefined,
        sortedColumnKey: grid.get('sortedColumnKey') || undefined,
        sortDescending: grid.get('sortDescending') || false,
        allSelectedPerColumn: grid.get('allSelectedPerColumn') || Map(),
        selectedRows: grid.get('selectedIdsPerColumn') || Map(),
        disabledRows: grid.get('disabledIds') || Set(),
        filterState,
        hiddenColumns,
        ...ownProps
      }
    },
    {
      gridInit,
      gridSelect,
      gridSelectNone,
      gridSortDescending,
      gridResetSort,
      gridInitSort,
      showPopover,
      hidePopover,
      setFilter,
      clearFilter,
      clearAllFilters,
      openModal
    }
    // @ts-expect-error
  )(InstantGrid as new (props: Props<T>) => InstantGrid<T>)
}

export default createInstantGrid()
