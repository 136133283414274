import styled, { keyframes } from 'styled-components'
import vars from '../styles/variables'

export const animation = keyframes`
  from {
    transform: translateY(0) translateX(-100%);
  }

  to {
    transform: translateY(0) translateX(0);
  }
`

export const ConsignmentDrawer = styled.div`
  background: ${vars.colors.gray};
  max-height: 95vh;
  overflow-y: scroll;
  min-width: 20vw;
  padding: 0.5rem;
  /*z-index: -1;*/
  display: grid;
  grid-gap: 5px;

  transition: transform 200ms;
  animation: ${animation} 200ms forwards;

  grid-template-columns: 1fr;
  grid-template-areas:
    'events'
    'delivery'
    'pickup'
    'slot'
    'info'
    'tracking';
`

export const ConsignmentDrawerClose = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  padding: 0 0.5rem;
  line-height: 1;
  cursor: pointer;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  z-index: 9999;

  @media (min-width: 700px) {
    display: none;
  }

  &:after {
    position: absolute;
    left: 0.45rem;
    content: '×';
    font-size: 1.8rem;
  }
`

export const GridConsignmentEvents = styled.div`
  grid-area: events;
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 5px;
  border-radius: ${vars.borderRadius.all.m};
  padding: 0.5rem;
  box-shadow: ${vars.boxShadow.customerMedium};
  background: ${vars.colors.white};
`

export const GridConsignmentEvent = styled.div`
  background: ${vars.colors.event};
  padding: 0.5rem;
  border-radius: ${vars.borderRadius.all.m};
`

interface GridConsignmentBoxProps {
  area: string
}

export const GridConsignmentBox = styled.div<GridConsignmentBoxProps>`
  grid-area: ${(props) => props.area};
  grid-template-rows: 1fr;
  grid-gap: 5px;
  border-radius: ${vars.borderRadius.all.m};
  padding: 0.5rem;
  box-shadow: ${vars.boxShadow.customerMedium};
  background: ${vars.colors.white};
`
