import { Seq } from 'immutable'
import { OrderIdType, OrderNote } from '../types/coreEntitiesTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'

export const ordersNotesSelector: (state: AppStateType) => Seq.Indexed<OrderNote> = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'orderNotes']),
  (orderNote) => (orderNote ? orderNote.valueSeq() : Seq.Indexed<OrderNote>([])) as Seq.Indexed<OrderNote>
)

export const orderNoteFromOrderIdSelector: (state: AppStateType, orderId: number) => OrderNote | undefined =
  createImmutableEqualSelector(
    ordersNotesSelector,
    (state: AppStateType, orderId: OrderIdType) => orderId,
    (orderNotes, orderId) => orderNotes.find((row) => row.get('orderId') === orderId)
  )
