import { Map, List } from 'immutable'
import { DangerousGoods, OrderIdType } from '../types/coreEntitiesTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'

export const dangerousGoodsSelector: (state: AppStateType, orderId: OrderIdType | undefined) => List<DangerousGoods> =
  createImmutableEqualSelector(
    (state: AppStateType) => state.getIn(['entities', 'dangerousGoods']) as Map<string, DangerousGoods>,
    (state: AppStateType, orderId: OrderIdType | undefined) => orderId,
    (dangerousGoods, orderId: OrderIdType | undefined) =>
      dangerousGoods && orderId
        ? dangerousGoods
            .valueSeq()
            .filter((it) => it.get('orderId') === orderId)
            .toList()
        : List([])
  )
