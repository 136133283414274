import { List, Map, Set } from 'immutable'
import { H2OrderForm, H2OrderFormProps } from './H2BookingTypes'
import { ImmutableMap, ImmutableMapFactory } from '../../../types/immutableTypes'
import { GroupRecipientProps } from '../MultistopGroupForm'
import { OrderAction } from '../bookingSupport'
import { MeasurementUnit } from '../../../domain/measurementUnits'
import trim from 'lodash/trim'
import {
  bookingFormatLocalDateTime,
  emptyVAS,
  getIndicatorText,
  getMeasurementsTotalHeight,
  getMeasurementsTotalLength,
  getMeasurementsTotalVolume,
  getMeasurementsTotalWeight,
  getMeasurementsTotalWidth,
  getMeasurementVolume
} from '../bookingOrderFunctions'
import { isNotEmpty } from '../../../utils/stringUtils'
import { isNotEmpty as isNotEmptyCollection } from '../../../utils/collectionUtils'
import { emptyMeasurements, IMeasurement, MeasurementProps, ValueAddedService, VasProps } from '../bookingOrderTypes'
import toString from 'lodash/toString'
import { Department } from '../../../types/coreEntitiesTypes'

export const h2BookingForm = 'h2BookingForm'
export const h2BookingSubUrl = 'h2-booking'

export const customerComponentNames = List.of<keyof H2OrderFormProps>('customerId')
export const serviceComponentNames = List.of<keyof H2OrderFormProps>('serviceCode', 'serviceDate')
export const searchOrderComponentNames = List.of<keyof H2OrderFormProps>('shipmentOrPackageId')
export const h2measurementComponentNames = List.of<keyof H2OrderFormProps>('shipmentId', 'measurements')

const departmentsWithAlternativeDeliveryTimes = Set.of(
  '181738 DD FOLLO D.ENHET',
  'LSO H2',
  'Moss H2',
  'Oslo Sentrum H2',
  'Rud H2',
  'Bergen H2',
  'Fredrikstad H2'
)
const pickupTimeEarliest = (departmentAlystraId: string) =>
  departmentsWithAlternativeDeliveryTimes.contains(departmentAlystraId) ? '13:00' : '15:00'
const pickupTimeLatest = (departmentAlystraId: string) =>
  departmentsWithAlternativeDeliveryTimes.contains(departmentAlystraId) ? '22:00' : '21:00'
const deliveryTimeEarliest = (departmentAlystraId: string) =>
  departmentsWithAlternativeDeliveryTimes.contains(departmentAlystraId) ? '15:00' : '17:00'
const deliveryTimeLatest = (departmentAlystraId: string) =>
  departmentsWithAlternativeDeliveryTimes.contains(departmentAlystraId) ? '22:00' : '21:00'

export const recipientFields = List.of<keyof H2OrderFormProps>(
  'deliveryName',
  'deliveryAddress',
  'deliveryZipArea',
  'deliveryZipCode',
  'deliveryCountryCode',
  'deliveryPhone',
  'deliveryContactPerson',
  'deliveryInstructions'
)

export const pickupFields = List.of<keyof H2OrderFormProps>(
  'pickupName',
  'pickupAddress',
  'pickupZipArea',
  'pickupZipCode',
  'pickupCountryCode',
  'pickupPhone',
  'pickupContactPerson',
  'pickupInstructions'
)

export const createConsignmentSet = (
  formValues: H2OrderForm,
  pickupPhone: string,
  pickup: ImmutableMap<GroupRecipientProps>,
  deliveryPhone: string,
  recipient: ImmutableMap<GroupRecipientProps>,
  orderAction: OrderAction,
  department: Department
) => {
  const measurements = formValues.get('measurements')
  const serviceDate = formValues.get('serviceDate')

  return {
    updateIndicator: getIndicatorText(orderAction),
    shipmentId: formValues.get('shipmentId'),
    controlTotals: {
      weight: { value: getMeasurementsTotalWeight(measurements), unit: MeasurementUnit.KGM },
      volume: { value: getMeasurementsTotalVolume(measurements), unit: MeasurementUnit.DMQ },
      dimensions: {
        height: { value: getMeasurementsTotalHeight(measurements), unit: MeasurementUnit.CMT },
        length: { value: getMeasurementsTotalLength(measurements), unit: MeasurementUnit.CMT },
        width: { value: getMeasurementsTotalWidth(measurements), unit: MeasurementUnit.CMT }
      }
    },
    consignments: measurements.map((measurement) => ({
      serviceCode: formValues.get('serviceCode'),
      serviceDate: isNotEmpty(serviceDate) ? serviceDate : undefined,
      additionalServiceCodes: formValues
        .get('additionalServices')
        .filter((vas) => isNotEmpty(vas.get('vasCode')))
        .map((vas) => ({
          vasCode: vas.get('vasCode') || '',
          quantity: vas.get('quantity') || '1',
          pureVas: vas.get('pureVas') || false
        })),
      packageId: measurement.get('packageId'),
      consignor: {
        partyId: formValues.get('customerId'),
        alystraPartyId: formValues.get('customerId'),
        name: pickup.get('name'),
        address: pickup.get('address'),
        city: pickup.get('city'),
        postalCode: trim(pickup.get('zipCode')),
        countryCode: pickup.get('countryCode'),
        phone: pickup.get('secondPhone'),
        cellPhone: pickupPhone,
        contactPerson: pickup.get('contactPerson')
      },
      consignee: {
        name: recipient.get('name'),
        address: recipient.get('address'),
        city: recipient.get('city'),
        postalCode: trim(recipient.get('zipCode')),
        countryCode: recipient.get('countryCode'),
        phone: recipient.get('secondPhone'),
        cellPhone: deliveryPhone,
        contactPerson: recipient.get('contactPerson')
      },
      pickupInstructions: pickup.get('instructions') || '',
      deliveryInstructions: recipient.get('instructions') || '',
      pickupDateEarliest: serviceDate
        ? bookingFormatLocalDateTime(serviceDate, pickupTimeEarliest(department.get('alystraId')))
        : undefined,
      pickupDateLatest: serviceDate
        ? bookingFormatLocalDateTime(serviceDate, pickupTimeLatest(department.get('alystraId')))
        : undefined,
      deliveryDateEarliest: serviceDate
        ? bookingFormatLocalDateTime(serviceDate, deliveryTimeEarliest(department.get('alystraId')))
        : undefined,
      deliveryDateLatest: serviceDate
        ? bookingFormatLocalDateTime(serviceDate, deliveryTimeLatest(department.get('alystraId')))
        : undefined,
      packageMeasures: {
        presetDimension: measurement.get('presetDimension'),
        weight: { value: measurement.get('weight') || 0, unit: MeasurementUnit.KGM },
        volume: { value: getMeasurementVolume(measurement) || 0, unit: MeasurementUnit.DMQ },
        dimensions: {
          height: { value: measurement.get('height') || 0, unit: MeasurementUnit.CMT },
          length: { value: measurement.get('length') || 0, unit: MeasurementUnit.CMT },
          width: { value: measurement.get('width') || 0, unit: MeasurementUnit.CMT }
        }
      }
    }))
  }
}

export const getH2FormData = (orderData: Map<string, any>, deliveryPhoneInForm: string): H2OrderForm =>
  ImmutableMapFactory<H2OrderFormProps>({
    pickupName: orderData.get('pickupName'),
    pickupAddress: orderData.get('pickupAddress'),
    pickupZipArea: orderData.get('pickupZipArea'),
    pickupZipCode: orderData.get('pickupZipCode'),
    pickupCountryCode: orderData.get('pickupCountryCode')?.toLowerCase(),
    deliveryName: orderData.get('deliveryName'),
    deliveryAddress: orderData.get('deliveryAddress'),
    deliveryZipArea: orderData.get('deliveryZipArea'),
    deliveryZipCode: orderData.get('deliveryZipCode'),
    deliveryCountryCode: orderData.get('deliveryCountryCode')?.toLowerCase(),
    deliveryPhone: orderData.get('deliveryPhone') || deliveryPhoneInForm,
    customerId: orderData.get('customerId'),
    serviceCode: orderData.get('serviceCode'),
    measurements: getMeasurementsFormData(orderData.get('measurements')),
    shipmentId: orderData.get('shipmentId'),
    additionalServices: getAdditionalServicesFormData(orderData.get('additionalServices'))
  })

const getMeasurementsFormData = (measurements: List<Map<string, any>>): List<IMeasurement> =>
  isNotEmptyCollection(measurements)
    ? measurements.map((m) =>
        ImmutableMapFactory<MeasurementProps>({
          packageId: toString(m.get('packageId')),
          weight: toString(m.get('weight')) || '0',
          height: toString(m.get('height')) || '0',
          length: toString(m.get('length')) || '0',
          width: toString(m.get('width')) || '0',
          presetDimension: null,
          specificationDescription: '',
          volume: getMeasurementVolume(m).toString()
        })
      )
    : List([emptyMeasurements()])

const getAdditionalServicesFormData = (additionalServices: List<Map<string, any>>): List<ValueAddedService> =>
  isNotEmptyCollection(additionalServices)
    ? additionalServices.map((vas) =>
        ImmutableMapFactory<VasProps>({
          vasCode: vas.get('vasCode') || '',
          quantity: vas.get('quantity') || '',
          pureVas: vas.get('pureVas') || false
        })
      )
    : List([emptyVAS()])
