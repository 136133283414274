import React, { Component } from 'react'
import { DirectionsRenderer, DirectionsService } from '@react-google-maps/api'
import { Map, Seq } from 'immutable'
import { Slot } from '../types/coreEntitiesTypes'

type DirectionsRequest = google.maps.DirectionsRequest
type DirectionsResult = google.maps.DirectionsResult

interface Props {
  waypoints: Seq.Indexed<Map<string, any>>
  selectedSlot: Slot | undefined
}

interface State {
  response: DirectionsResult | null
  travelMode: string
}

export default class extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      response: null,
      travelMode: 'DRIVING'
    }

    this.directionsCallback = this.directionsCallback.bind(this)
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.selectedSlot?.get('id') !== prevProps.selectedSlot?.get('id')) this.setState({ response: null })
  }

  createDirectionsRequest(waypoints: any) {
    if (!waypoints || waypoints.length <= 1) {
      return
    }
    const firstWaypoint = waypoints.shift()
    const lastWaypoint = waypoints.pop()
    return {
      origin: {
        lat: firstWaypoint.lat,
        lng: firstWaypoint.lng
      },
      destination: {
        lat: lastWaypoint.lat,
        lng: lastWaypoint.lng
      },
      waypoints: waypoints.map((wp: any) => {
        return {
          location: {
            lat: wp.lat,
            lng: wp.lng
          }
        }
      }),
      provideRouteAlternatives: false,
      travelMode: 'DRIVING',
      drivingOptions: {
        departureTime: new Date()
      },
      optimizeWaypoints: false,
      unitSystem: google.maps.UnitSystem.METRIC
    } as DirectionsRequest
  }

  directionsCallback(response: any) {
    if (response !== null) {
      if (response.status === 'OK') {
        this.setState(() => ({
          response: response
        }))
      }
    }
  }

  render() {
    const { waypoints } = this.props

    if (waypoints.isEmpty()) {
      return null
    }

    let previousLocation: string = '_'
    const waypointsJs = waypoints
      .toList()
      .toJS()
      .filter((wp: any) => {
        const location = wp.lat + '_' + wp.lng
        if (previousLocation === location) return false
        previousLocation = location
        return true
      })

    //We're only allowed to ask for 23 waypoints per request, plus origin, plus destination, so 25 total
    const waypointsSplitInGroupsOf25: any[][] = []
    while (waypointsJs.length > 0) {
      waypointsSplitInGroupsOf25.push(waypointsJs.splice(0, 25))
    }

    return waypointsSplitInGroupsOf25
      .map((wps, index) => {
        if (!wps || wps.length === 0) {
          return null
        }
        const directionsRequest = this.createDirectionsRequest(wps)

        return (
          <>
            {directionsRequest && this.state.response === null && (
              <DirectionsService
                key={'DirectionsService_' + wps[0]?.id + '_' + index}
                options={directionsRequest as DirectionsRequest}
                callback={this.directionsCallback}
              />
            )}
            {this.state.response !== null && (
              <DirectionsRenderer
                key={'DirectionsRenderer_' + wps[0]?.id + '_' + index}
                options={{
                  directions: this.state.response,
                  suppressMarkers: true
                }}
              />
            )}
          </>
        )
      })
      .filter((notNull) => notNull)
  }
}
