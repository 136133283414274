import { H3 } from '../../../primitives/Headings'
import i18next from 'i18next'
import { SmallValidationError, ValidationError } from '../../../primitives/ErrorMessages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import AutoSuggestWrapperForInternalBooking from '../../../components/AutoSuggestWrapperForInternalBooking'
import AutoSuggestWrapperForSubcustomer from '../../../components/AutoSuggestWrapperForSubcustomer'
import { required } from '../../../utils/inputValidation'
import { AUTOSUGGEST_CUSTOMERS } from '../../../actions/actionTypes'
import {
  getCustomerLabelWithoutSubcustomer,
  getCustomerSuggestionLabelWithoutSubcustomer,
  anyMandatorySubcustomers
} from '../bookingOrderFunctions'
import { List, Map } from 'immutable'
import { ClearInstantBookingFormButton } from '../../../components/ClearInstantBookingFormButton'
import InputGroup from '../InputGroup'
import React from 'react'
import { ICustomer } from '../bookingOrderTypes'
import { DepartmentIdType } from '../../../types/coreEntitiesTypes'
import CustomerSpecificInformation from './CustomerSpecificInformation'
import { bookingPageComponentNames } from '../bookingSupport'

interface Props {
  creditBlockedCustomer: boolean
  onCustomerChanged: (customer: ICustomer) => void
  departmentId: DepartmentIdType
  setPageStateValue: (key: string, value: any) => void
  mergeFormValues: (key: string, value: any) => void
  selectedCustomerId: number
  subCustomersForSelectedAlystraId: Map<string, any>
  originalCustomerInformation: string | undefined
  customerInfo: string | undefined
  updateCustomerInformation: (customerId: number, information: string) => void
  showEditCustomerSpecificInformation: boolean
  clearComponents: (components: List<string>) => void
}

const BookingFormCustomer = ({
  creditBlockedCustomer,
  onCustomerChanged,
  departmentId,
  setPageStateValue,
  mergeFormValues,
  subCustomersForSelectedAlystraId,
  selectedCustomerId,
  originalCustomerInformation,
  customerInfo,
  updateCustomerInformation,
  showEditCustomerSpecificInformation,
  clearComponents
}: Props) => {
  const subcustomers = anyMandatorySubcustomers(subCustomersForSelectedAlystraId)
    ? subCustomersForSelectedAlystraId.filter((any) => any.get('alystraSubcustomer') !== '')
    : subCustomersForSelectedAlystraId

  return (
    <InputGroup aria-label="Select customer form">
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <H3 style={{ paddingRight: '0.3em' }}>{i18next.t('instant.booking.customer')}</H3>
        {creditBlockedCustomer && (
          <ValidationError marginLeft={true}>
            <FontAwesomeIcon icon="exclamation-triangle" style={{ marginRight: '0.3em' }} />
            {i18next.t('instant.booking.creditBlockedCustomer')}
          </ValidationError>
        )}
      </div>

      <div>
        <div>
          <Control.text
            placeholder={i18next.t('instant.booking.customerPlaceholder')}
            component={AutoSuggestWrapperForInternalBooking}
            model=".customerId"
            id="createOrderForm.customerId"
            validators={{ required }}
            labeled={true}
            searchType={AUTOSUGGEST_CUSTOMERS}
            getLabel={getCustomerLabelWithoutSubcustomer}
            onSelect={onCustomerChanged}
            departmentId={departmentId}
            suggestionLabel={getCustomerSuggestionLabelWithoutSubcustomer}
            defaultValue={''}
            onChange={() => {
              setPageStateValue('selectedCustomerId', null)
              setPageStateValue('originalCustomerInformation', null)
              mergeFormValues(
                'createOrderForm',
                Map({
                  customerInfo: '',
                  subcustomer: ''
                })
              )
            }}
          />
          <Errors
            className="errors"
            model=".customerId"
            show="touched"
            wrapper={SmallValidationError}
            messages={{ required: `${i18next.t('application.required')}` }}
          />
        </div>
        {selectedCustomerId &&
          subCustomersForSelectedAlystraId.filter((sub) => sub.get('alystraSubcustomer')).count() > 0 && (
            <div style={{ paddingTop: '0.75em' }}>
              <Control.text
                placeholder={i18next.t('instant.booking.subcustomerPlaceholder')}
                model=".subcustomer"
                component={AutoSuggestWrapperForSubcustomer}
                onSelect={onCustomerChanged}
                setPageStateValue={setPageStateValue}
                subCustomersForSelectedAlystraId={subcustomers}
                validators={{
                  required: anyMandatorySubcustomers(subCustomersForSelectedAlystraId) ? required : () => true,
                  unknownSubcustomer: (value: string) =>
                    value == '' || subcustomers.some((any) => any.get('alystraSubcustomer') == value)
                }}
              />
              <Errors
                className="errors"
                model=".subcustomer"
                wrapper={SmallValidationError}
                messages={{
                  required: `${i18next.t('application.required')}`,
                  unknownSubcustomer: `${i18next.t('instant.booking.invalidSubcustomer')}`
                }}
              />
            </div>
          )}
        <div style={{ paddingTop: '16px' }}>
          <CustomerSpecificInformation
            mergeFormValues={mergeFormValues}
            originalCustomerInformation={originalCustomerInformation}
            customerInfo={customerInfo}
            setPageStateValue={setPageStateValue}
            selectedCustomerId={selectedCustomerId}
            updateCustomerInformation={updateCustomerInformation}
            showEditCustomerSpecificInformation={showEditCustomerSpecificInformation}
          />
        </div>
      </div>
      <ClearInstantBookingFormButton clearComponents={clearComponents} components={bookingPageComponentNames} />
    </InputGroup>
  )
}

export default BookingFormCustomer
