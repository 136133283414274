import { List } from 'immutable'
import * as React from 'react'
import Select from '../../../primitives/Select'
// @ts-expect-error
import { Control } from 'react-redux-form/lib/immutable'
import i18next from 'i18next'

interface PreplannedUnitSelectProps {
  model: string
  id: string
  units: List<any>
  onSelectCourier: (courier: any) => void
  style?: React.CSSProperties
}

export const PreplannedUnitSelect = ({ model, id, units, onSelectCourier, style }: PreplannedUnitSelectProps) => (
  <Select expand>
    <Control.select
      model={model}
      id={id}
      style={style}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        onSelectCourier(e.target.value)
      }}
      defaultValue={''}
    >
      <option value={''}>{i18next.t('instant.booking.prePlannedToCourier')}</option>
      {units &&
        units.map((unit: any) => {
          const unitId = unit.get('id')
          return (
            <option key={`courier-${unitId}`} value={unit.get('alystraId')}>
              {`${unit.get('alystraId')} - (${unit.get('name')})`}
            </option>
          )
        })}
    </Control.select>
  </Select>
)
