import { isProduction } from '@glow/common'
import { FormikRadioSelect, formikValidationRequired } from '@glow/formik-components'
import { LayoutFormRow, Option } from '@glow/ui-components'
import { useFormikContext } from 'formik'
import i18next from 'i18next'
import { List } from 'immutable'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getUnits } from '../../../../actions/creators/helpers'
import { SYSTEMBOLAGET_ALYSTRA_ID } from '../../../../components/newShipmentModal/panels/ManualOverrideUtils'
import { CourierUserWithActionProps } from '../../../../components/unit/CourierUserGrid'
import { courierUsersWithAlcoholDeliveryEducationFilterByUnitIdSelectorList } from '../../../../components/unit/UnitHelpers'
import { useAppDispatch } from '../../../../reducers/redux-hooks'
import { consignmentsByOrderIdsSelector } from '../../../../selectors/consignmentSelectors'
import {
  activeUnitsWhereUsersCanLoginSelectorSorted,
  unitsWithoutUsersInDepartmentSelectorSorted
} from '../../../../selectors/unitSelectors'
import { DepartmentIdType, OrderIdType } from '../../../../types/coreEntitiesTypes'
import { ImmutableMap } from '../../../../types/immutableTypes'
import { AppStateType } from '../../../../utils/appStateReduxStore'
import { ManualOverrideFormProps } from '../ManualOverrideView'

interface SelectManualEventUnitProps {
  orderIds: List<OrderIdType>
  departmentIds: List<DepartmentIdType>
}

function formatUserOptionText(user: ImmutableMap<CourierUserWithActionProps>): string {
  if (user.get('driverId')) return user.get('name') + ' - ' + user.get('driverId')
  else return user.get('name')
}

export function UnitDriverForm({ orderIds, departmentIds }: SelectManualEventUnitProps) {
  const dispatch = useAppDispatch()
  const { values, setFieldValue } = useFormikContext<ManualOverrideFormProps>()

  useEffect(() => {
    dispatch(getUnits(departmentIds.toSet()))
  }, [departmentIds])

  const consignments = useSelector((state: AppStateType) => {
    return consignmentsByOrderIdsSelector(state, orderIds)
  })
  const courierMustHaveAlcoholEducation = consignments.some((c) => c.get('custAlystraId') == SYSTEMBOLAGET_ALYSTRA_ID)
  const units = useSelector((state: AppStateType) =>
    isProduction()
      ? unitsWithoutUsersInDepartmentSelectorSorted(state, departmentIds)
      : activeUnitsWhereUsersCanLoginSelectorSorted(state, departmentIds)
  )
  const unitOptions: Option[] = useMemo(
    () =>
      units.toArray().map((unit) => ({
        code: unit.get('id') + '',
        label: `${unit.get('name')} - ${unit.get('alystraId')}`
      })),
    [units]
  )
  const users = useSelector((state: AppStateType) =>
    courierUsersWithAlcoholDeliveryEducationFilterByUnitIdSelectorList(
      state,
      parseInt(values.unitId),
      courierMustHaveAlcoholEducation
    )
  )

  const userOptions: Option[] = useMemo(
    () =>
      users.toArray().map((user) => ({
        code: user.get('id'),
        label: formatUserOptionText(user)
      })),
    [users]
  )

  useEffect(() => {
    if (users && users.count() === 1) {
      setFieldValue('driverUserId', userOptions[0].code)
    } else {
      setFieldValue('driverUserId', '')
    }
  }, [values.unitId])

  const noActiveDriversMessage = useMemo(
    () =>
      parseInt(values.unitId) > 0 && userOptions.length === 0
        ? i18next.t('manualOverride.noActiveDriverError')
        : undefined,
    [values.unitId, userOptions.length]
  )

  return (
    <>
      <LayoutFormRow columns={2}>
        <FormikRadioSelect
          name="unitId"
          validate={(value: string) => formikValidationRequired(value, i18next.t('shipmentDetails.required'))}
          required
          options={unitOptions}
          label={i18next.t('manualOverride.selectUnit')}
          aria-label={i18next.t('manualOverride.selectUnit')}
          placeholder={i18next.t('manualOverride.selectUnit')}
          selectedLabel={unitOptions.find((courier) => courier.code === values.unitId)?.label}
        />
        <FormikRadioSelect
          name="driverUserId"
          validate={(value: string) => formikValidationRequired(value, i18next.t('shipmentDetails.required'))}
          required
          options={userOptions}
          placeholder={i18next.t('manualOverride.selectDriver')}
          label={i18next.t('manualOverride.selectDriver')}
          aria-label={i18next.t('manualOverride.selectDriver')}
          selectedLabel={userOptions.find((user) => user.code === values.driverUserId)?.label}
          error={Boolean(noActiveDriversMessage)}
          errorMessage={noActiveDriversMessage}
        />
      </LayoutFormRow>
    </>
  )
}
