import { Label } from '../../primitives/Forms'
import variables from '../../styles/variables'
import i18next from 'i18next'
import { getMeasurementsTotalVolume, getMeasurementsTotalWeight } from './bookingOrderFunctions'
import React from 'react'
import { List } from 'immutable'
import { IMeasurement } from './bookingOrderTypes'

interface Props {
  measurements: List<IMeasurement>
}

export const BookingTotalWeightAndVolume = ({ measurements }: Props) => (
  <div style={{ display: 'flex', marginTop: '0.75em', alignItems: 'baseline' }}>
    <div style={{ flexGrow: 1 }}>
      <Label>
        <div style={{ color: variables.colors.black, fontWeight: 'bold', fontSize: '0.8rem' }}>
          {i18next.t('instant.booking.totalVolume', {
            volume: getMeasurementsTotalVolume(measurements).toFixed(1)
          })}{' '}
          dm³
        </div>
      </Label>
    </div>

    <div style={{ flexGrow: 1 }}>
      <Label>
        <div style={{ color: variables.colors.black, fontWeight: 'bold', fontSize: '0.8rem' }}>
          {i18next.t('instant.booking.totalWeight', {
            weight: getMeasurementsTotalWeight(measurements).toFixed(1)
          })}{' '}
          kg
        </div>
      </Label>
    </div>
  </div>
)
