import { LinkButton, ModalBase, PageHeading, PageHeadingProps } from '@glow/ui-components'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppStateType } from '../../utils/appStateReduxStore'
import { publishedWhatsThisModalsSelector } from '../../selectors/whatsThisModalSelector'
import i18next from 'i18next'
import { useAppDispatch } from '../../reducers/redux-hooks'
import { loggedInUserSelector } from '../../selectors/userSelectors'
import { isAuthPath } from '../../utils/loginUtils'
import { getPublishedWhatsThisModals } from '../../actions/creators/helpers'
import { MarkdownRenderer } from '../../pages/cms/MarkdownRenderer'
import { doesAnyPageMatchCurrentPathname } from '@glow/common'
import { useActiveWhatsThisModalText } from './utils'
import { supportedLanguages } from '../../i18n/i18n'
import { useLocation } from 'react-router'

const useFetchWhatsThisModals = () => {
  const dispatch = useAppDispatch()
  const user = useSelector((state: AppStateType) => loggedInUserSelector(state))
  useEffect(() => {
    if (!user?.isEmpty() && !isAuthPath()) {
      dispatch(getPublishedWhatsThisModals())
    }
  }, [user?.get('id')])
}

export const CMSPageHeading = (props: PageHeadingProps) => {
  const [showWhatsThisModal, setShowWhatsThisModal] = useState(false)
  useFetchWhatsThisModals()
  const publishedWhatsThisModals = useSelector((state: AppStateType) => publishedWhatsThisModalsSelector(state))
  const location = useLocation()

  const activeWhatsThisModal = useMemo(
    () =>
      publishedWhatsThisModals.find((whatsThisModal) =>
        doesAnyPageMatchCurrentPathname([whatsThisModal.get('page')], location.pathname)
      ),
    [publishedWhatsThisModals, location.pathname]
  )
  const currentLanguageCode = useMemo(
    () => supportedLanguages.find((lng) => lng.code === i18next.language)?.code || 'en',
    [i18next.language]
  )

  const whatsThisModalText = useActiveWhatsThisModalText(activeWhatsThisModal, location.pathname, currentLanguageCode)

  return (
    <>
      <PageHeading
        {...props}
        linkElement={
          whatsThisModalText ? (
            <LinkButton onClick={() => setShowWhatsThisModal(true)} type="button">
              {i18next.t('application.helpLink')}
            </LinkButton>
          ) : (
            props.linkElement
          )
        }
      />
      <ModalBase
        onClose={() => setShowWhatsThisModal(false)}
        open={showWhatsThisModal}
        size="medium"
        closeText={i18next.t('button.close')}
      >
        <MarkdownRenderer text={whatsThisModalText ?? ''} />
      </ModalBase>
    </>
  )
}
