import styled from 'styled-components'

interface SpaceProps {
  half?: boolean
  double?: boolean
}

export const HorizontalSpace = styled.div<SpaceProps>`
  display: inline-block;
  width: ${(props) => (props.half ? '0.5em' : props.double ? '2em' : '1em')};
`

export const VerticalSpace = styled.div`
  height: 30px;
`
