import React, { useEffect, useState } from 'react'
import { FlexColumn, FlexRow } from '../../../../primitives/Flex'
import { OrderIdType, Shipment } from '../../../../types/coreEntitiesTypes'
import { useSelector } from 'react-redux'
import { fromJS, List } from 'immutable'
import { PrimaryButton } from '../../../../primitives/Button'
import { DeliveryTime } from '../../shared/Shipment'
import { removeShipmentBookingTimes, setPageStateValue, updateDeliveryTime } from '../../../../actions/creators/helpers'
import { AppStateType } from '../../../../utils/appStateReduxStore'
import { createImmutableEqualSelector } from '../../../../selectors/createImmutableEqualSelector'
import { DateTime } from 'luxon'
import variables from '../../../../styles/variables'
import i18next from 'i18next'
import styled from 'styled-components'
import { ThunkDispatch } from '../../../../actions/creators/baseHelpers'
import { api } from '../../../../http/httpHelper'
import { FIND_AVAILABLE_DELIVERY_TIMES } from '../../../../actions/actionTypes'
import { BookedInfoRow } from './BookedInfoRow'
import { isValidDeliveryTime, getDeliveryTime } from './bookingTimeUtils'
import { ErrorNotification } from './ErrorNotification'
import { AvailableDeliveryTimesView } from './AvailableDeliveryTimesView'
import { CustomTimeWindowView } from './CustomTimeWindowView'
import { useAppDispatch } from '../../../../reducers/redux-hooks'

export const findAvailableDeliveryTimes = (orderId: OrderIdType, endDate: DateTime) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncQuery(FIND_AVAILABLE_DELIVERY_TIMES, {
      query: 'availableDeliveryTimes',
      params: {
        orderId,
        endDate: endDate.toISODate()
      }
    })
  ).then((response) => dispatch(setPageStateValue('availableDeliveryTimes', fromJS(response.readBody))))

export const availableTimesSelector: (state: AppStateType) => List<DeliveryTime> = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['pageState', 'availableDeliveryTimes']),
  (availableDeliveryTimes) =>
    availableDeliveryTimes
      ? List(availableDeliveryTimes).map((row: any) => ({
          earliest: DateTime.fromISO(row.get('deliveryEarliest')),
          latest: DateTime.fromISO(row.get('deliveryLatest'))
        }))
      : List()
)

export const BookingTimeWindow: React.FC<{ shipment: Shipment }> = ({ shipment }) => {
  const orderId = shipment.get('orderId')
  const dispatch = useAppDispatch()
  const availableDeliveryTimes = useSelector(availableTimesSelector)
  const [endDate, setEndDate] = useState<DateTime>(DateTime.now().endOf('month'))
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState<DeliveryTime | null>(null)
  const [errorNotification, setErrorNotification] = useState<string | null>(null)
  const [showCustomTimeView, setShowCustomTimeView] = useState<boolean>(false)

  const today = DateTime.now().startOf('day')

  useEffect(() => {
    dispatch(findAvailableDeliveryTimes(orderId, endDate))
    setSelectedDeliveryTime(getDeliveryTime(shipment))
    setShowCustomTimeView(false)
  }, [shipment, endDate])

  const completionHandler = (success: boolean, err?: any) => {
    if (success) {
      setErrorNotification(null)
    } else {
      if (
        Array.isArray(err?.body) &&
        err.body.filter((row: any) => row?.errorType === 'DELIVERY_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST')
          .length === 1
      ) {
        setErrorNotification(i18next.t('bookingTimeWindow.errorEarliestNotBeforeLatest'))
      } else {
        setErrorNotification(i18next.t('bookingTimeWindow.serverError'))
      }
      setTimeout(() => setErrorNotification(null), 10000)
    }
  }

  const saveDeliveryTime = (deliveryTime: DeliveryTime) => {
    dispatch(updateDeliveryTime(orderId, deliveryTime, completionHandler))
  }

  const removeBookingTimes = () => {
    dispatch(removeShipmentBookingTimes(orderId, completionHandler))
    setSelectedDeliveryTime(null)
  }

  const toggleCustomTimeView = () => setShowCustomTimeView(!showCustomTimeView)

  const nextPage = () => setEndDate(endDate.plus({ month: 1 }).endOf('month'))

  const prevPage = () => setEndDate(DateTime.max(today.endOf('day'), endDate.minus({ month: 1 }).endOf('month')))

  return (
    <FlexColumn>
      {errorNotification ? (
        <ErrorNotification message={errorNotification} />
      ) : (
        <BookedInfoRow shipment={shipment} onRemoveBookingTimes={removeBookingTimes} />
      )}
      {showCustomTimeView ? (
        <CustomTimeWindowView
          selectedDeliveryTime={selectedDeliveryTime}
          setSelectedDeliveryTime={setSelectedDeliveryTime}
          toggleCustomTimeView={toggleCustomTimeView}
        />
      ) : (
        <AvailableDeliveryTimesView
          availableDeliveryTimes={availableDeliveryTimes}
          selectedDeliveryTime={selectedDeliveryTime}
          setSelectedDeliveryTime={setSelectedDeliveryTime}
          toggleCustomTimeView={toggleCustomTimeView}
          endDate={endDate}
          setEndDate={setEndDate}
          prevPage={prevPage}
          nextPage={nextPage}
          prevDisabled={endDate <= today}
          nextDisabled={endDate >= DateTime.now().plus({ months: 6 })}
          arrivedAtTerminal={shipment.get('packagesArrivedAtDip') || 'no'}
        />
      )}
      <FlexRow justifyContent={'flex-end'}>
        <BookButton
          disabled={!isValidDeliveryTime(selectedDeliveryTime)}
          onClick={() => selectedDeliveryTime && saveDeliveryTime(selectedDeliveryTime)}
        >
          {i18next.t('bookingTimeWindow.bookButton')}
        </BookButton>
      </FlexRow>
    </FlexColumn>
  )
}

const BookButton = styled(PrimaryButton)`
  margin-top: 1.5em;
  background: ${variables.newColors.primaryGreen};
`
