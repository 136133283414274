import { H3 } from '../../../primitives/Headings'
import i18next from 'i18next'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { email, phone, required, validPhonePrefix, validTrimmed } from '../../../utils/inputValidation'
import { SmallValidationError } from '../../../primitives/ErrorMessages'
import { fromJS, List, Map } from 'immutable'
import { LabeledInput } from '../../../primitives/LabeledInput'
import { InputForLabel, TextAreaInputWithLabel } from '../../../primitives/Forms'
import Select from '../../../primitives/Select'
import { getSupportedCountries, ICountry } from '../../../utils/country'
import { TertiaryButton } from '../../../primitives/Button'
import { ClearInstantBookingFormButton } from '../../../components/ClearInstantBookingFormButton'
import { BookingType, OrderAction, pickupComponentNames } from '../bookingSupport'
import InputGroup from '../InputGroup'
import * as React from 'react'
import { IAddress, IOrderForm, IOrderFormProps } from '../bookingOrderTypes'
import toUpper from 'lodash/toUpper'
import { ImmutableMap, isNotNullOrUndefined } from '../../../types/immutableTypes'
import {
  createPickupAddress,
  formValuesToGroupPickup,
  getPhonePrefixFromDepartment,
  stripEmptyPhonePrefix
} from '../bookingOrderFunctions'
import { Department } from '../../../types/coreEntitiesTypes'
import { AddressOptions } from '../../../actions/creators/addressHelpers'
import ImmutableComponent from '../../../primitives/ImmutableComponent'
import { CustomerIdType } from '../../../domain/customer'
import { GroupRecipientProps, MultistopGroupForm } from '../MultistopGroupForm'
import { GooglePlacesAutocompleteWrapper } from '../../../components/GooglePlacesAutompleteWrapper'
import { PickupName } from './PickupName'

interface Props {
  departmentId?: number
  mergeFormValues: (model: string, values: Map<string, any>) => void
  department: Department
  formValues: IOrderForm
  selectedCustomerId?: number
  createOrUpdateAddress?: (address: IAddress, options?: AddressOptions) => any
  addNotification: (props: { title: string; message?: string; timeout: number }) => void
  customerId?: CustomerIdType
  userId?: number
  orderAction: OrderAction
  setTouched: (models: string) => void
  setUntouched: (models: string) => void
  form: { [key: string]: any }
  disableAdditionalPickupButton?: boolean
  hideAdditionalPickupButton?: boolean
  bookingType?: BookingType
  formName: string
  clearComponents: (components: List<string>) => void
}

export const pickupFields = List.of<keyof IOrderFormProps>(
  'pickupName',
  'pickupAddress',
  'pickupZipArea',
  'pickupZipCode',
  'pickupCountryCode',
  'pickupPhone',
  'pickupEmail',
  'pickupContactPerson',
  'pickupInstructions',
  'customerRef'
)

class BookingFormPickup extends ImmutableComponent<Props> {
  static defaultProps = {
    department: Map()
  }

  onPickupAddressChanged = (address: IAddress) => {
    const formValues = {
      pickupAddress: address.get('streetname') || address.get('address') || '',
      pickupZipCode: address.get('zipCode') || '',
      pickupZipArea: address.get('zipArea') || '',
      pickupCountryCode: toUpper(address.get('countryCode') || address.get('country') || ''),
      pickupAddressLat: address.get('latLng')?.get('lat') || NaN,
      pickupAddressLng: address.get('latLng')?.get('lng') || NaN
    }
    this.props.mergeFormValues(this.props.formName, Map(formValues).filter(isNotNullOrUndefined))
  }

  onPickupPlaceChanged = (department: Department, address: IAddress) => {
    this.onPickupAddressChanged(address)
    const formValues = {
      pickupPhone: address.get('phone') || getPhonePrefixFromDepartment(department),
      pickupContactPerson: address.get('contactPerson') || '',
      pickupInstructions: address.get('instructions', ''),
      pickupAddressLat: address.get('latLng')?.get('lat') || NaN,
      pickupAddressLng: address.get('latLng')?.get('lng') || NaN,
      pickupEmail: ''
    }
    this.props.mergeFormValues(this.props.formName, Map(formValues).filter(isNotNullOrUndefined))
  }

  setDefaultPickupAddress = (values: IOrderForm) => {
    const { department, selectedCustomerId, createOrUpdateAddress, addNotification } = this.props
    const phonePrefix = getPhonePrefixFromDepartment(department)
    const pickupPhone = stripEmptyPhonePrefix(values.get('pickupPhone'), phonePrefix)
    const deliveryPhone = stripEmptyPhonePrefix(values.get('deliveryPhone'), phonePrefix)
    const newAddress = createPickupAddress(values.set('pickupPhone', pickupPhone).set('deliveryPhone', deliveryPhone))
    if (selectedCustomerId && createOrUpdateAddress) {
      createOrUpdateAddress(newAddress, { defaultForCustomerId: selectedCustomerId }).then(() =>
        addNotification({
          title: i18next.t('instant.booking.defaultAddressUpdated'),
          timeout: 5000
        })
      )
    }
  }

  static isFormfieldValid(formField: any) {
    return formField && formField.valid
  }

  isPickupValid = (form: { [p: string]: any }) => {
    return (
      form &&
      [form.pickupName, form.pickupAddress, form.pickupZipArea, form.pickupZipCode, form.pickupCountryCode].every(
        BookingFormPickup.isFormfieldValid
      )
    )
  }

  setFieldsTouched = (fields: List<string>, touched: boolean = true) => {
    if (touched) {
      fields.forEach(this.props.setTouched)
    } else {
      fields.forEach(this.props.setUntouched)
    }
  }

  setPickupFieldsTouched = (touched: boolean = true) => {
    this.setFieldsTouched(
      pickupFields.map((f) => `${this.props.formName}.` + f),
      touched
    )
  }

  submitPickup = () => {
    const values = this.props.formValues
    const form = this.props.form
    this.setPickupFieldsTouched(true)
    if (this.isPickupValid(form)) {
      const groupPickups = values.get('groupPickups') || List()
      const phonePrefix = getPhonePrefixFromDepartment(this.props.department)
      this.props.mergeFormValues(
        this.props.formName,
        Map({
          groupPickups: groupPickups.push(
            formValuesToGroupPickup(values, getPhonePrefixFromDepartment(this.props.department))
          )
        })
      )
      this.props.mergeFormValues(
        this.props.formName,
        Map({
          pickupName: '',
          pickupAddress: '',
          pickupZipArea: '',
          pickupZipCode: '',
          pickupCountryCode: '',
          pickupPhone: phonePrefix || '',
          pickupContactPerson: '',
          pickupInstructions: '',
          pickupEmail: ''
        })
      )
      this.setPickupFieldsTouched(false)
    }
  }

  render() {
    const {
      mergeFormValues,
      department,
      formValues,
      orderAction,
      disableAdditionalPickupButton,
      hideAdditionalPickupButton,
      bookingType,
      clearComponents
    } = this.props
    const isH2Booking = bookingType === BookingType.H2

    return (
      <InputGroup aria-label="Pickup form">
        <H3>{i18next.t('instant.booking.pickup')}</H3>
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: 5, paddingRight: '0.75em' }}>
            <PickupName
              isH2Booking={isH2Booking}
              department={department}
              onPickupPlaceChanged={this.onPickupPlaceChanged}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Control
              model=".pickupLocation"
              id="pickupLocation"
              component={GooglePlacesAutocompleteWrapper}
              searchAroundArea={
                department
                  ? {
                      lat: department.get('lat'),
                      lng: department.get('lng')
                    }
                  : {}
              }
              rounded={true}
              onComplete={(address: IAddress) => this.onPickupAddressChanged(fromJS(address) as IAddress)}
            />
          </div>
        </div>

        <div style={{ display: 'flex', marginTop: '0.75em' }}>
          <div style={{ paddingRight: '0.75em', flexGrow: 3 }}>
            <LabeledInput label={i18next.t('instant.booking.pickupAddressPlaceholder')} htmlFor="pickupAddress">
              <Control.text
                autoComplete="nope"
                model=".pickupAddress"
                id="pickupAddress"
                component={InputForLabel}
                validators={{ required }}
                defaultValue={''}
              />
            </LabeledInput>
            <Errors
              className="errors"
              model=".pickupAddress"
              show="touched"
              wrapper={SmallValidationError}
              messages={{ required: `${i18next.t('application.required')}` }}
            />
          </div>
          <div style={{ paddingRight: '0.75em', width: '10em' }}>
            <LabeledInput label={i18next.t('instant.booking.pickupZipCodePlaceholder')} htmlFor="pickupZipCode">
              <Control.text
                autoComplete="nope"
                model=".pickupZipCode"
                id="pickupZipCode"
                component={InputForLabel}
                validators={{ required, validTrimmed }}
                defaultValue={''}
              />
            </LabeledInput>

            <Errors
              model=".pickupZipCode"
              show="touched"
              wrapper={SmallValidationError}
              messages={{
                required: `${i18next.t('application.required')}`,
                validTrimmedToNumber: `${i18next.t('error.notAValidNumber')}`
              }}
            />
          </div>
          <div style={{ paddingRight: '0.75em', flexGrow: 1 }}>
            <LabeledInput label={i18next.t('instant.booking.pickupZipAreaPlaceholder')} htmlFor="pickupZipArea">
              <Control.text
                autoComplete="nope"
                model=".pickupZipArea"
                id="pickupZipArea"
                component={InputForLabel}
                validators={{ required }}
                defaultValue={''}
              />
            </LabeledInput>
            <Errors
              model=".pickupZipArea"
              show="touched"
              wrapper={SmallValidationError}
              messages={{ required: `${i18next.t('application.required')}` }}
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <Select expand>
              <Control.select
                model={'.pickupCountryCode'}
                id="pickupCountryCode"
                validators={{ required }}
                defaultValue={''}
              >
                <option value={''}>{i18next.t('instant.booking.pickupCountryPlaceholder')}</option>
                {getSupportedCountries().map((country: ICountry) => {
                  const id = country.get('code')
                  return (
                    <option key={`dimension-${id}`} value={id}>
                      {country.get('name')}
                    </option>
                  )
                })}
              </Control.select>
            </Select>
            <Errors
              className="errors"
              model=".pickupCountryCode"
              show="touched"
              wrapper={SmallValidationError}
              messages={{ required: `${i18next.t('application.required')}` }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', marginTop: '0.75em', alignItems: 'baseline', gap: '0.75rem' }}>
          <div style={{ flexGrow: 1 }}>
            <LabeledInput label={i18next.t('instant.booking.pickupContactPerson')} htmlFor="pickupContactPerson">
              <Control.text
                autoComplete="nope"
                model=".pickupContactPerson"
                id="pickupContactPerson"
                component={InputForLabel}
              />
            </LabeledInput>
          </div>

          <div style={{ flexGrow: 1 }}>
            <LabeledInput label={i18next.t('instant.booking.pickupPhoneNumberPlaceholder')} htmlFor="pickupPhone">
              <Control.text
                autoComplete="nope"
                model=".pickupPhone"
                id="pickupPhone"
                component={InputForLabel}
                validators={{ phone, validPhonePrefix }}
              />
            </LabeledInput>
            <Errors
              className="errors"
              model=".pickupPhone"
              show="touched"
              wrapper={SmallValidationError}
              messages={{
                required: `${i18next.t('application.required')}`,
                phone: `${i18next.t('error.phoneNumber')}`,
                validPhonePrefix: `${i18next.t('error.phoneNumberPrefix')}`
              }}
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <LabeledInput label={i18next.t('shipment.email')} htmlFor="pickupEmail">
              <Control.text
                autoComplete="nope"
                model=".pickupEmail"
                id="pickupEmail"
                component={InputForLabel}
                validators={{ email }}
              />
            </LabeledInput>
            <Errors
              className="errors"
              model=".pickupEmail"
              show="touched"
              wrapper={SmallValidationError}
              messages={{ email: `${i18next.t('instant.booking.invalidEmail')}` }}
            />
          </div>
        </div>
        <div style={{ marginTop: '0.75em' }}>
          <LabeledInput label={i18next.t('instant.booking.pickupInformationPlaceholder')} htmlFor="pickupInstructions">
            <Control.textarea
              model=".pickupInstructions"
              id="pickupInstructions"
              rows={4}
              component={TextAreaInputWithLabel}
            />
          </LabeledInput>
        </div>
        {!isH2Booking && (
          <>
            <div
              style={{ display: 'flex', marginTop: '0.75em', alignItems: 'baseline', justifyContent: 'space-between' }}
            >
              <TertiaryButton
                disabled={!formValues.get('pickupAddressLat')}
                title={
                  !formValues.get('pickupAddressLat') && formValues.get('pickupAddress')
                    ? i18next.t('instant.booking.setAsDefaultDisabledText')
                    : ''
                }
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  this.setDefaultPickupAddress(formValues)
                }}
              >
                <span style={{ margin: '5px' }}>{i18next.t('instant.booking.setAsDefaultAddress')}</span>
              </TertiaryButton>
              {orderAction !== OrderAction.Edit &&
                orderAction !== OrderAction.Restore &&
                !hideAdditionalPickupButton && (
                  <TertiaryButton type="button" onClick={this.submitPickup} disabled={disableAdditionalPickupButton}>
                    <span style={{ margin: '5px' }}>{i18next.t('instant.booking.addPickup')}</span>
                  </TertiaryButton>
                )}
            </div>
            <MultistopGroupForm
              recipients={formValues.get('groupPickups') || List<ImmutableMap<GroupRecipientProps>>()}
              formValues={formValues}
              onDelete={(id: number) => {
                const groupPickups =
                  this.props.formValues.get('groupPickups') || List<ImmutableMap<GroupRecipientProps>>()
                mergeFormValues(this.props.formName, Map({ groupPickups: groupPickups.remove(id) }))
              }}
            />
          </>
        )}
        <ClearInstantBookingFormButton clearComponents={clearComponents} components={pickupComponentNames} />
      </InputGroup>
    )
  }
}

export default BookingFormPickup
