import { isProduction } from '@glow/common'
import { FormikMultiSelect } from '@glow/formik-components'
import { ButtonBase, IconFa, LayoutForm, Option, SelectionCard } from '@glow/ui-components'
import { Form, Formik, FormikHelpers } from 'formik'
import i18next from 'i18next'
import { List, Map, OrderedSet, Set } from 'immutable'
import { DateTime } from 'luxon'
import React, { ReactNode, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { dispatchManualOverrideEvent } from '../../../components/newShipmentModal/panels/ManualOverrideUtils'
import { ErrorResponseToast, ToastContext } from '../../../contexts/ToastContext'
import { useAppDispatch } from '../../../reducers/redux-hooks'
import { selectedDepartmentIdsSelector } from '../../../selectors/departmentsSelector'
import { Consignment, OrderIdType, isH2Order, isHDOrder } from '../../../types/coreEntitiesTypes'
import { ImmutableMap } from '../../../types/immutableTypes'
import { AppStateType } from '../../../utils/appStateReduxStore'
import { EventType } from '../../../utils/consignmentEvent'
import { ConsignmentState, consignmentCanBeCollected } from '../../../utils/consignmentState'
import { today } from '../../../utils/dateTime'
import { DeviationCodeLabel, pickupDeviationCodes } from '../../../utils/deviation'
import { TIMESTAMP_DATE_FORMAT, TIMESTAMP_TIME_FORMAT } from '../../../utils/inputValidation'
import { ChangeDepartmentForm } from './ChangeDepartmentForm'
import { ChangeSlotForm } from './ChangeSlotForm'
import { CollectedForm, DeviatedForm, ReturnedAndDeliveredForm, ScannedForm, SortedForm } from './ManualOverrideForms'

export interface ManualOverrideFormProps {
  packages: string[]
  event: EventType | ''
  name: string
  date: string
  time: string
  departmentId: string
  deviationCode: string
  unitId: string
  driverUserId: string
}

const initialFormValues = (
  defaultPackages: Option[],
  canCollectShipment: boolean,
  departmentId: string,
  consignments: List<PartialConsignmentMap>,
  setNoInitialEventType?: boolean
): ManualOverrideFormProps => {
  const consignmentIds = defaultPackages.map((x) => x.code)
  const selectedConsignments = consignments.filter((c) => consignmentIds.includes(c.get('id') + ''))

  const firstUnitId = selectedConsignments.first(undefined)?.get('courierId')
  const allConsignmentsHaveSameUnit = selectedConsignments.every((c) => c.get('courierId') === firstUnitId)
  const selectedUnitId = allConsignmentsHaveSameUnit && firstUnitId ? firstUnitId.toString() : ''

  const firstDriverUserId = selectedConsignments.first(undefined)?.get('driverUserId')
  const allConsignmentsHaveSameDriver = selectedConsignments.every((c) => c.get('driverUserId') === firstUnitId)
  const selectedDriverUserId = allConsignmentsHaveSameDriver && firstDriverUserId ? firstDriverUserId.toString() : ''

  return {
    packages: defaultPackages.map((x) => x.code + ''),
    event: setNoInitialEventType ? '' : canCollectShipment ? EventType.COLLECTED : EventType.DELIVERED,
    name: '',
    date: today().toFormat('yyyy-MM-dd'),
    time: DateTime.local().toFormat('HH:mm'),
    departmentId,
    deviationCode: pickupDeviationCodes.first<DeviationCodeLabel>(Map()).get('code'),
    unitId: selectedUnitId,
    driverUserId: selectedDriverUserId
  }
}

interface Props {
  consignments: List<Consignment>
  orderIds: List<OrderIdType>
  urlDepartmentId: string
  url: string
  bulkActionMode?: boolean
}

export const ManualOverrideEvents = ({
  event,
  canCollectShipment,
  canReturnShipment,
  setFieldValue,
  canSortShipment,
  canMarkAsNotArrivedAtDistributingTerminal
}: {
  event: EventType
  canCollectShipment: boolean
  canReturnShipment: boolean
  setFieldValue: (value: string, event: EventType) => void
  canSortShipment: boolean
  canMarkAsNotArrivedAtDistributingTerminal: boolean
}) => {
  return (
    <div className="flex gap-4 border-0 border-b border-solid border-gray-300 pb-6">
      <SelectionCard
        onClick={() => setFieldValue('event', EventType.COLLECTED)}
        selected={event === EventType.COLLECTED}
        variant="neutral"
        disabled={!canCollectShipment}
        disabledText={i18next.t('shipmentDetails.disabledEventReason.collected')}
        icon={<IconFa icon={['far', 'circle-arrow-up']} />}
      >
        {i18next.t(`manualOverride.eventType.${EventType.COLLECTED}`)}
      </SelectionCard>
      <SelectionCard
        onClick={() => setFieldValue('event', EventType.DELIVERED)}
        selected={event === EventType.DELIVERED}
        variant="green"
        icon={<IconFa icon={['far', 'check-circle']} />}
      >
        {i18next.t(`manualOverride.eventType.${EventType.DELIVERED}`)}
      </SelectionCard>
      <SelectionCard
        onClick={() => setFieldValue('event', EventType.DEVIATED)}
        selected={event === EventType.DEVIATED}
        variant="red"
        icon={<IconFa icon={['fas', 'exclamation-triangle']} />}
      >
        {i18next.t(`manualOverride.eventType.${EventType.DEVIATED}`)}
      </SelectionCard>
      <SelectionCard
        onClick={() => setFieldValue('event', EventType.RETURNED)}
        selected={event === EventType.RETURNED}
        variant="orange"
        disabled={!canReturnShipment}
        disabledText={i18next.t('shipmentDetails.disabledEventReason.returned')}
        icon={<IconFa icon={['fas', 'check-circle']} />}
      >
        {i18next.t(`manualOverride.eventType.${EventType.RETURNED}`)}
      </SelectionCard>
      <SelectionCard
        onClick={() => setFieldValue('event', EventType.SCANNED)}
        selected={event === EventType.SCANNED}
        variant="neutral"
        icon={<IconFa icon={['far', 'barcode-read']} />}
      >
        {i18next.t(`manualOverride.eventType.${EventType.SCANNED}`)}
      </SelectionCard>
      {/*THIS SHOULD NEVER BE TURNED ON IN PROD! Talk to business before removing the feature flag, this is not meant for prod!*/}
      {!isProduction() && (
        <SelectionCard
          onClick={() => setFieldValue('event', EventType.ORDER_SORTED)}
          selected={event === EventType.ORDER_SORTED}
          variant="neutral"
          icon={<IconFa icon={['fas', 'conveyor-belt-boxes']} />}
          disabled={!canSortShipment}
          disabledText={i18next.t('manualOverride.disabledEventReason.order-sorted')}
        >
          {i18next.t(`manualOverride.eventType.${EventType.ORDER_SORTED}`)}
        </SelectionCard>
      )}
      <SelectionCard
        onClick={() => setFieldValue('event', EventType.NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL)}
        selected={event === EventType.NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL}
        variant="neutral"
        icon={<IconFa icon={['fas', 'conveyor-belt-boxes']} />}
        disabled={!canMarkAsNotArrivedAtDistributingTerminal}
        disabledText={i18next.t(`manualOverride.disabledEventReason.${EventType.NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL}`)}
      >
        {i18next.t(`manualOverride.eventType.${EventType.NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL}`)}
      </SelectionCard>
    </div>
  )
}

const SecondColumn = ({ consignments, urlDepartmentId, orderIds }: Props) => {
  if (!consignments) return null
  return (
    <div className="py-6 px-10 border-0 border-l border-solid border-emphasis-low">
      <ChangeSlotForm consignments={consignments} departmentId={Number(urlDepartmentId)} />
      <h5 className="h5">{i18next.t('consignment.moveOrderTitle')}</h5>
      <span>{i18next.t('shipmentDetails.moveShipmentDescription')}</span>
      <ChangeDepartmentForm orderIds={orderIds} departmentId={urlDepartmentId} />
    </div>
  )
}

interface ManualEventsSectionFieldsProps extends Omit<ManualEventsSectionFormProps, 'children'>, FormikRenderProps {
  showSaveButton: boolean
}

export const ManualEventsSectionFields = ({
  consignments,
  urlDepartmentId,
  orderIds,
  bulkActionMode,
  values,
  isSubmitting,
  packageOptions,
  setFieldValue,
  showSaveButton = true
}: ManualEventsSectionFieldsProps) => {
  const canCollectShipment = consignments.every((consignment) =>
    consignmentCanBeCollected.contains(consignment.get('state'))
  )
  const canReturnShipment = consignments.every((consignment) => ConsignmentState.DEVIATED === consignment.get('state'))

  const canSortShipment = consignments.every((consignment) => isH2Order(consignment.get('type')))
  const canMarkAsNotArrivedAtDistributingTerminal = consignments.every((consignment) =>
    isHDOrder(consignment.get('type'))
  )

  const selectedDepartmentIds = useSelector((state: AppStateType) => {
    return selectedDepartmentIdsSelector(state, urlDepartmentId)
  })

  return (
    <>
      <div className="flex mb-4 justify-between">
        <h5 className="h5">{i18next.t('shipmentDetails.manualChangeEvent')}</h5>
        {!bulkActionMode && showSaveButton && (
          <div>
            <ButtonBase type="submit" isLoading={isSubmitting} variant="primary">
              {i18next.t('application.save')}
            </ButtonBase>
          </div>
        )}
      </div>
      {!bulkActionMode && (
        <div className="w-fit mb-4">
          <FormikMultiSelect
            name="packages"
            placeholder="Select package"
            options={packageOptions}
            selected={
              values.packages.length === packageOptions.length
                ? i18next.t('shipmentDetails.allPackages')
                : values.packages.length > 1
                  ? `${values.packages.length} ${i18next.t('shipmentDetails.packages')}`
                  : `${values.packages.length} ${i18next.t('shipmentDetails.package')}`
            }
            aria-label={i18next.t('shipmentFormPage.selectStateLabel')}
          />
        </div>
      )}
      <LayoutForm>
        <ManualOverrideEvents
          event={values.event as EventType}
          canCollectShipment={canCollectShipment}
          canReturnShipment={canReturnShipment}
          setFieldValue={setFieldValue}
          canSortShipment={canSortShipment}
          canMarkAsNotArrivedAtDistributingTerminal={canMarkAsNotArrivedAtDistributingTerminal}
        />
        <div className="mt-6">
          {values.event === EventType.SCANNED ? (
            <ScannedForm values={values} />
          ) : values.event === EventType.ORDER_SORTED ||
            values.event === EventType.NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL ? (
            <SortedForm values={values} />
          ) : values.event === EventType.DEVIATED ? (
            <DeviatedForm
              departmentIds={selectedDepartmentIds}
              orderIds={orderIds}
              values={values}
              consignments={consignments}
            />
          ) : values.event === EventType.COLLECTED ? (
            <CollectedForm departmentIds={selectedDepartmentIds} orderIds={orderIds} values={values} />
          ) : (
            <ReturnedAndDeliveredForm departmentIds={selectedDepartmentIds} orderIds={orderIds} values={values} />
          )}
        </div>
      </LayoutForm>
    </>
  )
}

export interface FormikRenderProps {
  values: ManualOverrideFormProps
  isSubmitting: boolean
  setFieldValue: (key: string, value: string) => void
  packageOptions: Option[]
}

export interface PartialConsignment {
  id: number
  state: ConsignmentState
  packageId: string
  courierId?: number
  driverUserId?: number
  vasCodes?: List<string>
  orderId: number
  type: string | null
}

export interface PartialConsignmentMap extends ImmutableMap<PartialConsignment> {}

export interface ManualEventsSectionFormProps extends Omit<Props, 'consignments'> {
  children: (props: FormikRenderProps) => ReactNode
  consignments: List<PartialConsignmentMap>
  onClose?: () => void
  setNoInitialEventType?: boolean
}

export const ManualEventsSectionForm = ({
  children,
  consignments,
  bulkActionMode,
  urlDepartmentId,
  orderIds,
  onClose,
  setNoInitialEventType
}: ManualEventsSectionFormProps) => {
  const { setToast, setToastError } = useContext(ToastContext)
  const dispatch = useAppDispatch()
  // For unknown reason, formik isSubmitting doesn't work with the RouteLeavingGuard when saving
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  const packageOptions: Option[] = useMemo(() => {
    return consignments
      .map((consignment) => ({ code: `${consignment.get('id')}`, label: consignment.get('packageId') }))
      .toArray()
  }, [consignments])

  const canCollectShipment = consignments.every((consignment) =>
    consignmentCanBeCollected.contains(consignment.get('state'))
  )

  const onEventSubmit = async (
    values: ManualOverrideFormProps,
    { resetForm }: FormikHelpers<ManualOverrideFormProps>
  ) => {
    if (eventTimeIsInFuture(values.date, values.time)) {
      setToast({ variant: 'error-dark', text: i18next.t('shipmentDetails.eventTimeCannotBeInFuture') })
      return
    }
    setIsSubmitting(true)
    const safeNumbers = values.packages.map((x) => Number(x)).filter((x) => !isNaN(x))
    try {
      await postEvents(safeNumbers, values)
      onClose?.()
      setToast({ variant: 'success-dark', text: i18next.t('shipmentDetails.eventWasAdded') })
      resetForm()
      if (bulkActionMode && !setNoInitialEventType) {
        navigate(-1)
      }
    } catch (error) {
      setToastError(error as ErrorResponseToast)
    } finally {
      setIsSubmitting(false)
    }
  }

  const eventTimeIsInFuture = (date: string, time: string): boolean => {
    const eventTime = DateTime.fromFormat(`${date} ${time}`, `${TIMESTAMP_DATE_FORMAT} ${TIMESTAMP_TIME_FORMAT}`)
    return eventTime.toISO() > DateTime.now().toISO()
  }

  const postEvents = async (safeNumbers: number[], values: ManualOverrideFormProps) => {
    return new Promise(async (resolve, reject) => {
      const timestamp = DateTime.fromFormat(
        `${values.date} ${values.time}`,
        `${TIMESTAMP_DATE_FORMAT} ${TIMESTAMP_TIME_FORMAT}`
      ).toISO()
      if (safeNumbers.length > 0) {
        const formValues = { ...values, consignmentIds: Set.of(...safeNumbers) }
        const req = dispatchManualOverrideEvent(
          values.event as EventType,
          OrderedSet(orderIds),
          timestamp,
          formValues,
          dispatch
        )

        if (req) {
          try {
            await req
            return resolve({})
          } catch (error) {
            reject(error)
          }
        } else {
          reject(i18next.t('shipmentDetails.issueSaving'))
        }
      } else {
        reject(i18next.t('shipmentDetails.noSelectedPackages'))
      }
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues(
        packageOptions,
        canCollectShipment,
        urlDepartmentId,
        consignments,
        setNoInitialEventType
      )}
      onSubmit={(values, helpers) => onEventSubmit(values, helpers)}
    >
      {({ values, setFieldValue }) => (
        <>
          {/* <RouteLeavingGuard when={dirty && !isSubmitting} /> */}
          {children({ values, isSubmitting, setFieldValue, packageOptions })}
        </>
      )}
    </Formik>
  )
}

export const SHOW_OLD_MANUAL_OVERRIDE_COMPONENT = false

export const ManualOverrideViewTab = (props: Props) => {
  return (
    <div className="grid grid-cols-[auto,25rem] h-full">
      {SHOW_OLD_MANUAL_OVERRIDE_COMPONENT && (
        <ManualEventsSectionForm {...props}>
          {(formikRenderProps) => (
            <Form className="flex-1 my-6 px-10">
              <ManualEventsSectionFields showSaveButton={true} {...props} {...formikRenderProps} />
            </Form>
          )}
        </ManualEventsSectionForm>
      )}

      <SecondColumn {...props} />
    </div>
  )
}
