import { CustomerIdType } from '../../domain/customer'
import { api } from '../../http/httpHelper'
import { OrderIdType } from '../../types/coreEntitiesTypes'
import { GET_CUSTOMER, GET_DELETED_ORDER, GET_ORDER, GET_ORDERS_BY_GROUP_ID } from '../actionTypes'
import { fetchEvenIfPresent, fetchIfNotPresent } from './baseHelpers'

export const getOrderById = (id: OrderIdType) =>
  fetchIfNotPresent(GET_ORDER, { query: 'orderById', params: { id: id, includeRoutePoints: true } })

export const getDeletedOrderById = (id: OrderIdType) =>
  fetchIfNotPresent(GET_DELETED_ORDER, { query: 'deletedOrderById', params: { id: id } })

export const getOrdersByGroupId = (groupId: string) =>
  fetchEvenIfPresent(GET_ORDERS_BY_GROUP_ID, {
    query: 'ordersByGroupId',
    params: { groupId: groupId }
  })

export const getOrderByIdUncached = (id: OrderIdType) =>
  api.asyncQuery(GET_ORDER, { query: 'orderById', params: { id: id } })

export const getDeletedOrderByIdUncached = (id: OrderIdType) =>
  api.asyncQuery(GET_DELETED_ORDER, { query: 'deletedOrderById', params: { id: id } })

export const getCustomerById = (id: CustomerIdType) =>
  fetchIfNotPresent(GET_CUSTOMER, { query: 'customerById', params: { id: id } })
